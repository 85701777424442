import React, { useContext, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LinkIcon from '@material-ui/icons/Link';

import { Button } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DocRow = (props) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const globalContext = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const { file, files, setFiles, utilityOptions, entity } = props;

    //CHANGE THE VALIDATION HERE
    // const checkFileBlob = file.blob === null;
    const checkFileBlob = true;

    //EXEMPLU DE DESCARCARE
    // OffersAPI.getDocumentByPath(path).then((res) => {
    //     const url = window.URL.createObjectURL(new Blob([res.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', path);
    //     document.body.appendChild(link);
    //     link.click();
    //     link.parentNode.removeChild(link);
    // });

    const deleteOnFrontEnd = () => {
        const filteredFiles = files.filter((pV) => pV.id !== file.id);
        setFiles(filteredFiles);
    };

    const deleteOnBackEnd = async () => {
        setLoading(true);

        try {
            console.log('delete pe back');
            deleteOnFrontEnd();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const downloadFile = () => {
        console.log('download');
        const link = document.createElement('a');
        link.href = file.url;
        //CHANGE THE FILE NAME HERE
        link.setAttribute('download', file.url.substring('data:image/'.length, file.url.indexOf(';base64')));
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    //TODO, it will look differently when we will have the back-end: Frontend deletion and loading state will be updated when the request is done and has an ok result
    const handleDelete = () => {
        if (checkFileBlob) {
            if (loading) {
                enqueueSnackbar(t('A document is already being deleted! Wait until it finishes!'), {
                    variant: 'error',
                });
                return;
            }
            deleteOnBackEnd();
        } else {
            deleteOnFrontEnd();
        }
    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(file.url);
        } catch (error) {
            console.error(error);
        } finally {
            enqueueSnackbar(t('Document link copied to clipboard!'), {
                variant: 'success',
            });
        }
    };

    return (
        <div className="group flex items-center justify-between gap-1 rounded-md bg-layout-transparent px-1 py-2 hover:bg-primary-main">
            <div className="flex items-center justify-between gap-1">
                <InsertDriveFileIcon className="h-8 w-8 text-buttons-text" />
                <p className="text-buttons-text">{file.name}</p>
            </div>
            <div className="flex items-center gap-2 ">
                {utilityOptions.canCopyLink && checkFileBlob && (
                    <div
                        onClick={handleCopy}
                        className="flex cursor-pointer items-center rounded-full p-1 group-hover:bg-layout-lighter"
                    >
                        <LinkIcon className="h-8 w-8 text-buttons-text " />
                    </div>
                )}
                {utilityOptions.canDownload && checkFileBlob && (
                    <div
                        onClick={downloadFile}
                        className="flex cursor-pointer items-center rounded-full p-1 group-hover:bg-layout-lighter"
                    >
                        <GetAppIcon className="h-8 w-8 text-buttons-text" />
                    </div>
                )}
                {utilityOptions.canDelete && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            globalContext.setGlobalModalChildren(
                                <div>
                                    <div className="flex-col">
                                        <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                            {t('Are you sure you want to')}
                                            <br />
                                            {t('delete this document?')}
                                        </p>
                                        <div className="flex items-center justify-center">
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    globalContext.setGlobalModalOpen(false);
                                                    handleDelete();
                                                }}
                                            >
                                                {t('Yes')}
                                            </Button>
                                            <div className="w-4" />
                                            <Button onClick={() => globalContext.setGlobalModalOpen(false)}>
                                                {t('No')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            );
                            globalContext.setGlobalModalOpen(true);
                        }}
                        className="flex cursor-pointer items-center rounded-full p-1 group-hover:bg-layout-lighter"
                    >
                        <CloseIcon className="h-8 w-8 text-buttons-text" />
                    </div>
                )}
            </div>
        </div>
    );
};

DocRow.propTypes = {
    entity: PropTypes.string,
    file: PropTypes.object,
    utilityOptions: PropTypes.object,
    setFiles: PropTypes.func,
    files: PropTypes.arrayOf(PropTypes.object),
};

DocRow.defaultProps = {
    entity: PropTypes.null,
    file: {},
    utilityOptions: { canDelete: false, canCopyLink: false, canDownload: false },
    setFiles: () => {},
    files: [],
};

export default DocRow;
