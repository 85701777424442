import React, { Fragment, useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ListAltIcon from '@material-ui/icons/ListAlt';

import { Button, LinearProgress, TextField } from '@material-ui/core';

import { AccountingAccounts } from 'api/Accounting_Accounts';
import { Revenues } from 'api/Expenses_Revenues';
import CustomModal from 'components/modals/custom_modal';
import ProjectSelection from 'components/shared/configurator/project-selection';
import ErrorButton from 'components/shared/error-button';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import UtilityRevenueBudget from 'pages/crm/contracts/creation-steps/utility-revenue-budget';
import OfferArticleHistory from 'pages/quotes/newBidding/offer-article-history';
import CreateStockModal from 'pages/quotes/stocks/create-stock-modal';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, LabelWrapper, Tags } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { quotes } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import { getCompanyVAT } from 'utils/getterFunctions';
import {
    calculateTotal,
    calculateVATValue,
    formatPositiveNumberWithDigits,
    formatVATnumber,
    toLocaleNumber,
    toRoundedSignificantDigits,
} from 'utils/index';
import * as yup from 'yup';

import Variable from '../../../components/crm/quotes/variable';
import Budgetary from './budgetary/budgetary';

const ArticleForm = (props) => {
    const { articleId: id = null, setInitializeData, isStockCase, onDeleteArticleCallback, setDisabledParent } = props;

    const [isOpenStockModal, setIsOpenStockModal] = useState(false);

    const [selectOption, setSelectOption] = useState(null);
    const [categories, setCategories] = useState([]);
    const [article, setArticle] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [articleName, setArticleName] = useState(null);
    const [articleCode, setArticleCode] = useState(null);
    const [articleDescription, setArticleDescription] = useState('');
    const [um, setUM] = useState(null);
    const [newUm, setNewUm] = useState(null);
    const [newUmField, setNewUmField] = useState(false);
    const [priceWithoutTVA, setPriceWithoutTVA] = useState(0);
    const [tvaPercent, setTvaPercent] = useState(null);
    const [vars, setVars] = useState([]);
    const [loading, setLoading] = useState(!!id);
    const [companyVat, setCompanyVat] = useState(0);

    const [accounts, setAccounts] = useState([]);
    const [allRevenues, setAllRevenues] = useState([]);
    const [exchangeRate, setExchangeRate] = useState(0.0001);
    const [exchangeDate, setExchangeDate] = useState(new Date());
    const [checkedRevenues, setCheckedRevenues] = useState([]);

    /*
        Selected project state
    */
    const [selectedProject, setSelectedProject] = useState(null);

    const { currencyObj, setGlobalModalChildren, setGlobalModalOpen } = useContext(GlobalContext);
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    const canAllPM = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const canAllTags = checkPerm([
        {
            permissionId: '33',
            ermissionType: 'ALL',
        },
    ]);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();

    const optionClass = 'text-lg text-main-text font-bold flex items-center';

    const serviceMapper = ['SERVICES', 'FIZIC', 'DIGITAL'];
    const [unitList, setUnitList] = useState([
        t('Hours'),
        t('Days'),
        t('Piece'),
        'Kg',
        t('Linear meter'),
        t('Square meter'),
        t('Add new UM'),
    ]);

    const [variableUnitList, setVariableUnitList] = useState([
        t('Hours'),
        t('Days'),
        t('Piece'),
        'Kg',
        t('Linear meter'),
        t('Square meter'),
        t('Add new UM'),
    ]);

    /**
     *  State for the budgetary component
     */
    const [marja, setMarja] = useState(0);
    // const [expensesMath, setExpensesMath] = useState({});

    /**
     * This is the state to hold the array of the selected expenses by the user
     * and is the state that will be use in frontend to hold all data about selected expenses
     */
    const [selectedExpensesFromFront, setSelectedExpensesFromFront] = useState([]);

    /**
     * Function for expenses
     */

    const formatExpensesForTheRequest = () => {
        const finishedArray = [];

        for (const expense of selectedExpensesFromFront) {
            for (const parentExpense of expense.parentExpenses) {
                if (parentExpense.childrenExpenses.length !== 0) {
                    for (const childExpense of parentExpense.childrenExpenses) {
                        finishedArray.push({
                            expenseNameId: childExpense.id,
                            value: childExpense.value,
                        });
                    }
                } else {
                    finishedArray.push({
                        expenseNameId: parentExpense.id,
                        value: parentExpense.value,
                    });
                }
            }
        }

        return finishedArray;
    };

    /** Functions for calculation of VAT and finale price with VAT  */
    const variablesPrice = vars?.map((v) => Number(v.price))?.reduce((a, b) => a + b, 0);
    const totalPrice = Number(priceWithoutTVA) + variablesPrice;
    const totalVatValue =
        Number(calculateVATValue(priceWithoutTVA, tvaPercent)) +
        vars?.map((v) => Number(calculateVATValue(v.price, v.priceTVA)))?.reduce((a, b) => a + b, 0);

    useEffect(() => {
        if (um == unitList.length - 1) {
            setUM(null);
            setNewUmField(!newUmField);
        }
    }, [um]);

    let schema = yup.object().shape({
        tvaPercent: yup
            .number()
            .typeError(t('The VAT rate of the article is mandatory!'))
            .required(t('The VAT rate of the article is mandatory!'))
            .min(0, t('The VAT percentage of the article must be greater than or equal to 0%')),
        priceWithoutTVA: yup
            .number()
            .typeError(t('Article price required!'))
            .required(t('Article price required!'))
            .min(0, t('Article price must be greater than or equal to 0')),
        um: yup
            .number()
            .typeError(t('You must choose a unit of measure for the article!'))
            .required(t('You must choose a unit of measure for the article!')),
        articleCode: yup
            .string()
            .trim()
            .typeError(t('Article SKU is required!'))
            .required(t('Article SKU is required!'))
            .min(3, t('Article SKU must be at least 3 characters long!')),
        articleName: yup
            .string()
            .trim()
            .typeError(t('Article name required!'))
            .required(t('Article name required!'))
            .min(3, t('The article name must be at least 3 characters long!')),
        selectedCategory: yup
            .array()
            .min(1, t('You must choose at least one category for the article!'))
            .required(t('Choosing the category for the article is mandatory!')),
        selectOption: yup
            .number()
            .typeError(t('You have to choose the type of article!'))
            .required(t('You have to choose the type of article!')),
        vars: yup.array().of(
            yup.object({
                priceTVA: yup
                    .number()
                    .typeError(t('The VAT rate of the variable is mandatory!'))
                    .required(t('The VAT rate of the variable is mandatory!'))
                    .min(0, t('The VAT percentage of the variable must be greater than or equal to 0%')),
                price: yup
                    .number()
                    .typeError(t('Variable price is required!'))
                    .required(t('Variable price is required!'))
                    .min(0, t('Variable price must be greater than or equal to 0')),
                unit: yup
                    .number()
                    .typeError(t('You must choose a unit of measure for the variable!'))
                    .required(t('You must choose a unit of measure for the variable!')),
                code: yup
                    .string()
                    .trim()
                    .typeError(t('Variable SKU is required!'))
                    .required(t('Variable SKU is required!'))
                    .min(3, t('Variable SKU must be at least 3 characters long!')),
                name: yup
                    .string()
                    .trim()
                    .typeError(t('Variable name required!'))
                    .required(t('Variable name required!'))
                    .min(3, t('The variable name must be at least 3 characters long!')),
                type: yup
                    .number()
                    .typeError(t('You have to choose a price type for the variable!'))
                    .required(t('You have to choose a price type for the variable!')),
            })
        ),
        marja: yup
            .number()
            .typeError(t('The cost of the margin is mandatory!'))
            .required(t('The cost of the margin is mandatory!')),
        // expenses: yup
        //     .array()
        //     .min(1, t('The article budget must have at least one expense!'))
        //     .of(
        //         yup.object().shape({
        //             parentExpenses: yup.array().min(1, t('Every expense must have at least one parent expense!')),
        //         })
        //     ),
        revenues: yup.array().min(1, t('The article must have at least one revenue!')),
    });

    const getExchangeRate = async (e) => {
        try {
            let selectedDate = new Date(e);
            if (selectedDate > new Date()) {
                enqueueSnackbar(t("The selected date can't be greater then the current date!"), {
                    variant: 'error',
                });
                selectedDate = new Date();
            }

            const res = await API.get('currencyByDate', {
                params: {
                    date: selectedDate,
                },
            });

            const { rates } = res.data.data;

            setExchangeDate(selectedDate);
            setExchangeRate(rates);
        } catch (error) {
            console.error(error);
            throw 'Error fetching exchange rate';
        }
    };

    const getCategories = async () => {
        try {
            const response = await API.get('/categories');
            return response.data.getCategory;
        } catch (err) {
            console.error(err);
        }
    };

    const getAllRevenues = async () => {
        let revenues = [];

        await Revenues.get(999999, 0).then((res) => {
            if (res.ok) {
                revenues = res.data.revenuName;
            } else {
                console.error(res.error);
                throw 'Error fetching revenues';
            }
        });

        return revenues;
    };

    const getArticleById = async () => {
        try {
            const response = await API.get(`article/${id}`);
            return response.data.article;
        } catch (error) {
            console.error(error);
            throw 'Error fetching article';
        }
    };

    const getAllAccountingAccounts = async () => {
        let account = [];

        await AccountingAccounts.getAll().then((res) => {
            if (res.ok) {
                account = res.data;
            } else {
                console.error(res.error);
                enqueueSnackbar(
                    errorHandling(res.error).length > 100 ? errorHandling(res.error) : t(errorHandling(res.error)),
                    {
                        variant: 'error',
                    }
                );
                throw 'Error fetching accounting accounts';
            }
        });

        return account;
    };

    const allTags = categories.map((c) => ({
        id: c.id,
        name: c.name,
        value: c.id,
    }));

    const defaultVar = {
        ordine: vars.length + 1,
        name: '',
        code: '',
        description: '',
        type: 0,
        percent: null,
        price: null,
        priceTVA: companyVat,
        valueTVA: '',
        priceFinal: '',
        unit: null,
    };

    /** This function edit the vars array with the values from variables component */
    const editVariable = (index, key, value) => {
        const newVars = [...vars]; // new Array(vars)
        newVars[index][key] = value;
        setVars(newVars);
    };

    const deleteVar = (index) => {
        setVars(vars.filter((_, i) => i !== index));
    };

    const formatRevenuesForRequest = (array) => {
        return array.flatMap((el) => {
            return el.list.map((e) => ({
                grandParentId: el.grandParentId,
                value: e.value,
                secondCurrencyValue: e.secondCurrencyValue,
                revenueNameId: e.id,
            }));
        });
    };

    const addArticle = async () => {
        try {
            setLoading(true);
            setInitializeData?.((prev) => ({ ...prev, loading: true }));

            await schema.validate({
                tvaPercent,
                priceWithoutTVA,
                um,
                articleCode,
                articleName,
                selectedCategory,
                selectOption,
                vars,
                marja: marja,
                // expenses: selectedExpensesFromFront,
                revenues: checkedRevenues.flatMap((revenueGroup) => revenueGroup.list),
            });

            try {
                await API.post('/articles', {
                    data: {
                        name: articleName,
                        description: articleDescription,
                        sku: articleCode,
                        type: serviceMapper[selectOption],
                        categories: selectedCategory.map((c) => c.id),
                        price: {
                            unit: unitList[um],
                            pricePerUnit: priceWithoutTVA,
                            procentualVAT: tvaPercent,
                        },
                        Budget: {
                            margin: marja,
                            expenses: formatExpensesForTheRequest(),
                        },
                        ArticleRevenues: formatRevenuesForRequest(checkedRevenues),
                        exchangeRate: exchangeRate,
                        exchangeDate: exchangeDate,
                        options: vars.map((v) => ({
                            order: v.ordine,
                            name: v.name,
                            sku: v.code,
                            description: v.description,
                            price: {
                                type:
                                    v.type === 0
                                        ? 'SIMPLE'
                                        : v.type === 1
                                        ? 'BASE_PROCENT'
                                        : v.type === 2
                                        ? 'BASE_PROCENT_AND_VARIABLE'
                                        : null,
                                priceProcent: Number(v.percent),
                                unit: variableUnitList[v.unit],
                                pricePerUnit: v.price,
                                procentualVAT: v.priceTVA,
                            },
                        })),
                    },
                    pm_planningOverviewProjectId: selectedProject?.id ?? undefined,
                });
                enqueueSnackbar(t('Article was successfully created!'), { variant: 'success' });

                if (setInitializeData) setInitializeData((prev) => ({ ...prev, tab: 0 }));
                else history.push(quotes.base + quotes.nomenclature.base + quotes.nomenclature.read);
            } catch (err) {
                console.error(t('Article could not be created! Try again!'));
                throw err;
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        } finally {
            setLoading(false);
            setInitializeData?.((prev) => ({ ...prev, loading: false }));
        }
    };

    /**
     * We need to adapt the format of the options objects recive in the api response
     * filter the array base on the order value
     * @data is a list of objects
     */

    const formatVarsFromResponse = (data, n) => {
        return data
            .map((o) => ({
                description: o.description,
                name: o.name,
                code: o.sku,
                ordine: o.order,
                percent: o.price.priceProcent,
                price: o.price.pricePerUnit,
                priceTVA: o.price.procentualVAT,
                valueTVA: calculateVATValue(o.price.pricePerUnit, o.price.procentualVAT),
                priceFinal: calculateTotal(
                    calculateVATValue(o.price.pricePerUnit, o.price.procentualVAT),
                    o.price.pricePerUnit
                ),
                unit: n.indexOf(o.price.unit),
                type:
                    o.price.type === 'SIMPLE'
                        ? 0
                        : o.price.type === 'BASE_PROCENT'
                        ? 1
                        : o.price.type === 'BASE_PROCENT_AND_VARIABLE'
                        ? 2
                        : null,
            }))
            .sort((f, s) => f.ordine - s.ordine);
    };

    const formatRevenuesFromRequest = (array, responseRevenues) => {
        return array.reduce((acc, e) => {
            // ? We try to find if the grand parent of the expense or revenue already exists
            const grandParentIdIndex = acc.findIndex((accEl) => accEl.grandParentId === e.grandParentId);

            // ? If the grand parent exists we push the new expense or revenue into the grand parent list
            if (grandParentIdIndex >= 0) {
                const newAcc = structuredClone(acc);
                newAcc[grandParentIdIndex].list.push({
                    value: e.value,
                    secondCurrencyValue: e.secondCurrencyValue,
                    id: e.revenueNameId,
                });

                return newAcc;
            }

            // ? If the grand parent does't exists we create a new entry in the array for him and the respective expense or revenue
            return [
                ...acc,
                {
                    selectionIndex: responseRevenues.findIndex((el) => el.id === e.grandParentId),
                    grandParentId: e.grandParentId,
                    list: [
                        {
                            value: e.value,
                            secondCurrencyValue: e.secondCurrencyValue,
                            id: e.revenueNameId,
                        },
                    ],
                },
            ];
        }, []);
    };

    const getInitialDate = async () => {
        try {
            setLoading(true);

            const [vatResponse, articleResponse, revenuesResponse, categoriesResponse, accountsResponse] =
                await Promise.all([
                    getCompanyVAT(),
                    id ? getArticleById() : getExchangeRate(new Date()),
                    getAllRevenues(),
                    getCategories(),
                    getAllAccountingAccounts(),
                ]);

            setCompanyVat(vatResponse);
            setAllRevenues(revenuesResponse);
            setCategories(categoriesResponse);
            setAccounts(accountsResponse);

            if (!id) {
                setTvaPercent(vatResponse);
                const nrArticles = await API.get('/nrArticles');
                setArticleCode('SKU-' + nrArticles.data.count);
                return;
            }

            setArticle(articleResponse);
            const toAddUM = [];

            for (const option of articleResponse.options) {
                if (variableUnitList.indexOf(option.price.unit) === -1) toAddUM.push(option.price.unit);
            }

            const currentUM = [...variableUnitList];
            const lastItem = currentUM.pop(); // The last element of the current list of um is special; Will be use to add new custom um's;
            const newUMList = [...currentUM, ...toAddUM, lastItem];
            setVariableUnitList(newUMList);

            setVars(formatVarsFromResponse(articleResponse.options, newUMList));

            setSelectOption(
                serviceMapper.indexOf(articleResponse.type) === -1 ? null : serviceMapper.indexOf(articleResponse.type)
            );
            setSelectedCategory(
                articleResponse.categories.map((c) => {
                    return {
                        id: c.categorie.id,
                        name: c.categorie.name,
                        value: c.categorie.id,
                    };
                })
            );
            setArticleName(articleResponse.name);
            setArticleCode(articleResponse.sku);
            setArticleDescription(articleResponse.description);

            if (unitList.indexOf(articleResponse.price.unit) === -1) {
                const newUnitList = [...unitList];
                const len = newUnitList.length;
                newUnitList[len - 1] = articleResponse.price.unit;
                newUnitList.push(t('Add new UM'));
                setUnitList(newUnitList);
                setUM(newUnitList.indexOf(articleResponse.price.unit));
            } else {
                setUM(unitList.indexOf(articleResponse.price.unit));
            }
            setPriceWithoutTVA(articleResponse.price.pricePerUnit);
            setTvaPercent(articleResponse.price.procentualVAT);
            setMarja(articleResponse.Budget.margin);
            setCheckedRevenues(formatRevenuesFromRequest(articleResponse.ArticleRevenues, revenuesResponse));
            setExchangeDate(articleResponse.exchangeDate);
            setExchangeRate(articleResponse.exchangeRate);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getInitialDate();
    }, [id]);

    const updateArticle = async () => {
        try {
            setLoading(true);

            await schema.validate({
                tvaPercent,
                priceWithoutTVA,
                um,
                articleCode,
                articleName,
                selectedCategory,
                selectOption,
                vars,
                marja: marja,
                // expenses: selectedExpensesFromFront,
                revenues: checkedRevenues.flatMap((revenueGroup) => revenueGroup.list),
            });

            try {
                await API.put('/articles', {
                    data: {
                        name: articleName,
                        description: articleDescription,
                        sku: articleCode,
                        type: serviceMapper[selectOption],
                        priceId: article.priceId,
                        categories: selectedCategory.map((c) => c.id),
                        price: {
                            id: article.priceId,
                            unit: unitList[um],
                            pricePerUnit: priceWithoutTVA,
                            procentualVAT: tvaPercent,
                        },
                        Budget: {
                            margin: marja,
                            expenses: formatExpensesForTheRequest(),
                        },
                        ArticleRevenues: formatRevenuesForRequest(checkedRevenues),
                        exchangeRate: exchangeRate,
                        exchangeDate: exchangeDate,
                        options: vars.map((v) => ({
                            order: v.ordine,
                            name: v.name,
                            sku: v.code,
                            description: v.description,
                            price: {
                                type:
                                    v.type === 0
                                        ? 'SIMPLE'
                                        : v.type === 1
                                        ? 'BASE_PROCENT'
                                        : v.type === 2
                                        ? 'BASE_PROCENT_AND_VARIABLE'
                                        : null,
                                priceProcent: Number(v.percent === undefined ? 0 : v.percent),
                                unit: variableUnitList[v.unit],
                                pricePerUnit: v.price,
                                procentualVAT: v.priceTVA,
                            },
                        })),
                    },
                    id: id,
                    pm_planningOverviewProjectId: selectedProject?.id ?? undefined,
                });
                enqueueSnackbar(t('Article was successfully updated!'), {
                    variant: 'success',
                });
                if (!isStockCase) history.push(quotes.base + quotes.nomenclature.base + quotes.nomenclature.read);
            } catch (err) {
                console.error(t('Article could not be updated! Try again!'));
                throw err;
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleDuplicateArticle = () => {
        setGlobalModalOpen(true);
        setGlobalModalChildren(
            <div className="flex-col">
                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                    {t('Are you sure you want to duplicate this article?')}
                </p>
                <div className="flex items-center justify-center">
                    <Button id="global-yes-button-root" color="primary" onClick={handleDuplicateRequest}>
                        {t('Yes')}
                    </Button>
                    <div className="w-4" />
                    <Button id="global-no-button-root" onClick={() => setGlobalModalOpen(false)}>
                        {t('No')}
                    </Button>
                </div>
            </div>
        );
    };

    const handleDuplicateRequest = async () => {
        try {
            setLoading(true);
            setGlobalModalOpen(false);
            await API.post('duplicateArticle', undefined, {
                params: {
                    id: article.id,
                },
            });
            enqueueSnackbar(t('The article was duplicated successfully!'), { variant: 'success' });
            history.push(quotes.base + quotes.nomenclature.base + quotes.nomenclature.read);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setGlobalModalChildren(null);
            setLoading(false);
        }
    };

    const handleDeleteRequest = async () => {
        try {
            setGlobalModalOpen(false);
            setDisabledParent(true);
            setLoading(true);

            await API.patch('deleteArticle', undefined, {
                params: {
                    id,
                },
            });

            onDeleteArticleCallback(article.id);
            enqueueSnackbar(t('The article was deleted successfully!'), { variant: 'success' });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setGlobalModalChildren(null);
            setLoading(false);
            setDisabledParent(false);
        }
    };

    const handleDeleteArticle = () => {
        setGlobalModalOpen(true);
        setGlobalModalChildren(
            <div className="flex-col">
                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                    {t('Are you sure you want to delete this article?')}
                </p>
                <div className="flex items-center justify-center">
                    <Button id="global-yes-button-root" color="primary" onClick={handleDeleteRequest}>
                        {t('Yes')}
                    </Button>
                    <div className="w-4" />
                    <Button id="global-no-button-root" onClick={() => setGlobalModalOpen(false)}>
                        {t('No')}
                    </Button>
                </div>
            </div>
        );
    };

    const checkedRevenuesList = checkedRevenues.flatMap((revenueGroup) => revenueGroup.list);
    const checkedRevenuesSum = toRoundedSignificantDigits(
        checkedRevenuesList.reduce((sum, current) => (sum += current.value), 0)
    );
    const validRevenueValue = checkedRevenuesSum === priceWithoutTVA;

    return (
        <>
            <div className={`${!setInitializeData && !isStockCase ? 'page-container' : ''}`}>
                {loading ? (
                    <LinearProgress />
                ) : (
                    <Fragment>
                        <div className="grid w-3/4 grid-cols-2 gap-16 2xl:w-full xl:gap-8 lg:grid-cols-1">
                            <div className="w-full">
                                <h4 className="mb-10 text-2xl text-dark-text">{t('Article')}</h4>
                                <div className="mb-5">
                                    <LabelWrapper label={t('Article type')}>
                                        <Dropdown
                                            disabled={!canAll}
                                            options={[t('Service'), t('Physical product'), t('Digital product')]}
                                            placeholder={t('Choose the option')}
                                            selectedOption={selectOption}
                                            setSelectedOption={setSelectOption}
                                        />
                                    </LabelWrapper>
                                </div>

                                {/* Category validation */}
                                {categories.length > 0 && allTags.length > 0 ? (
                                    <div className="relative z-40 mb-5 flex flex-wrap rounded-md border border-layout-transparent px-4 py-1 pb-0">
                                        <p className="mr-2 flex h-10 items-center">{t('Article category')}</p>
                                        <Tags
                                            disabled={!canAll}
                                            tags={selectedCategory}
                                            setTags={setSelectedCategory}
                                            allTags={allTags}
                                        />
                                    </div>
                                ) : (
                                    <div className="mb-5 flex flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                        <p className="mb-5">
                                            {t('There are no categories yet, you have to add one first')}
                                        </p>
                                        <Button
                                            disabled={!canAllTags}
                                            color="primary"
                                            onClick={() =>
                                                history.push(
                                                    quotes.base +
                                                        quotes.nomenclature.base +
                                                        quotes.categories.base +
                                                        quotes.categories.create
                                                )
                                            }
                                        >
                                            {t('Add category')}
                                        </Button>
                                    </div>
                                )}

                                <div className="mb-5">
                                    <TextField
                                        disabled={!canAll}
                                        name="nume articol"
                                        label={t('Article name')}
                                        placeholder={t('Name the article')}
                                        value={articleName}
                                        onChange={(e) => setArticleName(e.target.value)}
                                    />
                                </div>

                                <div className="mb-5">
                                    <TextField
                                        disabled={!canAll}
                                        name="cod articol"
                                        label={t('Article code(SKU)')}
                                        placeholder="ABC1234"
                                        value={articleCode}
                                        onChange={(e) => setArticleCode(e.target.value)}
                                    />
                                </div>

                                <div className="mb-5">
                                    <TextField
                                        disabled={!canAll}
                                        name="descriere"
                                        label={t('Description')}
                                        placeholder={t('This is a description for the article')}
                                        value={articleDescription}
                                        onChange={(e) => setArticleDescription(e.target.value)}
                                        multiline
                                        rows={3}
                                    />
                                </div>

                                {newUmField && (
                                    <div className="mb-5">
                                        <div className="mb-4">
                                            <TextField
                                                disabled={!canAll}
                                                name="new um article"
                                                label={t('New UM')}
                                                placeholder={t('Add new UM')}
                                                value={newUm}
                                                onChange={(e) => setNewUm(e.target.value)}
                                            />
                                        </div>
                                        <Button
                                            disabled={!canAll}
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                const newUnitList = [...unitList];
                                                const listLen = newUnitList.length;
                                                if (newUm == null) {
                                                    setNewUmField(!newUmField);
                                                    return;
                                                } else {
                                                    newUnitList[listLen - 1] = newUm;
                                                }
                                                newUnitList.push(t('Add new UM'));
                                                setUnitList(newUnitList);
                                                setNewUm('');
                                                setNewUmField(false);
                                                setUM(newUnitList.indexOf(newUm));
                                            }}
                                        >
                                            {t('Add new UM')}
                                        </Button>
                                    </div>
                                )}

                                <div className="mb-5 flex gap-4 sm:flex-col">
                                    <div className="flex-1">
                                        <LabelWrapper label="UM">
                                            <Dropdown
                                                disabled={!canAll}
                                                options={unitList}
                                                placeholder={t('Choose UM')}
                                                selectedOption={um}
                                                setSelectedOption={(newUm) => setUM(newUm)}
                                            />
                                        </LabelWrapper>
                                    </div>

                                    <div className="flex w-full flex-row gap-2 xl:flex-col">
                                        <div className="flex-1">
                                            <LocaleTextField
                                                disabled={!canAll}
                                                name="price without TVA"
                                                label={t('Price without VAT')}
                                                placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                                                value={priceWithoutTVA}
                                                onChange={(e) =>
                                                    setPriceWithoutTVA(formatPositiveNumberWithDigits(e.target.value))
                                                }
                                            />
                                        </div>

                                        <div className="flex-1">
                                            <LocaleTextField
                                                disabled={!canAll}
                                                name="cota TVA"
                                                label={t('VAT rate')}
                                                placeholder="19%"
                                                value={tvaPercent}
                                                onChange={(e) => setTvaPercent(formatVATnumber(e.target.value))}
                                            />
                                        </div>

                                        <div className="flex-1">
                                            <LabelWrapper label={t('VAT value')}>
                                                <div className="h-10 cursor-not-allowed rounded-md bg-layout-transparent p-3 font-bold text-main-text">
                                                    <p className="font-bold text-main-text">
                                                        {isNaN(calculateVATValue(priceWithoutTVA, tvaPercent))
                                                            ? toLocaleNumber(0, 2)
                                                            : toLocaleNumber(
                                                                  calculateVATValue(priceWithoutTVA, tvaPercent),
                                                                  2
                                                              )}{' '}
                                                        {currencyObj.currency}
                                                    </p>
                                                </div>
                                            </LabelWrapper>
                                        </div>

                                        <div className="flex-1">
                                            <LabelWrapper label={t('Final price with VAT')}>
                                                <div className="h-10 cursor-not-allowed rounded-md bg-layout-transparent p-3">
                                                    <p className="font-bold text-main-text">
                                                        {isNaN(
                                                            calculateTotal(
                                                                calculateVATValue(priceWithoutTVA, tvaPercent),
                                                                priceWithoutTVA
                                                            )
                                                        )
                                                            ? toLocaleNumber(0, 2)
                                                            : toLocaleNumber(
                                                                  calculateTotal(
                                                                      calculateVATValue(priceWithoutTVA, tvaPercent),
                                                                      priceWithoutTVA
                                                                  ),
                                                                  2
                                                              )}{' '}
                                                        {currencyObj.currency}
                                                    </p>
                                                </div>
                                            </LabelWrapper>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-9">
                                    <h4 className="mb-10 mt-14 text-2xl text-dark-text">{t('Variables')}</h4>
                                    {vars.map((v, index) => (
                                        <div key={v.id}>
                                            <Variable
                                                disabled={!canAll}
                                                variable={v}
                                                editVariable={(key, value) => editVariable(index, key, value)}
                                                deleteVariable={() => deleteVar(index)}
                                                articlePrice={priceWithoutTVA}
                                                vars={vars}
                                                unitList={variableUnitList}
                                                setUnitList={setVariableUnitList}
                                            />
                                        </div>
                                    ))}
                                </div>

                                {vars?.findIndex((v) => v.type === 2) === -1 ? (
                                    <Button
                                        disabled={!canAll}
                                        startIcon={<AddIcon />}
                                        onClick={() => setVars([...vars, defaultVar])}
                                    >
                                        {t('Add new variable')}
                                    </Button>
                                ) : (
                                    <p className="mb-10 mt-10 text-lg text-error">
                                        {t(
                                            'Only one variable can be added, the price of which is a percentage of the whole item, and it is not allowed to add another type of variable!'
                                        )}
                                    </p>
                                )}
                            </div>

                            <div className="w-10/12 2xl:w-full">
                                {article?.stockId && (
                                    <OfferArticleHistory
                                        article={article}
                                        style={{
                                            borderRadius: 'unset',
                                            backgroundColor: 'var(--layout-transparent)',
                                        }}
                                    />
                                )}
                                <h3 className="mt-10 bg-layout-transparent p-7 text-2xl font-bold">{t('Summary')}</h3>
                                <div>
                                    {/* 
                                Summary details
                            */}
                                    <div
                                        className={`border-b-2 border-layout-transparent-dark bg-layout-transparent ${
                                            articleName ? 'p-7 pt-0' : ''
                                        }`}
                                    >
                                        <h4 className="mb-4 text-xl">{articleName}</h4>
                                        {articleName ? (
                                            <div className="grid grid-cols-4 gap-2  pb-4 xl:grid-cols-2">
                                                <LabelWrapper label={t('Price without VAT')} noPadding>
                                                    <p className={optionClass}>
                                                        {priceWithoutTVA
                                                            ? toLocaleNumber(priceWithoutTVA, 2)
                                                            : toLocaleNumber(0, 2)}{' '}
                                                        {currencyObj.currency}
                                                    </p>
                                                </LabelWrapper>

                                                <LabelWrapper label={t('VAT rate')} noPadding>
                                                    <p className={optionClass}>
                                                        {tvaPercent
                                                            ? toLocaleNumber(tvaPercent, 2)
                                                            : toLocaleNumber(0, 2)}{' '}
                                                        %
                                                    </p>
                                                </LabelWrapper>

                                                <LabelWrapper label={t('VAT value')} noPadding>
                                                    <p className={optionClass}>
                                                        {isNaN(calculateVATValue(priceWithoutTVA, tvaPercent))
                                                            ? toLocaleNumber(0, 2)
                                                            : toLocaleNumber(
                                                                  calculateVATValue(priceWithoutTVA, tvaPercent),
                                                                  2
                                                              )}{' '}
                                                        {currencyObj.currency}
                                                    </p>
                                                </LabelWrapper>

                                                <LabelWrapper label={t('Final price with VAT')} noPadding>
                                                    <p className={optionClass}>
                                                        {isNaN(
                                                            calculateTotal(
                                                                calculateVATValue(priceWithoutTVA, tvaPercent),
                                                                priceWithoutTVA
                                                            )
                                                        )
                                                            ? toLocaleNumber(0, 2)
                                                            : toLocaleNumber(
                                                                  calculateTotal(
                                                                      calculateVATValue(priceWithoutTVA, tvaPercent),
                                                                      priceWithoutTVA
                                                                  ),
                                                                  2
                                                              )}{' '}
                                                        {currencyObj.currency}
                                                    </p>
                                                </LabelWrapper>
                                            </div>
                                        ) : null}
                                    </div>

                                    {/* 
                                Variable List
                            */}
                                    {vars.map((v) => (
                                        <>
                                            {v.name ? (
                                                <div
                                                    key={v.id}
                                                    className="border-b-2 border-layout-transparent-dark bg-layout-transparent p-7 transition-colors hover:bg-layout-transparent-dark"
                                                >
                                                    <h4 className="mb-4 text-xl">{v.name}</h4>
                                                    <div className="grid grid-cols-4 gap-2 pb-4 xl:grid-cols-2">
                                                        <LabelWrapper label={t('Price without VAT')} noPadding>
                                                            <p className={optionClass}>
                                                                {v.price
                                                                    ? toLocaleNumber(v.price, 2)
                                                                    : toLocaleNumber(0, 2)}{' '}
                                                                {currencyObj.currency}
                                                            </p>
                                                        </LabelWrapper>

                                                        <LabelWrapper label={t('VAT rate')} noPadding>
                                                            <p className={optionClass}>
                                                                {v.priceTVA
                                                                    ? toLocaleNumber(v.priceTVA, 2)
                                                                    : toLocaleNumber(0, 2)}{' '}
                                                                %
                                                            </p>
                                                        </LabelWrapper>

                                                        <LabelWrapper label={t('VAT value')} noPadding>
                                                            <p className={optionClass}>
                                                                {toLocaleNumber(
                                                                    calculateVATValue(v.price, v.priceTVA),
                                                                    2
                                                                )}{' '}
                                                                {currencyObj.currency}
                                                            </p>
                                                        </LabelWrapper>

                                                        <LabelWrapper label={t('Final price with VAT')} noPadding>
                                                            <p className={optionClass}>
                                                                {toLocaleNumber(
                                                                    calculateTotal(
                                                                        calculateVATValue(v.price, v.priceTVA),
                                                                        v.price
                                                                    ),
                                                                    2
                                                                )}{' '}
                                                                {currencyObj.currency}
                                                            </p>
                                                        </LabelWrapper>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </>
                                    ))}

                                    {/* 
                                Conclusion
                            */}
                                    <div className="bg-layout-transparent p-7">
                                        <h4 className="mb-4 text-xl">{t('Price with all variables')}</h4>
                                        <div className="grid grid-cols-3 gap-2 lg:grid-cols-1">
                                            <div>
                                                <LabelWrapper label={t('Price without VAT')} noPadding>
                                                    <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                        {isNaN(totalPrice)
                                                            ? toLocaleNumber(0, 2)
                                                            : toLocaleNumber(totalPrice, 2)}{' '}
                                                        {currencyObj.currency}
                                                    </div>
                                                </LabelWrapper>
                                            </div>

                                            <div>
                                                <LabelWrapper label={t('VAT value')} noPadding>
                                                    <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                        {isNaN(totalVatValue)
                                                            ? toLocaleNumber(0, 2)
                                                            : toLocaleNumber(totalVatValue, 2)}{' '}
                                                        {currencyObj.currency}
                                                    </div>
                                                </LabelWrapper>
                                            </div>

                                            <div>
                                                <LabelWrapper label={t('Final price with VAT')} noPadding>
                                                    <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                        {isNaN(totalPrice + totalVatValue)
                                                            ? toLocaleNumber(0, 2)
                                                            : toLocaleNumber(totalPrice + totalVatValue, 2)}{' '}
                                                        {currencyObj.currency}
                                                    </div>
                                                </LabelWrapper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {canAllPM && (
                            <ProjectSelection
                                disabled={!canAll}
                                title={t('Assign the article on a project')}
                                label={t('Select an option only if you want the article to be on a project')}
                                entityId={id}
                                pmProjectId={article?.pm_planningOverviewProjectId ?? null}
                                style={{
                                    containerStyle: { marginTop: '3.5rem' },
                                    headerStyle: {
                                        color: 'var(--text-main)',
                                        fontSize: '1.5rem',
                                        lineHeight: '2rem',
                                        fontWeight: 700,
                                        marginBottom: 0,
                                    },
                                    headerType: 'h4',
                                }}
                                selectedProject={selectedProject}
                                setSelectedProject={setSelectedProject}
                            />
                        )}

                        <div className="mt-24 flex w-full flex-col gap-4 rounded-md border border-secondary-light px-8 py-6">
                            <div className="inline-flex max-w-lg gap-2 rounded-md bg-layout-transparent p-4 sm:flex-col">
                                <div className="inline-block">
                                    <LabelWrapper label={t('Select the date for the exchange rate')}>
                                        <DatePicker
                                            disabled={loading || !canAll}
                                            date={exchangeDate}
                                            setDate={getExchangeRate}
                                        />
                                    </LabelWrapper>
                                </div>

                                <div className="inline-block">
                                    <LabelWrapper label={t('Exchange rate')}>
                                        <LocaleTextField
                                            name="exchangeRate"
                                            disabled={loading || !canAll}
                                            placeholder={`${toLocaleNumber(0, 4, 4)}`}
                                            value={exchangeRate}
                                            onChange={(e) => setExchangeRate(e.target.value)}
                                            minDecimals={4}
                                            maxDecimals={4}
                                            className="sm:w-full"
                                        />
                                    </LabelWrapper>
                                </div>
                            </div>

                            <UtilityRevenueBudget
                                checkedRevenues={checkedRevenues}
                                setCheckedRevenues={setCheckedRevenues}
                                exchangeRate={exchangeRate}
                                disableInputs={loading || !canAll}
                                isEdit={!canAll}
                                allRevenues={allRevenues}
                                accounts={accounts}
                                style={{
                                    marginTop: '2.5rem',
                                    marginBottom: 0,
                                }}
                            />

                            {checkedRevenuesList.length > 0 && (
                                <h4 className={`text-xl ${validRevenueValue ? 'text-success' : 'text-error'}`}>
                                    {`${t('The value of the article')}: ${toLocaleNumber(priceWithoutTVA, 2, 2)} ${
                                        currencyObj.currency
                                    } ${t(
                                        'must be equal to the sum of values of the checked revenues'
                                    )}: ${toLocaleNumber(checkedRevenuesSum, 2, 2)} ${currencyObj.currency}.`}
                                </h4>
                            )}
                        </div>

                        {/*
                        Budgetary component
                        IMPORTANT: initialExpenses have to be correct on the first render of the component
                        Otherwise it may not work properly, and break
                        */}

                        <div className="mt-24">
                            {/* Editing the budget */}
                            {id && Boolean(article) && (
                                <Budgetary
                                    disabled={!canAll}
                                    margin={marja}
                                    setMargin={setMarja}
                                    initialExpenses={article.Budget}
                                    selectedExpensesFromFront={selectedExpensesFromFront}
                                    setSelectedExpensesFromFront={setSelectedExpensesFromFront}
                                />
                            )}

                            {/* Creating the budget */}
                            {!id && (
                                <Budgetary
                                    disabled={!canAll}
                                    margin={marja}
                                    setMargin={setMarja}
                                    initialExpenses={{}}
                                    selectedExpensesFromFront={selectedExpensesFromFront}
                                    setSelectedExpensesFromFront={setSelectedExpensesFromFront}
                                />
                            )}
                        </div>

                        {/* 
                        End of Budgetary component
                    */}

                        <div className="mt-28 flex flex-wrap gap-4">
                            {id ? (
                                <Button
                                    disabled={!canAll || !validRevenueValue}
                                    size="large"
                                    color="secondary"
                                    startIcon={<CheckIcon />}
                                    onClick={updateArticle}
                                >
                                    {t('Update article')}
                                </Button>
                            ) : (
                                <Button
                                    disabled={!canAll || !validRevenueValue}
                                    size="large"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    onClick={addArticle}
                                >
                                    {t('Add the article')}
                                </Button>
                            )}

                            {id && !isStockCase && (
                                <>
                                    <Button
                                        disabled={!canAll || !validRevenueValue}
                                        color="secondary"
                                        startIcon={<ListAltIcon style={{ fontSize: 25 }} />}
                                        onClick={() => setIsOpenStockModal(true)}
                                    >
                                        {t('Create stock')}
                                    </Button>

                                    <Button
                                        color="secondary"
                                        startIcon={<FileCopyIcon style={{ fontSize: 25 }} />}
                                        onClick={handleDuplicateArticle}
                                    >
                                        {t('Duplicate article')}
                                    </Button>
                                </>
                            )}

                            {isStockCase && (
                                <ErrorButton
                                    disabled={!canAll}
                                    size="large"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleDeleteArticle}
                                >
                                    {t('Delete article')}
                                </ErrorButton>
                            )}
                        </div>
                    </Fragment>
                )}
            </div>
            <CustomModal open={isOpenStockModal}>
                <CreateStockModal onClose={() => setIsOpenStockModal(false)} articleId={id} />
            </CustomModal>
        </>
    );
};

ArticleForm.propTypes = {
    articleId: PropTypes.string,
    setInitializeData: PropTypes.func,
    isStockCase: PropTypes.bool,
    onDeleteArticleCallback: PropTypes.func,
    setDisabledParent: PropTypes.func,
};

ArticleForm.defaultValues = {
    articleId: null,
    setInitializeData: null,
    isStockCase: false,
    onDeleteArticleCallback: null,
    setDisabledParent: null,
};

export default ArticleForm;
