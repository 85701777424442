import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { quotes } from 'routes';

import CreateVariations from './create-variation';
import EditVariations from './edit-variations';
import ManageVariations from './manage-variations';

const Variations = () => {
    return (
        <>
            <Switch>
                <Route exact path={quotes.base + quotes.variations.base + '/' + ':articleId'}>
                    <ManageVariations />
                </Route>

                <Route path={quotes.base + quotes.variations.base + quotes.variations.create + '/' + ':articleId'}>
                    <CreateVariations />
                </Route>

                <Route path={quotes.base + quotes.variations.base + quotes.variations.updateFull + '/' + ':articleId'}>
                    <EditVariations />
                </Route>
            </Switch>
        </>
    );
};

export default Variations;
