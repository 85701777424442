import React, { useRef, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MessageIcon from '@material-ui/icons/Message';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import BasicTooltip from 'components/shared/basic-tooltip';
import FileUploadContainer from 'components/shared/file-upload-container';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { uploadMultiFiles, uploadSingleFile } from 'utils';

const PMAddWikiComment = (props) => {
    const { onSubmit, fileIconView, getWikis, createNew, setCreateNew } = props;

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const inputRef = useRef(null);

    const [newComment, setNewComment] = useState('');
    const [newCommentFiles, setNewCommentFiles] = useState([]);

    return (
        <div className="relative">
            <div className="flex items-center gap-10">
                <div
                    className={`group relative z-10 flex cursor-pointer items-start text-secondary-light ${
                        createNew.open ? 'hidden' : 'flex'
                    }`}
                    onClick={() => {
                        setCreateNew({ type: 'comment', open: true });
                        if (inputRef.current) {
                            setTimeout(() => {
                                inputRef.current.focus();
                            }, 10);
                        }
                    }}
                >
                    <MessageIcon className="group-hover:text-secondary-lighter" />
                    <p className="ml-2 font-semibold text-secondary-light opacity-100 group-hover:text-secondary-lighter">
                        {t('Add comment')}
                    </p>
                </div>

                <div
                    className={`group relative z-10 flex cursor-pointer items-start text-secondary-light ${
                        createNew.open ? 'hidden' : 'flex'
                    }`}
                    onClick={() => {
                        setCreateNew({ type: 'document', open: true });
                    }}
                >
                    <NoteAddIcon className="group-hover:text-secondary-lighter" />
                    <p className="ml-2 font-semibold text-secondary-light opacity-100 group-hover:text-secondary-lighter">
                        {t('Add document/s')}
                    </p>
                </div>
            </div>

            <div
                className={`flex flex-col gap-6 rounded-md bg-layout-transparent-dark p-3 ${
                    createNew.open ? 'flex' : 'hidden'
                }`}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();

                        if (newComment.trim().length <= 0 || !createNew.open) return;

                        // Do the main stuff
                        onSubmit(newComment, newCommentFiles);
                        getWikis();

                        // Cleanup
                        setCreateNew({ type: null, open: false });
                        setNewComment('');
                        setNewCommentFiles([]);
                    }}
                    className="relative z-10 flex items-center justify-between rounded-br-md rounded-tr-md bg-layout-transparent py-1 pl-4 pr-1 transition-colors"
                    style={{
                        borderLeft: '1px solid var(--secondary-lighter)',
                    }}
                >
                    {createNew.type === 'comment' && (
                        <input
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder={`${t('Add comment')}*`}
                            className="outline-none w-full border-none  bg-transparent placeholder-dark-text placeholder-opacity-50"
                            autoComplete="off"
                            ref={inputRef}
                        />
                    )}

                    {createNew.type === 'document' && (
                        <textarea
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder={t('Document/s description')}
                            className={`outline-none w-full border-none  bg-transparent placeholder-dark-text placeholder-opacity-50`}
                            autoComplete="off"
                            ref={inputRef}
                            rows={5}
                        />
                    )}

                    {newComment.length > 0 && createNew.type === 'comment' && (
                        <BasicTooltip tip={t('Add comment')}>
                            <button
                                type="submit"
                                className="flex h-8 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-sm bg-secondary-lighter transition-all hover:bg-secondary-light"
                            >
                                <CheckIcon className="text-main-main" style={{ fontSize: '1rem' }} />
                            </button>
                        </BasicTooltip>
                    )}

                    {createNew.type === 'comment' && (
                        <BasicTooltip tip={t('Cancel adding comment')} position="left">
                            <div
                                className=" ml-1 flex h-8 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-sm bg-layout-transparent hover:bg-layout-transparent-dark"
                                onClick={() => {
                                    setNewComment('');
                                    setCreateNew({ type: null, open: false });
                                    setNewCommentFiles([]);
                                }}
                            >
                                <CloseIcon className="text-secondary-lighter" style={{ fontSize: '1rem' }} />
                            </div>
                        </BasicTooltip>
                    )}
                </form>

                {/* 
                    Uploaded Files Container
                */}
                {fileIconView && (
                    <div className="flex flex-col gap-6">
                        {newCommentFiles.length > 0 && (
                            <div className="flex flex-col gap-2">
                                <div className="relative z-20 flex flex-col gap-1">
                                    {newCommentFiles.map((file) => (
                                        <div
                                            key={file.id}
                                            className="flex items-center justify-between py-1"
                                            style={{
                                                borderBottom: '1px solid rgb(var(--base-layout-light) / 50%)',
                                            }}
                                        >
                                            <p className="text-medium mr-2 cursor-pointer py-1 opacity-75 transition-opacity hover:opacity-100">
                                                {file.info.name}
                                            </p>
                                            <div
                                                className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-md hover:bg-layout-transparent"
                                                onClick={() =>
                                                    setNewCommentFiles(newCommentFiles.filter((f) => f.id !== file.id))
                                                }
                                            >
                                                <DeleteIcon
                                                    className="text-secondary-lighter hover:text-secondary-light"
                                                    style={{ fontSize: '1.5rem' }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* 
                    Tools Container 
                */}
                        <div className="flex items-center justify-between">
                            <div className="relative z-30 flex items-center ">
                                <FileUploadContainer
                                    onUpload={(e) => {
                                        if (createNew.type === 'comment') {
                                            uploadSingleFile(
                                                e,
                                                ({ message, blob, info }) => {
                                                    if (message) {
                                                        enqueueSnackbar(message, {
                                                            variant: 'error',
                                                        });
                                                    } else if (blob && info) {
                                                        setNewCommentFiles((prev) => [
                                                            ...prev,
                                                            {
                                                                id: Math.random(),
                                                                blob,
                                                                info,
                                                            },
                                                        ]);
                                                    }
                                                },
                                                'all'
                                            );
                                        }

                                        if (createNew.type === 'document') {
                                            uploadMultiFiles(
                                                e,
                                                ({ message, blob, info }) => {
                                                    if (message) {
                                                        enqueueSnackbar(message, {
                                                            variant: 'error',
                                                        });
                                                    } else if (blob && info) {
                                                        const newFiles = [];

                                                        for (let i = 0; i < blob.length; i++) {
                                                            newFiles.push({
                                                                id: Math.random(),
                                                                blob: blob[i],
                                                                info: info[i],
                                                            });
                                                        }

                                                        setNewCommentFiles((prev) => [...prev, ...newFiles]);
                                                    }
                                                },
                                                'all'
                                            );
                                        }
                                    }}
                                >
                                    <div className="group flex cursor-pointer items-center gap-1">
                                        {createNew.type === 'comment' && (
                                            <InsertDriveFileIcon
                                                className="text-secondary-lighter transition-colors group-hover:text-secondary-light"
                                                style={{ fontSize: '1.5rem' }}
                                            />
                                        )}
                                        {createNew.type === 'document' && (
                                            <AddIcon
                                                className="text-secondary-lighter transition-colors group-hover:text-secondary-light"
                                                style={{ fontSize: '1.5rem' }}
                                            />
                                        )}

                                        <p className="opacity-75 group-hover:opacity-100">
                                            {createNew.type === 'comment' && t('Attach file')}
                                            {createNew.type === 'document' && `${t('Upload document/s')}*`}
                                        </p>
                                    </div>
                                </FileUploadContainer>
                            </div>

                            {createNew.type === 'document' && (
                                <div className="flex items-center">
                                    {newCommentFiles.length > 0 && (
                                        <BasicTooltip tip={t('Add document')}>
                                            <button
                                                onClick={() => {
                                                    if (!newCommentFiles.length || !createNew.open) return;

                                                    // Do the main stuff
                                                    onSubmit(newComment, newCommentFiles);
                                                    getWikis();

                                                    // Cleanup
                                                    setNewComment('');
                                                    setCreateNew({ type: null, open: false });
                                                    setNewCommentFiles([]);
                                                }}
                                                className="flex h-8 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-sm bg-secondary-lighter transition-all hover:bg-secondary-light"
                                            >
                                                <CheckIcon className="text-main-main" style={{ fontSize: '1rem' }} />
                                            </button>
                                        </BasicTooltip>
                                    )}

                                    <BasicTooltip tip={t('Cancel adding document')} position="left">
                                        <div
                                            className="ml-1 flex h-8 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-sm bg-layout-transparent hover:bg-layout-transparent-dark"
                                            onClick={() => {
                                                setNewComment('');
                                                setCreateNew({ type: null, open: false });
                                                setNewCommentFiles([]);
                                            }}
                                        >
                                            <CloseIcon
                                                className="text-secondary-lighter"
                                                style={{
                                                    fontSize: '1rem',
                                                }}
                                            />
                                        </div>
                                    </BasicTooltip>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

PMAddWikiComment.propTypes = {
    onSubmit: PropTypes.func,
    fileIconView: PropTypes.bool,
    getWikis: PropTypes.func,
    createNew: PropTypes.object,
    setCreateNew: PropTypes.func,
};

PMAddWikiComment.defaultProps = {
    onSubmit: () => null,
    fileIconView: true,
    getWikis: () => null,
    createNew: { type: null, open: false },
    setCreateNew: () => null,
};

export default PMAddWikiComment;
