import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import DepartmentContext from 'contexts/DepartmentContext';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm } from 'routes';

const Sales = () => {
    const { t } = useTranslation();
    const departments = useContext(DepartmentContext);
    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const history = useHistory();

    /*
        Timeline / Rezumat / Gantt
    */
    const [viewType, setViewType] = useState(0);

    /*
        Filters
        Status: todo, done, all
        Type: comment, task, email...
    */
    const [status, setStatus] = useState('all');
    const [activityType, setActivityType] = useState('ALL');

    const handleTypeChange = (event) => {
        setActivityType(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    return (
        <>
            <Helmet>
                <title>CRM | {t('sales')}</title>
            </Helmet>

            <Header
                pageTitle={selectedDepartment !== 0 ? departments[selectedDepartment - 1].name : t('internal-activity')}
                action={
                    <Button
                        startIcon={<AddIcon />}
                        color="secondary"
                        style={{ borderRadius: '999px' }}
                        onClick={() => history.push(crm.base + crm.contacts.base + crm.contacts.create)}
                    >
                        Adauga lead nou
                    </Button>
                }
                toolbar={
                    <Tabs
                        tabs={['Toate', ...(departments?.map((d) => d.name) ?? [])]}
                        activeTab={selectedDepartment}
                        setActiveTab={setSelectedDepartment}
                    />
                }
                toolbarSecondary={
                    <div className="flex flex-col">
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="status"
                                name="status-filter"
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <div className="no-user-select-recursive flex items-center text-main-text">
                                    <FormControlLabel value="all" control={<Radio />} label={t('all')} />
                                    <FormControlLabel value="todo" control={<Radio />} label={t('to-do')} />
                                    <FormControlLabel value="done" control={<Radio />} label={t('done')} />
                                </div>
                            </RadioGroup>
                        </FormControl>

                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="activity-type"
                                name="activity-type-filter"
                                value={activityType}
                                onChange={handleTypeChange}
                            >
                                <div className="no-user-select-recursive flex items-center text-main-text">
                                    <FormControlLabel value="ALL" control={<Radio />} label="Toate" />
                                    <FormControlLabel value="COMMENT" control={<Radio />} label="Comentarii" />
                                    <FormControlLabel value="TASK" control={<Radio />} label="Sarcini" />
                                    <FormControlLabel value="EMAIL" control={<Radio />} label="Emailuri" />
                                    <FormControlLabel value="FILES" control={<Radio />} label="Fișiere" />
                                    <FormControlLabel value="DOCUMENTS" control={<Radio />} label="Documente" />
                                    <FormControlLabel
                                        value="INVOICES_AND_BILLS"
                                        control={<Radio />}
                                        label="Facturi/Proforme"
                                    />
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                }
                toolbarTertiary={
                    <Tabs tabs={['Timeline', 'Rezumat', 'Gantt']} activeTab={viewType} setActiveTab={setViewType} />
                }
            />

            <div className="page-container"></div>
        </>
    );
};

export default Sales;
