import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { ReactComponent as AuthorIcon } from 'assets/pipelines/svgs/author-icon.svg';
import { ReactComponent as CalendarBlankIcon } from 'assets/pipelines/svgs/calendar-blank-icon.svg';
import { ReactComponent as CalendarRangeIcon } from 'assets/pipelines/svgs/calendar-range-icon.svg';
import { ReactComponent as CalendarSelectionIcon } from 'assets/pipelines/svgs/calendar-selection-icon.svg';
import { ReactComponent as ClientIcon } from 'assets/pipelines/svgs/client-icon.svg';
import { ReactComponent as CurrentDayIcon } from 'assets/pipelines/svgs/current-day-icon.svg';
import { ReactComponent as EqualIcon } from 'assets/pipelines/svgs/equal-icon.svg';
import { ReactComponent as GreatEqualIcon } from 'assets/pipelines/svgs/greater-equal-icon.svg';
import { ReactComponent as HighestIcon } from 'assets/pipelines/svgs/highest-icon.svg';
import { ReactComponent as LastThirtyDaysIcon } from 'assets/pipelines/svgs/last-thirty-days-icon.svg';
import { ReactComponent as LessEqualIcon } from 'assets/pipelines/svgs/less-equal-icon.svg';
import { ReactComponent as LowestIcon } from 'assets/pipelines/svgs/lowest-icon.svg';
import { ReactComponent as PriceIcon } from 'assets/pipelines/svgs/price-icon.svg';
import { ReactComponent as RecentIcon } from 'assets/pipelines/svgs/recent-icon.svg';
import { ReactComponent as SpecificIcon } from 'assets/pipelines/svgs/specific-icon.svg';
import { ReactComponent as TagIcon } from 'assets/pipelines/svgs/tag-icon.svg';

import { Button } from '@material-ui/core';

import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import PageFilter from 'components/shared/page-filter/page-filter';
import PipelineContent from 'components/shared/pipelines/pipeline-content';
import Sort from 'components/shared/sort/sort';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import {
    errorHandling,
    formatDate,
    formatTime,
    getDayBeginningAndEnding,
    ONE_DAY_IN_MILLISECONDS,
    toLocaleNumber,
} from 'utils';
import API from 'utils/axios';

const DEFAULT_PIPELINE_STATE = {
    length: 0,
    content: {
        DRAFT: {
            length: 0,
            content: {
                IMPORTANT_URGENT: [],
                IMPORTANT: [],
                URGENT: [],
                EXPIRING: [],
                REST: [],
            },
        },
        SENT: {
            length: 0,
            content: {
                IMPORTANT_URGENT: [],
                IMPORTANT: [],
                URGENT: [],
                EXPIRING: [],
                REST: [],
            },
        },
        ACCEPTED: {
            length: 0,
            content: {
                IMPORTANT_URGENT: [],
                IMPORTANT: [],
                URGENT: [],
                EXPIRING: [],
                REST: [],
            },
        },
        BLOCKED: {
            length: 0,
            content: {
                IMPORTANT_URGENT: [],
                IMPORTANT: [],
                URGENT: [],
                EXPIRING: [],
                REST: [],
            },
        },
        LOST: {
            length: 0,
            content: {
                IMPORTANT_URGENT: [],
                IMPORTANT: [],
                URGENT: [],
                EXPIRING: [],
                REST: [],
            },
        },
    },
};

const BiddingPipeline = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [loading, setLoading] = useState(true);

    const [offers, setOffers] = useState([]);
    const [formattedOffers, setFormattedOffers] = useState(DEFAULT_PIPELINE_STATE);

    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);
    const [tags, setTags] = useState([]);

    const [sort, setSort] = useState([
        {
            key: 'important',
            label: t('Important'),
            value: true,
            type: 'switch',
            options: null,
        },
        {
            key: 'urgent',
            label: t('Urgent'),
            value: true,
            type: 'switch',
            options: null,
        },
        {
            key: 'expiring',
            label: t('Expiring offers'),
            value: true,
            type: 'switch',
            options: null,
        },
        {
            key: 'totalPrice',
            label: t('Price'),
            value: null,
            type: 'toggle',
            options: [
                {
                    icon: <MoneyOffIcon style={{ fontSize: '2rem' }} />,
                    value: null,
                },
                { icon: <HighestIcon />, value: 'asc' },
                { icon: <LowestIcon />, value: 'desc' },
            ],
        },
        {
            key: 'createAt',
            label: t('Creation date'),
            value: 'desc',
            type: 'toggle',
            options: [
                {
                    icon: <EventBusyIcon style={{ fontSize: '2rem' }} />,
                    value: null,
                },
                { icon: <HighestIcon />, value: 'asc' },
                { icon: <LowestIcon />, value: 'desc' },
            ],
        },
    ]);

    const { currencyObj } = useContext(GlobalContext);
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '9',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '9',
            permissionType: 'VIEW',
        },
    ]);

    const handleGetOfferStatus = (offer) => {
        const status = offer.offerHistory.at(-1).status;

        if (status === 'NOT_ACCEPTED') return 'LOST';
        if (status === 'NEW' && offer.status === 'DRAFT') return 'DRAFT';
        if (status === 'NEW' && offer.status === 'CREATED') return 'SENT';

        return status;
    };

    const handleGetOfferKey = (offer, sort) => {
        const important = sort.find((element) => element.key === 'important').value;
        const urgent = sort.find((element) => element.key === 'urgent').value;
        const expiring = sort.find((element) => element.key === 'expiring').value;

        if (important && urgent && offer.important && offer.urgent) return 'IMPORTANT_URGENT';
        if (important && offer.important) return 'IMPORTANT';
        if (urgent && !offer.important && offer.urgent) return 'URGENT';

        const dayDifference =
            (new Date().getTime() - new Date(offer.expirationDate).getTime()) / ONE_DAY_IN_MILLISECONDS;
        const isExpiring = dayDifference > 0 && dayDifference <= 3;
        const status = offer.offerHistory.at(-1).status;
        const isStatusOk = status !== 'ACCEPTED' && status !== 'LOST' && status !== 'NOT_ACCEPTED';

        if (expiring && isExpiring && isStatusOk) return 'EXPIRING';

        return 'REST';
    };

    const handleFormatOffers = (offers, sort) => {
        return offers
            .map((o) => {
                const offer = o.Offer;
                const actualOffer = offer.followUpOffers.at(-1) ?? offer;
                return {
                    ...actualOffer,
                    metadata: {
                        originalOffer: offer,
                    },
                };
            })
            .reduce((acc, curr) => {
                const status = handleGetOfferStatus(curr);
                const key = handleGetOfferKey(curr, sort);

                return {
                    ...acc,
                    length: offers.length,
                    content: {
                        ...acc.content,
                        [status]: {
                            ...acc.content[status],
                            length: acc.content[status].length + 1,
                            content: {
                                ...acc.content[status].content,
                                [key]: [...acc.content[status].content[key], curr],
                            },
                        },
                    },
                };
            }, DEFAULT_PIPELINE_STATE);
    };

    const getOffers = async (sort, filter) => {
        try {
            const querySort = sort
                .filter(
                    (element) => !['important', 'urgent', 'expiring'].includes(element.key) && element.value !== null,
                )
                .reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {});

            const queryFilter = filter.reduce(
                (acc, curr) => ({ ...acc, [curr.metadata.backendKey ?? curr.key]: curr.value }),
                {},
            );

            const response = await API.get('offer_pipeline', { params: { ...querySort, ...queryFilter } });
            const formattedOffers = handleFormatOffers(response.data, sort);

            setFormattedOffers(formattedOffers);
            setOffers(response.data);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const getClients = async () => {
        try {
            const response = await API.get('/contacts', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                    type: 'ALL',
                },
            });
            const fetchedClients = response.data.data.contacts;

            setClients(fetchedClients);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const getUsers = async () => {
        try {
            const response = await API.get('/tenants', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                },
            });

            setUsers(response.data.users);
        } catch (err) {
            console.error(err);
            throw new Error(err);
        }
    };

    const getTags = async () => {
        try {
            const response = await API.get('/offer_tags', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                },
            });

            setTags(response.data.data.offerTag);
        } catch (err) {
            console.error(err);
            throw new Error(err);
        }
    };

    useEffect(() => {
        if (!canView) history.push('/');

        (async () => {
            try {
                await Promise.all([getOffers(sort, filter), getClients(), getUsers(), getTags()]);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t(errorHandling(error)), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, [canView]);

    const columns = [
        {
            title: t('Drafts'),
            key: 'DRAFT',
            color: 'var(--main-text)',
            backgroundColor: '#FB924B',
        },
        {
            title: t('Offers sent'),
            key: 'SENT',
            color: 'var(--main-text)',
            backgroundColor: '#1D8CF8',
        },
        {
            title: t('Offers accepted'),
            key: 'ACCEPTED',
            color: 'var(--main-text)',
            backgroundColor: '#3EC356',
        },
        {
            title: t('Offers blocked'),
            key: 'BLOCKED',
            color: 'var(--main-text)',
            backgroundColor: '#3587A4',
        },
        {
            title: t('Offers lost'),
            key: 'LOST',
            color: 'var(--main-text)',
            backgroundColor: '#A47DEF',
        },
    ];

    const [sortKeys, setSortKeys] = useState(() => {
        const important = sort.find((element) => element.key === 'important').value;
        const urgent = sort.find((element) => element.key === 'urgent').value;
        const expiring = sort.find((element) => element.key === 'expiring').value;

        return [
            {
                key: 'IMPORTANT_URGENT',
                title: t('Important/Urgent'),
                chipStyles: {
                    color: 'var(--error)',
                    borderColor: 'var(--error)',
                    fontSize: '1rem',
                },
                lines: [
                    {
                        styles: {
                            backgroundColor: 'var(--error)',
                        },
                    },
                    {
                        styles: {
                            backgroundColor: 'var(--error)',
                        },
                    },
                ],
                render: important && urgent,
            },
            {
                key: 'IMPORTANT',
                title: t('Important'),
                chipStyles: {
                    color: 'var(--main-text)',
                    borderColor: 'var(--error)',
                    fontSize: '1rem',
                },
                lines: [
                    {
                        styles: {
                            backgroundColor: 'var(--error)',
                        },
                    },
                    {
                        styles: {
                            backgroundColor: 'var(--main-text)',
                        },
                    },
                ],
                render: important,
            },
            {
                key: 'URGENT',
                title: t('Not Important/Urgent'),
                chipStyles: {
                    color: 'var(--error',
                    borderColor: 'var(--error)',
                    fontSize: '1rem',
                },
                lines: [
                    {
                        styles: {
                            backgroundColor: 'var(--error)',
                        },
                    },
                ],
                render: urgent,
            },
            {
                key: 'EXPIRING',
                title: t('Expiring offers'),
                chipStyles: {
                    color: 'var(--main-text)',
                    borderColor: 'var(--disabled)',
                    fontSize: '1rem',
                },
                lines: [
                    {
                        styles: {
                            backgroundColor: 'var(--main-text)',
                        },
                    },
                ],
                render: expiring,
            },
            {
                key: 'REST',
                title: t('All offers'),
                chipStyles: {
                    color: 'var(--main-text)',
                    borderColor: 'var(--disabled)',
                    fontSize: '1rem',
                },
                lines: [
                    {
                        styles: {
                            backgroundColor: 'var(--main-text)',
                        },
                    },
                ],
                render: true,
            },
        ];
    });

    const handleSort = async (sort, lastAppliedSort) => {
        try {
            const backendProps = sort.filter((element) => !['important', 'urgent', 'expiring'].includes(element.key));
            const lastBackendProps = lastAppliedSort.filter(
                (element) => !['important', 'urgent', 'expiring'].includes(element.key),
            );
            const isBackRequest = backendProps
                .map((e) => e.value)
                .some((element, index) => element !== lastBackendProps.map((e) => e.value)[index]);

            if (isBackRequest) {
                setLoading(true);
                await Promise.all([getOffers(sort, filter), getClients(), getUsers(), getTags()]);
            } else {
                const formattedOffers = handleFormatOffers(offers, sort);
                setFormattedOffers(formattedOffers);
            }

            setSortKeys((prev) => {
                const important = sort.find((element) => element.key === 'important').value;
                const urgent = sort.find((element) => element.key === 'urgent').value;
                const expiring = sort.find((element) => element.key === 'expiring').value;

                return prev.map((sortKey) => {
                    if (sortKey.key === 'IMPORTANT_URGENT') return { ...sortKey, render: important && urgent };
                    if (sortKey.key === 'IMPORTANT') return { ...sortKey, render: important };
                    if (sortKey.key === 'URGENT') return { ...sortKey, render: urgent };
                    if (sortKey.key === 'EXPIRING') return { ...sortKey, render: expiring };

                    return sortKey;
                });
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const itemProps = {
        chip: {
            render: (item) =>
                typeof item.contact.data === 'string'
                    ? JSON.parse(item.contact.data).standard.name
                    : item.contact.data.standard.name,
        },
        header: {
            renderVisibleRows: (item) => [
                {
                    icon: <AttachFileIcon className="rotate-45 transform" />,
                    content: item.name,
                },
            ],
            renderExpandedRows: (item) => [
                {
                    icon: null,
                    content: `${t('Offer details')}: ${item.description}`,
                },
            ],
        },
        body: {
            renderVisibleRows: (item) => [
                {
                    label: { icon: null, text: t('Creation date') },
                    content: formatDate(item.createAt),
                    canRender: true,
                },
                {
                    label: { icon: null, text: t('Sent date') },
                    content: formatDate(item.metadata.originalOffer.offerDate),
                    canRender: item.status !== 'DRAFT',
                },
                {
                    label: { icon: null, text: t('Offer value (without VAT)') },
                    content: `${toLocaleNumber(item.priceWithoutVat, 2, 4)} ${currencyObj.currency}`,
                    canRender: true,
                },
                {
                    label: { icon: null, text: t('Offer value') },
                    content: `${toLocaleNumber(item.totalPrice, 2, 4)} ${currencyObj.currency}`,
                    canRender: true,
                },
            ],
            renderExpandedRows: (item) => [
                {
                    label: { icon: null, text: t('Author') },
                    content: item.author.profile.name,
                    canRender: true,
                },
                {
                    label: { icon: null, text: t('To') },
                    content:
                        typeof item.contact.data === 'string'
                            ? JSON.parse(item.contact.data).standard.email
                            : item.contact.data.standard.email,
                    canRender: true,
                },
                {
                    label: { icon: null, text: t('No. of tags') },
                    content: item.OfTags.length,
                    canRender: true,
                },
                {
                    label: { icon: null, text: t('Last update') },
                    content: `${formatDate(item.updateAt)}, ${formatTime(item.updateAt)}`,
                    canRender: true,
                },
                {
                    label: { icon: null, text: t('No. of revisions') },
                    content: item.metadata.originalOffer.followUpOffers.length,
                    canRender: true,
                },
                ...(() => {
                    const dayDifference =
                        (new Date().getTime() - new Date(item.expirationDate).getTime()) / ONE_DAY_IN_MILLISECONDS;
                    const status = item.offerHistory.at(-1).status;
                    const okStatus = status !== 'ACCEPTED' && status !== 'LOST' && status !== 'NOT_ACCEPTED';

                    const icon =
                        !okStatus || dayDifference > 3 ? null : (
                            <ReportProblemOutlinedIcon
                                className={
                                    Math.ceil(
                                        (new Date().getTime() - new Date(item.expirationDate).getTime()) /
                                            ONE_DAY_IN_MILLISECONDS,
                                    ) > 0
                                        ? 'text-warning'
                                        : 'text-error'
                                }
                            />
                        );

                    const text = !okStatus
                        ? t('Expiration date')
                        : dayDifference > 0
                          ? t('Expires in')
                          : t('Expired by');

                    const content = !okStatus
                        ? formatDate(item.expirationDate)
                        : `${Math.ceil(Math.abs(dayDifference))} ${t('days')}`;

                    return [
                        {
                            label: {
                                icon,
                                text,
                            },
                            content,
                            canRender: true,
                        },
                    ];
                })(),
            ],
        },
        button: {
            render: () => t('Open offer'),
            onClick: (item) =>
                history.push(quotes.base + quotes.viewBidding.base + '/' + item.metadata.originalOffer.id),
            canRender: true,
        },
    };

    const [filter, setFilter] = useState([]);
    const filterOptions = [
        {
            id: 'client-group',
            icon: <ClientIcon />,
            label: 'Client',
            data: null,
            list: [
                {
                    id: 'contactId',
                    icon: <RecentIcon />,
                    label: 'Recent',
                    data: {
                        defaultValue: null,
                        filterData: {
                            label: 'Client',
                            render: (value) => {
                                const data = JSON.parse(clients.find((client) => client.id === value).data);
                                return 'standard' in data && 'name' in data['standard']
                                    ? data['standard'].name
                                    : 'Nume inexistent';
                            },
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['id'],
                                type: 'list',
                                options: clients.slice(0, 4),
                                label: t('Select client'),
                                render: (item) => {
                                    const data = JSON.parse(item.data);
                                    return {
                                        icon: <ClientIcon />,
                                        label:
                                            'standard' in data && 'name' in data['standard']
                                                ? data['standard'].name
                                                : 'Nume inexistent',
                                    };
                                },
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'contactId',
                    icon: <SpecificIcon />,
                    label: t('Specific client'),
                    data: {
                        defaultValue: null,
                        filterData: {
                            label: 'Client',
                            render: (value) => {
                                const data = JSON.parse(clients.find((client) => client.id === value).data);
                                return 'standard' in data && 'name' in data['standard']
                                    ? data['standard'].name
                                    : 'Nume inexistent';
                            },
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['id'],
                                type: 'select',
                                options: clients,
                                label: t('Select client'),
                                render: (item) => {
                                    const data = JSON.parse(item.data);
                                    return 'standard' in data && 'name' in data['standard']
                                        ? data['standard'].name
                                        : 'Nume inexistent';
                                },
                            },
                        ],
                    },
                    list: null,
                },
            ],
        },
        {
            id: 'date-group',
            icon: <CalendarBlankIcon />,
            label: t('Date'),
            data: null,
            list: [
                {
                    id: 'date',
                    icon: <CurrentDayIcon />,
                    label: t('Today'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date()).startDate,
                            endDate: getDayBeginningAndEnding(new Date()).endDate,
                        },
                        filterData: {
                            label: t('Date'),
                            render: (value) => formatDate(value.startDate, true, true),
                            metadata: {},
                        },
                        inputs: [],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <LastThirtyDaysIcon />,
                    label: t('Last 30 days'),
                    data: {
                        defaultValue: {
                            startDate: (() => {
                                const date = getDayBeginningAndEnding(new Date()).startDate;
                                date.setDate(date.getDate() - 29);
                                return date;
                            })(),
                            endDate: getDayBeginningAndEnding(new Date()).endDate,
                        },
                        filterData: {
                            label: 'Interval',
                            render: (value) =>
                                `${formatDate(value.startDate, true, true)} - ${formatDate(value.endDate, true, true)}`,
                            metadata: {},
                        },
                        inputs: [],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <CalendarSelectionIcon />,
                    label: t('Selected date'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date()).startDate,
                            endDate: getDayBeginningAndEnding(new Date()).endDate,
                        },
                        filterData: {
                            label: t('Date'),
                            render: (value) => formatDate(value.startDate, true, true),
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['startDate', 'endDate'],
                                type: 'date',
                                options: null,
                                label: t('Choose a date'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <CalendarRangeIcon />,
                    label: t('Selection range'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date()).startDate,
                            endDate: getDayBeginningAndEnding(new Date()).endDate,
                        },
                        filterData: {
                            label: 'Interval',
                            render: (value) =>
                                `${formatDate(value.startDate, true, true)} - ${formatDate(value.endDate, true, true)}`,
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['startDate'],
                                type: 'date',
                                options: null,
                                label: t('Select the end date'),
                                render: null,
                            },
                            {
                                key: ['endDate'],
                                type: 'date',
                                options: null,
                                label: t('Select the start date'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
            ],
        },
        {
            id: 'price-group',
            icon: <PriceIcon />,
            label: t('Offer value'),
            data: null,
            list: [
                {
                    id: 'value',
                    icon: <EqualIcon />,
                    label: t('Equal to'),
                    data: {
                        defaultValue: 0,
                        filterData: {
                            label: t('Price equal to'),
                            render: (value) => `${toLocaleNumber(value, 0, 0)} ${currencyObj.currency}`,
                            metadata: {
                                backendKey: 'equal',
                            },
                        },
                        inputs: [
                            {
                                key: ['equal'],
                                type: 'value',
                                options: null,
                                label: t('Enter value'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'value',
                    icon: <GreatEqualIcon />,
                    label: t('Equal or greater then'),
                    data: {
                        defaultValue: 0,
                        filterData: {
                            label: t('Price equal or greater then'),
                            render: (value) => `${toLocaleNumber(value, 0, 0)} ${currencyObj.currency}`,
                            metadata: {
                                backendKey: 'greater',
                            },
                        },
                        inputs: [
                            {
                                key: ['gte'],
                                type: 'value',
                                options: null,
                                label: t('Enter value'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'value',
                    icon: <LessEqualIcon />,
                    label: t('Equal or less then'),
                    data: {
                        defaultValue: 0,
                        filterData: {
                            label: t('Price equal or less then'),
                            render: (value) => `${toLocaleNumber(value, 0, 0)} ${currencyObj.currency}`,
                            metadata: {
                                backendKey: 'smaller',
                            },
                        },
                        inputs: [
                            {
                                key: ['lte'],
                                type: 'value',
                                options: null,
                                label: t('Enter value'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
            ],
        },
        {
            id: 'authorId',
            icon: <AuthorIcon />,
            label: t('Author'),
            data: {
                defaultValue: null,
                filterData: {
                    label: t('Author'),
                    render: (value) => users.find((u) => u.id === value).profile.name,
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'select',
                        options: users,
                        label: t('Select author'),
                        render: (item) => item.profile.name,
                    },
                ],
            },
            list: null,
        },
        {
            id: 'tagsIds',
            icon: <TagIcon />,
            label: t('Tags'),
            data: {
                defaultValue: [],
                filterData: {
                    label: t('Tags'),
                    render: (value) => {
                        let tagsString = '';
                        value.forEach((v, index) => {
                            const tag = tags.find((t) => t.id === v).name;
                            tagsString += `${tag}${index !== value.length - 1 ? ', ' : ''}`;
                        });

                        return tagsString;
                    },
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'multiselect',
                        options: tags,
                        label: t('Select tags'),
                        render: (item) => item.name,
                    },
                ],
            },
            list: null,
        },
    ];

    const handleFilter = async (filter) => {
        try {
            setLoading(true);
            await Promise.all([getOffers(sort, filter), getClients(), getUsers(), getTags()]);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {t('Offer')} | {t('Offers pipeline')}
                </title>
            </Helmet>

            <Header
                pageTitle={t('Offers pipeline')}
                action={
                    <div className="ml-auto flex gap-6">
                        <Sort
                            disabled={loading || formattedOffers.length === 0}
                            sort={sort}
                            setSort={setSort}
                            onSort={handleSort}
                        />
                        {canAll && (
                            <Button
                                color="secondary"
                                startIcon={<AddIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={() => {
                                    history.push(quotes.base + quotes.newBidding.base);
                                }}
                            >
                                {t('New offer')}
                            </Button>
                        )}
                    </div>
                }
            />

            <div className="page-container">
                <div className="flex flex-col gap-6 xl:gap-12">
                    <PageFilter
                        disabled={loading}
                        title={t('Filter offers')}
                        filter={filter}
                        setFilter={setFilter}
                        filterOptions={filterOptions}
                        onFilter={handleFilter}
                        mobileBP="xl"
                    />
                    {loading ? (
                        <Loading style={{ height: '70vh' }} />
                    ) : formattedOffers.length ? (
                        <PipelineContent
                            columns={columns}
                            sortKeys={sortKeys}
                            itemProps={itemProps}
                            data={formattedOffers}
                            mobileBP="xl"
                        />
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </div>
            </div>
        </>
    );
};

export default BiddingPipeline;
