import React from 'react';

import { CircularProgress } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const LoadingExportModal = ({ open }) => {
    const { t } = useTranslation();

    return (
        <CustomModal open={open}>
            <div className="flex w-96 flex-col items-center justify-center gap-8 px-10 py-20 sm:w-64">
                <CircularProgress />
                <h2 className="text-2xl sm:text-xl">
                    {t('Data is generated, please wait and do not close or reload the page!')}
                </h2>
            </div>
        </CustomModal>
    );
};

LoadingExportModal.propTypes = {
    open: PropTypes.bool,
};

LoadingExportModal.defaultProps = {
    open: false,
};

export default LoadingExportModal;
