import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DepartmentDetails from 'components/crm/departments/department-details';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const EditDepartments = () => {
    const { id: departmentId } = useParams();
    const [department, setDepartment] = useState(null);

    const { t } = useTranslation();

    const getDepartment = async () => {
        try {
            const res = await API.get(`department/${departmentId}`);
            setDepartment(res.data.department);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getDepartment();
    }, []);

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Edit department')}</title>
            </Helmet>

            <Header pageTitle={t('Edit department')} />
            <div className="page-container">
                {department && <DepartmentDetails department={department} id={departmentId} area={'ERP'} />}
            </div>
        </>
    );
};

export default EditDepartments;
