import React, { useEffect, useRef } from 'react';

import useScreenSizes from 'hooks/useScreenSizes.js';
import PropTypes from 'prop-types';

export const Stepper = (props) => {
    const { step, setStep, steps, disabled } = props;
    const stepperContainer = useRef(null);
    const [width] = useScreenSizes();

    useEffect(() => {
        if (!stepperContainer.current) return;

        const wrapper = stepperContainer.current;
        const wrapperWidth = wrapper.clientWidth;

        const container = wrapper.firstChild;

        const childrenWidths = Array.from(container.children).map((child) => child.clientWidth);
        const leftOffset = childrenWidths.slice(0, step + 1).reduce((sum, curr) => (sum += curr), 0);

        if (leftOffset > wrapperWidth) {
            container.style.transform = `translateX(-${leftOffset - wrapperWidth}px)`;
            container.style.transition = `0.2s ease-in-out`;
        } else if (leftOffset <= wrapperWidth) {
            container.style.transform = `translateX(0px)`;
            container.style.transition = `0.2s ease-in-out`;
        }
    }, [step, width]);

    return (
        <div className="w-full" ref={stepperContainer}>
            <div className="inline-flex items-stretch justify-start">
                {steps.map((s, index) => (
                    // eslint-disable-next-line
                    <div
                        className={`${
                            disabled ? 'pointer-events-none' : ''
                        } flex-shrink-0 border-2  px-6 py-3 text-sm font-semibold transition-all duration-200 first:rounded-bl-md first:rounded-tl-md ${
                            index === step
                                ? 'rounded-br-md rounded-tr-md border-primary-main text-main-text'
                                : 'border-transparent'
                        } ${index <= step ? 'border-primary-main' : 'border-transparent'} ${
                            index < step
                                ? 'bg-primary-main text-buttons-text hover:border-primary-light hover:bg-primary-light'
                                : 'border-transparent text-dark-text'
                        }  cursor-pointer`}
                        onClick={() => setStep(index)}
                        // eslint-disable-next-line
                        key={index}
                    >
                        {`${index + 1}. ${s}`}
                    </div>
                ))}
            </div>
        </div>
    );
};

Stepper.propTypes = {
    step: PropTypes.number,
    setStep: PropTypes.func,
    steps: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
};

Stepper.defaultProps = {
    step: 0,
    setStep: () => null,
    steps: [],
    disabled: false,
};
