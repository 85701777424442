import React, { useEffect, useState } from 'react';

import VariationsForm from 'components/crm/quotes/variations/variations-form';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { errorHandling } from 'utils';
import API from 'utils/axios';

const EditVariations = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { variationId } = useParams();

    const [variationInfo, setVariationInfo] = useState();

    useEffect(() => {
        (async () => {
            try {
                const res = await API.get('/articleVariation', {
                    params: {
                        id: variationId,
                    },
                });
                setVariationInfo(res.data.articleVariation);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        })();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('Edit variation')} </title>
            </Helmet>

            <Header pageTitle={t('Edit variation')} />

            <div className="page-container">
                <VariationsForm variationInfo={variationInfo} />
            </div>
        </>
    );
};

export default EditVariations;
