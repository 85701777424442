import { END_OF_DAY_OFFSET, getTextHeight, normalizeDate, ONE_DAY_IN_MILLISECONDS, remToPx } from 'utils';

const getTodayDate = () => new Date().getTime();

const INITIAL_HEIGHT = 60;
const DEFAULT_DISABLED_CROP_HEIGHTS_INTERVALS = [[0, INITIAL_HEIGHT]];
const LENGTH_DIVIDER = 300;
const PADDING_Y = '0.25rem';

const getRowHeight = (str, paddingOffset, currentHeight, classes = '') => {
    const height = getTextHeight(str, `text-xl ${classes}`, LENGTH_DIVIDER - paddingOffset) + remToPx(PADDING_Y) * 2;

    const disabledCropHeightsInterval = [currentHeight, currentHeight + height];

    return {
        height,
        disabledCropHeightsInterval,
    };
};

/**
 * ? Checks if a status is overdue if the end time is greater then the current time and the status has a different value then 'DONE'
 * @param {Date} end - Date that needs to be checked out
 * @param {string} status - Status of the of task
 * @returns {boolean} Overdue status
 */
const hasTimeForCompletionPassed = (end, status) => {
    const endDate = new Date(end).getTime();

    return getTodayDate() > endDate && status !== 'DONE';
};

/**
 * ? Will return the start date of the task based on different cases
 * @param {object} task - Task object
 * @returns {string} Start date
 */
const findStartDate = (task) => {
    // ? If the task has a declared estimated start date we return it
    if (task.estimatedStartDate) return task.estimatedStartDate;

    // ? If the task is a parent for other subtasks we will return the estimated start date of the first subtask
    if (task.activitiesTasks?.length > 0) return task.activitiesTasks[0].estimatedStartDate;

    // ? In all other cases we return the creation date
    return task.createAt;
};

/**
 * ? Will return the end date of the task based on different cases
 * @param {object} task - Task object
 * @returns {string} End date
 */
const findEndDate = (task) => {
    // ? If the task has a declared estimated end date we return it
    if (task.estimatedEndDate) return task.estimatedEndDate;

    // ? If the task is a parent for other subtasks we will return the estimated end date of the last subtask
    if (task.activitiesTasks?.length > 0) return task.activitiesTasks[task.activitiesTasks.length - 1].estimatedEndDate;

    // ? In all other cases we return the creation date
    return task.createAt;
};

/**
 * ? This function is used to generate an array of tasks for gantt, based on an array of milestones
 * @param {array} milestones - From the milestones array we will extract all the tasks
 * @returns {array} Tasks
 */
export const getTasks = (milestones) => {
    const tasks = [];
    let currentHeight = INITIAL_HEIGHT;
    const disabledCropHeightsIntervals = [...DEFAULT_DISABLED_CROP_HEIGHTS_INTERVALS];

    for (const task of milestones) {
        const paddingX = '0.5rem';
        const padding = `${PADDING_Y} ${paddingX}`;
        // ? Here we get the start date and end date of the current task
        const start = findStartDate(task);
        const end = findEndDate(task);

        // ? We check if the task is overdue
        const overdue = hasTimeForCompletionPassed(end, task.status);

        const paddingOffset = remToPx(paddingX) * 2;
        const { height, disabledCropHeightsInterval } = getRowHeight(
            task.title,
            paddingOffset,
            currentHeight,
            'font-bold'
        );
        disabledCropHeightsIntervals.push(disabledCropHeightsInterval);
        currentHeight += height;

        tasks.push({
            id: task.id,
            name: task.title,
            description: task.description,
            responsible: task.users.map((user) => ({
                name: user.user.email,
                avatar: '',
            })),
            start,
            end,
            progress: 100,
            // ? If the task is overdue we assign a red color, if is done a gree one, otherwise we use the default color
            fill: overdue ? 'rgb(255 105 105)' : task.status === 'DONE' ? 'rgb(55 169 133)' : 'rgb(43 163 173)',
            styles: { padding, fontWeight: 'bold' },
            rowStyles: { height: `${height}px` },
            type: 'milestone',
        });

        // Has milestones
        if (task.activitiesTasks?.length > 0) {
            for (let i = 0; i <= task.activitiesTasks.length - 1; i++) {
                const paddingLeft = '0.5rem';
                const paddingRight = '1.5rem';
                const padding = `${PADDING_Y} ${paddingLeft} ${PADDING_Y} ${paddingRight}`;
                const milestone = task.activitiesTasks[i];

                // ? Here we get the start date and end date of the current task
                const start = findStartDate(milestone);
                const end = findEndDate(milestone);

                // ? We check if the task is overdue
                const overdue = hasTimeForCompletionPassed(end, milestone.status);

                const paddingOffset = remToPx(paddingLeft) + remToPx(paddingRight);
                const { height, disabledCropHeightsInterval } = getRowHeight(
                    milestone.name,
                    paddingOffset,
                    currentHeight,
                    'font-bold'
                );
                disabledCropHeightsIntervals.push(disabledCropHeightsInterval);
                currentHeight += height;

                tasks.push({
                    id: milestone.id,
                    name: milestone.name,
                    description: milestone.description,
                    responsible: [
                        {
                            name: milestone.responsible[0].user.email,
                            avatar: '',
                        },
                    ],
                    start,
                    end,
                    progress: 100,
                    // ? If the task is the first subtask of a parent we assign as the dependency the parent id
                    // ? If the task is not the first subtask, then we assign as the dependency the id of last child of the previous task
                    // ? In case the previous task has no children we assign as the dependency the previous task id
                    dependencies: [
                        i === 0
                            ? task.id
                            : task.activitiesTasks.at(i - 1).subTasks?.at(-1)?.id ?? task.activitiesTasks.at(i - 1).id,
                    ],
                    // ? If the task is overdue we assign a red color, if is done a gree one, otherwise we use the default color
                    fill: overdue
                        ? 'rgb(255 105 105/ 90%)'
                        : milestone.status === 'DONE'
                        ? 'rgb(55 169 133 / 90%)'
                        : 'rgb(104 104 104 / 90%)',
                    styles: { padding, fontWeight: 'bold' },
                    rowStyles: { height: `${height}px` },
                    type: 'sub-milestone',
                });

                if (milestone.subTasks?.length > 0) {
                    for (let j = 0; j <= milestone.subTasks.length - 1; j++) {
                        const paddingLeft = '0.5rem';
                        const paddingRight = '2.5rem';
                        const padding = `${PADDING_Y} ${paddingLeft} ${PADDING_Y} ${paddingRight}`;
                        const subTask = milestone.subTasks[j];

                        // ? Here we get the start date and end date of the current task
                        const start = findStartDate(subTask);
                        const end = findEndDate(subTask);

                        // ? We check if the task is overdue
                        const overdue = hasTimeForCompletionPassed(end, subTask.status);

                        const paddingOffset = remToPx(paddingLeft) + remToPx(paddingRight);
                        const { height, disabledCropHeightsInterval } = getRowHeight(
                            subTask.name,
                            paddingOffset,
                            currentHeight
                        );
                        disabledCropHeightsIntervals.push(disabledCropHeightsInterval);
                        currentHeight += height;

                        tasks.push({
                            id: subTask.id,
                            name: subTask.name,
                            description: milestone.description,
                            responsible: [
                                {
                                    name: subTask.responsible[0].user.email,
                                    avatar: '',
                                },
                            ],
                            start,
                            end,
                            progress: 100,
                            // ? If the task is not the first one we assign as dependency the previous task id
                            // ? If the task is the first one we assign as dependency the parent task id
                            dependencies: [j > 0 ? milestone.subTasks[j - 1].id : milestone.id],
                            // ? If the task is overdue we assign a red color, if is done a gree one, otherwise we use the default color
                            fill: overdue
                                ? 'rgb(255 105 105/ 80%)'
                                : subTask.status === 'DONE'
                                ? 'rgb(55 169 133 / 80%)'
                                : 'rgb(116 204 212 / 80%)',
                            styles: { padding },
                            rowStyles: { height: `${height}px` },
                            type: 'task',
                        });
                    }
                }
            }
        }
    }

    return [tasks, disabledCropHeightsIntervals];
};

/**
 * ? This function returns the oldest time stamp between current start date and initial start date
 * @param {object} activity - Activity object
 * @param {boolean} hasInitialStartDate - If true we will check what start date is older and return the time stamp, otherwise we return the current time stamp
 * @returns {number} The oldest time stamp
 */
const findActivityIntervalStartTimeStamp = (activity, hasInitialStartDate) => {
    const currentStartDate = new Date(activity.startDate).getTime();

    if (!hasInitialStartDate) return currentStartDate;

    return Math.min(currentStartDate, new Date(activity.initialStartDate).getTime());
};

/**
 * ? This function returns the oldest time stamp between current end date and initial end date
 * @param {object} activity - Activity object
 * @param {boolean} hasInitialStartDate - If true we will check what end date is newer and return the time stamp, otherwise we return the current time stamp
 * @returns {number} The oldest time stamp
 */
const findActivityIntervalEndTimeStamp = (activity, hasInitialStartDate) => {
    const currentEndDate =
        normalizeDate(activity.startDate) +
        (activity.realizationDays - 1) * ONE_DAY_IN_MILLISECONDS +
        END_OF_DAY_OFFSET;

    if (!hasInitialStartDate) return currentEndDate;

    return Math.max(
        currentEndDate,
        normalizeDate(activity.initialStartDate) +
            (activity.initialRealizationDays - 1) * ONE_DAY_IN_MILLISECONDS +
            END_OF_DAY_OFFSET
    );
};

/**
 * ? This function is used to get all end time stamps of activities for a given section
 * @param {array} activities - Activities used to extract the end time stamps
 * @returns {array} End time stamps
 */
const findActivityIntervalEndTimeStamps = (activities) => {
    const activitiesEndTimeStamps = [];

    for (const a of activities) {
        // ? We determine if the current activity has an initial start date
        const hasInitialStartDate = a.initialStartDate && a.initialRealizationDays;

        const endTimeStamp = findActivityIntervalEndTimeStamp(a, hasInitialStartDate);
        activitiesEndTimeStamps.push(endTimeStamp);
    }

    return { activitiesEndTimeStamps };
};

// ? Colors used for the project structure gantt
//EXCEEDED <- #991b1b
//PRIOR <- #166534
//OVERDUE <- #ef4444
//DONE <- #22c55e
//OVERDUE NEW <- #f87171
//DONE NEW <- #a3e635
//GRANDPARENT <- #06b6d4
//PARENT <- #3b82f6
//CHILD <- #6366f1
//NEW PARENT <- #f97316
//NEW CHILD <- #eab308
//DEAD ZONE <- #64748b
//HIGHLIGHT INITIAL <- #374151

const mainActivityColorTypes = {
    projectStructure: {
        default: '#06b6d4',
    },
    section: {
        default: '#3b82f6',
        new: '#f97316',
    },
    activity: {
        default: '#6366f1',
        new: '#eab308',
    },
};

const doneActivityColorTypes = {
    projectStructure: {
        default: '#22c55e',
    },
    section: {
        default: '#22c55e',
        new: '#a3e635',
    },
    activity: {
        default: '#22c55e',
        new: '#a3e635',
    },
};

const overdueActivityColorTypes = {
    projectStructure: {
        default: '#ef4444',
    },
    section: {
        default: '#ef4444',
        new: '#f87171',
    },
    activity: {
        default: '#ef4444',
        new: '#f87171',
    },
};

const marginActivityColorTypes = {
    exceeded: '#991b1b',
    prior: '#166534',
};

/**
 * ? Finds the end time stamp of an activity based on the given case, the initial or the current one
 * ? For the case when we try to find the end time stamp of an initial date we might not have one and for this particular case we use the backup keys in order to return the current end time stamp
 * @param {object} activity - The activity for witch we find the end time stamp
 * @param {object} keys - The keys witch represent the current or he initial case with the respective backup if there is one
 * @returns {Date} The end time stamp of the activity
 */
const findActivityEndTimeStamp = (activity, keys) => {
    return (
        normalizeDate(activity[keys.main.date] ?? activity[keys.backup.date]) +
        ((activity[keys.main.days] ?? activity[keys.backup.days]) - 1) * ONE_DAY_IN_MILLISECONDS +
        END_OF_DAY_OFFSET
    );
};

/**
 * ? Will return all end time stamps of the activities and ignore the new ones if you specify it
 * @param {Array} activities - The activities used to find the end time stamps
 * @param {object} keys - The keys witch represent the current or he initial case with the respective backup if there is one
 * @param {boolean} ignoreNew - Is used to specify to the function to ignore the activities created after the initial creation of the project structure
 * @returns {Array} The end time stamps of the activity
 */
const findActivityEndTimeStamps = (activities, keys, ignoreNew) => {
    const activitiesEndTimeStamps = [];

    for (const a of activities) {
        // ? If the activity is new and you specified to skip the new activities then we skip it
        if (ignoreNew && a.isNew) {
            continue;
        } else {
            const endTimeStamp = findActivityEndTimeStamp(a, keys);
            activitiesEndTimeStamps.push(endTimeStamp);
        }
    }

    return { activitiesEndTimeStamps };
};

/**
 * ? Is used to determine the start and end time stamps of an activity
 * @param {object} activity - The activity used to get the start and end time stamps
 * @param {string} type - The time of the activity (activity, section, projectStructure)
 * @param {object} keys - The keys witch represent the current or he initial case with the respective backup if there is one
 * @param {boolean} canIgnoreNew - Is used to specify to the function to ignore the activities created after the initial creation of the project structure
 * @returns {object} Returns the start and end time stamps
 */
const getActivityTimeStamps = (activity, type, keys, canIgnoreNew) => {
    // ? The start of the activity is determined by the start date of the activity (initial or current)
    let startTimeStamp = new Date(activity[keys.main.date] ?? activity[keys.backup.date]).getTime();
    let endTimeStamp;

    const ignoreNew = canIgnoreNew && !activity.isNew;

    if (type === 'activity') {
        endTimeStamp = findActivityEndTimeStamp(activity, keys);
    }

    if (type === 'section') {
        // ? We get all the end time stamps of an activity
        const { activitiesEndTimeStamps } = findActivityEndTimeStamps(activity.activities, keys, ignoreNew);

        // ? We find the most recent end time stamp
        const maxActivitiesEndTimeStamp = Math.max(...activitiesEndTimeStamps);

        // ? In case we don't have an end date or the end date is less then the end of the day of the start date, we will set as the end time stamp the end of the day of the start date
        endTimeStamp = Math.max(normalizeDate(startTimeStamp) + END_OF_DAY_OFFSET, maxActivitiesEndTimeStamp);
    }

    if (type === 'projectStructure') {
        let allActivitiesEndTimeStamps = [];

        // ? We get all the end time stamps of all the activities
        for (const m of activity.milestones) {
            const { activitiesEndTimeStamps } = findActivityEndTimeStamps(m.activities, keys, ignoreNew);
            allActivitiesEndTimeStamps = [...allActivitiesEndTimeStamps, ...activitiesEndTimeStamps];
        }

        // ? We find the most recent end time stamp
        const maxActivitiesEndTimeStamp = Math.max(...allActivitiesEndTimeStamps);

        // ? In case we don't have an end date or the end date is less then the end of the day of the start date, we will set as the end time stamp the end of the day of the start date
        endTimeStamp = Math.max(normalizeDate(startTimeStamp) + END_OF_DAY_OFFSET, maxActivitiesEndTimeStamp);
    }

    return { startTimeStamp, endTimeStamp };
};

/**
 * ? Calculates the values (start and end) for the current and initial dates reported to the interval segment
 * @param {Number} intervalStart - The interval start time stamp to calculate
 * @param {Number} intervalEnd - The interval end time stamp to calculate
 * @param {Number} currentStart - The current start time stamp to calculate
 * @param {Number} currentEnd - The current start time stamp to calculate
 * @param {Boolean} hasInitial - A value witch specifies if the activity has initial start date or not
 * @param {Number} initialStart - The initial start time stamp to calculate
 * @param {Number} initialEnd - The initial start time stamp to calculate
 * @returns {object} The values (start and end) for the current and initial dates on the interval segment
 */
const getActivityCurrentAndInitialPercents = (
    intervalStart,
    intervalEnd,
    currentStart,
    currentEnd,
    hasInitial,
    initialStart,
    initialEnd
) => {
    const current = {
        left: null,
        right: null,
    };

    const initial = {
        left: null,
        right: null,
    };

    const currentLeft = ((currentStart - intervalStart) / (intervalEnd - intervalStart)) * 100;
    const currentRight = ((currentEnd - intervalStart) / (intervalEnd - intervalStart)) * 100;

    current['left'] = currentLeft;
    current['right'] = currentRight;

    // ? We calculate the initial percentages only the activity has an initial date
    if (hasInitial) {
        const initialLeft = ((initialStart - intervalStart) / (intervalEnd - intervalStart)) * 100;
        const initialRight = ((initialEnd - intervalStart) / (intervalEnd - intervalStart)) * 100;

        initial['left'] = initialLeft;
        initial['right'] = initialRight;
    }

    return { current, initial };
};

/**
 * The function `findMarginActivityColorType` determines whether a current value exceeds or is prior to an initial value.
 * ? We use this in order to determine the color of the left and right segments witch represent the time of a delay or a faster completion of the activity at both ends
 * @param {Number} currentValue - The `currentValue` parameter represents the current value of a certain
 * activity. It is the value that you want to compare with the `initialValue` parameter.
 * @param {Number} initialValue - The `initialValue` parameter represents the initial value of a certain
 * activity.
 * @returns {String} The function `findMarginActivityColorType` returns either 'exceeded' or 'prior' based on
 * the comparison between `currentValue` and `initialValue`.
 */
const findMarginActivityColorType = (currentValue, initialValue) => {
    if (currentValue > initialValue) return 'exceeded';
    if (currentValue <= initialValue) return 'prior';
};

/**
 * The function `findActivityOffsets` calculates various percentages (offsets) based on the current and initial
 * values, taking into account intersecting and non-intersecting scenarios.
 * @param {Object} current - The `current` parameter represents the current  position of an activity. It
 * contains properties `left` and `right`, which represent the left and right boundaries of the
 * activity.
 * @param {Object} initial - The `initial` parameter represents the initial  position of an activity. It
 * contains the `left` and `right` boundaries of the activity.
 * @returns {Object} The function `findActivityOffsets` returns an object with the following properties: startLeft, startRight, centerLeft, centerRight, endLeft, endRight, deadZoneLeft, deadZoneRight,
 */
const findActivityOffsets = (current, initial) => {
    let startLeft, startRight, centerLeft, centerRight, endLeft, endRight, deadZoneLeft, deadZoneRight;

    // ? We get the left and right boundaries of the interval
    const leftBoundary = Math.max(current.left, initial.left);
    const rightBoundary = Math.min(current.right, initial.right);

    // ? We check if the intervals are intersecting
    const isIntersecting = leftBoundary < rightBoundary;

    // ? If the intervals are not intersecting we assign the start to the initial, the center to the current, and we determine the dead zone values
    if (!isIntersecting) {
        startLeft = initial.left;
        startRight = initial.right;
        centerLeft = current.left;
        centerRight = current.right;
        deadZoneLeft = Math.min(leftBoundary, rightBoundary);
        deadZoneRight = Math.max(leftBoundary, rightBoundary);
    }

    // ? If the intervals are intersecting we have to determine the start, center and end values
    if (isIntersecting) {
        startLeft = Math.min(current.left, initial.left);
        startRight = Math.max(current.left, initial.left);
        centerLeft = Math.max(current.left, initial.left);
        centerRight = Math.min(current.right, initial.right);
        endLeft = Math.min(current.right, initial.right);
        endRight = Math.max(current.right, initial.right);
    }

    return {
        startLeft,
        startRight,
        centerLeft,
        centerRight,
        endLeft,
        endRight,
        deadZoneLeft,
        deadZoneRight,
    };
};

/**
 * The `formatStops` function takes in a `stops` object and an `append_to` value, and returns a new
 * array of stops sorted based on their offset values and a corresponding order array.
 * @param {object} stops - The `stops` parameter is an object that contains nested objects. Each nested object
 * represents a stop and has a unique key. The keys of the nested objects represent the parent key and
 * the child key of the stop.
 * @param {String} append_to - The `append_to` parameter is a value that will be added to each stop object in
 * the `newStops` array.
 * @returns {object} The function `formatStops` returns an object with two properties: `stops` and `order`. The
 * `stops` property contains an array of objects, where each object represents a stop and its
 * corresponding values. The `order` property contains an array of strings, representing the order in
 * which the stops should be displayed.
 */
const formatStops = (stops, append_to) => {
    const newStops = [];

    Object.entries(stops).forEach(([pKey, pValues]) => {
        Object.entries(pValues).forEach(([cKey, cValue]) => {
            newStops.push({
                [`stop-${pKey}-${cKey}`]: { ...cValue, sortKey: `stop-${pKey}`, append_to },
            });
        });
    });

    newStops.sort((a, b) => {
        const aKey = Object.keys(a)[0].split('-', 2)[1];
        const bKey = Object.keys(b)[0].split('-', 2)[1];

        const aValue = +Object.values(a)[0].offset.split('%')[0];
        const bValue = +Object.values(b)[0].offset.split('%')[0];

        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;

        // ? This step is done in order to sort in the correct order the start and the end of a segement
        const indexOfPrevious = newStops.findIndex((el) => Object.keys(el)[0] === Object.keys(a)[0]);
        const prevElement = newStops[indexOfPrevious - 1];
        if (!prevElement) return 0;

        const prevElementKey = Object.keys(prevElement)[0].split('-', 2)[1];
        if (prevElementKey === aKey && aKey !== bKey) return -1;
    });

    const sortOrder = Object.keys(Object.assign({}, ...newStops));

    return { stops: newStops, order: sortOrder };
};

/**
 * ? Returns all the properties needed to create the fill object of an activity
 * @param {object} current - The values of the current start time stamp reported to the interval segment
 * @param {Boolean} hasInitial - A value witch specifies if the activity has initial start date or not
 * @param {object} initial - The values of the initial start time stamp  reported to the interval segment
 * @param {String} type - Activity type (project structure, section, activity)
 * @param {Number} opacity - The opacity used for the fill colors
 * @param {String} ganttActivityId - Only used to create distinct elements for the activity to be rendered in the gantt
 * @param {Boolean} isDone - A value witch specifies if the activity is marked as done
 * @param {Boolean} isNew - A value witch specifies if the activity is marked as new
 * @param {Boolean} isOverdue - A value witch specifies if the activity is overdue
 * @returns {object} The fill object
 */
const getActivityStops = (current, hasInitial, initial, type, opacity, ganttActivityId, isDone, isNew, isOverdue) => {
    // ? We extract the possible colors
    const doneColor = doneActivityColorTypes[type][isNew ? 'new' : 'default'];
    const overdueColor = overdueActivityColorTypes[type][isNew ? 'new' : 'default'];
    const mainColor = mainActivityColorTypes[type][isNew ? 'new' : 'default'];

    // ? Determine witch color we should use to fill common part of an activity segment
    const centerColor = isDone ? doneColor : isOverdue ? overdueColor : mainColor;

    // ? We get the percentages for the segments needed to fill the activity element in the gantt
    // ! We only calculate all of them when we have an initial date of the activity
    const { startLeft, startRight, centerLeft, centerRight, endLeft, endRight, deadZoneLeft, deadZoneRight } =
        hasInitial ? findActivityOffsets(current, initial) : { centerLeft: current.left, centerRight: current.right };

    const activityStops = {};
    let currentHighlight = null;
    let initialHighlight = null;

    // ? We check if the activity has an end segment
    const hasEnd = typeof endLeft === 'number' && typeof endRight === 'number';

    // ? We check if the activity has a dead zone segment
    // ! Dead zone segments represents the segment between the initial and current activity segment if this to din't intersect
    const hasDeadZone = typeof deadZoneLeft === 'number' && typeof deadZoneRight === 'number';

    // ? If we have an initial date we will create the start segment (witch can be 0% to 0%)
    if (hasInitial) {
        const startColor = marginActivityColorTypes[findMarginActivityColorType(current.left, initial.left)];
        const start = {
            left: {
                offset: `${startLeft}%`,
                'stop-color': startColor,
                'stop-opacity': `${opacity}%`,
            },
            right: {
                offset: `${startRight}%`,
                'stop-color': startColor,
                'stop-opacity': `${opacity}%`,
            },
        };
        activityStops['start'] = { ...start };

        currentHighlight = {
            leftOffset: current.left,
            rightOffset: current.right,
            color: centerColor,
            opacity: 100,
        };
        initialHighlight = {
            leftOffset: initial.left,
            rightOffset: initial.right,
            color: '#374151',
            opacity: 100,
        };
    }

    // ? If we have an initial date and dead zone percentages we will create the dead zone segment (witch can be 0% to 0%)
    if (hasDeadZone && hasInitial) {
        const deadZone = {
            left: {
                offset: `${deadZoneLeft}%`,
                'stop-color': '#64748b',
                'stop-opacity': `${opacity}%`,
            },
            right: {
                offset: `${deadZoneRight}%`,
                'stop-color': '#64748b',
                'stop-opacity': `${opacity}%`,
            },
        };
        activityStops['deadZone'] = { ...deadZone };
    }

    const center = {
        left: {
            offset: `${centerLeft}%`,
            'stop-color': centerColor,
            'stop-opacity': `${opacity}%`,
        },
        right: {
            offset: `${centerRight}%`,
            'stop-color': centerColor,
            'stop-opacity': `${opacity}%`,
        },
    };
    activityStops['center'] = { ...center };

    // ? If we have an initial date and end percentages we will create the end segment (witch can be 0% to 0%)
    if (hasEnd && hasInitial) {
        const endColor = marginActivityColorTypes[findMarginActivityColorType(current.right, initial.right)];
        const end = {
            left: {
                offset: `${endLeft}%`,
                'stop-color': endColor,
                'stop-opacity': `${opacity}%`,
            },
            right: {
                offset: `${endRight}%`,
                'stop-color': endColor,
                'stop-opacity': `${opacity}%`,
            },
        };
        activityStops['end'] = { ...end };
    }

    const formattedActivityStops = formatStops(activityStops, `bar-gradient-activity-${ganttActivityId}`);

    return {
        activityStops: formattedActivityStops,
        currentHighlight,
        initialHighlight,
    };
};

/**
 * ? Gets the fill object used by the gantt in order to render an activity and the initial and current dates of the activity
 * @param {object} activity - The activity for witch we return the information about
 * @param {String} type - The activity type
 * @param {Number} intervalStartTimeStamp - The interval start time stamp
 * @param {Number} intervalEndTimeStamp - The interval end time stamp
 * @param {Number} opacity - The opacity for the fill color
 * @param {Boolean} hasInitial - This param indicates if the activity has initial dates or not
 * @returns {object} Contains the fill object and the initial and current dates
 */
const getActivityFill = (activity, type, intervalStartTimeStamp, intervalEndTimeStamp, opacity, hasInitial) => {
    // ? The current time stamps
    const { startTimeStamp: currentStartTimeStamp, endTimeStamp: currentEndTimeStamp } = getActivityTimeStamps(
        activity,
        type,
        {
            main: {
                date: 'startDate',
                days: 'realizationDays',
            },
        },
        false
    );

    // ? The initial time stamps
    const { startTimeStamp: initialStartTimeStamp, endTimeStamp: initialEndTimeStamp } = getActivityTimeStamps(
        activity,
        type,
        {
            main: {
                date: 'initialStartDate',
                days: 'initialRealizationDays',
            },
            backup: {
                date: 'startDate',
                days: 'realizationDays',
            },
        },
        type !== 'activity'
    );

    const mappedGanttActivityId = {
        projectStructure: activity.id,
        section: activity.referenceKey,
        activity: activity.referenceKey,
    };
    // ? This is only used to create an unique id for some of the fill elements needed to properly render an activity in the gantt
    const ganttActivityId = mappedGanttActivityId[type];

    // ? We check if the activity is marked as done
    const isDone = activity.status === 'DONE';

    // ? We check if this is a new activity
    const isNew = activity?.isNew ?? false;

    // ? We check if the activity is overdue
    const isOverdue = hasTimeForCompletionPassed(new Date(currentEndTimeStamp), activity.status);

    const { current, initial } = getActivityCurrentAndInitialPercents(
        intervalStartTimeStamp,
        intervalEndTimeStamp,
        currentStartTimeStamp,
        currentEndTimeStamp,
        hasInitial || currentEndTimeStamp !== initialEndTimeStamp,
        initialStartTimeStamp,
        initialEndTimeStamp
    );

    const { activityStops, currentHighlight, initialHighlight } = getActivityStops(
        current,
        hasInitial || currentEndTimeStamp !== initialEndTimeStamp,
        initial,
        type,
        opacity,
        ganttActivityId,
        isDone,
        isNew,
        isOverdue
    );

    return {
        fill: { activityStops, currentHighlight, initialHighlight },
        currentStart: new Date(currentStartTimeStamp),
        currentEnd: new Date(currentEndTimeStamp),
        initialStart: new Date(initialStartTimeStamp),
        initialEnd: new Date(initialEndTimeStamp),
    };
};

/**
 * ? We return all the needed info about the activity in order to create an object (start, end, currentStart, currentEnd, initialStart, initialEnd time stamps and the fill object) witch can be rendered inside the gantt
 * @param {object} activity - The activity used to find all the info
 * @returns {object} We return all the info about the activity
 */
const getActivityInfo = (activity) => {
    // ? If the activity object has the realizationDays property we conclude that this "activity" is of the type activity
    if (Object.hasOwn(activity, 'realizationDays')) {
        // ? We check if the activity has an initial start date
        const hasInitialStartDate = activity.initialStartDate && activity.initialRealizationDays;

        // ? We get the interval of the activity witch represents the most early date and the most recent date based on both current and initial dates
        const startTimeStamp = findActivityIntervalStartTimeStamp(activity, hasInitialStartDate);
        const endTimeStamp = findActivityIntervalEndTimeStamp(activity, hasInitialStartDate);

        const { fill, currentStart, currentEnd, initialStart, initialEnd } = getActivityFill(
            activity,
            'activity',
            startTimeStamp,
            endTimeStamp,
            85,
            hasInitialStartDate
        );

        return {
            start: new Date(startTimeStamp),
            end: new Date(endTimeStamp),
            fill,
            currentStart,
            currentEnd,
            initialStart,
            initialEnd,
        };
    }

    // ? If the activity object has the activities property we conclude that this "activity" is of the type section
    if (Object.hasOwn(activity, 'activities')) {
        // ? We check if the section has an initial start date
        const hasInitialStartDate = activity.initialStartDate;

        // ? We get all the end time stamps for all the activities inside the section
        const { activitiesEndTimeStamps } = findActivityIntervalEndTimeStamps(activity.activities);

        // ? We get the interval start time stamp of the section
        const startTimeStamp = findActivityIntervalStartTimeStamp(activity, hasInitialStartDate);

        // ? We find the most recent end time stamp based on the activities of the section
        const maxActivitiesEndTimeStamp = Math.max(...activitiesEndTimeStamps);

        // ? In case we don't have an end date or the end date is less then the end of the day of the start date, we will set as the interval end time stamp the end of the day of the start date
        const endTimeStamp = Math.max(normalizeDate(startTimeStamp) + END_OF_DAY_OFFSET, maxActivitiesEndTimeStamp);

        const { fill, currentStart, currentEnd, initialStart, initialEnd } = getActivityFill(
            activity,
            'section',
            startTimeStamp,
            endTimeStamp,
            85,
            hasInitialStartDate
        );

        return {
            start: new Date(startTimeStamp),
            end: new Date(endTimeStamp),
            fill,
            currentStart,
            currentEnd,
            initialStart,
            initialEnd,
        };
    }

    // ? If the activity object has the milestones property we conclude that this "activity" is of the type projectStructure
    if (Object.hasOwn(activity, 'milestones')) {
        // ? We check if the project structure has an initial start date
        const hasInitialStartDate = activity.initialStartDate;

        // ? We get all the end time stamps for all the activities inside the project structure
        let allActivitiesEndTimeStamps = [];
        for (const m of activity.milestones) {
            const { activitiesEndTimeStamps } = findActivityIntervalEndTimeStamps(m.activities);
            allActivitiesEndTimeStamps = [...allActivitiesEndTimeStamps, ...activitiesEndTimeStamps];
        }

        // ? We get the interval start time stamp of the project structure
        const startTimeStamp = findActivityIntervalStartTimeStamp(activity, hasInitialStartDate);

        // ? We find the most recent end time stamp based on the activities of the project structure
        const maxActivitiesEndTimeStamp = Math.max(...allActivitiesEndTimeStamps);

        // ? In case we don't have an end date or the end date is less then the end of the day of the start date, we will set as the interval end time stamp the end of the day of the start date
        const endTimeStamp = Math.max(normalizeDate(startTimeStamp) + END_OF_DAY_OFFSET, maxActivitiesEndTimeStamp);

        const { fill, currentStart, currentEnd, initialStart, initialEnd } = getActivityFill(
            activity,
            'projectStructure',
            startTimeStamp,
            endTimeStamp,
            85,
            hasInitialStartDate
        );

        return {
            start: new Date(startTimeStamp),
            end: new Date(endTimeStamp),
            fill,
            currentStart,
            currentEnd,
            initialStart,
            initialEnd,
        };
    }
};

/**
 * ? Will return an array of activities in order to display them inside the gantt component
 * @param {Array} projectStructures - The project structures used to create the activities array
 * @returns {Array} Activities ready to be displayed in the gantt component
 */
export const getActivities = (projectStructures) => {
    const activities = [];
    let currentHeight = INITIAL_HEIGHT;
    const disabledCropHeightsIntervals = [...DEFAULT_DISABLED_CROP_HEIGHTS_INTERVALS];

    for (const projectStructure of projectStructures) {
        const paddingX = '0.5rem';
        const padding = `${PADDING_Y} ${paddingX}`;

        const { start, end, currentStart, currentEnd, initialStart, initialEnd, fill } =
            getActivityInfo(projectStructure);

        const paddingOffset = remToPx(paddingX) * 2;
        const { height, disabledCropHeightsInterval } = getRowHeight(
            projectStructure.name,
            paddingOffset,
            currentHeight,
            'font-bold'
        );
        disabledCropHeightsIntervals.push(disabledCropHeightsInterval);
        currentHeight += height;

        activities.push({
            id: projectStructure.id,
            name: projectStructure.name,
            description: projectStructure.description,
            start,
            end,
            currentStart,
            currentEnd,
            initialStart,
            initialEnd,
            progress: 100,
            fill,
            styles: { padding, fontWeight: 'bold' },
            rowStyles: { height: `${height}px` },
            type: 'projectStructure',
        });

        if (projectStructure.milestones.length > 0) {
            for (const milestone of projectStructure.milestones) {
                const paddingLeft = '0.5rem';
                const paddingRight = '1.5rem';
                const padding = `${PADDING_Y} ${paddingLeft} ${PADDING_Y} ${paddingRight}`;
                // ? If the section has as a parent another section then we set that section as the parent otherwise we use the project structure
                const dependencies = [milestone.dependency ?? projectStructure.id];
                const { start, end, currentStart, currentEnd, initialStart, initialEnd, fill } =
                    getActivityInfo(milestone);

                const paddingOffset = remToPx(paddingLeft) + remToPx(paddingRight);
                const { height, disabledCropHeightsInterval } = getRowHeight(
                    milestone.name,
                    paddingOffset,
                    currentHeight,
                    'font-bold'
                );
                disabledCropHeightsIntervals.push(disabledCropHeightsInterval);
                currentHeight += height;

                activities.push({
                    id: milestone.referenceKey,
                    name: milestone.name,
                    description: milestone.description,
                    start,
                    end,
                    currentStart,
                    currentEnd,
                    initialStart,
                    initialEnd,
                    progress: 100,
                    dependencies,
                    fill,
                    styles: { padding, fontWeight: 'bold' },
                    rowStyles: { height: `${height}px` },
                    type: 'section',
                });

                if (milestone.activities.length > 0) {
                    for (const activity of milestone.activities) {
                        const paddingLeft = '0.5rem';
                        const paddingRight = '2.5rem';
                        const padding = `${PADDING_Y} ${paddingLeft} ${PADDING_Y} ${paddingRight}`;
                        // ? If the activity has as a parent another activity then we set that activity as the parent otherwise we use the project structure
                        const dependencies = [activity.dependency ?? milestone.referenceKey];
                        const { start, end, currentStart, currentEnd, initialStart, initialEnd, fill } =
                            getActivityInfo(activity);

                        const paddingOffset = remToPx(paddingLeft) + remToPx(paddingRight);
                        const { height, disabledCropHeightsInterval } = getRowHeight(
                            activity.name,
                            paddingOffset,
                            currentHeight
                        );
                        disabledCropHeightsIntervals.push(disabledCropHeightsInterval);
                        currentHeight += height;

                        activities.push({
                            id: activity.referenceKey,
                            name: activity.name,
                            description: milestone.description,
                            start,
                            end,
                            currentStart,
                            currentEnd,
                            initialStart,
                            initialEnd,
                            progress: 100,
                            dependencies,
                            fill: fill,
                            styles: { padding },
                            rowStyles: { height: `${height}px` },
                            type: 'activity',
                        });
                    }
                }
            }
        }
    }

    return [activities, disabledCropHeightsIntervals];
};

/**
 * ? Returns an array of sorted milestones by date (oldest to newest)
 * @param {Array} milestones - Milestones to be sorted by
 * @returns {Array} The sorted array of milestones by date
 */
const getSortedMilestones = (milestones) =>
    milestones.sort((a, b) => new Date(a.createAt).getTime() - new Date(b.createAt).getTime());

/**
 * ? Sorts the selected milestones by the specified type, between the selected interval if it's the case
 * @param {Array} milestones - The milestones witch need to be sorted by
 * @param {object} ganttFilterData - Represents the type of the sorting, the minimum and the maximum dates of the displayed gantt, and what milestones should be displayed in the gantt
 * @returns {Array} The sorted array of milestones
 */
export const getFilteredMilestones = (milestones, ganttFilterData) => {
    // ? Sorts all milestones
    if (ganttFilterData.type === 0) {
        const sortedMilestones = getSortedMilestones(milestones);

        return sortedMilestones;
    }

    // ? Sorts the selected milestones
    if (ganttFilterData.type === 1 || ganttFilterData.type === 3) {
        const fMilestones = [];

        for (const selectedMilestone of ganttFilterData.milestones) {
            fMilestones.push(milestones[selectedMilestone]);
        }

        const sortedMilestones = getSortedMilestones(fMilestones);

        return sortedMilestones;
    }

    // ? Sorts only the milestones that are included in the interval
    if (ganttFilterData.type === 2) {
        const fMilestones = [];
        const startDate = new Date(ganttFilterData.startDate).getTime();
        const endDate = new Date(ganttFilterData.endDate).getTime();

        for (const milestone of milestones) {
            const milestoneStartDate = new Date(milestone.activitiesTasks.at(0).estimatedStartDate).getTime();
            const milestoneEndDate = new Date(milestone.activitiesTasks.at(-1).estimatedEndDate).getTime();

            if (
                (milestoneStartDate <= startDate && milestoneEndDate <= endDate) ||
                (milestoneStartDate >= startDate && milestoneEndDate >= endDate) ||
                (milestoneStartDate <= startDate && milestoneEndDate >= endDate) ||
                (milestoneStartDate >= startDate && milestoneEndDate <= endDate)
            ) {
                fMilestones.push(milestone);
            }
        }

        const sortedMilestones = getSortedMilestones(fMilestones);

        return sortedMilestones;
    }

    // ? Sorts only the milestones that are at least part of the specified interval
    if (ganttFilterData.type === 4) {
        const fMilestones = [];
        const startDate = new Date(ganttFilterData.startDate).getTime();
        const endDate = new Date(ganttFilterData.endDate).getTime();

        for (const milestone of milestones) {
            const milestoneStartDate = new Date(milestone.activitiesTasks.at(0).estimatedStartDate).getTime();
            const milestoneEndDate = new Date(milestone.activitiesTasks.at(-1).estimatedEndDate).getTime();

            if (milestoneStartDate >= startDate && milestoneEndDate <= endDate) {
                fMilestones.push(milestone);
            }
        }

        const sortedMilestones = getSortedMilestones(fMilestones);

        return sortedMilestones;
    }
};
