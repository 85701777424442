import React from 'react';

import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PropTypes from 'prop-types';

const List = ({ options, selectedOption, setSelectedOption, disabled }) => {
    return (
        <MuiList>
            {options.map((option, index) => (
                <ListItem
                    disabled={disabled}
                    button
                    key={index}
                    selected={selectedOption === index}
                    onClick={() => setSelectedOption(index === selectedOption ? null : index)}
                >
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText primary={option.label} />
                </ListItem>
            ))}
        </MuiList>
    );
};

List.propTypes = {
    options: PropTypes.array,
    selectedOption: PropTypes.number,
    setSelectedOption: PropTypes.func,
    disabled: PropTypes.bool,
};

List.defaultProps = {
    options: [],
    selectedOption: null,
    setSelectedOption: () => {},
    disabled: false,
};

export default List;
