import React from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import DocumentItem from './document-item';

const ElementsColumn = ({ column, elements, placeholderProps }) => {
    const { t } = useTranslation();

    return (
        <div
            key={column.id}
            className="sticky top-4 flex flex-col gap-4 self-start rounded-md bg-layout-light p-4 pb-0"
        >
            <h3 className="text-center text-2xl">{t(column.title)}</h3>
            <Droppable droppableId={column.id} isDropDisabled={column.isDropDisabled} type="elements">
                {(provided, snapshot) => {
                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={`relative flex flex-col ${
                                snapshot.isDraggingOver ? 'rounded-md bg-disabled' : ''
                            }`}
                        >
                            {elements.map((element, index) => (
                                <DocumentItem key={element.id} itemData={element} index={index} />
                            ))}
                            {provided.placeholder}
                            {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                                <div
                                    style={{
                                        top: placeholderProps.clientY,
                                        left: placeholderProps.clientX,
                                        height: placeholderProps.clientHeight,
                                        width: placeholderProps.clientWidth,
                                        backgroundColor: 'rgb(var(--base-layout-main) / 35%)',
                                        position: 'absolute',
                                        borderRadius: '0.375rem',
                                    }}
                                />
                            )}
                        </div>
                    );
                }}
            </Droppable>
        </div>
    );
};

ElementsColumn.propTypes = {
    column: PropTypes.object,
    placeholderProps: PropTypes.object,
    elements: PropTypes.array,
};

ElementsColumn.defaultProps = {
    column: {},
    placeholderProps: {},
    elements: [],
};

export default ElementsColumn;
