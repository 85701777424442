import React, { useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { configurator, projectManagement } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';

const DepartmentDetails = ({ department, id, area, setInitializeData }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [departmentName, setDepartmentName] = useState('');
    const [observations, setObservations] = useState('');
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '14',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    const createDepartment = async () => {
        if (departmentName.length < 2) {
            enqueueSnackbar(t('The department name must be at least 2 characters long!'), { variant: 'error' });
            return;
        }

        setLoading(true);
        setInitializeData?.((prev) => ({ ...prev, loading: true }));
        try {
            if (area === 'ERP') {
                await API.post('/departments', {
                    name: departmentName,
                    observation: observations,
                });

                if (setInitializeData) setInitializeData((prev) => ({ ...prev, tab: 0 }));
                else history.push(configurator.base + `?tab=CRM Departments`);
            } else {
                await API.post('/pmDepartments', {
                    name: departmentName,
                    observation: observations,
                    type: 'PLANNING_OVERVIEW',
                });
                history.push(projectManagement.base + projectManagement.projectManagementConfigurator.base + `?tab=1`);
            }

            enqueueSnackbar(t('The department was successfully created!'), {
                variant: 'success',
            });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        } finally {
            setLoading(false);
            setInitializeData?.((prev) => ({ ...prev, loading: false }));
        }
    };

    const updateDepartment = async () => {
        if (departmentName.length < 3) {
            enqueueSnackbar(t('The department name must be at least 3 characters long!'), { variant: 'error' });
            return;
        }

        setLoading(true);
        try {
            if (area === 'ERP') {
                await API.put('/departments', {
                    data: {
                        name: departmentName,
                        observation: observations,
                    },
                    id: id,
                });
                history.push(configurator.base + '?tab=CRM Departments');
            } else {
                await API.put(`/pmDepartment/${id}`, {
                    name: departmentName,
                    observation: observations,
                });
                history.push(projectManagement.base + projectManagement.projectManagementConfigurator.base + `?tab=1`);
            }

            enqueueSnackbar(t('The department was successfully updated!'), {
                variant: 'success',
            });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (department) {
            setDepartmentName(department?.name);
            setObservations(department?.observation);
        }
    }, [department]);

    return (
        <>
            {canAll && (
                <>
                    {loading ? (
                        <div className="flex h-svh w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="mt-10 max-w-sm">
                            <h4 className="mb-10 text-2xl text-dark-text">{t('Department details')}</h4>
                            <div className="relative mb-5">
                                <TextField
                                    name={'departament'}
                                    label={t('Department name')}
                                    placeholder={t('Add name to the department')}
                                    value={departmentName}
                                    onChange={(e) => setDepartmentName(e.target.value)}
                                />
                            </div>
                            <div className="relative mb-5">
                                <TextField
                                    name={'Observations'}
                                    label={t('Observations')}
                                    placeholder={t('Observations...')}
                                    value={observations}
                                    multiline
                                    rows={3}
                                    onChange={(e) => setObservations(e.target.value)}
                                />
                            </div>
                            <div className="mt-20">
                                {id ? (
                                    <Button
                                        type="submit"
                                        startIcon={<CheckIcon />}
                                        color="primary"
                                        onClick={() => updateDepartment()}
                                    >
                                        {t('Edit department')}
                                    </Button>
                                ) : (
                                    <Button startIcon={<AddIcon />} color="primary" onClick={() => createDepartment()}>
                                        {t('Add department')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

DepartmentDetails.propTypes = {
    department: PropTypes.object,
    id: PropTypes.string,
    area: PropTypes.string,
    setInitializeData: PropTypes.func,
};

DepartmentDetails.defaultProps = {
    department: null,
    id: null,
    area: 'ERP',
    setInitializeData: null,
};

export default DepartmentDetails;
