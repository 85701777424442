import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { resourceModule } from 'routes';

import AllocationAdd from './allocation-add';
import AllocationAll from './allocation-all';

const AllocationResources = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path={resourceModule.allocationResources.base}>
                    <Redirect to={resourceModule.allocationResources.all} />
                </Route>
                <Route path={resourceModule.allocationResources.all}>
                    <AllocationAll />
                </Route>
                <Route path={resourceModule.allocationResources.add}>
                    <AllocationAdd />
                </Route>
                <Route path={resourceModule.allocationResources.editFull}>
                    <AllocationAdd />
                </Route>
            </Switch>
        </Fragment>
    );
};

export default AllocationResources;
