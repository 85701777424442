import React, { useContext, useState } from 'react';

import { Button } from '@material-ui/core';

import PropTypes from 'prop-types';
import { Dropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

import { DocumentContext } from '../document-template';

const DocumentTemplateMilestoneSelection = ({ onClose, element }) => {
    const { t } = useTranslation();
    const { milestones, handleChangeSelectedMilestone } = useContext(DocumentContext);

    const viewModes = ['Day', 'Week', 'Month'];
    const [selectedMilestone, setSelectedMilestone] = useState(() => {
        if (element.entity[0]) return milestones.findIndex((m) => m.id === element.entity[0].entityId);
        return null;
    });
    const [selectedViewMode, setSelectedViewMode] = useState(() => {
        if (element.entity[0]) return viewModes.findIndex((v) => v === element.entity[0].data.viewMode);
        return null;
    });

    return (
        <div
            className="rounded-t-x flex w-max flex-col gap-10 px-5 py-12"
            style={{
                maxWidth: '90vw',
            }}
        >
            <h2 className="text-center">{t('Select milestone and Gantt view mode')}</h2>
            <div className="mx-10 flex gap-6 sm:flex-wrap sm:px-5">
                <div className="w-full">
                    <Dropdown
                        options={milestones.map((m) => m.title)}
                        placeholder={t('Select milestone')}
                        selectedOption={selectedMilestone}
                        setSelectedOption={setSelectedMilestone}
                    />
                </div>

                <div className="w-full">
                    <Dropdown
                        options={viewModes.map((el) => t(el))}
                        selectedOption={selectedViewMode}
                        setSelectedOption={setSelectedViewMode}
                    />
                </div>
            </div>

            <div className="flex justify-between px-5">
                <Button onClick={onClose}>{t('Cancel')}</Button>
                <Button
                    color="secondary"
                    onClick={() => {
                        onClose();
                        handleChangeSelectedMilestone(element.pairKey, selectedMilestone, viewModes[selectedViewMode]);
                    }}
                    disabled={selectedMilestone === null || selectedViewMode === null}
                >
                    {t('Complete')}
                </Button>
            </div>
        </div>
    );
};

DocumentTemplateMilestoneSelection.propTypes = {
    onClose: PropTypes.func,
    element: PropTypes.object,
};

DocumentTemplateMilestoneSelection.defaultProps = {
    onClose: () => {},
    element: {},
};

export default DocumentTemplateMilestoneSelection;
