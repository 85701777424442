import React from 'react';

import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

const RiemLegend = ({ riem }) => {
    return (
        <div
            className="flex w-max flex-col gap-2 rounded-sm bg-white p-4"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(riem.legend.content) }}
        />
    );
};

RiemLegend.propTypes = {
    riem: PropTypes.object,
};

RiemLegend.defaultProps = {
    riem: {},
};

export default RiemLegend;
