import React, { useContext, useState } from 'react';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { Button } from '@material-ui/core';

import { ActionArrow } from 'components/internal-activity/activity';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { errorHandling, formatDate, formatTime } from 'utils';
import API from 'utils/axios';

const PMWikiComment = (props) => {
    const { comment, tenants, getWikis } = props;

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const globalContext = useContext(GlobalContext);
    const { user } = useContext(UserContext);
    const { checkPerm } = useContext(UserContext);
    const [isDeleting, setIsDeleting] = useState(false);

    const canAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    /**
     * Assignee to a variable the comment author information
     */
    const author = tenants.find(({ id }) => id === comment.authorId);

    const deleteComment = async () => {
        if (isDeleting) return;

        setIsDeleting(true);
        try {
            await API.delete('wikiComment', {
                data: {
                    id: comment.id,
                },
            });
            enqueueSnackbar(comment?.comment ? t('Comment deleted!') : t('Document/s deleted!'), {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
            console.error(err);
        } finally {
            await getWikis();
        }
    };

    return (
        <>
            {comment !== null && (
                <div
                    className={`relative mb-1.5 flex flex-col gap-2 rounded-br-md rounded-tr-md border-l border-secondary-light bg-layout-transparent px-8 py-3 transition-colors hover:bg-layout-transparent-dark ${
                        isDeleting ? 'hidden' : 'block'
                    }`}
                >
                    {canAll && (
                        <div className="absolute right-2 top-2">
                            <ActionArrow
                                actions={
                                    user.id === comment.authorId //We check if the user is the author of the comment(only if is true, user have permission to delete wiki)
                                        ? [
                                              {
                                                  name: t('Delete'),
                                                  action: () => {
                                                      globalContext.setGlobalModalOpen(true);
                                                      globalContext.setGlobalModalChildren(
                                                          <div className="flex-col">
                                                              <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                                  {t('Are you sure you want to')}
                                                                  <br />
                                                                  {t('delete this comment?')}
                                                              </p>
                                                              <div className="flex items-center justify-center">
                                                                  <Button
                                                                      color="primary"
                                                                      onClick={() => {
                                                                          deleteComment();
                                                                          globalContext.setGlobalModalOpen(false);
                                                                      }}
                                                                  >
                                                                      {t('Yes')}
                                                                  </Button>
                                                                  <div className="w-4" />
                                                                  <Button
                                                                      onClick={() =>
                                                                          globalContext.setGlobalModalOpen(false)
                                                                      }
                                                                  >
                                                                      {t('No')}
                                                                  </Button>
                                                              </div>
                                                          </div>
                                                      );
                                                  },
                                              },
                                          ]
                                        : []
                                }
                            />
                        </div>
                    )}

                    {/* Comment */}
                    {comment?.comment !== null && comment?.comment !== '' && (
                        <p className="mb-2 font-semibold">{comment.comment}</p>
                    )}

                    {/* Comment author and date of creation */}
                    <p className="text-dark-text">
                        <span className="font-semibold">{author?.profile?.name}</span>{' '}
                        {`${formatDate(comment.createdAt)} ${formatTime(comment.createdAt)}`}
                    </p>

                    {/* Comment Files */}
                    {comment?.files?.length > 0 &&
                        comment.files.map((file) => (
                            <div className="relative z-20 flex flex-col gap-1" key={file.id}>
                                <div
                                    key={comment.fileId}
                                    className="flex items-center justify-between border-b border-layout-lighter py-1"
                                >
                                    <a className="flex items-center" target="_blank" href={file.urls} rel="noreferrer">
                                        <div className="mr-1 flex h-6 w-6 items-center justify-center">
                                            <InsertDriveFileIcon
                                                className="text-secondary-lighter"
                                                style={{
                                                    fontSize: '1.5rem',
                                                }}
                                            />
                                        </div>
                                        <p className="text-medium mr-2 cursor-pointer py-1 opacity-75 transition-opacity hover:opacity-100">
                                            {file.fileName}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </>
    );
};

PMWikiComment.propTypes = {
    comment: PropTypes.object,
    userId: PropTypes.string,
    tenants: PropTypes.array,
    getWikis: PropTypes.func,
};

PMWikiComment.defaultProps = {
    comment: null,
    userId: null,
    tenants: [],
    getWikis: () => null,
};

export default PMWikiComment;
