import React, { cloneElement, useContext, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import BallotIcon from '@material-ui/icons/Ballot';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EmailIcon from '@material-ui/icons/Email';
import Filter1Icon from '@material-ui/icons/Filter1';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ImageIcon from '@material-ui/icons/Image';
import LinkIcon from '@material-ui/icons/Link';
import PhoneIcon from '@material-ui/icons/Phone';
import TitleIcon from '@material-ui/icons/Title';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';

import { Button, ClickAwayListener } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const BigDropdown = (props) => {
    const { addField } = props;
    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '13',
            permissionType: 'ALL',
        },
    ]);

    const [open, setOpen] = useState(false);

    const fieldIconStyle = {
        fontSize: '1.75rem',
        marginRight: '.75rem',
        flexShrink: 0,
    };

    const fieldColumns = [
        {
            id: 'field-column-1',
            fields: [
                {
                    name: 'Text',
                    type: 'Text',
                    icon: <TitleIcon style={fieldIconStyle} />,
                },
                {
                    name: t('Number'),
                    type: 'Number',
                    icon: <Filter1Icon style={fieldIconStyle} />,
                },
                {
                    name: t('Phone Number'),
                    type: 'Phone',
                    icon: <PhoneIcon style={fieldIconStyle} />,
                },
                {
                    name: 'Email',
                    type: 'Email',
                    icon: <EmailIcon style={fieldIconStyle} />,
                },
                { name: 'Link', type: 'Link', icon: <LinkIcon style={fieldIconStyle} /> },
            ],
        },
        {
            id: 'field-column-2',
            fields: [
                {
                    name: 'Selector',
                    type: 'Selector',
                    icon: <FormatListBulletedIcon style={fieldIconStyle} />,
                },
                {
                    name: t('Structure'),
                    type: 'Structure',
                    icon: <BallotIcon style={fieldIconStyle} />,
                },
                {
                    name: 'Toggle',
                    type: 'Toggle',
                    icon: <ToggleOffIcon style={fieldIconStyle} />,
                },
                {
                    name: 'Data',
                    type: 'Date',
                    icon: <DateRangeIcon style={fieldIconStyle} />,
                },
            ],
        },
        {
            id: 'field-column-3',
            fields: [
                {
                    name: t('Picture'),
                    type: 'Image',
                    icon: <ImageIcon style={fieldIconStyle} />,
                },
                {
                    name: t('File'),
                    type: 'File',
                    icon: <AttachFileIcon style={fieldIconStyle} />,
                },
            ],
        },
        // {
        //     id: 'field-column-3',
        //     fields: [
        //         { name: t('Atribute Criteria'), type: 'Criterion', icon: <StorageIcon style={fieldIconStyle} /> },
        //         { name: t('Reference'), type: 'Reference', icon: <MenuOpenIcon style={fieldIconStyle} /> },
        //     ],
        // },
    ];

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className="relative">
                {canAll && (
                    <Button
                        startIcon={<AddIcon className="text-buttons-text" />}
                        color="secondary"
                        onClick={() => setOpen(true)}
                    >
                        {t('Add Fields')}
                    </Button>
                )}

                <div
                    className={`absolute left-0 top-full grid translate-y-2 transform grid-cols-3 gap-0.5 rounded-lg bg-primary-light p-2 transition-all duration-200 ${
                        open
                            ? 'pointer-events-auto translate-y-0 opacity-100'
                            : 'pointer-events-none translate-y-8 opacity-0'
                    }`}
                    style={{ width: '380px' }}
                >
                    {fieldColumns.map((fieldCol) => (
                        <div key={fieldCol.id}>
                            {fieldCol.fields.map((field) => (
                                <div
                                    key={field.type}
                                    className="no-user-select-recursive mb-0.5 flex cursor-pointer items-center justify-start rounded-md px-2 py-2 transition-colors last:mb-0 hover:bg-layout-transparent-dark"
                                    onClick={() => {
                                        addField(field.type);
                                        setOpen(false);
                                    }}
                                >
                                    {cloneElement(field.icon, { className: 'text-buttons-text' })}
                                    <p className="flex-shrink-0 text-buttons-text">{field.name}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </ClickAwayListener>
    );
};

BigDropdown.propTypes = {
    addField: PropTypes.func,
};

BigDropdown.defaultProps = {
    addField: () => null,
};

export default BigDropdown;
