import React from 'react';

import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import AffiliateAccountForm from './affiliate_account_form';

export default function AddAffiliate({ pmId }) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('PM Configurator')} | {t('Add affiliate account on PM')}
                </title>
            </Helmet>

            <Header pageTitle={t('Add affiliate account on PM')} />

            <div className="page-container">
                <AffiliateAccountForm pmId={pmId} />
            </div>
        </React.Fragment>
    );
}

AddAffiliate.propTypes = {
    pmId: PropTypes.string,
};

AddAffiliate.defaultProps = {
    pmId: '',
};
