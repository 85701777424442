import React, { Fragment, useContext, useRef, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import NoImage from '../../../../assets/common/no-image.png';

import { Button } from '@material-ui/core';

import Gallery from 'components/crm/estate/gallery';
import GalleryEditable from 'components/crm/estate/gallery-editable';
import CustomModal from 'components/modals/custom_modal';
import ErrorButton from 'components/shared/error-button';
import FileUploadContainer from 'components/shared/file-upload-container';
import DOMPurify from 'dompurify';
import { useSnackbar } from 'notistack';
import EditElementModal from 'pages/configurator/documents_configurator/documents_items/edit-element-modal';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

import { DocumentContext } from '../document-template';
import DocumentTemplateMilestoneSelection from './document-template-milestone-selection';
import DocumentTemplateMilestonesListSelection from './document-template-milestones-list-selection';
import DocumentTemplateOfferSelection from './document-template-offer-selection';

const DocumentTemplateItem = ({ element, highlightAllPairs }) => {
    const { t } = useTranslation();

    const purposeMapper = {
        default: '',
        milestone: ' - Milestone',
        milestones: ` - ${t('Milestones')}`,
        offer: ` - ${t('Offer')}`,
    };

    const titleMapper = (type, purpose) =>
        ({
            title: (
                <h4 className="text-center">
                    {t('Title')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            subtitle: (
                <h4 className="text-center">
                    {t('Subtitle')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            image: (
                <h4 className="text-center">
                    {t('Image')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            gallery: (
                <h4 className="text-center">
                    {t('Gallery')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            content: (
                <h4 className="text-center">
                    {t('Content')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            table: (
                <h4 className="text-center">
                    {t('Table')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            milestone: (
                <h4 className="text-center">
                    {t('Milestone template')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            milestones: (
                <h4 className="text-center">
                    {t('Milestones list')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            offer: (
                <h4 className="text-center">
                    {t('Offer details')}
                    {purposeMapper[purpose]}
                </h4>
            ),
            signature: (
                <h4 className="text-center">
                    {t('Signature')}
                    {purposeMapper[purpose]}
                </h4>
            ),
        })[type];

    const { enqueueSnackbar } = useSnackbar();
    const {
        isMultiView,
        highlightPair,
        setHighlightPair,
        highlightReference,
        setHighlightReference,
        handleChangeItem,
        templateData,
        usedTemplate,
        rawTemplate,
        handleRemoveItem,
        handleRemovePair,
    } = useContext(DocumentContext);

    //? We set the actual element to the element in the main column only when the element is not multi language
    const actualElement =
        element.referenceId && !element.isMultiLanguage
            ? Object.values(templateData.elements).find((el) => el.id === element.referenceId)
            : element;

    //? We can delete an element only when the currently used template is the same as the last and the element can't be found anymore in the new version of the template
    const canDeleteElement =
        usedTemplate && usedTemplate.templateId === templateData.id && !rawTemplate.elements[element.id];

    const [open, setOpen] = useState(false);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [galleryPhotoIndex, setGalleryPhotoIndex] = useState(0);
    const [openMilestoneSelection, setOpenMilestoneSelection] = useState(false);
    const [openMilestonesListSelection, setOpenMilestonesListSelection] = useState(false);

    const isImage = element.itemType === 'image';
    const isGallery = element.itemType === 'gallery';
    const isTable = element.itemType === 'table';
    const isText = !isImage && !isTable && !isGallery;
    const isDefaultPurpose = element.purpose === 'default';
    const isMilestonePurpose = element.purpose === 'milestone';
    const isMilestonesPurpose = element.purpose === 'milestones';
    const isOfferPurpose = element.purpose === 'offer';

    //? We use this reference in order to keep track of what row or column is being edited in a table
    const tableIndexInfo = useRef(null);

    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);
    const handleOpenSelection = () => setOpenMilestoneSelection(true);
    const handleCloseSelection = () => setOpenMilestoneSelection(false);
    const handleOpenListSelection = () => setOpenMilestonesListSelection(true);
    const handleCloseListSelection = () => setOpenMilestonesListSelection(false);
    const handleImageUpload = (e) => {
        const rawImage = e.target.files[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(rawImage);
        fileReader.onload = async (event) => {
            try {
                handleChangeItem(actualElement.id, 'value', { url: event.target.result, blob: rawImage });
            } catch (err) {
                console.error(err);
                enqueueSnackbar(t('Could not upload file! Try again!'), { variant: 'error' });
            }
        };
    };
    const handleGalleryUpload = (e) => handleChangeItem(actualElement.id, 'value', e);

    const mappedEditTextValue = {
        default: actualElement.value,
        table: tableIndexInfo.current?.value,
    };
    const editTextValue =
        mappedEditTextValue[
            (() => {
                if (isText) return 'default';
                if (isTable) return 'table';
            })()
        ];

    /* The below code defines an object `mappedEditTextFunctions` that contains different functions for
    handling text editing based on different scenarios. The `default` function is used for general
    text editing and updates the value of an element and closes a modal. The `table` function is
    specifically used for editing text within a table structure. It updates the value of a table's
    columns or rows based on the type of table info provided and closes a modal afterwards. The
    `tableIndexInfo.current` reference is used to keep track of the table information being edited. */
    const mappedEditTextFunctions = {
        default: (value) => {
            handleChangeItem(actualElement.id, 'value', value);
            setOpen(false);
        },
        table: (value) => {
            const tableInfo = tableIndexInfo.current;

            if (tableInfo.type === 'column') {
                const newColumns = structuredClone(actualElement.value.columns);
                newColumns[tableInfo.index] = value;

                const newValue = { ...actualElement.value, columns: newColumns };
                handleChangeItem(actualElement.id, 'value', newValue);
            }

            if (tableInfo.type === 'row') {
                const newRows = structuredClone(actualElement.value.rows);
                newRows[tableInfo.index][tableInfo.vIndex] = value;

                const newValue = { ...actualElement.value, rows: newRows };
                handleChangeItem(actualElement.id, 'value', newValue);
            }

            tableIndexInfo.current = null;
            setOpen(false);
        },
    };
    const handleEditText =
        mappedEditTextFunctions[
            (() => {
                if (isText) return 'default';
                if (isTable) return 'table';
            })()
        ];

    const handleAddNewColumn = () => {
        const newColumns = structuredClone(actualElement.value.columns);
        newColumns.push(`<p>${t('New header')}</p>`);

        const newRows = structuredClone(actualElement.value.rows);
        for (let i = 0; i < newRows.length; i++) {
            for (let j = 0; j < newColumns.length; j++) {
                if (j < newColumns.length - 1) continue;
                newRows[i].push(`<p>${t('New value')}</p>`);
            }
        }

        const newValue = { ...actualElement.value, rows: newRows, columns: newColumns };
        handleChangeItem(actualElement.id, 'value', newValue);
    };

    const handleAddNewRow = () => {
        const newRows = structuredClone(actualElement.value.rows);
        newRows.push(Array.from({ length: actualElement.value.columns.length }, () => `<p>${t('New value')}</p>`));

        const newValue = { ...actualElement.value, rows: newRows };
        handleChangeItem(actualElement.id, 'value', newValue);
    };

    const handleDeleteColumn = (index) => {
        const newColumns = structuredClone(actualElement.value.columns);
        newColumns.splice(index, 1);

        const newRows = structuredClone(actualElement.value.rows);
        for (let i = 0; i < newRows.length; i++) {
            newRows[i].splice(index, 1);
        }

        const newValue = { ...actualElement.value, rows: newRows, columns: newColumns };
        handleChangeItem(actualElement.id, 'value', newValue);
    };

    const handleDeleteRow = (index) => {
        const newRows = structuredClone(actualElement.value.rows);
        newRows.splice(index, 1);

        const newValue = { ...actualElement.value, rows: newRows };
        handleChangeItem(actualElement.id, 'value', newValue);
    };

    return (
        <>
            <div
                className={`rounded-md px-4 py-2 sm:px-2 ${
                    highlightPair.includes(element.pairKey) || highlightReference.referenceId === element.id
                        ? 'bg-layout-main'
                        : 'bg-layout-lighter'
                }`}
            >
                <div className="flex flex-col gap-4">
                    <div className="flex h-10 items-center justify-between gap-4">
                        {titleMapper(element.itemType, element.purpose)}
                        <div className="flex items-center gap-1 sm:flex-col">
                            {isMultiView && element.referenceId && (
                                <p
                                    onMouseEnter={() =>
                                        setHighlightReference({
                                            id: element.id,
                                            referenceId: element.referenceId,
                                        })
                                    }
                                    onMouseLeave={() => setHighlightReference({ id: '', referenceId: '' })}
                                    className={`cursor-view whitespace-nowrap rounded-full p-1 text-center ${
                                        element.referenceId === highlightReference.referenceId &&
                                        element.id === highlightReference.id
                                            ? 'bg-warning-light text-dark-text'
                                            : 'bg-warning text-main-text'
                                    } text-sm`}
                                >
                                    {t('See reference')}
                                </p>
                            )}
                            {element.pairKey && (
                                <p
                                    onMouseEnter={() =>
                                        highlightAllPairs
                                            ? (() => {
                                                  const mainElementId = element.referenceId || element.id;
                                                  const pairKeys = Object.values(templateData.elements)
                                                      .filter((el) => el.referenceId === mainElementId)
                                                      .map((el) => el.pairKey);

                                                  pairKeys.push(
                                                      Object.values(templateData.elements).find(
                                                          (el) => el.id === mainElementId,
                                                      ).pairKey,
                                                  );

                                                  setHighlightPair(pairKeys);
                                              })()
                                            : setHighlightPair([element.pairKey])
                                    }
                                    onMouseLeave={() => setHighlightPair([])}
                                    className={`cursor-view whitespace-nowrap rounded-full p-1 text-center ${
                                        highlightPair.includes(element.pairKey)
                                            ? 'bg-success-light text-dark-text'
                                            : 'bg-success text-main-text'
                                    } text-sm`}
                                >
                                    {t('See pair')}
                                </p>
                            )}
                        </div>

                        {canDeleteElement && (
                            <ErrorButton
                                onClick={() =>
                                    element.pairKey
                                        ? handleRemovePair(element.pairKey)
                                        : handleRemoveItem(element.referenceId || element.id)
                                }
                                onMouseEnter={element.pairKey ? () => setHighlightPair(element.pairKey) : undefined}
                                onMouseLeave={element.pairKey ? () => setHighlightPair('') : undefined}
                                className="flex-shrink-0"
                                size="small"
                                endIcon={<CancelIcon />}
                            >
                                {t(element.pairKey ? 'Delete pair' : 'Delete element')}
                            </ErrorButton>
                        )}
                    </div>
                    <div className="flex flex-col justify-between gap-2">
                        <p className="rounded-md bg-layout-light p-2 text-center">
                            {t(
                                element.isMultiLanguage
                                    ? 'This element is multi language and it ca be edited on each language'
                                    : "This element is not multi language, and it doesn't matter on which column you edit it",
                            )}
                        </p>
                        <div className="flex overflow-auto">
                            {isText && (
                                <div className="flex-grow">
                                    <iframe
                                        className="h-40 w-full rounded-md border border-layout-lighter bg-white p-2"
                                        srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(actualElement.value)}`}
                                    />
                                </div>
                            )}
                            {isImage && (
                                <div className="flex h-64 w-full items-center justify-center">
                                    <img
                                        className="h-full w-full object-contain"
                                        src={actualElement.value.url ?? NoImage}
                                        alt={`Image ${actualElement.id}`}
                                    />
                                </div>
                            )}
                            {isTable && (
                                <div className="pl-10 pt-10">
                                    <table
                                        className="border-collapse rounded-sm border-none"
                                        style={{ minWidth: '100px' }}
                                    >
                                        <thead>
                                            <tr className="bg-layout-transparent-dark">
                                                {actualElement.value.columns.map((header, index) => {
                                                    return (
                                                        <th
                                                            className="relative border border-layout-main p-1"
                                                            key={index}
                                                        >
                                                            <div className="flex items-center justify-between gap-1">
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: DOMPurify.sanitize(header),
                                                                    }}
                                                                />

                                                                <span>
                                                                    <ActionButton
                                                                        onClick={() => {
                                                                            tableIndexInfo.current = {
                                                                                type: 'column',
                                                                                index,
                                                                                value: header,
                                                                            };
                                                                            setOpen(true);
                                                                        }}
                                                                        icon={<EditIcon />}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div className="absolute -top-9 left-1/2 -translate-x-1/2 transform">
                                                                <ActionButton
                                                                    disabled={actualElement.value.columns.length === 1}
                                                                    color={
                                                                        actualElement.value.columns.length === 1
                                                                            ? 'var(--disabled)'
                                                                            : 'var(--error)'
                                                                    }
                                                                    onClick={() => handleDeleteColumn(index)}
                                                                    icon={<DeleteIcon />}
                                                                />
                                                            </div>
                                                        </th>
                                                    );
                                                })}

                                                <th className="bg-layout-lighter px-2 py-1">
                                                    <ActionButton
                                                        color="var(--success)"
                                                        onClick={handleAddNewColumn}
                                                        icon={<AddIcon />}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {actualElement.value.rows.map((row, index) => {
                                                return (
                                                    <tr key={index} className="bg-layout-transparent">
                                                        {row.map((value, vIndex) => {
                                                            return (
                                                                <td
                                                                    className="relative border border-layout-main p-1"
                                                                    key={vIndex}
                                                                >
                                                                    <div className="flex items-center justify-between gap-1">
                                                                        <span
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: DOMPurify.sanitize(value),
                                                                            }}
                                                                        />
                                                                        <span>
                                                                            <ActionButton
                                                                                onClick={() => {
                                                                                    tableIndexInfo.current = {
                                                                                        type: 'row',
                                                                                        index,
                                                                                        vIndex,
                                                                                        value,
                                                                                    };
                                                                                    setOpen(true);
                                                                                }}
                                                                                icon={<EditIcon />}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    {vIndex === 0 && (
                                                                        <div className="absolute -left-9 top-1/2 -translate-y-1/2 transform">
                                                                            <ActionButton
                                                                                disabled={
                                                                                    actualElement.value.rows.length ===
                                                                                    1
                                                                                }
                                                                                color={
                                                                                    actualElement.value.rows.length ===
                                                                                    1
                                                                                        ? 'var(--disabled)'
                                                                                        : 'var(--error)'
                                                                                }
                                                                                onClick={() => handleDeleteRow(index)}
                                                                                icon={<DeleteIcon />}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}

                                            <tr>
                                                <td className="px-1 py-2">
                                                    <div className="inline-block">
                                                        <ActionButton
                                                            color="var(--success)"
                                                            onClick={handleAddNewRow}
                                                            icon={<AddIcon />}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {isGallery && (
                                <div className="h-64 w-full">
                                    <GalleryEditable
                                        type="Multi-select"
                                        gallery={actualElement.value}
                                        setGallery={handleGalleryUpload}
                                        setOpen={setGalleryOpen}
                                        photoClickCallback={(photoIndex) => {
                                            setGalleryPhotoIndex(photoIndex);
                                        }}
                                    />
                                    <Gallery
                                        open={galleryOpen}
                                        setOpen={setGalleryOpen}
                                        initialIndex={galleryPhotoIndex}
                                        urlList={actualElement.value.map((item) => item.url)}
                                    />
                                </div>
                            )}
                        </div>

                        {!(isGallery && isDefaultPurpose) && !isTable && (
                            <div
                                className="mx-auto grid w-full grid-cols-4 place-content-center place-items-center gap-2 rounded-md bg-layout-light p-2 sm:grid-cols-1"
                                style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(18rem, max-content))' }}
                            >
                                {isMilestonePurpose && (
                                    <Button size="small" onClick={handleOpenSelection} color="primary">
                                        {t('Select milestone')}
                                    </Button>
                                )}
                                {isMilestonesPurpose && (
                                    <Button size="small" onClick={handleOpenListSelection} color="primary">
                                        {t('Select milestones')}
                                    </Button>
                                )}
                                {isOfferPurpose && <DocumentTemplateOfferSelection element={actualElement} />}
                                {isText && element.flagManualEdit && (
                                    <Button size="small" onClick={handleOpenModal} color="primary">
                                        {t('Edit element')}
                                    </Button>
                                )}
                                {isImage && element.flagManualEdit && (
                                    <FileUploadContainer onUpload={handleImageUpload} multiple={false} accept="image">
                                        <Button size="small" color="primary">
                                            {t(actualElement.value.url ? 'Edit image' : 'Add image')}
                                        </Button>
                                    </FileUploadContainer>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {(isText || isTable) && (
                <CustomModal open={open}>
                    <EditElementModal onClose={handleCloseModal} value={editTextValue} onFinish={handleEditText} />
                </CustomModal>
            )}

            {isMilestonePurpose && (
                <CustomModal open={openMilestoneSelection}>
                    <DocumentTemplateMilestoneSelection onClose={handleCloseSelection} element={actualElement} />
                </CustomModal>
            )}

            {isMilestonesPurpose && (
                <CustomModal open={openMilestonesListSelection}>
                    <DocumentTemplateMilestonesListSelection
                        onClose={handleCloseListSelection}
                        element={actualElement}
                    />
                </CustomModal>
            )}
        </>
    );
};

DocumentTemplateItem.propTypes = {
    element: PropTypes.object,
    highlightAllPairs: PropTypes.bool,
};

DocumentTemplateItem.defaultProps = {
    element: null,
    highlightAllPairs: false,
};

export default DocumentTemplateItem;
