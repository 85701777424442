import React from 'react';

import FolderIcon from '@material-ui/icons/Folder';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NoDataPlaceholder = ({ styles }) => {
    const { t } = useTranslation();

    return (
        <div
            className="mx-auto flex w-full flex-col items-center justify-center py-16"
            style={{
                ...styles,
            }}
        >
            <FolderIcon
                className="mb-4 text-main-text opacity-20"
                style={{
                    fontSize: 64,
                }}
            />
            <h6 className="whitespace-nowrap text-3xl font-bold uppercase text-primary-main">
                {t('There is no data')}
            </h6>
        </div>
    );
};

NoDataPlaceholder.propTypes = {
    styles: PropTypes.object,
};

NoDataPlaceholder.defaultPropTypes = {
    styles: {},
};

export default NoDataPlaceholder;
