import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AccountingAccounts } from 'api/Accounting_Accounts';
import { Expenses } from 'api/Expenses_Revenues';
import BasicTooltip from 'components/shared/basic-tooltip';
import useScreenSizes from 'hooks/useScreenSizes.js';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, Dropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { accountancy } from 'routes';
import { errorHandling } from 'utils';
import * as yup from 'yup';

const ExpenseForm = ({ expenseId, canEdit, setInitializeData }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [width] = useScreenSizes();

    const [loadingExpenses, setLoadingExpenses] = useState(!!expenseId);
    const [loadingExpensesSubmission, setLoadingExpensesSubmission] = useState(false);

    // Main state for storing the data for the EXPENSE
    const [expense, setExpense] = useState({
        name: '',
        accountingAccount: null,
        type: null,
        subExpensesName: [],
    });

    // This state is used to compere the initial expense data with the expense data from the database
    // in order to disable the creation or edit button
    const [initialJSON, setInitialJSON] = useState(
        JSON.stringify({
            name: '',
            subExpensesName: [],
        })
    );

    const types = ['INDIRECT', 'DIRECT', 'ADMINISTRATIV'];

    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [initialAccount, setInitialAccount] = useState(null);

    useEffect(() => {
        (async () => {
            if (!expenseId) return;

            Expenses.getById(expenseId).then((res) => {
                if (res.ok) {
                    if (accounts.length) {
                        const expenseWithAccountingAccounts = {
                            id: res.data.id,
                            name: res.data.name,
                            isDeleted: res.data.isDeleted,
                            type: types.findIndex((type) => type === res.data.type),
                            accountingAccount: accounts.findIndex(
                                (account) => account.id === res.data.accountingAccountsId
                            ),
                            subExpensesName: res.data.subExpensesName
                                .map((subEx) => ({
                                    id: subEx.id,
                                    name: subEx.name,
                                    isDeleted: subEx.isDeleted,
                                    type: types.findIndex((type) => type === subEx.type),
                                    accountingAccount: accounts.findIndex(
                                        (account) => account.id === subEx.accountingAccountsId
                                    ),
                                    subExpensesName: subEx.subExpensesName
                                        .map((subSubEx) => ({
                                            id: subSubEx.id,
                                            name: subSubEx.name,
                                            isDeleted: subSubEx.isDeleted,
                                            type: types.findIndex((type) => type === subSubEx.type),
                                            accountingAccount: accounts.findIndex(
                                                (account) => account.id === subSubEx.accountingAccountsId
                                            ),
                                        }))
                                        .filter((subSubEx) => subSubEx.isDeleted === false),
                                }))
                                .filter((subEx) => subEx.isDeleted === false),
                        };

                        setExpense(expenseWithAccountingAccounts);
                        setInitialJSON(JSON.stringify(expenseWithAccountingAccounts));
                    }
                } else {
                    console.error(res.error);
                    enqueueSnackbar(
                        errorHandling(res.error).length > 100 ? errorHandling(res.error) : t(errorHandling(res.error)),
                        {
                            variant: 'error',
                        }
                    );
                }

                setLoadingExpenses(false);
            });
        })();
    }, [accounts, expenseId]);

    useEffect(() => {
        AccountingAccounts.getAll().then((res) => {
            if (res.ok) {
                setAccounts(res.data);
            } else {
                console.error(res.error);
                enqueueSnackbar(
                    errorHandling(res.error).length > 100 ? errorHandling(res.error) : t(errorHandling(res.error)),
                    {
                        variant: 'error',
                    }
                );
            }
        });
    }, []);

    /**
     * This useEffect is used for setting the state of the dropdown
     *  as we are searching for the index of the selected value in the array of accounts
     */
    useEffect(() => {
        if (selectedAccount && accounts.length > 0) {
            const selectingIndex = accounts.findIndex((account) => account.id === selectedAccount);
        }
    }, [expense, accounts]);

    /**
     * Functions for expenses
     */

    const addExpense = useCallback(() => {
        const newExpense = { ...expense };
        newExpense.subExpensesName.push({
            id: Date.now(),
            name: '',
            type: null,
            accountingAccount: null,
            subExpensesName: [],
        });
        setExpense(newExpense);
    }, [expense]);

    const updateExpense = useCallback(
        (expenseIndex, expenseName = undefined, accountingAccountIndex = undefined, typeIndex = undefined) => {
            const newExpense = { ...expense };
            const expenseToUpdate = newExpense.subExpensesName[expenseIndex];

            if (expenseName !== undefined) {
                expenseToUpdate.name = expenseName;
            }

            if (accountingAccountIndex !== undefined) {
                expenseToUpdate.accountingAccount = accountingAccountIndex;
            }

            if (typeIndex !== undefined) {
                expenseToUpdate.type = typeIndex;
            }

            setExpense(newExpense);
        },
        [expense]
    );

    const deleteExpense = useCallback(
        (id) => {
            const newExpense = { ...expense };
            newExpense.subExpensesName = newExpense.subExpensesName.filter((ex) => ex.id !== id);
            setExpense(newExpense);
        },
        [expense]
    );

    /**
     * --------------------------------------------
     */

    /**
     * Functions for sub-expenses
     */

    const addSubExpense = useCallback(
        (expenseIndex) => {
            const newExpense = { ...expense };
            newExpense.subExpensesName[expenseIndex].subExpensesName.push({
                id: Date.now(),
                name: '',
                type: null,
                accountingAccount: null,
                created: true,
            });
            setExpense(newExpense);
        },
        [expense]
    );

    const updateSubexpense = useCallback(
        (
            expenseIndex,
            subexpenseIndex,
            expenseName = undefined,
            accountingAccountIndex = undefined,
            typeIndex = undefined
        ) => {
            const newExpense = { ...expense };
            const expenseToUpdate = newExpense.subExpensesName[expenseIndex].subExpensesName[subexpenseIndex];

            if (expenseName !== undefined) {
                expenseToUpdate.name = expenseName;
            }

            if (accountingAccountIndex !== undefined) {
                expenseToUpdate.accountingAccount = accountingAccountIndex;
            }

            if (typeIndex !== undefined) {
                expenseToUpdate.type = typeIndex;
            }

            setExpense(newExpense);
        },
        [expense]
    );

    const deleteSubExpense = useCallback(
        (id) => {
            const newExpense = { ...expense };

            newExpense.subExpensesName.map((subEx1) => {
                subEx1.subExpensesName = subEx1.subExpensesName.filter((ex) => ex.id !== id);
            });
            setExpense(newExpense);
        },
        [expense]
    );

    /**
     * --------------------------------------------
     */

    /**
     * Function for deleting expense or sub-expense. After request with the id, we call
     * function for deleting expenses or sub-expenses from frontend
     * @param {*} id - of the expense or sub-expense
     * @param {*} type - expense for expenses or sub-expense for Sub-expenses (to now that function for deleting we will call)
     */
    const deleteExpenseOrSubExpense = async (id, type) => {
        if (type == 'expense') {
            deleteExpense(id);
        } else {
            deleteSubExpense(id);
        }

        // At editing we make differences between an existing expense and a new created one
        //(from back they come with id as a string and from front, id is a number)
        if (typeof id === 'string') {
            Expenses.delete(id).then((res) => {
                if (res.ok) {
                    type == 'expense'
                        ? enqueueSnackbar(t('Expense deleted successfully!'), { variant: 'success' })
                        : enqueueSnackbar(t('Sub-expense deleted successfully!'), { variant: 'success' });
                } else {
                    console.error(res.error);
                    enqueueSnackbar(
                        errorHandling(res.error).length > 100 ? errorHandling(res.error) : t(errorHandling(res.error)),
                        {
                            variant: 'error',
                        }
                    );
                }
            });
        }
    };

    const schema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .typeError(t('The title of the expense is mandatory!'))
            .min(3, t('The title of the expense must be at least 3 characters long!'))
            .required(t('The title of the expense is mandatory!')),
        accountingAccount: yup
            .number()
            .typeError(t('The expense must have an accounting account!'))
            .required(t('The expense must have an accounting account!')),
        type: yup.number().typeError(t('The expense must have a type!')).required(t('The expense must have a type!')),
        subExpensesName: yup
            .array()
            .of(
                yup.object({
                    name: yup
                        .string()
                        .trim()
                        .typeError(t('The name of the expense is mandatory!'))
                        .min(3, t('The name of the expense must be at least 3 characters long!'))
                        .required(t('The name of the expense is mandatory!')),
                    accountingAccount: yup
                        .number()
                        .typeError(t('The expense must have an accounting account!'))
                        .required(t('The expense must have an accounting account!')),
                    type: yup
                        .number()
                        .typeError(t('The expense must have a type!'))
                        .required(t('The expense must have a type!')),
                    subExpensesName: yup.array().of(
                        yup.object({
                            type: yup
                                .number()
                                .when('name', {
                                    is: (value) => value !== '',
                                    then: yup
                                        .number()
                                        .typeError(t('The sub-expense must have a type!'))
                                        .required(t('The sub-expense must have a type!')),
                                })
                                .typeError(t('The name of the sub-expense is mandatory!')),
                            accountingAccount: yup
                                .number()
                                .when('name', {
                                    is: (value) => value !== '',
                                    then: yup
                                        .number()
                                        .typeError(t('The sub-expense must have an accounting account!'))
                                        .required(t('The sub-expense must have an accounting account!')),
                                })
                                .typeError(t('The name of the sub-expense is mandatory!')),
                            name: yup
                                .string()
                                .trim()
                                .typeError(t('The name of the sub-expense is mandatory!'))
                                .min(3, t('The name of the sub-expense must be at least 3 characters long!'))
                                .required(t('The name of the sub-expense is mandatory!')),
                        })
                    ),
                })
            )
            .min(1, t('You must declare at least one expense name!'))
            .required(t('You must declare at least one expense name!')),
    });

    /**
     * ---------------------------------------------
     */

    const submitExpense = useCallback(async () => {
        const formatSubExpense = (subExpense) => {
            const newSubExpense = {
                id: typeof subExpense.id === 'number' ? undefined : subExpense.id,
                name: subExpense.name,
                type: types[subExpense.type],
                accountingAccountsId: accounts[subExpense.accountingAccount].id,
                subExpensesName:
                    'subExpensesName' in subExpense
                        ? subExpense.subExpensesName.map((se) => formatSubExpense(se))
                        : undefined,
            };

            return newSubExpense;
        };

        if (expenseId) {
            // Update expense

            try {
                setLoadingExpensesSubmission(true);

                await schema.validate({
                    name: expense.name,
                    type: expense.type,
                    accountingAccount: expense.accountingAccount,
                    subExpensesName: expense.subExpensesName,
                });

                delete expense.createdAt;
                delete expense.isDeleted;
                delete expense.parentId;
                delete expense.updatedAt;
                delete expense.tenantId;

                const editRequestBody = {
                    ...expense,
                    type: types[expense.type],
                    accountingAccountsId: accounts[expense.accountingAccount].id,
                    subExpensesName: expense.subExpensesName.map((subExpense) => formatSubExpense(subExpense)),
                };

                delete editRequestBody.accountingAccount;

                Expenses.edit(editRequestBody).then((res) => {
                    if (res.ok) {
                        enqueueSnackbar(t('Expense updated successfully!'), { variant: 'success' });
                        history.push(accountancy.base + accountancy.expenseNomenclature.base);
                    } else {
                        console.error(res.error);
                        throw res.error;
                    }
                });
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err), { variant: 'error' });
            } finally {
                setLoadingExpensesSubmission(false);
            }
        } else {
            // Create expense
            try {
                setLoadingExpensesSubmission(true);
                setInitializeData?.((prev) => ({ ...prev, loading: true }));

                await schema.validate({
                    name: expense.name,
                    type: expense.type,
                    accountingAccount: expense.accountingAccount,
                    subExpensesName: expense.subExpensesName,
                });

                const createRequestBody = {
                    ...expense,
                    type: types[expense.type],
                    accountingAccountsId: accounts[expense.accountingAccount].id,
                    subExpensesName: expense.subExpensesName.map((subExpense) => formatSubExpense(subExpense)),
                };

                delete createRequestBody.accountingAccount;

                Expenses.create(createRequestBody).then((res) => {
                    if (res.ok) {
                        enqueueSnackbar(t('Expense created successfully!'), { variant: 'success' });

                        if (setInitializeData) setInitializeData((prev) => ({ ...prev, tab: 0 }));
                        else history.push(accountancy.base + accountancy.expenseNomenclature.base);
                    } else {
                        console.error(res.error);
                        throw res.error;
                    }
                });
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err), { variant: 'error' });
            } finally {
                setLoadingExpensesSubmission(false);
                setInitializeData?.((prev) => ({ ...prev, loading: false }));
            }
        }
    }, [expense, expenseId, selectedAccount]);

    return (
        <div className="w-full max-w-6xl pr-44 md:pr-0">
            {loadingExpenses ? (
                <div className="flex justify-center rounded-md bg-layout-transparent p-12">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {/* 
                Title of the expense with his accounting account
                 */}
                    <div className="flex items-center">
                        <TextField
                            placeholder={t('Expense name')}
                            value={expense.name}
                            onChange={(e) => setExpense({ ...expense, name: e.target.value })}
                            disabled={!canEdit}
                        />
                        {canEdit && (
                            <div className="ml-2 flex-shrink-0">
                                <BasicTooltip tip={t('Add expense')}>
                                    <div
                                        style={{ padding: '0.5rem' }}
                                        className="grid cursor-pointer place-content-center rounded-md bg-primary-main transition-all  hover:bg-primary-light group-hover:pointer-events-auto group-hover:translate-y-0"
                                        onClick={addExpense}
                                    >
                                        <AddIcon className="text-buttons-text" style={{ fontSize: '1.5rem' }} />
                                    </div>
                                </BasicTooltip>
                            </div>
                        )}
                        <div className="ml-2 w-5/12 sm:w-3/12">
                            <Dropdown
                                variant="black"
                                options={accounts.map((account) => account.code + ' - ' + account.name)}
                                placeholder={t('Choose the accounting account')}
                                selectedOption={expense.accountingAccount}
                                setSelectedOption={(e) => {
                                    if (canEdit) setExpense({ ...expense, accountingAccount: e });
                                }}
                                disabled={!canEdit}
                            />
                        </div>
                        <div className="ml-2 w-5/12 sm:w-3/12">
                            <Dropdown
                                variant="black"
                                options={['INDIRECT', 'DIRECT', t('ADMINISTRATIVE')]}
                                placeholder={t('Choose the type')}
                                selectedOption={expense.type}
                                setSelectedOption={(e) => {
                                    if (canEdit) setExpense({ ...expense, type: e });
                                }}
                                disabled={!canEdit}
                            />
                        </div>
                    </div>

                    <div
                        className="ml-3 w-full gap-2 pl-4 pt-4"
                        style={{
                            position: expense.subExpensesName.length > 0 && 'relative',

                            borderLeft: expense.subExpensesName.length > 0 && `2px solid var(--warning)`,
                        }}
                    >
                        {expense.subExpensesName.map((subExpense1, subExpense1Index) => (
                            <div key={subExpense1.id} className="flex flex-col">
                                {/* 
                                Parent expense with his accounting account
                                */}
                                <div className="group relative mt-4 flex items-center">
                                    <div
                                        style={{
                                            borderLeft: `1px solid var(--warning)`,
                                            height: '1rem',
                                            borderBottom: `1px solid var(--warning)`,
                                            width: '13px',
                                            borderRadius: '0px 0px 0px 10px',
                                            position: 'absolute',
                                            left: '-13.5px',
                                            top: '5px',
                                        }}
                                    ></div>

                                    <TextField
                                        placeholder={t('Expense name')}
                                        value={subExpense1.name}
                                        onChange={(e) => updateExpense(subExpense1Index, e.target.value)}
                                        disabled={!canEdit}
                                    />
                                    <div
                                        style={{
                                            backgroundColor: `var(--warning)`,
                                            width: '2rem',
                                            height: '1px',
                                        }}
                                    ></div>

                                    <div className="w-4/12 sm:w-3/12">
                                        <Dropdown
                                            variant="black"
                                            options={accounts.map((account) => account.code + ' - ' + account.name)}
                                            placeholder={t('Choose the accounting account')}
                                            selectedOption={subExpense1.accountingAccount}
                                            setSelectedOption={(e) => {
                                                updateExpense(subExpense1Index, undefined, e, undefined);
                                            }}
                                            style={{ zIndex: subExpense1Index + 100 }}
                                            disabled={!canEdit}
                                        />
                                    </div>

                                    <div className="ml-2 w-4/12 sm:w-3/12">
                                        <Dropdown
                                            variant="black"
                                            options={['INDIRECT', 'DIRECT', t('ADMINISTRATIVE')]}
                                            placeholder={t('Choose the type')}
                                            selectedOption={subExpense1.type}
                                            setSelectedOption={(e) => {
                                                updateExpense(subExpense1Index, undefined, undefined, e);
                                            }}
                                            disabled={!canEdit}
                                        />
                                    </div>

                                    {canEdit && (
                                        <div style={{ zIndex: subExpense1Index }} className="flex gap-2 pl-2">
                                            <div className="flex-shrink-0">
                                                <BasicTooltip tip={t('Add sub-expense')} position="right">
                                                    <ActionButton
                                                        size={width <= 750 ? 9 : 7}
                                                        icon={<AddIcon />}
                                                        color="var(--success)"
                                                        onClick={() => addSubExpense(subExpense1Index)}
                                                    />
                                                </BasicTooltip>
                                            </div>
                                            {subExpense1.subExpensesName.length === 0 && (
                                                <div className="duration-250 flex-shrink-0 translate-x-5 transform opacity-0 transition-all group-hover:translate-x-0 group-hover:opacity-100 md:translate-x-0 md:opacity-100">
                                                    <BasicTooltip
                                                        position="right"
                                                        tip={t('Delete expense')}
                                                        style={{ zIndex: subExpense1Index }}
                                                    >
                                                        <ActionButton
                                                            size={width <= 750 ? 9 : 7}
                                                            icon={<DeleteIcon />}
                                                            color="var(--error)"
                                                            onClick={() =>
                                                                expenseId
                                                                    ? deleteExpenseOrSubExpense(
                                                                          subExpense1.id,
                                                                          'expense'
                                                                      )
                                                                    : deleteExpense(subExpense1.id)
                                                            }
                                                        />
                                                    </BasicTooltip>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                {subExpense1.subExpensesName.length > 0 && (
                                    <div
                                        className="relative ml-8 space-y-4 pl-4 pt-4 md:ml-3"
                                        style={{
                                            borderLeft: `2px solid  var(--warning)`,
                                            // borderRadius: '0px 0px 0px 20px',
                                        }}
                                    >
                                        {/* <div className="nomenclature-left-border" /> */}
                                        {/* 
                                        Child expense with his accounting account
                                        */}
                                        {subExpense1.subExpensesName.map((subExpense2, subExpense2Index) => (
                                            <div
                                                key={subExpense2.id}
                                                className={`group relative flex items-center ${
                                                    subExpense2.name.length && 'gap-6'
                                                }`}
                                            >
                                                {subExpense1.subExpensesName.length && (
                                                    <div
                                                        style={{
                                                            borderLeft: `1px solid  var(--warning)`,
                                                            height: '1rem',
                                                            borderBottom: `1px solid  var(--warning)`,
                                                            width: '13px',
                                                            borderRadius: '0px 0px 0px 10px',
                                                            position: 'absolute',
                                                            left: '-13.5px',
                                                            top: '5px',
                                                        }}
                                                    ></div>
                                                )}

                                                <TextField
                                                    className="rounded-md bg-layout-light bg-opacity-5"
                                                    placeholder={t('Sub-expense name')}
                                                    value={subExpense2.name}
                                                    onChange={(e) =>
                                                        updateSubexpense(
                                                            subExpense1Index,
                                                            subExpense2Index,
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={!canEdit}
                                                />

                                                {subExpense2.name.length > 0 && (
                                                    <div className="flex w-2/6 gap-2">
                                                        <div className="w-1/2">
                                                            <Dropdown
                                                                variant="black"
                                                                options={accounts.map(
                                                                    (account) => account.code + ' - ' + account.name
                                                                )}
                                                                placeholder={t('Choose the accounting account')}
                                                                selectedOption={subExpense2.accountingAccount}
                                                                setSelectedOption={(e) => {
                                                                    updateSubexpense(
                                                                        subExpense1Index,
                                                                        subExpense2Index,
                                                                        undefined,
                                                                        e,
                                                                        undefined
                                                                    );
                                                                }}
                                                                disabled={!canEdit}
                                                            />
                                                        </div>

                                                        <div className="w-1/2">
                                                            <Dropdown
                                                                variant="black"
                                                                options={['INDIRECT', 'DIRECT', t('ADMINISTRATIVE')]}
                                                                placeholder={t('Choose the type')}
                                                                selectedOption={subExpense2.type}
                                                                setSelectedOption={(e) => {
                                                                    updateSubexpense(
                                                                        subExpense1Index,
                                                                        subExpense2Index,
                                                                        undefined,
                                                                        undefined,
                                                                        e
                                                                    );
                                                                }}
                                                                disabled={!canEdit}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                {canEdit && (
                                                    <div
                                                        style={{ zIndex: subExpense1Index }}
                                                        className={`${
                                                            subExpense2.name.length > 0 ? 'pl-0' : 'pl-2'
                                                        } duration-250 flex-shrink-0 translate-x-5 transform opacity-0 transition-all group-hover:translate-x-0 group-hover:opacity-100 md:translate-x-0 md:opacity-100`}
                                                    >
                                                        <BasicTooltip tip={t('Delete sub-expense')} position="right">
                                                            <ActionButton
                                                                size={width <= 750 ? 9 : 7}
                                                                icon={<DeleteIcon />}
                                                                color="var(--error)"
                                                                onClick={() =>
                                                                    expenseId
                                                                        ? deleteExpenseOrSubExpense(
                                                                              subExpense2.id,
                                                                              'sub-expense'
                                                                          )
                                                                        : deleteSubExpense(subExpense2.id)
                                                                }
                                                            />
                                                        </BasicTooltip>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    {canEdit && (
                        // Create or edit button
                        <div
                            className={`pt-12 transition-opacity ${
                                initialJSON !== JSON.stringify(expense) || selectedAccount !== initialAccount
                                    ? 'pointer-events-auto opacity-100'
                                    : 'pointer-events-none opacity-25'
                            }`}
                        >
                            <Button
                                color="primary"
                                onClick={submitExpense}
                                startIcon={expenseId ? <CheckIcon /> : <AddIcon />}
                                disabled={loadingExpensesSubmission}
                            >
                                {expenseId ? t('Edit expense') : t('Add expense')}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

ExpenseForm.propTypes = {
    expenseId: PropTypes.string,
    canEdit: PropTypes.bool,
    setInitializeData: PropTypes.func,
};

ExpenseForm.defaultProps = {
    expenseId: null,
    canEdit: true,
    setInitializeData: null,
};

export default ExpenseForm;
