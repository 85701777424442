import React, { Fragment } from 'react';

import Pagination from 'components/shared/pagination';
import PropTypes from 'prop-types';

import PaginationSearch from './pagination-search';

const DisplayQueryResults = ({ children, query, loading, setLoading, getFunction, getSearchFunction, refreshList }) => {
    const isSearch = query.trim().length > 0;

    return (
        <Fragment>
            {!isSearch && (
                <Pagination
                    loading={loading}
                    setLoading={setLoading}
                    getFunction={getFunction}
                    refreshList={refreshList}
                >
                    {children}
                </Pagination>
            )}
            {isSearch && (
                <PaginationSearch
                    query={query}
                    loading={loading}
                    setLoading={setLoading}
                    getFunction={getSearchFunction}
                    refreshList={refreshList}
                >
                    {children}
                </PaginationSearch>
            )}
        </Fragment>
    );
};

DisplayQueryResults.propTypes = {
    children: PropTypes.element,
    query: PropTypes.string,
    getFunction: PropTypes.func,
    getSearchFunction: PropTypes.func,
    setLoading: PropTypes.func,
    loading: PropTypes.bool,
    refreshList: PropTypes.array,
};

DisplayQueryResults.defaultProps = {
    children: null,
    query: '',
    getFunction: () => {},
    getSearchFunction: () => {},
    setLoading: () => {},
    loading: false,
    refreshList: [],
};

export default DisplayQueryResults;
