import React from 'react';

import { Button, makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles(() => {
    return {
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
    };
});

const ErrorButton = ({ children, className, style, ...props }) => {
    const { error } = useStyles();
    const classes = `${error} ${className}`;

    return (
        <Button {...props} className={classes} style={{ ...style }}>
            {children}
        </Button>
    );
};

ErrorButton.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    style: PropTypes.object,
};

ErrorButton.defaultProps = {
    children: null,
    className: '',
    style: {},
};

export default ErrorButton;
