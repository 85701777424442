export default [
    {
        version: '1.0.0 Alpha',
        description: 'This is the initial version of the platform.',
        features: [
            // 'This is the feature with number 1',
            // 'This is the feature with number 2',
            // 'This is the feature with number 3',
            // 'This is the feature with number 4',
        ],
        bugsFixed: [
            // 'This is the bug with number 1',
            // 'This is the bug with number 2',
            // 'This is the bug with number 3',
            // 'This is the bug with number 4',
        ],
    },
];
