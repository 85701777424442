import React, { useEffect, useState } from 'react';

import AttributesForm from 'components/crm/quotes/attributes/attributes-form';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { errorHandling } from 'utils';
import API from 'utils/axios';

const EditAttributes = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { attributeId } = useParams();

    const [attributeInfo, setAttributeInfo] = useState();

    useEffect(() => {
        (async () => {
            try {
                const res = await API.get('/articleAttribute', {
                    params: {
                        id: attributeId,
                    },
                });
                setAttributeInfo(res.data.articleAttribute);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        })();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('Edit attribute')} </title>
            </Helmet>

            <Header pageTitle={t('Edit attribute')} />

            <div className="page-container">
                <AttributesForm attributeInfo={attributeInfo} />
            </div>
        </>
    );
};

export default EditAttributes;
