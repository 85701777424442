import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import DisplayQueryResults from 'components/shared/display-query-results';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import { useResources } from 'contexts/ResourcesContext';
import { TableSeparator } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

import MaterialRow from '../material-resources/material-row';

const WarehouseMaterial = () => {
    const { t } = useTranslation();
    const [materials, setMaterials] = useState([]);
    const { query, filterParameters } = useResources();
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams();

    const getMaterials = async (currentPage, perPage) => {
        try {
            const res = await API.get('materialResourceByWarehouse', {
                params: {
                    currentPage,
                    perPage,
                    pagesToLoad: 1,
                    warehouseId: id,
                    type: filterParameters.type === 'All' ? undefined : filterParameters.type,
                },
            });

            setMaterials(res.data.allMaterialResources.materialResource);

            return res.data.allMaterialResources.length;
        } catch (error) {
            throw new Error(error);
        }
    };

    const getSearchedMaterials = async (currentPage, perPage) => {
        try {
            const res = await API.get('searchMaterialResourceByWarehouse', {
                params: {
                    currentPage,
                    perPage,
                    pagesToLoad: 1,
                    warehouseId: id,
                    type: filterParameters.type === 'All' ? undefined : filterParameters.type,
                    text: query,
                },
            });

            setMaterials(res.data.searchedMaterialResource.materialResource);

            return res.data.searchedMaterialResource.length;
        } catch (error) {
            throw new Error(error);
        }
    };

    return (
        <div className="page-container">
            <DisplayQueryResults
                loading={isLoading}
                query={query}
                setLoading={setIsLoading}
                getFunction={getMaterials}
                getSearchFunction={getSearchedMaterials}
                refreshList={[...Object.values(filterParameters)]}
            >
                {isLoading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : materials.length ? (
                    <Fragment>
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Name')}</TableCell>
                                        <TableCell>{t('Quantity')}</TableCell>
                                        <TableCell>{t('NIR')}</TableCell>
                                        <TableCell>{t('Type')}</TableCell>
                                        <TableCell>{t('Status')}</TableCell>
                                        <TableCell>{t('Project')}</TableCell>
                                        <TableCell>{t('Warehouse')}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {materials.map((material) => (
                                        <MaterialRow key={material.id} material={material} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Fragment>
                ) : (
                    <NoDataPlaceholder />
                )}
            </DisplayQueryResults>
        </div>
    );
};

export default WarehouseMaterial;
