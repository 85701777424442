import React from 'react';

import { TextField } from '@material-ui/core';

import PropTypes from 'prop-types';

export const InputGroup = (props) => {
    const { children, name, setName, placeholder, disabled } = props;

    return (
        <div className="inline-flex w-full flex-col">
            <TextField
                disabled={disabled}
                value={name}
                placeholder={placeholder}
                onChange={(e) => (!disabled ? setName(e.target.value) : null)}
            />
            <div className={`input-group w-full pl-2 ${children ? 'mt-2' : ''}`}>{children}</div>
        </div>
    );
};
InputGroup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.arrayOf(PropTypes.element)]),
    name: PropTypes.string,
    setName: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
};
InputGroup.defaultProps = {
    children: null,
    name: '',
    setName: () => null,
    placeholder: '',
    disabled: false,
};
