import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import GantContext from 'contexts/GantContext';
import PropTypes from 'prop-types';
import { crm, internalActivity, projectManagement } from 'routes';
import API from 'utils/axios';

import { cellHeight } from './config';
import TeamInfo from './team-info';

const Project = ({ project, teamToUpdate, setTeamToUpdate, canEditProject, canViewContacts }) => {
    const history = useHistory();

    const [clients, setClients] = useState([]);

    const [teamInfo, setTeamInfo] = useState([]);

    const { type } = useContext(GantContext);

    const teamInfoEndpoint = useMemo(() => {
        return type === 'internal' ? '/getTeamInformation' : 'getPmTeamInformation';
    }, [type]);

    const getTeamInfo = useCallback(async (requestRoute, teamId) => {
        try {
            const response = await API.get(requestRoute, {
                params: {
                    teamId,
                },
            });
            return response.data.teamInfo;
        } catch (err) {
            console.error(err);
        }

        return null;
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const resContacts = await API.get('/contacts', {
                    params: {
                        perPage: 9999,
                        currentPage: 0,
                        pagesToLoad: 1,
                        type: 'ALL',
                    },
                });

                setClients(resContacts.data.data.contacts);
            } catch (err) {
                console.error(err);
            }
        })();

        (async () => {
            if (!('teams' in project) || !project.teams.length) return;

            let newTeamInfo = [];
            let i = 0;
            for (const team of project.teams) {
                const req = await getTeamInfo(teamInfoEndpoint, team.id);
                newTeamInfo.push(req);
                i += 1;
                if (i === project.teams.length) {
                    setTeamInfo(newTeamInfo);
                }
            }
        })();
    }, [project]);

    /**
     * Function take the project contact id and return a string with the name o the contact(client of the project)
     * @param contactId - string
     */
    const nameOfClient = useCallback(
        (contactId) => {
            const indexOfClient = clients.findIndex((c) => c.id === contactId);

            if (indexOfClient !== -1) {
                const data = JSON.parse(clients[indexOfClient].data);
                return 'standard' in data ? data.standard.name : data.standare.name;
            } else {
                return false;
            }
        },
        [clients]
    );

    useEffect(() => {
        (async () => {
            const indexOfTeam = project.teams.findIndex((t) => t.id === teamToUpdate);

            if (indexOfTeam >= 0) {
                const newTeam = await getTeamInfo(teamInfoEndpoint, teamToUpdate);
                const newTeamInfo = [...teamInfo];
                newTeamInfo[indexOfTeam] = newTeam;
                setTeamToUpdate('');
                setTeamInfo(newTeamInfo);
            }
        })();
    }, [teamToUpdate, teamInfoEndpoint, teamInfo]);

    return (
        <>
            <div
                className="user-select-none relative rounded-md bg-layout-transparent px-4"
                style={{
                    paddingTop: cellHeight,
                    marginBottom: cellHeight,
                }}
            >
                <div className="absolute left-4 top-0 flex -translate-y-1/2 transform items-center space-x-2">
                    <div
                        className={`rounded-full bg-primary-main px-4 py-2 text-sm font-bold text-buttons-text hover:bg-primary-dark ${
                            !canEditProject ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                        onClick={() => {
                            if (!canEditProject) return;
                            type === 'internal'
                                ? history.push(
                                      internalActivity.base +
                                          internalActivity.planning.base +
                                          internalActivity.planning.update +
                                          '/' +
                                          project.id
                                  )
                                : history.push(
                                      projectManagement.base +
                                          projectManagement.projectEntity.base +
                                          projectManagement.projectEntity.update +
                                          '/' +
                                          project.id
                                  );
                        }}
                    >
                        {project.name}
                    </div>
                    <div
                        className={`rounded-full bg-primary-main px-4 py-2 text-sm font-bold text-buttons-text hover:bg-primary-dark ${
                            !canViewContacts ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                        onClick={() => {
                            if (!canViewContacts) return;

                            if (nameOfClient(project.contactId))
                                history.push(
                                    history.push(crm.base + crm.contactActivity.base + '/' + project.contactId)
                                );
                        }}
                    >
                        {nameOfClient(project.contactId)}
                    </div>
                </div>

                {/* --- Teams --- */}

                {/* No teams */}
                {project.teams.length === 0 && <></>}

                {/* If team info fetched, display it, else loading */}
                {project.teams.length > 0 && (
                    <>
                        {teamInfo.length === project.teams.length ? (
                            <>
                                {project.teams.map((team, teamIndex) => (
                                    <div
                                        key={team.id}
                                        style={{ position: 'relative', zIndex: project.teams.length - teamIndex }}
                                    >
                                        <TeamInfo team={team} teamInfo={teamInfo[teamIndex]} />
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div style={{ paddingBottom: cellHeight }}>
                                <div className="rounded-md bg-layout-transparent p-12 text-center">
                                    <CircularProgress />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

Project.propTypes = {
    project: PropTypes.object,
    teamToUpdate: PropTypes.string,
    setTeamToUpdate: PropTypes.func,
    canEditProject: PropTypes.bool,
    canViewContacts: PropTypes.bool,
};

Project.defaultProps = {
    project: null,
    teamToUpdate: '',
    setTeamToUpdate: () => null,
    canEditProject: false,
    canViewContacts: false,
};

export default Project;
