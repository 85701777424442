import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';

import CategoryForm from '../../../components/crm/quotes/category-form';

const CreateArticleCategory = ({ setInitializeData }) => {
    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);
    const history = useHistory();

    const canAll = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push(quotes.base + quotes.nomenclature.base + quotes.nomenclature.read);
        }
    }, [canAll]);

    if (setInitializeData) return <CategoryForm setInitializeData={setInitializeData} />;

    return (
        <>
            <Helmet>
                <title>
                    {t('nomenclature')} | {t('Add new article category')}
                </title>
            </Helmet>

            <Header pageTitle={t('Add new article category')} />
            <div className="page-container">
                <CategoryForm />
            </div>
        </>
    );
};

CreateArticleCategory.propTypes = {
    setInitializeData: PropTypes.func,
};

CreateArticleCategory.defaultProps = {
    setInitializeData: null,
};

export default CreateArticleCategory;
