import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

import PlanningOverviewProject from './project-form';

const EditProject = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const { id } = useParams();
    const [projectInfo, setProjectInfo] = useState(null);

    const { permissionMap } = useContext(UserContext);
    const canAll = permissionMap['Planning Overview'] > 1;

    useEffect(() => {
        if (!canAll) {
            history.push('/');
            return;
        }

        (async () => {
            try {
                const response = await API.get('/projectOverviewPlanning', {
                    params: {
                        id,
                    },
                });

                setProjectInfo(response.data.project);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [canAll]);

    return (
        canAll && (
            <>
                <Helmet>
                    <title>{t('Edit project')}</title>
                </Helmet>

                <Header pageTitle={t('Edit project')} />

                <div className="page-container">
                    <PlanningOverviewProject projectInfo={projectInfo} />
                </div>
            </>
        )
    );
};

export default EditProject;
