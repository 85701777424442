import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as FilterIcon } from 'assets/pipelines/svgs/filter-icon.svg';

import { Button, makeStyles, Popover } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { findFilterOption } from './filter';
import FilterList from './filter-list';

const useStyles = makeStyles(() => {
    return {
        add: {
            borderColor: `var(--buttons-text)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                color: `rgb(var(--base-buttons-text) / 75%)`,
                borderColor: `rgb(var(--base-buttons-text) / 75%)`,
            },
        },
    };
});

const countDimensions = {
    8: 'w-8 h-8',
    10: 'w-10 h-10',
    12: 'w-12 h-12',
    14: 'w-14 h-14',
    16: 'w-16 h-16',
    18: 'w-18 h-18',
    20: 'w-20 h-20',
};

const breakpointClasses = {
    sm: {
        wrapper: 'sm:flex',
    },
    md: {
        wrapper: 'md:flex',
    },
    lg: {
        wrapper: 'lg:flex',
    },
    xl: {
        wrapper: 'xl:flex',
    },
    '2xl': {
        wrapper: '2xl:flex',
    },
};

const FilterMobile = ({ filterOptions, filter, disabled, onChangeFilter, onRemoveFilter, mobileBP }) => {
    const { t } = useTranslation();
    const { add } = useStyles();

    const [hidden, setHidden] = useState(false);

    const handleHide = () => setHidden(true);
    const handleCanShow = () => setHidden(false);

    const [openAdd, setOpenAdd] = useState(false);

    const filterRef = useRef(null);

    const handleOpenAdd = () => {
        setOpenAdd(true);
        setTimeout(() => {
            filterRef.current.style.overflow = 'visible';
        }, 300);
    };
    const handleCloseAdd = () => {
        filterRef.current.style.overflow = 'hidden';
        setOpenAdd(false);
    };

    const contentRef = useRef(null);

    const actionRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'filter-id-mobile' : undefined;

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        if (filter.length === 0) handleOpenAdd();
    };
    const handleClose = () => {
        handleCloseAdd();
        setAnchorEl(null);
    };

    useEffect(() => {
        let observer = null;

        if (open) {
            setTimeout(() => {
                observer = new ResizeObserver(() => {
                    if (actionRef.current) actionRef.current.updatePosition();

                    const popoverEl = document.getElementById(id);
                    if (popoverEl) {
                        const { height: bodyHeight } = getComputedStyle(document.body);
                        const { height: contentHeight } = getComputedStyle(contentRef.current);

                        if (parseFloat(contentHeight) >= parseFloat(bodyHeight) * 0.9)
                            popoverEl.style.overflowY = 'auto';
                        else popoverEl.style.overflowY = 'visible';
                    }
                });

                observer.observe(contentRef.current);
            }, 0);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [open]);

    useEffect(() => {
        let observer = null;

        if (open) {
            setTimeout(() => {
                observer = new ResizeObserver(() => {
                    if (contentRef.current) contentRef.current.style.width = getComputedStyle(anchorEl).width;

                    const { display } = getComputedStyle(anchorEl);
                    if (display === 'none') {
                        handleHide();
                        handleClose();
                    } else handleCanShow();
                });

                observer.observe(anchorEl);
            }, 0);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [open]);

    return (
        <>
            <div
                className={`hidden w-full cursor-pointer items-center justify-between gap-2 rounded-md bg-layout-lighter px-5 py-4 ${breakpointClasses[mobileBP].wrapper}`}
                aria-describedby={id}
                onClick={handleOpen}
            >
                <FilterIcon className={`h-8 w-8 ${disabled ? 'text-disabled' : 'text-main-text'}`} />

                <div className="flex items-center gap-2">
                    <p className={`text-2xl ${disabled || open ? 'text-disabled' : ''}`}>{t('Filter')}</p>
                    {!open && (
                        <p
                            className={`ml-auto flex ${countDimensions[filter.length.toString().length > 2 ? (filter.length.toString().length - 2) * 2 + 8 : 8]} flex-shrink-0 items-center justify-center rounded-full border border-main-text text-lg leading-none ${
                                disabled ? 'border-disabled text-disabled' : ''
                            }`}
                        >
                            {filter.length}
                        </p>
                    )}
                </div>

                <div
                    className={`flex items-center ${
                        disabled ? 'pointer-events-none' : ''
                    } transform transition-transform duration-300 ${open ? 'rotate-180' : 'rotate-0'}`}
                >
                    <ExpandMoreIcon fontSize="large" className={`${disabled ? 'text-disabled' : 'text-main-text'}`} />
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                action={actionRef}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                marginThreshold={12}
                hidden={hidden}
            >
                <div
                    className="flex h-full flex-col gap-3 rounded-xl bg-layout-lighter px-4 py-3 shadow"
                    ref={contentRef}
                >
                    {filter.length > 0 && (
                        <div className="flex flex-col gap-3">
                            <p className="ml-3 opacity-75">{t('Applied filters')}</p>

                            {filter.map((element) => {
                                const filterOption = findFilterOption(element, filterOptions);

                                return (
                                    <div
                                        key={element.id}
                                        onClick={() => onRemoveFilter(element.id, handleClose)}
                                        className={`flex cursor-pointer items-center justify-between gap-1 rounded-md bg-layout-transparent px-2 py-2.5 hover:bg-error-light ${
                                            disabled ? 'pointer-events-none' : ''
                                        }`}
                                    >
                                        <p
                                            className={`text-xl font-semibold leading-none ${
                                                disabled ? 'text-disabled' : ''
                                            }`}
                                        >{`${filterOption.data.filterData.label}: ${filterOption.data.filterData.render(
                                            element.value,
                                        )}`}</p>
                                        <CloseIcon
                                            className={`${disabled ? 'text-disabled' : 'text-main-text'}`}
                                            style={{
                                                fontSize: '1.75rem',
                                            }}
                                        />
                                    </div>
                                );
                            })}

                            <div className={`${filter.length > 0 ? 'mt-2' : ''}`}>
                                <Button
                                    disabled={disabled}
                                    variant="outlined"
                                    className={`w-full ${add}`}
                                    onClick={openAdd ? handleCloseAdd : handleOpenAdd}
                                    endIcon={openAdd ? <CloseIcon /> : <AddIcon />}
                                >
                                    {openAdd ? t('Cancel') : t('Add filter')}
                                </Button>
                            </div>
                        </div>
                    )}

                    <div
                        className={`grid transform transition-all duration-300`}
                        style={{
                            gridTemplateRows: openAdd ? '1fr' : '0fr',
                        }}
                    >
                        <div className="flex flex-col gap-3 overflow-hidden" ref={filterRef}>
                            <FilterList
                                key={openAdd}
                                mobileBP={mobileBP}
                                disabled={disabled}
                                list={filterOptions}
                                filter={filter}
                                relations={[]}
                                onChangeFilter={onChangeFilter.bind(null, handleCloseAdd)}
                            />
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};

FilterMobile.propTypes = {
    disabled: PropTypes.bool,
    filterOptions: PropTypes.array,
    filter: PropTypes.array,
    onChangeFilter: PropTypes.func,
    onRemoveFilter: PropTypes.func,
    mobileBP: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
};

FilterMobile.defaultProps = {
    disabled: false,
    filterOptions: [],
    filter: [],
    onChangeFilter: () => {},
    onRemoveFilter: () => {},
    mobileBP: 'sm',
};

export default FilterMobile;
