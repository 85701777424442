export const cellHeight = 30;
export const cellStyle = {
    width: cellHeight + 'px',
    height: cellHeight + 'px',
    maxHeight: cellHeight + 'px',
    fontSize: '1rem',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
};
