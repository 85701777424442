import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { crm } from 'routes';

import CreateContact from './create-contact';
import EditContact from './edit-contact';
import ManageContacts from './manage-contacts';

const Contacts = () => {
    return (
        <>
            <Switch>
                <Route path={crm.base + crm.contacts.base + crm.contacts.create}>
                    <CreateContact />
                </Route>

                <Route exact path={crm.base + crm.contacts.base + crm.contacts.read}>
                    <ManageContacts />
                </Route>

                <Route path={crm.base + crm.contacts.base + crm.contacts.updateFull}>
                    <EditContact />
                </Route>
            </Switch>
        </>
    );
};

export default Contacts;
