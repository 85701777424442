import React, { useContext, useEffect, useState } from 'react';

import Configurator from 'components/shared/configurator/configurator';
import ConfiguratorForm from 'components/shared/configurator/configurator-form';
import UserContext from 'contexts/UserContext';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreatePartner = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '7',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>{t('Create partner')}</title>
            </Helmet>

            <Header
                pageTitle={t('Create partner')}
                toolbar={
                    <Tabs tabs={[t('Preview'), 'Configurator']} activeTab={activeTab} setActiveTab={setActiveTab} />
                }
            />

            <div className="page-container relative">
                {activeTab === 0 ? <ConfiguratorForm entity="partner" /> : null}

                {activeTab === 1 ? <Configurator entity="partner" /> : null}
            </div>
        </>
    );
};

export default CreatePartner;
