import React from 'react';

import PropTypes from 'prop-types';

import Filter from '../filter/filter';

const breakpointClasses = {
    sm: { wrapper: 'sm:mx-auto sm:w-full sm:max-w-3xl' },
    md: { wrapper: 'md:mx-auto md:w-full md:max-w-3xl' },
    lg: { wrapper: 'lg:mx-auto lg:w-full lg:max-w-3xl' },
    xl: { wrapper: 'xl:mx-auto xl:w-full xl:max-w-3xl' },
    '2xl': { wrapper: '2xl:mx-auto 2xl:w-full 2xl:max-w-3xl' },
};

const PageFilter = ({ title, filterOptions, filter, setFilter, onFilter, disabled, mobileBP }) => {
    return (
        <div className={`flex flex-col gap-6 ${breakpointClasses[mobileBP].wrapper}`}>
            <p className={`text-2xl ${disabled ? 'text-disabled' : ''}`}>{title}</p>
            <Filter
                disabled={disabled}
                filter={filter}
                setFilter={setFilter}
                filterOptions={filterOptions}
                onFilter={onFilter}
                mobileBP={mobileBP}
            />
        </div>
    );
};

PageFilter.propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string,
    filterOptions: PropTypes.array,
    filter: PropTypes.array,
    setFilter: PropTypes.func,
    onFilter: PropTypes.func,
    mobileBP: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
};

PageFilter.defaultProps = {
    disabled: false,
    title: '',
    filterOptions: [],
    filter: [],
    setFilter: () => {},
    onFilter: () => {},
    mobileBP: 'sm',
};

export default PageFilter;
