import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import EmployeeAPI from 'api/Employee';
import BasicTooltip from 'components/shared/basic-tooltip';
import useConfirm from 'hooks/useConfirm';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { internalActivity, projectManagement } from 'routes';

const rootUrl = {
    pm: projectManagement.base + projectManagement.projectManagementConfigurator.base,
    internalActivity: internalActivity.base,
};

function EmployeeRow({ employee, moduleType, actionCallback, canEditEmploy }) {
    const history = useHistory();
    const confirm = useConfirm();
    const { t } = useTranslation();
    const { projectId } = useParams();
    const employeeTotalHours = employee.schedule.map((h) => h.hours).reduce((acc, val) => acc + val, 0);

    const Employee = moduleType === 'perProject' ? EmployeeAPI.perProject(projectId) : EmployeeAPI[moduleType];

    return (
        <div className="group relative flex flex-wrap justify-center gap-1 rounded-md bg-layout-transparent-dark px-8 py-4">
            <div className="relative flex flex-1 flex-wrap items-center">
                <p className="mr-2 text-lg font-semibold">{employee.firstName}</p>
                <p className="inline-flex text-dark-text">
                    {employee.position} <span className="mx-4 text-sm text-dark-text opacity-50">&#9679;</span>
                </p>

                <p className="text-dark-text">{employeeTotalHours}h</p>
            </div>

            {canEditEmploy && (
                <div className="flex gap-2 ">
                    <div className="flex items-center">
                        <BasicTooltip tip={t('Edit employee')}>
                            <div
                                className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-primary-main transition-all  hover:bg-primary-light group-hover:pointer-events-auto group-hover:translate-y-0"
                                onClick={() =>
                                    history.push({
                                        pathname: `${rootUrl[moduleType]}/edit-employee/${employee.id}`,
                                        state: { employeeType: employee.employeeType.toLowerCase() },
                                    })
                                }
                            >
                                <EditIcon className="text-buttons-text" style={{ fontSize: '1.1rem' }} />
                            </div>
                        </BasicTooltip>
                    </div>

                    <div className="flex items-center">
                        {employee.isDeleted ? (
                            <BasicTooltip tip={t('Activate employee')}>
                                <div
                                    className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-success transition-all  hover:bg-success-light group-hover:pointer-events-auto group-hover:translate-y-0"
                                    onClick={() =>
                                        confirm(t('Are you sure you want to activate this employee?'), () =>
                                            Employee.activate(employee).then(actionCallback)
                                        )
                                    }
                                >
                                    <PersonAddIcon className="text-buttons-text" style={{ fontSize: '1.1rem' }} />
                                </div>
                            </BasicTooltip>
                        ) : (
                            <BasicTooltip tip={t('Deactivate employee')}>
                                <div
                                    className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-error transition-all  hover:bg-error-light group-hover:pointer-events-auto group-hover:translate-y-0"
                                    onClick={() =>
                                        confirm(t('Are you sure you want to deactivate this employee?'), () =>
                                            Employee.deactivate(employee.id).then(actionCallback)
                                        )
                                    }
                                >
                                    <BlockIcon className="text-buttons-text" style={{ fontSize: '1.1rem' }} />
                                </div>
                            </BasicTooltip>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

EmployeeRow.propTypes = {
    employee: PropTypes.object.isRequired,
    moduleType: PropTypes.oneOf(['pm', 'internalActivity']).isRequired,
    actionCallback: PropTypes.func,
    canEditEmploy: PropTypes.bool,
};

export default function ViewEmployees({ moduleType, employees, actionCallback, canEditEmploy }) {
    const activeEmployees = employees.filter((employee) => !employee.isDeleted);
    const inactiveEmployees = employees.filter((employee) => employee.isDeleted);
    const { t } = useTranslation();

    return (
        <div className="w-4/12 xl:w-1/2 lg:w-3/4 md:w-full">
            {activeEmployees.length > 0 && (
                <section className="mb-10 flex flex-col gap-2">
                    <h4 className="mb-4 text-2xl text-dark-text">{t('Active Employees')}</h4>
                    {activeEmployees.map((employee) => (
                        <EmployeeRow
                            key={employee.id}
                            employee={employee}
                            moduleType={moduleType}
                            actionCallback={actionCallback}
                            canEditEmploy={canEditEmploy}
                        />
                    ))}
                </section>
            )}

            {inactiveEmployees.length > 0 && (
                <section className="mb-10 flex flex-col gap-2">
                    <h4 className="mb-4 text-2xl text-dark-text">{t('Inactive Employees')}</h4>
                    {inactiveEmployees.map((employee) => (
                        <EmployeeRow
                            key={employee.id}
                            employee={employee}
                            moduleType={moduleType}
                            actionCallback={actionCallback}
                            canEditEmploy={canEditEmploy}
                        />
                    ))}
                </section>
            )}
        </div>
    );
}

ViewEmployees.propTypes = {
    moduleType: PropTypes.oneOf(['pm', 'internalActivity']).isRequired,
    employees: PropTypes.array.isRequired,
    actionCallback: PropTypes.func,
    canEditEmploy: PropTypes.bool,
};
