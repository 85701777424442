import React, { cloneElement, Fragment } from 'react';

import PropTypes from 'prop-types';

const PipelineItemHeader = ({ itemProps, item, open }) => {
    const visibleRows = itemProps.header.renderVisibleRows(item);
    const extendedRows = itemProps.header.renderExpandedRows(item);

    const canExtend = extendedRows.length > 0;

    return (
        <div
            className="grid items-center gap-x-2 rounded-md bg-layout-transparent p-3"
            style={{
                gridTemplateColumns: '2rem 1fr',
            }}
        >
            {visibleRows.map((row, index) => {
                return (
                    <Fragment key={index}>
                        {row.icon ? (
                            <div className={`flex items-center justify-center ${index !== 0 ? 'mt-2' : ''}`}>
                                {cloneElement(row.icon, {
                                    style: {
                                        fontSize: '1.75rem',
                                    },
                                })}
                            </div>
                        ) : (
                            '\u00A0'
                        )}

                        <p className={`text-lg font-semibold leading-none ${index !== 0 ? 'mt-2' : ''}`}>
                            {row.content}
                        </p>
                    </Fragment>
                );
            })}

            {canExtend && (
                <div
                    className={`col-span-full grid transform transition-all duration-300 ${
                        open ? 'translate-y-2 pb-2' : 'translate-y-0'
                    }`}
                    style={{
                        gridTemplateColumns: 'subgrid',
                        gridTemplateRows: open ? '1fr' : `0fr`,
                    }}
                >
                    <div
                        className="col-span-full grid items-center gap-2 overflow-hidden"
                        style={{
                            gridTemplateColumns: 'subgrid',
                        }}
                    >
                        {extendedRows.map((row, index) => {
                            return (
                                <Fragment key={index}>
                                    {row.icon ? (
                                        <div className="flex rotate-45 transform items-center justify-center">
                                            {cloneElement(row.icon, {
                                                style: {
                                                    fontSize: '1.75rem',
                                                },
                                            })}
                                        </div>
                                    ) : (
                                        '\u00A0'
                                    )}

                                    <p className="font-semibold">{row.content}</p>
                                </Fragment>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

PipelineItemHeader.propTypes = {
    itemProps: PropTypes.object,
    item: PropTypes.object,
    open: PropTypes.bool,
};

PipelineItemHeader.defaultProps = {
    itemProps: {},
    item: {},
    open: false,
};

export default PipelineItemHeader;
