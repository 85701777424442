import React, { useCallback, useContext, useEffect, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';

import { Button, TextField } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import { CheckItem, LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { configurator } from 'routes';
import { formatDate, linkStyle } from 'utils';
import API from 'utils/axios';

const EditZoneDetails = (props) => {
    const { zoneId } = props;
    const history = useHistory();
    const { t } = useTranslation();

    const [zoneName, setZoneName] = useState('');
    const [observation, setObservation] = useState('');
    const [checked, setChecked] = useState(false);
    const [createdAt, setCreatedAt] = useState(null);
    const [area, setArea] = useState(null);

    const { enqueueSnackbar } = useSnackbar();
    const optionSpanClass = 'text-white font-bold';

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '11',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        const getZone = async () => {
            try {
                const response = await API.get(`/area/${zoneId}`);
                setArea(response.data.area);
            } catch (err) {
                console.error(err);
            }
        };
        getZone();
    }, []);

    useEffect(() => {
        if (area) {
            setZoneName(area?.name);
            setObservation(area?.description);
            setChecked(area?.status === 'ACTIVE');
            setCreatedAt(area?.createAt);
        }
    }, [area]);

    const updateZone = useCallback(async () => {
        if (zoneName.length < 3) {
            enqueueSnackbar(t('Area name must be at least 3 characters long!'), { variant: 'error' });
            return;
        }
        try {
            await API.put('/areas', {
                data: {
                    name: zoneName,
                    description: observation,
                },
                id: zoneId,
            });
            enqueueSnackbar(t('Area was updated successfully!'), { variant: 'success' });

            history.push(configurator.base + `?tab=Areas %26 Projects`);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(t('Failed to update area!'), { variant: 'error' });
        }
    }, [zoneName, observation]);

    return (
        <div className="page-container">
            <div className="inline-grid grid-cols-2 gap-20 md:flex md:flex-col">
                <div className="w-96 sm:w-full">
                    <h4 className="mb-10 text-2xl text-gray-300">{t('User details')}</h4>
                    <div className="mb-5">
                        <TextField
                            name={'Nume zonă'}
                            label={t('Area name')}
                            placeholder={t('Add a name for the area')}
                            value={zoneName}
                            onChange={(e) => setZoneName(e.target.value)}
                        />
                    </div>

                    <div className="mb-5">
                        <TextField
                            name="descriere"
                            label={t('Observations')}
                            placeholder={t('Add observations')}
                            value={observation}
                            onChange={(e) => setObservation(e.target.value)}
                            multiline
                            rows={3}
                        />
                    </div>
                    <div className="w-min">
                        <LabelWrapper label={'Status'}>
                            <CheckItem
                                name={checked ? t('active') : t('inactive')}
                                checked={checked}
                                small
                                setChecked={() => setChecked(!checked)}
                            />
                        </LabelWrapper>
                    </div>
                </div>
                <div className="w-96 sm:w-full">
                    <h4 className="mb-10 text-2xl text-gray-300">{t('Lead activity')}</h4>
                    <div className="mb-5">
                        <LabelWrapper label={t('Created by')}>
                            <div className="cursor-pointer rounded-md bg-layout-transparent p-3">
                                <span
                                    className={`${optionSpanClass} ${canAll & linkStyle}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (!canAll) return;
                                        history.push(
                                            configurator.base +
                                                configurator.users.base +
                                                configurator.users.update +
                                                '/' +
                                                area?.authorId +
                                                '?tab=1'
                                        );
                                    }}
                                >
                                    {area?.author?.profile?.name}
                                </span>
                            </div>
                        </LabelWrapper>
                    </div>

                    <div className="mb-5">
                        <LabelWrapper label={t('Created on')}>
                            <div className="cursor-not-allowed rounded-md bg-layout-transparent p-3">
                                <span className={optionSpanClass}>{formatDate(createdAt)}</span>
                            </div>
                        </LabelWrapper>
                    </div>
                </div>
                <div className="mt-10">
                    <Button size="large" color="primary" startIcon={<CheckIcon />} onClick={updateZone}>
                        {t('Update data')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

EditZoneDetails.propTypes = {
    zoneId: PropTypes.string,
};

EditZoneDetails.defaultProps = {
    zoneId: null,
};

export default EditZoneDetails;
