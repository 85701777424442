import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ProjectStructureForm from 'components/project-structures/project-structure-form';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateProjectStructure = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '42',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>{t('Add new Project structure')}</title>
            </Helmet>

            <Header pageTitle={t('Add new Project structure')} />
            {canAll && (
                <div className="page-container">
                    <ProjectStructureForm />
                </div>
            )}
        </>
    );
};

export default CreateProjectStructure;
