import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Configurator from 'components/shared/configurator/configurator';
import ConfiguratorForm from 'components/shared/configurator/configurator-form';
import UserContext from 'contexts/UserContext';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateContact = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const { checkPerm } = useContext(UserContext);
    const history = useHistory();

    const canAll = checkPerm([
        {
            permissionId: '6',
            permissionType: 'ALL',
        },
    ]);

    const canAllConfigurator = checkPerm([
        {
            permissionId: '13',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    const allTabs = [
        {
            id: '6',
            tab: t('Preview'),
        },
        {
            id: '13',
            tab: 'Configurator',
        },
    ];
    const tabs = [];
    allTabs.forEach((t) => {
        if (
            checkPerm([
                {
                    permissionId: t.id,
                    permissionType: 'ALL',
                },
            ])
        )
            tabs.push(t.tab);
    });

    return (
        <>
            <Helmet>
                <title>CRM | {t('create-contact')}</title>
            </Helmet>

            <Header
                pageTitle={t('create-contact')}
                toolbar={<Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />}
            />

            {canAll && (
                <div className="page-container">
                    {activeTab === tabs.indexOf(t('Preview')) ? (
                        <ConfiguratorForm entity="contact" />
                    ) : activeTab === tabs.indexOf('Configurator') ? (
                        <>{canAllConfigurator && <Configurator entity="contact" />}</>
                    ) : null}
                </div>
            )}
        </>
    );
};

export default CreateContact;
