import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FingerprintIcon from '@material-ui/icons/Fingerprint';

import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Layout } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { whoAmI } from 'utils';
import API from 'utils/axios';

const LoginPage = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { setUser } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const [loading, setLoading] = useState(false);

    const iconStyle = {
        marginRight: '1rem',
        color: 'var(--main-text)',
        fontSize: '4rem',
        flexShrink: 0,
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await API.post('login', {
                email: username,
                password,
            });
            const { token } = res.data;
            localStorage.setItem('accessToken', token);
            API.defaults.headers.common.Authorization = token;
            const findUser = await whoAmI();
            if (findUser !== null) {
                setUser(findUser);
                enqueueSnackbar('Welcome back!', { variant: 'success' });

                history.push('/');
            } else {
                localStorage.removeItem('accessToken');
                setUser(null);
            }
        } catch (err) {
            enqueueSnackbar(err.response.data.message, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>ERP | Login</title>
            </Helmet>

            <Layout sidebar={false}>
                <form className="relative flex h-svh w-full items-center justify-center" onSubmit={handleSubmit}>
                    <div className="w-full max-w-sm -translate-y-12 transform">
                        <div className="mb-12 flex items-center">
                            <FingerprintIcon style={iconStyle} />
                            <h1 className="font-medium uppercase text-main-text">ERP LOGIN</h1>
                        </div>

                        <div className="mb-10 w-full">
                            <div className="mb-5">
                                <TextField
                                    label={t('User')}
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <TextField
                                label={t('Password')}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <div className="mb-5 flex items-center justify-between">
                            <Button
                                type="submit"
                                fullWidth
                                color="primary"
                                style={{
                                    pointerEvents: loading ? 'none' : 'all',
                                }}
                            >
                                {t('Log in')}
                            </Button>

                            <div className="w-5" />

                            <Button fullWidth variant="text" onClick={() => history.push('/recover-password')}>
                                {t('Recover password')}
                            </Button>
                        </div>

                        <FormControlLabel
                            control={<Checkbox color="primary" checked={rememberMe} onChange={setRememberMe} />}
                            label={<p className="pointer-events-none text-main-text">{t('Remember me')}</p>}
                        />
                    </div>
                </form>
            </Layout>
        </>
    );
};

export default LoginPage;
