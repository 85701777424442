import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import API from 'utils/axios';

import CategoryForm from '../../../components/crm/quotes/category-form';

const EditArticleCategory = () => {
    const { id: categoryId } = useParams();
    const [category, setCategory] = useState(null);

    const { t } = useTranslation();

    const getCategory = async () => {
        try {
            const res = await API.get(`category/${categoryId}`);
            setCategory(res.data.category);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getCategory();
    }, []);

    const { checkPerm } = useContext(UserContext);
    const history = useHistory();

    const canAll = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push(quotes.base + quotes.nomenclature.base + quotes.nomenclature.read);
        }
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>
                    {t('nomenclature')} | {t('Edit category')}
                </title>
            </Helmet>

            <Header pageTitle={t('Edit category')} />

            <div className="page-container">
                <CategoryForm category={category} id={categoryId} />
            </div>
        </>
    );
};

export default EditArticleCategory;
