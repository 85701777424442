import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import RevenueForm from '../../../components/accountancy/revenue-form';

const EditExpense = () => {
    const { t } = useTranslation();
    const { revenueId } = useParams();
    const { permissionMap } = useContext(UserContext);
    const canAll = permissionMap['Nomenclator venituri'] > 1;
    const history = useHistory();

    useEffect(() => {
        if (!canAll) history.push('/');
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>{t('Edit revenue')}</title>
            </Helmet>

            <Header pageTitle={t('Edit revenue')} />

            <div className="page-container">
                <RevenueForm revenueId={revenueId} canEdit={canAll} />
            </div>
        </>
    );
};

export default EditExpense;
