import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import { formatDate, getTextHeight, remToPx } from 'utils';

import RiemFooter from './riem-footer';
import RiemHeader from './riem-header';
import RiemInfo from './riem-info';
import RiemItem from './riem-item';

const COLUMN_WIDTHS_PORTRAIT = {
    small: '3rem',
    medium: '5rem',
    big: '7rem',
    large: '10.5rem',
};

const COLUMNS_WIDTHS_PORTRAIT = {
    number: COLUMN_WIDTHS_PORTRAIT['small'],
    stage: COLUMN_WIDTHS_PORTRAIT['large'],
    identifiedDescription: COLUMN_WIDTHS_PORTRAIT['large'],
    identifiedScore: COLUMN_WIDTHS_PORTRAIT['medium'],
    identifiedProbability: COLUMN_WIDTHS_PORTRAIT['medium'],
    identifiedTotal: COLUMN_WIDTHS_PORTRAIT['medium'],
    measures: COLUMN_WIDTHS_PORTRAIT['large'],
    residualDescription: COLUMN_WIDTHS_PORTRAIT['large'],
    residualScore: COLUMN_WIDTHS_PORTRAIT['medium'],
    residualProbability: COLUMN_WIDTHS_PORTRAIT['medium'],
    residualTotal: COLUMN_WIDTHS_PORTRAIT['medium'],
    identificationDate: COLUMN_WIDTHS_PORTRAIT['big'],
    lastUpdate: COLUMN_WIDTHS_PORTRAIT['big'],
};

const COLUMN_WIDTHS_LANDSCAPE = {
    small: '3rem',
    medium: '5rem',
    big: '7rem',
    large: '21rem',
};

const COLUMNS_WIDTHS_LANDSCAPE = {
    number: COLUMN_WIDTHS_LANDSCAPE['small'],
    stage: COLUMN_WIDTHS_LANDSCAPE['large'],
    identifiedDescription: COLUMN_WIDTHS_LANDSCAPE['large'],
    identifiedScore: COLUMN_WIDTHS_LANDSCAPE['medium'],
    identifiedProbability: COLUMN_WIDTHS_LANDSCAPE['medium'],
    identifiedTotal: COLUMN_WIDTHS_LANDSCAPE['medium'],
    measures: COLUMN_WIDTHS_LANDSCAPE['large'],
    residualDescription: COLUMN_WIDTHS_LANDSCAPE['large'],
    residualScore: COLUMN_WIDTHS_LANDSCAPE['medium'],
    residualProbability: COLUMN_WIDTHS_LANDSCAPE['medium'],
    residualTotal: COLUMN_WIDTHS_LANDSCAPE['medium'],
    identificationDate: COLUMN_WIDTHS_LANDSCAPE['medium'],
    lastUpdate: COLUMN_WIDTHS_LANDSCAPE['medium'],
};

const COLUMN_WIDTHS_TYPE = {
    portrait: COLUMN_WIDTHS_PORTRAIT,
    landscape: COLUMN_WIDTHS_LANDSCAPE,
};

const COLUMNS_WIDTHS_TYPE = {
    portrait: COLUMNS_WIDTHS_PORTRAIT,
    landscape: COLUMNS_WIDTHS_LANDSCAPE,
};

const RiemTable = forwardRef(function RiemTable({ riem, companyLogo, renderType, renderOrder }, ref) {
    const columnWidths = COLUMN_WIDTHS_TYPE[renderType];

    const { infoHeight, headerHeight, rowsHeights, footerHeight } = (() => {
        if (!renderType) return { infoHeight: null, headerHeight: null, rowsHeights: [], footerHeight: null };

        const columnsWidths = COLUMNS_WIDTHS_TYPE[renderType];

        let currentHeight = 0;
        const newDisabledCropHeightsIntervals = [];

        const mappedInfoContent = {
            project: `: ${riem.project.name}`,
            beneficiary: `: ${
                typeof riem.project.contact.data === 'string'
                    ? JSON.parse(riem.project.contact.data).standard.name
                    : riem.project.contact.data.standard.name
            }`,
            dates: `\n${formatDate(riem.createdAt, true, true)} / ${formatDate(riem.updatedAt, true, true)}`,
        };

        const infoHeight = Math.max(
            [
                ...Object.entries(riem.info)
                    .filter(([key]) => key !== 'title')
                    .map(([key, val]) =>
                        getTextHeight(
                            `${val}${mappedInfoContent[key]}`,
                            'whitespace-pre-wrap text-xl font-bold',
                            remToPx(columnsWidths['residualProbability']) +
                                remToPx(columnsWidths['residualTotal']) +
                                remToPx(columnsWidths['identificationDate']) +
                                remToPx(columnsWidths['lastUpdate']) -
                                remToPx('3rem')
                        )
                    ),
                remToPx('9rem'),
            ].reduce((acc, curr) => (acc += curr), 0),
            getTextHeight(
                riem.info.title,
                'p-6 text-4xl font-bold',
                remToPx(columnsWidths['identifiedDescription']) +
                    remToPx(columnsWidths['identifiedScore']) +
                    remToPx(columnsWidths['identifiedProbability']) +
                    remToPx(columnsWidths['identifiedTotal']) +
                    remToPx(columnsWidths['measures']) +
                    remToPx(columnsWidths['residualDescription']) +
                    remToPx(columnsWidths['residualScore'])
            )
        );

        newDisabledCropHeightsIntervals.push([currentHeight, currentHeight + infoHeight]);
        currentHeight += infoHeight;

        const headerHeight = Math.max(
            ...Object.entries(riem.header).map(([key, val]) =>
                getTextHeight(val, 'w-full break-words px-0.5 py-1 text-xl font-bold', remToPx(columnsWidths[key]))
            )
        );
        newDisabledCropHeightsIntervals.push([currentHeight, currentHeight + headerHeight]);
        currentHeight += headerHeight;

        const rowsHeights = riem.items.map((item) => {
            const height = Math.max(
                ...Object.entries(item).map(([key, val]) =>
                    getTextHeight(val, 'w-full break-words px-0.5 py-1 text-xl font-bold', remToPx(columnsWidths[key]))
                )
            );

            newDisabledCropHeightsIntervals.push([currentHeight, currentHeight + height]);
            currentHeight += height;

            return `${height}px`;
        });

        const footerHeight = getTextHeight(
            riem.footer.total,
            'w-full break-words px-0.5 py-1 text-xl font-bold',
            remToPx(columnsWidths['number']) +
                remToPx(columnsWidths['stage']) +
                remToPx(columnsWidths['identifiedDescription'])
        );
        newDisabledCropHeightsIntervals.push([currentHeight, currentHeight + footerHeight]);

        ref.current[renderOrder] = newDisabledCropHeightsIntervals;

        return {
            infoHeight: `${infoHeight}px`,
            headerHeight: `${headerHeight}px`,
            rowsHeights,
            footerHeight: `${footerHeight}px`,
        };
    })();

    return (
        <div
            role="table"
            className="row-span-full grid"
            style={{
                gridTemplateColumns: renderType
                    ? `${columnWidths['small']} repeat(2, ${columnWidths['large']}) repeat(3, ${columnWidths['medium']}) repeat(2, ${columnWidths['large']}) repeat(3, ${columnWidths['medium']}) repeat(2, ${columnWidths['big']})`
                    : 'repeat(13, minmax(min-content, 1fr))',
                gridTemplateRows: renderType ? `repeat(${riem.items.length + 3}, min-content)` : 'subgrid',
            }}
        >
            <div
                role="rowgroup"
                data-groupRole="thead"
                className="col-span-full row-span-2 grid text-center"
                style={{
                    gridTemplateRows: 'subgrid',
                    gridTemplateColumns: 'subgrid',
                }}
            >
                <RiemInfo riem={riem} companyLogo={companyLogo} renderType={renderType} height={infoHeight} />
                <RiemHeader riem={riem} renderType={renderType} height={headerHeight} />
            </div>

            <div
                role="rowgroup"
                data-groupRole="tbody"
                className="col-span-full grid"
                style={{
                    gridRow: `span ${riem.items.length + 1} / span ${riem.items.length + 1}`,
                    gridTemplateColumns: 'subgrid',
                    gridTemplateRows: 'subgrid',
                }}
            >
                {riem.items.map((item, index) => (
                    <RiemItem
                        key={index}
                        index={index}
                        item={item}
                        riem={riem}
                        renderType={renderType}
                        height={rowsHeights[index] ?? null}
                    />
                ))}

                <RiemFooter riem={riem} renderType={renderType} height={footerHeight} />
            </div>
        </div>
    );
});

RiemTable.propTypes = {
    riem: PropTypes.object,
    companyLogo: PropTypes.string,
    renderType: PropTypes.string,
    renderOrder: PropTypes.number,
};

RiemTable.defaultProps = {
    riem: {},
    companyLogo: '',
    renderType: null,
    renderOrder: null,
};

export default RiemTable;
