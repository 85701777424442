import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Footer from 'components/footer/footer';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { modules } from 'routes';

const Modules = () => {
    return (
        <>
            <Helmet>
                <title>Modules</title>
            </Helmet>

            <Switch>
                <Route exact path={modules.base}>
                    <Header pageTitle="MODULES" />
                </Route>

                <Route path={modules.base + modules.worksUndertaking.base}>
                    <div className="page-container relative">
                        <h3>Feature in development!</h3>
                    </div>
                </Route>

                <Route path={modules.base + modules.salesModule.base}>
                    <div className="page-container relative">
                        <h3>Feature in development!</h3>
                    </div>
                </Route>

                <Route path={modules.base + modules.bookingModule.base}>
                    <div className="page-container relative">
                        <h3>Feature in development!</h3>
                    </div>
                </Route>

                <Route path={modules.base + modules.facilityManagement.base}>
                    <div className="page-container relative">
                        <h3>Feature in development!</h3>
                    </div>
                </Route>
            </Switch>

            <Footer />
        </>
    );
};

export default Modules;
