import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Footer from 'components/footer/footer';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';

import Attributes from './attributes/attributes';
import BiddingHistory from './biddingHistory/bidding-history';
import BiddingPipeline from './biddingPipeline/bidding-pipeline';
import NewOffer from './newBidding/new-offer';
import OfferView from './newBidding/offer-view';
import Nomenclatures from './nomenclature/nomenclatures';
import Variations from './variations/variations';

const Quotes = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('Bidding')}</title>
            </Helmet>

            <Switch>
                <Route path={quotes.base + quotes.biddingPipeline.base + quotes.biddingPipeline.read}>
                    <BiddingPipeline />
                </Route>

                <Route path={quotes.base + quotes.biddingHistory.base + quotes.biddingHistory.read}>
                    <BiddingHistory />
                </Route>

                <Route path={quotes.base + quotes.newBidding.base + quotes.newBidding.read}>
                    <NewOffer />
                </Route>

                <Route path={quotes.base + quotes.viewBidding.full}>
                    <OfferView />
                </Route>

                <Route path={quotes.base + quotes.nomenclature.base + quotes.nomenclature.read}>
                    <Nomenclatures />
                </Route>

                <Route path={quotes.base + quotes.attributes.base}>
                    <Attributes />
                </Route>

                <Route path={quotes.base + quotes.variations.base}>
                    <Variations />
                </Route>
            </Switch>

            <Footer />
        </>
    );
};

export default Quotes;
