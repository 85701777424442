import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

const defaultValues = {
    query: '',
    filterParameters: {},
    setQuery: () => {},
    onUpdateFilterParameters: () => {},
    title: '',
    setTitle: () => {},
};

const ResourcesContext = createContext(defaultValues);

const ResourcesProvider = ({ children }) => {
    const location = useLocation();
    const [query, setQuery] = useState('');
    const [filterParameters, setFilterParameters] = useState({ type: 'All' });
    const [title, setTitle] = useState('');

    const handleUpdateFilterParameters = (e) => {
        setFilterParameters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    useEffect(() => {
        setQuery('');
        setFilterParameters({ type: 'All' });
    }, [location.pathname]);

    return (
        <ResourcesContext.Provider
            value={{
                query,
                filterParameters,
                title,
                setQuery,
                onUpdateFilterParameters: handleUpdateFilterParameters,
                setTitle,
            }}
        >
            {children}
        </ResourcesContext.Provider>
    );
};

ResourcesProvider.propTypes = {
    children: PropTypes.node,
};

const useResources = () => {
    const value = useContext(ResourcesContext);
    if (value === undefined) throw new Error('RESOURCES CONTEXT WAS USED OUTSIDE THE PROVIDER');

    return value;
};

export { ResourcesProvider, useResources };
