import { calculateVATValue } from 'utils';

/**
 *
 * @param {*} a - article
 * @param {*} selectedAttributes - selected attributes from this article
 * @param {*} selectedVariations  - selected variations from this article
 * @returns the total price of the article without vat value
 */
const totalPrice = (a, selectedAttributes, selectedVariations) => {
    const articlePriceTotal = (
        Number(a?.price?.pricePerUnit * a.amount || a.quantity) +
        Number(
            a.selectedVariables
                ?.map((v) => Number(v.price?.pricePerUnit * (v.amount || v.quantity)))
                ?.reduce((a, b) => a + b, 0)
                .toFixed(2)
        ) +
        Number(
            selectedAttributes
                ?.map((a) => a.price?.pricePerUnit)
                ?.reduce((a, b) => a + b, 0)
                .toFixed(2)
        ) +
        Number(
            selectedVariations
                ?.map((a) => a.price?.pricePerUnit)
                ?.reduce((a, b) => a + b, 0)
                .toFixed(2)
        )
    ).toFixed(2);

    return articlePriceTotal;
};

/**
 *
 * @param {*} a - article
 * @param {*} selectedAttributes - selected attributes from this article
 * @param {*} selectedVariations - selected variations from this article
 * @returns  the total vat value of the article
 */
const totalVatValue = (a, selectedAttributes, selectedVariations) => {
    const articleTotalVat = (
        Number(calculateVATValue(a?.price?.pricePerUnit, a?.price?.procentualVAT) * a.amount) +
        a.selectedVariables
            ?.map((v) =>
                Number(calculateVATValue(v.price.pricePerUnit, v.price.procentualVAT) * (v.amount || v.quantity))
            )
            ?.reduce((a, b) => a + b, 0) +
        selectedAttributes
            ?.map((at) => Number(calculateVATValue(at.price.pricePerUnit, at.price.procentualVAT) * 1))
            ?.reduce((a, b) => a + b, 0) +
        selectedVariations
            ?.map((vr) => Number(calculateVATValue(vr.price.pricePerUnit, vr.price.procentualVAT) * 1))
            ?.reduce((a, b) => a + b, 0)
    ).toFixed(2);
    return articleTotalVat;
};

// Function for creating the object with the articles info and prices for offer PDF

/**
 *
 * @param {*} selectedArticles - an array with selected articles from offer
 * @param {*} offerContext - the offer context
 * @returns items, who is an array with the all info of the articles for PDF
 */
export const articlesInfoForPDF = (selectedArticles, revenues, offerId) => {
    const items = selectedArticles.map((article, index) => {
        const baseItemVatValue = Number(calculateVATValue(article.price.pricePerUnit, article.price.procentualVAT));

        const subItems = [];
        let returnedRevenues = [];

        let newSelectedAttributes = [];
        let newSelectedVariations = [];

        // If are selected variables in article, we loop through them and add the in subItems array objects with all information
        if (article.selectedVariables.length) {
            article.selectedVariables.forEach((variable) => {
                const variableVatValue = Number(
                    calculateVATValue(variable.price.pricePerUnit, variable.price.procentualVAT)
                );

                subItems.push({
                    nrArticle: '-',
                    amount: variable.amount || variable.quantity,
                    itemName: variable.name,
                    pricePerUnit: variable.price.pricePerUnit,
                    vatValue: variableVatValue * (variable.amount || variable.quantity),
                    totalPrice:
                        (variable.price.pricePerUnit + variableVatValue) * (variable.amount || variable.quantity),
                });
            });
        }

        if (article.ArticleRevenues.length) {
            returnedRevenues = article.ArticleRevenues.reduce((acc, e) => {
                // ? We try to find if the grand parent of the expense or revenue already exists
                const grandParentIdIndex = acc.findIndex((accEl) => accEl.grandParentId === e.grandParentId);

                // ? If the grand parent exists we push the new expense or revenue into the grand parent list
                if (grandParentIdIndex >= 0) {
                    const newAcc = structuredClone(acc);

                    newAcc[grandParentIdIndex].list.push({
                        value: e.value,
                        secondCurrencyValue: e.secondCurrencyValue,
                        id: e.revenueNameId,
                        Revenue: e.Revenue,
                    });

                    return newAcc;
                }

                const foundRevenue = revenues.find((fr) => fr.id === e.grandParentId);

                // ? If the grand parent does't exists we create a new entry in the array for him and the respective expense or revenue
                return [
                    ...acc,
                    {
                        grandParentId: e.grandParentId,
                        Revenue: foundRevenue,
                        list: [
                            {
                                value: e.value,
                                secondCurrencyValue: e.secondCurrencyValue,
                                id: e.revenueNameId,
                                Revenue: e.Revenue,
                            },
                        ],
                    },
                ];
            }, []);
        }

        // This if check if in OfferArticles from article exist the offer who we want to edit
        if (article.OfferArticles.findIndex((art) => art.offerId === offerId) !== -1) {
            // We set the indexes of the selected attributes
            const attributeIndexes = article.OfferArticles[
                article.OfferArticles.findIndex((art) => art.offerId === offerId)
            ].OfferArticleAttributes?.map((atr) => {
                return article.ArticleAttribute?.findIndex(
                    (elm) => elm.id === atr.articleAttribute.originalArticleAttributeId
                );
            });

            // If are selected attributes we will edit the article and we add the selected attributes
            if (attributeIndexes.length) {
                attributeIndexes.map((attribute) => {
                    const attributeToAdd = article.ArticleAttribute[attribute];
                    newSelectedAttributes = [...newSelectedAttributes, attributeToAdd];
                });

                // We loop through the selected attributes and push in subItems array the objects with the info
                if (newSelectedAttributes.length) {
                    newSelectedAttributes.forEach((attribute) => {
                        const attributeVatValue = Number(
                            calculateVATValue(attribute.price.pricePerUnit, attribute.price.procentualVAT)
                        );

                        subItems.push({
                            nrArticle: '-',
                            amount: '-',
                            itemName: attribute.name,
                            pricePerUnit: attribute.price.pricePerUnit,
                            vatValue: attributeVatValue,
                            totalPrice: attribute.price.pricePerUnit + attributeVatValue,
                        });
                    });
                }
            }

            // We set the indexes of the selected variations
            const variationIndexes = article.OfferArticles[
                article.OfferArticles.findIndex((art) => art.offerId === offerId)
            ].OfferArticlesVariations.map((variation) => {
                return article.ArticleVariations?.findIndex(
                    (elm) => elm.id === variation.articleVariation.originalArticleVariationId
                );
            });

            // If are selected variations we will edit the article and we add the selected variations
            if (variationIndexes.length) {
                variationIndexes.map((variation) => {
                    const variationToAdd = article.ArticleVariations[variation];
                    newSelectedVariations = [...newSelectedVariations, variationToAdd];
                });

                // We loop through the selected variations and push in subItems array the objects with the info
                if (newSelectedVariations.length) {
                    newSelectedVariations.forEach((variation) => {
                        const variationVatValue = Number(
                            calculateVATValue(variation.price.pricePerUnit, variation.price.procentualVAT)
                        );

                        subItems.push({
                            nrArticle: '-',
                            amount: '-',
                            itemName: variation.name,
                            pricePerUnit: variation.price.pricePerUnit,
                            vatValue: variationVatValue,
                            totalPrice: variation.price.pricePerUnit + variationVatValue,
                        });
                    });
                }
            }
        }

        const totalPriceValue = totalPrice(article, newSelectedAttributes, newSelectedVariations);
        const totalVatValueNew = totalVatValue(article, newSelectedAttributes, newSelectedVariations);

        /*
            Calculate totals
        */
        return {
            nrArticle: index + 1,
            amount: article.amount,
            itemName: article.name,
            pricePerUnit: Number(totalPriceValue),
            vatValue: Number(totalVatValueNew),
            totalPrice: Number(totalPriceValue) + Number(totalVatValueNew),
            baseItem: {
                nrArticle: '-',
                amount: article.amount,
                itemName: article.name,
                pricePerUnit: Number(article.price.pricePerUnit),
                vatValue: Number(baseItemVatValue) * Number(article.amount),
                totalPrice: (Number(article.price.pricePerUnit) + Number(baseItemVatValue)) * Number(article.amount),
                subItems,
                revenues: returnedRevenues,
            },
        };
    });

    return items;
};

//Function for making the object with sip information and prices for offer PDF
export const sipInfoForPDF = (selectedSIP, articleItems, companyVat) => {
    const sips = selectedSIP.map((sip, index) => {
        const sipData = typeof sip.data === 'string' ? JSON.parse(sip.data).standard : sip.data.standard;

        const sipVatValue = Number(calculateVATValue(sipData.price, companyVat));

        return {
            nrArticle:
                articleItems.length === 0 || !articleItems ? index + 1 : articleItems.at(-1).nrArticle + index + 1,
            amount: '-',
            itemName: sipData.name,
            pricePerUnit: sipData.price,
            vatValue: sipVatValue,
            totalPrice: sipData.price + sipVatValue,
            baseItem: null,
        };
    });

    return sips;
};
