import React, { useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from '@material-ui/core';

import ConfigFieldWrapper from 'components/shared/configurator/config-field-wrapper';
import PropTypes from 'prop-types';
import { ActionButton, InputGroup, SelectList } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';

const StructureField = (props) => {
    const { t } = useTranslation();
    const { field, changeFieldValue, deleteFieldFromColumn } = props;
    const [selectedType, setSelectedType] = useState([field.fieldOptions.indexOf(field.selectedTypeOptions)]);

    useEffect(() => {
        changeFieldValue('selectedTypeOptions', field.fieldOptions[selectedType[0]]);
    }, selectedType);

    return (
        <>
            {field ? (
                <ConfigFieldWrapper
                    canBeDeleted={field.canBeDeleted}
                    type={field.type}
                    name={field.displayName}
                    setName={(newValue) => changeFieldValue('displayName', newValue)}
                    deleteFieldFromColumn={deleteFieldFromColumn}
                >
                    <SelectList
                        options={field.fieldOptions}
                        selectedOptions={selectedType}
                        setSelectedOptions={setSelectedType}
                    />

                    <div className="h-3" />

                    {field.options.map((option, optionIndex) => (
                        <>
                            <div
                                className="group relative mb-2 border-l border-layout-transparent-dark pl-2"
                                key={optionIndex}
                            >
                                {/* Main option Delete Button */}
                                <div
                                    className="absolute right-1.5 z-50 opacity-0 transition-opacity group-hover:opacity-100"
                                    style={{ top: '.325rem' }}
                                >
                                    <ActionButton
                                        icon={<CloseIcon className="text-main-text" />}
                                        onClick={() => {
                                            const newOptions = field.options.filter(
                                                (_, index) => index !== optionIndex
                                            );
                                            changeFieldValue('options', newOptions);
                                        }}
                                    />
                                </div>

                                <InputGroup
                                    placeholder={t('Option name')}
                                    name={option.name}
                                    setName={(newName) => {
                                        const newOptions = [...field.options];
                                        newOptions[optionIndex].name = newName;
                                        changeFieldValue('options', newOptions);
                                    }}
                                >
                                    {option.options.map((opt, optIndex) => (
                                        <div className="relative mb-2" key={optIndex}>
                                            {/* Secondary option Delete Button */}
                                            <div
                                                className="absolute right-1.5 z-50 grid grid-cols-2 gap-1 opacity-0 transition-opacity group-hover:opacity-100"
                                                style={{ top: '.325rem' }}
                                            >
                                                <ActionButton
                                                    icon={<AddIcon className="text-main-text" />}
                                                    onClick={() => {
                                                        const newOptions = [...field.options];
                                                        newOptions[optionIndex].options[optIndex].options = [
                                                            ...newOptions[optionIndex].options[optIndex].options,
                                                            { name: '', options: [] },
                                                        ];
                                                        changeFieldValue('options', newOptions);
                                                    }}
                                                />
                                                <ActionButton
                                                    icon={<CloseIcon className="text-main-text" />}
                                                    onClick={() => {
                                                        const newOptions = [...field.options];
                                                        newOptions[optionIndex].options = newOptions[
                                                            optionIndex
                                                        ].options.filter((_, index) => index !== optIndex);
                                                        changeFieldValue('options', newOptions);
                                                    }}
                                                />
                                            </div>

                                            <InputGroup
                                                placeholder={t('Option name')}
                                                name={opt.name}
                                                setName={(newName) => {
                                                    const newOptions = [...field.options];
                                                    newOptions[optionIndex].options[optIndex].name = newName;
                                                    changeFieldValue('options', newOptions);
                                                }}
                                            >
                                                {opt.options.length ? (
                                                    <>
                                                        {opt.options.map((subOpt, subOptIndex) => (
                                                            <div className="relative mb-2 last:mb-0" key={subOptIndex}>
                                                                <div
                                                                    className="absolute right-1.5 z-50 opacity-0 transition-opacity group-hover:opacity-100"
                                                                    style={{ top: '.325rem' }}
                                                                >
                                                                    <ActionButton
                                                                        icon={<CloseIcon className="text-main-text" />}
                                                                        onClick={() => {
                                                                            const newOptions = [...field.options];
                                                                            newOptions[optionIndex].options[
                                                                                optIndex
                                                                            ].options = newOptions[optionIndex].options[
                                                                                optIndex
                                                                            ].options.filter(
                                                                                (_, index) => index !== subOptIndex
                                                                            );
                                                                            changeFieldValue('options', newOptions);
                                                                        }}
                                                                    />
                                                                </div>

                                                                <InputGroup
                                                                    placeholder={t('Option name')}
                                                                    name={subOpt.name}
                                                                    setName={(newName) => {
                                                                        const newOptions = [...field.options];
                                                                        newOptions[optionIndex].options[
                                                                            optIndex
                                                                        ].options[subOptIndex].name = newName;
                                                                        changeFieldValue('options', newOptions);
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </InputGroup>
                                        </div>
                                    ))}
                                    <Button
                                        onClick={() => {
                                            const newOptions = [...field.options];
                                            newOptions[optionIndex].options = [
                                                ...newOptions[optionIndex].options,
                                                { name: '', options: [] },
                                            ];
                                            changeFieldValue('options', newOptions);
                                        }}
                                    >
                                        <AddIcon />
                                    </Button>
                                </InputGroup>
                            </div>
                        </>
                    ))}

                    <div className="h-1" />

                    <Button
                        color="primary"
                        onClick={() => {
                            changeFieldValue('options', [
                                ...field.options,
                                {
                                    name: '',
                                    options: [],
                                },
                            ]);
                        }}
                    >
                        {t('Add structure')}
                    </Button>
                </ConfigFieldWrapper>
            ) : null}
        </>
    );
};

StructureField.propTypes = {
    field: PropTypes.object,
    changeFieldValue: PropTypes.func,
    deleteFieldFromColumn: PropTypes.func,
};

StructureField.defaultProps = {
    field: null,
    changeFieldValue: () => null,
    deleteFieldFromColumn: () => null,
};

export default StructureField;
