import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ProjectStructureForm from 'components/project-structures/project-structure-form';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const EditProjectStructure = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const history = useHistory();
    // ? Variable used to hold the current project structure
    const [projectStructureInfo, setProjectStructureInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [revisions, setRevisions] = useState([]);
    const [projectStructureSelection, setProjectStructureSelection] = useState(0);

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '42',
            permissionType: 'ALL',
        },
    ]);

    /**
     * ? We set the current project structure and her position
     * @param {number} position - Used to get the next or previous project structure
     * @param {boolean} isInitial - If we have the initial call we will set the revisions nad history length of that project structure
     */
    const getProjectStructure = async (position, isInitial = false) => {
        try {
            setLoading(true);

            if (!isInitial) setProjectStructureSelection(position);

            const response = await API.get(`projectStructure`, {
                params: {
                    id: isInitial ? id : revisions[position - 1].id,
                },
            });

            const rowData = response.data.projectStructure;

            if (isInitial) {
                let newRevisions = [{ id: rowData.id }];

                // ? If we have a project structure this means we have a history for this project structure
                if (rowData.ProjectStructure) {
                    // ? We construct the revisions object witch is the array that hold all the project structures id's
                    newRevisions = [
                        { id: rowData.ProjectStructure.id },
                        ...rowData.ProjectStructure.Revisions.slice(0, -1),
                        ...newRevisions,
                    ];
                }

                setRevisions(newRevisions);
                setProjectStructureSelection(newRevisions.findIndex((r) => r.id === id) + 1);
            }

            setProjectStructureInfo(rowData);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (!canAll) {
            history.goBack();
        }

        getProjectStructure(null, true);
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>{t('Edit Project structure')}</title>
            </Helmet>

            <Header pageTitle={t('Edit Project structure')} />
            {canAll && (
                <div className="page-container">
                    <ProjectStructureForm
                        projectStructureInfo={projectStructureInfo}
                        getNewProjectStructure={getProjectStructure}
                        historyLength={revisions.length}
                        projectStructureSelection={projectStructureSelection}
                        loading={loading}
                        setLoading={setLoading}
                        originalId={id}
                        isInitialLoad={isInitialLoad}
                        setIsInitialLoad={setIsInitialLoad}
                    />
                </div>
            )}
        </>
    );
};

export default EditProjectStructure;
