import React, { createContext, useCallback, useContext, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import GlobalContext from 'contexts/GlobalContext';
import PropTypes from 'prop-types';
import { Dropdown } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { accountancy } from 'routes';
import { getExpensesNameById, getExpensesNames } from 'utils/getterFunctions';
import { formatPositiveNumberWithDigits, toLocaleNumber } from 'utils/index';

const BudgetaryContext = createContext();

const MarginRow = ({ margin, setMargin, disabled = false }) => {
    const { t } = useTranslation();
    const { currencyObj } = useContext(GlobalContext);

    return (
        <div className="relative rounded-md bg-layout-transparent">
            <div className="flex w-full items-center gap-4 p-4">
                <div className="flex-grow">
                    <p className="text-2xl font-extrabold text-main-text">{t('Margin')}</p>
                </div>

                {/* Margin value textfield */}
                <div className="w-40 flex-none">
                    <LocaleTextField
                        disabled={disabled}
                        placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                        value={margin}
                        onChange={(e) => setMargin(formatPositiveNumberWithDigits(e.target.value))}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                        }}
                    />
                </div>

                {/* This divs are here to mach the structure of the expenses */}
                <div className="flex-none" style={{ width: '35px' }}></div>

                <div className="w-13 ml-8">
                    <div
                        style={{
                            width: '30px',
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

MarginRow.propTypes = {
    margin: PropTypes.number,
    setMargin: PropTypes.func,
    disabled: PropTypes.bool,
};

const GrandparentExpenseRow = ({ expense, listOfTheExpensesFromBack }) => {
    const { t } = useTranslation();
    const { deleteGrandparentExpense, addParentExpenseToAGrandParentExpense, disabled } =
        React.useContext(BudgetaryContext);
    const { currencyObj } = React.useContext(GlobalContext);

    const selectedGrandparentExpenseWithInfoFromBack = listOfTheExpensesFromBack.find((ex) => ex.id === expense.id); // We get the info of the selected grandparent expense, with his parent expenses info

    const filteredListOfTheGrandparentParentExpenses =
        selectedGrandparentExpenseWithInfoFromBack.subExpensesName?.filter(
            (subExpense) =>
                subExpense.isDeleted === false &&
                !expense.parentExpenses?.some((parentExpense) => parentExpense.id === subExpense.id)
        ); // We filer the parent expenses of the selected grandparent expense according to the parent expenses already selected to overcome the duplicates

    return (
        <div className="budgetary-border-selector relative mb-6">
            <div
                className="flex w-full items-center gap-4 rounded-t-md p-4 sm:gap-2"
                style={{
                    backgroundColor: expense.isDeleted ? 'rgb(var(--base-error) / 40%)' : 'var(--layout-transparent)',
                }}
            >
                {/* Grandparent expense name */}
                <div className="flex-grow">
                    <p className="text-2xl font-extrabold text-main-text" style={{ hyphens: 'auto' }}>
                        {expense.name}
                    </p>
                </div>

                {/* Grandparent value textfield */}
                <div className="w-40 flex-none">
                    <LocaleTextField
                        disabled
                        placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                        value={expense.value}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                        }}
                    />
                </div>

                {/* Dropdown for adding a new parent expense */}
                <div
                    className={`flex-none 
                     ${expense.isDeleted && 'w-12'}
                `}
                >
                    {selectedGrandparentExpenseWithInfoFromBack.subExpensesName?.length > 0 &&
                        !expense.isDeleted && ( // If the selected grandparent expense has sub expenses, it will be displayed
                            <Dropdown
                                disabled={disabled}
                                variant="green"
                                onlyIcon
                                icon={<AddIcon className="text-buttons-text" />}
                                options={filteredListOfTheGrandparentParentExpenses.map(
                                    (parentExpense) => parentExpense.name
                                )}
                                selectedOption={null}
                                setSelectedOption={(i) =>
                                    addParentExpenseToAGrandParentExpense(
                                        expense.id,
                                        filteredListOfTheGrandparentParentExpenses[i]
                                    )
                                }
                                placeholder={t('Add parent expense')}
                            />
                        )}
                </div>
                {/* The delete button */}
                <div className="w-13 ml-8 sm:ml-0">
                    {expense.parentExpenses?.length === 0 ? (
                        <div
                            className={`group flex items-center justify-center rounded-full border p-1 transition-all duration-150 ${
                                !disabled ? 'cursor-pointer border-error-light hover:border-error' : 'border-disabled'
                            }`}
                            onClick={!disabled ? () => deleteGrandparentExpense(expense.id) : undefined}
                        >
                            <DeleteIcon
                                style={{ fontSize: '18px' }}
                                className={`transition-all duration-150 ${
                                    !disabled ? 'text-error-light group-hover:text-error' : 'text-disabled'
                                }`}
                            />
                        </div>
                    ) : (
                        // If the grandparent expense has parent expenses selected, the delete button will not be displayed
                        <div
                            style={{
                                width: '30px',
                            }}
                        ></div>
                    )}
                </div>
            </div>

            {/* Map of the parent expenses */}
            <div>
                {expense.parentExpenses?.map((parentExpense) => (
                    <ParentExpenseRow
                        key={parentExpense.id}
                        grandparentExpenseParentExpenseList={selectedGrandparentExpenseWithInfoFromBack.subExpensesName}
                        parentExpense={parentExpense}
                        grandparentExpenseId={expense.id}
                    />
                ))}
            </div>
        </div>
    );
};

GrandparentExpenseRow.propTypes = {
    expense: PropTypes.object,
    listOfTheExpensesFromBack: PropTypes.array,
};

const ParentExpenseRow = ({ grandparentExpenseParentExpenseList, parentExpense, grandparentExpenseId }) => {
    const { t } = useTranslation();
    const { addChildExpenseToParentExpense, deleteParentExpense, changeParentExpenseValue, disabled } =
        React.useContext(BudgetaryContext);
    const { currencyObj } = useContext(GlobalContext);

    const childrenExpensesOfTheSelectedParentExpense = grandparentExpenseParentExpenseList.find(
        (expense) => expense.id === parentExpense.id
    )?.subExpensesName; // We get the children expenses array of the selected parent expense

    const filteredChildrenExpensesOfTheSelectedParentExpense = childrenExpensesOfTheSelectedParentExpense.filter(
        (expense) => expense.isDeleted === false && !parentExpense.childrenExpenses?.some((ex) => ex.id === expense.id) // We filter the children expenses array according to the children expenses already selected to overcome the duplicates
    );

    return (
        <div
            className={`budgetary-parent-selector relative border-t border-secondary-main`}
            style={{
                backgroundColor: parentExpense.isDeleted ? 'rgb(var(--base-error) / 40%)' : 'var(--layout-transparent)',
            }}
        >
            <div className={`flex w-full items-center gap-4 p-4 sm:gap-2`}>
                {/* Parent expense name */}
                <div className="ml-6 flex-grow sm:ml-2">
                    <p className="text-xl font-bold text-main-text" style={{ hyphens: 'auto' }}>
                        {parentExpense.name}
                    </p>
                </div>

                {/* Parent value  */}
                <div className="w-40 flex-none">
                    <LocaleTextField
                        disabled={
                            disabled ||
                            (parentExpense.childrenExpenses.length > 0 ? true : false) ||
                            parentExpense.isDeleted === true
                        }
                        placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                        value={parentExpense.value}
                        onChange={(e) =>
                            changeParentExpenseValue(
                                grandparentExpenseId,
                                parentExpense.id,
                                formatPositiveNumberWithDigits(e.target.value)
                            )
                        }
                        InputProps={{
                            endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                        }}
                    />
                </div>

                {/* Dropdown for the value of the parent */}
                <div
                    className={`flex-none 
                     ${parentExpense.isDeleted && 'w-12'}
                `}
                >
                    {!parentExpense.isDeleted && (
                        <Dropdown
                            disabled={disabled}
                            variant="green"
                            onlyIcon
                            icon={<AddIcon className="text-buttons-text" />}
                            options={filteredChildrenExpensesOfTheSelectedParentExpense.map(
                                (childrenExpense) => childrenExpense.name
                            )}
                            selectedOption={null}
                            setSelectedOption={(i) =>
                                addChildExpenseToParentExpense(
                                    grandparentExpenseId,
                                    parentExpense.id,
                                    filteredChildrenExpensesOfTheSelectedParentExpense[i]
                                )
                            }
                            placeholder={t('Add sub-expense')}
                        />
                    )}
                </div>

                {/* Delete button */}
                <div className="w-13 ml-8 sm:ml-0">
                    {parentExpense.childrenExpenses.length === 0 ? (
                        <div
                            className={`group flex items-center justify-center rounded-full border p-1 transition-all duration-150 ${
                                !disabled ? 'cursor-pointer border-error-light hover:border-error' : 'border-disabled'
                            }`}
                            onClick={
                                !disabled
                                    ? () => deleteParentExpense(grandparentExpenseId, parentExpense.id)
                                    : undefined
                            }
                        >
                            <DeleteIcon
                                style={{ fontSize: '20px' }}
                                className={`transition-all duration-150 ${
                                    !disabled ? 'text-error-light group-hover:text-error' : 'text-disabled'
                                }`}
                            />
                        </div>
                    ) : (
                        <div
                            style={{
                                width: '30px',
                            }}
                        ></div>
                    )}
                </div>
            </div>

            {/* Map of the children expenses */}
            <div>
                {parentExpense.childrenExpenses.map((childExpense) => (
                    <ChildExpenseRow
                        key={childExpense.id}
                        grandparentExpenseId={grandparentExpenseId}
                        parentExpenseId={parentExpense.id}
                        childExpense={childExpense}
                    />
                ))}
            </div>
        </div>
    );
};

ParentExpenseRow.propTypes = {
    parentExpense: PropTypes.object,
    grandparentExpenseParentExpenseList: PropTypes.array,
    grandparentExpenseId: PropTypes.string,
};

const ChildExpenseRow = ({ grandparentExpenseId, parentExpenseId, childExpense }) => {
    const { deleteChildExpense, changeChildExpenseValue, disabled } = React.useContext(BudgetaryContext);
    const { currencyObj } = useContext(GlobalContext);

    return (
        <div
            className="budgetary-child-selector relative border-t border-secondary-main"
            style={{
                backgroundColor: childExpense.isDeleted ? 'rgb(var(--base-error) / 40%)' : 'var(--layout-transparent)',
            }}
        >
            <div className="flex w-full items-center gap-4 p-4 sm:gap-2">
                {/* Child expense name */}
                <div className="ml-12 flex-grow sm:ml-4">
                    <p className="text-lg text-main-text" style={{ hyphens: 'auto' }}>
                        {childExpense.name}
                    </p>
                </div>

                {/* Child value textfield */}
                {
                    <div className="w-40 flex-none">
                        <LocaleTextField
                            placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                            value={childExpense.value}
                            onChange={(e) =>
                                changeChildExpenseValue(
                                    grandparentExpenseId,
                                    parentExpenseId,
                                    childExpense.id,
                                    formatPositiveNumberWithDigits(e.target.value)
                                )
                            }
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                            }}
                            disabled={disabled || childExpense.isDeleted}
                        />
                    </div>
                }
                <div className="flex-none" style={{ width: '35px' }}></div>

                {/* Delete button */}
                <div className="w-13 ml-8 sm:ml-0">
                    <div
                        className={`group flex items-center justify-center rounded-full border p-1 transition-all duration-150 ${
                            !disabled ? 'cursor-pointer border-error-light hover:border-error' : ''
                        }`}
                        onClick={
                            !disabled
                                ? () => deleteChildExpense(grandparentExpenseId, parentExpenseId, childExpense.id)
                                : undefined
                        }
                    >
                        <DeleteIcon
                            style={{ fontSize: '20px' }}
                            className={`transition-all duration-150 ${
                                !disabled ? 'text-error-light group-hover:text-error' : 'text-disabled'
                            }`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

ChildExpenseRow.propTypes = {
    grandparentExpenseId: PropTypes.string,
    parentExpenseId: PropTypes.string,
    childExpense: PropTypes.object,
};

const Budgetary = ({
    margin = 0,
    setMargin,
    initialExpenses = {},
    withinOffer = false,
    selectedExpensesFromFront,
    setSelectedExpensesFromFront,
    disabled = false,
}) => {
    const [canRender, setCanRender] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();

    const { currencyObj } = useContext(GlobalContext);

    // This is the boundary area for the new logic

    // const reshapeExpensesListForTheRequest = (expensesList) => {
    //     const newExpensesList = [...expensesList];

    //     newExpensesList.map((grandparentExpense)=>{

    //     })
    // };

    /**
     * To be more precise and to have a visual image of an expense structure, you have a pseudo expenses structure in the following example:
     *
     *  1. GRANDPARENT EXPENSE OR THE BIG EXPENSE
     *          2. PARENT EXPENSE
     *                  3. CHILD EXPENSE I
     *                  3.1 CHILD EXPENSE II
     *  With this names will we work on all expenses logic
     */

    /**
     * This is the state to hold the array of expenses on the backend
     */
    const [listOfTheExpensesFromBack, setListOfTheExpensesFromBack] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            const grandparentExpensesFromRequest = await getExpensesNames();

            let newListOfTheExpensesFromBackInUpdate = [...grandparentExpensesFromRequest]; // This variable will keep the array of the expenses/ we will initialize with the expenses from back
            // which will be modified by adding the all info, when this are in initialExpenses

            let newSelectedExpensesList = []; // This variable will keep the array of selected expenses, build by the initialExpenses

            if (Object.keys(initialExpenses).length) {
                // If initialExpenses is not empty, it means we must construct the needed array of selected expenses
                for (const [key, value] of Object.entries(initialExpenses)) {
                    if (key !== 'margin') {
                        // We don't want to map over margin

                        let newGrandparentExpenseInfo = await getExpensesNameById(key); // Get the grandparent expense info from back

                        const indexOfTheGrandparentExpenseWhichMustBeReplaced =
                            grandparentExpensesFromRequest.findIndex((expense) => expense.id === key); // We search for the index of the grandparent expense in the listOfTheExpensesFromBack array

                        newListOfTheExpensesFromBackInUpdate[indexOfTheGrandparentExpenseWhichMustBeReplaced] =
                            newGrandparentExpenseInfo; // Replace the old grandparent expense with the new grandparent expense from the backend

                        //  ------------------------------------------------------------------------------------------------

                        /**
                         *
                         * @returns The parentExpenses of the grandparent expense
                         */
                        const newParentExpensesShape = () => {
                            const newParentExpensesArray = []; // This variable will keep the array of parent expenses

                            for (const [parentKey, parentValue] of Object.entries(value.subExpensesName)) {
                                const parentShape = {
                                    id: parentKey,
                                    name: parentValue.name,
                                    value: parentValue.value ? parentValue.value : 0,
                                    isDeleted: parentValue.isDeleted,
                                };

                                let childrenArray = [];

                                if (Object.keys(parentValue.subExpensesName).length) {
                                    //We check if the parent has children expenses
                                    for (const [childKey, childValue] of Object.entries(parentValue.subExpensesName)) {
                                        childrenArray.push({
                                            id: childKey,
                                            name: childValue.name,
                                            value: childValue.value,
                                            isDeleted: childValue.isDeleted,
                                        });
                                    }
                                }

                                parentShape.childrenExpenses = childrenArray; // We add in the parent expense object the children expenses

                                newParentExpensesArray.push(parentShape);
                            }

                            return newParentExpensesArray;
                        };

                        const newSelectedExpenseShape = {
                            id: key,
                            name: value.name,
                            value: 0,
                            isDeleted: value.isDeleted,
                            parentExpenses: newParentExpensesShape(),
                        }; // The new expense, constructed by following the response from the backend

                        newSelectedExpensesList.push(newSelectedExpenseShape);
                    }
                }
            }

            /**
             * At the mounting of the component, we will set the listOfTheExpensesFromBack state with the grandparent expenses only
             * this array with objects will contain only grandparent expenses information
             * The parent expenses and children expenses information will be added to the listOfTheExpensesFromBack state when the user will select a grandparent expense - view - addGrandparentExpense - function
             */

            if (Object.keys(initialExpenses).length) {
                // Only if the initialExpenses is not empty, we will set the new expenses from back, selected expenses and calculate the values
                setListOfTheExpensesFromBack(newListOfTheExpensesFromBackInUpdate);
                setSelectedExpensesFromFront(newSelectedExpensesList);
                calculateExpensesValues(newSelectedExpensesList);
            } else {
                // If the initialExpenses is empty, we set the listOfTheExpensesFromBack state with the grandparent expenses only
                setListOfTheExpensesFromBack(grandparentExpensesFromRequest);
            }

            setCanRender(true);
        })();
    }, []);

    //-----------------------> Utils functions <-----------------------

    /**
     * This is a helper function to return a grandparent expense object from the listOfTheExpensesFromBack state by id
     */
    function getAGrandparentExpenseFromListOfTheExpensesFromBack(idOfTheGrandparentExpense) {
        return listOfTheExpensesFromBack.find((expense) => expense.id === idOfTheGrandparentExpense);
    }

    //---------------------------------------------------------------

    //-----------------------> Functions for the GRANDPARENT EXPENSES <--------------------------------

    /**
     * Function for adding a grandparent expense
     * @param {*} idOfTheGrandparentExpense - id of the grandparent expense
     */
    const addGrandparentExpense = useCallback(
        async (idOfTheGrandparentExpense) => {
            const newSelectedExpenses = [...selectedExpensesFromFront];

            // Search in the listOfTheExpensesFromBack array for the expense object with the id equal with idOfTheGrandparentExpense
            const expenseElement = getAGrandparentExpenseFromListOfTheExpensesFromBack(idOfTheGrandparentExpense);

            // Push in the selectedExpensesFromFront a new object with the grandparent expense information
            newSelectedExpenses.push({
                id: expenseElement.id,
                name: expenseElement.name,
                value: 0,
                parentExpenses: [],
                isDeleted: expenseElement.isDeleted,
            });

            setSelectedExpensesFromFront(newSelectedExpenses);

            // We check in the listOfTheExpensesFromBack array, the added grandparent expense should not contain parent expenses
            if (!expenseElement.subExpensesName) {
                let newGrandparentExpenseInfo = await getExpensesNameById(expenseElement.id); // Get from the backend the grandparent expense complete information (parent expenses and children expenses)
                // We will replace the old grandparent with the new one

                const newListOfTheExpensesFromBack = [...listOfTheExpensesFromBack];

                const indexOfTheGrandparentExpenseWhichMustBeReplaced = listOfTheExpensesFromBack.findIndex(
                    (expense) => expense.id === expenseElement.id
                ); // We search for the index of the added grandparent expense in the listOfTheExpensesFromBack array

                newListOfTheExpensesFromBack[indexOfTheGrandparentExpenseWhichMustBeReplaced] =
                    newGrandparentExpenseInfo; // Replace the old grandparent expense with the new grandparent expense from the backend

                setListOfTheExpensesFromBack(newListOfTheExpensesFromBack);
            }
        },
        [listOfTheExpensesFromBack, selectedExpensesFromFront]
    );

    /**
     * Function for deleting the selected grandparent expense
     * @param {*} idOfTheGrandparentExpense - The grandparent expense id
     */
    const deleteGrandparentExpense = useCallback(
        (idOfTheGrandparentExpense) => {
            setSelectedExpensesFromFront(
                selectedExpensesFromFront.filter((expense) => expense.id !== idOfTheGrandparentExpense)
            );
        },
        [listOfTheExpensesFromBack, selectedExpensesFromFront]
    );

    //-----------------------> Functions for the PARENT EXPENSES <--------------------------------

    /**
     *  Function for adding the selected parent expense
     * @param {*} idOfTheGrandparentExpense - id of the grandparent expense
     * @param {*} parentExpenseInfo - object containing the information of the parent expense, information from the backend
     */
    const addParentExpenseToAGrandParentExpense = useCallback(
        (idOfTheGrandparentExpense, parentExpenseInfo) => {
            const newSelectedExpenses = [...selectedExpensesFromFront];

            const indexOfTheGrandparentExpenseFromSelectedExpenses = selectedExpensesFromFront.findIndex(
                (expense) => expense.id === idOfTheGrandparentExpense
            ); // We search for the index of the added grandparent expense in the selectedExpensesFromFront state, to know in which grandparent expense to add parent expenses

            newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses].parentExpenses.push({
                id: parentExpenseInfo.id,
                name: parentExpenseInfo.name,
                value: 0,
                childrenExpenses: [],
                isDeleted: parentExpenseInfo.isDeleted,
            });

            setSelectedExpensesFromFront(newSelectedExpenses);
        },
        [listOfTheExpensesFromBack, selectedExpensesFromFront]
    );

    /**
     * Function for deleting the selected parent expense
     * @param {*} idOfTheGrandparentExpense - id of the grandparent expense
     * @param {*} idOfTheParentExpense - id of the parent expense
     */
    const deleteParentExpense = useCallback(
        (idOfTheGrandparentExpense, idOfTheParentExpense) => {
            const newSelectedExpenses = [...selectedExpensesFromFront];

            const indexOfTheGrandparentExpenseFromSelectedExpenses = selectedExpensesFromFront.findIndex(
                (expense) => expense.id === idOfTheGrandparentExpense
            ); // We search for the index of the added grandparent expense in the selectedExpensesFromFront state, to know in which grandparent expense to add parent expenses

            const parentExpensesFiltered = newSelectedExpenses[
                indexOfTheGrandparentExpenseFromSelectedExpenses
            ].parentExpenses.filter((parentExpense) => parentExpense.id !== idOfTheParentExpense); // We filter the parent expenses to remove the parent expense who is wanted to be deleted

            newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses] = {
                ...newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses],
                parentExpenses: parentExpensesFiltered,
            }; // Replace the old parent expenses with the new filtered parent expenses

            setSelectedExpensesFromFront(newSelectedExpenses);

            calculateExpensesValues(newSelectedExpenses); // After deleting the parent, we calculate the grandparent expense value
        },
        [listOfTheExpensesFromBack, selectedExpensesFromFront]
    );

    /**
     * Function to change the value of a parent expense
     * @param {*} idOfTheGrandparentExpense - id of the grandparent expense
     * @param {*} idOfTheParentExpense - id of the parent expense
     * @param {*} valueOfTheChildExpense - value of the parent expense
     */
    const changeParentExpenseValue = useCallback(
        (idOfTheGrandparentExpense, idOfTheParentExpense, valueOfTheParentExpense) => {
            const newSelectedExpenses = [...selectedExpensesFromFront];

            const indexOfTheGrandparentExpenseFromSelectedExpenses = selectedExpensesFromFront.findIndex(
                (expense) => expense.id === idOfTheGrandparentExpense
            ); // We search for the index of the added grandparent expense in the selectedExpensesFromFront state, to know in which grandparent expense to add parent expenses

            const indexOdTheParentExpenseToEdit = newSelectedExpenses[
                indexOfTheGrandparentExpenseFromSelectedExpenses
            ].parentExpenses.findIndex((parentExpense) => parentExpense.id === idOfTheParentExpense); // We filter the parent expenses to remove the parent expense who is wanted to be deleted

            newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses].parentExpenses[
                indexOdTheParentExpenseToEdit
            ].value = valueOfTheParentExpense; // We overwrite the value of the parent expense with the new value

            setSelectedExpensesFromFront(newSelectedExpenses);
            calculateExpensesValues(newSelectedExpenses); // After setting the value of the parent, we calculate the grandparent expense value
        },
        [listOfTheExpensesFromBack, selectedExpensesFromFront]
    );

    //-----------------------> Functions for the CHILD EXPENSES <--------------------------------

    /**
     * Function for adding the selected child expense to a parent expense
     * @param {*} idOfTheGrandparentExpense - id of the grandparent expense
     * @param {*} idOfTheParentExpense - id of the parent expense
     * @param {*} childExpenseInfo - child information object
     *
     */
    const addChildExpenseToParentExpense = useCallback(
        (idOfTheGrandparentExpense, idOfTheParentExpense, childExpenseInfo) => {
            const newSelectedExpenses = [...selectedExpensesFromFront];

            const indexOfTheGrandparentExpenseFromSelectedExpenses = selectedExpensesFromFront.findIndex(
                (expense) => expense.id === idOfTheGrandparentExpense
            ); // We search for the index of the added grandparent expense in the selectedExpensesFromFront state, to know in which grandparent expense to add parent expenses

            const indexOfTheParentExpense = newSelectedExpenses[
                indexOfTheGrandparentExpenseFromSelectedExpenses
            ].parentExpenses.findIndex((expense) => expense.id === idOfTheParentExpense); // We search for the index of the parent expense of the selected child expense

            newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses].parentExpenses[
                indexOfTheParentExpense
            ].childrenExpenses.push({
                id: childExpenseInfo.id,
                name: childExpenseInfo.name,
                value: 0,
                isDeleted: childExpenseInfo.isDeleted,
            });

            setSelectedExpensesFromFront(newSelectedExpenses);
        },
        [listOfTheExpensesFromBack, selectedExpensesFromFront]
    );

    /**
     * Function to delete the selected child expense
     * @param {*} idOfTheGrandparentExpense - id of the grandparent expense
     * @param {*} idOfTheParentExpense - id of the parent expense
     * @param {*} idOfTheChildExpense - id of the child expense
     *
     */
    const deleteChildExpense = useCallback(
        (idOfTheGrandparentExpense, idOfTheParentExpense, idOfTheChildExpense) => {
            const newSelectedExpenses = [...selectedExpensesFromFront];

            const indexOfTheGrandparentExpenseFromSelectedExpenses = selectedExpensesFromFront.findIndex(
                (expense) => expense.id === idOfTheGrandparentExpense
            ); // We search for the index of the added grandparent expense in the selectedExpensesFromFront state, to know in which grandparent expense to add parent expenses

            const indexOfTheParentExpense = newSelectedExpenses[
                indexOfTheGrandparentExpenseFromSelectedExpenses
            ].parentExpenses.findIndex((expense) => expense.id === idOfTheParentExpense); // We search for the index of the parent expense of the selected child expenses

            const childrenExpensesFiltered = newSelectedExpenses[
                indexOfTheGrandparentExpenseFromSelectedExpenses
            ].parentExpenses[indexOfTheParentExpense].childrenExpenses.filter(
                (childExpense) => childExpense.id !== idOfTheChildExpense
            ); // We filter the child expenses

            newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses] = {
                ...newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses],
                parentExpenses: [
                    ...newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses].parentExpenses.filter(
                        (thisParentExpense) => thisParentExpense.id !== idOfTheParentExpense
                    ), // we filter the parent expense whose child is wanted to be deleted
                    (newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses].parentExpenses[
                        indexOfTheParentExpense
                    ] = {
                        ...newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses].parentExpenses[
                            indexOfTheParentExpense
                        ],
                        childrenExpenses: childrenExpensesFiltered,
                    }),
                ],
            }; // We overwrite the old children expenses array with the new filtered children expenses

            setSelectedExpensesFromFront(newSelectedExpenses);
            calculateExpensesValues(newSelectedExpenses); // After deleting the child, we calculate the parent expense value
        },
        [listOfTheExpensesFromBack, selectedExpensesFromFront]
    );

    /**
     * Function to change the value of a child expense
     * @param {*} idOfTheGrandparentExpense - id of the grandparent expense
     * @param {*} idOfTheParentExpense - id of the parent expense
     * @param {*} idOfTheChildExpense - id of the child expense
     * @param {*} valueOfTheChildExpense - value of the child expense
     *
     */
    const changeChildExpenseValue = useCallback(
        (idOfTheGrandparentExpense, idOfTheParentExpense, idOfTheChildExpense, valueOfTheChildExpense) => {
            const newSelectedExpenses = [...selectedExpensesFromFront];

            const indexOfTheGrandparentExpenseFromSelectedExpenses = selectedExpensesFromFront.findIndex(
                (expense) => expense.id === idOfTheGrandparentExpense
            ); // We search for the index of the added grandparent expense in the selectedExpensesFromFront state, to know in which grandparent expense to add parent expenses

            const indexOfTheParentExpense = newSelectedExpenses[
                indexOfTheGrandparentExpenseFromSelectedExpenses
            ].parentExpenses.findIndex((expense) => expense.id === idOfTheParentExpense); // We search for the index of the parent expense of the selected child expenses

            const indexOfTheChildExpense = newSelectedExpenses[
                indexOfTheGrandparentExpenseFromSelectedExpenses
            ].parentExpenses[indexOfTheParentExpense].childrenExpenses.findIndex(
                (childExpense) => childExpense.id === idOfTheChildExpense
            ); // We search for the index of the child expense

            newSelectedExpenses[indexOfTheGrandparentExpenseFromSelectedExpenses].parentExpenses[
                indexOfTheParentExpense
            ].childrenExpenses[indexOfTheChildExpense].value = valueOfTheChildExpense; // We overwrite the old child expense value with the new value

            setSelectedExpensesFromFront(newSelectedExpenses);

            calculateExpensesValues(newSelectedExpenses); // After setting the value of the child expense, we calculate the value of his parent expense
        },
        [listOfTheExpensesFromBack, selectedExpensesFromFront]
    );

    //-----------------------> Mathematic logic <--------------------------------

    /**
     * Function for calculating the value of each expense from selectedExpensesFromFront state
     */
    const calculateExpensesValues = React.useCallback(
        (selectedExpense) => {
            const selectedExpensesFromFrontWithValues = selectedExpense.map((grandparentExpense) => {
                const parentExpenses = grandparentExpense.parentExpenses.map((parentExpense) => {
                    if (parentExpense.childrenExpenses?.length > 0) {
                        // If the childrenExpenses array is not empty, we calculate set the parent value with the sum of all the children values
                        const parentExpenseValue = parentExpense.childrenExpenses.reduce(
                            (accumulatedValue, childExpense) => accumulatedValue + childExpense.value,
                            0
                        );

                        return {
                            ...parentExpense,
                            value: parentExpenseValue,
                        };
                    } else {
                        // If the childrenExpenses array is empty, we only return the parent Expense object, because his value is set by the user
                        return { ...parentExpense };
                    }
                });

                // The total value of grandparent expense
                const grandparentExpenseValue = parentExpenses.reduce(
                    (accumulatedValue, parentExpense) => accumulatedValue + parentExpense.value,
                    0
                );

                // We return the grandparent expense with the new value, representing the total value of the parent expenses
                // And the parentExpenses array contain value for each parentExpense
                return {
                    ...grandparentExpense,
                    parentExpenses,
                    value: grandparentExpenseValue,
                };
            });

            setSelectedExpensesFromFront(selectedExpensesFromFrontWithValues);

            return selectedExpensesFromFrontWithValues.reduce(
                (accumulatedValue, grandparentExpense) => accumulatedValue + grandparentExpense,
                0
            );
        },
        [selectedExpensesFromFront]
    );

    return (
        <>
            {canRender && listOfTheExpensesFromBack.length > 0 ? (
                <BudgetaryContext.Provider
                    value={{
                        selectedExpensesFromFront,
                        deleteGrandparentExpense,
                        addParentExpenseToAGrandParentExpense,
                        deleteParentExpense,
                        changeParentExpenseValue,
                        addChildExpenseToParentExpense,
                        deleteChildExpense,
                        changeChildExpenseValue,
                        disabled,
                    }}
                >
                    <div>
                        <div className="flex items-start gap-4 lg:flex-col">
                            <div className="w-full rounded-md border border-secondary-light p-6">
                                {/* Expenses cascade */}
                                {selectedExpensesFromFront.map((expense) => (
                                    <GrandparentExpenseRow
                                        key={expense.id}
                                        expense={expense}
                                        listOfTheExpensesFromBack={listOfTheExpensesFromBack}
                                    />
                                ))}

                                {/* The dropdown for adding the new GRANDPARENT EXPENSE (LEVEL 1 - TITLE) */}
                                {!withinOffer &&
                                    (() => {
                                        const options = listOfTheExpensesFromBack.filter(
                                            // We will filter the grandparent expense who are already selected, to avoid duplicates
                                            (grandparentExpense) =>
                                                !selectedExpensesFromFront.some(
                                                    (expense) => expense.id === grandparentExpense.id
                                                ) // We use SOME array method to find if in the selectedExpensesFromFront exists an expense with the same id as the grandparentExpense displayed as an option
                                        );

                                        return (
                                            // Dropdown for adding the new grandparent expense
                                            <div className="relative z-10 mx-2 mb-6 mt-2 inline-block">
                                                <Dropdown
                                                    disabled={disabled}
                                                    variant="green"
                                                    icon={<AddIcon className="text-buttons-text" />}
                                                    placeholder={t('Add expense')}
                                                    options={options.map((name) => name.name)}
                                                    selectedOption={null}
                                                    setSelectedOption={(i) => addGrandparentExpense(options[i].id)}
                                                />
                                            </div>
                                        );
                                    })()}

                                {/* Margin container */}
                                <MarginRow margin={margin} setMargin={setMargin} disabled={disabled} />
                            </div>

                            {/* Summary */}
                            <div className="w-3/12 flex-shrink-0 rounded-md border border-secondary-light p-4 lg:w-6/12 md:w-full">
                                <div className="mb-8">
                                    <p className="pb-1 text-3xl font-bold">{t('Summary')}</p>
                                    <div
                                        className="bg-secondary-light"
                                        style={{
                                            width: '5.5rem',
                                            height: '2.9px',
                                        }}
                                    ></div>
                                </div>

                                <div className="space-y-4">
                                    {/* Map for the grandparent expenses with values */}
                                    {selectedExpensesFromFront.map((grandparentExpense) => (
                                        <>
                                            <div
                                                key={grandparentExpense.id}
                                                className="flex items-center justify-between border-b border-secondary-main pb-2"
                                            >
                                                <p className="text-lg">{grandparentExpense.name}</p>
                                                <p className="text-lg">
                                                    <span className="text-primary-light">
                                                        {toLocaleNumber(grandparentExpense.value, 2)}
                                                    </span>{' '}
                                                    {currencyObj.currency}
                                                </p>
                                            </div>
                                        </>
                                    ))}

                                    {/* Margin value */}
                                    <div className="flex items-center justify-between">
                                        <p className="text-lg font-semibold">{t('Margin')}</p>
                                        <p className="text-lg font-semibold">
                                            <span className="text-primary-light">{toLocaleNumber(margin, 2)}</span>{' '}
                                            {currencyObj.currency}
                                        </p>
                                    </div>

                                    {/* Total value of the budgetary */}
                                    <div className="flex items-center justify-between border-t-2 border-secondary-light pt-4">
                                        <p className="text-xl font-semibold">Total</p>
                                        <p className="text-xl font-semibold">
                                            <span className="pr-2 text-primary-light">
                                                {toLocaleNumber(
                                                    selectedExpensesFromFront.reduce(
                                                        (accumulatedValue, grandparentExpense) =>
                                                            accumulatedValue + grandparentExpense.value,
                                                        0
                                                    ) + margin,
                                                    2
                                                )}
                                            </span>

                                            {currencyObj.currency}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BudgetaryContext.Provider>
            ) : canRender && listOfTheExpensesFromBack.length <= 0 ? (
                <div className=" flex max-w-3xl flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                    <p className="mb-5">{t('There are no expenses yet, you have to add one first')}</p>
                    <Button
                        disabled={disabled}
                        color="primary"
                        onClick={() => history.push(accountancy.base + accountancy.expenseNomenclature.base)}
                    >
                        {t('Add expense')}
                    </Button>
                </div>
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </>
    );
};

Budgetary.propTypes = {
    margin: PropTypes.number,
    setMargin: PropTypes.func,
    initialExpenses: PropTypes.object,
    withinOffer: PropTypes.bool,
    selectedExpensesFromFront: PropTypes.array,
    setSelectedExpensesFromFront: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Budgetary;
