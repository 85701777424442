import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import { Button, TableCell } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { PropTypes } from 'prop-types';
import { ExpandableRow, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import { calculateTotal, calculateVATValue, toLocaleNumber } from 'utils';

const optionClass = 'text-md text-dark-text mb-4';
const optionClassValues = 'text-md text-dark-text mb-4 flex flex-col';
const optionSpanClass = 'text-main-text font-bold';

const ProjectArticleRow = ({ article }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { currencyObj } = useContext(GlobalContext);
    const { checkPerm } = useContext(UserContext);

    const canView = checkPerm([
        {
            permissionId: '18',
            permissionType: 'VIEW',
        },
    ]);

    const totalPrice =
        Number(article.price.pricePerUnit) +
        article.options.map((a) => Number(a.price.pricePerUnit))?.reduce((a, b) => a + b, 0);
    const totalVatValue =
        Number(calculateVATValue(article.price.pricePerUnit, article.price.procentualVAT)) +
        article.options
            .map((a) => Number(calculateVATValue(a.price.pricePerUnit, a.price.procentualVAT)))
            ?.reduce((a, b) => a + b, 0);

    const handleViewArticle = () => {
        history.push(quotes.base + quotes.nomenclature.base + quotes.nomenclature.update + '/' + article.id);
    };

    return (
        <ExpandableRow
            colSpan={6}
            row={
                <>
                    <TableCell>{article.name}</TableCell>
                    <TableCell>
                        {article.categories.map(
                            (c, index) => c.categorie.name + (index < article.categories.length - 1 ? ', ' : '')
                        )}
                    </TableCell>
                    <TableCell>
                        {toLocaleNumber(totalPrice, 2)} {currencyObj.currency}
                    </TableCell>
                    <TableCell>{toLocaleNumber(article.price.procentualVAT, 2)} %</TableCell>
                    <TableCell>
                        {toLocaleNumber(totalVatValue, 2)} {currencyObj.currency}
                    </TableCell>
                    <TableCell>
                        {toLocaleNumber(totalPrice + totalVatValue, 2)} {currencyObj.currency}
                    </TableCell>
                    <TableCell>{article.options.length}</TableCell>
                </>
            }
            collapse={
                <>
                    {canView && (
                        <div className="flex gap-3 p-3">
                            <Button
                                color="secondary"
                                startIcon={<VisibilityOutlinedIcon style={{ fontSize: 25 }} />}
                                onClick={handleViewArticle}
                            >
                                {t('View article')}
                            </Button>
                        </div>
                    )}
                    <div className="-ml-3 grid w-4/5 grid-cols-2 gap-16 2xl:w-3/4 xl:w-full lg:-ml-0  lg:grid-cols-1 lg:p-4">
                        <div>
                            <div className="mt-10 bg-layout-transparent p-6">
                                <h4 className="mb-5 break-words text-2xl">{article.name}</h4>
                                <p className={optionClass}>
                                    {t('Article type')}:{' '}
                                    <span className={optionSpanClass}>
                                        {article.type === 'SERVICES'
                                            ? t('Service')
                                            : article.type === 'FIZIC'
                                            ? t('Physical product')
                                            : article.type === 'DIGITAL'
                                            ? t('Digital product')
                                            : ''}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Article category')}:{' '}
                                    <span className={optionSpanClass}>
                                        {article.categories.map(
                                            (c, index) =>
                                                c.categorie.name + (index < article.categories.length - 1 ? ', ' : '')
                                        )}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Article code(SKU)')}: <span className={optionSpanClass}>{article.sku}</span>
                                </p>
                                <p className={optionClass}>
                                    {t('Description')}: <span className={optionSpanClass}>{article.description}</span>
                                </p>

                                <div className="inline-flex gap-6">
                                    <p className={optionClassValues}>
                                        {t('Price without VAT')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(article.price.pricePerUnit, 2)} {currencyObj.currency}
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('VAT rate')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(article.price.procentualVAT, 2)} %
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('VAT value')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(
                                                calculateVATValue(
                                                    article.price.pricePerUnit,
                                                    article.price.procentualVAT
                                                ),
                                                2
                                            )}{' '}
                                            {currencyObj.currency}
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('Final price with VAT')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(
                                                calculateTotal(
                                                    calculateVATValue(
                                                        article.price.pricePerUnit,
                                                        article.price.procentualVAT
                                                    ),
                                                    article.price.pricePerUnit
                                                ),
                                                2
                                            )}{' '}
                                            {currencyObj.currency}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {article.options.map((o) => (
                                <div
                                    className="border-t-2 border-layout-transparent-dark p-6 hover:bg-layout-transparent"
                                    key={o.id}
                                >
                                    <h4 className="mb-5 text-2xl">{o.name}</h4>
                                    <p className={optionClass}>
                                        {t('Variable code(SKU)')}: <span className={optionSpanClass}>{o.sku}</span>
                                    </p>
                                    <p className={optionClass}>
                                        {t('Description')}: <span className={optionSpanClass}>{o.description}</span>
                                    </p>
                                    {o.price.type === 'BASE_PROCENT' ? (
                                        <p className={optionClass}>
                                            {t('Percentage of basic item')}:{' '}
                                            <span className={optionSpanClass}>{o.price.priceProcent} %</span>
                                        </p>
                                    ) : o.price.type === 'BASE_PROCENT_AND_VARIABLE' ? (
                                        <p className={optionClass}>
                                            {t('Percentage of the entire article(article + all variables)')}:{' '}
                                            <span className={optionSpanClass}>{o.price.priceProcent} %</span>
                                        </p>
                                    ) : null}

                                    <div className="inline-flex gap-6">
                                        <p className={optionClassValues}>
                                            {t('Price without VAT')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(o.price.pricePerUnit, 2)} {currencyObj.currency}
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('VAT rate')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(o.price.procentualVAT, 2)} %
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('VAT value')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(
                                                    calculateVATValue(o.price.pricePerUnit, o.price.procentualVAT),
                                                    2
                                                )}{' '}
                                                {currencyObj.currency}
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('Final price with VAT')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(
                                                    calculateTotal(
                                                        calculateVATValue(o.price.pricePerUnit, o.price.procentualVAT),
                                                        o.price.pricePerUnit
                                                    ),
                                                    2
                                                )}{' '}
                                                {currencyObj.currency}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-9/12 sm:w-full">
                            <div className="mt-10 bg-layout-transparent p-7">
                                <h4 className="mb-4 text-2xl">{t('Price with all variables')}</h4>
                                <div className="grid grid-cols-3 gap-2 xl:grid-cols-1">
                                    <div>
                                        <LabelWrapper label={t('Price without VAT')} noPadding>
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(totalPrice, 2)} {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>

                                    <div>
                                        <LabelWrapper label={t('VAT value')} noPadding>
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(totalVatValue, 2)} {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>

                                    <div>
                                        <LabelWrapper label={t('Final price with VAT')} noPadding>
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(totalPrice + totalVatValue, 2)} {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        />
    );
};

ProjectArticleRow.propTypes = {
    article: PropTypes.object,
};

ProjectArticleRow.defaultProps = {
    article: {},
};

export default ProjectArticleRow;
