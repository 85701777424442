import { toCanvas } from 'html-to-image';
import { defaultTheme, rgbToHex } from 'utils';

import { handleElementExport, handleMultiPageExport } from './exportUtils';

/**
 * The `exportImagesAsPDF` function is a JavaScript function that exports an HTML element as a PDF document,
 * with options for additional elements, callbacks, download settings, image splitting, aliases, custom
 * file names, and crop margins.
 * @param {Element} element - The HTML element that you want to export as a PDF. This can be any valid HTML
 * element such as a div, table, or canvas.
 * @param {Object} tenant - The object used to extract all the data needed about the company.
 * @param {Element} additionalElement - The additionalElement parameter is an optional parameter that
 * represents an additional element to be included in the exported PDF. It can be any HTML element that
 * you want to include in the PDF along with the main element.
 * @param {Function} firstCallback - The `firstCallback` parameter is a callback function that will be
 * executed after the PDF export process starts. It is an optional parameter, so you can pass `null`
 * if you don't need to execute any callback function after the export.
 * @param {Function} secondCallback - The `secondCallback` parameter is a callback function that will be
 * executed after the PDF export is completed or an error occurs. It is an optional parameter, so you
 * can pass a function to it if you want to perform any additional actions after the export is done.
 * @param {Boolean} download - A boolean value indicating whether the PDF should be downloaded
 * automatically after generation. If set to true, the PDF will be downloaded; if set to false, the PDF
 * will not be downloaded.
 * @param {String} splitImage - The `splitImage` parameter determines how the exported PDF will
 * handle multiple pages. It can have two possible values: multi-page for a PDF with multiple pages and single-page for a one page PDF
 * @param {String} alias  - The `alias` parameter is used to provide title for the first page of the exported
 * PDF file if a value is provided
 * @param {String} customFileName - The `customFileName` parameter is an optional parameter that allows
 * you to specify a custom name for the downloaded PDF file. If provided, the downloaded file will be
 * named using the specified custom name followed by the ".pdf" extension. If not provided, the
 * downloaded file will be named using the current date
 * @returns The function `exportImagesAsPDF` returns a Promise that resolves to the PDF file data.
 */
const exportImagesAsPDF = async (
    pageOrientation = 'landscape',
    elements,
    tenant,
    additionalElements = [],
    firstCallback = null,
    secondCallback = null,
    download = true,
    splitImage = 'multi-page',
    alias = null,
    customFileName = null,
    disabledCropHeightsIntervals = Array.from({ length: elements.length }, () => [])
) => {
    try {
        // ? We create a delay in order to wait for some UI elements to display (loaders or others)
        await new Promise((resolve) => setTimeout(resolve, 250));

        let dataImages = [];
        let additionalExtractedData = null;
        // ? We extract the images into a variables and we skip the auto scale in order to maintain the aspect ratio of the images
        const extractedData = await Promise.all(elements.map((element) => toCanvas(element, { skipAutoScale: true })));
        if (additionalElements.length)
            additionalExtractedData = await Promise.all(
                additionalElements.map((element) => toCanvas(element, { skipAutoScale: true }))
            );

        // ? In the case of a multi page export we split the big image in smaller images in order to fit them in different PDF pages
        if (splitImage === 'multi-page') {
            for (let i = 0; i < elements.length; i++) {
                const extractedImages = handleMultiPageExport(
                    extractedData[i],
                    disabledCropHeightsIntervals,
                    pageOrientation
                );
                dataImages = [...dataImages, ...extractedImages];
                // ? If we have an additional element we added to the images array
                if (additionalElements[i]) {
                    const extractedImage = handleElementExport(additionalExtractedData[i], pageOrientation);
                    dataImages.push(extractedImage);
                }
            }
        }

        // ? In the case of a single page export we get the image using the same function as for the the additional element
        if (splitImage === 'single-page') {
            const extractedImage = handleElementExport(extractedData[0], pageOrientation);
            dataImages = [extractedImage];
        }

        // ? We must delete the pageBreak property on the last item of the array in order to avoid creating an empty page in the PDF
        delete dataImages.at(-1).pageBreak;

        // ? Header object with the company details
        const headerDetails = {
            name: tenant.companyName,
            registerNo: tenant.companyRegisterNo,
            cui: tenant.cui,
            address: tenant.addressString,
            phone: tenant.phoneNo,
            email: tenant.email,
        };

        const colorsDetails = {};

        // ? If the company has at least one theme we will use that theme in order to get the PDF colors
        if (tenant.ThemeConfigurator.length > 0) {
            const tenantTheme = JSON.parse(
                tenant.ThemeConfigurator.find((theme) => theme.id === tenant.activeThemeId).theme
            );
            colorsDetails.mainText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-TEXT'].color.split(' '));
            colorsDetails.linkText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-LINK-TEXT'].color.split(' '));
            colorsDetails.footerText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-LAYOUT-TEXT'].color.split(' '));
            colorsDetails.footerBackground = rgbToHex(
                ...tenantTheme['PDF-COLORS']['PDF-LAYOUT-BACKGROUND'].color.split(' ')
            );
        }
        // ? If the company doesn't have a theme yet we use the general default M2M theme
        else {
            const parsedDefaultTheme = JSON.parse(defaultTheme.theme);
            colorsDetails.mainText = rgbToHex(...parsedDefaultTheme['PDF-COLORS']['PDF-TEXT'].color.split(' '));
            colorsDetails.linkText = rgbToHex(...parsedDefaultTheme['PDF-COLORS']['PDF-LINK-TEXT'].color.split(' '));
            colorsDetails.footerText = rgbToHex(
                ...parsedDefaultTheme['PDF-COLORS']['PDF-LAYOUT-TEXT'].color.split(' ')
            );
            colorsDetails.footerBackground = rgbToHex(
                ...parsedDefaultTheme['PDF-COLORS']['PDF-LAYOUT-BACKGROUND'].color.split(' ')
            );
        }

        // ? At the end we return a promise that we will be used to create the web worker where the PDF file will be created
        return new Promise((resolve, reject) => {
            // ? We create the web worker and we assign it a name and a type of 'module' witch is mandatory
            const worker = new Worker('../workers/exportImagesAsPDF.worker', {
                name: 'exportImagesAsPDF',
                type: 'module',
            });
            // ? We send a message to the worker with all the data received above
            worker.postMessage({
                dataImages,
                alias,
                headerDetails,
                colorsDetails,
                pageOrientation,
                companyLogo: tenant.logoUrl,
            });
            // ? If the action is completed successfully we receive the message witch contains the PDF
            worker.onmessage = (e) => {
                if (download) {
                    const link = document.createElement('a');
                    link.href = e.data.pdf;
                    link.setAttribute(
                        'download',
                        customFileName ? `${customFileName}.pdf` : `${new Date().toISOString().slice(0, 10)}.pdf`
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }
                // ? At the end we call the callbacks, close the worker and resolve the promise
                firstCallback?.();
                secondCallback?.();
                worker.terminate();
                resolve(e.data.pdf);
            };
            // ? If the actions result in a error we call the callbacks, close the worker and reject the promise
            worker.onerror = (e) => {
                console.error(e);
                firstCallback?.();
                secondCallback?.();
                worker.terminate();
                reject(e);
            };
        });
    } catch (error) {
        console.error(error);
    }
};

export default exportImagesAsPDF;
