import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ListIcon from '@material-ui/icons/List';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import MilestonesForm from 'components/milestones/milestones-form';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { ExpandableRow, TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { formatDate, linkStyle } from 'utils';
import API from 'utils/axios';

const MilestonesRow = (props) => {
    const { milestone, setInitializeData } = props;
    const history = useHistory();
    const { t } = useTranslation();

    const [user, setUser] = useState(null);

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '15',
            permissionType: 'ALL',
        },
    ]);

    const canAllUsers = checkPerm([
        {
            permissionId: '11',
            permissionType: 'ALL',
        },
    ]);

    /**
     * Function take the user who is the author of the milestone
     */
    const getUsers = async () => {
        try {
            const response = await API.get(`/user/${milestone.authorId}`);
            setUser(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getUsers();
    }, [milestone]);

    const getPipelineType = useCallback(() => {
        switch (milestone.pipelineType) {
            case 'NOT_ASSUMED':
                return t('Do not take in the pipeline');
            case 'LEADS_TO_POTENTIAL':
                return t('Leads to potential');
            case 'OFFER':
                return t('Offer');
            default:
                return t('Customer activity (contracts)');
        }
    }, [milestone]);

    return (
        <ExpandableRow
            colSpan={6}
            padding={false}
            row={
                <>
                    <TableCell>{milestone.name}</TableCell>
                    <TableCell>{getPipelineType()}</TableCell>
                    <TableCell>{milestone.pipelineType === 'NOT_ASSUMED' ? t('No') : t('Yes')}</TableCell>
                    <TableCell>{milestone.isActive ? t('active') : t('inactive')}</TableCell>
                    <TableCell>{formatDate(milestone.createAt)}</TableCell>
                    <TableCell>
                        <span
                            className={`font-semibold ${canAllUsers && linkStyle}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!canAllUsers || setInitializeData) return;
                                history.push(
                                    configurator.base +
                                        configurator.users.base +
                                        configurator.users.update +
                                        '/' +
                                        milestone.authorId +
                                        '?tab=1'
                                );
                            }}
                        >
                            {user?.user?.profile?.name}
                        </span>
                    </TableCell>
                    {/* <TableCell>{milestone.description}</TableCell> */}
                </>
            }
            collapse={
                <div className="relative overflow-visible">
                    <div className="mb-2 p-5">
                        {canAll && !setInitializeData && (
                            <Button
                                startIcon={<ListIcon />}
                                color="secondary"
                                onClick={() => {
                                    history.push(
                                        configurator.base +
                                            configurator.milestones.base +
                                            configurator.milestones.update +
                                            '/' +
                                            milestone.id
                                    );
                                }}
                            >
                                {t('View & Edit Milestone')}
                            </Button>
                        )}
                    </div>

                    <div className="mb-6 overflow-visible pl-5">
                        <MilestonesForm view={false} editable={true} id={milestone.id} milestoneInfo={milestone} />
                    </div>
                </div>
            }
        />
    );
};

MilestonesRow.propTypes = {
    milestone: PropTypes.object,
    zIndex: PropTypes.number,
    setInitializeData: PropTypes.object,
};

MilestonesRow.defaultProps = {
    milestone: null,
    zIndex: 1,
    setInitializeData: null,
};

const Milestones = ({ setInitializeData }) => {
    const [milestones, setMilestones] = useState([]);
    const { t } = useTranslation();

    const { checkPerm } = useContext(UserContext);

    const [loading, setLoading] = useState(true);

    const canView = checkPerm([
        {
            permissionId: '15',
            permissionType: 'VIEW',
        },
    ]);

    const getMilestones = async (currentPage, perPage) => {
        try {
            const response = await API.get('/mileStones', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            setMilestones(response.data.data.data);
            setInitializeData?.((prev) => ({ ...prev, nextDisabled: !response.data.data.length }));
            return response.data.data.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <>
            {!setInitializeData && (
                <Helmet>
                    <title>
                        {t('ERP Configurator')} | {t('Milestone')}
                    </title>
                </Helmet>
            )}

            {canView && (
                <Pagination loading={loading} setLoading={setLoading} getFunction={getMilestones}>
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : milestones.length ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Milestone name')}</TableCell>
                                        <TableCell>{t('Group')}</TableCell>
                                        <TableCell>Pipeline</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>{t('Created on')}</TableCell>
                                        <TableCell>{t('Author')}</TableCell>
                                        {/* <TableCell>{t('Observations')}</TableCell> */}
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {milestones?.map((m) => (
                                        <MilestonesRow key={m.id} milestone={m} setInitializeData={setInitializeData} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </Pagination>
            )}
        </>
    );
};

Milestones.propTypes = {
    setInitializeData: PropTypes.func,
};

Milestones.defaultProps = {
    setInitializeData: null,
};

export default Milestones;
