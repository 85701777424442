import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
    ArrowDown,
    ArrowRightLeft,
    ArrowUp,
    BarChartHorizontal,
    BookUser,
    Boxes,
    BrainCircuit,
    BriefcaseBusiness,
    ChartNoAxesCombined,
    ClipboardType,
    FileChartColumn,
    FileCheck,
    FileCog,
    FilePenLine,
    FilePieChart,
    FilePlus2,
    FileStack,
    FileText,
    FolderCog,
    GitFork,
    Goal,
    Handshake,
    HelpCircle,
    KanbanSquare,
    Layers,
    LayoutGrid,
    List,
    ListTodo,
    ListTree,
    MapPin,
    MonitorCog,
    MousePointer,
    Package,
    ReceiptEuro,
    Search,
    Settings,
    StretchVertical,
    TrendingUpDown,
    User,
    Users,
    Wallet,
} from 'lucide-react';

import SupportModal from 'components/modals/modal';
import { useTranslation } from 'react-i18next';
// Routes
import {
    accountancy,
    affiliate,
    configurator,
    crm,
    internalActivity,
    projectInManagement,
    projectManagement,
    quotes,
    resourceModule,
} from 'routes';

const mappedModulePathKeys = {
    quotes: quotes.base,
    pm: projectManagement.base,
    project: projectInManagement.base,
    affiliateProject: affiliate.projectManagement.project,
    rm: resourceModule.base,
};

const useNavProps = (checkPerm, user, language, setModalOpen, setModalChildren) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [name, changeNavName] = useState('');
    const prevUrl = useRef('');

    const perms = {
        internalActivity: [
            checkPerm([
                {
                    permissionId: '1',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '2',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '3',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '4',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '42',
                    permissionType: 'VIEW',
                },
            ]),
        ],
        crm: [
            checkPerm([
                {
                    permissionId: '6',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '7',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '8',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '9',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '10',
                    permissionType: 'VIEW',
                },
            ]),
        ],
        modules: [
            checkPerm([
                {
                    permissionId: '19',
                    permissionType: 'VIEW',
                },
            ]),

            checkPerm([
                {
                    permissionId: '20',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '21',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '22',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '23',
                    permissionType: 'VIEW',
                },
            ]),
        ],
        config: [
            checkPerm([
                {
                    permissionId: '17',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '11',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '12',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '16',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '13',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '14',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '15',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '33',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '34',
                    permissionType: 'VIEW',
                },
            ]),
        ],

        accountancy: [
            checkPerm([
                {
                    permissionId: '35',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '36',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '37',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '38',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '39',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '40',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '41',
                    permissionType: 'VIEW',
                },
            ]),
        ],
    };

    const affiliateDefaultNavProps = useMemo(() => {
        return user && user.isAffiliate
            ? [
                  {
                      name: t('Project Management Module'),
                      icon: <FolderCog className="li-icon-width" />,
                      subItems: [
                          {
                              name: t('Projects'),
                              icon: <MousePointer className="li-icon-width" />,

                              onRoute: () => history.push(affiliate.base + affiliate.projectManagement.projects),
                          },
                          {
                              name: t('Planning Overview'),
                              icon: <BarChartHorizontal className="li-icon-width" />,
                              onRoute: () => history.push(affiliate.base + affiliate.projectManagement.overview),
                          },
                      ],
                  },
              ]
            : [];
    }, [user, language]);

    const defaultNavProps = useMemo(
        () => ({
            moduleName: '',
            navItems: [
                ...(perms.internalActivity.indexOf(true) > -1
                    ? [
                          {
                              name: t('Internal Activity'),
                              icon: <ListTree className="li-icon-width" />,
                              subItems: [
                                  ...(perms.internalActivity[0]
                                      ? [
                                            {
                                                name: t('Timeline Activities'),
                                                icon: <ListTodo className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(
                                                        internalActivity.base + internalActivity.timeline.base,
                                                    ),
                                            },
                                        ]
                                      : []),
                                  ...(perms.internalActivity[0]
                                      ? [
                                            {
                                                name: t('Tasks pipeline'),
                                                icon: (
                                                    <StretchVertical className="li-icon-width rotate-180 transform" />
                                                ),
                                                onRoute: () =>
                                                    history.push(
                                                        internalActivity.base + internalActivity.tasksPipeline.base,
                                                    ),
                                            },
                                        ]
                                      : []),
                                  ...(perms.internalActivity[1]
                                      ? [
                                            {
                                                name: t('Milestones pipeline'),
                                                icon: <KanbanSquare className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(
                                                        internalActivity.base + internalActivity.pipeline.base,
                                                    ),
                                            },
                                        ]
                                      : []),
                                  ...(perms.internalActivity[2]
                                      ? [
                                            {
                                                name: 'Planning Overview',
                                                icon: <BarChartHorizontal className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(
                                                        internalActivity.base + internalActivity.planning.base,
                                                    ),
                                            },
                                        ]
                                      : []),
                                  ...(perms.internalActivity[3]
                                      ? [
                                            {
                                                name: t('Partners'),
                                                icon: <BookUser className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(
                                                        internalActivity.base + internalActivity.partners.base,
                                                    ),
                                            },
                                        ]
                                      : []),
                                  ...(perms.internalActivity[4]
                                      ? [
                                            {
                                                name: t('Project structures'),
                                                icon: <GitFork className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(
                                                        internalActivity.base + internalActivity.projectStructure.base,
                                                    ),
                                            },
                                        ]
                                      : []),
                              ],
                          },
                      ]
                    : []),
                ...(perms.crm.indexOf(true) > -1
                    ? [
                          {
                              name: 'CRM',
                              icon: <Users className="li-icon-width" />,
                              subItems: [
                                  ...(perms.crm[0]
                                      ? [
                                            {
                                                name: t('contacts'),
                                                icon: <User className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(crm.base + crm.contacts.base + crm.contacts.read),
                                            },
                                        ]
                                      : []),
                                  ...(perms.crm[1]
                                      ? [
                                            {
                                                name: 'SIP',
                                                icon: <Package className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(crm.base + crm.estate.base + crm.estate.read),
                                            },
                                        ]
                                      : []),
                                  ...(perms.crm[2]
                                      ? [
                                            {
                                                name: t('contracts'),
                                                icon: <FileCheck className="li-icon-width" />,
                                                subItems: [
                                                    {
                                                        name: t('Contracts list'),
                                                        icon: <List className="li-icon-width" />,
                                                        onRoute: () =>
                                                            history.push(
                                                                crm.base + crm.contracts.base + crm.contracts.read,
                                                            ),
                                                    },
                                                    {
                                                        name: t('Contracts pipeline'),
                                                        icon: <KanbanSquare className="li-icon-width" />,
                                                        onRoute: () =>
                                                            history.push(
                                                                crm.base + crm.contracts.base + crm.contracts.pipeline,
                                                            ),
                                                    },
                                                ],
                                            },
                                        ]
                                      : []),
                                  ...(perms.crm[3]
                                      ? [
                                            {
                                                name: t('Offers'),
                                                icon: <Handshake className="li-icon-width" />,
                                                moduleItemsKey: mappedModulePathKeys['quotes'],
                                                onRoute: () =>
                                                    history.push(
                                                        quotes.base +
                                                            quotes.biddingHistory.base +
                                                            quotes.biddingHistory.read,
                                                    ),
                                            },
                                        ]
                                      : []),
                                  ...(perms.crm[4]
                                      ? [
                                            {
                                                name: t('Reports'),
                                                icon: <ChartNoAxesCombined className="li-icon-width" />,
                                                onRoute: () => history.push(crm.base + crm.reports.base),
                                            },
                                        ]
                                      : []),
                                  {
                                      name: t('Sales KPIs'),
                                      icon: <Goal className="li-icon-width" />,
                                      onRoute: () => console.log('Sales KPIs'),
                                  },
                                  {
                                      name: t('Sales Reporting KPIs'),
                                      icon: <FilePieChart className="li-icon-width" />,
                                      onRoute: () => console.log('Sales Reporting KPIs'),
                                  },
                              ],
                          },
                      ]
                    : []),
                ...(perms.modules.indexOf(true) > -1 && user && !user.isAffiliate
                    ? [
                          {
                              name: 'Module',
                              icon: <LayoutGrid className="li-icon-width" />,
                              subItems: [
                                  ...(perms.modules[0]
                                      ? [
                                            {
                                                name: t('Project Management Module'),
                                                moduleItemsKey: mappedModulePathKeys['pm'],
                                                icon: <FolderCog className="li-icon-width" />,
                                                onRoute: () => history.push(projectManagement.base),
                                            },
                                            {
                                                name: t('Resource Management Module'),
                                                moduleItemsKey: mappedModulePathKeys['rm'],
                                                icon: <Layers className="li-icon-width" />,
                                                onRoute: () => history.push(resourceModule.base),
                                            },
                                        ]
                                      : []),
                                  //   ...(perms.modules[1]
                                  //       ? [
                                  //             {
                                  //                 name: t('Works Undertaking Module'),
                                  //                 icon: (
                                  //                     <div className="flex items-center justify-center li-icon-width">
                                  //                         <i className="fa-solid fa-helmet-safety text-2xl" />
                                  //                     </div>
                                  //                 ),
                                  //                 onRoute: () =>
                                  //                     history.push(modules.base + modules.worksUndertaking.base),
                                  //             },
                                  //         ]
                                  //       : []),
                                  //   ...(perms.modules[2]
                                  //       ? [
                                  //             {
                                  //                 name: t('Sales Module'),
                                  //                 icon: (
                                  //                     <div className="flex items-center justify-center li-icon-width">
                                  //                         <i className="fa-solid fa-hand-pointer text-2xl" />
                                  //                     </div>
                                  //                 ),
                                  //                 onRoute: () => history.push(modules.base + modules.salesModule.base),
                                  //             },
                                  //         ]
                                  //       : []),

                                  //   ...(perms.modules[3]
                                  //       ? [
                                  //             {
                                  //                 name: t('Booking Module'),
                                  //                 icon: (
                                  //                     <div className="flex items-center justify-center li-icon-width">
                                  //                         <i className="fa-solid fa-calendar-days text-2xl" />
                                  //                     </div>
                                  //                 ),
                                  //                 onRoute: () => history.push(modules.base + modules.bookingModule.base),
                                  //             },
                                  //         ]
                                  //       : []),
                                  //   ...(perms.modules[4]
                                  //       ? [
                                  //             {
                                  //                 name: t('Facility Management Module'),
                                  //                 icon: (
                                  //                     <div className="flex items-center justify-center li-icon-width">
                                  //                         <i className="fa-solid fa-house text-2xl" />
                                  //                     </div>
                                  //                 ),
                                  //                 onRoute: () =>
                                  //                     history.push(modules.base + modules.facilityManagement.base),
                                  //             },
                                  //         ]
                                  //       : []),
                              ],
                          },
                      ]
                    : []),

                // Affiliate zones
                ...affiliateDefaultNavProps,

                ...(perms.accountancy.indexOf(true) > -1
                    ? [
                          {
                              name: t('Financials'),
                              icon: <Wallet className="li-icon-width" />,
                              subItems: [
                                  ...(perms.accountancy[0]
                                      ? [
                                            {
                                                name: t('Expenses nomenclature'),
                                                icon: <ArrowDown className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(
                                                        accountancy.base + accountancy.expenseNomenclature.base,
                                                    ),
                                            },
                                        ]
                                      : []),
                                  ...(perms.accountancy[1]
                                      ? [
                                            {
                                                name: t('Revenues nomenclature'),
                                                icon: <ArrowUp className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(
                                                        accountancy.base + accountancy.revenueNomenclature.base,
                                                    ),
                                            },
                                        ]
                                      : []),
                                  ...(perms.accountancy[2]
                                      ? [
                                            {
                                                name: t('Accounting accounts'),
                                                icon: <List className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(
                                                        accountancy.base + accountancy.accountingAccounts.base,
                                                    ),
                                            },
                                        ]
                                      : []),
                                  ...(perms.accountancy[3]
                                      ? [
                                            {
                                                name: t('Contract type nomenclature'),
                                                icon: <FileCog className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(
                                                        accountancy.base + accountancy.contractTypeNomenclature.base,
                                                    ),
                                            },
                                        ]
                                      : []),
                                  ...(perms.accountancy[4]
                                      ? [
                                            {
                                                name: t('Invoices'),
                                                icon: <ReceiptEuro className="li-icon-width" />,
                                                onRoute: () =>
                                                    history.push(accountancy.base + accountancy.invoice.base),
                                            },
                                        ]
                                      : []),

                                  {
                                      name: `GFT - ${t('Financial Tool')}`,
                                      icon: <FileChartColumn className="li-icon-width" />,
                                      subItems: [
                                          ...(perms.accountancy[5]
                                              ? [
                                                    {
                                                        name: t('P&L'),
                                                        icon: <TrendingUpDown className="li-icon-width" />,
                                                        onRoute: () =>
                                                            history.push(
                                                                accountancy.base + accountancy.PL.base + '?type=pl',
                                                            ),
                                                    },
                                                ]
                                              : []),

                                          ...(perms.accountancy[6]
                                              ? [
                                                    {
                                                        name: t('Cashflow'),
                                                        icon: <ArrowRightLeft className="li-icon-width" />,
                                                        onRoute: () =>
                                                            history.push(
                                                                accountancy.base +
                                                                    accountancy.cashflow.base +
                                                                    '?type=cashflow',
                                                            ),
                                                    },
                                                ]
                                              : []),
                                      ],
                                  },

                                  {
                                      name: t('KPIs'),
                                      icon: <Goal className="li-icon-width" />,
                                      onRoute: () => console.log('KPIs'),
                                  },
                                  {
                                      name: t('Reporting KPIs'),
                                      icon: <FilePieChart className="li-icon-width" />,
                                      onRoute: () => console.log('Reporting KPIs'),
                                  },
                              ],
                          },
                      ]
                    : []),

                {
                    name: t('Accounting'),
                    icon: <FilePenLine className="li-icon-width" />,
                    onRoute: () => console.log('Accounting'),
                },
                {
                    name: t('Business Intelligence'),
                    icon: <BrainCircuit className="li-icon-width" />,
                    onRoute: () => console.log('Business Intelligence'),
                },

                ...(perms.config.indexOf(true) > -1
                    ? [
                          {
                              name: t('ERP Configurator'),
                              icon: <Settings className="li-icon-width" />,
                              onRoute: () => history.push(configurator.base),
                          },
                      ]
                    : []),

                // {
                //     name: t('Versions'),
                //     icon: <FolderIcon className="li-icon-width" />,
                //     onRoute: () => history.push('/versions'),
                // },
                {
                    name: t('Technical support'),
                    icon: <HelpCircle className="li-icon-width" />,
                    onRoute: () => {
                        setModalChildren(<SupportModal />);
                        setModalOpen(true);
                    },
                },
            ],
        }),
        [user, language],
    );

    const calcName = (n) => (name.length > 0 ? name : n);

    const quotesNavProps = useMemo(() => {
        return user && !user.isAffiliate
            ? [
                  {
                      modulePathKey: mappedModulePathKeys['quotes'],
                      navProps: {
                          moduleName: calcName(t('Offer')),
                          navItems:
                              user && user.isAffiliate
                                  ? [
                                        {
                                            name: t('Offer history'),
                                            icon: <FileStack className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    quotes.base +
                                                        quotes.biddingHistory.base +
                                                        quotes.biddingHistory.read,
                                                ),
                                        },
                                        {
                                            name: t('Offers pipeline'),
                                            icon: <KanbanSquare className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    quotes.base +
                                                        quotes.biddingPipeline.base +
                                                        quotes.biddingPipeline.read,
                                                ),
                                        },
                                    ]
                                  : [
                                        {
                                            name: t('Offer history'),
                                            icon: <FileStack className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    quotes.base +
                                                        quotes.biddingHistory.base +
                                                        quotes.biddingHistory.read,
                                                ),
                                        },
                                        {
                                            name: t('Offers pipeline'),
                                            icon: <KanbanSquare className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    quotes.base +
                                                        quotes.biddingPipeline.base +
                                                        quotes.biddingPipeline.read,
                                                ),
                                        },
                                        {
                                            name: t('New offer'),
                                            icon: <FilePlus2 className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    quotes.base + quotes.newBidding.base + quotes.newBidding.read,
                                                ),
                                        },
                                        {
                                            name: t('Article nomenclature'),
                                            icon: <List className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    quotes.base + quotes.nomenclature.base + quotes.nomenclature.read,
                                                ),
                                        },
                                    ],
                      },
                  },
              ]
            : [];
    }, [location, name, user, language]);

    const affiliateModuleNavProps = useMemo(() => {
        return user && user.isAffiliate
            ? [
                  {
                      modulePathKey: mappedModulePathKeys['affiliateProject'],
                      navProps: {
                          backUrl: '/',
                          moduleName: calcName(t('Project')),
                          navItems: [
                              {
                                  name: t('Wikis'),
                                  icon: <ClipboardType className="li-icon-width" />,
                                  onRoute: () =>
                                      history.push(
                                          affiliate.base +
                                              affiliate.projectManagement.project +
                                              '/' +
                                              location.pathname.split('/')[3] +
                                              affiliate.projectWikis.base,
                                      ),
                              },
                              {
                                  name: t('Tasks'),
                                  icon: <ListTodo className="li-icon-width" />,
                                  onRoute: () =>
                                      history.push(
                                          affiliate.base +
                                              affiliate.projectManagement.project +
                                              '/' +
                                              location.pathname.split('/')[3] +
                                              affiliate.projectTasks.base,
                                      ),
                              },
                              {
                                  name: t('Planning Overview'),
                                  icon: <BarChartHorizontal className="li-icon-width" />,
                                  onRoute: () =>
                                      history.push(
                                          affiliate.base +
                                              affiliate.projectManagement.project +
                                              '/' +
                                              location.pathname.split('/')[3] +
                                              affiliate.planingOverviewOnProject.base,
                                      ),
                              },
                          ],
                      },
                  },
              ]
            : [];
    }, [location, name, user, language]);

    const projectManagementModuleNavProps = useMemo(() => {
        return user && !user.isAffiliate
            ? [
                  {
                      modulePathKey: mappedModulePathKeys['pm'],
                      navProps: {
                          backUrl: '/',
                          moduleName: calcName('Project management'),
                          navItems:
                              user && !user.isInvestor
                                  ? [
                                        {
                                            name: t('Projects'),
                                            icon: <MousePointer className="li-icon-width" />,
                                            onRoute: () => history.push(projectManagement.base),
                                        },
                                        {
                                            name: t('Planning Overview'),
                                            icon: <BarChartHorizontal className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectManagement.base +
                                                        projectManagement.projectsPlaningOverview.base +
                                                        projectManagement.projectsPlaningOverview.read,
                                                ),
                                        },
                                        // {
                                        //     name: t('Reports'),
                                        //     icon: (
                                        //         <div className="li-icon-width flex items-center justify-center">
                                        //             <i className="fa-solid fa-chart-pie text-2xl" />
                                        //         </div>
                                        //     ),
                                        //     onRoute: () =>
                                        //         history.push(
                                        //             projectManagement.base +
                                        //                 projectManagement.projectsReports.base +
                                        //                 projectManagement.projectsReports.read
                                        //         ),
                                        // },

                                        {
                                            name: 'Financial Tools',
                                            icon: <FileChartColumn className="li-icon-width" />,
                                            subItems: [
                                                {
                                                    name: t('P&L'),
                                                    icon: <TrendingUpDown className="li-icon-width" />,
                                                    onRoute: () =>
                                                        history.push(
                                                            projectManagement.base +
                                                                projectManagement.projectsPL.base +
                                                                '?type=pl_pm',
                                                        ),
                                                },
                                                {
                                                    name: t('Cashflow'),
                                                    icon: <ArrowRightLeft className="li-icon-width" />,
                                                    onRoute: () =>
                                                        history.push(
                                                            projectManagement.base +
                                                                projectManagement.projectsCashflow.base +
                                                                '?type=cashflow_pm',
                                                        ),
                                                },
                                            ],
                                        },
                                        {
                                            name: 'Configurator',
                                            icon: <Settings className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectManagement.base +
                                                        projectManagement.projectManagementConfigurator.base +
                                                        projectManagement.projectManagementConfigurator.read,
                                                ),
                                        },

                                        // {
                                        //     name: 'Configurator',
                                        //     icon: (
                                        //         <div className="flex items-center justify-center li-icon-width">
                                        //             <i className="fa-solid fa-gear text-2xl" />
                                        //         </div>
                                        //     ),
                                        //     onRoute: () =>
                                        //         history.push(
                                        //             projectManagement.base +
                                        //                 projectManagement.projectManagementConfigurator.base +
                                        //                 projectManagement.projectManagementConfigurator.read
                                        //         ),
                                        // },
                                    ]
                                  : [
                                        {
                                            name: t('Projects'),
                                            icon: <MousePointer className="li-icon-width" />,
                                            onRoute: () => history.push(projectManagement.base),
                                        },
                                        {
                                            name: t('Planning Overview'),
                                            icon: <BarChartHorizontal className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectManagement.base +
                                                        projectManagement.projectsPlaningOverview.base +
                                                        projectManagement.projectsPlaningOverview.read,
                                                ),
                                        },
                                        {
                                            name: 'Financial Tools',
                                            icon: <FileChartColumn className="li-icon-width" />,
                                            subItems: [
                                                {
                                                    name: t('P&L'),
                                                    icon: <TrendingUpDown className="li-icon-width" />,
                                                    onRoute: () =>
                                                        history.push(
                                                            projectManagement.base +
                                                                projectManagement.projectsPL.base +
                                                                '?type=pl_pm',
                                                        ),
                                                },
                                                {
                                                    name: t('Cashflow'),
                                                    icon: <ArrowRightLeft className="li-icon-width" />,
                                                    onRoute: () =>
                                                        history.push(
                                                            projectManagement.base +
                                                                projectManagement.projectsCashflow.base +
                                                                '?type=cashflow_pm',
                                                        ),
                                                },
                                            ],
                                        },
                                    ],
                      },
                  },
              ]
            : [];
    }, [location, name, user, language]);

    const individualProjectManagement = useMemo(() => {
        return user && !user.isAffiliate
            ? [
                  {
                      modulePathKey: mappedModulePathKeys['project'],
                      navProps: {
                          backUrl: '/',
                          moduleName: calcName('Project per proiect'),
                          navItems:
                              user && !user.isInvestor
                                  ? [
                                        {
                                            name: t('Wikis'),
                                            icon: <ClipboardType className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectInManagement.base +
                                                        '/' +
                                                        location.pathname.split('/')[2] +
                                                        projectInManagement.wiki.base,
                                                ),
                                        },
                                        {
                                            name: 'Task Management',
                                            icon: <ListTodo className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectInManagement.base +
                                                        '/' +
                                                        location.pathname.split('/')[2] +
                                                        projectInManagement.projectsTaskManagement.base,
                                                ),
                                        },
                                        {
                                            name: t('Tasks pipeline'),
                                            icon: <StretchVertical className="li-icon-width rotate-180 transform" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectInManagement.base +
                                                        '/' +
                                                        location.pathname.split('/')[2] +
                                                        projectInManagement.projectsTasksPipeline.base,
                                                ),
                                        },
                                        {
                                            name: 'Planning Overview',
                                            icon: <BarChartHorizontal className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectInManagement.base +
                                                        '/' +
                                                        location.pathname.split('/')[2] +
                                                        projectInManagement.planingOverviewOnProject.base,
                                                ),
                                        },
                                        {
                                            name: `CPI - ${t('Client Interactions')}`,
                                            icon: <BriefcaseBusiness className="li-icon-width" />,
                                            subItems: [
                                                // {
                                                //     name: t('Clients'),
                                                //     icon: <User className="li-icon-width" />,
                                                //     onRoute: () =>
                                                //         history.push(
                                                //             projectInManagement.base +
                                                //                 '/' +
                                                //                 location.pathname.split('/')[2] +
                                                //                 projectInManagement.projectClients.base,
                                                //         ),
                                                // },
                                                {
                                                    name: t('Contracts'),
                                                    icon: <FileCheck className="li-icon-width" />,
                                                    subItems: [
                                                        {
                                                            name: t('Contracts list'),
                                                            icon: <List className="li-icon-width" />,
                                                            onRoute: () =>
                                                                history.push(
                                                                    projectInManagement.base +
                                                                        '/' +
                                                                        location.pathname.split('/')[2] +
                                                                        projectInManagement.projectContracts.base +
                                                                        projectInManagement.projectContracts.read,
                                                                ),
                                                        },
                                                        {
                                                            name: t('Contracts pipeline'),
                                                            icon: <KanbanSquare className="li-icon-width" />,
                                                            onRoute: () =>
                                                                history.push(
                                                                    projectInManagement.base +
                                                                        '/' +
                                                                        location.pathname.split('/')[2] +
                                                                        projectInManagement.projectContracts.base +
                                                                        projectInManagement.projectContracts.pipeline,
                                                                ),
                                                        },
                                                    ],
                                                },
                                                {
                                                    name: t('Invoices'),
                                                    icon: <ReceiptEuro className="li-icon-width" />,
                                                    onRoute: () => console.log('Facturi'),
                                                },
                                            ],
                                        },

                                        {
                                            name: `IPO - ${t('Internal Operations')}`,
                                            icon: <MonitorCog className="li-icon-width" />,
                                            onRoute: () => console.log('Internal project operations'),
                                        },

                                        {
                                            name: `RPIM - ${t('Resource Interaction Manager')}`,
                                            icon: <Layers className="li-icon-width" />,
                                            onRoute: () => console.log('Resource Project Operations'),
                                        },

                                        {
                                            name: 'RIEM',
                                            icon: <FileText className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectInManagement.base +
                                                        '/' +
                                                        location.pathname.split('/')[2] +
                                                        projectInManagement.riem.base,
                                                ),
                                        },
                                        {
                                            name: t('KPIs'),
                                            icon: <Goal className="li-icon-width" />,
                                            onRoute: () => console.log('KPIs'),
                                        },
                                        {
                                            name: t('Reporting KPIs'),
                                            icon: <FilePieChart className="li-icon-width" />,
                                            onRoute: () => console.log('Reporting KPIs'),
                                        },

                                        {
                                            name: t('Watcher Insights'),
                                            icon: <Search className="li-icon-width" />,
                                            onRoute: () => console.log('Watcher Insights'),
                                        },

                                        // {
                                        //     name: t('Articles'),
                                        //     icon: <List className="li-icon-width" />,
                                        //     onRoute: () =>
                                        //         history.push(
                                        //             projectInManagement.base +
                                        //                 '/' +
                                        //                 location.pathname.split('/')[2] +
                                        //                 projectInManagement.projectArticles.base,
                                        //         ),
                                        // },
                                        {
                                            name: `FDPE - ${t('Financial Data Exporter')}`,
                                            icon: <FileChartColumn className="li-icon-width" />,
                                            subItems: [
                                                {
                                                    name: t('P&L'),
                                                    icon: <TrendingUpDown className="li-icon-width" />,
                                                    onRoute: () =>
                                                        history.push(
                                                            projectInManagement.base +
                                                                '/' +
                                                                location.pathname.split('/')[2] +
                                                                projectInManagement.projectPL.base +
                                                                '?type=pl_pm',
                                                        ),
                                                },
                                                {
                                                    name: t('Cashflow'),
                                                    icon: <ArrowRightLeft className="li-icon-width" />,
                                                    onRoute: () =>
                                                        history.push(
                                                            projectInManagement.base +
                                                                '/' +
                                                                location.pathname.split('/')[2] +
                                                                projectInManagement.projectCashflow.base +
                                                                '?type=cashflow_pm',
                                                        ),
                                                },
                                            ],
                                        },
                                        {
                                            name: 'Configurator',
                                            icon: <Settings className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectInManagement.base +
                                                        '/' +
                                                        location.pathname.split('/')[2] +
                                                        projectInManagement.configurator,
                                                ),
                                        },

                                        // {
                                        //     name: 'Time Management',
                                        //     icon: <i className="fa-solid fa-clock text-2xl ml-2 mr-2" />,
                                        //     onRoute: () => history.push(projectInManagement.base + '/' + projectId + projectInManagement.timeManagement.base),
                                        // },
                                        // {
                                        //     name: 'Files & Documents Management',
                                        //     icon: <i className="fa-solid fa-file-lines text-2xl ml-2 mr-2" />,
                                        //     onRoute: () => history.push(projectInManagement.base + '/' + projectId + projectInManagement.filesAndDocumentsManagement.base),
                                        // },
                                        // {
                                        //     name: 'Bugete',
                                        //     icon: <i className="fa-solid fa-calculator text-2xl ml-2 mr-2" />,
                                        //     onRoute: () => history.push(projectInManagement.base + '/' + projectId + projectInManagement.projectBudgets.base),
                                        // },
                                        // {
                                        //     name: 'Risk Management',
                                        //     icon: <i className="fa-solid fa-triangle-exclamation text-2xl ml-2 mr-2" />,
                                        //     onRoute: () => history.push(projectInManagement.base + '/' + projectId + projectInManagement.riskManagement.base),
                                        // },
                                        // {
                                        //     name: 'Rapoarte',
                                        //     icon: <i className="fa-solid fa-chart-pie text-2xl ml-2 mr-2" />,
                                        //     onRoute: () => history.push(projectInManagement.base + '/' + projectId + projectInManagement.projectManagementReports.base),
                                        // },
                                        // {
                                        //     name: 'Project settings',
                                        //     icon: <i className="fa-solid fa-gear text-2xl ml-2 mr-2" />,
                                        //     onRoute: () => history.push(projectInManagement.base + '/' + projectId + projectInManagement.projectSettings.base),
                                        // },
                                    ]
                                  : [
                                        {
                                            name: t('Wikis'),
                                            icon: <ClipboardType className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectInManagement.base +
                                                        '/' +
                                                        location.pathname.split('/')[2] +
                                                        projectInManagement.wiki.base,
                                                ),
                                        },
                                        {
                                            name: 'Task Management',
                                            icon: <ListTodo className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectInManagement.base +
                                                        '/' +
                                                        location.pathname.split('/')[2] +
                                                        projectInManagement.projectsTaskManagement.base,
                                                ),
                                        },
                                        {
                                            name: t('Tasks pipeline'),
                                            icon: <StretchVertical className="li-icon-width rotate-180 transform" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectInManagement.base +
                                                        '/' +
                                                        location.pathname.split('/')[2] +
                                                        projectInManagement.projectsTasksPipeline.base,
                                                ),
                                        },
                                        {
                                            name: 'Planning Overview',
                                            icon: <BarChartHorizontal className="li-icon-width" />,
                                            onRoute: () =>
                                                history.push(
                                                    projectInManagement.base +
                                                        '/' +
                                                        location.pathname.split('/')[2] +
                                                        projectInManagement.planingOverviewOnProject.base,
                                                ),
                                        },
                                        {
                                            name: `CPI - ${t('Client Interactions')}`,
                                            icon: <BriefcaseBusiness className="li-icon-width" />,
                                            subItems: [
                                                // {
                                                //     name: t('Clients'),
                                                //     icon: <User className="li-icon-width" />,
                                                //     onRoute: () =>
                                                //         history.push(
                                                //             projectInManagement.base +
                                                //                 '/' +
                                                //                 location.pathname.split('/')[2] +
                                                //                 projectInManagement.projectClients.base,
                                                //         ),
                                                // },
                                                {
                                                    name: t('Contracts'),
                                                    icon: <FileCheck className="li-icon-width" />,
                                                    subItems: [
                                                        {
                                                            name: t('Contracts list'),
                                                            icon: <List className="li-icon-width" />,
                                                            onRoute: () =>
                                                                history.push(
                                                                    projectInManagement.base +
                                                                        '/' +
                                                                        location.pathname.split('/')[2] +
                                                                        projectInManagement.projectContracts.base +
                                                                        projectInManagement.projectContracts.read,
                                                                ),
                                                        },
                                                        {
                                                            name: t('Contracts pipeline'),
                                                            icon: <KanbanSquare className="li-icon-width" />,
                                                            onRoute: () =>
                                                                history.push(
                                                                    projectInManagement.base +
                                                                        '/' +
                                                                        location.pathname.split('/')[2] +
                                                                        projectInManagement.projectContracts.base +
                                                                        projectInManagement.projectContracts.pipeline,
                                                                ),
                                                        },
                                                    ],
                                                },
                                                {
                                                    name: t('Invoices'),
                                                    icon: <ReceiptEuro className="li-icon-width" />,
                                                    onRoute: () => console.log('Facturi'),
                                                },
                                            ],
                                        },

                                        {
                                            name: `IPO - ${t('Internal Operations')}`,
                                            icon: <MonitorCog className="li-icon-width" />,
                                            onRoute: () => console.log('Internal project operations'),
                                        },

                                        {
                                            name: `RPIM - ${t('Resource Interaction Manager')}`,
                                            icon: <Layers className="li-icon-width" />,
                                            onRoute: () => console.log('Resource Project Operations'),
                                        },

                                        {
                                            name: 'RIEM',
                                            icon: <FileText className="li-icon-width" />,
                                            onRoute: () => console.log('Resource Project Operations'),
                                        },

                                        {
                                            name: t('KPIs'),
                                            icon: <Goal className="li-icon-width" />,
                                            onRoute: () => console.log('Sales KPIs'),
                                        },
                                        {
                                            name: t('Reporting KPIs'),
                                            icon: <FilePieChart className="li-icon-width" />,
                                            onRoute: () => console.log('Sales Reporting KPIs'),
                                        },

                                        {
                                            name: t('Watcher Insights'),
                                            icon: <Search className="li-icon-width" />,
                                            onRoute: () => console.log('Watcher Insights'),
                                        },

                                        // {
                                        //     name: t('Articles'),
                                        //     icon: <List className="li-icon-width" />,
                                        //     onRoute: () =>
                                        //         history.push(
                                        //             projectInManagement.base +
                                        //                 '/' +
                                        //                 location.pathname.split('/')[2] +
                                        //                 projectInManagement.projectArticles.base,
                                        //         ),
                                        // },

                                        {
                                            name: `FDPE - ${t('Financial Data Exporter')}`,
                                            icon: <FileChartColumn className="li-icon-width" />,
                                            subItems: [
                                                {
                                                    name: t('P&L'),
                                                    icon: <TrendingUpDown className="li-icon-width" />,
                                                    onRoute: () =>
                                                        history.push(
                                                            projectInManagement.base +
                                                                '/' +
                                                                location.pathname.split('/')[2] +
                                                                projectInManagement.projectPL.base +
                                                                '?type=pl_pm',
                                                        ),
                                                },
                                                {
                                                    name: t('Cashflow'),
                                                    icon: <ArrowRightLeft className="li-icon-width" />,
                                                    onRoute: () =>
                                                        history.push(
                                                            projectInManagement.base +
                                                                '/' +
                                                                location.pathname.split('/')[2] +
                                                                projectInManagement.projectCashflow.base +
                                                                '?type=cashflow_pm',
                                                        ),
                                                },
                                            ],
                                        },
                                    ],
                      },
                  },
              ]
            : [];
    }, [location, name, user, language]);

    const resourceModuleNavProps = useMemo(() => {
        return user && !user.isAffiliate
            ? [
                  {
                      modulePathKey: mappedModulePathKeys['rm'],
                      navProps: {
                          backUrl: '/',
                          moduleName: calcName(t('Manage Resources')),
                          navItems: [
                              {
                                  name: t('Resources'),
                                  icon: <Boxes className="li-icon-width" />,
                                  onRoute: () => history.push(resourceModule.resources.base),
                              },

                              {
                                  name: t('Warehouse locations'),
                                  icon: <MapPin className="li-icon-width transform" />,
                                  onRoute: () => history.push(resourceModule.warehouse.base),
                              },
                          ],
                      },
                  },
              ]
            : [];
    }, [location, name, user, language]);

    const moduleNavProps = useMemo(
        () => [
            // Ofertare
            ...quotesNavProps,
            // PM
            ...projectManagementModuleNavProps,

            // PM per project
            ...individualProjectManagement,

            // PM Affiliate
            ...affiliateModuleNavProps,

            //RM
            ...resourceModuleNavProps,
        ],
        [
            quotesNavProps,
            projectManagementModuleNavProps,
            individualProjectManagement,
            affiliateModuleNavProps,
            resourceModuleNavProps,
        ],
    );

    const dashboardProps = useMemo(
        () =>
            defaultNavProps.navItems.reduce(
                (acc, curr) => {
                    const subItems = curr.subItems ?? [];
                    if (!subItems.length) return { ...acc, others: [...acc.others, curr] };

                    const itemsKeys = subItems.filter((itemKey) => Boolean(itemKey.moduleItemsKey));
                    const ignore = Boolean(itemsKeys.length) && itemsKeys.length === subItems.length;

                    const result = moduleNavProps
                        .filter((moduleItem) =>
                            itemsKeys.map((itemKey) => itemKey.moduleItemsKey).includes(moduleItem.modulePathKey),
                        )
                        .map((moduleItem) => ({
                            icon: itemsKeys.find((itemKey) => itemKey.moduleItemsKey === moduleItem.modulePathKey).icon,
                            name: moduleItem.navProps.moduleName,
                            subItems: moduleItem.navProps.navItems.flatMap((nI) => nI.subItems ?? nI),
                        }));

                    if (ignore) return { ...acc, modules: [...acc.modules, ...result] };

                    result.unshift({
                        ...curr,
                        subItems: subItems.flatMap((sB) => sB.subItems ?? sB),
                    });
                    return { ...acc, modules: [...acc.modules, ...result] };
                },
                { modules: [], others: [] },
            ),
        [defaultNavProps, moduleNavProps],
    );

    const moduleIndex = useMemo(() => {
        return moduleNavProps.findIndex((a) => location.pathname.search(a.modulePathKey) > -1);
    }, [location]);

    history.listen(() => (prevUrl.current = location.pathname));

    useEffect(() => {
        if (prevUrl.current.length > 0) {
            // Detect module change
            try {
                if (prevUrl.current.split('/')[1] !== location.pathname.split('/')[1]) {
                    changeNavName('');
                }
            } catch (err) {
                console.error(err);
            }
        }
    }, [location]);

    return [
        moduleIndex > -1
            ? { ...moduleNavProps[moduleIndex].navProps, dashboardProps }
            : { ...defaultNavProps, dashboardProps },
        changeNavName,
    ];
};

export default useNavProps;
