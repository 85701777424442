import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, TableCell } from '@material-ui/core';

import PrpTypes from 'prop-types';
import { ExpandableRow } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { resourceModule } from 'routes';

const HumanRow = ({ human }) => {
    const optionClass = 'text-base text-dark-text mb-2';
    const optionSpanClass = 'text-main-text font-bold';

    const history = useHistory();
    const { t } = useTranslation();

    const handleEdit = () => history.push(resourceModule.humanResources.edit + '/' + human.id);

    return (
        <ExpandableRow
            colSpan={7}
            row={
                <Fragment>
                    <TableCell>{human.name}</TableCell>
                    <TableCell>{human.address}</TableCell>
                    <TableCell>{human.CNP}</TableCell>
                    <TableCell>{human.email}</TableCell>
                </Fragment>
            }
            collapse={
                <div className="flex flex-col gap-10">
                    <div className="flex flex-wrap justify-between gap-4">
                        <Button color="primary" onClick={handleEdit}>
                            {t('Edit resource')}
                        </Button>
                        <div className="flex flex-wrap gap-4">
                            <Fragment>&nbsp;</Fragment>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-10">
                        <div>
                            <h4 className="mb-5 whitespace-nowrap">{t('Resource info')}</h4>
                            <p className={optionClass}>
                                {t('Name')}: <span className={optionSpanClass}>{human.name}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Address')}: <span className={optionSpanClass}>{human.address}</span>
                            </p>
                            <p className={optionClass}>
                                CNP: <span className={optionSpanClass}>{human.CNP}</span>
                            </p>
                            <p className={optionClass}>
                                Email: <span className={optionSpanClass}>{human.email}</span>
                            </p>
                        </div>
                    </div>
                </div>
            }
        />
    );
};

HumanRow.propTypes = {
    human: PrpTypes.object,
};

export default HumanRow;
