import axios from 'axios';

// ?  We instantiate a axios entity which will be used across the application
export default axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
});
