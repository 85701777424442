import React, { useContext, useState } from 'react';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { Button } from '@material-ui/core';

import Configurator from 'components/shared/configurator/configurator';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import EditEmailTemplate from 'pages/configurator/email_templates/edit_email_template';
import PropTypes from 'prop-types';
import { Header, Stepper, Tabs } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import API from 'utils/axios';

import InitializeArticleCategories from './initialize-article-categories';
import InitializeArticles from './initialize-articles';
import InitializeCompanyDetails from './initialize-company-details';
import InitializeDepartments from './initialize-departments';
import InitializeExpenses from './initialize-expenses';
import InitializeMilestones from './initialize-milestones';
import InitializeOffersTags from './initialize-offers-tags';
import InitializePDFTemplates from './initialize-pdf-templates';
import InitializeRevenues from './initialize-revenues';
import InitializeStepInfo from './initialize-step-info';

const steps = [
    'COMPANY_DETAILS',
    'CONFIGURATOR_CONTACTS',
    'CONFIGURATOR_SIPS',
    'CONFIGURATOR_CONTRACTS',
    'CONFIGURATOR_PARTNERS',
    'CONFIGURATOR_DEPARTMENTS',
    'CONFIGURATOR_MILESTONES',
    'CONFIGURATOR_OFFERS_TAGS',
    'CONFIGURATOR_PDF_TEMPLATES',
    'CONFIGURATOR_EMAIL_TEMPLATES',
    'CONFIGURATOR_EXPENSES',
    'CONFIGURATOR_REVENUES',
    'CONFIGURATOR_CATEGORIES_ARTICLE',
    'CONFIGURATOR_ARTICLES',
    'COMPLETED',
];

const defaultInitialData = {
    prevDisabled: false,
    nextDisabled: true,
    skipDisabled: false,
    loading: false,
    tab: 0,
    step: 0,
};

const InitializeCompany = ({ onLogout, onSuccess }) => {
    const { tenant } = useContext(GlobalContext);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [initializeData, setInitializeData] = useState({
        ...defaultInitialData,
        step: steps.findIndex((s) => s === tenant.initializationStep),
    });

    const depTabs = [t('Departments'), t('Add new department')];
    const milTabs = [t('Milestone'), t('Add new Milestone')];
    const offerTagsTabs = [t('Offer tags'), t('Add new offer tag')];
    const pdfTabs = [t('PDF templates'), t('Add new PDF template')];
    const expTabs = [t('Expenses'), t('Add expense')];
    const revTabs = [t('Revenues'), t('Add revenue')];
    const artCatTabs = [t('Article categories'), t('Add new article category')];
    const artTabs = [t('Articles'), t('Add new article')];

    const handleIncrementStep = async (canUpdateStep = true, isFinalStep = false) => {
        try {
            if (!canUpdateStep) {
                setInitializeData((prev) => ({
                    ...prev,
                    step: prev.step + 1,
                }));

                enqueueSnackbar(`${t('Step completed')} (${t(steps[initializeData.step].split('_').join(' '))})`, {
                    variant: 'success',
                });

                return;
            }

            setInitializeData((prev) => ({ ...prev, loading: true }));

            await API.patch('/tenant', undefined, {
                params: { step: steps[initializeData.step + 1] },
            });

            if (isFinalStep) {
                onSuccess();

                enqueueSnackbar(t('Initialization completed'), {
                    variant: 'success',
                });

                return;
            }

            setInitializeData((prev) => ({
                ...defaultInitialData,
                step: prev.step + 1,
            }));

            enqueueSnackbar(`${t('Step completed')} (${t(steps[initializeData.step].split('_').join(' '))})`, {
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const handleDecrementStep = async () => {
        try {
            setInitializeData((prev) => ({ ...prev, loading: true }));

            await API.patch('/tenant', undefined, {
                params: { step: steps[initializeData.step - 1] },
            });

            setInitializeData((prev) => ({
                ...defaultInitialData,
                step: prev.step - 1,
            }));

            enqueueSnackbar(
                `${t('You went to previous step')} (${t(steps[initializeData.step - 1].split('_').join(' '))})`,
                {
                    variant: 'success',
                }
            );
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Finalize company initialization')}</title>
            </Helmet>

            <Header
                pageTitle={t('Finalize company initialization')}
                action={
                    <Button startIcon={<ExitToAppIcon />} style={{ borderRadius: '999px' }} onClick={onLogout}>
                        {t('Logout')}
                    </Button>
                }
                toolbar={
                    <Stepper
                        step={initializeData.step}
                        steps={steps.slice(0, steps.length - 1).map((step) => t(step.split('_').join(' ')))}
                    />
                }
                toolbarSecondary={
                    initializeData.step === 5 ? (
                        <Tabs
                            activeTab={initializeData.tab}
                            setActiveTab={(index) => setInitializeData((prev) => ({ ...prev, tab: index }))}
                            tabs={depTabs}
                        />
                    ) : initializeData.step === 6 ? (
                        <Tabs
                            activeTab={initializeData.tab}
                            setActiveTab={(index) => setInitializeData((prev) => ({ ...prev, tab: index }))}
                            tabs={milTabs}
                        />
                    ) : initializeData.step === 7 ? (
                        <Tabs
                            activeTab={initializeData.tab}
                            setActiveTab={(index) => setInitializeData((prev) => ({ ...prev, tab: index }))}
                            tabs={offerTagsTabs}
                        />
                    ) : initializeData.step === 8 ? (
                        <Tabs
                            activeTab={initializeData.tab}
                            setActiveTab={(index) => setInitializeData((prev) => ({ ...prev, tab: index }))}
                            tabs={pdfTabs}
                        />
                    ) : initializeData.step === 10 ? (
                        <Tabs
                            activeTab={initializeData.tab}
                            setActiveTab={(index) => setInitializeData((prev) => ({ ...prev, tab: index }))}
                            tabs={expTabs}
                        />
                    ) : initializeData.step === 11 ? (
                        <Tabs
                            activeTab={initializeData.tab}
                            setActiveTab={(index) => setInitializeData((prev) => ({ ...prev, tab: index }))}
                            tabs={revTabs}
                        />
                    ) : initializeData.step === 12 ? (
                        <Tabs
                            activeTab={initializeData.tab}
                            setActiveTab={(index) => setInitializeData((prev) => ({ ...prev, tab: index }))}
                            tabs={artCatTabs}
                        />
                    ) : initializeData.step === 13 ? (
                        <Tabs
                            activeTab={initializeData.tab}
                            setActiveTab={(index) => setInitializeData((prev) => ({ ...prev, tab: index }))}
                            tabs={artTabs}
                        />
                    ) : null
                }
            />

            <div className="page-container">
                {initializeData.step === 0 && <InitializeCompanyDetails handleIncrementStep={handleIncrementStep} />}
                {initializeData.step === 1 && (
                    <>
                        <InitializeStepInfo
                            secondaryInfo={[
                                {
                                    id: 0,
                                    content: t(
                                        'In this step you can customize the contact entity. This step is not mandatory and it can be skipped'
                                    ),
                                },
                            ]}
                            className="mb-10"
                        />
                        <Configurator
                            entity="contact"
                            handleDecrementStep={handleDecrementStep}
                            handleIncrementStep={handleIncrementStep}
                            initializeData={initializeData}
                        />
                    </>
                )}
                {initializeData.step === 2 && (
                    <>
                        <InitializeStepInfo
                            secondaryInfo={[
                                {
                                    id: 0,
                                    content: t(
                                        'In this step you can customize the SIP entity. This step is not mandatory and it can be skipped'
                                    ),
                                },
                            ]}
                            className="mb-10"
                        />
                        <Configurator
                            entity="estate"
                            handleDecrementStep={handleDecrementStep}
                            handleIncrementStep={handleIncrementStep}
                            initializeData={initializeData}
                        />
                    </>
                )}
                {initializeData.step === 3 && (
                    <>
                        <InitializeStepInfo
                            secondaryInfo={[
                                {
                                    id: 0,
                                    content: t(
                                        'In this step you can customize the contract entity. This step is not mandatory and it can be skipped'
                                    ),
                                },
                            ]}
                            className="mb-10"
                        />
                        <Configurator
                            entity="contract"
                            handleDecrementStep={handleDecrementStep}
                            handleIncrementStep={handleIncrementStep}
                            initializeData={initializeData}
                        />
                    </>
                )}
                {initializeData.step === 4 && (
                    <>
                        <InitializeStepInfo
                            secondaryInfo={[
                                {
                                    id: 0,
                                    content: t(
                                        'In this step you can customize the partner entity. This step is not mandatory and it can be skipped'
                                    ),
                                },
                            ]}
                            className="mb-10"
                        />
                        <Configurator
                            entity="partner"
                            handleDecrementStep={handleDecrementStep}
                            handleIncrementStep={handleIncrementStep}
                            initializeData={initializeData}
                        />
                    </>
                )}
                {initializeData.step === 5 && (
                    <InitializeDepartments
                        initializeData={initializeData}
                        setInitializeData={setInitializeData}
                        handleDecrementStep={handleDecrementStep}
                        handleIncrementStep={handleIncrementStep}
                    />
                )}
                {initializeData.step === 6 && (
                    <InitializeMilestones
                        initializeData={initializeData}
                        setInitializeData={setInitializeData}
                        handleDecrementStep={handleDecrementStep}
                        handleIncrementStep={handleIncrementStep}
                    />
                )}
                {initializeData.step === 7 && (
                    <InitializeOffersTags
                        initializeData={initializeData}
                        setInitializeData={setInitializeData}
                        handleDecrementStep={handleDecrementStep}
                        handleIncrementStep={handleIncrementStep}
                    />
                )}
                {initializeData.step === 8 && (
                    <InitializePDFTemplates
                        initializeData={initializeData}
                        setInitializeData={setInitializeData}
                        handleDecrementStep={handleDecrementStep}
                        handleIncrementStep={handleIncrementStep}
                    />
                )}
                {initializeData.step === 9 && (
                    <>
                        <InitializeStepInfo
                            secondaryInfo={[
                                {
                                    id: 0,
                                    content: t(
                                        'Here you can change what colors will be used for the emails. This step is not mandatory and it can be skipped'
                                    ),
                                },
                            ]}
                            className="mb-10"
                        />
                        <EditEmailTemplate
                            initializeData={initializeData}
                            setInitializeData={setInitializeData}
                            handleDecrementStep={handleDecrementStep}
                            handleIncrementStep={handleIncrementStep}
                        />
                    </>
                )}
                {initializeData.step === 10 && (
                    <InitializeExpenses
                        initializeData={initializeData}
                        setInitializeData={setInitializeData}
                        handleDecrementStep={handleDecrementStep}
                        handleIncrementStep={handleIncrementStep}
                    />
                )}
                {initializeData.step === 11 && (
                    <InitializeRevenues
                        initializeData={initializeData}
                        setInitializeData={setInitializeData}
                        handleDecrementStep={handleDecrementStep}
                        handleIncrementStep={handleIncrementStep}
                    />
                )}
                {initializeData.step === 12 && (
                    <InitializeArticleCategories
                        initializeData={initializeData}
                        setInitializeData={setInitializeData}
                        handleDecrementStep={handleDecrementStep}
                        handleIncrementStep={handleIncrementStep}
                    />
                )}
                {initializeData.step === 13 && (
                    <InitializeArticles
                        initializeData={initializeData}
                        setInitializeData={setInitializeData}
                        handleDecrementStep={handleDecrementStep}
                        handleIncrementStep={handleIncrementStep}
                    />
                )}
            </div>
        </>
    );
};

InitializeCompany.propTypes = {
    onLogout: PropTypes.func,
    onSuccess: PropTypes.func,
};

InitializeCompany.defaultProps = {
    onLogout: () => {},
    onSuccess: () => {},
};

export default InitializeCompany;
