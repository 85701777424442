import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ConfigFieldWrapper from './config-field-wrapper';

const PhoneField = (props) => {
    const { t } = useTranslation();
    const { field, changeFieldValue, deleteFieldFromColumn } = props;

    return (
        <ConfigFieldWrapper
            canBeDeleted={field.canBeDeleted}
            type={field.type}
            name={t(field.displayName)}
            setName={(newValue) => changeFieldValue('displayName', newValue)}
            deleteFieldFromColumn={deleteFieldFromColumn}
            hasOptions={false}
        />
    );
};

PhoneField.propTypes = {
    field: PropTypes.object,
    changeFieldValue: PropTypes.func,
    deleteFieldFromColumn: PropTypes.func,
};

PhoneField.defaultProps = {
    field: null,
    changeFieldValue: () => null,
    deleteFieldFromColumn: () => null,
};

export default PhoneField;
