import React, { cloneElement } from 'react';

import PropTypes from 'prop-types';

const PipelineItemRow = ({ row, className }) => {
    return (
        <>
            <div className={`flex items-center gap-3 ${className}`}>
                {row.label.icon && (
                    <div className="flex items-center justify-center">
                        {cloneElement(row.label.icon, {
                            style: {
                                fontSize: '1.5rem',
                            },
                        })}
                    </div>
                )}
                <p className="text-sm text-dark-text">{row.label.text}:</p>
            </div>
            <p className={`break-all text-right text-lg font-semibold leading-none ${className}`}>{row.content}</p>
        </>
    );
};

PipelineItemRow.propTypes = {
    row: PropTypes.object,
    className: PropTypes.string,
};

PipelineItemRow.defaultProps = {
    row: {},
    className: '',
};

export default PipelineItemRow;
