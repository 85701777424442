import React from 'react';

import { Button, makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles(() => {
    return {
        success: {
            backgroundColor: `var(--success)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--success-light)`,
            },
        },
    };
});

const SuccessButton = ({ children, className, style, ...props }) => {
    const { success } = useStyles();
    const classes = `${success} ${className}`;

    return (
        <Button {...props} className={classes} style={{ ...style }}>
            {children}
        </Button>
    );
};

SuccessButton.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    style: PropTypes.object,
};

SuccessButton.defaultProps = {
    children: null,
    className: '',
    style: {},
};

export default SuccessButton;
