import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { Button, CircularProgress, TextField } from '@material-ui/core';

import FileUploadContainer from 'components/shared/file-upload-container';
import { useSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import { LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { configurator } from 'routes';
import API from 'utils/axios';
import { errorHandling, uploadSingleFile } from 'utils/index';
import * as yup from 'yup';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const PDFTemplateForm = ({ templateInfo, setInitializeData }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { templateId } = useParams();

    const [loading, setLoading] = useState(false);

    const [PDFTemplateName, setPDFTemplateName] = useState(null);

    const [userStartPDF, setUserStartPDF] = useState(null);
    const [userEndPDF, setUserEndPDF] = useState(null);

    const [numPagesStartPDF, setNumPagesStartPDF] = useState(null);
    const [pageNumberStartPDF, setPageNumberStartPDF] = useState(1);

    function onDocumentLoadSuccessStartPDF({ numPages: nextNumPages }) {
        setNumPagesStartPDF(nextNumPages);
    }

    const [numPagesEndPDF, setNumPagesEndPDF] = useState(null);
    const [pageNumberEndPDF, setPageNumberEndPDF] = useState(1);

    function onDocumentLoadSuccessEndPDF({ numPages: nextNumPages }) {
        setNumPagesEndPDF(nextNumPages);
    }

    /**
     * PDF Upload Code
     */

    const handlePDFUpload = async (e, location) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }
                if (location === 'start') {
                    setUserStartPDF(blob);
                } else if (location === 'end') {
                    setUserEndPDF(blob);
                }
            },
            'file'
        );
    };

    const schema = yup.object().shape({
        PDFTemplateName: yup
            .string()
            .trim()
            .typeError(t('The PDF template name is mandatory'))
            .required(t('The PDF template name is mandatory'))
            .min(3, t('The PDF template name must be at least 3 characters long!')),
    });

    const createOrEditPDFTemplate = async () => {
        try {
            setLoading(true);
            setInitializeData?.((prev) => ({ ...prev, loading: true }));

            await schema.validate({
                PDFTemplateName,
            });

            if (userStartPDF === null || userEndPDF === null) {
                enqueueSnackbar(t('The PDF template must have a start page and end page'), {
                    variant: 'error',
                });
                return;
            }

            const reqBody = new FormData();

            reqBody.append('name', PDFTemplateName);

            if (templateId) {
                reqBody.append('id', templateId);
                if (userStartPDF.type === 'application/pdf' && userEndPDF.type === undefined) {
                    reqBody.append('startPdf', userStartPDF);
                    reqBody.append('type', 'START');
                } else if (userEndPDF.type === 'application/pdf' && userStartPDF.type === undefined) {
                    reqBody.append('endPdf', userEndPDF);
                    reqBody.append('type', 'END');
                } else if (userStartPDF.type === 'application/pdf' && userEndPDF.type === 'application/pdf') {
                    reqBody.append('startPdf', userStartPDF);
                    reqBody.append('endPdf', userEndPDF);
                }
            } else {
                reqBody.append('startPdf', userStartPDF);
                reqBody.append('endPdf', userEndPDF);
            }

            try {
                setLoading(true);
                if (templateId) {
                    await API.put('/offer_template_pages', reqBody, { 'Content-Type': 'multipart/form-data' });
                    enqueueSnackbar(t('The PDF template was successfully updated!'), {
                        variant: 'success',
                    });
                } else {
                    await API.post('/offer_template_pages', reqBody, { 'Content-Type': 'multipart/form-data' });
                    enqueueSnackbar(t('The PDF template was successfully created!'), {
                        variant: 'success',
                    });
                }

                if (setInitializeData) setInitializeData((prev) => ({ ...prev, tab: 0 }));
                else history.push(configurator.base + `?tab=PDF templates`);
            } catch (err) {
                console.error(err);
                throw err;
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        } finally {
            setLoading(false);
            setInitializeData?.((prev) => ({ ...prev, loading: false }));
        }
    };

    useEffect(() => {
        if (Object.keys(templateInfo).length > 0) {
            setPDFTemplateName(templateInfo.name);
            setUserStartPDF({
                httpHeaders: {
                    'Access-Control-Allow-Origin': '*',
                },
                url: templateInfo?.OfferPdfTemplates[0].url,
            });
            setUserEndPDF({
                httpHeaders: {
                    'Access-Control-Allow-Origin': '*',
                },
                url: templateInfo?.OfferPdfTemplates[1].url,
            });
        }
    }, [templateInfo]);

    return (
        <>
            {loading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="mb-10 max-w-lg">
                        <LabelWrapper label={t('PDF template name')}>
                            <TextField
                                placeholder={t('PDF template name')}
                                value={PDFTemplateName}
                                onChange={(e) => setPDFTemplateName(e.target.value)}
                            />
                        </LabelWrapper>
                    </div>
                    <div className="mb-20 flex gap-2 lg:flex-col lg:gap-16">
                        <div className="flex w-1/2 flex-col items-center justify-center lg:w-full">
                            <div className="group relative">
                                <div className="Example__container">
                                    <div className="Example__container__load"></div>
                                    <div className="Example__container__document">
                                        <Document
                                            file={userStartPDF}
                                            onLoadSuccess={onDocumentLoadSuccessStartPDF}
                                            options={options}
                                            noData={''}
                                            error={
                                                <div className="text-error">{t('Error on loading PDF start page')}</div>
                                            }
                                            loading={
                                                <div className="text-primary-main">
                                                    {t('Start page of the PDF template is loading, please wait!')}
                                                </div>
                                            }
                                        >
                                            <Page pageNumber={pageNumberStartPDF} />
                                        </Document>
                                    </div>
                                </div>
                                {userStartPDF && (
                                    <div
                                        className="absolute flex -translate-y-20 select-none rounded-md bg-blue-50 opacity-0 transition-all duration-200 group-hover:translate-y-0 group-hover:opacity-100 group-hover:shadow-xl"
                                        style={{
                                            zIndex: 999,
                                            bottom: '5%',
                                            left: '41%',
                                        }}
                                    >
                                        <div
                                            className={` cursor-pointer rounded-md p-3 transition-all ${
                                                pageNumberStartPDF === 1
                                                    ? 'text-gray-200'
                                                    : 'text-blue-300 hover:bg-blue-100 hover:text-blue-600'
                                            }`}
                                            onClick={() => {
                                                if (pageNumberStartPDF === 1) return;
                                                else setPageNumberStartPDF(pageNumberStartPDF - 1);
                                            }}
                                        >
                                            <ArrowBackIosIcon />
                                        </div>
                                        <div className="p-3 font-semibold text-primary-main">
                                            {pageNumberStartPDF} of {numPagesStartPDF}
                                        </div>
                                        <div
                                            className={` cursor-pointer  rounded-md p-3 transition-all
                        ${
                            pageNumberStartPDF === numPagesStartPDF
                                ? 'text-gray-200'
                                : 'text-blue-300 hover:bg-blue-100 hover:text-blue-600'
                        }
                        `}
                                            onClick={() => {
                                                if (pageNumberStartPDF === numPagesStartPDF) return;
                                                else setPageNumberStartPDF(pageNumberStartPDF + 1);
                                            }}
                                        >
                                            <ArrowForwardIosIcon />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="mt-4 flex">
                                <FileUploadContainer onUpload={(e) => handlePDFUpload(e, 'start')}>
                                    <Button fullWidth startIcon={<PictureAsPdfIcon />} endIcon={<ExpandLessIcon />}>
                                        {templateId
                                            ? t('Change start page for PDF template')
                                            : t('Add start page for PDF template')}
                                    </Button>
                                </FileUploadContainer>
                            </div>
                        </div>

                        <div className="flex w-1/2 flex-col items-center justify-center lg:w-full">
                            <div className="Example group relative">
                                <div className="Example__container">
                                    <div className="Example__container__load"></div>
                                    <div className="Example__container__document">
                                        <Document
                                            file={userEndPDF}
                                            onLoadSuccess={onDocumentLoadSuccessEndPDF}
                                            options={options}
                                            noData={''}
                                            error={
                                                <div className="text-error">{t('Error on loading PDF end page')}</div>
                                            }
                                            loading={
                                                <div className="text-primary-main">
                                                    {t('End page of the PDF template is loading, please wait!')}
                                                </div>
                                            }
                                        >
                                            <Page pageNumber={pageNumberEndPDF} />
                                        </Document>
                                    </div>
                                </div>
                                {userEndPDF && (
                                    <div
                                        className="absolute flex -translate-y-20 select-none rounded-md bg-blue-50 opacity-0 transition-all duration-200 group-hover:translate-y-0 group-hover:opacity-100 group-hover:shadow-xl"
                                        style={{
                                            zIndex: 999,
                                            bottom: '5%',
                                            left: '41%',
                                        }}
                                    >
                                        <div
                                            className={` cursor-pointer rounded-md p-3 transition-all ${
                                                pageNumberEndPDF === 1
                                                    ? 'text-gray-200'
                                                    : 'text-blue-300 hover:bg-blue-100 hover:text-blue-600'
                                            }`}
                                            onClick={() => {
                                                if (pageNumberEndPDF === 1) return;
                                                else setPageNumberEndPDF(pageNumberEndPDF - 1);
                                            }}
                                        >
                                            <ArrowBackIosIcon />
                                        </div>
                                        <div className="p-3 font-semibold text-primary-main">
                                            {pageNumberEndPDF} of {numPagesEndPDF}
                                        </div>
                                        <div
                                            className={` cursor-pointer  rounded-md p-3 transition-all
                        ${
                            pageNumberEndPDF === numPagesEndPDF
                                ? 'text-gray-200'
                                : 'text-blue-300 hover:bg-blue-100 hover:text-blue-600'
                        }
                        `}
                                            onClick={() => {
                                                if (pageNumberEndPDF === numPagesEndPDF) return;
                                                else setPageNumberEndPDF(pageNumberEndPDF + 1);
                                            }}
                                        >
                                            <ArrowForwardIosIcon />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="mt-4 flex">
                                <FileUploadContainer onUpload={(e) => handlePDFUpload(e, 'end')}>
                                    <Button fullWidth startIcon={<PictureAsPdfIcon />} endIcon={<ExpandMoreIcon />}>
                                        {templateId
                                            ? t('Change end page for PDF template')
                                            : t('Add end page for PDF template')}
                                    </Button>
                                </FileUploadContainer>
                            </div>
                        </div>
                    </div>

                    <div>
                        <Button
                            color="secondary"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() => createOrEditPDFTemplate()}
                        >
                            {templateId ? t('Edit PDF template') : t('Save PDF template')}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

PDFTemplateForm.propTypes = {
    templateInfo: PropTypes.object,
    setInitializeData: PropTypes.func,
};

PDFTemplateForm.defaultProps = {
    templateInfo: {},
    setInitializeData: null,
};

export default PDFTemplateForm;
