import React, { useEffect, useRef, useState } from 'react';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

import { Button, CircularProgress } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import useScreenSizes from 'hooks/useScreenSizes';
import PropTypes from 'prop-types';
import { ActionButton, Tabs } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const RiemExportModal = ({ open, setOpen, riems, disabled, handleExport }) => {
    const { t } = useTranslation();

    const contentRef = useRef(null);

    const [width, height] = useScreenSizes();
    const [selectedExportMode, setSelectedExportMode] = useState(1);
    const exportModeTabs = ['portrait', 'landscape'];

    const [order, setOrder] = useState(
        Array.from({ length: riems.length }, (_, i) => ({ position: i, isActive: true }))
    );

    useEffect(() => {
        let observer = null;

        observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target.getBoundingClientRect().height >= height * 0.6) {
                    contentRef.current.style.overflowY = 'auto';
                    contentRef.current.style.overflowX = 'hidden';
                } else {
                    contentRef.current.style.overflow = 'visible';
                }
            }
        });

        observer.observe(contentRef.current);

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [height]);

    const handleReorder = (start, end) =>
        setOrder((prev) => {
            const newOrder = structuredClone(prev);
            [newOrder[start], newOrder[end]] = [newOrder[end], newOrder[start]];
            return newOrder;
        });

    const handleToggleLanguageStatus = (i) =>
        setOrder((prev) =>
            prev.map((element, index) => (index === i ? { ...element, isActive: !element.isActive } : element))
        );

    return (
        <CustomModal open={open}>
            <div
                className="flex w-full flex-col items-center gap-16 p-10 sm:px-2"
                style={{ width: width > 750 ? '50rem' : '90vw', maxHeight: width > 600 ? '90vh' : '70vh' }}
                ref={contentRef}
            >
                <h2 className="text-center">
                    {t(
                        disabled ? 'Data is generated, please wait and do not close or reload the page!' : 'RIEM export'
                    )}
                </h2>
                {disabled ? (
                    <CircularProgress />
                ) : (
                    <div className="flex w-full flex-col gap-8">
                        <div className="flex w-full items-center justify-center gap-4 rounded-md bg-layout-lighter p-4">
                            <h4>{t('Export in the mode')}:</h4>
                            <Tabs
                                small
                                activeTab={selectedExportMode}
                                setActiveTab={setSelectedExportMode}
                                tabs={exportModeTabs.map((tab) => t(`${tab[0].toUpperCase()}${tab.substring(1)}`))}
                            />
                        </div>

                        <div
                            className="flex flex-col items-center gap-4 overflow-y-auto rounded-md bg-layout-lighter p-4"
                            style={{ maxHeight: '50vh' }}
                        >
                            <h4>{t('Order')}</h4>
                            <div className="flex w-full flex-col items-center gap-4">
                                {order.map((val, index) => {
                                    const riem = riems[val.position];

                                    return (
                                        <div
                                            key={riem}
                                            className="flex w-full items-center justify-between gap-8 rounded-md  bg-layout-transparent-dark px-6 py-2"
                                        >
                                            <p>{riem.name}</p>
                                            <div className="flex gap-2">
                                                <ActionButton
                                                    size={9}
                                                    icon={<ArrowUpwardIcon />}
                                                    disabled={index === 0 || disabled}
                                                    onClick={() => handleReorder(index, index - 1)}
                                                />
                                                <ActionButton
                                                    size={9}
                                                    icon={<ArrowDownwardIcon />}
                                                    disabled={index === riems.length - 1 || disabled}
                                                    onClick={() => handleReorder(index, index + 1)}
                                                />
                                                <ActionButton
                                                    size={9}
                                                    color={val.isActive ? 'var(--error)' : 'var(--success)'}
                                                    icon={val.isActive ? <CloseIcon /> : <CheckIcon />}
                                                    onClick={() => handleToggleLanguageStatus(index)}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex w-full justify-end gap-4">
                    <Button
                        disabled={disabled}
                        onClick={() => setOpen(false)}
                        startIcon={<CancelIcon />}
                        color="secondary"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        startIcon={<CheckCircleIcon />}
                        color="secondary"
                        onClick={() =>
                            handleExport(
                                order.filter((element) => element.isActive),
                                exportModeTabs[selectedExportMode]
                            )
                        }
                        disabled={
                            disabled || riems.length === 0 || order.filter((element) => element.isActive).length === 0
                        }
                    >
                        {t('Complete')}
                    </Button>
                </div>
            </div>
        </CustomModal>
    );
};

RiemExportModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    riems: PropTypes.array,
    disabled: PropTypes.bool,
    handleExport: PropTypes.func,
};

RiemExportModal.defaultProps = {
    open: null,
    setOpen: () => {},
    riems: [],
    disabled: false,
    handleExport: () => {},
};

export default RiemExportModal;
