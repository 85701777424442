import React, { useContext } from 'react';

import LanguageIcon from '@material-ui/icons/Language';

import GlobalContext from 'contexts/GlobalContext';
import useScreenSizes from 'hooks/useScreenSizes.js';
import { useTranslation } from 'react-i18next';

const LangSwitch = () => {
    const { i18n } = useTranslation();
    const { language } = useContext(GlobalContext);

    const [width] = useScreenSizes();

    return (
        <div
            className="flex h-20 w-20 cursor-pointer flex-col items-center md:h-full md:w-auto md:flex-row md:gap-8"
            onClick={() => (language === 'en' ? i18n.changeLanguage('ro') : i18n.changeLanguage('en'))}
        >
            {width > 750 && <LanguageIcon className="text-sidebar-text" />}
            <div className="mt-1.5 flex h-8 w-8 items-center justify-center md:mt-0">
                <p
                    className={`duration-350 text-base font-semibold text-sidebar-text transition-all md:text-3xl ${
                        language !== 'en' ? 'opacity-100' : 'opacity-25'
                    }`}
                >
                    RO
                </p>
            </div>

            <div className="flex h-8 w-8 items-center justify-center">
                <p
                    className={`duration-350 text-base font-semibold text-sidebar-text transition-all md:text-3xl ${
                        language === 'en' ? 'opacity-100' : 'opacity-25'
                    }`}
                >
                    EN
                </p>
            </div>
        </div>
    );
};

export default LangSwitch;
