import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';

import ConfiguratorForm from 'components/shared/configurator/configurator-form';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const EditPartner = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [partnerValues, setPartnerValues] = useState(null);
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '7',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const res = await API.get(`partner`, {
                    params: {
                        id,
                    },
                });
                const parsedData = JSON.parse(res.data.data.data);
                setPartnerValues(parsedData);
            } catch (err) {
                console.error(err);
                history.goBack();
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>{t('Edit partner')}</title>
            </Helmet>

            <Header pageTitle={t('Edit partner')} />

            <div className="page-container">
                {partnerValues !== null && !loading && (
                    <ConfiguratorForm entity="partner" entityId={id} defaultValues={partnerValues} />
                )}
                {loading && <LinearProgress />}
            </div>
        </>
    );
};

export default EditPartner;
