import React from 'react';

import { CircularProgress } from '@material-ui/core';

import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

import EmailTemplateForm from './email_templates_form';

const EditEmailTemplate = ({ initializeData, setInitializeData, handleDecrementStep, handleIncrementStep }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);

    const [templates, setTemplates] = React.useState([]);

    const getTemplates = async () => {
        setLoading(true);
        setInitializeData?.((prev) => ({ ...prev, loading: true }));
        try {
            const response = await API.get('email_template');

            setTemplates([response.data.data]);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
            setInitializeData?.((prev) => ({ ...prev, loading: false }));
        }
    };

    React.useEffect(() => {
        getTemplates();
    }, []);

    if (loading)
        return (
            <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                <CircularProgress />
            </div>
        );

    if (initializeData)
        return (
            <EmailTemplateForm
                template={templates[0]}
                initializeData={initializeData}
                handleDecrementStep={handleDecrementStep}
                handleIncrementStep={handleIncrementStep}
            />
        );

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Edit email template')}</title>
            </Helmet>

            <Header pageTitle={t('Edit email template')} />
            <div className="page-container">
                <EmailTemplateForm template={templates[0]} />
            </div>
        </>
    );
};

EditEmailTemplate.propTypes = {
    initializeData: PropTypes.object,
    setInitializeData: PropTypes.func,
    handleDecrementStep: PropTypes.func,
    handleIncrementStep: PropTypes.func,
};

EditEmailTemplate.defaultProps = {
    initializeData: null,
    setInitializeData: null,
    handleDecrementStep: null,
    handleIncrementStep: null,
};

export default EditEmailTemplate;
