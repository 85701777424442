import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import InvoiceForm from 'components/accountancy/invoice-form';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import API from 'utils/axios';

const EditInvoice = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { permissionMap } = useContext(UserContext);
    const history = useHistory();
    const canAll = permissionMap['Facturi'] > 1;
    const canAllPM = permissionMap['Modul Project Manager'] > 1;

    const { invoiceId } = useParams();

    const [invoiceToEdit, setInvoiceToEdit] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!canAll) history.push('/');

        (async () => {
            try {
                const res = await API.get('/invoice', {
                    params: {
                        id: invoiceId,
                    },
                });

                setInvoiceToEdit(res.data.invoice);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        })();
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>{t('Edit invoice')}</title>
            </Helmet>

            <Header pageTitle={t('Edit invoice')} />

            {loading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <div className="page-container">
                    <InvoiceForm
                        invoiceToEdit={invoiceToEdit}
                        viewOnly={false}
                        isValid={true}
                        displayPmProjects={canAllPM}
                    />
                </div>
            )}
        </>
    );
};

export default EditInvoice;
