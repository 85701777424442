import React, { useContext, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';

import { Button, TextField } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { affiliate, configurator } from 'routes';
import { errorHandling, PASSWORD_REGEX } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const UserPassword = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [repeatPassword, setRepeatPassword] = useState(null);

    const { user } = useContext(UserContext);

    const { enqueueSnackbar } = useSnackbar();

    let schema = yup.object().shape({
        repeatPassword: yup
            .string()
            .trim()
            .typeError(t('Confirmation of new password required!'))
            .matches(newPassword, t("Passwords don't match!")),
        newPassword: yup
            .string()
            .trim()
            .typeError(t('The new password is required!'))
            .min(5, t('New password must be at least 5 characters long!'))
            .matches(
                PASSWORD_REGEX,
                t(
                    'New password must contain a combination of at least 5 small and large characters, at least one number and one of the characters @ # $% *!'
                )
            )
            .required(t('The new password is required!')),
        oldPassword: yup
            .string()
            .trim()
            .typeError(t('The old password is required!'))
            .required(t('The old password is required!')),
    });

    const updatePassword = async () => {
        try {
            await schema.validate({ repeatPassword, newPassword, oldPassword });

            try {
                await API.put('/user_password_change', {
                    oldPassword: oldPassword,
                    password: newPassword,
                    confirmPassword: repeatPassword,
                });

                history.push(configurator.base);

                enqueueSnackbar(t('Password was successfully updated!'), {
                    variant: 'success',
                });
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    return (
        <div className="mt-10 flex w-full justify-between">
            <div className="w-full max-w-xs flex-shrink-0">
                <h4 className="mb-10 text-2xl text-dark-text">{t('Change the password')}</h4>
                <div className="relative mb-5">
                    <TextField
                        name={t('old-password')}
                        type="password"
                        label={t('Old password')}
                        placeholder={t('Add old password')}
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </div>
                <div className="relative mb-5">
                    <TextField
                        name={'new-password'}
                        type="password"
                        label={t('New password')}
                        placeholder={t('Add new password')}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <div className="relative mb-10">
                    <TextField
                        name={'repeat-new-password'}
                        type="password"
                        label={t('Repeat new password')}
                        placeholder={t('Repeat new password')}
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                </div>
                <Button type="submit" startIcon={<CheckIcon />} color="primary" onClick={() => updatePassword()}>
                    {t('Update password')}
                </Button>
            </div>
        </div>
    );
};

export default UserPassword;
