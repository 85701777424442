import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PropTypes from 'prop-types';

const countDimensions = {
    8: 'w-8 h-8',
    10: 'w-10 h-10',
    12: 'w-12 h-12',
    14: 'w-14 h-14',
    16: 'w-16 h-16',
    18: 'w-18 h-18',
    20: 'w-20 h-20',
};

const breakpointClasses = {
    sm: {
        wrapper: 'sm:mb-2',
        title: 'sm:order-2',
        count: 'sm:order-1 sm:ml-0',
        expandWrapper: 'sm:block',
    },
    md: {
        wrapper: 'md:mb-2',
        title: 'md:order-2',
        count: 'md:order-1 md:ml-0',
        expandWrapper: 'md:block',
    },
    lg: {
        wrapper: 'lg:mb-2',
        title: 'lg:order-2',
        count: 'lg:order-1 lg:ml-0',
        expandWrapper: 'lg:block',
    },
    xl: {
        wrapper: 'xl:mb-2',
        title: 'xl:order-2',
        count: 'xl:order-1 xl:ml-0',
        expandWrapper: 'xl:block',
    },
    '2xl': {
        wrapper: '2xl:mb-2',
        title: '2xl:order-2',
        count: '2xl:order-1 2xl:ml-0',
        expandWrapper: '2xl:block',
    },
};

const PipelineLabel = ({ title, count, color, backgroundColor, mobileBP, open, handleToggleOpen }) => {
    return (
        <div
            className={`flex items-center gap-2 rounded-md px-5 py-4 ${breakpointClasses[mobileBP].wrapper}`}
            style={{
                color,
                backgroundColor,
            }}
        >
            <p className={`order-1 text-2xl ${breakpointClasses[mobileBP].title}`}>{title}</p>
            <p
                className={`order-2 ml-auto flex ${
                    countDimensions[count.toString().length > 2 ? (count.toString().length - 2) * 2 + 8 : 8]
                } flex-shrink-0 items-center justify-center rounded-full border border-main-text text-lg leading-none ${breakpointClasses[mobileBP].count}`}
            >
                {count}
            </p>
            <div
                className={`order-3 ml-auto hidden transform cursor-pointer transition-transform duration-300 ${
                    open ? 'rotate-180' : 'rotate-0'
                } ${breakpointClasses[mobileBP].expandWrapper}`}
                onClick={handleToggleOpen}
            >
                <ExpandMoreIcon fontSize="large" />
            </div>
        </div>
    );
};

PipelineLabel.propTypes = {
    title: PropTypes.string,
    count: PropTypes.number,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    mobileBP: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
    open: PropTypes.bool,
    handleToggleOpen: PropTypes.func,
};

PipelineLabel.defaultProps = {
    title: '',
    count: 0,
    color: 'var(--main-text)',
    backgroundColor: 'var(--primary-main)',
    mobileBP: 'sm',
    open: false,
    handleToggleOpen: () => {},
};

export default PipelineLabel;
