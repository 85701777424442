import React from 'react';

const NotFound = () => {
    return (
        <div className="no-user-select-recursive pointer-events-none flex h-svh w-screen flex-col items-center justify-center bg-layout-main">
            <h1 className="mb-4 text-9xl font-bold text-blue-500">404</h1>
            <p className="text-lg text-white opacity-50">Page not found or Domain name is non existent.</p>
        </div>
    );
};

export default NotFound;
