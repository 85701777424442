import React, { createContext, useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';

import { CircularProgress } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { AccountingAccounts } from 'api/Accounting_Accounts';
import { Revenues } from 'api/Expenses_Revenues';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { errorHandling, formatPositiveNumberWithDigits, toLocaleNumber } from 'utils';

const BudgetaryContext = createContext();

const GrandparentRevenueRow = ({ revenue }) => {
    const { accounts, isEdit } = useContext(BudgetaryContext);

    const accountingAccount = accounts[accounts.findIndex((account) => account.id === revenue.accountingAccountId)];

    // const parentList = isEdit
    //     ? revenue.subRevenueNames
    //     : revenue.subRevenueNames.filter((subRev) => subRev.isDeleted === false);

    const parentList = revenue.subRevenueNames.filter((subRev) => subRev.isDeleted === false);

    return (
        <div className="budgetary-border-selector relative">
            <div
                className={`flex w-full items-center gap-4 rounded-t-md p-4`}
                style={{
                    backgroundColor: revenue.isDeleted ? 'rgb(var(--base-error) / 40%)' : 'var(--layout-transparent)',
                }}
            >
                <div className="flex w-full items-center gap-4 p-4">
                    {/* Grandparent revenue name */}
                    <div className="flex-grow">
                        <p className="break-all text-2xl font-extrabold text-main-text">{revenue.name}</p>
                        <p className="text-sm">
                            <span className="text-primary-light">{accountingAccount.code}</span> -{' '}
                            <span className="text-dark-text">{accountingAccount.name}</span>
                        </p>
                    </div>
                </div>
            </div>

            {/* Map of the parent revenues */}
            <div>
                {parentList.map((parentRevenue) => (
                    <ParentRevenueRow key={parentRevenue.id} parentRevenue={parentRevenue} />
                ))}
            </div>
        </div>
    );
};

GrandparentRevenueRow.propTypes = {
    revenue: PropTypes.object,
};

const ParentRevenueRow = ({ parentRevenue }) => {
    const {
        accounts,
        checkedRevenues,
        setCheckedRevenues,
        currencyObj,
        referenceCurrencyObj,
        isEdit,
        exchangeRate,
        disableInputs,
    } = useContext(BudgetaryContext);

    const accountingAccount =
        accounts[accounts.findIndex((account) => account.id === parentRevenue.accountingAccountId)];

    const isChecked = checkedRevenues.list.findIndex((r) => r.id === parentRevenue.id) >= 0;
    const canDisplayCheck = isChecked && parentRevenue.isDeleted ? true : parentRevenue.isDeleted ? false : true;

    const handleCheckRevenue = () => {
        if (isChecked) {
            setCheckedRevenues((prev) => ({
                ...prev,
                list: prev.list.filter((r) => r.id !== parentRevenue.id),
            }));
        } else {
            setCheckedRevenues((prev) => ({
                ...prev,
                list: [...prev.list, { id: parentRevenue.id, value: 0, secondCurrencyValue: 0 }],
            }));
        }
    };

    const handleUpdateRevenueValue = (value) => {
        setCheckedRevenues((prev) => ({
            ...prev,
            list: prev.list.map((r) =>
                r.id === parentRevenue.id ? { ...r, value, secondCurrencyValue: value / exchangeRate } : r
            ),
        }));
    };

    // const childList = isEdit
    //     ? parentRevenue.subRevenueNames
    //     : parentRevenue.subRevenueNames.filter((subRev) => subRev.isDeleted === false);

    const childList = parentRevenue.subRevenueNames.filter((subRev) => subRev.isDeleted === false);

    return (
        <div className={`budgetary-parent-selector relative border-t border-secondary-main`}>
            <div
                className={`flex w-full items-center gap-4 p-4`}
                style={{
                    backgroundColor:
                        isChecked && parentRevenue.isDeleted
                            ? 'color-mix(in srgb, rgb(var(--base-secondary-light) / 20%), rgb(var(--base-error) / 40%))'
                            : isChecked
                            ? 'rgb(var(--base-secondary-light) / 20%)'
                            : parentRevenue.isDeleted
                            ? 'rgb(var(--base-error) / 40%)'
                            : '',
                }}
            >
                {/* Parent expense name */}
                <div className="ml-6 flex-grow">
                    <p className="break-all text-xl font-bold text-main-text">{parentRevenue.name}</p>
                    <p className="text-sm">
                        <span className="text-primary-light">{accountingAccount.code}</span> -{' '}
                        <span className="text-dark-text">{accountingAccount.name}</span>
                    </p>
                </div>
                {isChecked && (
                    <div className="flex flex-col gap-2" style={{ minWidth: '10rem' }}>
                        <LocaleTextField
                            disabled={disableInputs}
                            placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                            value={checkedRevenues.list.find((r) => r.id === parentRevenue.id)?.value}
                            onChange={(e) => {
                                const value = formatPositiveNumberWithDigits(e.target.value);

                                handleUpdateRevenueValue(value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                            }}
                        />

                        <LocaleTextField
                            placeholder={`${toLocaleNumber(100, 2)} ${referenceCurrencyObj.currency}`}
                            value={checkedRevenues.list.find((r) => r.id === parentRevenue.id)?.secondCurrencyValue}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">{referenceCurrencyObj.currency}</InputAdornment>
                                ),
                            }}
                        />
                    </div>
                )}
                {canDisplayCheck && (
                    <div className="w-13 ml-8">
                        <div
                            className={`group flex cursor-pointer items-center justify-center rounded-full border border-secondary-lighter p-1 transition-all duration-150 hover:border-secondary-main`}
                            onClick={handleCheckRevenue}
                        >
                            <CheckCircleIcon
                                style={{ fontSize: '15px' }}
                                className="text-secondary-lighter transition-all duration-150 group-hover:text-secondary-main"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Map of the children expenses */}
            <div>
                {childList.map((childRevenue) => (
                    <ChildRevenueRow key={childRevenue.id} childRevenue={childRevenue} />
                ))}
            </div>
        </div>
    );
};

ParentRevenueRow.propTypes = {
    parentRevenue: PropTypes.object,
};

const ChildRevenueRow = ({ childRevenue }) => {
    const {
        accounts,
        checkedRevenues,
        setCheckedRevenues,
        currencyObj,
        referenceCurrencyObj,
        exchangeRate,
        disableInputs,
    } = useContext(BudgetaryContext);

    const accountingAccount =
        accounts[accounts.findIndex((account) => account.id === childRevenue.accountingAccountId)];

    const isChecked = checkedRevenues.list.findIndex((r) => r.id === childRevenue.id) >= 0;
    const canDisplayCheck = isChecked && childRevenue.isDeleted ? true : childRevenue.isDeleted ? false : true;

    const handleCheckRevenue = () => {
        if (isChecked) {
            setCheckedRevenues((prev) => ({
                ...prev,
                list: prev.list.filter((r) => r.id !== childRevenue.id),
            }));
        } else {
            setCheckedRevenues((prev) => ({
                ...prev,
                list: [...prev.list, { id: childRevenue.id, value: 0, secondCurrencyValue: 0 }],
            }));
        }
    };

    const handleUpdateRevenueValue = (value) => {
        setCheckedRevenues((prev) => ({
            ...prev,
            list: prev.list.map((r) =>
                r.id === childRevenue.id ? { ...r, value, secondCurrencyValue: value / exchangeRate } : r
            ),
        }));
    };

    return (
        <div
            className={`budgetary-child-selector relative border-t border-secondary-main`}
            style={{
                backgroundColor:
                    isChecked && childRevenue.isDeleted
                        ? 'color-mix(in srgb, rgb(var(--base-secondary-light) / 20%), rgb(var(--base-error) / 40%))'
                        : isChecked
                        ? 'rgb(var(--base-secondary-light) / 20%)'
                        : childRevenue.isDeleted
                        ? 'rgb(var(--base-error) / 40%)'
                        : '',
            }}
        >
            <div className="flex w-full items-center gap-4 p-4">
                <div className="ml-12 flex-grow">
                    <p className="break-all text-lg text-main-text">{childRevenue.name}</p>
                    <p className="text-sm">
                        <span className="text-primary-light">{accountingAccount.code}</span> -{' '}
                        <span className="text-dark-text">{accountingAccount.name}</span>
                    </p>
                </div>
                {isChecked && (
                    <div className="flex flex-col gap-2" style={{ minWidth: '10rem' }}>
                        <LocaleTextField
                            disabled={disableInputs}
                            placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                            value={checkedRevenues.list.find((r) => r.id === childRevenue.id)?.value}
                            onChange={(e) => {
                                const value = formatPositiveNumberWithDigits(e.target.value);

                                handleUpdateRevenueValue(value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                            }}
                        />

                        <LocaleTextField
                            placeholder={`${toLocaleNumber(100, 2)} ${referenceCurrencyObj.currency}`}
                            value={checkedRevenues.list.find((r) => r.id === childRevenue.id)?.secondCurrencyValue}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">{referenceCurrencyObj.currency}</InputAdornment>
                                ),
                            }}
                        />
                    </div>
                )}
                {canDisplayCheck && (
                    <div className="w-13 ml-8">
                        <div
                            className={`group flex cursor-pointer items-center justify-center rounded-full border border-secondary-lighter p-1 transition-all duration-150 hover:border-secondary-main `}
                            onClick={handleCheckRevenue}
                        >
                            <CheckCircleIcon
                                style={{ fontSize: '15px' }}
                                className="text-secondary-lighter transition-all duration-150 group-hover:text-secondary-main"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

ChildRevenueRow.propTypes = {
    childRevenue: PropTypes.object,
};

const InvoiceRevenueBudget = (props) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { checkedRevenues, setCheckedRevenues, isEdit, viewOnly, exchangeRate, disableInputs } = props;

    const { currencyObj, referenceCurrencyObj } = useContext(GlobalContext);

    const [selectedRevenue, setSelectedRevenue] = useState(null);

    const [canRender, setCanRender] = useState(false);
    const [allRevenues, setAllRevenues] = useState([]);
    const [accounts, setAccounts] = useState([]);

    const getAllRevenues = async () => {
        await Revenues.get(999999, 0).then((res) => {
            if (res.ok) {
                const revenues = res.data.revenuName;
                setAllRevenues(revenues);

                if (isEdit || checkedRevenues.list.length > 0) {
                    setSelectedRevenue(revenues.findIndex((revenue) => revenue.id === checkedRevenues.grandParentId));
                }
            } else {
                console.error(res.error);
            }
        });
    };

    const getAllAccountingAccounts = async () => {
        await AccountingAccounts.getAll().then((res) => {
            if (res.ok) {
                setAccounts(res.data);
            } else {
                console.error(res.error);
                enqueueSnackbar(
                    errorHandling(res.error).length > 100 ? errorHandling(res.error) : t(errorHandling(res.error)),
                    {
                        variant: 'error',
                    }
                );
            }
        });
    };

    const fetchData = async () => {
        try {
            await Promise.all([getAllRevenues(), getAllAccountingAccounts()]);

            setCanRender(true);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return canRender ? (
        <BudgetaryContext.Provider
            value={{
                accounts,
                checkedRevenues,
                setCheckedRevenues,
                isEdit,
                currencyObj,
                referenceCurrencyObj,
                exchangeRate,
                disableInputs,
            }}
        >
            <div className="mb-10 flex max-w-2xl  flex-col items-start">
                {!viewOnly &&
                    (() => {
                        // const options = isEdit
                        //     ? allRevenues
                        //     : allRevenues.filter((revenue) => revenue.isDeleted === false);

                        const options = allRevenues.filter((revenue) => revenue.isDeleted === false);

                        return (
                            <div className="relative mb-6 mt-2 inline-block">
                                <Dropdown
                                    variant="green"
                                    icon={
                                        selectedRevenue !== null ? (
                                            <EditIcon className="text-buttons-text" />
                                        ) : (
                                            <AddIcon className="text-buttons-text" />
                                        )
                                    }
                                    placeholder={selectedRevenue !== null ? t('Change revenue') : t('Add revenue')}
                                    options={options.map((name) => name.name)}
                                    selectedOption={null}
                                    setSelectedOption={(i) => {
                                        if (i === selectedRevenue) return;

                                        setSelectedRevenue(i);
                                        setCheckedRevenues({
                                            grandParentId: allRevenues[i].id,
                                            list: [],
                                        });
                                    }}
                                />
                            </div>
                        );
                    })()}
                {selectedRevenue !== null && selectedRevenue !== -1 && (
                    <div className={`w-full rounded-md border border-secondary-light`} key={selectedRevenue}>
                        <GrandparentRevenueRow revenue={allRevenues[selectedRevenue]} />
                    </div>
                )}
            </div>
        </BudgetaryContext.Provider>
    ) : (
        <CircularProgress />
    );
};

InvoiceRevenueBudget.propTypes = {
    checkedRevenues: PropTypes.object,
    setCheckedRevenues: PropTypes.func,
    isEdit: PropTypes.bool,
    viewOnly: PropTypes.bool,
    disableInputs: PropTypes.bool,
    exchangeRate: PropTypes.number,
};

InvoiceRevenueBudget.defaultProps = {
    checkedRevenues: {},
    setCheckedRevenues: () => null,
    isEdit: false,
    viewOnly: false,
    disableInputs: false,
    exchangeRate: 0,
};

export default InvoiceRevenueBudget;
