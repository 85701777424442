import React, { useContext } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

import { TextField } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';

const ModalDocRow = (props) => {
    const { file, index, setModalFiles } = props;
    const { t } = useTranslation();
    const { theme } = useContext(GlobalContext);

    const handleChange = (e) => {
        setModalFiles((prev) => {
            const oldArray = _.cloneDeep(prev);

            oldArray[index].name = e.target.value;

            return oldArray;
        });
    };

    const deleteRow = () => {
        setModalFiles((prev) => {
            const updatedArray = _.cloneDeep(prev);
            updatedArray.splice(index, 1);

            return updatedArray;
        });
    };

    return (
        <div className="flex gap-8 rounded-md bg-layout-transparent-dark px-6 py-2">
            <div className="flex w-full items-center justify-between gap-12 p-3 md:gap-4 sm:flex-col sm:items-start">
                <div>
                    <p className="whitespace-nowrap font-bold">
                        <span className="font-normal">{`${t('File number')}:`}</span>
                        {'  '}
                        {index + 1}
                    </p>
                </div>
                <div style={{ minWidth: '14rem' }}>
                    <TextField
                        name={`${index}`}
                        type="text"
                        placeholder={t('Enter file name')}
                        label={t('File name')}
                        value={file.name}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="self-center">
                <ActionButton
                    size={9}
                    icon={<DeleteIcon />}
                    color={`rgb(${theme['STATE-COLORS']['ERROR'].color})`}
                    onClick={() => deleteRow()}
                />
            </div>
        </div>
    );
};

ModalDocRow.propTypes = {
    index: PropTypes.number,
    file: PropTypes.object,
    setModalFiles: PropTypes.func,
};

ModalDocRow.defaultProps = {
    index: 0,
    file: {},
    setModalFiles: () => null,
};

export default ModalDocRow;
