import React from 'react';

import DepartmentDetails from 'components/crm/departments/department-details';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const AddPmDepartment = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Add new Project Management department')}</title>
            </Helmet>

            <Header pageTitle={t('Add new Project Management department')} />
            <div className="page-container">
                <DepartmentDetails area={'PM'} />
            </div>
        </>
    );
};

export default AddPmDepartment;
