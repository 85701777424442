import React, { createContext, useContext, useState } from 'react';

import { Button } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ConfirmContext = createContext();

export function ConfirmProvider({ children }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [callback, setCallback] = useState(() => {});

    function confirm(title, callback) {
        setTitle(title);
        setCallback(() => callback);
        setOpen(true);
    }

    return (
        <ConfirmContext.Provider value={confirm}>
            {children}

            <div
                className="fixed left-0 top-0 flex h-svh w-full items-center justify-center overflow-hidden transition-all duration-300"
                style={{
                    pointerEvents: open ? 'all' : 'none',
                    opacity: open ? '1' : '0',
                    background: 'rgba(0, 0, 0, .8)',
                    backdropFilter: 'blur(5px)',
                    zIndex: 9999,
                }}
            >
                <div className="flex-col">
                    <p className="mb-8 text-center text-2xl font-bold text-white">{title}</p>

                    <div className="flex items-center justify-center">
                        <Button
                            color="primary"
                            onClick={() => {
                                callback();
                                setOpen(false);
                            }}
                        >
                            {t('Yes')}
                        </Button>

                        <div className="w-4" />

                        <Button onClick={() => setOpen(false)}>{t('No')}</Button>
                    </div>
                </div>
            </div>
        </ConfirmContext.Provider>
    );
}

ConfirmProvider.propTypes = {
    children: PropTypes.object,
};

export default function useConfirm() {
    return useContext(ConfirmContext);
}
