import React from 'react';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import PropTypes from 'prop-types';

const InitializeStepInfo = ({ mainInfo, secondaryInfo, className }) => {
    return (
        <div className={`flex flex-col gap-1 ${className}`}>
            <div className="flex flex-col gap-1">
                {mainInfo.map((info) => (
                    <div className="flex items-center gap-2" key={info.id}>
                        <ErrorOutlineIcon className="text-warning" />
                        <p className="text-lg text-warning">{info.content}</p>
                    </div>
                ))}
            </div>
            <div className="flex flex-col gap-1">
                {secondaryInfo.map((info) => (
                    <div className="flex items-center gap-2" key={info.id}>
                        <ErrorOutlineIcon className="text-main" />
                        <p className="text-main text-lg">{info.content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

InitializeStepInfo.propTypes = {
    className: PropTypes.string,
    mainInfo: PropTypes.array,
    secondaryInfo: PropTypes.array,
};

InitializeStepInfo.defaultProps = {
    className: '',
    mainInfo: [],
    secondaryInfo: [],
};

export default InitializeStepInfo;
