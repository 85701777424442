import React from 'react';

import { PropTypes } from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import PDFTemplateForm from './pdf-template-form';

const CreatePDFTemplate = ({ setInitializeData }) => {
    const { t } = useTranslation();

    if (setInitializeData) return <PDFTemplateForm setInitializeData={setInitializeData} />;

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Add new PDF template')}</title>
            </Helmet>

            <Header pageTitle={t('Add new PDF template')} />
            <div className="page-container">
                <PDFTemplateForm />
            </div>
        </>
    );
};

CreatePDFTemplate.propTypes = {
    setInitializeData: PropTypes.func,
};

CreatePDFTemplate.defaultProps = {
    setInitializeData: null,
};

export default CreatePDFTemplate;
