import React from 'react';

import PanToolIcon from '@material-ui/icons/PanTool';

import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import DocumentItem from './document-item';

const ConfiguratorColumn = ({ column, elements, isMultiColumn, index }) => {
    const isMainColumn = column.id.includes('column-main');

    return (
        <Draggable draggableId={column.id} index={index}>
            {(provided) => {
                return (
                    <div className="relative w-full" {...provided.draggableProps} ref={provided.innerRef}>
                        <div
                            {...provided.dragHandleProps}
                            className={`focus:outline-black group absolute -top-10 left-1/2 flex h-12 w-max -translate-x-1/2 transform flex-col items-center justify-center ${
                                !isMultiColumn ? 'hidden' : ''
                            }`}
                        >
                            <div className={`flex items-center gap-2`}>
                                <PanToolIcon className="h-6 w-max transform rounded-full text-layout-lighter transition-colors last:mb-0 group-hover:text-layout-light" />

                                <p className="truncate text-base text-layout-lighter group-hover:text-layout-light sm:text-xs">
                                    {column.title}
                                </p>
                            </div>
                        </div>

                        <Droppable
                            droppableId={column.id}
                            key={column.id}
                            isDropDisabled={!isMainColumn}
                            type="elements"
                        >
                            {(provided, snapshot) => {
                                return (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{
                                            minHeight: '50vh',
                                        }}
                                        className={`flex h-full flex-col gap-4 p-2 ${
                                            isMultiColumn ? 'rounded-sm border border-layout-lighter' : ''
                                        } ${snapshot.isDraggingOver ? 'rounded-md bg-disabled' : 'bg-dark-text'}`}
                                    >
                                        {elements.map((element, index) => (
                                            <DocumentItem
                                                key={element.id}
                                                itemData={element}
                                                index={index}
                                                isDragDisabled={!isMainColumn}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </div>
                );
            }}
        </Draggable>
    );
};

ConfiguratorColumn.propTypes = {
    column: PropTypes.object,
    elements: PropTypes.array,
    isMultiColumn: PropTypes.boolean,
    index: PropTypes.number,
};

ConfiguratorColumn.defaultProps = {
    column: null,
    elements: [],
    isMultiColumn: false,
    index: 0,
};

export default ConfiguratorColumn;
