import React, { useState } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Button } from '@material-ui/core';

import DOMPurify from 'dompurify';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useScreenSizes from 'hooks/useScreenSizes';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';

/**
 * ? This component is used to update a element with rich text content
 * @param {object} props - Component properties
 * @param {Function} props.onClose - Closes the modal (all changes will be lost). It's a callback, as such it can be used for more this
 * @param {Function} props.onFinish - Updates the element with the new value. It's a callback, as such it can be used for more this
 * @param {string} props.value - The original value
 */
const EditElementModal = ({ onClose, onFinish, value }) => {
    const { t } = useTranslation();
    const [width] = useScreenSizes();

    /**
     * ? Converts the html string to a Editor State object in order to use it with the Editor component
     * @param {string} html - a string with the html content
     * @returns {object} Editor State object
     */
    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const [editableValue, setEditableValue] = useState(htmlToDraftBlocks(value));

    const handleOnFinish = () => {
        const newValue = DOMPurify.sanitize(
            draftToHtml(convertToRaw(editableValue.getCurrentContent())).replaceAll('color: currentcolor;', '')
        );

        onFinish(newValue);
    };

    return (
        <div className="flex flex-col items-center gap-8 p-10" style={{ width: width > 600 ? '80vw' : '90vw' }}>
            <h2>{t('Edit text')}</h2>

            <div style={{ backgroundColor: 'var(--layout-light)' }} className={`rounded-md p-4`}>
                <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorState={editableValue}
                    onEditorStateChange={setEditableValue}
                    toolbarStyle={{
                        margin: 0,
                        padding: '0.5rem',
                        borderRadius: 0,
                        color: 'black',
                        backgroundColor: 'var(--layout-lighter)',
                        border: 'none',
                    }}
                    wrapperStyle={{
                        width: '100%',
                    }}
                    editorStyle={{
                        borderRadius: 0,
                        backgroundColor: 'white',
                        padding: '0px 8px',
                        width: width > 600 ? 'calc(80vw - 5rem)' : 'calc(90vw - 5rem)',
                        minHeight: '35rem',
                        maxHeight: width <= 750 ? '' : '40rem',
                        lineHeight: 1.2,
                        color: 'black',
                    }}
                    toolbar={{
                        options: [
                            'inline',
                            'blockType',
                            'fontSize',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'remove',
                            'history',
                        ],
                    }}
                />
            </div>

            <div className="flex w-full justify-end gap-4">
                <Button onClick={onClose} startIcon={<CancelIcon />} color="secondary">
                    {t('Cancel')}
                </Button>
                <Button
                    onClick={handleOnFinish}
                    startIcon={<CheckCircleIcon />}
                    color="secondary"
                    className="focus:outline-none disabled:!bg-slate-600 disabled:!text-slate-400"
                >
                    {t('Finish edit')}
                </Button>
            </div>
        </div>
    );
};

EditElementModal.propTypes = {
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    value: PropTypes.string,
};

EditElementModal.defaultProps = {
    onClose: () => {},
    onFinish: () => {},
    value: '',
};

export default EditElementModal;
