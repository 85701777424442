import React, { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CircularProgress } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { AccountingAccounts } from 'api/Accounting_Accounts';
import { Expenses } from 'api/Expenses_Revenues';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { errorHandling, formatPositiveNumberWithDigits, toLocaleNumber } from 'utils';
import API from 'utils/axios';

const BudgetaryContext = createContext();

const GrandparentExpenseRow = ({ expense, withFilter, checkKey }) => {
    const { accounts, checkedExpenses, isBudgetCase } = useContext(BudgetaryContext);
    const [open, setOpen] = useState(checkedExpenses.grandParentId === expense.id ? true : false);

    const accountingAccount = accounts[accounts.findIndex((account) => account.id === expense.accountingAccountsId)];

    const isDisabled =
        (expense.id !== checkedExpenses.grandParentId && checkedExpenses.list.length > 0) ||
        (expense.id === checkedExpenses.grandParentId &&
            checkedExpenses.list.length > 0 &&
            isBudgetCase &&
            checkKey !== 'idExpense') ||
        (expense.id === checkedExpenses.grandParentId &&
            checkedExpenses.list.length > 0 &&
            !isBudgetCase &&
            checkKey !== 'id');

    let parentList = [];

    // if (withFilter && !isEdit) {
    //     parentList = expense.subExpensesName.filter((subRev) => subRev.isDeleted === false);
    // } else {
    //     parentList = expense.subExpensesName;
    // }

    parentList = expense.subExpensesName.filter((subRev) => subRev.isDeleted === false);

    return (
        <div className="budgetary-border-selector relative">
            <div
                className={`flex w-full items-center gap-4 rounded-t-md p-4 ${!open ? 'budgetary-border-bottom' : ''}`}
                style={{
                    backgroundColor: expense.isDeleted ? 'rgb(var(--base-error) / 40%)' : 'var(--layout-transparent)',
                }}
            >
                <div className="flex w-full items-center gap-4 p-4">
                    {/* Grandparent expense name */}
                    <div className="flex-grow">
                        <p className="break-all text-2xl font-extrabold text-main-text">{expense.name}</p>
                        <p className="text-sm">
                            <span className="text-primary-light">{accountingAccount.code}</span> -{' '}
                            <span className="text-dark-text">{accountingAccount.name}</span>
                        </p>
                    </div>
                </div>

                <div className="w-13 ml-8">
                    <div
                        className={`group flex cursor-pointer items-center justify-center rounded-full border border-success-light p-1 transition-all duration-150 hover:border-success ${
                            open ? 'rotate-180 transform' : ''
                        }`}
                        onClick={() => setOpen(!open)}
                    >
                        <ExpandMoreIcon
                            style={{ fontSize: '15px' }}
                            className={`text-success-light transition-all duration-150 group-hover:text-success`}
                        />
                    </div>
                </div>
            </div>

            {/* Map of the parent expenses */}
            <div className={isDisabled ? 'pointer-events-none flex flex-col' : ''}>
                {parentList.map((parentExpense) => (
                    <ParentExpenseRow
                        key={parentExpense.id}
                        parentExpense={parentExpense}
                        expense={expense}
                        withFilter={withFilter}
                        open={open}
                        checkKey={checkKey}
                        isDisabled={isDisabled}
                    />
                ))}
            </div>
        </div>
    );
};

GrandparentExpenseRow.propTypes = {
    expense: PropTypes.object,
    withFilter: PropTypes.bool,
    checkKey: PropTypes.string,
};

const ParentExpenseRow = ({ parentExpense, expense, open, checkKey, isDisabled }) => {
    const {
        accounts,
        checkedExpenses,
        setCheckedExpenses,
        setIsBudgetCase,
        currencyObj,
        referenceCurrencyObj,
        exchangeRate,
        disableInputs,
    } = useContext(BudgetaryContext);

    const memoizedCheckedExpensesList = useMemo(() => checkedExpenses.list, [checkedExpenses.list]);

    const [elementHeight, setElementHeight] = useState(0);

    const elementId = `${expense.id}-${parentExpense[checkKey]}`;

    const accountingAccount =
        accounts[accounts.findIndex((account) => account.id === parentExpense.accountingAccountsId)];

    const isChecked = memoizedCheckedExpensesList.findIndex((r) => r.id === parentExpense[checkKey]) >= 0;
    const canDisplayCheck = isChecked && parentExpense.isDeleted ? true : parentExpense.isDeleted ? false : true;

    let childList = [];

    // if (withFilter && !isEdit) {
    //     childList = parentExpense.subExpensesName.filter((subRev) => subRev.isDeleted === false);
    // } else {
    //     childList = parentExpense.subExpensesName;
    // }

    childList = parentExpense.subExpensesName.filter((subRev) => subRev.isDeleted === false);

    const handleChangeBudgetary = () => {
        if (checkKey === 'id') setIsBudgetCase?.(false);
        else if (checkKey === 'idExpense') setIsBudgetCase?.(true);
    };

    const handleCheckExpense = () => {
        if (isChecked) {
            setCheckedExpenses((prev) => ({
                grandParentId: memoizedCheckedExpensesList.length >= 2 ? prev.grandParentId : null,
                list: prev.list.filter((r) => r.id !== parentExpense[checkKey]),
            }));
        } else {
            setCheckedExpenses((prev) => ({
                grandParentId: expense.id,
                list: [
                    ...prev.list,
                    {
                        id: parentExpense[checkKey],
                        value: 0,
                        secondCurrencyValue: 0,
                    },
                ],
            }));

            handleChangeBudgetary();
        }
    };

    const handleUpdateExpenseValue = (value) => {
        setCheckedExpenses((prev) => ({
            ...prev,
            list: prev.list.map((r) =>
                r.id === parentExpense[checkKey] ? { ...r, value, secondCurrencyValue: value / exchangeRate } : r
            ),
        }));
    };

    const handleResize = (node) => {
        if (!node) return;

        const elementData = node.scrollHeight;
        setElementHeight(elementData);
    };

    return (
        <div
            className={`budgetary-parent-selector relative transition-all duration-200 ease-in-out ${
                open ? `visible border-t border-secondary-main` : 'invisible max-h-0'
            } ${open && !isDisabled ? 'opacity-100' : 'opacity-0'} ${open && isDisabled ? 'opacity-50' : 'opacity-0'} `}
            style={{
                maxHeight: open ? `${elementHeight}px` : '',
            }}
            id={elementId}
            ref={handleResize}
        >
            <div
                className={`flex w-full items-center gap-4 p-4`}
                style={{
                    backgroundColor:
                        isChecked && parentExpense.isDeleted
                            ? 'color-mix(in srgb, rgb(var(--base-secondary-light) / 20%), rgb(var(--base-error) / 40%))'
                            : isChecked
                            ? 'rgb(var(--base-secondary-light) / 20%)'
                            : parentExpense.isDeleted
                            ? 'rgb(var(--base-error) / 40%)'
                            : '',
                }}
            >
                {/* Parent expense name */}
                <div className="ml-6 flex-grow">
                    <p className="break-all text-xl font-bold text-main-text">{parentExpense.name}</p>
                    <p className="text-sm">
                        <span className="text-primary-light">{accountingAccount.code}</span> -{' '}
                        <span className="text-dark-text">{accountingAccount.name}</span>
                    </p>
                </div>
                {isChecked && (
                    <div className="flex flex-col gap-2" style={{ minWidth: '10rem' }}>
                        <LocaleTextField
                            disabled={disableInputs}
                            placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                            value={memoizedCheckedExpensesList.find((r) => r.id === parentExpense[checkKey])?.value}
                            onChange={(e) => {
                                const value = formatPositiveNumberWithDigits(e.target.value);

                                handleUpdateExpenseValue(value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                            }}
                        />

                        <LocaleTextField
                            placeholder={`${toLocaleNumber(100, 2)} ${referenceCurrencyObj.currency}`}
                            value={
                                memoizedCheckedExpensesList.find((r) => r.id === parentExpense[checkKey])
                                    ?.secondCurrencyValue
                            }
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">{referenceCurrencyObj.currency}</InputAdornment>
                                ),
                            }}
                        />
                    </div>
                )}
                {canDisplayCheck && (
                    <div className="w-13 ml-8">
                        <div
                            className={`group flex cursor-pointer items-center justify-center rounded-full border border-secondary-lighter p-1 transition-all duration-150 hover:border-secondary-main `}
                            onClick={handleCheckExpense}
                        >
                            <CheckCircleIcon
                                style={{ fontSize: '15px' }}
                                className="text-secondary-lighter transition-all duration-150 group-hover:text-secondary-main"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Map of the children expenses */}
            <div>
                {childList.map((childExpense) => (
                    <ChildExpenseRow
                        key={childExpense.id}
                        childExpense={childExpense}
                        expense={expense}
                        checkKey={checkKey}
                    />
                ))}
            </div>
        </div>
    );
};

ParentExpenseRow.propTypes = {
    parentExpense: PropTypes.object,
    expense: PropTypes.object,
    withFilter: PropTypes.bool,
    open: PropTypes.bool,
    checkKey: PropTypes.string,
    isDisabled: PropTypes.bool,
};

const ChildExpenseRow = ({ childExpense, expense, checkKey }) => {
    const {
        accounts,
        checkedExpenses,
        setCheckedExpenses,
        setIsBudgetCase,
        currencyObj,
        referenceCurrencyObj,
        exchangeRate,
        disableInputs,
    } = useContext(BudgetaryContext);

    const accountingAccount =
        accounts[accounts.findIndex((account) => account.id === childExpense.accountingAccountsId)];

    const isChecked = checkedExpenses.list.findIndex((r) => r.id === childExpense[checkKey]) >= 0;
    const canDisplayCheck = isChecked && childExpense.isDeleted ? true : childExpense.isDeleted ? false : true;

    const handleChangeBudgetary = () => {
        if (checkKey === 'id') setIsBudgetCase?.(false);
        else if (checkKey === 'idExpense') setIsBudgetCase?.(true);
    };

    const handleCheckExpense = () => {
        if (isChecked) {
            setCheckedExpenses((prev) => ({
                grandParentId: checkedExpenses.list.length >= 2 ? prev.grandParentId : null,
                list: prev.list.filter((r) => r.id !== childExpense[checkKey]),
            }));
        } else {
            setCheckedExpenses((prev) => ({
                grandParentId: expense.id,
                list: [
                    ...prev.list,
                    {
                        id: childExpense[checkKey],
                        value: 0,
                        secondCurrencyValue: 0,
                    },
                ],
            }));

            handleChangeBudgetary();
        }
    };

    const handleUpdateExpenseValue = (value) => {
        setCheckedExpenses((prev) => ({
            ...prev,
            list: prev.list.map((r) =>
                r.id === childExpense[checkKey] ? { ...r, value, secondCurrencyValue: value / exchangeRate } : r
            ),
        }));
    };

    return (
        <div
            className="budgetary-child-selector relative border-t border-secondary-main"
            style={{
                backgroundColor:
                    isChecked && childExpense.isDeleted
                        ? 'color-mix(in srgb, rgb(var(--base-secondary-light) / 20%), rgb(var(--base-error) / 40%))'
                        : isChecked
                        ? 'rgb(var(--base-secondary-light) / 20%)'
                        : childExpense.isDeleted
                        ? 'rgb(var(--base-error) / 40%)'
                        : '',
            }}
        >
            <div className="flex w-full items-center gap-4 p-4">
                <div className="ml-12 flex-grow">
                    <p className="break-all text-lg text-main-text">{childExpense.name}</p>
                    <p className="text-sm">
                        <span className="text-primary-light">{accountingAccount.code}</span> -{' '}
                        <span className="text-dark-text">{accountingAccount.name}</span>
                    </p>
                </div>

                {isChecked && (
                    <div className="flex flex-col gap-2" style={{ minWidth: '10rem' }}>
                        <LocaleTextField
                            disabled={disableInputs}
                            placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                            value={checkedExpenses.list.find((r) => r.id === childExpense[checkKey])?.value}
                            onChange={(e) => {
                                const value = formatPositiveNumberWithDigits(e.target.value);

                                handleUpdateExpenseValue(value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                            }}
                        />

                        <LocaleTextField
                            placeholder={`${toLocaleNumber(100, 2)} ${referenceCurrencyObj.currency}`}
                            value={
                                checkedExpenses.list.find((r) => r.id === childExpense[checkKey])?.secondCurrencyValue
                            }
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">{referenceCurrencyObj.currency}</InputAdornment>
                                ),
                            }}
                        />
                    </div>
                )}

                {canDisplayCheck && (
                    <div className="w-13 ml-8">
                        <div
                            className={`group flex cursor-pointer items-center justify-center rounded-full border border-secondary-lighter p-1 transition-all duration-150 hover:border-secondary-main `}
                            onClick={handleCheckExpense}
                        >
                            <CheckCircleIcon
                                style={{ fontSize: '15px' }}
                                className="text-secondary-lighter transition-all duration-150 group-hover:text-secondary-main"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

ChildExpenseRow.propTypes = {
    childExpense: PropTypes.object,
    expense: PropTypes.object,
    checkKey: PropTypes.string,
};

const InvoiceExpenseBudget = (props) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const {
        checkedExpenses,
        setCheckedExpenses,
        isEdit,
        contractData,
        viewOnly,
        isBudgetCase,
        setIsBudgetCase,
        exchangeRate,
        disableInputs,
    } = props;

    const { currencyObj, referenceCurrencyObj, language } = useContext(GlobalContext);

    const [selectedExpense, setSelectedExpense] = useState(null);

    const [canRender, setCanRender] = useState(false);
    const [allExpenses, setAllExpenses] = useState([]);
    const [accounts, setAccounts] = useState([]);

    const [articlesFromCrmContract, setArticlesFromCrmContract] = useState([]);

    const getAllExpenses = async () => {
        await Expenses.get(0, 999999).then((res) => {
            if (res.ok) {
                const expenses = res.data.expenseNames;
                setAllExpenses(expenses);

                if ((isEdit || checkedExpenses.list.length > 0) && !isBudgetCase) {
                    setSelectedExpense(expenses.findIndex((revenue) => revenue.id === checkedExpenses.grandParentId));
                }
            } else {
                console.error(res.error);
            }
        });
    };

    const getAllAccountingAccounts = async () => {
        await AccountingAccounts.getAll().then((res) => {
            if (res.ok) {
                setAccounts(res.data);
            } else {
                console.error(res.error);
                enqueueSnackbar(
                    errorHandling(res.error).length > 100 ? errorHandling(res.error) : t(errorHandling(res.error)),
                    {
                        variant: 'error',
                    }
                );
            }
        });
    };

    const getCrmContractInfo = async (id) => {
        try {
            const response = await API.get(`/contract/${id}`);

            if (response.data.contract.offer !== null) {
                setArticlesFromCrmContract(response.data.contract.offer.offerArticles);
            } else {
                setArticlesFromCrmContract([]);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchData = async () => {
        if (contractData.canFetchContract) {
            await Promise.all([getAllExpenses(), getAllAccountingAccounts(), getCrmContractInfo(contractData.id)]);
        } else {
            await Promise.all([getAllExpenses(), getAllAccountingAccounts()]);
        }

        setCanRender(true);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!contractData.canFetchContract) return;

        getCrmContractInfo(contractData.id);
    }, [contractData.id]);

    const renderExpensesFromArticle = (budget) => {
        delete budget.margin;

        const formattedBudget = Object.values(budget).map((value) => {
            return {
                ...value,
                subExpensesName: [
                    ...Object.values(value.subExpensesName).map((subValue) => ({
                        ...subValue,
                        subExpensesName: [
                            ...Object.values(subValue.subExpensesName).map((subSubValue) => ({ ...subSubValue })),
                        ],
                    })),
                ],
            };
        });

        return formattedBudget.map((expense) => (
            <GrandparentExpenseRow key={expense.id} expense={expense} withFilter={false} checkKey="idExpense" />
        ));
    };

    return canRender ? (
        <BudgetaryContext.Provider
            value={{
                accounts,
                checkedExpenses,
                setCheckedExpenses,
                isEdit,
                isBudgetCase,
                setIsBudgetCase,
                currencyObj,
                referenceCurrencyObj,
                exchangeRate,
                language,
                disableInputs,
            }}
        >
            <div className="mb-10 flex max-w-2xl flex-col items-start">
                {!viewOnly && <p className="mb-2 mt-2 text-xl font-bold">{t('Nomenclature expenses')}</p>}
                {!viewOnly &&
                    (() => {
                        // const options = isEdit
                        //     ? allExpenses
                        //     : allExpenses.filter((expense) => expense.isDeleted === false);

                        const options = allExpenses.filter((expense) => expense.isDeleted === false);

                        return (
                            <div className="relative mb-6 mt-2 inline-block ">
                                <Dropdown
                                    variant="green"
                                    icon={
                                        selectedExpense !== null ? (
                                            <EditIcon className="text-buttons-text" />
                                        ) : (
                                            <AddIcon className="text-buttons-text" />
                                        )
                                    }
                                    placeholder={selectedExpense !== null ? t('Change expense') : t('Add expense')}
                                    options={options.map((name) => name.name)}
                                    selectedOption={null}
                                    setSelectedOption={(i) => {
                                        if (i === selectedExpense) return;

                                        setSelectedExpense(i);
                                        setCheckedExpenses({
                                            grandParentId: null,
                                            list: [],
                                        });
                                    }}
                                />
                            </div>
                        );
                    })()}
                {selectedExpense !== null && selectedExpense !== -1 && (
                    <div
                        className={`w-full rounded-md border border-secondary-light ${
                            articlesFromCrmContract.length > 0 ? 'mb-6' : ''
                        }`}
                    >
                        <GrandparentExpenseRow expense={allExpenses[selectedExpense]} withFilter={true} checkKey="id" />
                    </div>
                )}

                {articlesFromCrmContract.length > 0 && (
                    <React.Fragment>
                        {!viewOnly && <p className="mb-2 mt-2 text-xl font-bold">{t('Article expenses')}</p>}

                        {articlesFromCrmContract.map((article, index) => {
                            <p className="mb-2 mt-2 text-base font-bold">{article.name}</p>;

                            return (
                                <div
                                    key={article.id}
                                    className={`w-full rounded-md border border-secondary-light ${
                                        index !== 0 ? 'mt-6' : ''
                                    }`}
                                >
                                    {renderExpensesFromArticle(article.originalArticle.Budget)}
                                </div>
                            );
                        })}
                    </React.Fragment>
                )}
            </div>
        </BudgetaryContext.Provider>
    ) : (
        <CircularProgress />
    );
};

InvoiceExpenseBudget.propTypes = {
    checkedExpenses: PropTypes.object,
    setCheckedExpenses: PropTypes.func,
    isEdit: PropTypes.bool,
    contractData: PropTypes.object,
    viewOnly: PropTypes.bool,
    isBudgetCase: PropTypes.bool,
    disableInputs: PropTypes.bool,
    setIsBudgetCase: PropTypes.func,
    exchangeRate: PropTypes.number,
};

InvoiceExpenseBudget.defaultProps = {
    checkedExpenses: {},
    setCheckedExpenses: () => null,
    isEdit: false,
    contractData: { id: null, canFetchContract: false },
    viewOnly: false,
    isBudgetCase: false,
    disableInputs: false,
    setIsBudgetCase: () => null,
    exchangeRate: 0,
};

export default InvoiceExpenseBudget;
