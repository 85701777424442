import React, { useState } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';

import { Button, TextField } from '@material-ui/core';

import useScreenSizes from 'hooks/useScreenSizes';
import { SnackbarProvider, useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper, Toggle } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const ProjectStructureAddSectionModal = ({ options, onClose, onFinish, hiddenOptions }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [width] = useScreenSizes();
    const [selectedOption, setSelectedOption] = useState(null);
    const [sectionName, setSectionName] = useState('');
    const [basedOnAMilestone, setBasedOnAMilestone] = useState(false);

    const handleSetBasedOnaAMilestone = (e) => {
        setBasedOnAMilestone(e);
        setSectionName('');
        setSelectedOption(null);
    };

    const handleSetSelectedOption = (e) => {
        setSelectedOption(e);
        setSectionName(options[e].name);
    };

    const handleOnFinish = () => {
        if (sectionName.trim().length < 3) {
            enqueueSnackbar(t('The section name length must be at least 3 characters long'), { variant: 'error' });
            return;
        }

        if (basedOnAMilestone && selectedOption === null) {
            enqueueSnackbar(t('You must select one milestone'), { variant: 'error' });
            return;
        }

        onFinish(sectionName, options[selectedOption]?.id ?? null);
    };

    return (
        <div className="flex flex-col items-center gap-8 p-10" style={{ width: width > 600 ? '50vw' : '90vw' }}>
            <h2>{t('Add section')}</h2>

            <div
                style={{ backgroundColor: 'var(--layout-light)' }}
                className={`flex w-full max-w-xl flex-col items-center gap-8 rounded-md p-4`}
            >
                <div className="flex w-full items-center gap-8 rounded-md bg-layout-transparent p-2">
                    <Toggle checked={basedOnAMilestone} setChecked={handleSetBasedOnaAMilestone} />
                    <p>{t('Add a new section based on a milestone')}</p>
                </div>

                <div className="flex w-full flex-col gap-4 rounded-md bg-layout-transparent p-2">
                    {basedOnAMilestone && (
                        <Dropdown
                            icon={<EditIcon />}
                            placeholder={t('Select Milestone')}
                            options={options.map((aM) => aM.name)}
                            selectedOption={selectedOption}
                            setSelectedOption={handleSetSelectedOption}
                            hiddenOptions={hiddenOptions}
                        />
                    )}

                    <LabelWrapper label={t('Section name')}>
                        <TextField
                            placeholder={t('Section name')}
                            name="name"
                            type="text"
                            value={sectionName}
                            onChange={(e) => setSectionName(e.target.value)}
                        />
                    </LabelWrapper>
                </div>
            </div>

            <div className="flex w-full justify-end gap-4">
                <Button onClick={onClose} startIcon={<CancelIcon />} color="secondary">
                    {t('Cancel')}
                </Button>
                <Button
                    onClick={handleOnFinish}
                    startIcon={<CheckCircleIcon />}
                    color="secondary"
                    className="focus:outline-none disabled:!bg-slate-600 disabled:!text-slate-400"
                >
                    {t('Finish edit')}
                </Button>
            </div>
        </div>
    );
};

ProjectStructureAddSectionModal.propTypes = {
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    options: PropTypes.array,
    hiddenOptions: PropTypes.array,
};

ProjectStructureAddSectionModal.defaultProps = {
    onClose: () => {},
    onFinish: () => {},
    options: [],
    hiddenOptions: [],
};

export default ProjectStructureAddSectionModal;
