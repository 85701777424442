import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

import { DocumentContext } from '../document-template';

const DocumentTemplateOfferSelection = ({ element }) => {
    const { t } = useTranslation();
    const { offers, handleChangeSelectedOffer } = useContext(DocumentContext);
    const [selectedOffer, setSelectedOffer] = useState(null);

    useEffect(() => {
        if (element.entity[0]) setSelectedOffer(offers.findIndex((o) => o.id === element.entity[0].entityId));
        else setSelectedOffer(null);
    }, [element.entity]);

    return (
        <Dropdown
            options={offers.map((o) => o.name)}
            placeholder={t('Select offer')}
            selectedOption={selectedOffer}
            setSelectedOption={(idx) => {
                handleChangeSelectedOffer(element.pairKey, idx);
                setSelectedOffer(idx);
            }}
        />
    );
};

DocumentTemplateOfferSelection.propTypes = {
    element: PropTypes.object,
};

DocumentTemplateOfferSelection.defaultProps = {
    element: {},
};

export default DocumentTemplateOfferSelection;
