import React from 'react';

import { Chip } from '@material-ui/core';

import PropTypes from 'prop-types';

const PipelineSortLabel = ({ sortKey }) => {
    return (
        <div className="flex items-center gap-6">
            <div className="flex w-full flex-col gap-1.5">
                {sortKey.lines.map((line, index) => (
                    <div key={index} style={{ height: '1px', ...line.styles }} />
                ))}
            </div>

            <Chip
                size="small"
                variant="outlined"
                label={sortKey.title}
                style={{
                    ...sortKey.chipStyles,
                }}
            />

            <div className="flex w-full flex-col gap-1.5">
                {sortKey.lines.map((line, index) => (
                    <div key={index} style={{ height: '1px', ...line.styles }} />
                ))}
            </div>
        </div>
    );
};

PipelineSortLabel.propTypes = {
    sortKey: PropTypes.object,
};

PipelineSortLabel.defaultProps = {
    sortKey: {},
};

export default PipelineSortLabel;
