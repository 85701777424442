import React, { useEffect } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import PhotoIcon from '@material-ui/icons/Photo';

import { Button, TextField } from '@material-ui/core';

import FileUploadContainer from 'components/shared/file-upload-container';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { projectManagement } from 'routes';
import { errorHandling, PHONE_NO_REGEX, uploadSingleFile } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const AffiliateAccountForm = ({ pmId, affiliateInfoForEdit }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const { affiliateId } = useParams();

    const [profileImageBlob, setProfileImageBlob] = React.useState(null);

    const handleImageUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(t(message), { variant: 'error' });
                    return;
                }
                setProfileImageBlob(blob);
            },
            'image'
        );
    };

    const affiliateDefaultInfoObject = {
        name: '',
        role: '',
        phoneNumber: '',
        email: '',
        observations: '',
    };

    const [affiliateInfo, setAffiliateInfo] = React.useState(affiliateDefaultInfoObject);

    const handleAffiliateInfoChange = (e) => {
        setAffiliateInfo({ ...affiliateInfo, [e.target.name]: e.target.value });
    };

    const affiliateInfoValidationSchema = yup.object().shape({
        phoneNumber: yup
            .string()
            .trim()
            .required(t('Phone number field required!'))
            .matches(PHONE_NO_REGEX, t('Invalid phone number!'))
            .min(10, t('Phone number must be at least 10 digits')),
        email: yup.string().trim().required(t('Email field is required!')).email(t('Email is invalid!')),
        name: yup
            .string()
            .trim()
            .typeError(t('Affiliate name is required!'))
            .required(t('Affiliate name is required!')),
        role: yup
            .string()
            .trim()
            .typeError(t('Affiliate role name is required!'))
            .required(t('Affiliate role name is required!')),
    });

    useEffect(() => {
        if (affiliateInfoForEdit != null && Object.keys(affiliateInfoForEdit).length > 0) {
            const objectForEdit = {};
            objectForEdit.role = affiliateInfoForEdit.profile.role;
            objectForEdit.name = affiliateInfoForEdit.profile.name;
            objectForEdit.phoneNumber = affiliateInfoForEdit.profile.phoneNo;
            objectForEdit.email = affiliateInfoForEdit.email;
            objectForEdit.observations = affiliateInfoForEdit.observation;
            objectForEdit.id = affiliateInfoForEdit.id;
            setAffiliateInfo(objectForEdit);
        }
    }, [affiliateInfoForEdit]);

    const createAffiliateAccount = async () => {
        try {
            setLoading(true);

            await affiliateInfoValidationSchema.validate({
                name: affiliateInfo.name,
                role: affiliateInfo.role,
                phoneNumber: affiliateInfo.phoneNumber,
                email: affiliateInfo.email,
            });

            if (affiliateInfoForEdit === undefined) {
                if (profileImageBlob === null) {
                    enqueueSnackbar(t('Profile image is required!'), {
                        variant: 'error',
                    });
                    return;
                }
            }

            const reqBody = new FormData();

            // if we have infos for editing, we create the body different, cause thats how the backend wants it

            if (affiliateInfoForEdit) {
                reqBody.append(
                    'data',
                    JSON.stringify({
                        data: {
                            email: affiliateInfo.email,
                            observation: affiliateInfo.observations,
                            profile: {
                                name: affiliateInfo.name,
                                role: affiliateInfo.role,
                                phoneNo: affiliateInfo.phoneNumber,
                            },
                            permissions: [
                                {
                                    permissionId: '3',
                                    permissionType: 'VIEW',
                                },
                                {
                                    permissionId: '8',
                                    permissionType: 'VIEW',
                                },
                                {
                                    permissionId: '9',
                                    permissionType: 'VIEW',
                                },
                                {
                                    permissionId: '10',
                                    permissionType: 'VIEW',
                                },
                                {
                                    permissionId: '11',
                                    permissionType: 'ALL',
                                },
                                {
                                    permissionId: '12',
                                    permissionType: 'ALL',
                                },
                                {
                                    permissionId: '15',
                                    permissionType: 'ALL',
                                },
                                {
                                    permissionId: '19',
                                    permissionType: 'VIEW',
                                },
                                {
                                    permissionId: '40',
                                    permissionType: 'VIEW',
                                },
                                {
                                    permissionId: '41',
                                    permissionType: 'VIEW',
                                },
                            ],
                        },
                        id: affiliateInfo.id,
                    })
                );
            } else {
                reqBody.append(
                    'data',
                    JSON.stringify({
                        email: affiliateInfo.email,
                        observation: affiliateInfo.observations,
                        profile: {
                            name: affiliateInfo.name,
                            role: affiliateInfo.role,
                            phoneNo: affiliateInfo.phoneNumber,
                        },
                        // pMId: pmId,
                        permissions: [
                            {
                                permissionId: '3',
                                permissionType: 'VIEW',
                            },
                            {
                                permissionId: '8',
                                permissionType: 'VIEW',
                            },
                            {
                                permissionId: '9',
                                permissionType: 'VIEW',
                            },
                            {
                                permissionId: '10',
                                permissionType: 'VIEW',
                            },
                            {
                                permissionId: '11',
                                permissionType: 'ALL',
                            },
                            {
                                permissionId: '12',
                                permissionType: 'ALL',
                            },
                            {
                                permissionId: '15',
                                permissionType: 'ALL',
                            },
                            {
                                permissionId: '19',
                                permissionType: 'VIEW',
                            },
                            {
                                permissionId: '40',
                                permissionType: 'VIEW',
                            },
                            {
                                permissionId: '41',
                                permissionType: 'VIEW',
                            },
                        ],
                    })
                );
            }

            reqBody.append('profileImage', profileImageBlob);

            try {
                //if we have data for edit, then we update the affiliate
                if (affiliateInfoForEdit) {
                    await API.put('/PmAffiliates', reqBody, {
                        'Content-Type': 'multipart/form-data',
                    });

                    enqueueSnackbar(t('Affiliate account was successfully updated!'), {
                        variant: 'success',
                    });
                } else {
                    await API.post('/PmAffiliates', reqBody, {
                        'Content-Type': 'multipart/form-data',
                    });
                    enqueueSnackbar(t('Affiliate account was successfully created!'), {
                        variant: 'success',
                    });
                }

                history.push(
                    projectManagement.base +
                        projectManagement.projectManagementConfigurator.base +
                        '?tab=0' +
                        '&subTab=1'
                );
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err.errors);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-sm flex-shrink-0">
            <h4 className="mb-10 text-2xl text-dark-text">{t('Affiliate account details')}</h4>
            <div className="relative mb-5">
                <TextField
                    name="role"
                    label={t('Role name')}
                    placeholder="Project Manager"
                    value={affiliateInfo.role}
                    onChange={handleAffiliateInfoChange}
                />
            </div>
            <div className="relative mb-5">
                <TextField
                    name="name"
                    label={t('Affiliate name')}
                    placeholder={t('Affiliate name')}
                    value={affiliateInfo.name}
                    onChange={handleAffiliateInfoChange}
                />
            </div>
            <div className="relative mb-5">
                <TextField
                    // className={`${id ? 'pointer-events-none' : ''}`}
                    name="email"
                    type="email"
                    label="Email"
                    placeholder={t('name@email.com')}
                    value={affiliateInfo.email}
                    onChange={handleAffiliateInfoChange}
                />
            </div>
            <div className="relative mb-5">
                <TextField
                    name="phoneNumber"
                    label={t('Phone')}
                    placeholder="0752681439"
                    value={affiliateInfo.phoneNumber}
                    onChange={handleAffiliateInfoChange}
                />
            </div>

            <div className="relative mb-5">
                <TextField
                    name="observations"
                    label={t('Observations')}
                    placeholder={t('Observations...')}
                    multiline
                    value={affiliateInfo.observations}
                    onChange={handleAffiliateInfoChange}
                />
            </div>

            <div className="mb-5">
                <LabelWrapper label={t('Profile image')}>
                    <FileUploadContainer onUpload={handleImageUpload}>
                        <Button fullWidth startIcon={<PhotoIcon />}>
                            {(() => {
                                if (affiliateInfoForEdit === undefined && profileImageBlob === null) {
                                    return t('Add profile image');
                                } else if (affiliateInfoForEdit === undefined && profileImageBlob) {
                                    return profileImageBlob.name.substr(0, 20) + '...';
                                } else if (affiliateInfoForEdit && profileImageBlob === null) {
                                    return t('Change profile image');
                                } else {
                                    return profileImageBlob.name.substr(0, 20) + '...';
                                }
                            })()}
                        </Button>
                    </FileUploadContainer>
                </LabelWrapper>
            </div>

            <div className="mt-10">
                {affiliateId ? (
                    <Button
                        type="submit"
                        startIcon={<CheckIcon />}
                        disabled={loading}
                        color="primary"
                        onClick={createAffiliateAccount}
                    >
                        {t('Update profile info')}
                    </Button>
                ) : (
                    <Button startIcon={<AddIcon />} disabled={loading} color="primary" onClick={createAffiliateAccount}>
                        {t('Add user')}
                    </Button>
                )}
            </div>
        </div>
    );
};

AffiliateAccountForm.propTypes = {
    pmId: PropTypes.string,
    affiliateId: PropTypes.string,
    affiliateInfoForEdit: PropTypes.object,
};

AffiliateAccountForm.defaultProps = {
    pmId: '',
    affiliateId: '',
};

export default AffiliateAccountForm;
