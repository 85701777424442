import React, { useEffect, useState } from 'react';

import ConfigFieldWrapper from 'components/shared/configurator/config-field-wrapper';
import PropTypes from 'prop-types';
import { Dropdown, SelectList } from 'RaisisComponents/index.js';

const ReferenceField = (props) => {
    const { field, changeFieldValue, deleteFieldFromColumn } = props;
    const [selectedType, setSelectedType] = useState([0]);
    const [selectedRef, setSelectedRef] = useState(null);
    const [selectedRefSub, setSelectedRefSub] = useState(null);

    useEffect(() => {
        changeFieldValue('selectedTypeOptions', field.fieldOptions[selectedType[0]]);
    }, selectedType);

    useEffect(() => {
        const oldRefField = field.refField;
        changeFieldValue('refField', {
            ...oldRefField,
            selectedField: oldRefField.fields[selectedRef],
            selectedSubField: oldRefField.subFields[selectedRefSub],
        });
    }, [selectedRef, selectedRefSub]);

    return (
        <>
            {field ? (
                <ConfigFieldWrapper
                    canBeDeleted={field.canBeDeleted}
                    type={field.type}
                    name={field.displayName}
                    setName={(newValue) => changeFieldValue('displayName', newValue)}
                    deleteFieldFromColumn={deleteFieldFromColumn}
                >
                    <div className="mb-2">
                        <SelectList
                            options={field.fieldOptions}
                            selectedOptions={selectedType}
                            setSelectedOptions={setSelectedType}
                        />
                    </div>

                    <div className="relative z-50 mb-2">
                        <Dropdown
                            options={field.refField.fields}
                            selectedOption={selectedRef}
                            setSelectedOption={setSelectedRef}
                        />
                    </div>

                    <div className="relative z-40 border-l border-layout-transparent-dark pl-2">
                        <Dropdown
                            options={field.refField.subFields}
                            selectedOption={selectedRefSub}
                            setSelectedOption={setSelectedRefSub}
                        />
                    </div>
                </ConfigFieldWrapper>
            ) : null}
        </>
    );
};

ReferenceField.propTypes = {
    field: PropTypes.object,
    changeFieldValue: PropTypes.func,
    deleteFieldFromColumn: PropTypes.func,
};

ReferenceField.defaultProps = {
    field: null,
    changeFieldValue: () => null,
    deleteFieldFromColumn: () => null,
};

export default ReferenceField;
