import React, { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { TextareaAutosize, TextField } from '@material-ui/core';

import ErrorButton from 'components/shared/error-button';
import SuccessButton from 'components/shared/success-button';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { DatePicker, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { errorHandling, formatPositiveNumber } from 'utils';
import * as yup from 'yup';

import { RiemContext } from '../riem';

const RiemRowForm = ({ index, item, riem }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { onChangeItem, onRemoveItem, setIsOperatingOnRowAt } = useContext(RiemContext);

    const [isMounted, setIsMounted] = useState(false);
    const [formState, setFormState] = useState(item);

    const formRef = useRef(null);

    const handleChangeField = (e) =>
        setFormState((prev) => {
            const formValue = structuredClone(prev);
            let newValue = e.target.value;
            const purpose = (e.target.getAttribute && e.target.getAttribute('data-purpose')) || '';

            if (purpose) {
                newValue = Math.min(formatPositiveNumber(newValue), 5);
                formValue[e.target.name] = newValue;

                if (purpose === 'identified') {
                    const val1 = formValue['identifiedScore'];
                    const val2 = formValue['identifiedProbability'];

                    formValue['identifiedTotal'] = val1 && val2 ? val1 * val2 : val1 + val2;
                }

                if (purpose === 'residual') {
                    const val1 = formValue['residualScore'];
                    const val2 = formValue['residualProbability'];

                    formValue['residualTotal'] = val1 && val2 ? val1 * val2 : val1 + val2;
                }

                return formValue;
            }

            return { ...formValue, [e.target.name]: newValue };
        });

    const schema = yup.object().shape({
        stage: yup.string().typeError(t('The stage is mandatory')).required(t('The stage is mandatory')),
        identifiedDescription: yup
            .string()
            .typeError(t('The description of the identified risks is mandatory'))
            .required(t('The description of the identified risks is mandatory')),
        measures: yup
            .string()
            .typeError(t('The mitigation / control measures is mandatory'))
            .required(t('The mitigation / control measures is mandatory')),
        residualDescription: yup
            .string()
            .typeError(t('The description of residual risks is mandatory'))
            .required(t('The description of residual risks is mandatory')),
        identifiedScore: yup
            .number()
            .typeError(t('The identified risk score is mandatory'))
            .required(t('The identified risk score is mandatory')),
        identifiedProbability: yup
            .number()
            .typeError(t('The risk probability is mandatory'))
            .required(t('The risk probability is mandatory')),
        residualScore: yup
            .number()
            .typeError(t('The residual risk score is mandatory'))
            .required(t('The residual risk score is mandatory')),
        residualProbability: yup
            .number()
            .typeError(t('The residual risk probability is mandatory'))
            .required(t('The residual risk probability is mandatory')),
        identificationDate: yup
            .date()
            .typeError(t('The identification date is mandatory'))
            .required(t('The identification date is mandatory')),
        lastUpdate: yup.date().typeError(t('The last update is mandatory')).required(t('The last update is mandatory')),
    });

    const handleSuccessAction = async () => {
        try {
            await schema.validate(formState);

            onChangeItem(formState, item.id, index, riem.id);
            setIsOperatingOnRowAt(null);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };

    useEffect(() => {
        const form = formRef.current;
        if (!form) return;

        const firstTextArea = form.querySelector('textarea');
        firstTextArea.focus({ preventScroll: true });
        firstTextArea.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

        setIsMounted(true);
    }, []);

    return (
        <form
            ref={formRef}
            role="row"
            className="relative col-span-full grid bg-layout-transparent-dark"
            style={{
                gridTemplateColumns: 'subgrid',
            }}
        >
            <div role="cell" className="flex items-center justify-center border border-layout-main px-0.5 py-1">
                <p className="text-xl font-bold">{index + 1}</p>
            </div>
            <div
                role="cell"
                className="flex items-center justify-center border border-layout-main px-2 py-1"
                style={{
                    minWidth: '12rem',
                }}
            >
                <LabelWrapper label={t('Stage')}>
                    <TextField
                        name="stage"
                        InputProps={{
                            inputComponent: TextareaAutosize,
                            rows: 5,
                        }}
                        placeholder={t('Stage')}
                        value={formState.stage}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div
                role="cell"
                className="flex items-center justify-center border border-layout-main px-2 py-1"
                style={{
                    minWidth: '12rem',
                }}
            >
                <LabelWrapper label={t('Description of the identified risks')}>
                    <TextField
                        name="identifiedDescription"
                        placeholder={t('Description of the identified risks')}
                        value={formState.identifiedDescription}
                        InputProps={{
                            inputComponent: TextareaAutosize,
                            rows: 5,
                        }}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="cell" className="flex items-center justify-center border border-layout-main px-2 py-1">
                <LabelWrapper label={t('Identified risk score')}>
                    <TextField
                        name="identifiedScore"
                        type="number"
                        placeholder={t('Identified risk score')}
                        value={formState.identifiedScore.toString()}
                        onChange={handleChangeField}
                        inputProps={{
                            'data-purpose': 'identified',
                        }}
                    />
                </LabelWrapper>
            </div>
            <div role="cell" className="flex items-center justify-center border border-layout-main px-2 py-1">
                <LabelWrapper label={t('Risk probability')}>
                    <TextField
                        name="identifiedProbability"
                        type="number"
                        placeholder={t('Risk probability')}
                        value={formState.identifiedProbability.toString()}
                        onChange={handleChangeField}
                        inputProps={{
                            'data-purpose': 'identified',
                        }}
                    />
                </LabelWrapper>
            </div>
            <div
                role="cell"
                className="flex flex-col items-center justify-center border border-layout-main px-0.5 py-1"
            >
                <p className="text-center text-xl font-extralight">
                    {t('Identified risk score')} <span className="font-bold">&times;</span>{' '}
                    {t('Risk probability').toLowerCase()}:{' '}
                    <span className="font-bold">{formState.identifiedTotal}</span>
                </p>
            </div>
            <div
                role="cell"
                className="flex items-center justify-center border border-layout-main px-2 py-1"
                style={{
                    minWidth: '12rem',
                }}
            >
                <LabelWrapper label={t('Mitigation / control measures')}>
                    <TextField
                        name="measures"
                        placeholder={t('Mitigation / control measures')}
                        value={formState.measures}
                        InputProps={{
                            inputComponent: TextareaAutosize,
                            rows: 5,
                        }}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div
                role="cell"
                className="flex items-center justify-center border border-layout-main px-2 py-1"
                style={{
                    minWidth: '12rem',
                }}
            >
                <LabelWrapper label={t('Description of residual risks')}>
                    <TextField
                        name="residualDescription"
                        placeholder={t('Description of residual risks')}
                        value={formState.residualDescription}
                        InputProps={{
                            inputComponent: TextareaAutosize,
                            rows: 5,
                        }}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="cell" className="flex items-center justify-center border border-layout-main px-2 py-1">
                <LabelWrapper label={t('Residual risk score')}>
                    <TextField
                        name="residualScore"
                        type="number"
                        placeholder={t('Residual risk score')}
                        value={formState.residualScore.toString()}
                        onChange={handleChangeField}
                        inputProps={{
                            'data-purpose': 'residual',
                        }}
                    />
                </LabelWrapper>
            </div>
            <div role="cell" className="flex items-center justify-center border border-layout-main px-2 py-1">
                <LabelWrapper label={t('Residual risk probability')}>
                    <TextField
                        name="residualProbability"
                        type="number"
                        placeholder={t('Residual risk probability')}
                        value={formState.residualProbability.toString()}
                        onChange={handleChangeField}
                        inputProps={{
                            'data-purpose': 'residual',
                        }}
                    />
                </LabelWrapper>
            </div>
            <div role="cell" className="flex items-center justify-center border border-layout-main px-0.5 py-1">
                <p className="text-center text-xl font-extralight">
                    {t('Residual risk score')} <span className="font-bold">&times;</span>{' '}
                    {t('Residual risk probability').toLowerCase()}:{' '}
                    <span className="font-bold">{formState.residualTotal}</span>
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center border border-layout-main px-2 py-1">
                <LabelWrapper label={t('Identification date')}>
                    <DatePicker
                        date={formState.identificationDate}
                        setDate={(date) =>
                            handleChangeField({
                                target: {
                                    name: 'identificationDate',
                                    value: new Date(date).toDateString(),
                                },
                            })
                        }
                    />
                </LabelWrapper>
            </div>
            <div role="cell" className="flex items-center justify-center border border-layout-main px-2 py-1">
                <LabelWrapper label={t('Last update')}>
                    <DatePicker
                        date={formState.lastUpdate}
                        setDate={(date) =>
                            handleChangeField({
                                target: {
                                    name: 'lastUpdate',
                                    value: new Date(date).toDateString(),
                                },
                            })
                        }
                    />
                </LabelWrapper>
            </div>
            {isMounted &&
                createPortal(
                    <div className="flex flex-col items-center justify-center gap-2">
                        <SuccessButton startIcon={<CheckCircleIcon />} size="small" onClick={handleSuccessAction}>
                            {!item.id ? t('Add') : t('Complete')}
                        </SuccessButton>

                        <ErrorButton
                            startIcon={<CancelIcon />}
                            size="small"
                            onClick={() => {
                                if (!item.id) onRemoveItem(index);
                                setIsOperatingOnRowAt(null);
                            }}
                        >
                            {t('Cancel')}
                        </ErrorButton>
                    </div>,
                    document.getElementById(`riem-actions-container-${index}`)
                )}
        </form>
    );
};

RiemRowForm.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    riem: PropTypes.object,
};

RiemRowForm.defaultProps = {
    item: null,
    index: 0,
    riem: {},
};

export default RiemRowForm;
