import React, { useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import { Button, CircularProgress, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { quotes } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const CategoryForm = ({ category, id, setInitializeData }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [categoryName, setCategoryName] = useState('');
    const [observations, setObservations] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    let schema = yup.object().shape({
        categoryName: yup
            .string()
            .trim()
            .typeError(t('Category name field is mandatory!'))
            .required(t('Category name field is mandatory!')),
    });

    const createCategory = async () => {
        try {
            setLoading(true);
            setInitializeData?.((prev) => ({ ...prev, loading: true }));

            await schema.validate({ categoryName });
            try {
                await API.post('/categories', {
                    name: categoryName,
                    observation: observations,
                });

                if (setInitializeData) setInitializeData((prev) => ({ ...prev, tab: 0 }));
                else history.push(quotes.base + quotes.nomenclature.base + quotes.nomenclature.read + '?tab=1');

                enqueueSnackbar(t('Category was successfully created!'), { variant: 'success' });
            } catch (err) {
                console.error(err);
                throw err;
            }
        } catch (err) {
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        } finally {
            setLoading(false);
            setInitializeData?.((prev) => ({ ...prev, loading: false }));
        }
    };

    const updateCategory = async () => {
        try {
            setLoading(true);
            await schema.validate({ categoryName });
            try {
                await API.put('/categories', {
                    name: categoryName,
                    observation: observations,
                    id: id,
                });
                history.push(quotes.base + quotes.nomenclature.base + quotes.nomenclature.read + '?tab=1');
                enqueueSnackbar(t('Category was successfully updated!'), { variant: 'success' });
            } catch (err) {
                console.error(err);
                throw err;
            }
        } catch (err) {
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (category) {
            setCategoryName(category?.name);
            setObservations(category?.observation);
        }
    }, [category]);

    return loading ? (
        <div className="flex h-svh w-full items-center justify-center bg-layout-main">
            <CircularProgress />
        </div>
    ) : (
        <div className="w-full max-w-sm">
            <h4 className="mb-10 text-2xl text-dark-text">{t('Category details')}</h4>
            <div className="relative mb-5">
                <TextField
                    name={'departament'}
                    label={t('Category name')}
                    placeholder={t('Add a category name')}
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                />
            </div>
            <div className="relative mb-5">
                <TextField
                    name={'Observations'}
                    label={t('Observations')}
                    placeholder={t('Observations...')}
                    value={observations}
                    multiline
                    rows={3}
                    onChange={(e) => setObservations(e.target.value)}
                />
            </div>
            <div className="mt-20">
                {id ? (
                    <Button type="submit" startIcon={<CheckIcon />} color="primary" onClick={() => updateCategory()}>
                        {t('Edit category')}
                    </Button>
                ) : (
                    <Button startIcon={<AddIcon />} color="primary" onClick={() => createCategory()}>
                        {t('Add category')}
                    </Button>
                )}
            </div>
        </div>
    );
};

CategoryForm.propTypes = {
    category: PropTypes.object,
    id: PropTypes.string,
    setInitializeData: PropTypes.func,
};

CategoryForm.defaultProps = {
    category: null,
    id: null,
    setInitializeData: null,
};

export default CategoryForm;
