import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Footer from 'components/footer/footer';
import { ResourcesProvider } from 'contexts/ResourcesContext';
import { resourceModule } from 'routes';

import ResourcesPage from './resources/resources-page';
import WarehousePage from './warehouse/warehouse-page';

const ResourcesModuleRouting = () => {
    return (
        <ResourcesProvider>
            <Switch>
                <Route exact path={resourceModule.base}>
                    <Redirect to={resourceModule.resources.base} />
                </Route>

                <Route path={resourceModule.resources.base}>
                    <ResourcesPage />
                </Route>

                <Route path={resourceModule.warehouse.base}>
                    <WarehousePage />
                </Route>
            </Switch>
            <Footer />
        </ResourcesProvider>
    );
};

export default ResourcesModuleRouting;
