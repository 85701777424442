import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Employee from 'api/Employee';
import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import EmployeeForm from './employee-form';

export default function EditEmployeePage({ moduleType }) {
    const { t } = useTranslation();
    const location = useLocation();

    const { employeeId } = useParams();
    const employeeType = location.state?.employeeType ?? 'internal';
    const [employee, setEmployee] = useState(undefined);

    useEffect(() => {
        Employee[moduleType].getById(employeeId).then((res) => setEmployee(res.data));
    }, [employeeId]);

    return (
        employee !== undefined && (
            <React.Fragment>
                <Helmet>
                    <title>{t('Edit employee')}</title>
                </Helmet>

                <Header pageTitle={t('Edit employee')} />

                <div className="page-container">
                    <EmployeeForm moduleType={moduleType} employeeType={employeeType} employee={employee} />
                </div>
            </React.Fragment>
        )
    );
}

EditEmployeePage.propTypes = {
    moduleType: PropTypes.oneOf(['pm', 'internalActivity']).isRequired,
};
