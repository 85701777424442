import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CategoryIcon from '@material-ui/icons/Category';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { ExpandableRow, TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import { formatDate } from 'utils';
import API from 'utils/axios';

const ArticleCategoriesRow = ({ category, isInitializationCase }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    return (
        <ExpandableRow
            colSpan={4}
            padding={false}
            row={
                <>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>{formatDate(category.createAt)}</TableCell>
                    <TableCell>{category.observation}</TableCell>
                </>
            }
            collapse={
                <>
                    <div className="p-5">
                        {canAll && !isInitializationCase ? (
                            <Button
                                startIcon={<CategoryIcon />}
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        quotes.base +
                                            quotes.nomenclature.base +
                                            quotes.categories.base +
                                            quotes.categories.update +
                                            '/' +
                                            category.id
                                    )
                                }
                            >
                                {t('Edit category')}
                            </Button>
                        ) : (
                            <div className="text-main">{t('You cannot take any action!')}</div>
                        )}
                    </div>
                </>
            }
        />
    );
};

ArticleCategoriesRow.propTypes = {
    category: PropTypes.object,
    isInitializationCase: PropTypes.bool,
};

ArticleCategoriesRow.defaultProps = {
    category: null,
    isInitializationCase: false,
};

const ArticleCategories = ({ setInitializeData }) => {
    const [categories, setCategories] = useState([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const getCategories = async () => {
        setLoading(true);
        try {
            const response = await API.get('/categories');
            const rowCategories = response.data.getCategory;

            setCategories(rowCategories);
            setInitializeData?.((prev) => ({ ...prev, nextDisabled: !rowCategories.length }));
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <>
            {!setInitializeData && (
                <Helmet>
                    <title>{t('Article categories')}</title>
                </Helmet>
            )}

            {loading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : categories.length ? (
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Category name')}</TableCell>
                                <TableCell>{t('Created on')}</TableCell>
                                <TableCell>{t('Observations')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableSeparator />
                            {categories.map((category) => (
                                <ArticleCategoriesRow
                                    key={category.name}
                                    category={category}
                                    isInitializationCase={!!setInitializeData}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <NoDataPlaceholder />
            )}
        </>
    );
};

ArticleCategories.propTypes = {
    setInitializeData: PropTypes.func,
};

ArticleCategories.defaultProps = {
    setInitializeData: null,
};

export default ArticleCategories;
