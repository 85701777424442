import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import { Button } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';

const InitializeButtons = ({
    className,
    canSkip,
    handleIncrementStep,
    handleDecrementStep,
    handleIncrementCallback,
    nextDisabled,
    prevDisabled,
    skipDisabled,
    isFinalStep,
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleNextStep = async () => {
        try {
            await handleIncrementCallback?.();
            await handleIncrementStep();
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };

    return (
        <div className={className}>
            {handleDecrementStep && (
                <Button
                    size="large"
                    disabled={prevDisabled}
                    color="primary"
                    endIcon={<NavigateBeforeIcon />}
                    onClick={handleDecrementStep}
                >
                    {t('Previous step')}
                </Button>
            )}

            {handleIncrementStep && (
                <Button
                    size="large"
                    disabled={nextDisabled}
                    color="primary"
                    endIcon={isFinalStep ? <CheckCircleIcon /> : <NavigateNextIcon />}
                    onClick={handleNextStep}
                >
                    {isFinalStep ? t('Finalize initialization') : t('Next step')}
                </Button>
            )}

            {handleIncrementStep && canSkip && (
                <Button
                    size="large"
                    disabled={skipDisabled}
                    color="secondary"
                    endIcon={<SkipNextIcon />}
                    onClick={handleIncrementStep}
                >
                    {t('Skip step')}
                </Button>
            )}
        </div>
    );
};

InitializeButtons.propTypes = {
    className: PropTypes.string,
    canSkip: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    skipDisabled: PropTypes.bool,
    prevDisabled: PropTypes.bool,
    isFinalStep: PropTypes.bool,
    handleIncrementCallback: PropTypes.func,
    handleIncrementStep: PropTypes.func,
    handleDecrementStep: PropTypes.func,
};

InitializeButtons.defaultProps = {
    className: '',
    canSkip: true,
    nextDisabled: false,
    skipDisabled: false,
    prevDisabled: false,
    isFinalStep: false,
    handleIncrementCallback: null,
    handleIncrementStep: null,
    handleDecrementStep: null,
};

export default InitializeButtons;
