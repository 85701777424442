import i18n from 'i18next';
import API from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

export const generateUUID = () => crypto.randomUUID?.() ?? uuidv4();

// ? Link classes used to style the link like attributes
export const linkStyle = 'cursor-pointer hover:text-primary-main transition-colors';

// ? Different regexes used for form validation
export const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const IBAN_REGEX = /\b[A-Z]{2}\d{2}[A-Z0-9]{1,30}\b/i;
export const PHONE_NO_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const PASSWORD_REGEX = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%*]).{8,40})/;

// ? One day in milliseconds (hours * minutes * seconds * milliseconds)
export const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
// ? End of day offset is represented by a day in milliseconds minus one, in order to not represent a full day, but just the end of it
export const END_OF_DAY_OFFSET = ONE_DAY_IN_MILLISECONDS - 1;

// ? Short version of the months names
const monthMapper = ['IAN', 'FEB', 'MAR', 'APR', 'MAI', 'IUN', 'IUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

/**
 * The function returns an array of month names in either Romanian or English, depending on the
 * language parameter.
 * @param {String} lang - The `lang` parameter is a string that specifies the language for which the months
 * should be returned. It has a default value of 'ro' (Romanian).
 * @returns an array of month names in the specified language. If the language is set to 'ro'
 * (Romanian), it returns an array of Romanian month names. Otherwise, it returns an array of English
 * month names.
 */
export const months = (lang = 'ro') => {
    if (lang === 'ro') {
        return [
            'Ianuarie',
            'Februarie',
            'Martie',
            'Aprilie',
            'Mai',
            'Iunie',
            'Iulie',
            'August',
            'Septembrie',
            'Octombrie',
            'Noiembrie',
            'Decembrie',
        ];
    }
    return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
};

export const getDayBeginningAndEnding = (date) => {
    const startDate = new Date(date);
    const endDate = new Date(date);

    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(999);

    return { startDate, endDate };
};

/**
 * ? We use this function to remove the offset for dates that have different daylight saving time (summer time for example)
 * @param {Date | String | Number} date - The date
 * @returns {number} Normalized time stamp
 */
export const normalizeDate = (date) => new Date(date).getTime() - Math.abs(new Date(date).getTimezoneOffset() * 60000);

/**
 * The function `formatDate` formats a given date into a string with the day, month, and year
 * (optional).
 * @param {Date | String | Number} date - The `date` parameter is the input date that you want to format. It can be a string
 * representing a date in a valid format, such as "2021-01-01" or "January 1, 2021", or it can be a
 * JavaScript `Date` object.
 * @param {Boolean} withYear - The `withYear` parameter is a boolean value that determines whether or not
 * to include the year in the formatted date. If `withYear` is `true`, the formatted date will include
 * the year. If `withYear` is `false`, the formatted date will not include the year.
 * @returns {String} The function `formatDate` returns a formatted date string. If the `withYear` parameter is
 * `true`, the function returns a string in the format "day month year" (e.g., "25 DEC 2021"). If
 * the `withYear` parameter is `false` or not provided, the function returns a string in the format
 * "day month" (e.g., "25 DEC").
 */
export const formatDate = (date, withYear = true, numeric = false) => {
    const jsDate = new Date(date);
    const day = jsDate.getDate();
    const month = numeric ? jsDate.getMonth() + 1 : monthMapper[jsDate.getMonth()];
    const separator = numeric ? '.' : ' ';

    if (withYear) return `${day}${separator}${month}${separator}${jsDate.getFullYear()}`;
    else return `${day}${separator}${month}`;
};

/**
 * The `slugify` function takes a string as input and returns a slugified version of the string by
 * converting it to lowercase, replacing spaces with underscores, and removing special characters.
 * @param {String} string - The `string` parameter is the input string that you want to slugify.
 */
export const slugify = (string) =>
    string
        .toLowerCase()
        .replace(/ /g, '_')
        .replace(/[!@#$%^&?.\\/*()\[\]\:;""]/g, '');

/**
 * The function generates a random hexadecimal color value.
 * @returns {String} The function `generateHexValue` returns a randomly generated hexadecimal color value in the
 * format `#xxxxxx`, where `x` represents a hexadecimal digit.
 */
export const generateHexValue = () => {
    let aux = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + aux.slice(0, 6);
};

/**
 * The function generates a random RGB value by generating random values for red, green, and blue
 * components.
 * @returns {object} An object with three properties: r, g, and b. Each property represents a random integer
 * value between 0 and 255.
 */
export const generateRGBValue = () => {
    const F = Math.floor;
    const R = Math.random;
    const r = F(R() * 255);
    const g = F(R() * 255);
    const b = F(R() * 255);
    return { r, g, b };
};

// Date & time

/**
 * The `formatTime` function takes a date and returns a formatted string representing the time in hours
 * and minutes.
 * @param {Date | String | Number} date - The `date` parameter is a string, number or date javascript object representing a date and time in a specific format.
 * @returns {String} The function `formatTime` returns a formatted time string in the format "hh:mm".
 */
export const formatTime = (date) => {
    const jsDate = new Date(date);

    let hh = jsDate.getHours();
    if (hh < 10) hh = '0' + hh;

    let mm = jsDate.getMinutes();
    if (mm < 10) mm = '0' + mm;
    return `${hh}:${mm}`;
};

/**
 * The function calculates the number of days between a given date and the current date.
 * @param {Date | String | Number} date - The `date` parameter is the date for which you want to calculate the number of days
 * from. It should be a valid date string that can be parsed by the `Date` constructor, such as
 * "2021-01-01" or "January 1, 2021".
 * @returns {Number} the number of days between the current date and the provided date.
 */
export const daysFrom = (date) => {
    const nowDate = new Date().getTime();
    const jsDate = new Date(date).getTime();
    const days = Math.floor((nowDate - jsDate) / (1000 * 60 * 60 * 24));
    return days;
};

// Server checks

/**
 * The function `whoAmI` makes an asynchronous request to the backend API to retrieve the user data and
 * returns the user object if successful, otherwise it returns null.
 * @returns {Object | null} the user data from the API response if the request is successful. If there is an error, it
 * will return null.
 */
export const whoAmI = async () => {
    try {
        const res = await API.get(`${process.env.REACT_APP_BACKEND_URL}me`);
        return res.data.user;
    } catch {
        return null;
    }
};

/**
 * The function `checkDomain` makes an asynchronous request to the backend API to check if a subdomain
 * exists and returns a boolean value indicating the success of the request.
 * @returns {Boolean} The function `checkDomain` is returning a boolean value. It returns `true` if the response
 * status from the API call is 200, indicating a successful request. If there is an error or the
 * response status is not 200, it returns `false`.
 */
export const checkDomain = async () => {
    try {
        const res = await API.get(`${process.env.REACT_APP_BACKEND_URL}subdomain`);
        return res.status === 200;
    } catch (err) {
        console.error(err);
        return false;
    }
};

/**
 * The function "getMonthDays" returns the number of days in a given month and year.
 * @param {Number} month - The month parameter represents the month of the year, with January being 0 and
 * December being 11.
 * @param {Number} year - The year parameter is the year for which you want to get the number of days in a
 * specific month.
 * @returns {Number} The number of days in the specified month and year.
 */
export const getMonthDays = (month, year) => {
    const days = new Date(year, month, 0).getDate();
    return days;
};

// Economy

/**
 * The function calculates the value added tax (VAT) amount based on the value and VAT rate provided.
 * @param {Number | String} value - The value parameter represents the original value of a product or service. It is a
 * numeric value that indicates the cost of the item before any taxes or additional charges are
 * applied.
 * @param {Number | String} VAT - The "VAT" parameter represents the value-added tax rate as a percentage.
 * @returns {Number} the calculated value of TVA (Taxe sur la Valeur Ajoutée) based on the given value and VAT
 * rate.
 */
export const calculateVATValue = (value, VAT) => {
    return (Number(VAT) / 100) * Number(value);
};

/**
 * The function calculates the total price by adding margin and the price.
 * @param {Number | String} margin - The value of a discount or a price reduction.
 * @param {Number | String} price - The price of the item before any changes or discounts.
 * @returns {Number} The sum of the price and margin values.
 */
export const calculateTotal = (margin, price) => {
    return Number(margin) + Number(price);
};

/**
 * The function calculates the sum of a value with VAT included.
 * @param {Number | String} value - The value parameter represents the original value or amount before adding VAT.
 * @param {Number | String} VAT - The VAT parameter represents the percent-added tax rate.
 * @returns {Number} The sum of the original value and the value calculated by the calculateVATValue function, after
 * converting them to numbers.
 */
export const calculateSumWithVAT = (value, VAT) => {
    return calculateVATValue(value, VAT) + Number(value);
};

// ! Used in old budgetary components
export const expenseTypes = ['OPERATIONAL', 'FINANCIAL', 'EXTRAORDINARY'];

// File Upload

/**
 * The function `uploadSingleFile` is a JavaScript function that validates and uploads a single file
 * based on its type and size, and then calls a callback function with the result.
 * @param {File} e - The "e" parameter is an event object that is passed when the file input element's value
 * changes. It is used to access the selected file.
 * @param {Function} callback - The `callback` parameter is a function that will be called with an object as its
 * argument. This object contains information about the uploaded file, such as the file itself
 * (`blob`), the file name (`info.name`), and any error message (`message`).
 * @param {String} type - The `type` parameter is used to specify the type of file that can be uploaded. It can
 * have three possible values: 'image', 'file', or 'all'.
 */
export const uploadSingleFile = async (e, callback, type) => {
    const file = e.target.files[0];

    let typeValidation;
    if (type == 'image') {
        typeValidation = ['image/jpeg', 'image/png'];
    } else if (type == 'file') {
        typeValidation = ['application/pdf'];
    } else if (type == 'all') {
        typeValidation = ['image/jpeg', 'image/png', 'application/pdf'];
    }

    if (file.size / 1024 / 1024 > 2) {
        callback({
            message: 'The file is bigger than 2 MB limit!',
            blob: null,
            info: null,
        });
    } else {
        if (typeValidation.find((type) => type === file.type)) {
            try {
                callback({
                    message: null,
                    blob: file,
                    info: {
                        name: file.name,
                    },
                });
            } catch (err) {
                console.error('Failed to upload file from:', e.target);
                console.error(err);
                callback({
                    message: 'Something went wrong! Please try uploading the file again!',
                    blob: null,
                    info: null,
                });
            }
        } else {
            callback({
                message:
                    type == 'image'
                        ? 'Wrong file type! Only JPEG, PNG formats are supported!'
                        : type == 'file'
                          ? 'Wrong file type! Only PDF format is supported!'
                          : 'Wrong file type! Only JPEG, PNG and PDF formats are supported!',
                blob: null,
                info: null,
            });
        }
    }
};

/**
 * The `uploadMultiFiles` function is a JavaScript function that handles the validation and uploading
 * of multiple files based on their type.
 * @param {File} e - The event object that is triggered when the user selects files to upload.
 * @param {Function} callback - The `callback` parameter is a function that will be called with an object as an
 * argument. This object contains information about the uploaded files, such as the message (if any),
 * the files themselves (as an array of blobs), and additional information about each file (such as the
 * file name).
 * @param {String} type - The `type` parameter is a string that determines the type of files that can be
 * uploaded. It can have three possible values: "image", "file", or "all".
 */
export const uploadMultiFiles = async (e, callback, type) => {
    const file = e.target.files;

    let typeValidation;
    if (type == 'image') {
        typeValidation = ['image/jpeg', 'image/png'];
    } else if (type == 'file') {
        typeValidation = ['application/pdf'];
    } else if (type == 'all') {
        typeValidation = ['image/jpeg', 'image/png', 'application/pdf'];
    }

    if (Array.from(file).reduce((sum, currentFile) => (sum += currentFile.size), 0) / 1024 / 1024 > 10) {
        callback({
            message: 'The files are bigger than 10 MB limit!',
            blob: null,
            info: null,
        });
    } else {
        if (Array.from(file).every((f) => typeValidation.find((type) => type === f.type))) {
            try {
                callback({
                    message: null,
                    blob: Array.from(file),
                    info: Array.from(file).map((f) => ({
                        name: f.name,
                    })),
                });
            } catch (err) {
                console.error('Failed to upload files from:', e.target);
                console.error(err);
                callback({
                    message: 'Something went wrong! Please try uploading the files again!',
                    blob: null,
                    info: null,
                });
            }
        } else {
            callback({
                message:
                    type == 'image'
                        ? 'Wrong file type! Only JPEG, PNG formats are supported!'
                        : type == 'file'
                          ? 'Wrong file type! Only PDF format is supported!'
                          : 'Wrong file type! Only JPEG, PNG and PDF formats are supported!',
                blob: null,
                info: null,
            });
        }
    }
};

//Financial and math

/**
 * The function `formatPositiveNumber` takes a value and removes any non-numeric characters, returning
 * the resulting number.
 * @param {Number | String} value - The `value` parameter is the input number that needs to be formatted.
 * @returns {String }The function `formatPositiveNumber` returns a formatted positive number.
 */
export const formatPositiveNumber = (value) => {
    if (!value.length) return '';
    return Number(String(value).replace(/[^0-9]+/g, ''));
};

/**
 * The formatVATnumber function formats a VAT number by ensuring it is a number between 0 and 100 with
 * up to two decimal places.
 * @param {Number | String} value - The `value` parameter is the VAT number that needs to be formatted.
 * @returns {Number} The function `formatVATnumber` returns a formatted VAT number. If the input value is
 * greater than 100, it returns 100. If the input value matches the regular expression pattern, it
 * returns the value as a number with a maximum of 2 decimal places. Otherwise, it returns 0.
 */
export const formatVATnumber = (value) => {
    if (Number(value) > 100) return 100;
    const regex = /^(\d{0,2}(\.\d+)?|100(\.0+)?)$/;
    if (regex.test(value)) return Number(String(value).replace(/^(\d+)\.(\d{0,2})\d*$/, '$1.$2'));
    else return 0;
};

/**
 * The function formatPositiveNumberWithDigits takes a value as input and returns the number if it is a
 * positive number with digits, otherwise it returns 0.
 * @param {Number | String} value - The `value` parameter is the number that you want to format.
 * @returns {Number} he input value as a number if it is a positive number with digits. If the input value does
 * not match the regex pattern, it returns 0.
 */
export const formatPositiveNumberWithDigits = (value) => {
    const regex = /^-?\d*\.?\d+$/;
    if (regex.test(value)) return Number(value);
    return 0;
};

/**
 * The formatExchangeRate function checks if a value is a positive number and returns it, otherwise it
 * returns 0.0001.
 * @param {Number | String} value - The `value` parameter represents the exchange rate value that needs to be formatted.
 * @returns {Number} The function `formatExchangeRate` returns a number. If the input value passes the regular
 * expression test and is greater than 0, the function returns the input value as a number. Otherwise,
 * it returns the value 0.0001.
 */
export const formatExchangeRate = (value) => {
    const regex = /^-?\d*\.?\d+$/;
    if (regex.test(value) && Number(value) > 0) return Number(value);
    return 0.0001;
};

/**
 * The formatNumber function takes a value and returns a formatted number with up to 6 decimal places.
 * @param {Number | String} value - The `value` parameter is the input value that needs to be formatted as a number.
 * @returns {Number} The function `formatNumber` returns a formatted number.
 */
export const formatNumber = (value) => {
    if (!value.length) return '';
    return Number(String(value).replace(/^-?\d*\.?\d{0,6}$/, ''));
};

/**
 * The function `toLocaleNumber` converts a number to a localized string representation with a
 * specified number of decimal places.
 * @param {Number | String} value - The value parameter is the number that you want to convert to a localized number
 * format.
 * @param {Number} minimumDecimals - The minimum number of decimal places to display in the formatted
 * number. If the value has fewer decimal places than the minimum, zeros will be added to meet the
 * minimum requirement.
 * @param {Number} maximumDecimals - The `maximumDecimals` parameter specifies the maximum number of decimal
 * places to display in the formatted number.
 * @returns {String} a formatted number as a string, using the `toLocaleString` method.
 */
export const toLocaleNumber = (value, minimumDecimals = 0, maximumDecimals = 2) => {
    return Number(value).toLocaleString(i18n.resolvedLanguage, {
        minimumFractionDigits: minimumDecimals,
        maximumFractionDigits: maximumDecimals,
    });
};

export const toRoundedSignificantDigits = (value, digits = 2) => Number(Number(value).toFixed(digits));

/**
 * The function `displayNumber` takes a value and returns it formatted as a number with a specified
 * number of decimal places.
 * @param {Number | String} value - The `value` parameter is the number that you want to display.
 * @param {Number} minimumDecimals - The `minimumDecimals` parameter specifies the minimum number of decimal places
 * to display in the formatted number. If the number has fewer decimal places than `minimumDecimals`, zeros
 * will be added to the end.
 * @param {Number} maximumDecimals - The `maximumDecimals` parameter specifies the maximum number of decimal places
 * to display in the formatted number.
 * @returns {String} The function `displayNumber` is returning the result of calling either
 * `toLocaleNumber(value, minimumDecimals, maximumDecimals)` or `toLocaleNumber(0, minimumDecimals, maximumDecimals)`,
 * depending on whether `Number(value)` is finite or not.
 */
export const displayNumber = (value, minimumDecimals = 2, maximumDecimals = 2) => {
    return isFinite(Number(value))
        ? toLocaleNumber(value, minimumDecimals, maximumDecimals)
        : toLocaleNumber(0, minimumDecimals, maximumDecimals);
};

// Utils

/**
 * ? This function returns a custom message provided by the backend in the case when the errors property is present,
 * the default message generated by the backend when the status is 400 and a generic one when the status is 500
 * @param {Error} err - The error object
 * @returns {String} an error message
 */
export const errorHandling = (err) => {
    if ('errors' in err) {
        return err.errors[0];
    } else if (err.response.status === 400) {
        return err.response.data.message;
    } else if (err.response.status === 500) {
        return 'error-message';
    }
};

// ? An array of different RGB colors used in the gantt component in order to display different colors
export const ganttColorPallete = [
    '238, 155, 0',
    '10, 147, 150',
    '139, 107, 199',
    '187, 62, 3',
    '75, 150, 217',
    '231, 111, 81',
    '2, 116, 0',
    '161, 66, 180',
    '6, 167, 105',
    '42, 117, 184',
];

/**
 * This functions receives the RGB values of a color and converts them to a hex
 * @param {Number | String} r - Red value
 * @param {Number | String} g - Green value
 * @param {Number | String} b - Blue value
 * @returns {String} the color as a hex
 */
export const rgbToHex = (r, g, b) => {
    return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
};

/**
 * This functions receives the hex values of a color and converts it to a RGB color
 * @param {Number | String} hex - Color in hex
 * @returns {String | null} the color as a RGB string if the conversion is successful and null otherwise
 */
export const hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (r, g, b) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)}` : null;
};

/**
 * ? This function is used to get the row content of a file from a specific path in the project
 * @param {String} path - The path to the file in the project files
 * @returns {String} a file in a base64 format
 */
export const getRawFileFromPath = async (path, onlyBlob = false) => {
    const response = await fetch(path, {
        headers: {
            responseType: 'arraybuffer',
            'Access-Control-Allow-Origin': '*',
        },
    });
    const blob = await response.blob();
    if (onlyBlob) return blob;

    const base64String = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });

    return base64String;
};

// ? Represents a theme object used to in case the tenant has no themes added to it
export const defaultTheme = {
    id: generateUUID(),
    name: 'Business (default)',
    theme: JSON.stringify({
        'UI-COLORS': {
            'MAIN-PRIMARY': { varName: '--base-primary-main', color: '44 126 220' },
            'LIGHT-PRIMARY': { varName: '--base-primary-light', color: '50 140 248' },
            'DARK-PRIMARY': { varName: '--base-primary-dark', color: '45 122 255' },
            'MAIN-SECONDARY': { varName: '--base-secondary-main', color: '156 124 53' },
            'LIGHT-SECONDARY': { varName: '--base-secondary-light', color: '199 163 83' },
            'LIGHTER-SECONDARY': { varName: '--base-secondary-lighter', color: '216 191 135' },
            'GRADIENT-LIGHT': { varName: '--base-gradient-light', color: '17 81 139' },
            'GRADIENT-DARK': { varName: '--base-gradient-dark', color: '1 29 80' },
        },
        'LAYOUT-COLORS': {
            'MAIN-LAYOUT': { varName: '--base-layout-main', color: '20 20 20' },
            'LIGHT-LAYOUT': { varName: '--base-layout-light', color: '32 32 32' },
            'LIGHTER-LAYOUT': { varName: '--base-layout-lighter', color: '56 56 56' },
            'TRANSPARENT-LAYOUT': { varName: '--base-layout-transparent', color: '255 255 255' },
        },
        'STATE-COLORS': {
            ERROR: { varName: '--base-error', color: '255 105 105' },
            SUCCESS: { varName: '--base-success', color: '55 169 133' },
            WARNING: { varName: '--base-warning', color: '245 158 11' },
            DISABLED: { varName: '--base-disabled', color: '175 175 175' },
        },
        'TEXT-COLORS': {
            'MAIN-TEXT': { varName: '--base-main-text', color: '255 255 255' },
            'SECONDARY-TEXT': { varName: '--base-secondary-text', color: '156 124 53' },
            'DARK-TEXT': { varName: '--base-dark-text', color: '215 215 215' },
            'SIDEBAR-TEXT': { varName: '--base-sidebar-text', color: '255 255 255' },
            'BUTTONS-TEXT': { varName: '--base-buttons-text', color: '255 255 255' },
            'GRADIENT-TEXT': { varName: '--base-gradient-text', color: '255 255 255' },
        },
        'PDF-COLORS': {
            'PDF-TEXT': { varName: '--base-pdf-text', color: '0 0 0' },
            'PDF-LINK-TEXT': { varName: '--base-pdf-link-text', color: '45 122 255' },
            'PDF-LAYOUT-TEXT': { varName: '--base-pdf-layout-text', color: '255 255 255' },
            'PDF-LAYOUT-BACKGROUND': { varName: '--base-pdf-layout-background', color: '44 126 220' },
        },
    }),
};

export const findParentWithCSS = (element, property, value) => {
    while (element !== null) {
        const style = window.getComputedStyle(element);
        const propValue = style.getPropertyValue(property);
        if (propValue === value) {
            return element;
        }
        element = element.parentElement;
    }
    return null;
};

export const findParentWithoutCSS = (element, property, value) => {
    while (element !== null) {
        const style = window.getComputedStyle(element);
        const propValue = style.getPropertyValue(property);
        if (propValue !== value) {
            return element;
        }
        element = element.parentElement;
    }
    return null;
};

export function getTextHeight(text, classes, width = null) {
    const element = getTextHeight.element || (getTextHeight.element = document.getElementById('render-text-root'));

    element.textContent = text;
    element.classList.add(...classes.trim().split(' '));
    if (width !== null) element.style.width = `${width}px`;

    const elementHeight = element.clientHeight + 1;

    element.textContent = '';
    element.classList.remove(...classes.trim().split(' '));
    if (width !== null) element.style.width = '';

    return elementHeight;
}

export const remToPx = (rem) => parseFloat(rem) * parseFloat(getComputedStyle(document.documentElement).fontSize);
