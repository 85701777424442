import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';

import { Button, TextField } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { configurator } from 'routes';
import API from 'utils/axios';
import * as yup from 'yup';

const CreateZone = () => {
    const [zoneName, setZoneName] = useState('');
    const [observation, setObservation] = useState('');
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '12',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    let schema = yup.object().shape({
        zoneName: yup
            .string()
            .trim()
            .typeError(t('Area name must be at least 3 characters long!'))
            .required(t('Area name must be at least 3 characters long!')),
    });

    const createZone = useCallback(async () => {
        try {
            await schema.validate({ zoneName });
            try {
                await API.post('/areas', {
                    name: zoneName,
                    description: observation,
                });
                history.push(configurator.base + `?tab=Areas %26 Projects`);
                enqueueSnackbar(t('Area successfully added!'), { variant: 'success' });
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t('The area could not be added!'), { variant: 'error' });
            }
        } catch (err) {
            console.warn(err.errors);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    }, [zoneName, observation]);

    return (
        <>
            <Helmet>
                <title>
                    {t('Areas & Projects')} | {t('Add new area')}
                </title>
            </Helmet>

            <Header pageTitle={t('Add new area')} />

            {canAll && (
                <div className="page-container">
                    <div className="w-96 sm:w-full">
                        <h4 className="mb-10 text-2xl text-gray-300">{t('Area details')}</h4>
                        <div className="mb-5">
                            <TextField
                                name={'Nume zonă'}
                                label={t('Area name')}
                                placeholder={t('Add a name for the area')}
                                value={zoneName}
                                onChange={(e) => setZoneName(e.target.value)}
                            />
                        </div>

                        <div className="mb-5">
                            <TextField
                                name="descriere"
                                label={t('Observations')}
                                placeholder={t('Add observations')}
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                                multiline
                                rows={3}
                            />
                        </div>

                        <div className="mt-28">
                            <Button size="large" color="primary" startIcon={<AddIcon />} onClick={createZone}>
                                {t('Create area')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateZone;
