import React from 'react';

import PropTypes from 'prop-types';

const ValueDisplay = ({ label, value, alias }) => {
    return (
        <div className="flex h-full min-w-max flex-col justify-between gap-1 rounded-md bg-layout-transparent-dark p-1.5">
            <p className="text-sm">{label}</p>
            <p className="text-lg">
                {value} <span className="font-bold text-secondary-text">{alias}</span>
            </p>
        </div>
    );
};

ValueDisplay.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    alias: PropTypes.string,
};

export default ValueDisplay;
