import React, { useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { Button, TextField } from '@material-ui/core';

import ActivityContext from 'contexts/ActivityContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const CommentActivity = (props) => {
    const { departmentId, partnerId, tenantUsers, activity, contactId } = props;

    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const activityContext = useContext(ActivityContext);

    const { enqueueSnackbar } = useSnackbar();

    const [title, setTitle] = useState('');
    const [comment, setComment] = useState('');
    const [responsible, setResponsible] = useState([]);

    /*
        If activity is provided fill the fields
    */
    useEffect(() => {
        if (!activity) return;
    }, [activity]);

    const getUsersIds = (usersList) => {
        return usersList.map((user) => tenantUsers[user].id);
    };

    let schema = yup.object().shape({
        title: yup.string(t('Title cannot be empty!')).required(t('Title cannot be empty!')),
        comment: yup
            .string(t("The comment doesn't have to be empty!"))
            .required(t("The comment doesn't have to be empty!")),
        responsible: yup
            .array()
            .typeError(t('responsible-ms'))
            .min(1, t('Add at least 1 responsible!'))
            .required(t('responsible-ms')),
    });

    const createCommentActivity = async () => {
        if (activityContext.isCreating) return;
        const reqBody = { title, comment, responsible };

        try {
            await schema.validate(reqBody);

            try {
                const formData = new FormData();
                const reqBody = {
                    title,
                    description: comment,
                    note: '-',
                    actionType: 'COMMENT',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible),
                    supervisor: [],
                    watcher: [],
                    partnerId: partnerId ? partnerId : undefined,
                    departmentId,
                };

                if (contactId) reqBody.contactId = contactId;

                formData.append('data', JSON.stringify(reqBody));
                activityContext.submitNewActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    const editCommentActivity = async () => {
        if (activityContext.isCreating) return;
        const reqBody = { title, comment, responsible };

        try {
            await schema.validate(reqBody);

            try {
                const formData = new FormData();
                const reqBody = {
                    title,
                    description: comment,
                    note: '-',
                    actionType: 'COMMENT',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible),
                    supervisor: [],
                    watcher: [],
                    partnerId: partnerId ? partnerId : undefined,
                    departmentId,
                };

                if (contactId) reqBody.contactId = contactId;

                formData.append('id', JSON.stringify(activityContext.activityForEdit.id));
                formData.append('data', JSON.stringify(reqBody));
                activityContext.editActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    useEffect(() => {
        if (activityContext.activityForEdit !== null && activityContext.activityForEdit.actionType === 'COMMENT') {
            setTitle(activityContext.activityForEdit.title);
            setComment(activityContext.activityForEdit.description);
            setResponsible(
                activityContext.activityForEdit.users.map((user) =>
                    tenantUsers.findIndex((us) => us.id === user.userId)
                )
            );
        }
    }, [activityContext.activityForEdit]);

    return departmentId ? (
        <form>
            <div className="mb-5">
                <TextField
                    placeholder={t('Comment name')}
                    label={t('Comment name')}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>

            <div className="mb-5">
                <TextField
                    placeholder={t('Write a comment...')}
                    multiline
                    label={t('Comment')}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={3}
                />
            </div>

            <div className="mb-10">
                <LabelWrapper label={t('Responsible')}>
                    <MultiDropdown
                        variant="black"
                        options={tenantUsers.map((user) => user.profile?.name)}
                        placeholder={t('Choose user')}
                        selectedOptions={responsible}
                        setSelectedOptions={(i) => {
                            if (responsible?.indexOf(i) > -1) {
                                setResponsible(responsible.filter((opt) => opt !== i));
                            } else {
                                setResponsible([...responsible, i]);
                            }
                        }}
                    />
                </LabelWrapper>
            </div>

            <Button
                color="secondary"
                startIcon={activityContext.activityForEdit !== null ? <EditIcon /> : <AddIcon />}
                onClick={activityContext.activityForEdit !== null ? editCommentActivity : createCommentActivity}
            >
                {`${activityContext.activityForEdit !== null ? t('Edit') : t('Add')} ${t('Comment')}`}
            </Button>
        </form>
    ) : (
        <></>
    );
};

CommentActivity.propTypes = {
    departmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    partnerId: PropTypes.string,
    tenantUsers: PropTypes.arrayOf(PropTypes.object),
    activity: PropTypes.object,
    contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CommentActivity.defaultProps = {
    departmentId: null,
    partnerId: null,
    tenantUsers: [],
    activity: null,
    contactId: null,
};

export default CommentActivity;
