import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import Loading from 'components/shared/loading';
import OfferContext from 'contexts/OfferContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Tags, Toggle } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { configurator, quotes } from 'routes';
import API from 'utils/axios';
import { errorHandling } from 'utils/index';
import * as yup from 'yup';

const OfferTags = ({ viewOnly }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const { offerContext, editOfferContext } = useContext(OfferContext);
    const { checkPerm } = useContext(UserContext);

    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const [sendEmail, setSendEmail] = useState(false);
    const [emailSubject, setEmailSubject] = useState('');
    const [emailDescription, setEmailDescription] = useState('');

    const allTags = tags.map((c) => ({
        id: c.id,
        name: c.name,
        value: c.id,
    }));

    const canSendEmail = checkPerm([
        {
            permissionId: '31',
            permissionType: 'VIEW',
        },
    ]);

    const handleSetInitialTags = (offer) => {
        const newSelectedTags = offer.OfTags.map((a) => ({
            id: a.offerTagsId,
            name: a.offerTags.name,
            value: a.offerTagsId,
        }));

        setSelectedTags(newSelectedTags);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/offer_tags', {
                    params: {
                        pagesToLoad: 1,
                        currentPage: 0,
                        perPage: 99999,
                    },
                });

                setTags(response.data.data.offerTag);

                // we add in articles an empty selectedAttributes and selectedVariations for attributes and variants dropdowns
                if (offerContext.offer && offerContext.offer.OfTags.length) handleSetInitialTags(offerContext.offer);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const schema = yup.object().shape({
        emailDescription: yup
            .string(t('The description of the email is required!'))
            .trim()
            .typeError(t('The description of the email is required!'))
            .required(t('The description of the email is required!')),
        emailSubject: yup
            .string(t('The subject of the email is required!'))
            .trim()
            .typeError(t('The subject of the email is required!'))
            .min(3, t('The subject of the email must be at least 3 characters long!'))
            .required(t('The subject of the email is required!')),
    });

    const addOfferTags = async () => {
        try {
            if (sendEmail) {
                await schema.validate({
                    emailSubject,
                    emailDescription,
                });
            }

            try {
                setLoading(true);
                const res = await API.post(`/offer_completion/${offerContext.offerId}/TAG`, {
                    offerTagsIds: selectedTags?.map((tag) => ({
                        id: tag.id,
                    })),
                    subject: emailSubject,
                    message: emailDescription,
                    sendEmail,
                });

                await API.post('/offer_status', {
                    id: offerContext.offerId,
                    status: 'CREATED',
                });

                enqueueSnackbar(
                    t(
                        sendEmail
                            ? offerContext.offer?.originalOfferId
                                ? 'Revision was successfully created and mailed to client!'
                                : 'Offer was successfully created and mailed to client!'
                            : offerContext.offer?.originalOfferId
                            ? 'Revision was successfully created!'
                            : 'Offer was successfully created!'
                    ),
                    {
                        variant: 'success',
                    }
                );

                editOfferContext('offer', res.data.offer);
                setLoading(false);
                history.push(quotes.base + quotes.biddingHistory.base + quotes.biddingHistory.read);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className="flex max-w-3xl flex-col items-start">
                    {tags.length > 0 && allTags.length > 0 && ((selectedTags.length > 0 && viewOnly) || !viewOnly) && (
                        <div className="relative z-40 mb-10 flex flex-wrap rounded-md border border-layout-lighter px-4 py-1 pb-0">
                            <p className="mr-2 flex h-10 items-center">{t('Offer tags')}</p>
                            <div>
                                <Tags
                                    tags={selectedTags}
                                    setTags={setSelectedTags}
                                    allTags={allTags}
                                    disabled={viewOnly}
                                />
                            </div>
                        </div>
                    )}

                    {tags.length === 0 && allTags.length === 0 && !viewOnly && (
                        <div className="mb-10 flex flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                            <p className="mb-5">{t('There are no offer tags yet, you have to add one first!')}</p>
                            <Button
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        configurator.base + configurator.offerTags.base + configurator.offerTags.create
                                    )
                                }
                            >
                                {t('Add offer tags')}
                            </Button>
                        </div>
                    )}

                    {selectedTags.length === 0 && viewOnly && (
                        <div className="flex w-full max-w-3xl items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                            <p>{t('There are no tags on this offer!')}</p>
                        </div>
                    )}

                    {canSendEmail && !viewOnly && (
                        <div className={`text-main-text ${!sendEmail ? 'mb-20' : 'mb-5'}`}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={sendEmail}
                                        onChange={(e) => setSendEmail(e.target.checked)}
                                        name="email-notification"
                                        style={{ color: 'var(--success)' }}
                                    />
                                }
                                label={t('Send offer PDF through email')}
                            />
                        </div>
                    )}

                    {sendEmail && !viewOnly && (
                        <div className="w-full">
                            <div className="mb-2 w-full">
                                <TextField
                                    name="offer name"
                                    placeholder={t('Email subject')}
                                    value={emailSubject}
                                    onChange={(e) => setEmailSubject(e.target.value)}
                                />
                            </div>
                            <div className="mb-20 w-full">
                                <TextField
                                    name="offer description"
                                    placeholder={t('Email description')}
                                    value={emailDescription}
                                    onChange={(e) => setEmailDescription(e.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </div>
                        </div>
                    )}
                    {!viewOnly && (
                        <div className="flex gap-6">
                            <Button
                                color="primary"
                                startIcon={<ArrowBackIosIcon />}
                                onClick={() => editOfferContext('step', 3)}
                            >
                                {t('Step')} 4
                            </Button>
                            <Button color="secondary" startIcon={<DoneAllIcon />} onClick={addOfferTags}>
                                {offerContext.offer?.originalOfferId
                                    ? t('Completion of the revision')
                                    : t('Completion of the offer')}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

OfferTags.propTypes = {
    viewOnly: PropTypes.bool,
};

OfferTags.defaultProps = { viewOnly: false };

export default OfferTags;
