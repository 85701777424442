import API from 'utils/axios';

export const AccountingAccounts = {
    async getAll() {
        try {
            const response = await API.get('/accounting_accounts/RO', {
                params: {
                    perPage: 9999,
                    currentPage: 0,
                    pagesToLoad: 1,
                },
            });
            return { ok: true, data: response.data.accounts };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },
};
