import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { Header, Search, TableSeparator } from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const AccountRow = ({ account }) => {
    // const history = useHistory();
    // const { t } = useTranslation();

    return (
        <TableRow>
            <TableCell>{account.name}</TableCell>
            <TableCell>{account.code}</TableCell>
        </TableRow>
    );
};

AccountRow.propTypes = {
    account: PropTypes.object,
};

AccountRow.defaultProps = {
    account: null,
};

const AccountingAccounts = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { permissionMap } = useContext(UserContext);
    const canView = permissionMap['Conturi contabile'] > 0;

    useEffect(() => {
        if (!canView) history.push('/');
    }, [canView]);

    const [loading, setLoading] = useState(true);

    const [accounts, setAccounts] = useState([]);

    const [search, setSearch] = useState('');

    const getAccounts = async (currentPage, perPage) => {
        try {
            const res = await API.get('/accounting_accounts/RO', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            setAccounts(res.data.accounts);
            return res.data.count;
        } catch (err) {
            throw new Error(err);
        }
    };

    // TODO:  Implement search for accounts <--BACK--> // ON NAME AND CODE

    return (
        <>
            <Helmet>
                <title>{t('Accounting accounts')}</title>
            </Helmet>

            <Header
                pageTitle={
                    <div className="flex w-full flex-wrap items-center gap-8 sm:gap-4">
                        <span>{t('Accounting accounts')}</span>

                        <Search searchIconBig={true} value={search} setValue={setSearch} withSearchIcon={false} />
                    </div>
                }
            />

            <div className="page-container">
                <Pagination loading={loading} setLoading={setLoading} getFunction={getAccounts} perPageItems={20}>
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : accounts.length ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Accounting account name')}</TableCell>
                                        <TableCell>{t('Accounting account code')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {accounts.map((account) => (
                                        <AccountRow key={account.id} account={account} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </Pagination>
            </div>
        </>
    );
};

export default AccountingAccounts;
