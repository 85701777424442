import React, { useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Chip } from '@material-ui/core';

import PropTypes from 'prop-types';

import PipelineItemBody from './pipeline-item-body';
import PipelineItemHeader from './pipeline-item-header';

const PipelineItem = ({ itemProps, color, backgroundColor, item }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="flex flex-col gap-4 rounded-md bg-layout-lighter px-4 py-6 shadow">
            <Chip
                size="small"
                variant="outlined"
                style={{ color, borderColor: backgroundColor, fontSize: '1.25rem', borderWidth: '2px' }}
                label={itemProps.chip.render(item)}
            />
            <PipelineItemHeader itemProps={itemProps} item={item} open={open} />
            <PipelineItemBody
                itemProps={itemProps}
                item={item}
                open={open}
                color={color}
                backgroundColor={backgroundColor}
            />
            <div
                className={`mx-auto mt-1 transform cursor-pointer transition-transform duration-300 ${
                    open ? 'rotate-180' : 'rotate-0'
                }`}
                onClick={() => setOpen((prev) => !prev)}
            >
                <ExpandMoreIcon fontSize="large" />
            </div>
        </div>
    );
};

PipelineItem.propTypes = {
    itemProps: PropTypes.object,
    item: PropTypes.object,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
};

PipelineItem.defaultProps = {
    itemProps: {},
    item: {},
    color: 'var(--main-text)',
    backgroundColor: 'var(--primary-main)',
};

export default PipelineItem;
