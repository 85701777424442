import React, { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';

import { Button } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import useScreenSizes from 'hooks/useScreenSizes.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DocModal from './doc-modal';
import DocRow from './doc-row';

const DocsLayout = (props) => {
    const { t } = useTranslation();
    const { type, files, setFiles, utilityOptions, entity, view } = props;
    const [open, setOpen] = useState(false);
    const [width] = useScreenSizes();

    return (
        <>
            <div className="flex flex-col gap-4">
                {files.length > 0 && (
                    <div className="flex flex-col gap-2">
                        {files.map((f) => (
                            <DocRow
                                key={f.id}
                                file={f}
                                files={files}
                                utilityOptions={utilityOptions}
                                entity={entity}
                                setFiles={setFiles}
                            />
                        ))}
                    </div>
                )}
                {!view && (
                    <span>
                        <Button startIcon={<AddIcon />} color="primary" onClick={() => setOpen(true)}>
                            {type === 'Uni-select' ? t('Add document') : t('Add documents')}
                        </Button>
                    </span>
                )}
            </div>
            {open && (
                <CustomModal
                    open={open}
                    setOpen={setOpen}
                    styles={{ minWidth: width < 600 ? '90vw' : width < 1000 ? '75vw' : '750px' }}
                >
                    <DocModal type={type} files={files} setFiles={setFiles} setOpen={setOpen} />
                </CustomModal>
            )}
        </>
    );
};

DocsLayout.propTypes = {
    entity: PropTypes.string,
    type: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.object),
    setFiles: PropTypes.func,
    utilityOptions: PropTypes.object,
    view: PropTypes.bool,
};

DocsLayout.defaultProps = {
    view: false,
    entity: null,
    type: 'Uni-select',
    files: [],
    setFiles: () => {},
    utilityOptions: { canDelete: false, canCopyLink: false, canDownload: false },
};

export default DocsLayout;
