import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import SubMilestoneActivity from 'components/internal-activity/milestone-activity';
import ActivityContext from 'contexts/ActivityContext';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useScreenSizes from 'hooks/useScreenSizes.js';
import htmlToDraft from 'html-to-draftjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, LabelWrapper, MultiDropdown, SelectList, Tabs } from 'RaisisComponents/index.js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { ONE_DAY_IN_MILLISECONDS } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

function getPipelineType(pipeline) {
    switch (pipeline) {
        case 'NOT_ASSUMED':
            return 0;
        case 'LEADS_TO_POTENTIAL':
            return 1;
        case 'OFFER':
            return 2;
        default:
            return 3;
    }
}

const TaskActivity = (props) => {
    const { theme } = useContext(GlobalContext);
    const { departmentId, partnerId, tenantUsers, contactId } = props;
    const { t } = useTranslation();
    const activityContext = useContext(ActivityContext);
    const [width] = useScreenSizes();
    const { user } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [activityType, setActivityType] = useState(0);

    const [title, setTitle] = useState('');
    const [selectedTitle, setSelectedTitle] = useState([]);
    const [description, setDescription] = useState('');
    const [date, setDate] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [dateType, setDateType] = useState(0);

    /**
     * Reset dates on date type change
     */
    useEffect(() => {
        setDate(null);
        setDateTo(null);
    }, [dateType]);

    /**
     * Milestone states/functions
     */
    const [milestones, setMilestones] = useState([]);

    // Predefined
    const [fromPredefined, setFromPredefined] = useState(false);
    const [selectedMilestone, setSelectedMilestone] = useState(null);
    const [predefinedMilestones, setPredefinedMilestones] = useState([]);

    const [milestoneInfo, setMilestoneInfo] = useState(null);

    const [titleMilestone, setTitleMilestone] = useState('');
    const [active, setActive] = useState(false);
    const [users, setUsers] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const [commentMilestone, setCommentMilestone] = useState('');
    const [milestoneParentId, setMilestoneParentId] = useState(null);

    //Milestone description logic
    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const [descriptionMilestone, setDescriptionMilestone] = useState(
        htmlToDraftBlocks(`<h2 style="color: rgb(0,0,0);>${t('Add description for the Milestone')}</h2>`),
    );

    // 4 Dropdowns
    const [responsible, setResponsible] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [watcher, setWatcher] = useState([]);
    const [pipeline, setPipeline] = useState(null);

    // Partners
    const [partners, setPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState(null);

    // Arcadie genial idea
    const [milestoneStartDate, setMilestoneStartDate] = useState(new Date());

    // useEffect(() => {
    //     setResponsible([]);
    // }, [activityType]);

    /**
     * Fetch Tenants
     */
    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/tenants', {
                    params: {
                        perPage: 9999,
                        currentPage: 0,
                    },
                });
                setUsers(response.data.users.map((u) => u?.profile?.name));
                setAllUsers(response.data.users);

                const res = await API.get('/partners', {
                    params: {
                        perPage: 9999,
                        currentPage: 0,
                        pagesToLoad: 1,
                    },
                });
                setPartners(res.data.formattedPartners);

                setPredefinedMilestones((await API.get('/mileStoneList')).data.data);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    const partnerOptions = partners.map((p) => {
        const data = JSON.parse(p.data);
        return data['standard']?.name;
    });

    /**
     * Function take the info about user from an dropdown and transform data into an array on indexes for the dropdown to be set on edit
     * @param {*} usersId is an array of objects from the milestone data
     * @returns  An array of index for the dropdowns
     */
    const setDropdowns = useCallback(
        (usersId) => {
            if (allUsers.length > 0) {
                return usersId
                    ?.map((user) => user.userId)
                    .reduce((acc, curr) => {
                        const index = allUsers.findIndex((user) => user.id === curr);
                        return index >= 0 ? [...acc, index] : acc;
                    }, []);
            }

            return [];
        },
        [allUsers],
    );

    const formatSubMilestone = (subMilestone) => {
        return {
            ...subMilestone,
            mileStoneResponsible: setDropdowns(subMilestone.mileStoneResponsible),
            estimatedStartDate: null,
            estimatedEndDate: null,
            children: subMilestone.children.map((c) => ({
                ...c,
                mileStoneResponsible: setDropdowns(c.mileStoneResponsible),
                estimatedStartDate: null,
                priority: 1,
                estimatedEndDate: null,
            })),
        };
    };

    useEffect(() => {
        (async () => {
            if (selectedMilestone === null) return;
            try {
                const response = await API.get(`/mileStone/${predefinedMilestones[selectedMilestone].id}`);
                const milestoneInfo = response.data.data;

                const milestone = milestoneInfo.mileStoneTask.map((m) => formatSubMilestone(m));
                setTitleMilestone(milestoneInfo.name);
                setDescriptionMilestone(htmlToDraftBlocks(milestoneInfo.description));
                setResponsible(setDropdowns(milestoneInfo.responsible));
                setWatcher(setDropdowns(milestoneInfo.watcher));
                setSupervisor(setDropdowns(milestoneInfo.accepter));
                setPipeline(getPipelineType(milestoneInfo.pipelineType));
                setActive(milestoneInfo?.isActive);
                setMilestoneInfo(milestoneInfo);
                setMilestones(calculateDuration(milestone));
            } catch (err) {
                console.error(err);
            }
        })();
    }, [selectedMilestone, predefinedMilestones]);

    // UseCallback-ul face ca mileStoneResponsible sa fie null :) E reparat
    const defaultMilestone = () => {
        return {
            id: Math.random(),
            name: '',
            estimatedDays: 0,
            estimatedStartDate: null,
            estimatedEndDate: null,
            mileStoneResponsible: [],
            children: [],
        };
    };

    const addMilestone = () => {
        const newMilestones = [...milestones, defaultMilestone()];
        setMilestones(calculateDuration(newMilestones));
    };

    const deleteMilestone = (id) => {
        const newMilestones = milestones.filter((m) => m.id !== id);
        setMilestones(newMilestones.length > 0 ? calculateDuration(newMilestones) : []);
    };

    const updateMilestone = (id, key, value) => {
        const milestoneIndex = milestones.findIndex((m) => m.id === id);
        const newMilestones = [...milestones];
        newMilestones[milestoneIndex][key] = value;
        setMilestones(calculateDuration(newMilestones));
    };

    /**
     * @param {*} usersList - Is the state of the every dropdown with users - Array of numbers, returned by dropdown
     * @returns An array of users ids
     */
    const getUsersIds = (usersList) => {
        return usersList.map((user) => tenantUsers[user].id);
    };

    /**
     * Most important function!!!
     */
    const calculateDuration = (M, date = null) => {
        const newMilestones = [];

        let nextStartDate = new Date(date ?? milestoneStartDate).getTime();

        for (const milestone of M) {
            const newMilestone = { ...milestone };

            newMilestone.estimatedStartDate = new Date(nextStartDate);

            newMilestone.children = [];

            let totalEstimatedDays = 0;

            for (const task of milestone.children) {
                const newTask = { ...task };

                newTask.estimatedStartDate = nextStartDate;
                newTask.estimatedEndDate = newTask.estimatedStartDate + task.estimatedDays * ONE_DAY_IN_MILLISECONDS;
                nextStartDate = newTask.estimatedEndDate + ONE_DAY_IN_MILLISECONDS;
                newMilestone.children.push(newTask);
                totalEstimatedDays += task.estimatedDays;
            }

            newMilestone.estimatedEndDate = new Date(nextStartDate);
            newMilestone.estimatedDays = totalEstimatedDays;

            newMilestones.push(newMilestone);
        }

        return newMilestones;
    };

    let schemaMilestone = yup.object().shape({
        // descriptionMilestone: yup
        //     .object()
        //     .typeError(t('The description is required!'))
        //     .required(t('The description is required!')),
        commentMilestone: yup
            .string()
            .trim()
            .typeError(t('The Comment for the Milestone is mandatory!'))
            .required(t('The Comment for the Milestone is mandatory!')),
        pipeline: yup
            .number()
            .typeError(t('Selecting the Pipeline type for the Milestone is required!'))
            .required(t('Selecting the Pipeline type for the Milestone is required!')),
        responsible: yup
            .array()
            .typeError(t('Choosing at least one Milestone responsible is mandatory!'))
            .min(1, t('Choosing at least one Milestone responsible is mandatory!'))
            .required(t('Choosing at least one Milestone responsible is mandatory!')),
        supervisor: yup
            .array()
            .typeError(t('Choosing at least one Milestone supervisor is mandatory!'))
            .min(1, t('Choosing at least one Milestone supervisor is mandatory!'))
            .required(t('Choosing at least one Milestone supervisor is mandatory!')),
        watcher: yup
            .array()
            .typeError(t('Choosing at least one Milestone watcher/beneficiary is mandatory!'))
            .min(1, t('Choosing at least one Milestone watcher/beneficiary is mandatory!'))
            .required(t('Choosing at least one Milestone watcher/beneficiary is mandatory!')),
        titleMilestone: yup
            .string()
            .trim()
            .typeError(t('The name of the Milestone is mandatory!'))
            .required(t('The name of the Milestone is mandatory!')),
        milestone: yup
            .array()
            .of(
                yup.object().shape({
                    estimatedDays: yup
                        .number()
                        .typeError(
                            t('The Sub-Milestone must have allocated the required number of days for completion!'),
                        )
                        .required(
                            t('The Sub-Milestone must have allocated the required number of days for completion!'),
                        ),
                    name: yup
                        .string()
                        .trim()
                        .typeError(t('Sub-Milestone name is mandatory!'))
                        .required(t('Sub-Milestone name is mandatory!')),
                    mileStoneResponsible: yup
                        .array()
                        .of(yup.number().typeError(t('Choosing at least one Sub-Milestone responsible is mandatory!')))
                        .min(1, t('Choosing at least one Sub-Milestone responsible is mandatory!'))
                        .required(t('Choosing at least one Sub-Milestone responsible is mandatory!')),
                    children: yup
                        .array()
                        .of(
                            yup.object().shape({
                                estimatedDays: yup
                                    .number()
                                    .typeError(
                                        t('The Task must have allocated the required number of days for completion!'),
                                    )
                                    .required(
                                        t('The Task must have allocated the required number of days for completion!'),
                                    ),
                                name: yup
                                    .string()
                                    .trim()
                                    .typeError(t('Task name is mandatory!'))
                                    .required(t('Task name is mandatory!')),
                                priority: yup
                                    .number()
                                    .typeError(t('Priority for the task is required!'))
                                    .required(t('Priority for the task is required!')),
                                mileStoneResponsible: yup
                                    .array()
                                    .of(
                                        yup
                                            .number()
                                            .typeError(t('Choosing at least one Task responsible is mandatory!')),
                                    )
                                    .min(1, t('Choosing at least one Task responsible is mandatory!'))
                                    .required(t('Choosing at least one Task responsible is mandatory!')),
                            }),
                        )
                        .min(1, t('Sub-Milestone must contain at least one task')),
                }),
            )
            .min(1, t('The Milestone must have at least one Sub - Milestone!')),
    });

    const createMilestoneActivity = async () => {
        if (activityContext.isCreating) return;

        try {
            await schemaMilestone.validate({
                descriptionMilestone,
                titleMilestone,
                pipeline,
                responsible,
                commentMilestone,
                supervisor,
                watcher,
                milestone: milestones.map((m) => m),
            });

            try {
                const formData = new FormData();
                const htmlContent = DOMPurify.sanitize(
                    draftToHtml(convertToRaw(descriptionMilestone.getCurrentContent())).replaceAll(
                        'color: currentcolor;',
                        '',
                    ),
                );

                const reqBody = {
                    milestoneId: milestoneParentId ?? undefined,
                    contactId: contactId ?? undefined,
                    title: titleMilestone,
                    description: htmlContent,
                    note: commentMilestone,
                    actionType: 'TASK',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible),
                    watcher: getUsersIds(watcher),
                    supervisor: getUsersIds(supervisor),
                    partnerId: partnerId ? partnerId : undefined,
                    departmentId,
                    pipelineType:
                        pipeline === 0
                            ? 'NOT_ASSUMED'
                            : pipeline === 1
                              ? 'LEADS_TO_POTENTIAL'
                              : pipeline === 2
                                ? 'OFFER'
                                : pipeline === 3
                                  ? 'CLIENT_ACTIVITY'
                                  : '',
                    tasks: milestones?.map((m) => ({
                        mileStoneTaskId: typeof m.id === 'string' ? m.id : undefined,
                        name: m.name,
                        estimatedStartDate: m.estimatedStartDate,
                        estimatedEndDate: m.estimatedEndDate,
                        responsible: getUsersIds(m.mileStoneResponsible),
                        subTasks: m?.children?.map((c) => ({
                            mileStoneTaskId: typeof c.id === 'string' ? c.id : undefined,
                            name: c.name,
                            priority:
                                c.priority === 0 ? 'LOW' : c.priority === 1 ? 'MEDIUM' : c.priority === 2 ? 'HIGH' : '',
                            estimatedStartDate: c.estimatedStartDate,
                            estimatedEndDate: c.estimatedEndDate,
                            responsible: getUsersIds(c.mileStoneResponsible),
                        })),
                    })),
                };

                formData.append('data', JSON.stringify(reqBody));
                activityContext.submitNewActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    const editMilestoneActivity = async () => {
        if (activityContext.isCreating) return;

        try {
            await schemaMilestone.validate({
                descriptionMilestone,
                titleMilestone,
                pipeline,
                responsible,
                commentMilestone,
                supervisor,
                watcher,
                milestone: milestones.map((m) => m),
            });

            try {
                const formData = new FormData();
                const htmlContent = DOMPurify.sanitize(
                    draftToHtml(convertToRaw(descriptionMilestone.getCurrentContent())).replaceAll(
                        'color: currentcolor;',
                        '',
                    ),
                );

                const reqBody = {
                    milestoneId: milestoneParentId ?? undefined,
                    contactId: contactId ?? undefined,
                    title: titleMilestone,
                    description: htmlContent,
                    note: commentMilestone,
                    actionType: 'TASK',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible),
                    watcher: getUsersIds(watcher),
                    supervisor: getUsersIds(supervisor),
                    partnerId: partnerId ? partnerId : undefined,
                    departmentId,
                    pipelineType:
                        pipeline === 0
                            ? 'NOT_ASSUMED'
                            : pipeline === 1
                              ? 'LEADS_TO_POTENTIAL'
                              : pipeline === 2
                                ? 'OFFER'
                                : pipeline === 3
                                  ? 'CLIENT_ACTIVITY'
                                  : '',
                    tasks: milestones?.map((m) => ({
                        activitiesTaskId: typeof m.id === 'string' ? m.id : undefined,
                        name: m.name,
                        estimatedStartDate: m.estimatedStartDate,
                        estimatedEndDate: m.estimatedEndDate,
                        responsible: getUsersIds(m.mileStoneResponsible),
                        subTasks: m?.children?.map((c) => ({
                            activitiesTaskId: typeof c.id === 'string' ? c.id : undefined,
                            name: c.name,
                            priority:
                                c.priority === 0 ? 'LOW' : c.priority === 1 ? 'MEDIUM' : c.priority === 2 ? 'HIGH' : '',
                            estimatedStartDate: c.estimatedStartDate,
                            estimatedEndDate: c.estimatedEndDate,
                            responsible: getUsersIds(c.mileStoneResponsible),
                        })),
                    })),
                };

                formData.append('id', JSON.stringify(activityContext.activityForEdit.id));
                formData.append('data', JSON.stringify(reqBody));
                activityContext.editActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    /**
     * Sarcina simpla
     */
    const titleOptions = [
        t('Phone'),
        t('Office meeting'),
        t('Visit the property'),
        t('Whatsapp offer'),
        t('Mail Offer'),
        t('Pre-contract signing'),
    ];

    /**
     * Pre-defined names for activity
     */
    useEffect(() => {
        if (selectedTitle.length) setTitle(titleOptions[selectedTitle[0]]);
    }, [selectedTitle]);

    /**
     * Create Activity Function
     */
    let schema = yup.object().shape({
        responsible: yup
            .array()
            .typeError(t('You must select at least one responsible!'))
            .min(1, t('Add at least 1 responsible!'))
            .required(t('You must select at least one responsible!')),
        title: yup
            .string(t('Task name is mandatory!'))
            .min(3, t('Task name must contain at least 3 characters!'))
            .required(),
        description: yup.string(t('The description is required!')).required(t('The description is required!')),
        date: yup.object().typeError(t('Must pick a date!')).required(t('Must pick a date!')),
        selectedPartner: yup
            .number()
            .typeError(t('Partner field is not a number!'))
            .required(t('You must select a partner!')),
    });

    const createTaskActivity = async () => {
        if (activityContext.isCreating) return;

        try {
            await schema.validate({
                title,
                description,
                responsible,
                date,
                selectedPartner,
            });

            try {
                const formData = new FormData();
                const reqBody = {
                    title,
                    description,
                    note: '-',
                    actionType: 'TASK',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible),
                    supervisor: [],
                    watcher: [],
                    departmentId,
                    partnerId: partners !== null ? partners[selectedPartner].id : undefined,
                    startDate: date,
                    endDate: dateTo ?? undefined,
                };

                if (contactId) reqBody.contactId = contactId;

                formData.append('data', JSON.stringify(reqBody));
                activityContext.submitNewActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    const editTaskActivity = async () => {
        if (activityContext.isCreating) return;

        try {
            await schema.validate({
                title,
                description,
                responsible,
                date,
                selectedPartner,
            });

            try {
                const formData = new FormData();
                const reqBody = {
                    title,
                    description,
                    note: '-',
                    actionType: 'TASK',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible),
                    supervisor: [],
                    watcher: [],
                    departmentId,
                    partnerId: partners !== null ? partners[selectedPartner].id : undefined,
                    startDate: date,
                    endDate: dateTo ?? undefined,
                };

                if (contactId) reqBody.contactId = contactId;

                formData.append('id', JSON.stringify(activityContext.activityForEdit.id));
                formData.append('data', JSON.stringify(reqBody));
                activityContext.editActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    React.useEffect(() => {
        const { activityForEdit } = activityContext;

        if (activityForEdit) {
            const { activitiesTasks, title, description, users, partnerId, endDate, startDate, note, pipelineType } =
                activityForEdit;

            if (activitiesTasks.length > 0) {
                setActivityType(1);

                setTitleMilestone(title);
                setDescriptionMilestone(htmlToDraftBlocks(description));
                setCommentMilestone(note);

                setResponsible(
                    users
                        .filter((user) => user.userType === 'RESPONSIBLE')
                        .map((user) => tenantUsers.findIndex((u) => u.id === user.userId)),
                );
                setSupervisor(
                    users
                        .filter((user) => user.userType === 'ACCEPTER')
                        .map((user) => tenantUsers.findIndex((u) => u.id === user.userId)),
                );
                setWatcher(
                    users
                        .filter((user) => user.userType === 'WATCHER')
                        .map((user) => tenantUsers.findIndex((u) => u.id === user.userId)),
                );

                const pipelineMapper = {
                    NOT_ASSUMED: 0,
                    LEADS_TO_POTENTIAL: 1,
                    OFFER: 2,
                    CLIENT_ACTIVITY: 3,
                };

                setPipeline(pipelineMapper[pipelineType]);
                setMilestoneStartDate(activitiesTasks[0].estimatedStartDate);

                const milestones = activitiesTasks.map((milestone) => ({
                    children: milestone.subTasks.map((task) => ({
                        estimatedDays: Math.ceil(
                            (new Date(task.estimatedEndDate).getTime() - new Date(task.estimatedStartDate).getTime()) /
                                (1000 * 60 * 60 * 24),
                        ),
                        id: task.id,
                        mileStoneResponsible: task.responsible.map((user) => ({
                            id: user.id,
                            userId: user.userId,
                            mileStoneTaskId: user.taskId,
                        })),
                        name: task.name,
                        parentId: task.parrentId,
                    })),

                    estimatedDays: Math.ceil(
                        (new Date(milestone.estimatedEndDate).getTime() -
                            new Date(milestone.estimatedStartDate).getTime()) /
                            (1000 * 60 * 60 * 24),
                    ),
                    id: milestone.id,

                    mileStoneResponsible: milestone.responsible.map((user) => ({
                        id: user.id,
                        userId: user.userId,
                        mileStoneTaskId: user.taskId,
                    })),
                    name: milestone.name,
                    parentId: milestone.milestoneTaskParentId,
                }));

                const formatMilestone = milestones.map((m) => formatSubMilestone(m));
                setMilestones(calculateDuration(formatMilestone));
            } else {
                setActivityType(0);
                setTitle(title);
                setDescription(description);
                setResponsible(users.map((user) => tenantUsers.findIndex((u) => u.id === user.userId)));
                setSelectedPartner(partners.findIndex((partner) => partner.id === partnerId));

                if (endDate === null) {
                    setDateType(0);
                } else {
                    setDateType(1);
                    setDateTo(endDate);
                }

                setDate(startDate);
            }
        }
    }, [activityContext, partners, tenantUsers]);

    return (
        <div>
            <div className="mb-5">
                <Tabs
                    tabs={[t('Simple task'), 'Milestone']}
                    activeTab={activityType}
                    setActiveTab={setActivityType}
                    disabled={activityContext.activityForEdit}
                />
            </div>

            {activityType === 0 && (
                <form>
                    <div className="mb-5">
                        <TextField
                            placeholder={t('Task name')}
                            label={t('Task name')}
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setSelectedTitle([]);
                            }}
                        />
                    </div>

                    <div className="mb-5">
                        <LabelWrapper label={t('Choose from predefined')}>
                            <SelectList
                                options={titleOptions}
                                selectedOptions={selectedTitle}
                                setSelectedOptions={setSelectedTitle}
                            />
                        </LabelWrapper>
                    </div>

                    <div className="mb-5">
                        <TextField
                            placeholder={t('Add description')}
                            multiline
                            label={t('Description')}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                        />
                    </div>

                    {/* Date, Interval and duration type */}
                    <div className="mb-5">
                        <Tabs small tabs={['Moment', 'Interval']} activeTab={dateType} setActiveTab={setDateType} />
                    </div>

                    <div className="mb-5">
                        {dateType === 0 && (
                            <LabelWrapper label={t('Pick date')}>
                                <DatePicker date={date} setDate={setDate} variant="black" />
                            </LabelWrapper>
                        )}

                        {dateType === 1 && (
                            <div className="flex gap-4">
                                <LabelWrapper label={t('From')}>
                                    <DatePicker date={date} setDate={setDate} variant="black" />
                                </LabelWrapper>
                                <LabelWrapper label={t('Until')}>
                                    <DatePicker date={dateTo} setDate={setDateTo} variant="black" />
                                </LabelWrapper>
                            </div>
                        )}
                    </div>

                    <div className="mb-5">
                        <LabelWrapper label={t('Responsible')}>
                            <MultiDropdown
                                variant="black"
                                options={tenantUsers.map((user) => user.profile?.name)}
                                placeholder={t('Choose user')}
                                selectedOptions={responsible}
                                setSelectedOptions={(i) => {
                                    if (responsible) {
                                        if (responsible.indexOf(i) > -1) {
                                            setResponsible(responsible.filter((opt) => opt !== i));
                                        } else {
                                            setResponsible([...responsible, i]);
                                        }
                                    }
                                }}
                            />
                        </LabelWrapper>
                    </div>

                    <div className="mb-10">
                        <LabelWrapper label={t('Partners')}>
                            <Dropdown
                                variant="black"
                                options={partnerOptions}
                                placeholder={t('Choose partner')}
                                selectedOption={selectedPartner}
                                setSelectedOption={setSelectedPartner}
                            />
                        </LabelWrapper>
                    </div>

                    {/* <div className="mb-10">
                        <TextField
                            placeholder={t('Write a comment...')}
                            label={t('Comment')}
                            value={comment}
                            multiline
                            rows={3}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div> */}

                    <Button
                        color="secondary"
                        startIcon={activityContext.activityForEdit !== null ? <EditIcon /> : <AddIcon />}
                        onClick={activityContext.activityForEdit !== null ? editTaskActivity : createTaskActivity}
                    >
                        {`${activityContext.activityForEdit !== null ? t('Edit') : t('Add')} ${t('task')}`}
                    </Button>
                </form>
            )}

            {activityType === 1 && (
                <form>
                    {/* Choose from predefined CHECKBOX */}
                    <div className="mb-5">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={fromPredefined}
                                    onChange={(e) => setFromPredefined(e.currentTarget.checked)}
                                    style={{
                                        color: `rgb(${theme['UI-COLORS']['MAIN-PRIMARY'].color})`,
                                    }}
                                />
                            }
                            label={<p className="user-select-none">{t('Pick a preconfigured milestone')}</p>}
                        />
                    </div>

                    {/* Predefined milestones DROPDOWN */}
                    {fromPredefined && (
                        <div className="relative z-50 mb-5">
                            <Dropdown
                                options={predefinedMilestones.map((m) => m.name)}
                                selectedOption={selectedMilestone}
                                setSelectedOption={setSelectedMilestone}
                                placeholder={t('Add from Milestones')}
                                variant="black"
                            />

                            <p className="pointer-events-none mt-2 select-none text-dark-text">
                                {t('* If you choose a template, all the fields below will be preconfigured.')}
                            </p>
                        </div>
                    )}

                    <div className="mb-10">
                        <TextField
                            placeholder={t('Milestone name')}
                            value={titleMilestone}
                            onChange={(e) => setTitleMilestone(e.target.value)}
                        />
                    </div>

                    <div className="mb-10">
                        <LabelWrapper label={t('Add description for the Milestone')}>
                            <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                editorState={descriptionMilestone}
                                onEditorStateChange={setDescriptionMilestone}
                                toolbarStyle={{
                                    margin: 0,
                                    padding: 0,
                                    borderRadius: 0,
                                    color: 'black',
                                    border: 'none',
                                    backgroundColor: 'inherit',
                                }}
                                wrapperStyle={{
                                    width: '100%',
                                }}
                                editorStyle={{
                                    borderRadius: 0,
                                    backgroundColor: 'white',
                                    padding: '0px 8px',
                                    color: 'black',
                                    minHeight: '20rem',
                                    maxHeight: width <= 750 ? '' : '40rem',
                                    lineHeight: 1.2,
                                }}
                                toolbar={{
                                    options: [
                                        'inline',
                                        'blockType',
                                        'fontSize',
                                        'list',
                                        'textAlign',
                                        'colorPicker',
                                        'link',
                                        'remove',
                                        'history',
                                    ],
                                }}
                            />
                        </LabelWrapper>
                    </div>

                    {/* Submilestones */}
                    <div className="relative z-40 mb-10">
                        {milestones.map((subMile, index) => (
                            <div
                                key={subMile.id}
                                className="relative"
                                style={{
                                    position: 'relative',
                                    zIndex: milestones.length - index + 1,
                                }}
                            >
                                <SubMilestoneActivity
                                    onDelete={deleteMilestone}
                                    onUpdate={updateMilestone}
                                    milestone={subMile}
                                    allUsers={users}
                                    letter={String.fromCharCode(index + 65)}
                                />
                            </div>
                        ))}

                        <Button
                            startIcon={<AddIcon />}
                            fullWidth
                            onClick={() => {
                                if (milestones === undefined) {
                                    setMilestones([]);
                                    addMilestone();
                                } else {
                                    addMilestone();
                                }
                            }}
                            color="primary"
                        >
                            {t('Add Sub-Milestone')}
                        </Button>
                    </div>

                    {/* Milestone dates */}
                    <div className="relative z-30 mb-4 flex gap-4">
                        <LabelWrapper label={t('From')}>
                            <DatePicker
                                date={milestoneStartDate}
                                setDate={(date) => {
                                    setMilestoneStartDate(date);
                                    setMilestones((M) => {
                                        return calculateDuration(M, date);
                                    });
                                }}
                                variant="black"
                            />
                        </LabelWrapper>
                        {/* <LabelWrapper label={t('Up to')}>
                            <DatePicker
                                date={milestones?.length > 0 && milestones[milestones.length - 1].estimatedEndDate}
                                variant="black"
                            />
                        </LabelWrapper> */}
                    </div>

                    {/* 3 Dropdowns */}
                    <div className="mb-5 flex gap-5">
                        <LabelWrapper label={t('Responsible')}>
                            <MultiDropdown
                                variant="black"
                                options={tenantUsers.map((user) => user.profile?.name)}
                                placeholder={t('Choose user')}
                                selectedOptions={responsible}
                                setSelectedOptions={(i) => {
                                    if (responsible?.indexOf(i) > -1) {
                                        setResponsible(responsible.filter((opt) => opt !== i));
                                    } else {
                                        setResponsible([...responsible, i]);
                                    }
                                }}
                            />
                        </LabelWrapper>

                        <LabelWrapper label={t('Supervisor')}>
                            <MultiDropdown
                                variant="black"
                                options={tenantUsers.map((user) => user.profile?.name)}
                                placeholder={t('Choose user')}
                                selectedOptions={supervisor}
                                setSelectedOptions={(i) => {
                                    if (supervisor?.indexOf(i) > -1) {
                                        setSupervisor(supervisor.filter((opt) => opt !== i));
                                    } else {
                                        setSupervisor([...supervisor, i]);
                                    }
                                }}
                            />
                        </LabelWrapper>

                        <LabelWrapper label={t('Watcher / Beneficiary')}>
                            <MultiDropdown
                                variant="black"
                                options={tenantUsers.map((user) => user.profile?.name)}
                                placeholder={t('Choose user')}
                                selectedOptions={watcher}
                                setSelectedOptions={(i) => {
                                    if (watcher?.indexOf(i) > -1) {
                                        setWatcher(watcher.filter((opt) => opt !== i));
                                    } else {
                                        setWatcher([...watcher, i]);
                                    }
                                }}
                            />
                        </LabelWrapper>
                    </div>

                    {/* Pipeline Dropdown */}
                    <div className="mb-10">
                        <LabelWrapper label={t('Pipeline')}>
                            <Dropdown
                                variant="black"
                                placeholder={t('Choose option')}
                                options={[
                                    'Nu prelua',
                                    'Leads to potențial',
                                    'Ofertare',
                                    'Activitate clienți (contracte)',
                                ]}
                                selectedOption={pipeline}
                                setSelectedOption={setPipeline}
                            />
                        </LabelWrapper>
                    </div>

                    {/* Comment */}
                    <div className="mb-10">
                        <TextField
                            placeholder={t('Write a comment...')}
                            label={t('Comment')}
                            value={commentMilestone}
                            multiline
                            rows={3}
                            onChange={(e) => setCommentMilestone(e.target.value)}
                        />
                    </div>

                    {/* Submit button */}
                    <div className="flex items-center">
                        <Button
                            color="secondary"
                            startIcon={activityContext.activityForEdit !== null ? <EditIcon /> : <AddIcon />}
                            onClick={() => {
                                if (milestoneInfo) {
                                    const myMilestone = {
                                        name: titleMilestone,
                                        description: descriptionMilestone,
                                        mileStoneTask: [],
                                    };

                                    const confMilestone = {
                                        name: milestoneInfo.name,
                                        description: milestoneInfo.description,
                                        mileStoneTask: [],
                                    };

                                    /**
                                     * Construim in functie de milestone-ul nostru
                                     */
                                    for (const submilestone of milestones) {
                                        const newSubmilestone = {};
                                        newSubmilestone.name = submilestone.name;
                                        newSubmilestone.children = [];

                                        for (const task of submilestone.children) {
                                            newSubmilestone.children.push({ name: task.name });
                                        }

                                        myMilestone.mileStoneTask.push(newSubmilestone);
                                    }

                                    /**
                                     * Construim in functie de milestone-ul din configurat
                                     */

                                    for (const submilestone of milestoneInfo.mileStoneTask) {
                                        const newSubmilestone = {};
                                        newSubmilestone.name = submilestone.name;
                                        newSubmilestone.children = [];

                                        for (const task of submilestone.children) {
                                            newSubmilestone.children.push({ name: task.name });
                                        }

                                        confMilestone.mileStoneTask.push(newSubmilestone);
                                    }

                                    if (JSON.stringify(confMilestone) === JSON.stringify(myMilestone)) {
                                        setMilestoneParentId(milestoneInfo.id);
                                    }
                                }

                                if (activityContext.activityForEdit !== null) {
                                    editMilestoneActivity();
                                } else {
                                    createMilestoneActivity();
                                }
                            }}
                        >
                            {`${activityContext.activityForEdit !== null ? t('Edit') : t('Add')} ${t('milestone')}`}
                        </Button>

                        <div className="w-2 flex-shrink-0" />

                        <Button color="secondary">{t('Cancel')}</Button>
                    </div>
                </form>
            )}
        </div>
    );
};

TaskActivity.propTypes = {
    departmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    partnerId: PropTypes.string,
    tenantUsers: PropTypes.arrayOf(PropTypes.object),
    activity: PropTypes.object,
    contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TaskActivity.defaultProps = {
    departmentId: null,
    partnerId: null,
    tenantUsers: [],
    contactId: null,
    activity: null,
};

export default TaskActivity;
