import API from 'utils/axios';

const InternalActivityEmployee = {
    /**
     * Fetches all Employee's
     *
     * @return {array} Array of Employee's
     */
    async get() {
        try {
            const res = await API.get('/employees');
            return { ok: true, data: res.data.employees };
        } catch (error) {
            return {
                ok: false,
                data: [],
                error,
            };
        }
    },

    /**
     * Fetches an Employee by ID
     *
     * @return {object} Employee
     */
    async getById(employeeId) {
        try {
            const res = await API.get('/employee', {
                params: {
                    id: employeeId,
                },
            });
            return { ok: true, data: res.data.employee };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Create a new Internal Activity Employee
     *
     * @return {object} Operation status + an empty object (no need for the returned employee)
     */
    async create(employee) {
        const { firstName, lastName, position, schedule, employeeType, email, phoneNumber } = employee;

        try {
            await API.post('/employees', {
                firstName,
                lastName,
                email,
                phoneNumber,
                employeeType: employeeType.toUpperCase(),
                CNP: '-',
                address: '-',
                position,
                schedule,
                teams: [],
            });
            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Edit a Internal Activity Employee by ID
     *
     * @return {object} Operation status + an empty object (no need for the returned employee)
     */
    async edit(employee) {
        try {
            const body = {
                id: employee.id,
                data: employee,
            };

            delete body.data.id;
            delete body.data.userId;
            delete body.data.tenantId;

            await API.put('/employees', body);
            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Deactivate a PM Employee by ID
     *
     * @return {object} Operation status + an empty object (no need for the returned employee)
     */
    async deactivate(employeeId) {
        try {
            await API.delete('/employees', {
                data: {
                    id: employeeId,
                },
            });

            return {
                ok: true,
                data: {},
            };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Activate a Internal Activity Employee by ID
     *
     * @return {object} Operation status + an empty object (no need for the returned employee)
     */
    async activate(employee) {
        try {
            const body = {
                id: employee.id,
                data: {
                    ...employee,
                    isDeleted: false,
                    schedule: employee.schedule.map((s) => {
                        const { day, hours } = s;

                        return {
                            day,
                            hours,
                        };
                    }),
                },
            };

            delete body.data.id;
            delete body.data.userId;
            delete body.data.tenantId;

            await API.put('/employees', body);
            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },
};

const PMEmployee = {
    /**
     * Fetches all Employee's
     *
     * @return {array} Array of Employee's
     */
    async get() {
        try {
            const res = await API.get('/pm_employees');
            return { ok: true, data: res.data.pmEmployees };
        } catch (error) {
            return {
                ok: false,
                data: [],
                error,
            };
        }
    },

    /**
     * Fetches an Employee by ID
     *
     * @return {object} Employee
     */
    async getById(employeeId) {
        try {
            const res = await API.get('/pmEmployee', {
                params: {
                    id: employeeId,
                },
            });
            return { ok: true, data: res.data.pmEmployee };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Create a new PM Employee
     *
     * @return {object} Operation status + an empty object (no need for the returned employee)
     */
    async create(employee) {
        const { firstName, lastName, position, schedule, employeeType, email, phoneNumber } = employee;

        try {
            await API.post('/pm_employees', {
                firstName,
                lastName,
                email,
                phoneNumber,
                employeeType: employeeType.toUpperCase(),
                CNP: '-',
                address: '-',
                position,
                schedule,
                teams: [],
            });
            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Edit a PM Employee by ID
     *
     * @return {object} Operation status + an empty object (no need for the returned employee)
     */
    async edit(employee) {
        try {
            const body = {
                id: employee.id,
                data: employee,
            };

            delete body.data.id;
            delete body.data.userId;
            delete body.data.tenantId;
            delete body.data.pMId;

            await API.put('/pm_employees', body);
            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Deactivate a PM Employee by ID
     *
     * @return {object} Operation status + an empty object (no need for the returned employee)
     */
    async deactivate(employeeId) {
        try {
            await API.delete('/pm_employees', {
                params: {
                    id: employeeId,
                },
            });

            return {
                ok: true,
                data: {},
            };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Activate a PM Employee by ID
     *
     * @return {object} Operation status + an empty object (no need for the returned employee)
     */
    async activate(employee) {
        try {
            const body = {
                id: employee.id,
                data: {
                    ...employee,
                    isDeleted: false,
                    schedule: employee.schedule.map((s) => {
                        const { day, hours } = s;

                        return {
                            day,
                            hours,
                        };
                    }),
                },
            };

            delete body.data.id;
            delete body.data.userId;
            delete body.data.tenantId;
            delete body.data.pMId;

            await API.put('/pm_employees', body);
            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },
};

const PerProjectEmployees = (projectId) => ({
    async get() {
        try {
            const res = await API.get('/project_employees/' + projectId);
            return { ok: true, data: res.data.employees };
        } catch (error) {
            return {
                ok: false,
                data: [],
                error,
            };
        }
    },
});

const Employee = {
    internalActivity: InternalActivityEmployee,
    pm: PMEmployee,
    perProject(id) {
        return PerProjectEmployees(id);
    },
};
export default Employee;
