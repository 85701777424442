import React, { Fragment, useState } from 'react';

import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import DisplayQueryResults from 'components/shared/display-query-results';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import { useResources } from 'contexts/ResourcesContext';
import { TableSeparator } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

import HumanRow from './human-row';

const HumaneCategories = () => {
    const { t } = useTranslation();
    const [humans, setHumans] = useState([]);
    const { query } = useResources();
    const [isLoading, setIsLoading] = useState(false);

    const getHumans = async (currentPage, perPage) => {
        try {
            const res = await API.get('humanResources', {
                params: {
                    currentPage,
                    perPage,
                    pagesToLoad: 1,
                },
            });

            setHumans(res.data.allHumanResources.humanResources);

            return res.data.allHumanResources.length;
        } catch (error) {
            throw new Error(error);
        }
    };

    const getSearchedHumans = async (currentPage, perPage) => {
        try {
            const res = await API.get('searchHumanResources', {
                params: {
                    currentPage,
                    perPage,
                    pagesToLoad: 1,

                    text: query,
                },
            });

            setHumans(res.data.searchedMaterialResource.materialResource);

            return res.data.searchedMaterialResource.length;
        } catch (error) {
            throw new Error(error);
        }
    };

    return (
        <div className="page-container">
            <DisplayQueryResults
                loading={isLoading}
                query={query}
                setLoading={setIsLoading}
                getFunction={getHumans}
                getSearchFunction={getSearchedHumans}
            >
                {isLoading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : humans.length ? (
                    <Fragment>
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Name')}</TableCell>
                                        <TableCell>{t('Address')}</TableCell>
                                        <TableCell>{t('CNP')}</TableCell>
                                        <TableCell>{t('Email')}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {humans.map((human) => (
                                        <HumanRow key={human.id} human={human} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Fragment>
                ) : (
                    <NoDataPlaceholder />
                )}
            </DisplayQueryResults>
        </div>
    );
};

export default HumaneCategories;
