import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import FrappeGantt from 'components/frappe-gantt/frappe-gantt-layout';
import EmailCreator from 'components/internal-activity/email-creator';
import CustomModal from 'components/modals/custom_modal';
import LoadingExportModal from 'components/modals/loading-export-modal';
import TaskManagementDisplay from 'components/modules/pm-task-management/pm-task-management-display';
import PMTaskManagementModal from 'components/modules/pm-task-management/pm-task-management-modal';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import TaskManagementContext from 'contexts/TaskManagementContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import FilterMilestonesModal from 'pages/internal-activity/timeline-activity/filter-milestones-modal';
import PropTypes from 'prop-types';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import exportImage from 'utils/exportImage';
import exportImagesAsPDF from 'utils/exportImagesAsPDF';
import { getFilteredMilestones, getTasks } from 'utils/ganttUtils';
import { getPmDepartments, getProjectUsers } from 'utils/getterFunctions';

const PMTaskManagementTimeline = ({ projectName }) => {
    const { t } = useTranslation();
    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { user, checkPerm } = useContext(UserContext);
    const { tenant } = useContext(GlobalContext);

    const [modalOpen, setModalOpen] = useState(false);

    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const [departments, setDepartments] = useState([]);

    const [seeGantt, setSeeGantt] = useState(false);
    const [ganttData, setGanttData] = useState(null);
    const [loadingExportImage, setLoadingExportImage] = useState(false);
    const [loadingexportImagesAsPDF, setLoadingexportImagesAsPDF] = useState(false);

    const canView = checkPerm([
        {
            permissionId: '19',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canView) {
            history.push('/');
        }
    }, [canView]);

    const [projectAccounts, setProjectAccounts] = useState([]);

    //Send notification states
    const [emailUsers, setEmailUsers] = useState([]);
    const [emailModalStatus, setEmailModalStatus] = useState(false);
    const [initialMilestone, setInitialMilestone] = useState(null);
    const [milestones, setMilestones] = useState([]);

    // Filter gantt milestones
    const [filteredMilestones, setFilteredMilestones] = useState([]);
    const memoizedFilteredMilestones = useMemo(() => filteredMilestones, [filteredMilestones]);
    const [ganttFilterData, setGanttFilterData] = useState({
        type: 0,
        milestones: [],
        startDate: new Date(),
        endDate: new Date(),
    });
    const [openFilterGantt, setOpenFilterGantt] = useState(false);

    const handleOpenFilterGantt = () => setOpenFilterGantt((prev) => !prev);

    const handleFilterGantt = () => {
        const fMilestones = getFilteredMilestones(milestones, ganttFilterData);

        setFilteredMilestones(fMilestones);
        setOpenFilterGantt(false);
    };

    const customGanttData = useMemo(
        () => ({
            startDate:
                ganttFilterData.type === 2 || ganttFilterData.type === 3 || ganttFilterData.type === 4
                    ? new Date(ganttFilterData.startDate)
                    : null,
            endDate:
                ganttFilterData.type === 2 || ganttFilterData.type === 3 || ganttFilterData.type === 4
                    ? new Date(ganttFilterData.endDate)
                    : null,
        }),
        [ganttFilterData.type, ganttFilterData.startDate, ganttFilterData.endDate],
    );

    const handleNotify = (milestone) => {
        setInitialMilestone(milestone);
        setEmailModalStatus(true);
    };

    const getTenantAndClientAccounts = async () => {
        try {
            const [projectAccounts, clientAccounts] = await Promise.all([
                getProjectUsers({ pmProjectId: projectId }),
                API.get('/contacts', {
                    params: {
                        currentPage: 0,
                        perPage: 99999,
                        pagesToLoad: 1,
                        type: 'ALL',
                    },
                }),
            ]);

            const formattedClients = clientAccounts.data.data.contacts.map((client) => {
                const clientFields = typeof client.data === 'string' ? JSON.parse(client.data) : client.data;
                const profile = Object.assign({}, ...Object.values(clientFields));
                client.email = profile.email;
                delete profile.email;
                delete client.data;

                return { ...client, profile };
            });

            setEmailUsers([...projectAccounts, ...formattedClients]);
            setProjectAccounts(projectAccounts);
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * Status of the activity ( to do or done)
     * This state is use for sorting the activity by status
     */
    const [status, setStatus] = useState('all');
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    /**
     * Type of the activity ( comment, tasks, files, documents, invoices)
     * This state is use for sorting the activity by type
     */
    const [activityType, setActivityType] = useState('ALL');
    const handleTypeChange = (event) => {
        setActivityType(event.target.value);
    };

    useEffect(() => {
        (async () => {
            try {
                const deps = await getPmDepartments();
                setDepartments(deps);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    /**
     * State for open and close the modal
     * On creation of an activity: isCreating is true
     */
    const [isCreating, setIsCreating] = useState(false);

    const submitTaskManagementActivity = async (formData) => {
        if (isCreating) return;

        try {
            setIsCreating(true);

            await API.post('/pmProjectActivity', formData);

            enqueueSnackbar(t('Task Management activity added successfully!'), { variant: 'success' });

            setModalOpen(false);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        } finally {
            setIsCreating(false);
            fetchActivities();
        }
    };

    /*
        Fetching data periodically, and separating all activities in 2 arrays
        Update occurs only if differences are spotted
    */
    const [loadingActivities, setLoadingActivities] = useState(true);
    const [todoActivities, setTodoActivities] = useState([]);
    const [doneActivities, setDoneActivities] = useState([]);

    const fetchActivities = async () => {
        if (!departments.length) return;

        const params = {
            currentPage: 0,
            perPage: 9999,
            pagesToLoad: 1,
            pmProjectPlanId: projectId,
        };

        const res = await API.get('/pmProjectActivity', { params });

        const milestoneActivities = res.data.activity
            .map((activity) => activity.activityInfo)
            .filter((activity) => activity.activitiesTasks.length > 0);
        setMilestones(milestoneActivities);

        const sortedMilestones = milestoneActivities.sort(
            (a, b) => new Date(a.createAt).getTime() - new Date(b.createAt).getTime(),
        );
        setFilteredMilestones(sortedMilestones);

        const todoFiltered = [
            ...res.data.activity.filter(
                (a) =>
                    a.activityInfo.status === 'TODO' &&
                    (activityType === 'ALL' || activityType === a.activityInfo.actionType) &&
                    (selectedDepartment === 0 ||
                        a.activityInfo.pmDepartmentId === departments[selectedDepartment - 1].id),
            ),
        ];

        if (JSON.stringify(todoFiltered) !== JSON.stringify(todoActivities)) {
            setTodoActivities(todoFiltered);
        }

        const doneFiltered = [
            ...res.data.activity.filter(
                (a) =>
                    a.activityInfo.status === 'DONE' &&
                    (activityType === 'ALL' || activityType === a.activityInfo.actionType) &&
                    (selectedDepartment === 0 ||
                        a.activityInfo.pmDepartmentId === departments[selectedDepartment - 1].id),
            ),
        ];

        if (JSON.stringify(doneFiltered) !== JSON.stringify(doneActivities)) {
            setDoneActivities(doneFiltered);
        }
    };

    const [isDeleting, setIsDeleting] = useState(false);
    const deleteActivity = async (activity) => {
        // if (user.id !== activity.authorId) return;
        if (isDeleting) return;

        setIsDeleting(true);

        try {
            await API.delete('/pmProjectActivity', {
                data: {
                    id: activity.id,
                },
            });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        } finally {
            setIsDeleting(false);
            fetchActivities();
        }
    };

    const [isUpdating, setIsUpdating] = useState(false);
    const updateActivity = async (activity, newValue) => {
        if (isUpdating) return;
        setIsUpdating(true);

        try {
            await API.post('/mark_activity', {
                id: activity.id,
                status: newValue,
            });

            fetchActivities();
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        } finally {
            setIsUpdating(false);
        }
    };

    /**
     * This useEffect is for sorting. When the dependency is changed, is calling fetchActivities with correct params for sorting
     */
    useEffect(() => {
        const getData = async () => {
            setLoadingActivities(true);
            await Promise.allSettled([fetchActivities(), getTenantAndClientAccounts()]);
            setLoadingActivities(false);
        };

        if (departments.length > 0) {
            getData();
        } else {
            setLoadingActivities(false);
        }
    }, [departments, activityType, selectedDepartment]);

    // Export Gantt logic

    const handleExportGanntAsImage = async () => {
        if (!ganttData) {
            enqueueSnackbar(t('No Gantt  was loaded'), { variant: 'error' });
            return;
        }

        setLoadingExportImage(true);

        try {
            await exportImage(ganttData.gantt, true);
        } catch (error) {
            enqueueSnackbar(t("Gantt couldn't be exported"), { variant: 'error' });
            console.error(error);
        } finally {
            setLoadingExportImage(false);
        }
    };

    // Export Gantt as pdf logic

    const handleExportGanntAsPDF = async () => {
        if (!ganttData) {
            enqueueSnackbar(t('No Gantt  was loaded'), { variant: 'error' });
            return;
        }

        setLoadingexportImagesAsPDF(true);

        try {
            await exportImagesAsPDF(
                'landscape',
                [ganttData.gantt],
                tenant,
                [ganttData.legend],
                () => setLoadingexportImagesAsPDF(false),
                null,
                true,
                'multi-page',
                projectName,
                `Gantt_Export_${new Date().toISOString().slice(0, 10)}_${projectName}.pdf`,
                ganttData.disabledCropHeightsIntervals,
            );
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>{`Task Management`}</title>
            </Helmet>

            <Header
                pageTitle={`Task Management`}
                action={
                    <>
                        {canAll && seeGantt !== false && (
                            <Button
                                startIcon={loadingExportImage ? <CircularProgress size={20} /> : <ImportExportIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={handleExportGanntAsImage}
                            >
                                {t('Export Gantt as image')}
                            </Button>
                        )}
                        {canAll && seeGantt !== false && (
                            <Button
                                startIcon={
                                    loadingexportImagesAsPDF ? <CircularProgress size={20} /> : <ImportExportIcon />
                                }
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={loadingexportImagesAsPDF ? undefined : handleExportGanntAsPDF}
                            >
                                {t('Export Gantt as PDF')}
                            </Button>
                        )}
                        {canAll && seeGantt === false && (
                            <Button
                                startIcon={<EmailIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={() => {
                                    setEmailModalStatus((prev) => !prev);
                                }}
                            >
                                {t('Notify')}
                            </Button>
                        )}
                        {canAll && seeGantt === false && (
                            <div
                                className={`duration-350 transform transition-all ${
                                    modalOpen ? 'translate-x-8 opacity-0' : 'translate-x-0 opacity-100'
                                }`}
                            >
                                <Button
                                    startIcon={<AddIcon />}
                                    color="secondary"
                                    style={{ borderRadius: '999px' }}
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                >
                                    {t('Add activity')}
                                </Button>
                            </div>
                        )}
                    </>
                }
                toolbar={
                    <div className="flex items-center gap-8">
                        {!seeGantt ? (
                            <Tabs
                                tabs={[t('All'), ...(departments?.map((d) => d.name) ?? [])]}
                                activeTab={selectedDepartment}
                                setActiveTab={setSelectedDepartment}
                            />
                        ) : (
                            <div className="flex-shrink-0">
                                <Button
                                    style={{ borderRadius: '999px' }}
                                    startIcon={<FilterListIcon />}
                                    onClick={handleOpenFilterGantt}
                                >
                                    {t('Filter milestones')}
                                </Button>
                            </div>
                        )}
                        <div className="flex-shrink-0">
                            <Button
                                style={{ borderRadius: '999px' }}
                                onClick={() => {
                                    setSeeGantt(!seeGantt);
                                }}
                            >
                                {seeGantt === true ? t('See Timeline') : t('See Gantt')}
                            </Button>
                        </div>
                    </div>
                }
                toolbarSecondary={
                    !seeGantt ? (
                        <div className="flex flex-col">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="status"
                                    name="status-filter"
                                    value={status}
                                    onChange={handleStatusChange}
                                >
                                    <div className="no-user-select-recursive flex flex-wrap items-center">
                                        <FormControlLabel value="all" control={<Radio />} label={t('All')} />
                                        <FormControlLabel value="todo" control={<Radio />} label={t('To do')} />
                                        <FormControlLabel value="done" control={<Radio />} label={t('Done')} />
                                    </div>
                                </RadioGroup>
                            </FormControl>

                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="activity-type"
                                    name="activity-type-filter"
                                    value={activityType}
                                    onChange={handleTypeChange}
                                >
                                    <div className="no-user-select-recursive flex flex-wrap items-center">
                                        <FormControlLabel value="ALL" control={<Radio />} label={t('All')} />
                                        <FormControlLabel value="COMMENT" control={<Radio />} label={t('Comments')} />
                                        <FormControlLabel value="TASK" control={<Radio />} label={'Tasks'} />
                                        <FormControlLabel value="FILES" control={<Radio />} label={t('Files')} />
                                        <FormControlLabel
                                            value="DOCUMENTS"
                                            control={<Radio />}
                                            label={t('Documents')}
                                        />
                                        {/* <FormControlLabel
                                        value="INVOICES_AND_BILLS"
                                        control={<Radio  />}
                                        label={t('Invoices/Bills')}
                                    /> */}
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </div>
                    ) : null
                }
            />

            <div className="page-container">
                {seeGantt ? (
                    <Fragment>
                        <FrappeGantt
                            milestones={memoizedFilteredMilestones}
                            setGanttData={setGanttData}
                            customGanttData={customGanttData}
                            getTasksFunction={getTasks}
                        />

                        <CustomModal open={openFilterGantt}>
                            <FilterMilestonesModal
                                setOpen={setOpenFilterGantt}
                                ganttFilterData={ganttFilterData}
                                setGanttFilterData={setGanttFilterData}
                                handleFilterGantt={handleFilterGantt}
                                milestones={milestones}
                            />
                        </CustomModal>

                        <LoadingExportModal open={loadingExportImage || loadingexportImagesAsPDF} />
                    </Fragment>
                ) : (
                    <TaskManagementContext.Provider
                        value={{
                            isCreating,
                            setIsCreating,
                            submitTaskManagementActivity,
                            fetchActivities,
                        }}
                    >
                        <div className=" flex items-start justify-center lg:items-center">
                            <div
                                className="relative z-40 flex w-full flex-col items-center pl-24 md:pl-32 sm:pl-0"
                                style={{ maxWidth: '1000px' }}
                            >
                                {loadingActivities && <CircularProgress />}
                                {(todoActivities.length > 0 || doneActivities.length > 0) && (
                                    <Fragment>
                                        {(((status === 'all' || status === 'todo') && todoActivities.length > 0) ||
                                            isCreating) && (
                                            <div className="relative w-full border-l-2 border-layout-lighter py-8 pl-8 sm:pl-4">
                                                <h2 className="mb-12">{t('To do')}</h2>

                                                <div
                                                    className={`pointer-events-none flex w-full items-center justify-center overflow-hidden rounded-md bg-layout-transparent transition-all duration-300 ${
                                                        isCreating ? 'opacity-1 mb-10 h-48' : 'mb-0 h-0 opacity-0'
                                                    }`}
                                                >
                                                    <CircularProgress />
                                                </div>

                                                {todoActivities.map((a) => (
                                                    <TaskManagementDisplay
                                                        key={a.activityInfo.id}
                                                        activity={a}
                                                        deleteActivity={deleteActivity}
                                                        fetchActivities={fetchActivities}
                                                        updateActivity={updateActivity}
                                                        milestoneCheck={true}
                                                        actionArrow={{
                                                            edit: canAll ? true : false,
                                                            // gantt: true,
                                                            notify:
                                                                canAll && a.activityInfo.activitiesTasks.length > 0
                                                                    ? true
                                                                    : false,
                                                        }}
                                                        onNotify={handleNotify}
                                                        // check={canAll ? true : false}
                                                        // actionArrow={canAll ? true : false}
                                                        // deleteIcon={canAll ? true : false}
                                                        // commentView={canAll ? true : false}
                                                        // userLink={canAll ? true : false}
                                                    />
                                                ))}
                                            </div>
                                        )}

                                        {(status === 'all' || status === 'done') && doneActivities.length > 0 && (
                                            <div className="relative w-full border-l-2 border-layout-light py-8 pl-8 sm:pl-4">
                                                <h2 className="mb-12">{t('Done')}</h2>
                                                {doneActivities.map((a) => (
                                                    <TaskManagementDisplay
                                                        key={a.activityInfo.id}
                                                        activity={a}
                                                        deleteActivity={deleteActivity}
                                                        fetchActivities={fetchActivities}
                                                        updateActivity={updateActivity}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </Fragment>
                                )}

                                {!todoActivities.length && !doneActivities.length && !loadingActivities && (
                                    <NoDataPlaceholder />
                                )}
                            </div>

                            {canAll && modalOpen && (
                                <div className="sticky top-8 z-50 ml-8 rounded-md bg-layout-transparent xl:fixed xl:left-2/4 xl:top-2/4 xl:ml-0 xl:-translate-x-2/4 xl:-translate-y-2/4 xl:transform xl:bg-layout-lighter">
                                    <PMTaskManagementModal
                                        closeModal={() => setModalOpen(false)}
                                        departments={departments}
                                        tenants={projectAccounts}
                                    />
                                </div>
                            )}

                            <div
                                className={`fixed left-0 top-0  z-40 hidden h-svh w-screen ${
                                    modalOpen ? 'xl:flex' : ''
                                }`}
                                style={{ backgroundColor: 'rgba(0,0,0,0.3)', backdropFilter: 'blur(5px)' }}
                                onClick={() => setModalOpen((prev) => !prev)}
                            />

                            {emailModalStatus && (
                                <CustomModal
                                    open={emailModalStatus}
                                    setOpen={setEmailModalStatus}
                                    onClose={() => {
                                        setInitialMilestone(null);
                                    }}
                                >
                                    <EmailCreator
                                        cancel={() => {
                                            setInitialMilestone(null);
                                            setEmailModalStatus(false);
                                        }}
                                        users={emailUsers}
                                        emailReplyToUsers={emailUsers.filter((value) => value.id === user.id)}
                                        milestones={milestones}
                                        setEmailModalStatus={setEmailModalStatus}
                                        purpose="PM"
                                        initialMilestone={initialMilestone}
                                    />
                                </CustomModal>
                            )}
                        </div>
                    </TaskManagementContext.Provider>
                )}
            </div>
        </>
    );
};

PMTaskManagementTimeline.propTypes = {
    projectName: PropTypes.string,
};

export default PMTaskManagementTimeline;
