import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, TableCell, TableRow } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { crm } from 'routes';

const ProjectClientRow = ({ client }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '6',
            permissionType: 'ALL',
        },
    ]);

    const handleViewClient = () => {
        history.push(crm.base + crm.contacts.base + crm.contacts.update + '/' + client.id);
    };

    return (
        <TableRow>
            <TableCell>{client.name}</TableCell>
            <TableCell>{client.email}</TableCell>
            <TableCell>{client.phone_number}</TableCell>
            <TableCell>{client.cnp}</TableCell>
            <TableCell>{client.address}</TableCell>
            <TableCell>
                {canAll ? (
                    <div className="flex justify-end">
                        <Button onClick={handleViewClient} size="small">
                            {t('See client')}
                        </Button>
                    </div>
                ) : (
                    '&nbsp;'
                )}
            </TableCell>
        </TableRow>
    );
};

ProjectClientRow.propTypes = {
    client: PropTypes.object,
};

ProjectClientRow.defaultProps = {
    client: {},
};

export default ProjectClientRow;
