import React from 'react';

import DepartmentDetails from 'components/crm/departments/department-details';
import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateDepartments = ({ setInitializeData }) => {
    const { t } = useTranslation();

    if (setInitializeData) return <DepartmentDetails area={'ERP'} setInitializeData={setInitializeData} />;

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Add new department')}</title>
            </Helmet>

            <Header pageTitle={t('Add new department')} />
            <div className="page-container">
                <DepartmentDetails area={'ERP'} />
            </div>
        </>
    );
};

CreateDepartments.propTypes = {
    setInitializeData: PropTypes.func,
};

CreateDepartments.defaultProps = {
    setInitializeData: null,
};

export default CreateDepartments;
