import React, { useContext } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import GlobalContext from 'contexts/GlobalContext';

const SupportModal = () => {
    const { setGlobalModalOpen, setGlobalModalChildren } = useContext(GlobalContext);

    return (
        <>
            <div className="relative h-svh w-3/5 2xl:w-4/5 sm:w-full">
                <div
                    className="flex justify-end bg-white"
                    onClick={() => {
                        setGlobalModalChildren(null);
                        setGlobalModalOpen(false);
                    }}
                >
                    <CloseIcon
                        className="cursor-pointer text-primary-main hover:text-secondary-main"
                        style={{ fontSize: '3rem' }}
                    />
                </div>
                <div className="h-full flex-col">
                    <iframe
                        src="https://app.startinfinity.com/form/e4a7d707-494d-45f4-9664-e8ff40182856"
                        width="100%"
                        height="100%"
                        className="border-none"
                    ></iframe>
                </div>
            </div>
        </>
    );
};

export default SupportModal;
