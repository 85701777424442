import React, { useState } from 'react';

import DateRangeIcon from '@material-ui/icons/DateRange';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { DatePicker as MuiDatePicker } from '@material-ui/pickers';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const DatePicker = (props) => {
    const { date, setDate, variant, disabled } = props;
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const handleDateChange = (newDate) => {
        setDate(newDate);
        setIsOpen(false);
    };

    return (
        <div className={`relative ${disabled ? 'pointer-events-none' : ''}`}>
            <div className="dropdown-z-index relative inline-block w-full flex-shrink-0 cursor-pointer">
                {/* eslint-disable-next-line */}
                <div
                    className={`dropdown-selected ${date !== null ? 'hasValue' : ''} ${
                        disabled ? 'disabled' : variant
                    }`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="flex items-center">
                        <DateRangeIcon className="text-buttons-text" />
                        <p className="ml-2 whitespace-nowrap text-buttons-text">
                            {date ? dayjs(date).format('DD MMM YYYY') : t('Choose a date')}
                        </p>
                    </div>
                    <KeyboardArrowDownIcon
                        className={`flex-shrink-0 transform text-buttons-text transition-transform ${
                            isOpen ? 'rotate-180' : 'rotate-0'
                        }`}
                    />
                </div>
            </div>

            <div className="hidden-default-date-picker">
                <MuiDatePicker
                    margin="normal"
                    variant="inline"
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    format="d MMM yyyy"
                    value={date}
                    onChange={handleDateChange}
                />
            </div>
        </div>
    );
};
DatePicker.propTypes = {
    date: PropTypes.string,
    setDate: PropTypes.func,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
};
DatePicker.defaultProps = {
    date: null,
    setDate: () => null,
    variant: 'default',
    disabled: false,
};
