import React, { useContext, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { Button, TextField } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import FileUploadContainer from 'components/shared/file-upload-container';
import TaskManagementContext from 'contexts/TaskManagementContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { uploadSingleFile } from 'utils';
import * as yup from 'yup';

const File = (props) => {
    const { file, deleteFile } = props;
    const { t } = useTranslation();

    return (
        <div className="group mb-2 flex items-center justify-between rounded-md bg-layout-transparent px-4 py-2 transition-colors hover:bg-primary-transparent">
            <div className="flex items-center">
                <InsertDriveFileIcon className="mr-2 text-buttons-text" style={{ fontSize: '1.75rem' }} />
                <p className="user-select-none text-buttons-text">{file.info.name}</p>
            </div>

            <div className="flex items-center">
                <BasicTooltip tip={t('Delete file')}>
                    <div
                        className="flex h-7 w-7 -translate-y-2 transform cursor-pointer items-center justify-center rounded-full bg-error opacity-0 transition-all hover:bg-error-light group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100"
                        onClick={() => deleteFile(file.id)}
                    >
                        <CloseIcon className="text-buttons-text" style={{ fontSize: '1.25rem' }} />
                    </div>
                </BasicTooltip>
            </div>
        </div>
    );
};

File.propTypes = {
    file: PropTypes.object,
    deleteFile: PropTypes.func,
};

File.defaultProps = {
    file: null,
    deleteFile: () => null,
};

const PMTaskManagementDocuments = (props) => {
    const { departmentId, partnerId, tenantUsers, closeModal, beneficiaryCheck } = props;
    const { user } = useContext(UserContext);
    const taskManagementContext = useContext(TaskManagementContext);

    const [files, setFiles] = useState([]);
    const [title, setTitle] = useState('');
    const [responsible, setResponsible] = useState([]);
    const [description, setDescription] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { projectId } = useParams();

    const deleteFile = (id) => {
        const copy = files.filter((file) => file.id !== id);
        setFiles(copy);
    };

    let schema = yup.object().shape({
        responsible: yup
            .array()
            .typeError(t('You must select at least one responsible!'))
            .min(1, t('You must select at least one responsible!'))
            .required(t('You must select at least one responsible!')),
        description: yup
            .string()
            .typeError(t('The description is required!'))
            .trim()
            .required(t('The description is required!')),
        title: yup
            .string()
            .trim()
            .typeError(t('The title for the activity is mandatory!'))
            .required(t('The title for the activity is mandatory!')),
        files: yup
            .array()
            .typeError(t('You must upload at least one document!'))
            .min(1, t('You must upload at least one document!'))
            .required(t('You must upload at least one document!')),
    });

    /**
     * @param {*} users The list of the users index from an dropdown
     * @returns An array with users ids
     */
    const userIndexToId = (users) => {
        return users.map((user) => tenantUsers[user].id);
    };

    const createPMTaskManagementDocuments = async () => {
        if (taskManagementContext.isCreating) return;

        try {
            await schema.validate({
                title,
                files,
                responsible,
                description,
            });

            try {
                const formData = new FormData();
                const reqBody = {
                    title,
                    description,
                    note: '-',
                    actionType: 'DOCUMENTS',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: userIndexToId(responsible),
                    supervisor: [],
                    watcher: [],
                    partnerId: partnerId ? partnerId : undefined,
                    pmDepartmentId: departmentId,
                    pmProjectPlanId: projectId,
                    dashboardAffiliate: beneficiaryCheck,
                };

                formData.append('data', JSON.stringify(reqBody));

                if (files.length) {
                    files.forEach((file) => formData.append('files', file.blob));
                }

                formData.append(
                    'filesMetaData',
                    JSON.stringify(files.map((file) => ({ name: file.info.name, type: file.fileType })))
                );

                taskManagementContext.submitTaskManagementActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    return (
        <form>
            <div className="flex flex-col">
                {files.map((file) => (
                    <File key={file.id} file={file} deleteFile={deleteFile} />
                ))}
            </div>

            <FileUploadContainer
                onUpload={(e) => {
                    uploadSingleFile(
                        e,
                        ({ message, blob, info }) => {
                            if (message) {
                                enqueueSnackbar(message, {
                                    variant: 'error',
                                });
                            } else if (blob && info) {
                                setFiles([
                                    ...files,
                                    {
                                        id: Math.random(),
                                        blob,
                                        info,
                                    },
                                ]);
                            }
                        },
                        'all'
                    );
                }}
            >
                <div className="mb-5">
                    <Button startIcon={<AddIcon />}>{t('Attach new document')}</Button>
                </div>
            </FileUploadContainer>

            <div className="mb-3">
                <TextField
                    placeholder={t('Add a title for the activity')}
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                />
            </div>

            <div className="mb-5">
                <TextField
                    placeholder={t('Add description')}
                    value={description}
                    multiline
                    rows={3}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>

            <div className="mb-10 w-1/3 sm:w-full">
                <LabelWrapper label={t('Responsible')}>
                    <MultiDropdown
                        options={tenantUsers.map((user) => user?.profile?.name)}
                        placeholder={t('Choose user')}
                        selectedOptions={responsible}
                        variant="black"
                        setSelectedOptions={(i) => {
                            if (responsible.indexOf(i) > -1) {
                                setResponsible(responsible.filter((opt) => opt !== i));
                            } else {
                                setResponsible([...responsible, i]);
                            }
                        }}
                    />
                </LabelWrapper>
            </div>

            <div className="flex gap-3">
                <Button color="secondary" startIcon={<AddIcon />} onClick={createPMTaskManagementDocuments}>
                    {t('Add documents')}
                </Button>

                <Button color="secondary" onClick={() => closeModal()}>
                    {t('Cancel')}
                </Button>
            </div>
        </form>
    );
};

PMTaskManagementDocuments.propTypes = {
    departmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    partnerId: PropTypes.string,
    tenantUsers: PropTypes.arrayOf(PropTypes.object),
    activity: PropTypes.object,
    closeModal: PropTypes.func,
    beneficiaryCheck: PropTypes.bool,
};

PMTaskManagementDocuments.defaultProps = {
    departmentId: null,
    partnerId: null,
    tenantUsers: [],
    activity: null,
    contactId: null,
    closeModal: () => null,
    beneficiaryCheck: false,
};

export default PMTaskManagementDocuments;
