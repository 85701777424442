import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';

import { Button, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper } from 'RaisisComponents';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { quotes } from 'routes';
import { errorHandling, formatPositiveNumberWithDigits, formatVATnumber } from 'utils';
import API from 'utils/axios';
import { getCompanyVAT } from 'utils/getterFunctions';
import * as yup from 'yup';

const AttributesForm = ({ attributeInfo }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const { articleId, attributeId } = useParams();

    const [attribute, setAttribute] = useState({
        articleId,
        name: null,
        unit: null,
        pricePerUnit: null,
    });

    const editAttribute = (key, value) => {
        const newAttributes = { ...attribute };
        newAttributes[key] = value;
        setAttribute(newAttributes);
    };

    const attributeSchema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .typeError(t('Name of attribute is required'))
            .min(2, t('Name of attribute must have at least two characters'))
            .required(t('Name of attribute is required')),
        // unit: yup.string().trim().typeError().nullable(),
        // pricePerUnit: yup
        //     .number()
        //     .typeError('aici')
        //     .min(1)
        //     .nullable(true)
        //     .transform((value) => (value === '' ? null : value)),
        // procentualVAT: yup.number().typeError('acolo').min(1).nullable(true),
    });

    const createAttribute = async () => {
        try {
            await attributeSchema.validate({
                name: attribute.name,
                // unit: attribute.unit,
                // pricePerUnit: attribute.pricePerUnit,
                // procentualVAT: attribute.procentualVAT,
            });

            if (attribute.pricePerUnit === 0) {
                enqueueSnackbar(t('The price per unit of the attribute must be greater than zero'), {
                    variant: 'error',
                });
                return;
            }

            try {
                await API.post('/articleAttribute', {
                    ...attribute,
                    unit: attribute.unit !== null && attribute.unit !== '' ? attribute.unit : '-',
                    pricePerUnit:
                        attribute.pricePerUnit !== null && attribute.pricePerUnit !== '' ? attribute.pricePerUnit : 0,
                    procentualVAT:
                        attribute.procentualVAT !== null &&
                        attribute.procentualVAT !== '' &&
                        attribute.pricePerUnit !== null &&
                        attribute.pricePerUnit !== ''
                            ? attribute.procentualVAT
                            : 0,
                });
                enqueueSnackbar(t('The attribute was successfully saved!'), {
                    variant: 'success',
                });
                history.push(quotes.base + quotes.attributes.base + '/' + articleId);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.error(err.errors);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    const updateAttribute = async () => {
        try {
            await attributeSchema.validate({
                name: attribute.name,
                // unit: attribute.unit,
                // pricePerUnit: attribute.pricePerUnit,
                // procentualVAT: attribute.procentualVAT,
            });

            if (attribute.pricePerUnit === 0) {
                enqueueSnackbar(t('The price per unit of the attribute must be greater than zero'), {
                    variant: 'error',
                });
                return;
            }

            try {
                await API.put('/articleAttribute', {
                    id: attribute.id,
                    data: {
                        name: attribute.name,
                        articleId: articleId,
                        price: {
                            priceId: attribute.priceId,
                            unit: attribute.unit !== null && attribute.unit !== '' ? attribute.unit : '-',
                            pricePerUnit:
                                attribute.pricePerUnit !== null && attribute.pricePerUnit !== ''
                                    ? attribute.pricePerUnit
                                    : 0,
                            procentualVAT:
                                attribute.procentualVAT !== null &&
                                attribute.procentualVAT !== '' &&
                                attribute.pricePerUnit !== null &&
                                attribute.pricePerUnit !== ''
                                    ? attribute.procentualVAT
                                    : 0,
                        },
                    },
                });
                enqueueSnackbar(t('The attribute was successfully updated!'), {
                    variant: 'success',
                });
                history.push(quotes.base + quotes.attributes.base + '/' + articleId);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.error(err.errors);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    // We format attribute object on edit, to be similar with initial value of the attribute object.
    useEffect(() => {
        if (!attributeId && Object.keys(attributeInfo).length === 0)
            (async () => {
                const value = await getCompanyVAT();
                const newAttribute = {
                    ...attribute,
                    procentualVAT: value,
                };

                setAttribute(newAttribute);
            })();

        if (attributeInfo && attributeId) {
            const newAttribute = {
                id: attributeInfo.id,
                name: attributeInfo.name,
                priceId: attributeInfo.priceId,
                pricePerUnit:
                    attributeInfo.price?.pricePerUnit === 0 ||
                    attributeInfo.price?.pricePerUnit === null ||
                    attributeInfo.price?.pricePerUnit === ''
                        ? null
                        : attributeInfo.price?.pricePerUnit,
                unit:
                    attributeInfo.price?.unit === '-' || attributeInfo.price?.unit === null
                        ? null
                        : attributeInfo.price?.unit,
                procentualVAT:
                    attributeInfo.price?.procentualVAT === 0 || attributeInfo.price?.procentualVAT === null
                        ? null
                        : attributeInfo.price?.procentualVAT,
            };

            setAttribute(newAttribute);
        }
    }, [attributeId, attributeInfo]);

    return (
        attribute !== null && (
            <>
                <div className="mb-20 space-y-3">
                    <div className="group relative flex max-w-4xl items-center gap-2 md:flex-col">
                        <LabelWrapper label={t('Attribute name')}>
                            <TextField
                                placeholder={t('Attribute name')}
                                value={attribute.name}
                                onChange={(e) => editAttribute('name', e.target.value)}
                            />
                        </LabelWrapper>
                        <div className="w-1/3 md:w-full">
                            <LabelWrapper label={t('Unit price')}>
                                <LocaleTextField
                                    placeholder={t('Unit price')}
                                    value={attribute.pricePerUnit}
                                    onChange={(e) =>
                                        editAttribute('pricePerUnit', formatPositiveNumberWithDigits(e.target.value))
                                    }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">Lei</InputAdornment>,
                                    }}
                                />
                            </LabelWrapper>
                        </div>
                        <div className="w-1/3 md:w-full">
                            <LabelWrapper label={t('VAT rate')}>
                                <LocaleTextField
                                    placeholder={t('VAT rate')}
                                    value={
                                        attribute.pricePerUnit !== null &&
                                        attribute.pricePerUnit !== '' &&
                                        attribute.pricePerUnit !== 0
                                            ? attribute.procentualVAT
                                            : 0
                                    }
                                    onChange={(e) => editAttribute('procentualVAT', formatVATnumber(e.target.value))}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    disabled={
                                        attribute.pricePerUnit !== null && attribute.pricePerUnit !== '' ? false : true
                                    }
                                />
                            </LabelWrapper>
                        </div>
                        <div className="w-1/3 md:w-full">
                            <LabelWrapper label={'UM'}>
                                <TextField
                                    placeholder={'UM'}
                                    value={attribute.unit}
                                    onChange={(e) => editAttribute('unit', e.target.value)}
                                />
                            </LabelWrapper>
                        </div>
                    </div>
                </div>

                {attribute.name !== null && attribute.name !== '' ? (
                    <Button
                        color="secondary"
                        startIcon={attributeId ? <EditIcon /> : <CheckIcon />}
                        onClick={() => {
                            if (attributeId) {
                                updateAttribute();
                            } else {
                                createAttribute();
                            }
                        }}
                    >
                        {attributeId ? t('Edit attribute') : t('Save attribute')}
                    </Button>
                ) : null}
            </>
        )
    );
};

AttributesForm.propTypes = {
    attributeInfo: PropTypes.object,
};

AttributesForm.defaultProps = {
    attributeInfo: {},
};

export default AttributesForm;
