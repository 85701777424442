import React from 'react';

import CreateNomenclature from 'pages/quotes/nomenclature/create-nomenclature';
import ManageNomenclatures from 'pages/quotes/nomenclature/manage-nomenclatures';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InitializeButtons from './initialize-buttons';
import InitializeStepInfo from './initialize-step-info';

const InitializeArticles = ({ handleIncrementStep, handleDecrementStep, initializeData, setInitializeData }) => {
    const { t } = useTranslation();

    return (
        <>
            <InitializeStepInfo
                mainInfo={[{ id: 0, content: t('You can create articles which will be used to create an offer') }]}
                secondaryInfo={[{ id: 0, content: t("If you don't want to add one, this step can be skipped") }]}
                className="mb-10"
            />
            {initializeData.tab === 0 && <ManageNomenclatures setInitializeData={setInitializeData} />}
            {initializeData.tab === 1 && <CreateNomenclature setInitializeData={setInitializeData} />}
            <InitializeButtons
                handleIncrementStep={() => handleIncrementStep(true, true)}
                handleDecrementStep={handleDecrementStep}
                className={'mt-20 flex justify-start gap-4'}
                canSkip={true}
                isFinalStep={true}
                nextDisabled={initializeData.nextDisabled || initializeData.loading}
                prevDisabled={initializeData.loading}
                skipDisabled={initializeData.loading}
            />
        </>
    );
};

InitializeArticles.propTypes = {
    handleIncrementStep: PropTypes.func,
    handleDecrementStep: PropTypes.func,
    initializeData: PropTypes.object,
    setInitializeData: PropTypes.func,
};

InitializeArticles.defaultProps = {
    handleIncrementStep: null,
    handleDecrementStep: null,
    initializeData: {},
    setInitializeData: () => {},
};

export default InitializeArticles;
