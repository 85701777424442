import React from 'react';

import PropTypes from 'prop-types';

import { NewSidebar } from '../NewSidebar/NewSidebar';

const wrapperClassName =
    'relative w-full h-svh flex md:flex-col  items-start justify-between bg-layout-main overflow-hidden';

export const Layout = (props) => {
    const {
        children,
        navItems,
        logoAction,
        userName,
        imageUrl,
        userDropdownActions,
        sidebar,
        footer,
        selectedItem,
        selectedSubItem,
        setSelectedItem,
        setSelectedSubItem,
        moduleName,
        backHandle,
        disabledSidebar,
    } = props;

    return (
        <div className={wrapperClassName}>
            {sidebar && (
                <NewSidebar
                    logoAction={logoAction}
                    userName={userName}
                    userDropdownActions={userDropdownActions}
                    imageUrl={imageUrl}
                    footer={footer}
                    navItems={navItems}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    selectedSubItem={selectedSubItem}
                    setSelectedSubItem={setSelectedSubItem}
                    moduleName={moduleName}
                    backHandle={backHandle}
                    disabled={disabledSidebar}
                />
            )}

            <main className="main-content-area overflow-x-hidden">
                <section className="content-section">{children}</section>
            </main>
        </div>
    );
};

Layout.propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.object),
    logoAction: PropTypes.func,
    children: PropTypes.element,
    userName: PropTypes.string,
    imageUrl: PropTypes.string,
    userDropdownActions: PropTypes.arrayOf(PropTypes.object),
    sidebar: PropTypes.bool,
    footer: PropTypes.element,
    selectedItem: PropTypes.number,
    setSelectedItem: PropTypes.func,
    selectedSubItem: PropTypes.number,
    setSelectedSubItem: PropTypes.func,
    moduleName: PropTypes.string,
    backHandle: PropTypes.func,
    disabledSidebar: PropTypes.bool,
};

Layout.defaultProps = {
    navItems: [],
    logoAction: () => null,
    children: <></>,
    userName: null,
    imageUrl: null,
    userDropdownActions: [],
    sidebar: true,
    footer: null,
    selectedItem: null,
    setSelectedItem: () => {},
    selectedSubItem: null,
    setSelectedSubItem: () => {},
    moduleName: '',
    backHandle: () => null,
    disabledSidebar: true,
};
