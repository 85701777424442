import React from 'react';

import PropTypes from 'prop-types';

import DocumentTemplateItem from './document-template-items/document-template-item';

const DocumentTemplateColumn = ({ displayTitle, column, elements, isMultiColumn, highlightAllPairs }) => {
    return (
        <div className="flex w-full flex-col gap-4">
            {displayTitle && isMultiColumn && <h3 className="text-center text-layout-lighter">{column.title}</h3>}
            <div
                className={`${
                    isMultiColumn ? 'rounded-sm border border-layout-lighter' : ''
                } flex h-full flex-col gap-4 p-2`}
            >
                {elements.map((el) => (
                    <DocumentTemplateItem key={el.id} element={el} highlightAllPairs={highlightAllPairs} />
                ))}
            </div>
        </div>
    );
};

DocumentTemplateColumn.propTypes = {
    displayTitle: PropTypes.bool,
    column: PropTypes.object,
    elements: PropTypes.array,
    isMultiColumn: PropTypes.bool,
    highlightAllPairs: PropTypes.bool,
};

DocumentTemplateColumn.defaultProps = {
    displayTitle: false,
    column: null,
    elements: [],
    isMultiColumn: false,
    highlightAllPairs: false,
};

export default DocumentTemplateColumn;
