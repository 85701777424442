import React, { useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { Button, CircularProgress } from '@material-ui/core';

import BigDropdown from 'components/shared/configurator/big-dropdown';
import CriterionField from 'components/shared/configurator/criterion-field';
import DateField from 'components/shared/configurator/date-field';
import EmailField from 'components/shared/configurator/email-field';
import FileField from 'components/shared/configurator/file-field';
import ImageField from 'components/shared/configurator/image-field';
import LinkField from 'components/shared/configurator/link-field';
import NumberField from 'components/shared/configurator/number-field';
import PhoneField from 'components/shared/configurator/phone-field';
import ReferenceField from 'components/shared/configurator/reference-field';
import SelectorField from 'components/shared/configurator/selector-field';
import StructureField from 'components/shared/configurator/structure-field';
import TextField from 'components/shared/configurator/text-field';
import ToggleField from 'components/shared/configurator/toggle-field';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import InitializeButtons from 'pages/initialize-company/initialize-buttons';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { slugify } from 'utils';
import API from 'utils/axios';
import { getEntityFields, getFields } from 'utils/getterFunctions';

const Configurator = (props) => {
    const { t } = useTranslation();
    const { entity, handleIncrementStep, handleDecrementStep, initializeData } = props;
    const [fields, setFields] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const { checkPerm } = useContext(UserContext);

    const [loading, setLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);

    const canAll = checkPerm([
        {
            permissionId: '13',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '13',
            permissionType: 'VIEW',
        },
    ]);

    const { permissionMap } = useContext(UserContext);
    const canAllPmContracts = permissionMap['Modul Project Manager'] > 1;
    const canViewPmContracts = permissionMap['Modul Project Manager'] > 0;

    const [cols, setCols] = useState([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const f = await getFields();
            setFields(f);

            const res = await getEntityFields(
                entity === 'contact'
                    ? 'Contacts'
                    : entity === 'contract'
                    ? 'Contracts'
                    : entity === 'estate'
                    ? 'Immobile'
                    : entity === 'contractPM'
                    ? 'ContractPM'
                    : 'Partners'
            );

            setCols(res.objectToRender);
            setLoading(false);
        })();
    }, []);

    const initializeStructure = (structure) => {
        const newStructure = {
            ...structure,
            options: [],
        };
        if (structure.options.length) {
            structure.options.forEach((option) => {
                newStructure.options.push(initializeStructure(option));
            });
        }
        return newStructure;
    };

    const addColumn = () => {
        setCols([...cols, { name: '', displayName: '', fields: [] }]);
    };

    const changeColumnName = (colIndex, newValue) => {
        const newCols = [...cols];
        newCols[colIndex].displayName = newValue;
        newCols[colIndex].name = slugify(newValue);
        setCols(newCols);
    };

    const deleteColumn = (colIndex) => {
        setCols(cols.filter((_, index) => index !== colIndex));
    };

    const addFieldToColumn = (type, colIndex) => {
        let newCols = [...cols];
        let defaultField = fields.filter((field) => field.type === type)[0];
        if (type === 'Structure') {
            newCols[colIndex].fields = [...newCols[colIndex].fields, initializeStructure(defaultField)];
        } else {
            newCols[colIndex].fields = [...newCols[colIndex].fields, { ...defaultField }];
        }
        setCols(newCols);
    };

    const deleteFieldFromColumn = (colIndex, fieldIndex) => {
        const newCols = [...cols];
        newCols[colIndex].fields = newCols[colIndex].fields.filter((_, index) => index !== fieldIndex);
        setCols(newCols);
    };

    const changeFieldValue = (colIndex, fieldIndex, fieldName, newValue) => {
        const newCols = [...cols];
        newCols[colIndex].fields[fieldIndex][fieldName] = newValue;
        if (fieldName === 'displayName') newCols[colIndex].fields[fieldIndex].name = slugify(newValue);
        setCols(newCols);
    };

    const submitChanges = async () => {
        try {
            setIsUpdating(true);

            await API.post('/field', {
                type:
                    entity === 'contact'
                        ? 'Contacts'
                        : entity === 'contract'
                        ? 'Contracts'
                        : entity === 'estate'
                        ? 'Immobile'
                        : entity === 'contractPM'
                        ? 'ContractPM'
                        : 'Partners',
                schema: cols,
            });
            // alert('Changes saved successfully!');
            enqueueSnackbar(t('Changes saved successfully!'), {
                variant: 'success',
            });
        } catch (err) {
            // console.error(`Could not update ${entity} field!`);
            enqueueSnackbar(t(`Could not update ${entity} field!`), {
                variant: 'error',
            });
            console.error(err);
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <>
            {((canView && entity !== 'contractPM') || (canViewPmContracts && entity === 'contractPM')) && entity && (
                <>
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <div
                                className={`relative z-20 grid grid-cols-5 gap-16 2xl:grid-cols-4 2xl:gap-12 xl:grid-cols-3 xl:gap-10 lg:grid-cols-2 md:grid-cols-1 sm:gap-8 ${
                                    isUpdating ? 'pointer-events-none' : ''
                                }`}
                            >
                                {cols.map((col, colIndex) => (
                                    <div
                                        key={colIndex}
                                        className={`relative ${
                                            ((!canAll && entity !== 'contractPM') ||
                                                (!canAllPmContracts && entity === 'contractPM') ||
                                                colIndex === 0 ||
                                                (colIndex === 1 && entity === 'contact')) &&
                                            'pointer-events-none opacity-75'
                                        }`}
                                        style={{ zIndex: 9999 - colIndex }}
                                        onClick={(e) => {
                                            if (
                                                (!canAll && entity !== 'contractPM') ||
                                                (!canAllPmContracts && entity === 'contractPM')
                                            ) {
                                                e.stopPropagation();

                                                enqueueSnackbar(
                                                    t(
                                                        "Column cannot be modified because you don't have the edit permission!"
                                                    ),
                                                    {
                                                        variant: 'error',
                                                    }
                                                );
                                            } else if (colIndex === 0) {
                                                {
                                                    e.stopPropagation();

                                                    enqueueSnackbar(t('Standard column cannot be modified!'), {
                                                        variant: 'error',
                                                    });
                                                }
                                            } else if (colIndex === 1 && entity === 'contact') {
                                                {
                                                    e.stopPropagation();

                                                    enqueueSnackbar(t('Extra Info column cannot be modified!'), {
                                                        variant: 'error',
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        <div className="group mb-8 flex w-full items-center justify-between">
                                            <input
                                                className="outline-none w-full border-none bg-transparent text-2xl font-semibold text-main-text"
                                                placeholder="Nume Coloană"
                                                value={col.displayName}
                                                onChange={(e) => changeColumnName(colIndex, e.target.value)}
                                            />
                                            <div className="ml-2 flex-shrink-0 opacity-0 transition-opacity group-hover:opacity-100">
                                                <ActionButton
                                                    onClick={() => deleteColumn(colIndex)}
                                                    icon={<CloseIcon className="text-main-text" />}
                                                />
                                            </div>
                                        </div>

                                        {col.fields.map((field, fieldIndex) => (
                                            <div key={`${colIndex} ${fieldIndex}`}>
                                                {field.type === 'Text' ? (
                                                    <TextField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                        multiline={field.selectedTypeOptions === 'Long'}
                                                        rows={field.selectedTypeOptions === 'Long' ? 3 : 1}
                                                    />
                                                ) : field.type === 'Number' ? (
                                                    <NumberField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'Phone' ? (
                                                    <PhoneField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'Email' ? (
                                                    <EmailField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'Link' ? (
                                                    <LinkField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'Selector' ? (
                                                    <SelectorField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'Structure' ? (
                                                    <StructureField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'Toggle' ? (
                                                    <ToggleField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'Date' ? (
                                                    <DateField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'Image' ? (
                                                    <ImageField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'File' ? (
                                                    <FileField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'Criterion' ? (
                                                    <CriterionField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : field.type === 'Reference' ? (
                                                    <ReferenceField
                                                        field={field}
                                                        changeFieldValue={(fieldName, newValue) =>
                                                            changeFieldValue(colIndex, fieldIndex, fieldName, newValue)
                                                        }
                                                        deleteFieldFromColumn={() =>
                                                            deleteFieldFromColumn(colIndex, fieldIndex)
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        ))}

                                        <BigDropdown addField={(type) => addFieldToColumn(type, colIndex)} />
                                    </div>
                                ))}

                                {((canAll && entity !== 'contractPM') ||
                                    (canAllPmContracts && entity === 'contractPM')) && (
                                    <div>
                                        <Button
                                            startIcon={<AddIcon className="text-buttons-text" />}
                                            color="primary"
                                            onClick={addColumn}
                                        >
                                            {t('Add Column')}
                                        </Button>
                                    </div>
                                )}
                            </div>

                            {((canAll && entity !== 'contractPM') ||
                                (canAllPmContracts && entity === 'contractPM')) && (
                                <>
                                    {!handleIncrementStep && !handleDecrementStep ? (
                                        <div className="relative z-10 flex gap-4 pt-12">
                                            <Button color="primary" disabled={isUpdating} onClick={submitChanges}>
                                                {t('Save Configurator')}
                                            </Button>
                                        </div>
                                    ) : (
                                        <InitializeButtons
                                            className={'relative z-10 flex gap-4 pt-20'}
                                            prevDisabled={isUpdating || initializeData.loading}
                                            nextDisabled={isUpdating || initializeData.loading}
                                            skipDisabled={isUpdating || initializeData.loading}
                                            handleIncrementCallback={submitChanges}
                                            handleIncrementStep={handleIncrementStep}
                                            handleDecrementStep={handleDecrementStep}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

Configurator.propTypes = {
    entity: PropTypes.string,
    handleIncrementStep: PropTypes.func,
    handleDecrementStep: PropTypes.func,
    initializeData: PropTypes.object,
};

Configurator.defaultProps = {
    entity: null,
    handleIncrementStep: null,
    handleDecrementStep: null,
    initializeData: {},
};

export default Configurator;
