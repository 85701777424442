import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { TextField } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import PropTypes from 'prop-types';

export const LocaleTextField = (props) => {
    const inputRef = useRef(null);
    const [cursor, setCursor] = useState({ start: 0, end: 0 });
    const { name, label, placeholder, value, InputProps, disabled, onChange, minDecimals, maxDecimals, className } =
        props;

    const { language } = useContext(GlobalContext);

    const localeValue = Number(value).toLocaleString(language, {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
    });

    useEffect(() => {
        if (!inputRef.current) return;

        inputRef.current.setSelectionRange(cursor.start, cursor.start);
    }, [cursor]);

    const handleTextFieldRef = useCallback((node) => {
        if (!node) return;

        const input = node.querySelector('input');
        inputRef.current = input;
    }, []);

    return (
        <div className={`inline-block ${disabled ? 'pointer-events-none' : ''} ${className}`}>
            <TextField
                ref={handleTextFieldRef}
                name={name}
                label={label}
                value={localeValue}
                type="text"
                placeholder={placeholder}
                InputProps={InputProps}
                disabled={disabled}
                onChange={(e) => {
                    let newValue = e.target.value;

                    const formatter = new Intl.NumberFormat(language);
                    const formatterValues = formatter.formatToParts(9999.9);
                    const groupValue = formatterValues.find((value) => value.type === 'group')?.value;
                    const decimalValue = formatterValues.find((value) => value.type === 'decimal')?.value;

                    newValue = newValue.replaceAll(groupValue, '');
                    newValue = newValue.replaceAll(decimalValue, '.');

                    const decimalIndex = newValue.indexOf('.');
                    if (decimalIndex > -1) {
                        const integerPart = newValue.substring(0, decimalIndex);
                        const fractionalPart = newValue.substring(decimalIndex + 1, newValue.length);
                        newValue = integerPart + '.' + fractionalPart.substring(0, maxDecimals);
                    }

                    const toLocaleChangedValue = Number(newValue).toLocaleString(language, {
                        minimumFractionDigits: minDecimals,
                        maximumFractionDigits: maxDecimals,
                    });

                    let selectionStart = e.target.selectionStart;
                    let selectionEnd = e.target.selectionEnd;

                    if (toLocaleChangedValue.length - localeValue.length === 2) {
                        selectionStart = e.target.selectionStart + 1;
                        selectionEnd = e.target.selectionEnd + 1;
                    }

                    if (
                        (toLocaleChangedValue.length - localeValue.length === -2 && newValue.indexOf('.') !== -1) ||
                        ((value?.toString().startsWith('0') || !value) &&
                            Number(newValue) !== 0 &&
                            selectionStart === 2 &&
                            selectionEnd === 2)
                    ) {
                        selectionStart = e.target.selectionStart - 1;
                        selectionEnd = e.target.selectionEnd - 1;
                    }

                    if (toLocaleChangedValue.length - localeValue.length === 2 && newValue.indexOf('.') === -1) {
                        selectionStart = e.target.selectionStart + 2;
                        selectionEnd = e.target.selectionEnd + 2;
                    }

                    if (toLocaleChangedValue.length - localeValue.length === 3 && newValue.indexOf('.') === -1) {
                        selectionStart = e.target.selectionStart + 3;
                        selectionEnd = e.target.selectionEnd + 3;
                    }

                    setCursor({ start: selectionStart, end: selectionEnd });

                    onChange({
                        target: { value: newValue, name: e.target.name },
                    });
                }}
            />
        </div>
    );
};

LocaleTextField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    InputProps: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    minDecimals: PropTypes.number,
    maxDecimals: PropTypes.number,
    className: PropTypes.string,
};

LocaleTextField.defaultProps = {
    name: null,
    label: null,
    placeholder: null,
    value: '',
    InputProps: null,
    disabled: false,
    onChange: () => {},
    minDecimals: 2,
    maxDecimals: 2,
    className: '',
};
