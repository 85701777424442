import React, { useContext } from 'react';

import GlobalContext from 'contexts/GlobalContext';
import PropTypes from 'prop-types';

const CustomModal = (props) => {
    const { children, open, styles } = props;
    const { theme } = useContext(GlobalContext);

    return open ? (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.3)',
                zIndex: 99999,
                backdropFilter: 'blur(5px)',
                overflow: 'scroll',
            }}
        >
            <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    zIndex: 520,
                    backgroundColor: `rgb(${theme['LAYOUT-COLORS']['LIGHT-LAYOUT'].color})`,
                    borderRadius: '8px',
                    ...styles,
                }}
            >
                {children}
            </div>
        </div>
    ) : null;
};

CustomModal.propTypes = {
    children: PropTypes.object,
    open: PropTypes.bool,
    // setOpen: PropTypes.func,
    // onClose: PropTypes.func,
    styles: PropTypes.object,
};

export default CustomModal;
