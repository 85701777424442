import React, { useContext, useEffect, useState } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

import { CircularProgress } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import { PropTypes } from 'prop-types';
import { ActionButton, Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { formatDate, formatTime } from 'utils';
import API from 'utils/axios';

const ProjectSelection = ({
    title,
    label,
    entityId,
    pmProjectId,
    selectedProject,
    setSelectedProject,
    style,
    disabled,
}) => {
    const { theme } = useContext(GlobalContext);
    const optionClass = 'text-base text-dark-text mb-2';
    const optionSpanClass = 'text-main-text font-bold';
    const HeaderType = style.headerType;
    const { t } = useTranslation();
    const [pmProjects, setPmProjects] = useState([]);
    const [loading, setLoading] = useState(false);

    const getPMProjects = async () => {
        setLoading(true);

        try {
            const response = await API.get('/PmProjects', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                },
            });

            const projects = response.data.PmProjects.content;

            if (entityId && pmProjectId) setSelectedProject(projects.find((project) => project.id === pmProjectId));

            setPmProjects(projects);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPMProjects();
    }, []);

    const handleSelectedOption = () => {
        const selectedOption = pmProjects.findIndex((project) => project.id === selectedProject?.id);

        if (selectedOption >= 0) return selectedOption;
        else return null;
    };

    return loading ? (
        <div className="flex items-start justify-center" style={{ ...style?.containerStyle }}>
            <CircularProgress />
        </div>
    ) : (
        <div className="flex flex-col gap-5" style={{ ...style?.containerStyle }}>
            <HeaderType className="mb-5" style={{ ...style?.headerStyle }}>
                {title}
            </HeaderType>

            <div className="flex items-center gap-3">
                <div className="inline-block" style={{ maxWidth: 'calc(100% - 2.5rem)' }}>
                    <LabelWrapper label={label}>
                        <Dropdown
                            disabled={disabled}
                            variant="black"
                            options={pmProjects.map((i) => i.name)}
                            placeholder={t('Choose the project')}
                            selectedOption={handleSelectedOption()}
                            setSelectedOption={(e) => setSelectedProject(pmProjects[e])}
                        />
                    </LabelWrapper>
                </div>

                <div className="mt-6 flex-shrink-0">
                    <ActionButton
                        icon={<DeleteIcon />}
                        color={
                            selectedProject !== null
                                ? `rgb(${theme['STATE-COLORS']['ERROR'].color})`
                                : `rgb(${theme['STATE-COLORS']['DISABLED'].color})`
                        }
                        disabled={disabled || selectedProject === null ? true : false}
                        onClick={() => setSelectedProject(null)}
                    />
                </div>
            </div>
            {selectedProject !== null && (
                <div className="max-w-xl rounded-md bg-layout-transparent p-4">
                    <h4 className="mb-5 mt-5 whitespace-nowrap">{t('Project info')}</h4>
                    <p className={optionClass}>
                        {t('Project name')}: <span className={optionSpanClass}>{selectedProject.name}</span>
                    </p>
                    <p className={optionClass}>
                        {t('Allocated teams')}:{' '}
                        <span className={optionSpanClass}>
                            {(() => {
                                let string = selectedProject.teams
                                    .slice(0, 3)
                                    .map((t) => t.name)
                                    .join(', ');
                                if (selectedProject.teams.length > 3)
                                    string += ` + ${t('others')} ${selectedProject.teams.length - 3}`;
                                return string;
                            })()}
                        </span>
                    </p>
                    <p className={optionClass}>
                        {t('Created on')}:{' '}
                        <span className={optionSpanClass}>{formatDate(selectedProject.createAt)}</span>
                    </p>
                    <p className={optionClass}>
                        {t('Last update')}:{' '}
                        <span className={optionSpanClass}>
                            {formatDate(selectedProject.updateAt)}, {formatTime(selectedProject.updateAt)}
                        </span>
                    </p>
                </div>
            )}
        </div>
    );
};

ProjectSelection.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    entityId: PropTypes.string,
    pmProjectId: PropTypes.string,
    selectedProject: PropTypes.object,
    setSelectedProject: PropTypes.func,
    style: PropTypes.object,
    disabled: PropTypes.bool,
};

ProjectSelection.defaultProps = {
    title: '',
    label: '',
    entityId: null,
    pmProjectId: null,
    selectedProject: null,
    setSelectedProject: () => {},
    style: null,
    disabled: false,
};

export default ProjectSelection;
