import React from 'react';

import { Button, TextField } from '@material-ui/core';

import PropTypes from 'prop-types';
import { LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';

const UpdateEmployee = (props) => {
    const { roleName, setRoleName, teams, phone, setPhone, email, setEmail, updateEmployee } = props;
    const { t } = useTranslation();

    return (
        <>
            <div className="mb-16 flex w-full max-w-5xl items-start space-x-16">
                <div className="w-full space-y-8">
                    <h3 className="font-semibold">{t('User details')}</h3>
                    <TextField
                        label={t('Role')}
                        placeholder={t('Role name')}
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                    />
                    <LabelWrapper label={t('Allocated in teams')}>
                        <div className="flex flex-wrap rounded-md bg-layout-transparent px-5 py-2.5 text-main-text">
                            {teams.map((team, teamIndex) => (
                                <span key={team.name}>
                                    {`${team.name}${teamIndex === teams.length - 1 ? '' : ','}`}
                                    &nbsp;
                                </span>
                            ))}
                        </div>
                    </LabelWrapper>
                </div>
                <div className="w-full space-y-8">
                    <h3 className="font-semibold">{t('Contact')}</h3>
                    <TextField
                        label={t('Phone number')}
                        placeholder={t('Phone number')}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <TextField
                        label="Email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
            </div>
            <Button color="primary" onClick={updateEmployee}>
                {t('Update')}
            </Button>
        </>
    );
};

UpdateEmployee.propTypes = {
    roleName: PropTypes.string,
    setRoleName: PropTypes.func,
    phone: PropTypes.string,
    setPhone: PropTypes.func,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    teams: PropTypes.array,
    updateEmployee: PropTypes.func,
};

UpdateEmployee.defaultProps = {
    roleName: '',
    setRoleName: null,
    phone: '',
    setPhone: null,
    email: '',
    setEmail: null,
    teams: [],
    updateEmployee: null,
};

export default UpdateEmployee;
