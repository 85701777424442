import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import API from 'utils/axios';

const PDFTemplateRow = ({ template, isInitializationCase }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '34',
            permissionType: 'ALL',
        },
    ]);

    return (
        <TableRow colSpan={3} padding={false}>
            <TableCell>{template.name}</TableCell>
            <TableCell>
                <div className="flex justify-end">
                    {canAll && !isInitializationCase && (
                        <Button
                            color="secondary"
                            size="small"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() =>
                                history.push(
                                    configurator.base +
                                        configurator.pdfTemplates.base +
                                        configurator.pdfTemplates.update +
                                        '/' +
                                        template.id
                                )
                            }
                        >
                            {t('Edit PDF template')}
                        </Button>
                    )}
                </div>
            </TableCell>
        </TableRow>
    );
};

PDFTemplateRow.propTypes = {
    template: PropTypes.object,
    isInitializationCase: PropTypes.bool,
};

PDFTemplateRow.defaultProps = {
    template: null,
    isInitializationCase: false,
};

const ManagePDFTemplates = ({ setInitializeData }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { checkPerm } = useContext(UserContext);

    const [templates, setTemplates] = useState([]);

    const getTemplates = async (currentPage, perPage) => {
        try {
            const response = await API.get('offer_template_pages', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            setTemplates(response.data.data.data);
            setInitializeData?.((prev) => ({ ...prev, nextDisabled: !response.data.data.length }));
            return response.data.data.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    const canView = checkPerm([
        {
            permissionId: '34',
            permissionType: 'VIEW',
        },
    ]);

    useEffect(() => {
        if (!canView) {
            history.push('/');
        }
    }, [canView]);

    return (
        <>
            {!setInitializeData && (
                <Helmet>
                    <title>
                        {t('ERP Configurator')} | {t('CRM Departments')}
                    </title>
                </Helmet>
            )}

            <Pagination loading={loading} setLoading={setLoading} getFunction={getTemplates}>
                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : templates.length ? (
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('PDF template name')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableSeparator />
                                {templates?.map((template) => (
                                    <PDFTemplateRow
                                        key={template.id}
                                        template={template}
                                        isInitializationCase={!!setInitializeData}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <NoDataPlaceholder />
                )}
            </Pagination>
        </>
    );
};

ManagePDFTemplates.propTypes = {
    setInitializeData: PropTypes.func,
};

ManagePDFTemplates.defaultProps = {
    setInitializeData: null,
};

export default ManagePDFTemplates;
