import React from 'react';

import PropTypes from 'prop-types';

import { Toggle } from '../Toggle/Toggle';

export const CheckItem = (props) => {
    const { name, checked, setChecked, small, disabled } = props;

    return (
        // eslint-disable-next-line
        <div
            className={`flex-shrink-0 rounded-md bg-layout-transparent ${small ? 'p-1.5' : 'p-3'} ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-layout-transparent-dark'
            } flex items-center`}
            onClick={disabled ? undefined : () => setChecked(!checked)}
        >
            <Toggle checked={checked} disabled={disabled} />
            <p className={`${small ? 'text-base font-normal' : 'text-lg font-bold'} ml-2 pr-2 text-buttons-text`}>
                {name}
            </p>
        </div>
    );
};

CheckItem.propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    setChecked: PropTypes.func,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
};

CheckItem.defaultProps = {
    name: '',
    checked: false,
    setChecked: () => null,
    small: false,
    disabled: false,
};
