import React, { useEffect, useState } from 'react';

import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import { PropTypes } from 'prop-types';
import { TableSeparator } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { formatDate, formatTime } from 'utils';

const UserActivity = ({ userActions }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }, []);

    return (
        <>
            {loading ? (
                <div className="mt-5">
                    <LinearProgress />
                </div>
            ) : userActions.length !== 0 ? (
                <div className="mt-5">
                    <TableContainer component={Box}>
                        <Table>
                            <TableBody>
                                <TableSeparator />
                                {userActions.map((actions, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <div className="inline-block whitespace-nowrap">
                                                {formatDate(actions.createdAt)},
                                                <div className="ml-2 inline-flex">{formatTime(actions.createdAt)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell>{actions.description}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ) : (
                <h3 className="ml-2 mt-8 text-sm text-main-text">{t('The user has no activity yet!')}</h3>
            )}
        </>
    );
};

UserActivity.propTypes = {
    userActions: PropTypes.array,
};

UserActivity.defaultProps = {
    userActions: [],
};

export default UserActivity;
