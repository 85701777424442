import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

const GanttTable = ({ tasks, type }) => {
    const { t } = useTranslation();

    const headerStyes = {
        height: '60px',
        backgroundColor: '#e2e8f0',
    };

    const bodyRowStyles = {
        backgroundColor: '#ffffff',
    };

    const cellStyles = {
        borderTop: '1px solid rgb(192,192,192)',
        borderBottom: '1px solid rgb(192,192,192)',
        borderRight: '1px solid rgb(192,192,192)',
    };

    const lastAndFirstCellsStyles = {
        borderRight: '1px solid rgb(192,192,192)',
    };

    const mappedDateKeys = {
        'projectStructures-start': (task) => task['currentStart'],
        'projectStructures-end': (task) => task['currentEnd'],
        'milestones-start': (task) => task['start'],
        'milestones-end': (task) => task['end'],
    };

    const mappedDateFormats = {
        milestones: (date) => formatDate(date),
        projectStructures: (current, initial) => `${formatDate(current)} / ${formatDate(initial)}`,
    };

    const mappedDateHeader = {
        milestones: (word) => t(`${word} date`),
        projectStructures: (word) => (
            <div className="flex flex-col items-center gap-0.5">
                <span>{t(`${word} date`)}</span>
                <span>({t('Real / Estimated')})</span>
            </div>
        ),
    };

    return (
        <table className="border-none text-xl text-black">
            <thead>
                <tr style={headerStyes}>
                    <th className="whitespace-nowrap px-2 text-left">{t('Task name')}</th>
                    <th className="whitespace-nowrap px-2 text-left">{mappedDateHeader[type]('Start')}</th>
                    <th className="whitespace-nowrap px-2 text-left" style={lastAndFirstCellsStyles}>
                        {mappedDateHeader[type]('End')}
                    </th>
                </tr>
            </thead>
            <tbody>
                {tasks.map((task, index) => {
                    return (
                        <tr key={task.id} style={{ ...bodyRowStyles, ...task.rowStyles }}>
                            <td
                                style={
                                    index === 0 || index === tasks.length - 1
                                        ? {
                                              ...lastAndFirstCellsStyles,
                                              minWidth: '300px',
                                              maxWidth: '300px',
                                          }
                                        : {
                                              ...cellStyles,
                                              minWidth: '300px',
                                              maxWidth: '300px',
                                          }
                                }
                            >
                                <p
                                    className="text-xl text-black"
                                    style={{
                                        ...task.styles,
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    {task.name}
                                </p>
                            </td>
                            <td
                                className="whitespace-nowrap"
                                style={
                                    index === 0 || index === tasks.length - 1
                                        ? { ...lastAndFirstCellsStyles, ...task.styles, padding: '0 0.5rem' }
                                        : {
                                              ...cellStyles,
                                              fontWeight: task.styles.fontWeight,
                                              padding: '0 0.5rem',
                                          }
                                }
                            >
                                {mappedDateFormats[type](mappedDateKeys[`${type}-start`](task), task.initialStart)}
                            </td>
                            <td
                                className="whitespace-nowrap"
                                style={
                                    index === 0 || index === tasks.length - 1
                                        ? { ...lastAndFirstCellsStyles, ...task.styles, padding: '0 0.5rem' }
                                        : {
                                              ...cellStyles,
                                              fontWeight: task.styles.fontWeight,
                                              padding: '0 0.5rem',
                                          }
                                }
                            >
                                {mappedDateFormats[type](mappedDateKeys[`${type}-end`](task), task.initialEnd)}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

GanttTable.propTypes = {
    tasks: PropTypes.array,
    type: PropTypes.string,
};

GanttTable.defaultProps = {
    tasks: [],
    type: 'milestones',
};

export default GanttTable;
