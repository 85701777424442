import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Footer from 'components/footer/footer';
import Resource from 'components/planning-overview/resource-page/resource';
import Loading from 'components/shared/loading';
import UserContext from 'contexts/UserContext';
import PMCreateProjects from 'pages/all-pm-module/pm-create-project';
import PMEditProject from 'pages/all-pm-module/pm-edit-project';
import FinancialTools from 'pages/financial-tool/financial-tool';
import { projectManagement } from 'routes';
import API from 'utils/axios';

import AllPMConfigurator from './all-pm-configurator/all-pm-configurator';
import AllProjectsManagement from './all-pm-management/all-pm-management';
import PMPlanningOverview from './pm-planning-overview';

const ProjectManagement = () => {
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [pmId, setPmId] = useState('');

    const getPmModuleInfo = async () => {
        try {
            setLoading(true);

            const response = await API.get('/Pm');
            setPmId(response.data.pm.id);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPmModuleInfo();
    }, []);

    return (
        <React.Fragment>
            {loading ? (
                <Loading classes="h-svh" />
            ) : (
                <Switch>
                    {/* View all projects */}
                    <Route exact path={projectManagement.base}>
                        <AllProjectsManagement pmId={pmId} />
                    </Route>

                    {/* Create new project */}
                    <Route
                        exact
                        path={
                            projectManagement.base +
                            projectManagement.projectEntity.base +
                            projectManagement.projectEntity.create
                        }
                    >
                        <PMCreateProjects pmId={pmId} />
                    </Route>

                    {/* Update existing project */}
                    <Route
                        exact
                        path={
                            projectManagement.base +
                            projectManagement.projectEntity.base +
                            projectManagement.projectEntity.updateFull
                        }
                    >
                        <PMEditProject pmId={pmId} />
                    </Route>

                    {/* Planning Overview per all projects */}
                    <Route exact path={projectManagement.base + projectManagement.projectsPlaningOverview.base}>
                        <PMPlanningOverview />
                    </Route>

                    <Route path={projectManagement.base + projectManagement.projectsPlaningOverview.resourceFull}>
                        <Resource type="pm" />
                    </Route>

                    <Route path={projectManagement.base + projectManagement.projectsReports.base}>
                        <div className="page-container relative">
                            <h3>Feature in development!</h3>
                        </div>
                    </Route>

                    {/* P&L and Cashflow for each project  */}
                    <Route
                        path={projectManagement.base + projectManagement.projectsPL.base}
                        key={projectManagement.base + projectManagement.projectsPL.base}
                    >
                        <FinancialTools />
                    </Route>
                    <Route
                        path={projectManagement.base + projectManagement.projectsCashflow.base}
                        key={projectManagement.base + projectManagement.projectsCashflow.base}
                    >
                        <FinancialTools />
                    </Route>

                    {/* All projects configurator */}
                    {user && !user.isInvestor && (
                        <Route path={projectManagement.base + projectManagement.projectManagementConfigurator.base}>
                            <AllPMConfigurator />
                        </Route>
                    )}
                </Switch>
            )}

            <Footer />
        </React.Fragment>
    );
};

export default ProjectManagement;
