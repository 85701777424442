import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { Button, CircularProgress, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import BasicTooltip from 'components/shared/basic-tooltip';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import GlobalContext from 'contexts/GlobalContext';
import useScreenSizes from 'hooks/useScreenSizes.js';
import { useSnackbar } from 'notistack';
import { ActionButton, Header, LabelWrapper } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { quotes } from 'routes';
import { errorHandling, toLocaleNumber } from 'utils';
import API from 'utils/axios';

const ManageAttributes = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [width] = useScreenSizes();

    const { enqueueSnackbar } = useSnackbar();
    const { currencyObj } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const globalContext = useContext(GlobalContext);

    const { articleId } = useParams();

    const [attributes, setAttributes] = useState([]);

    const pageRef = useRef({ currentPage: 0, perPage: 10 });

    const getAttributes = async (currentPage, perPage) => {
        try {
            const res = await API.get('/articleAttributes', {
                params: {
                    articleId,
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            pageRef.current = { currentPage, perPage };
            setAttributes(res.data.allArticleAttributes.articleAttributes);
            return res.data.allArticleAttributes.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    /**
     *
     * @param {*} attributeId - id of the attribute
     */
    const deleteAttribute = async (attributeId) => {
        try {
            await API.delete('/articleAttribute', {
                params: {
                    id: attributeId,
                },
            });

            await getAttributes(pageRef.current.currentPage, pageRef.current.perPage);

            enqueueSnackbar(t('Attribute deleted successfully!'), {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Attributes')}</title>
            </Helmet>

            <Header
                pageTitle={t('Attributes')}
                action={
                    <Button
                        color="secondary"
                        startIcon={<AddIcon />}
                        style={{ borderRadius: '999px' }}
                        onClick={() =>
                            history.push(
                                quotes.base + quotes.attributes.base + quotes.attributes.create + '/' + articleId
                            )
                        }
                    >
                        {t('Add attribute')}
                    </Button>
                }
            ></Header>

            <div className="page-container">
                <Pagination loading={loading} setLoading={setLoading} getFunction={getAttributes}>
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : attributes.length ? (
                        <div className="flex flex-col md:gap-10">
                            {attributes.map((attribute, index) => (
                                <div key={attribute.id} className="group flex max-w-4xl items-center gap-2">
                                    <div className="pt-3 text-main-text md:self-start">{index + 1}.</div>
                                    <div className="flex items-center gap-2 md:w-3/4 md:flex-col sm:w-full">
                                        <LabelWrapper
                                            label={`${
                                                index === 0
                                                    ? t('Attribute name')
                                                    : width <= 750
                                                    ? t('Attribute name')
                                                    : ''
                                            }`}
                                        >
                                            <TextField
                                                placeholder={t('Attribute name')}
                                                value={attribute.name}
                                                disabled={true}
                                                readOnly={true}
                                            />
                                        </LabelWrapper>
                                        <div className="w-1/3 md:w-full">
                                            <LabelWrapper
                                                label={`${
                                                    index === 0 ? t('Unit price') : width <= 750 ? t('Unit price') : ''
                                                }`}
                                            >
                                                <TextField
                                                    placeholder={'-'}
                                                    value={
                                                        attribute.price?.pricePerUnit === 0
                                                            ? null
                                                            : toLocaleNumber(attribute.price?.pricePerUnit, 2)
                                                    }
                                                    disabled={true}
                                                    readOnly={true}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                {currencyObj.currency}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </LabelWrapper>
                                        </div>
                                        <div className="w-1/3 md:w-full">
                                            <LabelWrapper
                                                label={`${
                                                    index === 0 ? t('VAT rate') : width <= 750 ? t('VAT rate') : ''
                                                }`}
                                            >
                                                <LocaleTextField
                                                    placeholder={t('-')}
                                                    value={
                                                        attribute.price?.procentualVAT === 0
                                                            ? 0
                                                            : attribute.price?.procentualVAT
                                                    }
                                                    disabled={true}
                                                    readOnly={true}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">%</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </LabelWrapper>
                                        </div>
                                        <div className="w-1/3 md:w-full">
                                            <LabelWrapper label={`${index === 0 ? 'UM' : width <= 750 ? 'UM' : ''}`}>
                                                <TextField
                                                    placeholder={t('-')}
                                                    value={attribute.price?.unit === '-' ? null : attribute.price?.unit}
                                                    disabled={true}
                                                    readOnly={true}
                                                />
                                            </LabelWrapper>
                                        </div>
                                        <div className="mt-5 flex h-full gap-2 pl-1">
                                            <div className="flex-shrink-0">
                                                <BasicTooltip tip={t('Edit attribute')}>
                                                    <ActionButton
                                                        color="var(--success)"
                                                        icon={<EditIcon />}
                                                        onClick={() =>
                                                            history.push(
                                                                quotes.base +
                                                                    quotes.attributes.base +
                                                                    quotes.attributes.update +
                                                                    '/' +
                                                                    attribute.id +
                                                                    '/' +
                                                                    articleId
                                                            )
                                                        }
                                                    />
                                                </BasicTooltip>
                                            </div>

                                            <div className="duration-250 flex-shrink-0 translate-x-5 transform opacity-0 transition-all group-hover:translate-x-0 group-hover:opacity-100 md:translate-x-0 md:opacity-100">
                                                <BasicTooltip tip={t('Delete attribute')}>
                                                    <ActionButton
                                                        icon={<DeleteIcon />}
                                                        color="var(--error)"
                                                        onClick={() => {
                                                            globalContext.setGlobalModalOpen(true);
                                                            globalContext.setGlobalModalChildren(
                                                                <div className="flex-col">
                                                                    <p className="mb-3 text-center text-2xl font-bold text-main-text">
                                                                        {t('If an attribute is deleted,')}
                                                                        <br />
                                                                        {t(
                                                                            'this will be deleted permanently from this article!'
                                                                        )}
                                                                    </p>
                                                                    <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                                        {t('Are you sure you want to')}
                                                                        <br />
                                                                        {t('delete this attribute?')}
                                                                    </p>
                                                                    <div className="flex items-center justify-center">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                globalContext.setGlobalModalOpen(false);
                                                                                deleteAttribute(attribute.id);
                                                                            }}
                                                                        >
                                                                            {t('Yes')}
                                                                        </Button>
                                                                        <div className="w-4" />
                                                                        <Button
                                                                            onClick={() => {
                                                                                globalContext.setGlobalModalOpen(false);
                                                                            }}
                                                                        >
                                                                            {t('No')}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </BasicTooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </Pagination>
            </div>
        </>
    );
};

export default ManageAttributes;
