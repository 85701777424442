const ro = {
    Invoices: 'Facturi',
    'Add invoice': 'Adaugă factură',
    'Edit invoice': 'Editează factură',

    'Add the invoice': 'Adaugă factura',
    'Reset the invoice': 'Resetează factura',

    'Upload invoice PDF': 'Încarcă PDF-ul facturii',

    // Invoice types
    Expense: 'Cheltuială',
    Income: 'Venit',
    Advance: 'Avans',
    Reverse: 'Stornare',

    'Pick the invoice type': 'Alegeți tipul de factură',

    'Change the invoice type': 'Schimbați tipul facturii',

    'Error on loading invoice PDF': 'Eroare la încărcarea PDF-ului facturii',
    'Invoice PDF is loading, please wait!': 'PDF-ul facturii se încarcă, vă rugăm așteptați',

    'Choose the client of the invoice': 'Alegeți clientul pe care se va emite factura',

    'Configure the invoice': 'Configurează factura',
    'Client invoice': 'Clientul ofertei',

    'CRM contracts': 'Contracte CRM',

    'Invoice based on a CRM contract': 'Factura pe baza unui contract din CRM',
    'Invoice based on a PM project': 'Factura pe baza unui proiect din PM',

    'Choose the project for the invoice': 'Alegeți proiectul pentru factură',
    'Choose the contract for the invoice': 'Alegeți contractul pentru factură',

    'The invoice can be based on a contract or on a project, but not both!':
        'Factura se poate crea pe baza unui contract sau pe baza unui proiect, dar nu pe ambele!',

    'Reverse invoices': 'Facturi de avans',
    'Choose the advance invoice for the reverse': 'Alegeți factura de avans pentru stornare',

    'Reversal value (with VAT)': 'Valoare stornare (cu TVA)',
    'Reversal value': 'Valoare stornare',

    'Reversal value in the reference currency (with VAT)': 'Valoare stornare în moneda de referință (cu TVA)',
    'Reversal value in the reference currency': 'Valoare stornare în moneda de referință',

    'Simple income invoice': 'Factură simplă de tip venit',
    'Income invoice with advance reversal': 'Factură de venit cu stornare de avans',

    'Change the income invoice type': 'Schimbați tipul de factură de venit',

    'Invoice name': 'Denumire factură',
    'Invoice number': 'Număr factură',
    'Issue date of the invoice': 'Date de emitere a facturii',

    'Configure the expense invoice': 'Configurează factura de tip cheltuială',
    'Configure the advance invoice': 'Configurează factura de tip avans',
    'Configure the reversal invoice': 'Configurează factura de tip stornare',
    'Configure the simple income invoice': 'Configurează factura de tip venit',
    'Configure the income invoice with advance reversal': 'Configurează factura de tip venit cu stornare de avans',

    'The selected client has no contract yet! Create a new one or pick another client for the invoice!':
        'Clientul selectat nu are niciun contract! Creați unul sau alegeți un alt client pentru factură!',

    'The selected client has no contract yet! If you want the invoice to be on a contract you have to create one first!':
        'Clientul selectat nu are niciun contract! Dacă doriți ca factura să fie pe un contract, trebuie să creați mai întâi unul!',

    'The selected client has no project yet! Create a new one or pick another client for the invoice!':
        'Clientul selectat nu are niciun proiect! Creați unul sau alegeți un alt client pentru factură!',

    'The invoice name is required': 'Numele facturii este obligatoriu!',
    'The invoice number is required': 'Numărul facturii este obligatoriu!',
    'The invoice number must be at least 3 characters long!':
        'Numărul facturii trebuie să conțină cel puțin 3 caractere!',

    'Invoice value (with VAT)': 'Valoare factură (cu TVA)',
    'Invoice value': 'Valoare factură',

    'The invoice has been added successfully!': 'Factura a fost adăugată cu succes!',

    //Manage invoices
    'Invoice type': 'Tip factură',
    'Invoice status': 'Status factură',
    'Income with advance reversal': 'Venit cu stornare de avans',

    Validated: 'Validată',
    Invalidate: 'Invalidată',
    'Approve the invoice': 'Aprobă factura',

    'approve this invoice?': 'aprobați această factură?',
    'The invoice was validated!': 'Factura a fost validată!',

    'The invoice must have one expense selected!': 'Factura trebuie să aibă cel puțin o cheltuială selectată!',
    'The invoice must have one revenue selected!': 'Factura trebuie să aibă cel puțin un venit selectat!',

    Reversal: 'Stornare',
    'Update the invoice': 'Editează factura',
    'The invoice has been updated successfully!': 'Factura a fost editată cu succes!',

    'There are not advance invoices to be reversed! You must create an advance invoice!':
        'Nu sunt facturi de tip avans pentru a fi stornate! Trebuie să creați o factură de avans!',

    'Invoice value after reverse (with VAT)': 'Valoare factură după stornare (cu TVA)',
    'Invoice value after reverse': 'Valoare factură după stornare',

    'Invoice value in the reference currency after reverse (with VAT)':
        'Valoare factură în moneda de referință după stornare (cu TVA)',
    'Invoice value in the reference currency after reverse': 'Valoare factură în moneda de referință după stornare',

    Reversed: 'Stornată',

    'Delete invoice': 'Șterge factura',
    'delete this invoice?': 'ștergeți această factură?',
    'The invoice was deleted!': 'Factura a fost ștearsă cu succes!',

    'Invoice is deleted': 'Factura este ștearsă',
    'Payment status': 'Status plată',
    COLLECTED: 'ÎNCASATĂ',
    PAID: 'PLĂTITĂ',
    UNPAID: 'NEPLĂTITĂ',
    UNCOLLECTED: 'NEÎNCASATĂ',
    'Mark the invoice as collected': 'Marchează factura ca încasată',
    'mark as collected this invoice?': 'marcați ca încasată această factură?',
    'Advance invoice for the contract - ': 'Factura de avans pentru contractul - ',
    'The invoice was marked as paid!': 'Factura a fost marcată ca fiind încasată!',

    'Change revenue': 'Schimbă venit',
    'Change revenue/s': 'Schimbă venit/uri',
    'Add revenue/s': 'Adaugă venit/uri',
    'Change expense': 'Schimbă cheltuială',
    'Nomenclature expenses': 'Cheltuieli din nomenclator',
    'Article expenses': 'Cheltuieli din articol',
    Partner: 'Partener',
    'Choose the partner of the invoice': 'Alege-ţi partenerul afiliat facturii',
    'PM contracts': 'Contract PM',
    'The value of the invoice': 'Valoarea facturii',
    'must be equal to the sum of values of the checked expenses':
        'trebuie să fie egală cu suma valorilor cheltuielilor bifate',
    'must be equal to the sum of values of the checked revenues':
        'trebuie să fie egală cu suma valorilor veniturilor bifate',

    // EXCHANGE RATE
    'Select the date for the exchange rate': 'Selectați data pentru cursul valutar de schimb',
    'Invoice value in the reference currency (with VAT)': 'Valoarea facturii în moneda de referință (cu TVA)',
    'Invoice value in the reference currency': 'Valoarea facturii în moneda de referință',

    'Exchange rate': 'Curs valutar',
    "The selected date can't be greater then the current date!":
        'Data selectată nu poate fi mai mare decât data curentă!',

    'Invoice values after subtracting the value of the advance invoice':
        'Valorile facturii după scăderea valorii facturii de avans',
};

export default { ro };
