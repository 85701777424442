import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';

import { Button, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import { LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { configurator } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const OfferTagForm = ({ tagInfo, setInitializeData }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { offerTagId } = useParams();

    const [tagName, setTagName] = useState('');
    const [loading, setLoading] = useState(false);

    const offerTagSchema = yup.object().shape({
        tagName: yup
            .string()
            .trim()
            .typeError(t('Name of tag is required'))
            .required(t('Name of tag is required'))
            .min(2, t('Name of tag must have at least two characters')),
    });

    const createOfferTag = async () => {
        try {
            setLoading(true);
            setInitializeData?.((prev) => ({ ...prev, loading: true }));

            await offerTagSchema.validate({
                tagName,
            });

            try {
                await API.post('/offer_tags', {
                    name: tagName,
                });
                enqueueSnackbar(t('The tag was successfully saved!'), {
                    variant: 'success',
                });

                if (setInitializeData) setInitializeData((prev) => ({ ...prev, tab: 0 }));
                else history.push(configurator.base + `?tab=Offer tags`);
            } catch (err) {
                console.error(err);
                throw err;
            }
        } catch (err) {
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        } finally {
            setLoading(false);
            setInitializeData?.((prev) => ({ ...prev, loading: false }));
        }
    };

    useEffect(() => {
        if (offerTagId && tagInfo) {
            setTagName(tagInfo.name);
        }
    }, [offerTagId, tagInfo]);

    const updateOfferTag = async () => {
        try {
            setLoading(true);

            await offerTagSchema.validate({
                tagName,
            });

            try {
                await API.put('/offer_tags', {
                    id: tagInfo.id,
                    data: {
                        name: tagName,
                    },
                });
                enqueueSnackbar(t('The tag was successfully updated!'), {
                    variant: 'success',
                });
                history.push(configurator.base + `?tab=Offer tags`);
            } catch (err) {
                console.error(err);
                throw err;
            }
        } catch (err) {
            console.error(err.errors);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="mb-14 max-w-md">
                <LabelWrapper label={t('Tag name')}>
                    <TextField
                        placeholder={t('Tag name')}
                        value={tagName}
                        onChange={(e) => setTagName(e.target.value)}
                    />
                </LabelWrapper>
            </div>
            <Button
                color="secondary"
                startIcon={offerTagId ? <EditIcon /> : <CheckIcon />}
                onClick={() => {
                    offerTagId ? updateOfferTag() : createOfferTag();
                }}
            >
                {offerTagId ? t('Edit tag') : t('Save tag')}
            </Button>
        </>
    );
};

OfferTagForm.propTypes = {
    tagInfo: PropTypes.object,
    setInitializeData: PropTypes.func,
};

OfferTagForm.defaultProps = {
    tagInfo: {},
    setInitializeData: null,
};

export default OfferTagForm;
