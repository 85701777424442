import React, { useState } from 'react';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const PDFPreview = ({ file, pageNumber, setPageNumber }) => {
    const { t } = useTranslation();

    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    if (!file) return null;

    return (
        <div className="group relative mb-20">
            <div className="Example__container">
                <div className="Example__container__load"></div>
                <div className="Example__container__document">
                    <Document
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={options}
                        error={<div className="text-error">{t('Error on loading PDF')}</div>}
                        onLoadError={console.error}
                        loading={<div className="text-primary-main">{t('PDF is loading, please wait!')}</div>}
                        onSourceError={(error) => alert('Error while retrieving document source! ' + error.message)}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                </div>
            </div>
            {file && (
                <div
                    className="absolute flex -translate-y-20 select-none rounded-md bg-blue-50 opacity-0 transition-all duration-200 group-hover:translate-y-0 group-hover:opacity-100 group-hover:shadow-xl"
                    style={{
                        zIndex: 999,
                        bottom: '5%',
                        left: '41%',
                    }}
                >
                    <div
                        className={`rounded-md p-3 transition-all ${
                            pageNumber === 1 ? 'text-gray-200' : 'text-blue-300 hover:bg-blue-100 hover:text-blue-600'
                        }`}
                        onClick={() => {
                            if (pageNumber === 1) return;
                            else setPageNumber(pageNumber - 1);
                        }}
                    >
                        <ArrowBackIosIcon />
                    </div>
                    <div className="p-3 font-semibold">
                        {pageNumber} of {numPages}
                    </div>
                    <div
                        className={`rounded-md p-3 transition-all
                            ${
                                pageNumber === numPages
                                    ? 'text-gray-200'
                                    : 'text-blue-300 hover:bg-blue-100 hover:text-blue-600'
                            }`}
                        onClick={() => {
                            if (pageNumber === numPages) return;
                            else setPageNumber(pageNumber + 1);
                        }}
                    >
                        <ArrowForwardIosIcon />
                    </div>
                </div>
            )}
        </div>
    );
};

PDFPreview.propTypes = {
    file: PropTypes.object,
    pageNumber: PropTypes.number,
    setPageNumber: PropTypes.func,
};

PDFPreview.defaultProps = {
    file: null,
    pageNumber: 1,
    setPageNumber: () => {},
};

export default PDFPreview;
