import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button, TextField } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Dropdown, Header, LabelWrapper } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { configurator } from 'routes';
import API from 'utils/axios';

const CreateProjects = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { id: zoneId } = useParams();

    const [projectName, setProjectName] = useState('');
    const [observation, setObservation] = useState('');

    const [selectOption, setSelectOption] = useState(null);
    const [zones, setZones] = useState([]);
    const [zonesName, setZonesName] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '16',
            permissionType: 'ALL',
        },
    ]);

    const getZonesAndProjects = async () => {
        try {
            const response = await API.get('/areas', {
                params: {
                    currentPage: 0,
                    perPage: 9999,
                    pagesToLoad: 1,
                },
            });

            setZones(response.data.areas);
            setZonesName(response.data?.areas?.areas?.map((zone) => zone.name));
            let selectIndex = null;

            if (zoneId) {
                const res = response.data.areas;
                res.forEach((z, index) => {
                    if (z.id === zoneId) selectIndex = index;
                });
            }

            setSelectOption(selectIndex);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
        getZonesAndProjects();
    }, [canAll]);

    const createNewProject = useCallback(async () => {
        if (projectName.length < 3) {
            enqueueSnackbar(t('Project name must be at least 3 characters long!'), { variant: 'error' });
            return;
        }

        if (selectOption == null) {
            enqueueSnackbar(t('You must select a area for this project!'), { variant: 'error' });
            return;
        }

        try {
            await API.post('/projects', {
                name: projectName,
                description: observation,
                areaId: zones.areas[selectOption].id,
            });
            enqueueSnackbar(t('The project has been successfully added!'), { variant: 'success' });

            history.push(
                configurator.base +
                    configurator.zones.base +
                    configurator.zones.update +
                    '/' +
                    zones.areas[selectOption].id
            );
        } catch (err) {
            console.error(err);
            enqueueSnackbar(t('The project could not be added! Try again!'), { variant: 'error' });
        }
    }, [projectName, observation, selectOption]);

    return (
        <>
            <Helmet>
                <title>
                    {t('Areas & Projects')}| {t('Add new project')}
                </title>
            </Helmet>

            <Header pageTitle={t('Add new project')} />
            {canAll && (
                <div className="page-container">
                    <div className="w-96 sm:w-full">
                        <h4 className="mb-10 text-2xl text-gray-300">{t('Project details')}</h4>
                        <div className="relative z-50 mb-5">
                            <LabelWrapper label={t('Area')}>
                                <Dropdown
                                    placeholder={t('Choose area')}
                                    options={zonesName}
                                    selectedOption={selectOption}
                                    setSelectedOption={setSelectOption}
                                />
                            </LabelWrapper>
                        </div>
                        <div className="relative z-40 mb-5">
                            <TextField
                                name={'Nume proiect'}
                                label={t('Project name')}
                                placeholder={t('Add a name for the project')}
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                            />
                        </div>
                        <div className="relative z-20 mb-5">
                            <TextField
                                name="descriere"
                                label={t('Observations')}
                                placeholder={t('Add observations')}
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                                multiline
                                rows={3}
                            />
                        </div>

                        <div className="relative z-10 mt-28">
                            <Button size="large" color="primary" startIcon={<AddIcon />} onClick={createNewProject}>
                                {t('Create project')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateProjects;
