import React, { useContext, useState } from 'react';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { Button } from '@material-ui/core';

import PropTypes from 'prop-types';
import { ActionButton, Dropdown, MultiDropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

import { DocumentContext } from '../document-template';

const DocumentTemplateMilestonesListSelection = ({ onClose, element }) => {
    const { t } = useTranslation();
    const { milestones, handleChangeSelectedMilestones } = useContext(DocumentContext);

    const viewModes = ['Day', 'Week', 'Month'];
    const [selectedMilestones, setSelectedMilestones] = useState(() => {
        if (element.entity[0])
            return element.entity.map((entry) => milestones.findIndex((m) => m.id === entry.entityId));
        return [];
    });
    const [selectedViewMode, setSelectedViewMode] = useState(() => {
        if (element.entity[0]) return viewModes.findIndex((v) => v === element.entity[0].data.viewMode);
        return null;
    });

    const handleReorder = (start, end) =>
        setSelectedMilestones((prev) => {
            const newOrder = structuredClone(prev);
            [newOrder[start], newOrder[end]] = [newOrder[end], newOrder[start]];
            return newOrder;
        });

    return (
        <div
            className="rounded-t-x flex w-max flex-col gap-10 px-5 py-12"
            style={{
                maxWidth: '90vw',
            }}
        >
            <h2 className="text-center">{t('Select milestones and Gantt view mode')}</h2>
            <div className="mx-10 flex gap-6 sm:flex-wrap sm:px-5">
                <div className="w-full">
                    <MultiDropdown
                        options={milestones.map((m) => m.title)}
                        placeholder={t('Select milestone')}
                        selectedOptions={selectedMilestones}
                        setSelectedOptions={(i) => {
                            if (selectedMilestones.indexOf(i) > -1) {
                                setSelectedMilestones(selectedMilestones.filter((opt) => opt !== i));
                            } else {
                                setSelectedMilestones([...selectedMilestones, i]);
                            }
                        }}
                    />
                </div>

                <div className="w-full">
                    <Dropdown
                        options={viewModes.map((el) => t(el))}
                        selectedOption={selectedViewMode}
                        setSelectedOption={setSelectedViewMode}
                    />
                </div>
            </div>

            {selectedMilestones.length > 1 && (
                <div
                    className="mx-10 flex flex-col gap-4 overflow-y-auto rounded-md bg-layout-lighter p-4"
                    style={{ maxHeight: '60vh' }}
                >
                    <h4 className="text-center">{t('Milestones order')}</h4>

                    <div className="flex flex-col gap-2">
                        {selectedMilestones.map((selectedMilestone, index) => (
                            <div
                                key={selectedMilestone}
                                className="flex items-center justify-between gap-1 rounded-md bg-layout-transparent p-2"
                            >
                                <p className="text-lg">{milestones[selectedMilestone].title}</p>
                                <div className="flex gap-2">
                                    <ActionButton
                                        size={9}
                                        icon={<ArrowUpwardIcon />}
                                        disabled={index === 0}
                                        onClick={() => handleReorder(index, index - 1)}
                                    />
                                    <ActionButton
                                        size={9}
                                        icon={<ArrowDownwardIcon />}
                                        disabled={index === selectedMilestones.length - 1}
                                        onClick={() => handleReorder(index, index + 1)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="flex justify-between px-5">
                <Button onClick={onClose}>{t('Cancel')}</Button>
                <Button
                    color="secondary"
                    onClick={() => {
                        onClose();
                        handleChangeSelectedMilestones(
                            element.pairKey,
                            selectedMilestones,
                            viewModes[selectedViewMode]
                        );
                    }}
                    disabled={selectedMilestones.length === 0 || selectedViewMode === null}
                >
                    {t('Complete')}
                </Button>
            </div>
        </div>
    );
};

DocumentTemplateMilestonesListSelection.propTypes = {
    onClose: PropTypes.func,
    element: PropTypes.object,
};

DocumentTemplateMilestonesListSelection.defaultProps = {
    onClose: () => {},
    element: {},
};

export default DocumentTemplateMilestonesListSelection;
