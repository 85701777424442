import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';

import { Button, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import { LabelWrapper } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { quotes } from 'routes';
import { errorHandling, formatPositiveNumberWithDigits, formatVATnumber } from 'utils';
import API from 'utils/axios';
import { getCompanyVAT } from 'utils/getterFunctions';
import * as yup from 'yup';

const VariationsForm = ({ variationInfo }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const { articleId, variationId } = useParams();

    const [variation, setVariation] = useState({ articleId, name: null, unit: null, pricePerUnit: null });

    const editVariation = (key, value) => {
        const newVariation = { ...variation };
        newVariation[key] = value;
        setVariation(newVariation);
    };

    const variationSchema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .typeError(t('Name of variation is required'))
            .min(2, t('Name of variation must have at least two characters'))
            .required(t('Name of variation is required')),
        // unit: yup.string().trim().typeError().nullable(),
        // pricePerUnit: yup.number().typeError().nullable(),
        // procentualVAT: yup.number().typeError().nullable(),
    });

    const createVariation = async () => {
        try {
            await variationSchema.validate({
                name: variation.name,
                // unit: variation.unit,
                // pricePerUnit: variation.pricePerUnit,
                // procentualVAT: variation.procentualVAT,
            });

            if (variation.pricePerUnit === 0) {
                enqueueSnackbar(t('The price per unit of the variation must be greater than zero'), {
                    variant: 'error',
                });
                return;
            }

            try {
                await API.post('/articleVariation', {
                    ...variation,
                    unit: variation.unit !== null && variation.unit !== '' ? variation.unit : '-',
                    pricePerUnit:
                        variation.pricePerUnit !== null && variation.pricePerUnit !== '' ? variation.pricePerUnit : 0,
                    procentualVAT:
                        variation.procentualVAT !== null &&
                        variation.procentualVAT !== '' &&
                        variation.pricePerUnit !== null &&
                        variation.pricePerUnit !== ''
                            ? variation.procentualVAT
                            : 0,
                });
                enqueueSnackbar(t('The variation was successfully saved!'), {
                    variant: 'success',
                });
                history.push(quotes.base + quotes.variations.base + '/' + articleId);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.error(err.errors);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    const updateVariation = async () => {
        try {
            await variationSchema.validate({
                name: variation.name,
                // unit: variation.unit,
                // pricePerUnit: variation.pricePerUnit,
                // procentualVAT: variation.procentualVAT,
            });

            if (variation.pricePerUnit === 0) {
                enqueueSnackbar(t('The price per unit of the variation must be greater than zero'), {
                    variant: 'error',
                });
                return;
            }

            try {
                await API.put('/articleVariation', {
                    id: variation.id,
                    data: {
                        name: variation.name,
                        articleId: articleId,
                        price: {
                            priceId: variation.priceId,
                            unit: variation.unit !== null && variation.unit !== '' ? variation.unit : '-',
                            pricePerUnit:
                                variation.pricePerUnit !== null && variation.pricePerUnit !== ''
                                    ? variation.pricePerUnit
                                    : 0,
                            procentualVAT:
                                variation.procentualVAT !== null &&
                                variation.procentualVAT !== '' &&
                                variation.pricePerUnit !== null &&
                                variation.pricePerUnit !== ''
                                    ? variation.procentualVAT
                                    : 0,
                        },
                    },
                });
                enqueueSnackbar(t('The variation was successfully updated!'), {
                    variant: 'success',
                });
                history.push(quotes.base + quotes.variations.base + '/' + articleId);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.error(err.errors);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    // We format variation object on edit, to be similar with initial value of the variation object.
    useEffect(() => {
        if (!variationId && Object.keys(variationInfo).length === 0)
            (async () => {
                const value = await getCompanyVAT();
                const newVariation = {
                    ...variation,
                    procentualVAT: value,
                };

                setVariation(newVariation);
            })();

        if (variationInfo && variationId) {
            const newVariation = {
                id: variationInfo.id,
                name: variationInfo.name,
                priceId: variationInfo.priceId,
                pricePerUnit:
                    variationInfo.price?.pricePerUnit === null ||
                    variationInfo.price?.pricePerUnit === 0 ||
                    variationInfo.price?.pricePerUnit === ''
                        ? null
                        : variationInfo.price?.pricePerUnit,
                unit:
                    variationInfo.price?.unit === '-' || variationInfo.price?.unit === null
                        ? null
                        : variationInfo.price?.unit,
                procentualVAT:
                    variationInfo.price?.procentualVAT === null || variationInfo.price?.procentualVAT === 0
                        ? null
                        : variationInfo.price?.procentualVAT,
            };

            setVariation(newVariation);
        }
    }, [variationId, variationInfo]);

    return (
        variation !== null && (
            <>
                <div className="mb-20 space-y-3">
                    <div className="group relative flex max-w-4xl items-center gap-2 md:flex-col">
                        <LabelWrapper label={t('Variation name')}>
                            <TextField
                                placeholder={t('Variation name')}
                                value={variation.name}
                                onChange={(e) => editVariation('name', e.target.value)}
                            />
                        </LabelWrapper>
                        <div className="w-1/3 md:w-full">
                            <LabelWrapper label={t('Unit price')}>
                                <LocaleTextField
                                    placeholder={t('Unit price')}
                                    value={variation.pricePerUnit}
                                    onChange={(e) =>
                                        editVariation('pricePerUnit', formatPositiveNumberWithDigits(e.target.value))
                                    }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">Lei</InputAdornment>,
                                    }}
                                />
                            </LabelWrapper>
                        </div>
                        <div className="w-1/3 md:w-full">
                            <LabelWrapper label={t('VAT rate')}>
                                <LocaleTextField
                                    placeholder={t('VAT rate')}
                                    value={
                                        variation.pricePerUnit !== null &&
                                        variation.pricePerUnit !== '' &&
                                        variation.pricePerUnit !== 0
                                            ? variation.procentualVAT
                                            : 0
                                    }
                                    onChange={(e) => editVariation('procentualVAT', formatVATnumber(e.target.value))}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    disabled={
                                        variation.pricePerUnit !== null && variation.pricePerUnit !== '' ? false : true
                                    }
                                />
                            </LabelWrapper>
                        </div>
                        <div className="w-1/3 md:w-full">
                            <LabelWrapper label={'UM'}>
                                <TextField
                                    placeholder={'UM'}
                                    value={variation.unit}
                                    onChange={(e) => editVariation('unit', e.target.value)}
                                />
                            </LabelWrapper>
                        </div>
                    </div>
                </div>

                {variation.name !== null && variation.name !== '' ? (
                    <Button
                        color="secondary"
                        startIcon={variationId ? <EditIcon /> : <CheckIcon />}
                        onClick={() => {
                            if (variationId) {
                                updateVariation();
                            } else {
                                createVariation();
                            }
                        }}
                    >
                        {variationId ? t('Edit variation') : t('Save variation')}
                    </Button>
                ) : null}
            </>
        )
    );
};

VariationsForm.propTypes = {
    variationInfo: PropTypes.object,
};

VariationsForm.defaultProps = {
    variationInfo: {},
};

export default VariationsForm;
