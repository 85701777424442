import React from 'react';

import { useTranslation } from 'react-i18next';
import versions from 'version';

const Versions = () => {
    const { t } = useTranslation();

    return (
        <div className="page-container max-w-7xl">
            {versions.map((version) => (
                <section key={version.v} className="mb-12">
                    <h2 className="mb-4">
                        {t('Version')}:<span className="ml-2 font-semibold italic">{version.version} </span>
                    </h2>

                    {version.description !== '' && <p className="mb-3  text-lg">{t(`${version.description}`)}</p>}

                    {version.features.length > 0 && (
                        <div className="mb-4 ml-8">
                            <h3 className="mb-2 font-semibold">{t('New features')}:</h3>
                            <div className="ml-6">
                                {version.features.map((feature) => (
                                    <p key={feature} className="flex items-center">
                                        <i className="fa-solid fa-circle-plus mr-2 text-sm text-success"></i>
                                        <span>{feature}</span>
                                    </p>
                                ))}
                            </div>
                        </div>
                    )}

                    {version.bugsFixed.length > 0 && (
                        <div className={`${version.features.length ? 'ml-20' : 'ml-8'}`}>
                            <h3 className="mb-2 font-semibold">{t('Bugs fixed')}:</h3>

                            <div className="ml-10">
                                {version.bugsFixed.map((item) => (
                                    <p key={item} className="flex items-center">
                                        <i className="fa-solid fa-bug mr-2 text-sm text-error"></i> <span>{item}</span>
                                    </p>
                                ))}
                            </div>
                        </div>
                    )}
                </section>
            ))}
        </div>
    );
};

export default Versions;
