import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import InputAdornment from '@material-ui/core/InputAdornment';

import GlobalContext from 'contexts/GlobalContext';
import useScreenSizes from 'hooks/useScreenSizes';
import PropTypes from 'prop-types';
import { MultiDropdown } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { formatPositiveNumberWithDigits, toLocaleNumber } from 'utils';

const BudgetaryContext = createContext();

const GrandparentRevenueRow = ({ revenue, revenueIndex }) => {
    const { accounts, isEdit, checkedRevenues, isDisabled } = useContext(BudgetaryContext);
    const currentCheckedRevenue = checkedRevenues[revenueIndex];

    const [open, setOpen] = useState(currentCheckedRevenue.grandParentId === revenue.id ? true : false);

    const accountingAccount = accounts[accounts.findIndex((account) => account.id === revenue.accountingAccountId)];

    const parentList = isEdit
        ? revenue.subRevenueNames
        : revenue.subRevenueNames.filter((subRev) => subRev.isDeleted === false);

    return (
        <div className="budgetary-border-selector relative">
            <div
                className={`flex w-full items-center gap-4 rounded-t-md p-4 sm:gap-2 sm:p-2`}
                style={{
                    backgroundColor: revenue.isDeleted ? 'rgb(var(--base-error) / 40%)' : 'var(--layout-transparent)',
                }}
            >
                <div className="flex w-full items-center gap-4 p-4  sm:gap-2 sm:p-2">
                    {/* Grandparent revenue name */}
                    <div className="flex-grow">
                        <p className="break-all text-2xl font-extrabold text-main-text">{revenue.name}</p>
                        <p className="text-sm">
                            <span className="text-primary-light">{accountingAccount.code}</span> -{' '}
                            <span className="text-dark-text">{accountingAccount.name}</span>
                        </p>
                    </div>
                </div>

                <div className="w-13 ml-8 sm:ml-2">
                    <div
                        className={`group flex cursor-pointer items-center justify-center rounded-full border border-success-light p-1 transition-all duration-150 hover:border-success ${
                            open ? 'rotate-180 transform' : ''
                        }`}
                        onClick={() => setOpen(!open)}
                    >
                        <ExpandMoreIcon
                            style={{ fontSize: '15px' }}
                            className={`text-success-light transition-all duration-150 group-hover:text-success`}
                        />
                    </div>
                </div>
            </div>

            {/* Map of the parent revenues */}
            <div>
                {parentList.map((parentRevenue) => (
                    <ParentRevenueRow
                        key={parentRevenue.id}
                        parentRevenue={parentRevenue}
                        revenue={revenue}
                        open={open}
                        isDisabled={isDisabled}
                        revenueIndex={revenueIndex}
                    />
                ))}
            </div>
        </div>
    );
};

GrandparentRevenueRow.propTypes = {
    revenue: PropTypes.object,
    revenueIndex: PropTypes.number,
};

const ParentRevenueRow = ({ parentRevenue, open, isDisabled, revenue, revenueIndex }) => {
    const {
        accounts,
        checkedRevenues,
        setCheckedRevenues,
        currencyObj,
        isEdit,
        disableInputs,
        referenceCurrencyObj,
        exchangeRate,
        isMultiCurrency,
        isEstimateCase,
    } = useContext(BudgetaryContext);

    const [width] = useScreenSizes();

    const currentCheckedRevenue = checkedRevenues[revenueIndex];

    const memoizedCheckedRevenuesList = useMemo(() => currentCheckedRevenue.list, [currentCheckedRevenue.list]);

    const [elementHeight, setElementHeight] = useState(0);

    const elementId = `${revenue.id}-${parentRevenue.id}`;

    const accountingAccount =
        accounts[accounts.findIndex((account) => account.id === parentRevenue.accountingAccountId)];

    const isChecked = memoizedCheckedRevenuesList.findIndex((r) => r.id === parentRevenue.id) >= 0;
    const canDisplayCheck = disableInputs
        ? false
        : !isEstimateCase && isChecked && parentRevenue.isDeleted
        ? true
        : parentRevenue.isDeleted
        ? false
        : isEstimateCase
        ? false
        : true;

    const handleCheckRevenue = () => {
        if (isChecked) {
            setCheckedRevenues((prev) =>
                prev.map((e, index) =>
                    index === revenueIndex ? { ...e, list: e.list.filter((r) => r.id !== parentRevenue.id) } : e
                )
            );
        } else {
            setCheckedRevenues((prev) =>
                prev.map((e, index) =>
                    index === revenueIndex
                        ? {
                              ...e,
                              list: [
                                  ...e.list,
                                  {
                                      id: parentRevenue.id,
                                      value: 0,
                                      secondCurrencyValue: isMultiCurrency ? 0 : undefined,
                                  },
                              ],
                          }
                        : e
                )
            );
        }
    };

    const handleUpdateRevenueValue = (value) => {
        setCheckedRevenues((prev) =>
            prev.map((e, index) =>
                index === revenueIndex
                    ? {
                          ...e,
                          list: e.list.map((r) =>
                              r.id === parentRevenue.id
                                  ? {
                                        ...r,
                                        value,
                                        secondCurrencyValue: isMultiCurrency ? value / exchangeRate : undefined,
                                    }
                                  : r
                          ),
                      }
                    : e
            )
        );
    };

    const childList = isEdit
        ? parentRevenue.subRevenueNames
        : parentRevenue.subRevenueNames.filter((subRev) => subRev.isDeleted === false);

    const handleResize = useCallback(
        (node) => {
            if (!node) return;

            const elementData = node.scrollHeight;
            setElementHeight(elementData);
        },
        [width, memoizedCheckedRevenuesList]
    );

    return (
        <div
            className={`budgetary-parent-selector relative transition-all duration-200 ease-in-out ${
                open ? `visible border-t border-secondary-main` : 'invisible max-h-0'
            } ${open && !isDisabled ? 'opacity-100' : 'opacity-0'} ${open && isDisabled ? 'opacity-75' : 'opacity-0'} `}
            style={{
                maxHeight: open ? `${elementHeight}px` : '',
            }}
            id={elementId}
            ref={handleResize}
        >
            <div
                className={`flex w-full items-center gap-4 p-4 sm:gap-2 sm:p-2`}
                style={{
                    backgroundColor:
                        isChecked && parentRevenue.isDeleted
                            ? 'color-mix(in srgb, rgb(var(--base-secondary-light) / 20%), rgb(var(--base-error) / 40%))'
                            : isChecked
                            ? 'rgb(var(--base-secondary-light) / 20%)'
                            : parentRevenue.isDeleted
                            ? 'rgb(var(--base-error) / 40%)'
                            : '',
                }}
            >
                {/* Parent expense name */}
                <div className="ml-6 flex-grow">
                    <p className="break-all text-xl font-bold text-main-text">{parentRevenue.name}</p>
                    <p className="text-sm">
                        <span className="text-primary-light">{accountingAccount.code}</span> -{' '}
                        <span className="text-dark-text">{accountingAccount.name}</span>
                    </p>
                </div>
                {isChecked && (
                    <div className="flex flex-col gap-2" style={{ minWidth: '10rem' }}>
                        <LocaleTextField
                            disabled={disableInputs}
                            placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                            value={memoizedCheckedRevenuesList.find((r) => r.id === parentRevenue.id)?.value}
                            onChange={(e) => {
                                const value = formatPositiveNumberWithDigits(e.target.value);
                                handleUpdateRevenueValue(value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                            }}
                        />

                        {isMultiCurrency && (
                            <LocaleTextField
                                placeholder={`${toLocaleNumber(100, 2)} ${referenceCurrencyObj.currency}`}
                                value={
                                    memoizedCheckedRevenuesList.find((r) => r.id === parentRevenue.id)
                                        ?.secondCurrencyValue
                                }
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {referenceCurrencyObj.currency}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </div>
                )}
                {canDisplayCheck && (
                    <div className="w-13 ml-8 sm:ml-2">
                        <div
                            className={`group flex cursor-pointer items-center justify-center rounded-full border border-secondary-lighter p-1 transition-all duration-150 hover:border-secondary-main`}
                            onClick={handleCheckRevenue}
                        >
                            <CheckCircleIcon
                                style={{ fontSize: '15px' }}
                                className="text-secondary-lighter transition-all duration-150 group-hover:text-secondary-main"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Map of the children expenses */}
            <div className="flex flex-col">
                {childList.map((childRevenue) => (
                    <ChildRevenueRow key={childRevenue.id} childRevenue={childRevenue} revenueIndex={revenueIndex} />
                ))}
            </div>
        </div>
    );
};

ParentRevenueRow.propTypes = {
    parentRevenue: PropTypes.object,
    revenue: PropTypes.object,
    open: PropTypes.bool,
    isDisabled: PropTypes.bool,
    revenueIndex: PropTypes.number,
};

const ChildRevenueRow = ({ childRevenue, revenueIndex }) => {
    const {
        accounts,
        checkedRevenues,
        setCheckedRevenues,
        currencyObj,
        disableInputs,
        referenceCurrencyObj,
        exchangeRate,
        isMultiCurrency,
        isEstimateCase,
    } = useContext(BudgetaryContext);

    const currentCheckedRevenue = checkedRevenues[revenueIndex];

    const accountingAccount =
        accounts[accounts.findIndex((account) => account.id === childRevenue.accountingAccountId)];

    const isChecked = currentCheckedRevenue.list.findIndex((r) => r.id === childRevenue.id) >= 0;
    const canDisplayCheck = disableInputs
        ? false
        : !isEstimateCase && isChecked && childRevenue.isDeleted
        ? true
        : childRevenue.isDeleted
        ? false
        : isEstimateCase
        ? false
        : true;

    const handleCheckRevenue = () => {
        if (isChecked) {
            setCheckedRevenues((prev) =>
                prev.map((e, index) =>
                    index === revenueIndex ? { ...e, list: e.list.filter((r) => r.id !== childRevenue.id) } : e
                )
            );
        } else {
            setCheckedRevenues((prev) =>
                prev.map((e, index) =>
                    index === revenueIndex
                        ? {
                              ...e,
                              list: [
                                  ...e.list,
                                  {
                                      id: childRevenue.id,
                                      value: 0,
                                      secondCurrencyValue: isMultiCurrency ? 0 : undefined,
                                  },
                              ],
                          }
                        : e
                )
            );
        }
    };

    const handleUpdateRevenueValue = (value) => {
        setCheckedRevenues((prev) =>
            prev.map((e, index) =>
                index === revenueIndex
                    ? {
                          ...e,
                          list: e.list.map((r) =>
                              r.id === childRevenue.id
                                  ? {
                                        ...r,
                                        value,
                                        secondCurrencyValue: isMultiCurrency ? value / exchangeRate : undefined,
                                    }
                                  : r
                          ),
                      }
                    : e
            )
        );
    };

    return (
        <div
            className={`budgetary-child-selector relative border-t border-secondary-main`}
            style={{
                backgroundColor:
                    isChecked && childRevenue.isDeleted
                        ? 'color-mix(in srgb, rgb(var(--base-secondary-light) / 20%), rgb(var(--base-error) / 40%))'
                        : isChecked
                        ? 'rgb(var(--base-secondary-light) / 20%)'
                        : childRevenue.isDeleted
                        ? 'rgb(var(--base-error) / 40%)'
                        : '',
            }}
        >
            <div className="flex w-full items-center gap-4 p-4 sm:gap-2 sm:p-2">
                <div className="ml-12 flex-grow">
                    <p className="break-all text-lg text-main-text">{childRevenue.name}</p>
                    <p className="text-sm">
                        <span className="text-primary-light">{accountingAccount.code}</span> -{' '}
                        <span className="text-dark-text">{accountingAccount.name}</span>
                    </p>
                </div>
                {isChecked && (
                    <div className="flex flex-col gap-2" style={{ minWidth: '10rem' }}>
                        <LocaleTextField
                            disabled={disableInputs}
                            placeholder={`${toLocaleNumber(100, 2)} ${currencyObj.currency}`}
                            value={currentCheckedRevenue.list.find((r) => r.id === childRevenue.id)?.value}
                            onChange={(e) => {
                                const value = formatPositiveNumberWithDigits(e.target.value);

                                handleUpdateRevenueValue(value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                            }}
                        />

                        {isMultiCurrency && (
                            <LocaleTextField
                                placeholder={`${toLocaleNumber(100, 2)} ${referenceCurrencyObj.currency}`}
                                value={
                                    currentCheckedRevenue.list.find((r) => r.id === childRevenue.id)
                                        ?.secondCurrencyValue
                                }
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {referenceCurrencyObj.currency}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </div>
                )}
                {canDisplayCheck && (
                    <div className="w-13 ml-8 sm:ml-2">
                        <div
                            className={`group flex cursor-pointer items-center justify-center rounded-full border border-secondary-lighter p-1 transition-all duration-150 hover:border-secondary-main `}
                            onClick={handleCheckRevenue}
                        >
                            <CheckCircleIcon
                                style={{ fontSize: '15px' }}
                                className="text-secondary-lighter transition-all duration-150 group-hover:text-secondary-main"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

ChildRevenueRow.propTypes = {
    childRevenue: PropTypes.object,
    revenueIndex: PropTypes.number,
};

const UtilityRevenueBudget = (props) => {
    const { t } = useTranslation();

    const {
        checkedRevenues,
        setCheckedRevenues,
        isEdit,
        disableInputs,
        exchangeRate,
        isDisabled,
        isEstimateCase,
        allRevenues,
        accounts,
        style,
    } = props;

    const isMultiCurrency = exchangeRate !== null;

    const { currencyObj, referenceCurrencyObj } = useContext(GlobalContext);

    return (
        <BudgetaryContext.Provider
            value={{
                accounts,
                checkedRevenues,
                setCheckedRevenues,
                isEdit,
                currencyObj,
                disableInputs,
                isDisabled,
                isMultiCurrency,
                exchangeRate,
                referenceCurrencyObj,
                isEstimateCase,
            }}
        >
            <div className="mb-10 flex flex-col items-start" style={style}>
                {!isEstimateCase &&
                    (() => {
                        const options = isEdit
                            ? allRevenues
                            : allRevenues.filter((revenue) => revenue.isDeleted === false);

                        return (
                            <div className="relative mb-6 mt-2 inline-block">
                                <MultiDropdown
                                    disabled={disableInputs}
                                    variant="green"
                                    icon={
                                        checkedRevenues.length > 0 ? (
                                            <span>
                                                <EditIcon className="text-buttons-text" /> {t('Change revenues')}
                                            </span>
                                        ) : (
                                            <span>
                                                <AddIcon className="text-buttons-text" /> {t('Add revenues')}
                                            </span>
                                        )
                                    }
                                    placeholder={checkedRevenues.length > 0 ? t('Change revenues') : t('Add revenues')}
                                    options={options.map((name) => name.name)}
                                    selectedOptions={checkedRevenues.map((r) => r.selectionIndex)}
                                    setSelectedOptions={(i) => {
                                        if (checkedRevenues.map((r) => r.selectionIndex).indexOf(i) >= 0) {
                                            setCheckedRevenues((prev) => prev.filter((p) => p.selectionIndex !== i));
                                        } else {
                                            setCheckedRevenues((prev) => [
                                                ...prev,
                                                { selectionIndex: i, grandParentId: allRevenues[i].id, list: [] },
                                            ]);
                                        }
                                    }}
                                />
                            </div>
                        );
                    })()}
                {checkedRevenues.length > 0 && (
                    <div
                        className="grid w-full items-start gap-6"
                        style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(26rem, max-content))' }}
                    >
                        {checkedRevenues.map((r, index) => (
                            <div className={`w-full rounded-md border border-secondary-light`} key={r.id}>
                                <GrandparentRevenueRow revenue={allRevenues[r.selectionIndex]} revenueIndex={index} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </BudgetaryContext.Provider>
    );
};

UtilityRevenueBudget.propTypes = {
    checkedRevenues: PropTypes.array,
    setCheckedRevenues: PropTypes.func,
    isEdit: PropTypes.bool,
    viewOnly: PropTypes.bool,
    isEstimateCase: PropTypes.bool,
    disableInputs: PropTypes.bool,
    isDisabled: PropTypes.bool,
    exchangeRate: PropTypes.number,
    allRevenues: PropTypes.array,
    accounts: PropTypes.array,
    style: PropTypes.object,
};

UtilityRevenueBudget.defaultProps = {
    checkedRevenues: [],
    setCheckedRevenues: () => null,
    isEdit: false,
    viewOnly: false,
    disableInputs: false,
    isEstimateCase: false,
    isDisabled: false,
    exchangeRate: null,
    allRevenues: [],
    accounts: [],
    style: {},
};

export default UtilityRevenueBudget;
