import React from 'react';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import EmployeeForm from './employee-form';

export default function AddEmployeePage({ moduleType }) {
    const { t } = useTranslation();
    const location = useLocation();

    const employeeType = location.state?.employeeType ?? 'internal';

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('Create employee')}</title>
            </Helmet>

            <Header pageTitle={t('Create employee')} />

            <div className="page-container">
                <EmployeeForm moduleType={moduleType} employeeType={employeeType} />
            </div>
        </React.Fragment>
    );
}

AddEmployeePage.propTypes = {
    moduleType: PropTypes.oneOf(['pm', 'internalActivity']).isRequired,
};
