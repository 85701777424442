import React from 'react';

import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';

const BusinessIntelligence = () => {
    return (
        <>
            <Helmet>
                <title>Business Intelligence</title>
            </Helmet>

            <Header pageTitle="Business Intelligence" />

            <div className="page-container relative">
                <h3>Feature in development!</h3>
            </div>
        </>
    );
};

export default BusinessIntelligence;
