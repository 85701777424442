import React, { memo, useMemo } from 'react';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateUtils from '@date-io/dayjs';
import PropTypes from 'prop-types';

const AppWrapper = ({ children, tenantTheme }) => {
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: `rgb(${tenantTheme['UI-COLORS']['MAIN-PRIMARY'].color})`,
                        light: `rgb(${tenantTheme['UI-COLORS']['LIGHT-PRIMARY'].color})`,
                    },
                    secondary: {
                        main: `rgb(${tenantTheme['UI-COLORS']['MAIN-SECONDARY'].color})`,
                        light: `rgb(${tenantTheme['UI-COLORS']['LIGHT-SECONDARY'].color})`,
                    },
                    text: {
                        primary: `rgb(${tenantTheme['TEXT-COLORS']['MAIN-TEXT'].color})`,
                        secondary: `rgb(${tenantTheme['TEXT-COLORS']['SECONDARY-TEXT'].color})`,
                    },
                },
                typography: {
                    fontFamily: [
                        'Sarabun',
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(', '),
                },
                props: {
                    MuiInputLabel: {
                        shrink: true,
                    },
                    MuiInput: {
                        disableUnderline: true,
                        autoComplete: 'off',
                    },
                    MuiButton: {
                        disableElevation: true,
                        variant: 'contained',
                    },
                },
                overrides: {
                    MuiButton: {
                        root: {
                            textTransform: 'none',
                            fontSize: '1rem',
                            fontWeight: 500,
                            whiteSpace: 'nowrap',
                            padding: '.5rem 1.25rem',
                            borderRadius: '.5rem',
                            transitionProperty: 'background-color, box-shadow, border, color',
                        },
                        iconSizeSmall: {
                            '& > *:first-child': {
                                width: '18px',
                                height: '18px',
                            },
                        },
                        iconSizeMedium: {
                            '& > *:first-child': {
                                width: '20px',
                                height: '20px',
                            },
                        },
                        iconSizeLarge: {
                            '& > *:first-child': {
                                width: '22px',
                                height: '22px',
                            },
                        },
                        text: {
                            textTransform: 'none',
                            fontSize: '1rem',
                            fontWeight: 500,
                            whiteSpace: 'nowrap',
                            padding: '.5rem 1.25rem',
                        },
                        contained: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['BUTTONS-TEXT'].color})`,
                            backgroundColor: `rgb(${tenantTheme['UI-COLORS']['LIGHT-PRIMARY'].color} / 50%)`,
                            '&:hover': {
                                backgroundColor: `rgb(${tenantTheme['UI-COLORS']['LIGHT-PRIMARY'].color} / 75%)`,
                            },

                            '&$disabled': {
                                color: `rgb(${tenantTheme['TEXT-COLORS']['DARK-TEXT'].color})`,
                                backgroundColor: `rgb(${tenantTheme['STATE-COLORS']['DISABLED'].color})`,
                            },
                        },
                        containedPrimary: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['BUTTONS-TEXT'].color})`,
                            '&:hover': {
                                backgroundColor: `rgb(${tenantTheme['UI-COLORS']['LIGHT-PRIMARY'].color})`,
                            },
                        },
                        containedSecondary: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['BUTTONS-TEXT'].color})`,
                            '&:hover': {
                                backgroundColor: `rgb(${tenantTheme['UI-COLORS']['LIGHT-SECONDARY'].color})`,
                            },
                        },
                        outlined: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['BUTTONS-TEXT'].color})`,
                            borderColor: `rgb(${tenantTheme['UI-COLORS']['LIGHT-PRIMARY'].color} / 50%)`,
                            '&:hover': {
                                borderColor: `rgb(${tenantTheme['UI-COLORS']['LIGHT-PRIMARY'].color} / 75%)`,
                            },

                            '&$disabled': {
                                color: `rgb(${tenantTheme['TEXT-COLORS']['DARK-TEXT'].color})`,
                                borderColor: `rgb(${tenantTheme['STATE-COLORS']['DISABLED'].color})`,
                            },
                        },
                        outlinedPrimary: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['BUTTONS-TEXT'].color})`,
                            '&:hover': {
                                borderColor: `rgb(${tenantTheme['UI-COLORS']['LIGHT-PRIMARY'].color})`,
                            },
                        },
                        outlinedSecondary: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['BUTTONS-TEXT'].color})`,
                            '&:hover': {
                                borderColor: `rgb(${tenantTheme['UI-COLORS']['LIGHT-SECONDARY'].color})`,
                            },
                        },
                    },

                    MuiLink: {
                        root: {
                            transition: 'color .2s ease',
                            '&.MuiTypography-colorPrimary': {
                                '&:hover': {
                                    color: `rgb(${tenantTheme['UI-COLORS']['LIGHT-PRIMARY'].color})`,
                                },
                            },
                            '&.MuiTypography-colorSecondary': {
                                '&:hover': {
                                    color: `rgb(${tenantTheme['UI-COLORS']['LIGHT-SECONDARY'].color})`,
                                },
                            },
                        },
                        underlineHover: {
                            textDecoration: 'underline',
                        },
                    },

                    MuiTextField: {
                        root: {
                            width: '100%',
                            position: 'relative',
                            display: 'inline-flex',
                            flexDirection: 'column',
                        },
                    },

                    MuiInputLabel: {
                        root: {
                            fontSize: '10px',
                            height: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            color: `rgb(${tenantTheme['TEXT-COLORS']['DARK-TEXT'].color})`,
                            paddingLeft: 'calc(1rem + 1px)',
                            marginBottom: '5px',
                            whiteSpace: 'nowrap',

                            '&$disabled': {
                                color: `rgb(${tenantTheme['STATE-COLORS']['DISABLED'].color})`,
                            },

                            '&$error': {
                                color: `rgb(${tenantTheme['STATE-COLORS']['ERROR'].color})`,
                            },
                        },
                        formControl: {
                            position: 'relative',
                        },
                        shrink: {
                            transform: 'translate(0, 0) scale(1)',
                        },
                    },

                    MuiInputBase: {
                        root: {
                            width: '100%',
                            border: `1px solid rgb(${tenantTheme['LAYOUT-COLORS']['TRANSPARENT-LAYOUT'].color} / 20%)`,
                            borderRadius: '5px',
                            padding: 0,
                            transition: 'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                            '&$focused, &:hover': {
                                borderColor: `rgb(${tenantTheme['UI-COLORS']['MAIN-PRIMARY'].color})`,
                            },
                        },
                        input: {
                            fontSize: '1rem',
                            lineHeight: 1.2,
                            padding: '0 1rem',
                            '&::placeholder': {
                                fontWeight: 300,
                                fontStyle: 'italic',
                                opacity: 1,
                            },
                            '&$disabled': {
                                color: `rgb(${tenantTheme['STATE-COLORS']['DISABLED'].color})`,
                            },
                        },
                        inputMultiline: {
                            padding: '0 1rem',
                            minHeight: 'calc(5rem - 2px)',
                        },
                        formControl: {
                            margin: '0 !important',
                        },
                    },

                    MuiInput: {
                        input: {
                            height: 'calc(2.75rem - 2px)',
                        },
                    },

                    MuiFormHelperText: {
                        root: {
                            position: 'relative',
                            marginTop: 0,
                            paddingLeft: '1rem',

                            '&$error': {
                                color: `rgb(${tenantTheme['STATE-COLORS']['ERROR'].color})`,
                            },
                        },
                    },

                    MuiTableCell: {
                        root: {
                            padding: '.65rem 1rem',
                            fontSize: '1rem',

                            '&:first-of-type': {
                                borderTopLeftRadius: '5px',
                                borderBottomLeftRadius: '5px',
                            },
                            '&:last-of-type': {
                                borderTopRightRadius: '5px',
                                borderBottomRightRadius: '5px',
                            },
                        },

                        head: {
                            backgroundColor: `rgb(${tenantTheme['LAYOUT-COLORS']['LIGHT-LAYOUT'].color})`,
                            border: 'none',
                            padding: '1.25rem 1rem',
                            fontWeight: '700',
                        },

                        body: {
                            border: 'none',
                        },
                    },

                    MuiTableRow: {
                        root: {
                            borderTop: `1px solid rgb(${tenantTheme['LAYOUT-COLORS']['LIGHT-LAYOUT'].color})`,
                            cursor: 'pointer',

                            '&:hover': {
                                backgroundColor: `rgb(${tenantTheme['LAYOUT-COLORS']['LIGHT-LAYOUT'].color})`,
                                transition: 'background-color .2s ease',
                            },

                            '&:nth-of-type(1), &:nth-of-type(2)': {
                                border: 'none',
                            },
                        },

                        head: {
                            border: 'none',
                        },
                    },

                    MuiCheckbox: {
                        root: {
                            color: `rgb(${tenantTheme['UI-COLORS']['MAIN-PRIMARY'].color})`,
                        },
                    },

                    MuiPickersBasePicker: {
                        container: {
                            borderRadius: '4px',
                            overflow: 'hidden',
                            backgroundColor: 'var(--main-text)',
                            boxShadow:
                                '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
                        },
                    },

                    MuiPickersDay: {
                        daySelected: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['BUTTONS-TEXT'].color})`,
                        },
                        day: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['DARK-TEXT'].color})`,
                        },
                    },

                    MuiPickersToolbarText: {
                        toolbarTxt: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['BUTTONS-TEXT'].color} / 75%)`,
                        },
                        toolbarBtnSelected: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['BUTTONS-TEXT'].color})`,
                        },
                    },

                    MuiPickersYear: {
                        root: {
                            color: `rgb(${tenantTheme['LAYOUT-COLORS']['LIGHT-LAYOUT'].color})`,
                        },

                        yearSelected: {
                            color: `rgb(${tenantTheme['UI-COLORS']['MAIN-PRIMARY'].color})`,
                        },
                    },

                    MuiPickersCalendarHeader: {
                        switchHeader: {
                            '& .MuiTypography-root': {
                                color: `rgb(${tenantTheme['LAYOUT-COLORS']['LIGHT-LAYOUT'].color}) !important`,
                            },
                        },
                    },

                    MuiFormControlLabel: {
                        root: {
                            color: `rgb(${tenantTheme['TEXT-COLORS']['MAIN-TEXT'].color})`,
                        },
                    },

                    MuiRadio: {
                        root: {
                            color: `rgb(${tenantTheme['UI-COLORS']['MAIN-PRIMARY'].color}) !important`,
                        },
                    },

                    MuiPaginationItem: {
                        root: {
                            color: `rgb(${tenantTheme['LAYOUT-COLORS']['TRANSPARENT-LAYOUT'].color})`,
                        },

                        page: {
                            '&.Mui-selected': {
                                backgroundColor: `rgb(${tenantTheme['LAYOUT-COLORS']['TRANSPARENT-LAYOUT'].color} / 25%) !important`,
                            },
                        },
                    },

                    MuiPopover: {
                        paper: {
                            overflowX: 'visible',
                            overflowY: 'visible',
                            marginTop: '0.25rem',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    },

                    MuiSwitch: {
                        root: {
                            padding: '9px 12px',
                        },
                        sizeSmall: {
                            padding: '4px 7px',
                        },
                        track: {
                            borderRadius: '9999px',
                        },
                        thumb: {
                            color: 'var(--main-text)',
                            border: '1px solid var(--layout-lighter)',
                        },
                        switchBase: {
                            '&$checked + $track': {
                                opacity: 1,
                            },
                            '&$disabled': {
                                color: 'var(--disabled)',
                            },
                            '&$disabled + $track': {
                                backgroundColor: 'var(--disabled)',
                            },
                        },
                    },

                    MuiToggleButton: {
                        root: {
                            color: 'var(--buttons-text)',
                            borderColor: 'rgb(var(--base-buttons-text) / 15%)',

                            '&.Mui-selected': {
                                color: 'var(--secondary-light)',
                                borderColor: 'rgb(var(--base-secondary-light) / 15%)',
                            },
                        },
                    },

                    MuiList: {
                        root: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '.25rem',
                        },

                        padding: {
                            paddingTop: 0,
                            paddingBottom: 0,
                        },
                    },

                    MuiListItem: {
                        root: {
                            borderRadius: '4px',
                            gap: '.75rem',
                            paddingTop: '.75rem',
                            paddingBottom: '.75rem',
                        },

                        gutters: {
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                        },

                        button: {
                            '&:hover': {
                                backgroundColor: `var(--layout-transparent)`,
                            },

                            '&$selected': {
                                backgroundColor: `var(--layout-transparent-dark)`,

                                '&:hover': {
                                    backgroundColor: `var(--layout-transparent)`,
                                },
                            },
                        },
                    },

                    MuiListItemIcon: {
                        root: {
                            color: 'var(--main-text)',
                            minWidth: 24,
                            maxWidth: 24,
                            minHeight: 24,
                            maxHeight: 24,
                        },
                    },

                    MuiListItemText: {
                        primary: {
                            color: 'var(--main-text)',
                            fontWeight: 600,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                        },
                    },
                },
            }),
        [tenantTheme],
    );

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateUtils}>{children}</MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

AppWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
    tenantTheme: PropTypes.object,
};

const MemoizedAppWrapper = memo(AppWrapper);

export { MemoizedAppWrapper };
