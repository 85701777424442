import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import {
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import FileUploadContainer from 'components/shared/file-upload-container';
import useScreenSizes from 'hooks/useScreenSizes.js';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { configurator } from 'routes';
import { errorHandling, uploadSingleFile } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const FilesRow = (props) => {
    const { file, deleteFilesAndDocuments, fileType } = props;
    const { t } = useTranslation();

    return (
        // Display of the files and documents in modal
        <div className="group mb-2 flex items-center justify-between rounded-md bg-layout-transparent px-4 py-2 transition-colors hover:bg-layout-transparent-dark">
            {fileType === 'FILE' ? (
                <div className="flex items-center">
                    <AttachFileIcon
                        className="mr-2 rotate-45 transform text-buttons-text"
                        style={{ fontSize: '1.75rem' }}
                    />
                    <p className="user-select-none text-buttons-text">{file.info.name}</p>
                </div>
            ) : (
                <div className="flex items-center">
                    <InsertDriveFileIcon className="mr-2 text-buttons-text" style={{ fontSize: '1.75rem' }} />
                    <p className="user-select-none text-buttons-text">{file.info.name}</p>
                </div>
            )}

            <div className="flex items-center">
                <BasicTooltip tip={`${fileType == 'FILE' ? t('Delete file') : t('Delete document')}`}>
                    <div
                        className="flex h-7 w-7 -translate-y-2 transform cursor-pointer items-center justify-center rounded-full bg-error-light opacity-0 transition-all hover:bg-error group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100"
                        onClick={() => deleteFilesAndDocuments(file.id, file.fileType)}
                    >
                        <CloseIcon className="text-buttons-text" style={{ fontSize: '1.25rem' }} />
                    </div>
                </BasicTooltip>
            </div>
        </div>
    );
};

FilesRow.propTypes = {
    file: PropTypes.object,
    deleteFilesAndDocuments: PropTypes.func,
    fileType: PropTypes.string,
};

FilesRow.defaultProps = {
    file: null,
    deleteFilesAndDocuments: () => null,
    fileType: 'FILE',
};

const PMWikiModal = (props) => {
    const { closeModal, getWikis, wikiForEdit, setWikiForEdit, departments } = props;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { projectId } = useParams();

    const contentRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [width, height] = useScreenSizes();

    const [selectedDepartment, setSelectedDepartment] = useState(departments[0]?.id ?? '');

    const [wikiSubject, setWikiSubject] = useState('');
    const [wikiContent, setWikiContent] = useState('');

    const [files, setFiles] = useState([]);
    const [documents, setDocuments] = useState([]);

    /**
     * This use effect set the modal with info from the wiki info who must be edited
     */
    useEffect(() => {
        if (wikiForEdit) {
            setSelectedDepartment(wikiForEdit.departmentId);
            setWikiSubject(wikiForEdit.subject);
            setWikiContent(wikiForEdit.details);
        }
    }, [wikiForEdit]);

    /**
     * Function delete files or documents uploaded on the modal, before creation of wiki
     * @param {*} id  id of the file
     * @param {*} fileType can be 'FILE' or 'DOC'
     */
    const deleteFilesAndDocuments = (id, fileType) => {
        let copy;
        if (fileType === 'FILE') {
            copy = files.filter((file) => file.id !== id);
            setFiles(copy);
        } else {
            copy = documents.filter((document) => document.id !== id);
            setDocuments(copy);
        }
    };

    const schema = yup.object().shape({
        wikiSubject: yup
            .string()
            .trim()
            .typeError(t('The subject for the wiki is mandatory!'))
            .min(2, t('The subject must be at least 2 characters long!'))
            .required(t('The subject for the wiki is mandatory!')),
        wikiContent: yup
            .string()
            .trim()
            .typeError(t('The wiki must have a content!'))
            .required(t('The wiki must have a content!')),
        selectedDepartment: yup
            .string()
            .typeError(t('The wiki must have a department selected!'))
            .required(t('The wiki must have a department selected!')),
    });

    const createWiki = async () => {
        try {
            await schema.validate({
                wikiSubject,
                wikiContent,
                selectedDepartment,
            });

            const allFilesAndDocuments = [...files, ...documents]; //Create a single array with files and documents

            setLoading(true);
            try {
                const formData = new FormData();
                const reqBody = {
                    subject: wikiSubject,
                    details: wikiContent,
                    pmDepartmentId: selectedDepartment,
                    projectId: projectId,
                };

                formData.append('data', JSON.stringify(reqBody));

                allFilesAndDocuments.forEach((file) => formData.append('files', file.blob));
                formData.append(
                    'filesMetaData',
                    JSON.stringify(allFilesAndDocuments.map((file) => ({ name: file.info.name, type: file.fileType })))
                );

                await API.post('/wikis', formData);
                await getWikis();
                closeModal();
                setLoading(false);
                enqueueSnackbar(t('The wiki was successfully created!'), { variant: 'success' });
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const editWiki = async () => {
        try {
            await schema.validate({
                wikiSubject,
                wikiContent,
                selectedDepartment,
            });

            const allFilesAndDocuments = [...files, ...documents]; //Create a single array with files and documents

            setLoading(true);
            try {
                const formData = new FormData();
                const reqBody = {
                    id: wikiForEdit.id,
                    data: {
                        subject: wikiSubject,
                        details: wikiContent,
                        pmDepartmentId: selectedDepartment,
                        projectId: projectId,
                        filesToDelete: [],
                    },
                };

                formData.append('data', JSON.stringify(reqBody));

                allFilesAndDocuments.forEach((file) => formData.append('files', file.blob));
                formData.append(
                    'filesMetaData',
                    JSON.stringify(allFilesAndDocuments.map((file) => ({ name: file.info.name, type: file.fileType })))
                );

                await API.put('/wikis', formData);
                await getWikis();
                closeModal();
                setLoading(false);
                enqueueSnackbar(t('The wiki was successfully edited!'), { variant: 'success' });
                setWikiForEdit(null);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let observer = null;

        if (contentRef.current) {
            observer = new ResizeObserver((entries) => {
                if (contentRef.current) {
                    for (const entry of entries) {
                        if (entry.target.getBoundingClientRect().height >= height * 0.6) {
                            contentRef.current.style.overflowY = 'auto';
                            contentRef.current.style.overflowX = 'hidden';
                        } else {
                            contentRef.current.style.overflow = 'visible';
                        }
                    }
                }
            });

            observer.observe(contentRef.current);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [loading, height]);

    return (
        <div className="relative">
            {loading ? (
                <div className="py-20 text-center">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {departments.length > 0 ? (
                        <div
                            style={{
                                width: width > 1000 ? '500px' : '90vw',
                                maxWidth: '500px',
                            }}
                        >
                            {/* Modal Header */}
                            <div className="mb-5 flex items-center justify-between px-10 pt-10 sm:px-4 sm:pt-4">
                                <h2 className="font-semibold">{`${wikiForEdit ? t('Edit wiki') : t('Post wiki')}`}</h2>

                                <CloseIcon
                                    className="absolute right-4 top-4 ml-10 cursor-pointer text-primary-main opacity-75 hover:opacity-100"
                                    style={{
                                        fontSize: '2.5rem',
                                        transition: 'opacity .2s ease',
                                    }}
                                    onClick={() => {
                                        closeModal();
                                        setWikiForEdit(null);
                                    }}
                                />
                            </div>

                            <div className={`p-10 sm:p-4`} style={{ maxHeight: '70vh' }} ref={contentRef}>
                                {/* Modal departments */}
                                <div className="mb-5">
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="status"
                                            name="status-filter"
                                            value={selectedDepartment}
                                            onChange={(e) => setSelectedDepartment(e.target.value)}
                                        >
                                            <div className="no-user-select-recursive flex items-center text-main-text">
                                                <div className="flex flex-wrap">
                                                    {departments.map((d) => (
                                                        <FormControlLabel
                                                            key={d.id}
                                                            value={d.id}
                                                            control={<Radio />}
                                                            label={d.name}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>

                                {/* Modal  input fields*/}
                                <TextField
                                    name={t('Subject')}
                                    placeholder={t('Subject')}
                                    value={wikiSubject}
                                    onChange={(e) => setWikiSubject(e.target.value)}
                                />

                                <div className="mb-3"></div>

                                <TextField
                                    name={t('Post content')}
                                    placeholder={t('Post content')}
                                    value={wikiContent}
                                    onChange={(e) => setWikiContent(e.target.value)}
                                    multiline
                                />

                                <div className="mb-3"></div>

                                <div className="flex flex-col">
                                    <div>
                                        {files.map((file) => (
                                            <div key={file.id} className="mb-2 mt-2">
                                                <FilesRow
                                                    key={file.id}
                                                    file={file}
                                                    deleteFilesAndDocuments={deleteFilesAndDocuments}
                                                    fileType={file.fileType}
                                                />
                                            </div>
                                        ))}
                                    </div>

                                    <div>
                                        {documents.map((document) => (
                                            <div key={document.id} className="mb-2">
                                                <FilesRow
                                                    key={document.id}
                                                    file={document}
                                                    deleteFilesAndDocuments={deleteFilesAndDocuments}
                                                    fileType={document.fileType}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Modal buttons for upload file */}
                                <div className="mb-10 mt-2 flex flex-wrap gap-3">
                                    <FileUploadContainer
                                        onUpload={(e) => {
                                            uploadSingleFile(
                                                e,
                                                ({ message, blob, info }) => {
                                                    if (message) {
                                                        enqueueSnackbar(message, {
                                                            variant: 'error',
                                                        });
                                                    } else if (blob && info) {
                                                        setFiles([
                                                            ...files,
                                                            {
                                                                id: Math.random(),
                                                                blob,
                                                                info,
                                                                fileType: 'FILE',
                                                            },
                                                        ]);
                                                    }
                                                },
                                                'all'
                                            );
                                        }}
                                    >
                                        <Button type="submit" startIcon={<AttachmentIcon />}>
                                            {wikiForEdit ? t('Attach new file') : t('Attach file')}
                                        </Button>
                                    </FileUploadContainer>

                                    <FileUploadContainer
                                        onUpload={(e) => {
                                            uploadSingleFile(
                                                e,
                                                ({ message, blob, info }) => {
                                                    if (message) {
                                                        enqueueSnackbar(message, {
                                                            variant: 'error',
                                                        });
                                                    } else if (blob && info) {
                                                        setDocuments([
                                                            ...documents,
                                                            {
                                                                id: Math.random(),
                                                                blob,
                                                                info,
                                                                fileType: 'DOCUMENT',
                                                            },
                                                        ]);
                                                    }
                                                },
                                                'all'
                                            );
                                        }}
                                    >
                                        <Button type="submit" startIcon={<InsertDriveFileIcon />}>
                                            {wikiForEdit ? t('Attach new document') : t('Attach document')}
                                        </Button>
                                    </FileUploadContainer>
                                </div>

                                {/* Modal buttons for actions */}
                                {wikiForEdit ? (
                                    <div className="flex gap-3">
                                        <Button
                                            type="submit"
                                            startIcon={<EditIcon />}
                                            color="secondary"
                                            onClick={editWiki}
                                        >
                                            {t('Edit wiki')}
                                        </Button>

                                        <div>
                                            <Button
                                                type="submit"
                                                color="secondary"
                                                onClick={() => {
                                                    closeModal();
                                                    setWikiForEdit(null);
                                                }}
                                            >
                                                {t('Cancel')}
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex gap-3">
                                        <Button
                                            type="submit"
                                            startIcon={<AddIcon />}
                                            color="secondary"
                                            onClick={createWiki}
                                        >
                                            {t('Post')}
                                        </Button>

                                        <div>
                                            <Button
                                                type="submit"
                                                color="secondary"
                                                onClick={() => {
                                                    closeModal();
                                                    setWikiForEdit(null);
                                                }}
                                            >
                                                {t('Cancel')}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="px-4 py-20 text-center">
                            <p className="mb-8 text-base font-semibold opacity-75">
                                {t('To add a new wiki, you must add a department first!')}
                            </p>
                            <Button
                                startIcon={<AddIcon />}
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        configurator.base +
                                            configurator.departments.base +
                                            configurator.departments.create
                                    )
                                }
                            >
                                {t('Add department')}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

PMWikiModal.propTypes = {
    closeModal: PropTypes.func,
    getWikis: PropTypes.func,
    wikiForEdit: PropTypes.object,
    setWikiForEdit: PropTypes.func,
    departments: PropTypes.array,
};

PMWikiModal.defaultProps = {
    closeModal: () => null,
    getWikis: () => null,
    wikiForEdit: {},
    setWikiForEdit: () => null,
    departments: [],
};

export default PMWikiModal;
