import React, { useState } from 'react';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

export const Filter = (props) => {
    const { name, options, selectedOption, setSelectedOption, isDate } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleDateChange = (newDate) => {
        if (isDate) {
            setSelectedOption(newDate);
            setIsOpen(false);
        }
    };

    return (
        // only close on clickaway when is not of date type
        <ClickAwayListener
            onClickAway={() => {
                if (!isDate && isOpen) setIsOpen(false);
            }}
        >
            <div className="relative z-50 inline-block flex-shrink-0">
                <div className="relative flex items-center">
                    {/* Filter name */}
                    <p className="mr-2 flex-shrink-0">{name}</p>

                    {/* Filter Options / Selected option */}
                    {/* eslint-disable-next-line */}
                    <div
                        className={`relative cursor-pointer rounded-md bg-layout-lighter px-4 py-2 pr-2 text-main-text transition-colors hover:bg-primary-light ${
                            isOpen && !isDate && 'rounded-b-none'
                        }`}
                        onClick={() => {
                            if (!isOpen) setIsOpen(!isOpen);
                        }}
                    >
                        {/* Selected option */}
                        <div className="flex items-center">
                            {/* eslint-disable-next-line */}
                            <p className="pointer-events-none font-medium">
                                {selectedOption !== null
                                    ? isDate
                                        ? dayjs(selectedOption).format('DD MMM YYYY')
                                        : options[selectedOption]
                                    : 'Alege'}
                            </p>
                            <KeyboardArrowDownIcon
                                className={`flex-shrink-0 transform transition-transform ${isOpen && 'rotate-180'}`}
                                style={{
                                    marginLeft: '.5rem',
                                    fontSize: '1.35rem',
                                }}
                            />
                        </div>

                        {/* Options */}
                        {/* Text options */}
                        {isOpen && !isDate && (
                            <div
                                className="absolute left-0 top-full overflow-hidden rounded-md rounded-tl-none bg-primary-light"
                                style={{
                                    // so that the right corner is always beyond the selected option
                                    minWidth: '110%',
                                }}
                            >
                                <ul>
                                    {options.map((option, index) => (
                                        // eslint-disable-next-line
                                        <li
                                            key={option}
                                            className="flex cursor-pointer items-center p-3 pr-6 hover:bg-primary-main"
                                            onClick={() => {
                                                if (selectedOption !== index) setSelectedOption(index);
                                                else setSelectedOption(null);

                                                setIsOpen(false);
                                            }}
                                        >
                                            {selectedOption === index ? (
                                                <CheckBoxIcon
                                                    className="text-main-text"
                                                    style={{ fontSize: '1.20rem' }}
                                                />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon
                                                    className="text-main-text"
                                                    style={{ fontSize: '1.20rem' }}
                                                />
                                            )}
                                            <p className="pointer-events-none ml-2 whitespace-nowrap text-main-text">
                                                {option}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {/* Date options */}
                        {isDate && (
                            <div className="hidden-default-date-picker">
                                <MuiDatePicker
                                    margin="normal"
                                    variant="inline"
                                    open={isOpen}
                                    onClose={() => setIsOpen(false)}
                                    format="d MMM yyyy"
                                    value={selectedOption}
                                    onChange={handleDateChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    );
};

Filter.propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setSelectedOption: PropTypes.func,
    isDate: PropTypes.bool,
};

Filter.defaultProps = {
    name: '',
    options: [],
    selectedOption: null,
    setSelectedOption: () => null,
    isDate: false,
};
