import React, { useState } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import { Button } from '@material-ui/core';

import FileUploadContainer from 'components/shared/file-upload-container';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { uploadMultiFiles, uploadSingleFile } from 'utils';

import ModalDocRow from './modal-doc-row';

const DocModal = (props) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { type, files, setFiles, setOpen } = props;
    const [modalFiles, setModalFiles] = useState([]);

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = () => reject('Error loading');
            reader.readAsDataURL(blob);
        });
    };

    const generateBase64Files = async (blob, info, type) => {
        if (type === 'Uni-select') {
            const url = await blobToBase64(blob);

            setModalFiles((prev) => [
                ...prev,
                {
                    id: Math.random(),
                    blob,
                    url,
                    name: info.name,
                },
            ]);
        }

        if (type === 'Multi-select') {
            const newFiles = [];

            for (let i = 0; i < blob.length; i++) {
                const url = await blobToBase64(blob[i]);

                newFiles.push({
                    id: Math.random(),
                    blob: blob[i],
                    url,
                    name: info[i].name,
                });
            }

            setModalFiles((prev) => [...prev, ...newFiles]);
        }
    };

    const uploadFunction = (e) => {
        if (type === 'Uni-select') {
            uploadSingleFile(
                e,
                ({ message, blob, info }) => {
                    if (message) {
                        enqueueSnackbar(t(message), {
                            variant: 'error',
                        });
                    } else if (blob && info) {
                        generateBase64Files(blob, info, type);
                    }
                },
                'file'
            );
        } else {
            uploadMultiFiles(
                e,
                ({ message, blob, info }) => {
                    if (message) {
                        enqueueSnackbar(t(message), {
                            variant: 'error',
                        });
                    } else if (blob && info) {
                        generateBase64Files(blob, info, type);
                    }
                },
                'file'
            );
        }
    };

    const validateObject = () => {
        for (let i = 0; i < modalFiles.length; i++) {
            if (modalFiles[i].name.trim().length === 0 || modalFiles[i].name.trim().length === '') {
                enqueueSnackbar(`${t('You must add the name of the file, on file')}: ${Number(i) + 1}`, {
                    variant: 'error',
                });
                return false;
            } else if (modalFiles[i].name.trim().length < 2) {
                enqueueSnackbar(
                    `${t('The name of the file must be at least two characters long, on file')}: ${Number(i) + 1}`,
                    {
                        variant: 'error',
                    }
                );
                return false;
            }
        }
        return true;
    };

    const handleComplete = () => {
        const validationResult = validateObject();

        if (validationResult === false) return;

        setFiles([...files, ...modalFiles]);
        setOpen(false);
    };

    return (
        <div className="flex flex-col items-center gap-16 p-10 sm:px-4 sm:py-8">
            <h2>{type === 'Uni-select' ? t('Add document') : t('Add documents')}</h2>
            <div className="flex w-full flex-col gap-8">
                {modalFiles.length > 0 && (
                    <div className="flex flex-col gap-2 ">
                        {modalFiles.map((mFile, index) => (
                            <ModalDocRow key={mFile.id} file={mFile} index={index} setModalFiles={setModalFiles} />
                        ))}
                    </div>
                )}

                <div className="flex flex-col items-center gap-4">
                    {modalFiles.length === 0 && (
                        <h4>
                            {type === 'Uni-select'
                                ? t('Before continuing you have to select a file!')
                                : t('Before continuing you have to select one or more files!')}
                        </h4>
                    )}
                    <div className="flex">
                        <FileUploadContainer onUpload={uploadFunction}>
                            <Button startIcon={<NoteAddIcon />} color="primary" className="self-center">
                                {type === 'Uni-select' ? t('Select file') : t('Select files')}
                                <input name="image" hidden accept="image/*, application/pdf" type="file" />
                            </Button>
                        </FileUploadContainer>
                    </div>
                </div>
            </div>

            <div className="flex w-full justify-end gap-4">
                <Button startIcon={<CancelIcon />} color="secondary" onClick={() => setOpen(false)}>
                    {t('Cancel')}
                </Button>
                <Button
                    startIcon={<CheckCircleIcon />}
                    color="secondary"
                    disabled={modalFiles.length === 0}
                    className="focus:outline-none"
                    onClick={handleComplete}
                >
                    {t('Complete')}
                </Button>
            </div>
        </div>
    );
};

DocModal.propTypes = {
    type: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.object),
    setFiles: PropTypes.func,
    setOpen: PropTypes.func,
};

DocModal.defaultProps = {
    type: 'Uni-select',
    files: [],
    setFiles: () => {},
    setOpen: () => {},
};

export default DocModal;
