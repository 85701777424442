import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import PMWikiDisplay from 'components/modules/wiki/pm-wiki-display';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header, Tabs } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import { getTenantUsers } from 'utils/getterFunctions';

const AffiliateProjectWikis = () => {
    const { t } = useTranslation();
    const enqueueSnackbar = useSnackbar();

    const { projectId } = useParams();

    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const [fileType, setFileType] = useState('all');

    const [departments, setDepartments] = useState([]);
    const [wikis, setWikis] = useState([]);
    const [tenants, setTenants] = useState([]);

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const getWikis = async () => {
        try {
            const response = await API.get('/wikis', {
                params: {
                    projectId: projectId,
                    departmentId: selectedDepartment === 0 ? null : departments[selectedDepartment - 1].id,
                    fileType: fileType === 'all' ? null : fileType,
                },
            });
            setWikis(response.data.wikiArray);
        } catch (err) {
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
            console.error(err);
        }
    };

    useEffect(() => {
        (async () => {
            // const departmentsFetch = await getDepartments();
            try {
                const response = await API.get('pmDepartments', {
                    params: {
                        perPage: 9999,
                        currentPage: 0,
                        pagesToLoad: 1,
                        type: 'PLANNING_OVERVIEW',
                    },
                });

                setDepartments(response.data.pmDepartments);
            } catch (err) {
                console.error(err);
            }

            const tenantsFetch = await getTenantUsers();
            setTenants(tenantsFetch);

            getWikis();
        })();
    }, []);

    /**
     * This useEffect is for filtering wikis by department and file type
     */
    useEffect(() => {
        getWikis();
    }, [selectedDepartment, fileType]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Wiki - ${t('Documentation')}`}</title>
            </Helmet>
            <Header
                pageTitle={`Wiki - ${t('Documentation')}`}
                toolbar={
                    <Tabs
                        tabs={[t('All'), ...(departments?.map((d) => d.name) ?? [])]}
                        activeTab={selectedDepartment}
                        setActiveTab={setSelectedDepartment}
                    />
                }
                toolbarSecondary={
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="status"
                            name="status-filter"
                            value={fileType}
                            onChange={(e) => setFileType(e.target.value)}
                        >
                            <div className="no-user-select-recursive flex items-center text-main-text">
                                <FormControlLabel value="all" control={<Radio />} label={t('All')} />
                                <FormControlLabel value="FILE" control={<Radio />} label={t('Files')} />
                                <FormControlLabel value="DOCUMENT" control={<Radio />} label={t('Documents')} />
                            </div>
                        </RadioGroup>
                    </FormControl>
                }
            />
            <div className="page-container">
                <div className="flex items-start justify-center lg:items-center">
                    <div
                        className="relative z-40 flex w-full flex-col items-center pl-24 md:pl-32 sm:pl-0"
                        style={{ maxWidth: '800px' }}
                    >
                        {wikis.length > 0 && (
                            <div className="relative w-full border-l border-layout-lighter py-8 pl-8 sm:pl-6">
                                {wikis.map((wiki) => (
                                    <PMWikiDisplay
                                        key={wiki.id}
                                        wiki={wiki}
                                        tenants={tenants}
                                        departments={departments}
                                        getWikis={getWikis}
                                        canAll={canAll}
                                    />
                                ))}
                            </div>
                        )}
                        {!wikis.length && <NoDataPlaceholder />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AffiliateProjectWikis;
