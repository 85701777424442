import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generateUUID } from 'utils';

const mappedLegends = {
    projectStructures: [
        {
            id: generateUUID(),
            info: 'Project structure status',
            subItems: [
                { id: generateUUID(), info: 'Work in progress', color: '#06b6d4' },
                { id: generateUUID(), info: 'Done', color: '#22c55e' },
                { id: generateUUID(), info: 'Overdue', color: '#ef4444' },
                {
                    id: generateUUID(),
                    info: 'Delayed - the period in witch the project structure was started after the initial start date or finished after initial end date',
                    color: '#991b1b',
                },
                {
                    id: generateUUID(),
                    info: 'Ahead of time - the period in witch the project structure was started before the initial start date or finished before initial end date',
                    color: '#166534',
                },
            ],
        },
        {
            id: generateUUID(),
            info: 'Section status',
            subItems: [
                { id: generateUUID(), info: 'Work in progress', color: '#3b82f6' },
                {
                    id: generateUUID(),
                    info: "New and work in progress - the 'New' keyword specifies that this section was added after the initial creation of the project structure",
                    color: '#f97316',
                },
                { id: generateUUID(), info: 'Done', color: '#22c55e' },
                {
                    id: generateUUID(),
                    info: "New and done - the 'New' keyword specifies that this section was added after the initial creation of the project structure",
                    color: '#a3e635',
                },
                { id: generateUUID(), info: 'Overdue', color: '#ef4444' },
                {
                    id: generateUUID(),
                    info: "New and overdue - the 'New' keyword specifies that this section was added after the initial creation of the project structure",
                    color: '#f87171',
                },
                {
                    id: generateUUID(),
                    info: 'Delayed - the period in witch the section was started after the initial start date or finished after initial end date',
                    color: '#991b1b',
                },
                {
                    id: generateUUID(),
                    info: 'Ahead of time - the period in witch the section was started before the initial start date or finished before initial end date',
                    color: '#166534',
                },
            ],
        },
        {
            id: generateUUID(),
            info: 'Activity status',
            subItems: [
                { id: generateUUID(), info: 'Work in progress', color: '#6366f1' },
                {
                    id: generateUUID(),
                    info: "New and work in progress - the 'New' keyword specifies that this activity was added after the initial creation of the project structure",
                    color: '#eab308',
                },
                { id: generateUUID(), info: 'Done', color: '#22c55e' },
                {
                    id: generateUUID(),
                    info: "New and done - the 'New' keyword specifies that this activity was added after the initial creation of the project structure",
                    color: '#a3e635',
                },
                { id: generateUUID(), info: 'Overdue', color: '#ef4444' },
                {
                    id: generateUUID(),
                    info: "New and overdue - the 'New' keyword specifies that this activity was added after the initial creation of the project structure",
                    color: '#f87171',
                },
                {
                    id: generateUUID(),
                    info: 'Delayed - the period in witch the activity was started after the initial start date or finished after initial end date',
                    color: '#991b1b',
                },
                {
                    id: generateUUID(),
                    info: 'Ahead of time - the period in witch the activity was started before the initial start date or finished before initial end date',
                    color: '#166534',
                },
            ],
        },
        {
            id: generateUUID(),
            info: 'Others',
            subItems: [
                {
                    id: generateUUID(),
                    info: 'Dead zone - represents the interval between the actual and initial timelines of an activity',
                    color: '#64748b',
                },
                {
                    id: generateUUID(),
                    info: 'Initial interval - represents the initial timeline of an activity using the dates from the moment of the creation',
                    color: '#374151',
                },
                {
                    id: generateUUID(),
                    info: 'Today highlight',
                    color: '#cbd5e1',
                    icon: (
                        <VisibilityIcon
                            fontSize="large"
                            style={{
                                color: 'black',
                            }}
                        />
                    ),
                },
            ],
        },
    ],
    milestones: [
        {
            id: generateUUID(),
            info: 'Milestone status',
            subItems: [
                { id: generateUUID(), info: 'New or work in progress', color: 'rgb(43 163 173)' },
                { id: generateUUID(), info: 'Done', color: 'rgb(55 169 133)' },
                { id: generateUUID(), info: 'Overdue', color: 'rgb(255 105 105)' },
            ],
        },
        {
            id: generateUUID(),
            info: 'Sub-milestone status',
            subItems: [
                { id: generateUUID(), info: 'New or work in progress', color: 'rgb(104 104 104 / 90%)' },
                { id: generateUUID(), info: 'Done', color: 'rgb(55 169 133 / 90%)' },
                { id: generateUUID(), info: 'Overdue', color: 'rgb(255 105 105/ 90%)' },
            ],
        },
        {
            id: generateUUID(),
            info: 'Task status',
            subItems: [
                { id: generateUUID(), info: 'New or work in progress', color: 'rgb(116 204 212 / 80%)' },
                { id: generateUUID(), info: 'Done', color: 'rgb(55 169 133 / 80%)' },
                { id: generateUUID(), info: 'Overdue', color: 'rgb(255 105 105/ 80%)' },
            ],
        },
        {
            id: generateUUID(),
            info: 'Others',
            subItems: [
                {
                    id: generateUUID(),
                    info: 'Today highlight',
                    color: '#cbd5e1',
                    icon: (
                        <VisibilityIcon
                            fontSize="large"
                            style={{
                                color: 'black',
                            }}
                        />
                    ),
                },
            ],
        },
    ],
};

const GanttLegend = ({ type }) => {
    const { t } = useTranslation();
    const legendData = mappedLegends[type];

    return (
        <div className="flex flex-col justify-center gap-10 rounded-md bg-white p-10">
            <h2 className="text-3xl text-black">{t('Gantt legend')}</h2>
            <div className="grid grid-cols-4 gap-x-5 gap-y-10">
                {legendData.map((item) => (
                    <div key={item.id} className="flex flex-col gap-5 rounded-lg border-2 border-gray-500 p-5">
                        <h3 className="text-2xl text-black">{t(item.info)}</h3>
                        {item.subItems.map((subItem) => (
                            <div className="flex items-center gap-2 text-black" key={subItem.id}>
                                <div
                                    className="flex h-10 w-16 flex-shrink-0 items-center justify-center rounded-md"
                                    style={{
                                        backgroundColor: subItem.color,
                                    }}
                                >
                                    {subItem?.icon && subItem?.icon}
                                </div>
                                &mdash;
                                <p className="max-w-xs text-xl text-black">{t(subItem.info)}</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

GanttLegend.propTypes = {
    type: PropTypes.string,
};

GanttLegend.defaultProps = {
    type: 'milestones',
};

export default GanttLegend;
