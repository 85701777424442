import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Footer from 'components/footer/footer';
import ContactActivity from 'pages/crm/contact-activity/contact-activity';
import Contacts from 'pages/crm/contacts/contacts';
import Contracts from 'pages/crm/contracts/contracts';
import Estates from 'pages/crm/estate/estates';
import Reports from 'pages/crm/reports/reports';
import Sales from 'pages/crm/sales/sales';
import TasksPipeline from 'pages/internal-activity/tasks-pipeline/tasks-pipeline';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { crm } from 'routes';

const CRM = () => {
    return (
        <>
            <Helmet>
                <title>CRM</title>
            </Helmet>

            <Switch>
                <Route exact path={crm.base}>
                    <Header pageTitle="CRM" />
                </Route>

                <Route path={crm.base + crm.contactActivity.full}>
                    <ContactActivity />
                </Route>

                <Route path={crm.base + crm.contactActivityPipeline.full}>
                    <TasksPipeline purpose="CLIENT" />
                </Route>

                <Route path={crm.base + crm.sales.base}>
                    <Sales />
                </Route>

                <Route path={crm.base + crm.contacts.base}>
                    <Contacts />
                </Route>

                <Route path={crm.base + crm.estate.base}>
                    <Estates />
                </Route>

                <Route path={crm.base + crm.contracts.base}>
                    <Contracts />
                </Route>

                <Route path={crm.base + crm.reports.base}>
                    <Reports />
                </Route>
            </Switch>
            <Footer />
        </>
    );
};

export default CRM;
