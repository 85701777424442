import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { quotes } from 'routes';

import EditStock from '../stocks/edit-stock';
import CreateArticleCategory from './create-article-category';
import CreateNomenclature from './create-nomenclature';
import EditArticleCategory from './edit-article-category';
import EditNomenclature from './edit-nomenclature';
import ManageNomenclatures from './manage-nomenclatures';

const Nomenclatures = () => {
    return (
        <>
            <Switch>
                <Route exact path={quotes.base + quotes.nomenclature.base + quotes.nomenclature.read}>
                    <ManageNomenclatures />
                </Route>

                <Route path={quotes.base + quotes.nomenclature.base + quotes.nomenclature.create}>
                    <CreateNomenclature />
                </Route>

                <Route path={quotes.base + quotes.nomenclature.base + quotes.nomenclature.updateFull}>
                    <EditNomenclature />
                </Route>

                <Route path={quotes.base + quotes.nomenclature.base + quotes.stocks.base + quotes.stocks.updateFull}>
                    <EditStock />
                </Route>

                <Route
                    exact
                    path={quotes.base + quotes.nomenclature.base + quotes.categories.base + quotes.categories.create}
                >
                    <CreateArticleCategory />
                </Route>

                <Route
                    path={
                        quotes.base + quotes.nomenclature.base + quotes.categories.base + quotes.categories.updateFull
                    }
                >
                    <EditArticleCategory />
                </Route>
            </Switch>
        </>
    );
};

export default Nomenclatures;
