import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import ExpenseForm from '../../../components/accountancy/expense-form';

const EditExpense = () => {
    const { t } = useTranslation();
    const { expenseId } = useParams();
    const { permissionMap } = useContext(UserContext);
    const canAll = permissionMap['Nomenclator cheltuieli'] > 1;

    const history = useHistory();

    useEffect(() => {
        if (!canAll) history.push('/');
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>{t('Edit expense')}</title>
            </Helmet>

            <Header pageTitle={t('Edit expense')} />

            <div className="page-container">
                <ExpenseForm expenseId={expenseId} canEdit={canAll} />
            </div>
        </>
    );
};

export default EditExpense;
