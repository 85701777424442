import React, { useLayoutEffect, useRef } from 'react';

import useScreenSizes from 'hooks/useScreenSizes';
import PropTypes from 'prop-types';
import { findParentWithoutCSS } from 'utils';

const SPACING_OFFSET = 5;

const placementOrders = {
    top: ['top', 'right', 'bottom', 'left'],
    right: ['right', 'top', 'bottom', 'left'],
    bottom: ['bottom', 'top', 'right', 'left'],
    left: ['left', 'top', 'right', 'bottom'],
};

const defaultTranslationStyles = {
    left: '50%',
    top: '50%',
    bottom: 'auto',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
};

const BasicTooltip = (props) => {
    const wrapperRef = useRef(null);
    const [width, height] = useScreenSizes();
    const { children, tip, position, disabled } = props;

    //? The usage of useLayoutEffect is necessary because we modify the styles of the displayed content during the render of this component
    useLayoutEffect(() => {
        //? If we have no container we return early
        //! We should always have a container. If no container is present we have an error
        const wrapper = wrapperRef.current;
        if (!wrapper) return;

        //? We assign the default styles to the tooltip container element
        const containerElement = wrapper.querySelector('#tooltip-container');
        Object.assign(containerElement, defaultTranslationStyles);

        const childrenElement = wrapper.querySelector('#tooltip-children');
        //? We select the order based on the specified position
        const order = placementOrders[position];

        //? We determine the viewport by finding the closest parent that has overflow set to anything else then visible.
        //! Elements that have overflow set to anything else then visible will cut the content of the tooltip
        const viewportRect = findParentWithoutCSS(wrapper, 'overflow', 'visible').getBoundingClientRect();
        const viewportTop = viewportRect.top;
        const viewportRight = viewportRect.right;
        const viewportBottom = viewportRect.bottom;
        const viewportLeft = viewportRect.left;

        const containerRect = containerElement.getBoundingClientRect();
        const childrenRect = childrenElement.getBoundingClientRect();

        //? We calculate the position of the container if placed above the children
        const resultTop = childrenRect.top - containerRect.height - SPACING_OFFSET;
        //? We calculate the position of the container if placed below the children
        const resultBottom = childrenRect.bottom + containerRect.height + SPACING_OFFSET;

        //? We determine the edges of the container on the X axis
        const resultCenterX = (childrenRect.left + childrenRect.right) / 2;
        const resultXRight = resultCenterX + (containerRect.width + SPACING_OFFSET) / 2;
        const resultXLeft = resultCenterX - (containerRect.width - SPACING_OFFSET) / 2;

        //? We calculate the position of the container if placed to the right of the children
        const resultRight = childrenRect.right + containerRect.width + SPACING_OFFSET;
        //? We calculate the position of the container if placed to the left of the children
        const resultLeft = childrenRect.left - containerRect.width - SPACING_OFFSET;

        //? We determine the edges of the container on the Y axis
        const resultCenterY = (childrenRect.top + childrenRect.bottom) / 2;
        const resultYTop = resultCenterY - (containerRect.height - SPACING_OFFSET) / 2;
        const resultYBottom = resultCenterY + (containerRect.height + SPACING_OFFSET) / 2;

        //? We check if the container overflows on the X axis
        const overflowsX = resultXRight > viewportRight || resultXLeft < viewportLeft;

        //? We determine if the container can will overflow if placed on the top or the bottom
        const overflowsTop = resultTop < viewportTop || overflowsX;
        const overflowsBottom = resultBottom > viewportBottom || overflowsX;

        //? We check if the container overflows on the Y axis
        const overflowsY = resultYTop < viewportTop || resultYBottom > viewportBottom;

        //? We determine if the container can will overflow if placed on the right or the left
        const overflowsRight = resultRight > viewportRight || overflowsY;
        const overflowsLeft = resultLeft < viewportLeft || overflowsY;

        //? We will try to place the element in the specified position
        //! If we can't place it in the specified position, we will try to place it in any other position based on the specified order
        for (const placement of order) {
            if (placement === 'top' && !overflowsTop) {
                containerElement.style.top = 0;
                containerElement.style.bottom = 'auto';
                containerElement.style.left = '50%';
                containerElement.style.right = 'auto';
                containerElement.style.transform = `translate(-50%, -${containerRect.height + SPACING_OFFSET}px)`;
                break;
            }

            if (placement === 'right' && !overflowsRight) {
                containerElement.style.top = '50%';
                containerElement.style.bottom = 'auto';
                containerElement.style.left = 'auto';
                containerElement.style.right = 0;
                containerElement.style.transform = `translate(${containerRect.width + SPACING_OFFSET}px, -50%)`;
                break;
            }

            if (placement === 'bottom' && !overflowsBottom) {
                containerElement.style.top = 'auto';
                containerElement.style.bottom = 0;
                containerElement.style.left = '50%';
                containerElement.style.right = 'auto';
                containerElement.style.transform = `translate(-50%, ${containerRect.height + SPACING_OFFSET}px)`;
                break;
            }

            if (placement === 'left' && !overflowsLeft) {
                containerElement.style.top = '50%';
                containerElement.style.bottom = 'auto';
                containerElement.style.left = 0;
                containerElement.style.right = 'auto';
                containerElement.style.transform = `translate(-${containerRect.width + SPACING_OFFSET}px, -50%)`;
                break;
            }
        }
    }, [children, tip, position, disabled, width, height]);

    return (
        <div ref={wrapperRef} id="tooltip" className="basic-tooltip relative">
            <div
                id="tooltip-container"
                className={`pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-md p-2 transition-all duration-200 sm:hidden ${
                    !disabled ? 'basic-tooltip-toggle' : 'basic-tooltip-disabled'
                }`}
                style={{
                    zIndex: 'calc(infinity)',
                    backgroundColor: `rgb(var(--base-layout-light) / 90%)`,
                }}
            >
                <p
                    className="w-max text-center"
                    style={{
                        maxWidth: '300px',
                    }}
                >
                    {tip}
                </p>
            </div>

            <div id="tooltip-children">{children}</div>
        </div>
    );
};

BasicTooltip.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    tip: PropTypes.string,
    position: PropTypes.string,
    disabled: PropTypes.bool,
};

BasicTooltip.defaultProps = {
    children: <></>,
    tip: '',
    position: 'top',
    disabled: false,
};

export default BasicTooltip;
