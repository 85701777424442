import React from 'react';

import PropTypes from 'prop-types';

import ConfigFieldWrapper from './config-field-wrapper';

const EmailField = (props) => {
    const { field, changeFieldValue, deleteFieldFromColumn } = props;

    return (
        <ConfigFieldWrapper
            canBeDeleted={field.canBeDeleted}
            type={field.type}
            name={field.displayName}
            setName={(newValue) => changeFieldValue('displayName', newValue)}
            deleteFieldFromColumn={deleteFieldFromColumn}
            hasOptions={false}
        />
    );
};

EmailField.propTypes = {
    field: PropTypes.object,
    changeFieldValue: PropTypes.func,
    deleteFieldFromColumn: PropTypes.func,
};

EmailField.defaultProps = {
    field: null,
    changeFieldValue: () => null,
    deleteFieldFromColumn: () => null,
};

export default EmailField;
