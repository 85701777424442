import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, TableCell } from '@material-ui/core';

import PrpTypes from 'prop-types';
import { ExpandableRow } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { resourceModule } from 'routes';

const WarehouseRow = ({ warehouse }) => {
    const optionClass = 'text-base text-disabled mb-2';
    const optionSpanClass = 'text-main-text font-bold';

    const history = useHistory();
    const { t } = useTranslation();

    const handleEdit = () => history.push(resourceModule.warehouse.edit + '/' + warehouse.id);
    const handleViewResources = () => history.push(resourceModule.warehouse.humanResources + '/' + warehouse.id);
    const handleViewResponsible = () =>
        history.push(resourceModule.humanResources.edit + '/' + warehouse.responsibleId);

    return (
        <ExpandableRow
            colSpan={6}
            row={
                <>
                    <TableCell>{warehouse.name}</TableCell>
                    <TableCell>{warehouse.address}</TableCell>
                    <TableCell>{warehouse.space}</TableCell>
                    <TableCell>{t(warehouse.type)}</TableCell>
                    {/* TODO: CHANGE THIS LATER (MAYBE) */}
                    <TableCell>{warehouse.Responsible?.name ?? '-'}</TableCell>
                </>
            }
            collapse={
                <div className="flex flex-col gap-10">
                    <div className="flex flex-wrap justify-between gap-4">
                        <Button color="primary" onClick={handleEdit}>
                            {t('Edit warehouse')}
                        </Button>
                        <div className="flex justify-end gap-4">
                            <Button color="secondary" onClick={handleViewResources}>
                                {t('View warehouse resources')}
                            </Button>
                            {warehouse.responsibleId && (
                                <Button color="secondary" onClick={handleViewResponsible}>
                                    {t('View responsible')}
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-10">
                        <div>
                            <h4 className="mb-5 whitespace-nowrap">{t('Warehouse info')}</h4>
                            <p className={optionClass}>
                                {t('Name')}: <span className={optionSpanClass}>{warehouse.name}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Address')}: <span className={optionSpanClass}>{warehouse.address}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Space')}: <span className={optionSpanClass}>{warehouse.space}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Type')}: <span className={optionSpanClass}>{t(warehouse.type)}</span>
                            </p>
                        </div>
                        {/* TODO: ADD ALL RESPONSIBLE INFO */}
                        {warehouse.responsibleId && (
                            <div>
                                <h4 className="mb-5 whitespace-nowrap">{t('Responsible info')}</h4>
                                <p className={optionClass}>
                                    {t('Name')}: <span className={optionSpanClass}>{warehouse.Responsible.name}</span>
                                </p>
                                <p className={optionClass}>
                                    {t('Address')}:{' '}
                                    <span className={optionSpanClass}>{warehouse.Responsible.address}</span>
                                </p>
                                <p className={optionClass}>
                                    CNP: <span className={optionSpanClass}>{warehouse.Responsible.CNP}</span>
                                </p>
                                <p className={optionClass}>
                                    Email: <span className={optionSpanClass}>{t(warehouse.Responsible.email)}</span>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            }
        />
    );
};

WarehouseRow.propTypes = {
    warehouse: PrpTypes.object,
};

export default WarehouseRow;
