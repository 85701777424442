import React, { useEffect, useState } from 'react';

import {
    Box,
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import { PropTypes } from 'prop-types';
import { Header, TableSeparator } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

import ProjectArticleRow from './project-article-row';

const ProjectArticles = ({ projectId }) => {
    const { t } = useTranslation();

    const [categories, setCategories] = useState([]);

    const [sortType, setSortType] = useState('ALL');
    const [categorySortCrit, setCategorySortCrit] = useState('ALL');
    const articleType = ['SERVICES', 'FIZIC', 'DIGITAL'];
    const type = [t('Service'), t('Physical product'), t('Digital product')];

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);

    const getArticlesOnProject = async (currentPage, perPage) => {
        try {
            const res = await API.get('articlesForPmProject', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                    type: sortType !== 'ALL' ? sortType : null,
                    categorieId: categorySortCrit !== 'ALL' ? categorySortCrit : null,
                    pmPlanningOverviewProjectId: projectId,
                },
            });

            setArticles(res.data.articles);

            return res.data.length;
        } catch (error) {
            throw new Error(error);
        }
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const response = await API.get('/categories');
                setCategories(response.data.getCategory);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('Articles on project')}</title>
            </Helmet>
            <Header
                pageTitle={t('Articles on project')}
                toolbar={
                    <div className="flex items-center gap-10 text-white">
                        <RadioGroup
                            aria-label="status"
                            name="status-filter"
                            value={categorySortCrit}
                            onChange={(e) => setCategorySortCrit(e.target.value)}
                        >
                            <div className="no-user-select-recursive -mr-3 flex items-center text-white">
                                <div className="flex flex-wrap">
                                    <FormControlLabel key={'ALL'} value={'ALL'} control={<Radio />} label={t('All')} />
                                    {categories.map((c) => (
                                        <FormControlLabel key={c.id} value={c.id} control={<Radio />} label={c.name} />
                                    ))}
                                </div>
                            </div>
                        </RadioGroup>

                        <div className="h-10 w-1 bg-layout-transparent" />

                        <RadioGroup
                            aria-label="status"
                            name="status-filter"
                            value={sortType}
                            onChange={(e) => setSortType(e.target.value)}
                        >
                            <div className="no-user-select-recursive flex items-center text-white">
                                <div className="flex flex-wrap">
                                    <FormControlLabel key={'ALL'} value={'ALL'} control={<Radio />} label={t('All')} />
                                    {type.map((t, index) => (
                                        <FormControlLabel
                                            key={t}
                                            value={articleType[index]}
                                            control={<Radio />}
                                            label={t}
                                        />
                                    ))}
                                </div>
                            </div>
                        </RadioGroup>
                    </div>
                }
            />

            <Pagination
                loading={loading}
                setLoading={setLoading}
                getFunction={getArticlesOnProject}
                refreshList={[sortType, categorySortCrit]}
            >
                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : articles.length === 0 ? (
                    <NoDataPlaceholder />
                ) : (
                    <div className="page-container">
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Article name')}</TableCell>
                                        <TableCell>{t('Article category')}</TableCell>
                                        <TableCell>{t('Price without VAT')}</TableCell>
                                        <TableCell>{t('VAT rate')}</TableCell>
                                        <TableCell>{t('VAT value')}</TableCell>
                                        <TableCell>{t('Final price with VAT')}</TableCell>
                                        <TableCell>{t('Variables')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {articles.map((article) => (
                                        <ProjectArticleRow key={article.id} article={article} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </Pagination>
        </React.Fragment>
    );
};

ProjectArticles.propTypes = {
    projectId: PropTypes.string,
};

ProjectArticles.defaultProps = {
    projectId: null,
};

export default ProjectArticles;
