import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { resourceModule } from 'routes';

import HumanAdd from './human-add';
import HumaneCategories from './human-categories';

const HumanResources = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path={resourceModule.humanResources.base}>
                    <Redirect to={resourceModule.humanResources.categories} />
                </Route>
                <Route path={resourceModule.humanResources.add}>
                    <HumanAdd />
                </Route>
                <Route path={resourceModule.humanResources.editFull}>
                    <HumanAdd />
                </Route>
                <Route path={resourceModule.humanResources.categories}>
                    <HumaneCategories />
                </Route>
            </Switch>
        </Fragment>
    );
};

export default HumanResources;
