import React, { useState } from 'react';

import FingerprintIcon from '@material-ui/icons/Fingerprint';

import { Button, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { Layout } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { EMAIL_REGEX } from 'utils';
import API from 'utils/axios';

const iconStyle = {
    marginRight: '1rem',
    color: 'var(--main-text)',
    fontSize: '4rem',
    flexShrink: 0,
};

const RecoverPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!EMAIL_REGEX.test(email)) {
            enqueueSnackbar('E-mail is not valid!', { variant: 'error' });
            return;
        }

        setLoading(true);

        try {
            await API.post('/recover_password', {
                email,
            });
            setSuccess(true);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>ERP | Recover Password</title>
            </Helmet>

            <Layout sidebar={false}>
                {success ? (
                    <div className="relative flex h-svh w-full items-center justify-center p-10">
                        <h2 className="pointer-events-none text-center text-main-text" style={{ userSelect: 'none' }}>
                            <span className="text-dark-text">A link has been send to</span> {email}.<br />
                            <span className="text-primary-main">Click on it to recover your password!</span>
                        </h2>
                    </div>
                ) : (
                    <form className="relative flex h-svh w-full items-center justify-center" onSubmit={onSubmit}>
                        <div className="w-full max-w-sm -translate-y-12 transform">
                            <div className="mb-12 flex items-center" style={{ userSelect: 'none' }}>
                                <FingerprintIcon style={iconStyle} />
                                <h2 className="whitespace-nowrap font-medium text-main-text">Recover Password</h2>
                            </div>

                            <div className="mb-10 w-full">
                                <div className="mb-5">
                                    <TextField
                                        label="E-mail"
                                        placeholder="E-mail"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="flex">
                                <Button
                                    type="submit"
                                    fullWidth
                                    color="primary"
                                    style={{
                                        transition: 'opacity .2s ease',
                                        pointerEvents: loading ? 'none' : 'all',
                                        opacity: loading ? '.5' : '1',
                                    }}
                                >
                                    Recover Password
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            </Layout>
        </>
    );
};

export default RecoverPassword;
