import React, { memo, useEffect, useRef, useState } from 'react';

import currentDayHighlightPath from 'assets/frappe-gantt/svgs/current-day-highlight.svg';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Tabs } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { getRawFileFromPath } from 'utils';

import Gantt from './frappe-gantt';
import GanttLegend from './gantt-legend';
import GanttTable from './gantt-table';

const FrappeGanttLayout = memo(function FrappeGanttLayout({
    milestones,
    setGanttData,
    customGanttData,
    getTasksFunction,
    type,
}) {
    const { t } = useTranslation();
    const viewModes = ['Day', 'Week', 'Month'];

    const ganttRef = useRef(null);
    const currentDayHighlightElRef = useRef(null);

    const [viewType, setViewType] = useState(0);
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        (async () => {
            ganttRef.current = null;

            if (!milestones.length) return;

            // ? We fetch the eye icon file from the assets in order to display it into the gantt
            if (!currentDayHighlightElRef.current)
                currentDayHighlightElRef.current = await getRawFileFromPath(currentDayHighlightPath);

            const [tasks, disabledCropHeightsIntervals] = getTasksFunction(milestones);

            ganttRef.current = new Gantt('#gantt-container', tasks, {
                language: i18n.resolvedLanguage,
                start_date: customGanttData?.startDate ?? null,
                end_date: customGanttData?.endDate ?? null,
                view_mode: viewModes[viewType],
                current_day_highlight_element: currentDayHighlightElRef.current,
            });

            const ganttWrapper = document.getElementById('gantt-wrapper');
            const ganttLegend = document.getElementById('gantt-legend');

            setTasks(tasks);
            setGanttData?.({ gantt: ganttWrapper, legend: ganttLegend, disabledCropHeightsIntervals });
        })();
    }, [milestones, viewType, i18n.resolvedLanguage]);

    return milestones.length > 0 ? (
        <div className="relative">
            <div className="mb-4">
                <Tabs tabs={[t('Day'), t('Week'), t('Month')]} activeTab={viewType} setActiveTab={setViewType} />
            </div>

            <div className="overflow-y-hidden overflow-x-scroll">
                <div
                    className="gantt-wrapper relative table overflow-hidden rounded-md border-none"
                    style={{
                        marginBottom: '30px',
                    }}
                    id="gantt-wrapper"
                >
                    <div className="table-cell align-top">
                        <GanttTable tasks={tasks} type={type} />
                    </div>
                    <div className="table-cell align-top" id="gantt-container" />
                </div>

                <div className="min-w-max max-w-6xl" id="gantt-legend">
                    <GanttLegend type={type} />
                </div>
            </div>
        </div>
    ) : (
        <NoDataPlaceholder />
    );
});

FrappeGanttLayout.propTypes = {
    milestones: PropTypes.array.isRequired,
    setGanttData: PropTypes.func,
    customGanttData: PropTypes.object,
    getTasksFunction: PropTypes.func,
    type: PropTypes.string,
};

FrappeGanttLayout.defaultProps = {
    milestones: [],
    setGanttData: () => {},
    customGanttData: {},
    getTasksFunction: () => {},
    type: 'milestones',
};

export default FrappeGanttLayout;
