import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import VisibilityIcon from '@material-ui/icons/Visibility';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { ExpandableRow, Header, TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { affiliate, crm } from 'routes';
import { formatDate, formatTime } from 'utils';
import API from 'utils/axios';

const AffiliateProjectRow = ({ project }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { name, createAt, updateAt, teams } = project;
    const { permissionMap } = useContext(UserContext);
    const { language } = useContext(GlobalContext);

    const teamString = useMemo(() => {
        let string = teams
            .slice(0, 3)
            .map((t) => t.name)
            .join(', ');
        if (teams.length > 3) string += ` + ${t('others')} ${teams.length - 3}`;

        return string;
    }, [project, language]);

    const canViewContacts = permissionMap['Contacte'] > 0;
    const canViewContracts = permissionMap['Contracte'] > 1;

    const getClientName = () => {
        if (project.contactId) {
            const name =
                typeof project.contact.data === 'string' ? JSON.parse(project.contact.data) : project.contact.data;

            return name['standard'].name;
        }
    };

    const getContractName = () => {
        if (project.contractId) {
            const name =
                typeof project.Contract.data === 'string' ? JSON.parse(project.Contract.data) : project.Contract.data;

            return name['standard'].number;
        } else {
            return t('Without Contract');
        }
    };

    return (
        <ExpandableRow
            colSpan={6}
            padding={false}
            row={
                <>
                    <TableCell>
                        <div
                            className="inline font-bold hover:text-blue-400"
                            onClick={() =>
                                history.push(
                                    affiliate.base +
                                        affiliate.projectManagement.project +
                                        '/' +
                                        project.id +
                                        affiliate.planingOverviewOnProject.base
                                )
                            }
                        >
                            {name}
                        </div>
                    </TableCell>
                    <TableCell>
                        <div
                            className={`inline  font-bold ${canViewContacts && 'hover:text-blue-400'}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!canViewContacts) return;

                                if (project.contactId)
                                    history.push(crm.base + crm.contactActivity.base + '/' + project.contactId);
                            }}
                        >
                            {getClientName()}
                        </div>
                    </TableCell>
                    <TableCell>
                        <div
                            className={`inline font-bold ${canViewContracts && 'hover:text-blue-400'}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!canViewContracts) return;

                                if (project.contractId)
                                    history.push(
                                        crm.base + crm.contracts.base + crm.contracts.update + '/' + project.contractId
                                    );
                            }}
                        >
                            {getContractName()}
                        </div>
                    </TableCell>
                    <TableCell>{teamString}</TableCell>
                    <TableCell>{formatDate(createAt)}</TableCell>
                    <TableCell>
                        {formatDate(updateAt)}, {formatTime(updateAt)}
                    </TableCell>
                </>
            }
            collapse={
                <div className="relative overflow-visible">
                    <div className="mb-2 flex justify-end gap-3 p-5">
                        <Button
                            startIcon={<VisibilityIcon />}
                            color="primary"
                            onClick={() =>
                                history.push(
                                    affiliate.base +
                                        affiliate.projectManagement.project +
                                        '/' +
                                        project.id +
                                        affiliate.planingOverviewOnProject.base
                                )
                            }
                        >
                            {t('View project management')}
                        </Button>
                    </div>
                </div>
            }
        />
    );
};

AffiliateProjectRow.propTypes = {
    project: PropTypes.object.isRequired,
};

const AffiliateProjects = () => {
    const { t } = useTranslation();
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAffiliateProjects = async (currentPage, perPage) => {
        try {
            const response = await API.get('/affiliatePmProjectsPlanningOverview', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            setProjects(response.data.projects.content);
            return response.data.projects.count;
        } catch (error) {
            throw new Error(error);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('Projects')}</title>
            </Helmet>

            <Header pageTitle={t('Projects')} />

            <div className="page-container">
                <Pagination loading={loading} setLoading={setLoading} getFunction={getAffiliateProjects}>
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : projects.length ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Project name')}</TableCell>
                                        <TableCell>Client</TableCell>
                                        <TableCell>Contract</TableCell>
                                        <TableCell>{t('Allocated teams')}</TableCell>
                                        <TableCell>{t('Created on')} </TableCell>
                                        <TableCell>{t('Last update')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {projects.map((project) => (
                                        <AffiliateProjectRow key={project.id} project={project} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </Pagination>
            </div>
        </React.Fragment>
    );
};

export default AffiliateProjects;
