import React from 'react';

import PropTypes from 'prop-types';

export const Header = (props) => {
    const { pageTitle, action, toolbar, toolbarSecondary, toolbarTertiary } = props;
    return (
        <>
            <div className="relative mb-1 flex w-full flex-wrap items-center gap-4 bg-layout-light px-16 py-10 md:px-6 md:py-6">
                <h1 className="mr-5 md:text-3xl sm:text-2xl">{pageTitle}</h1>
                {action}
            </div>

            {toolbar && (
                <div className="relative w-full bg-layout-light px-16 py-4  md:px-8" style={{ zIndex: 3 }}>
                    {toolbar}
                </div>
            )}

            {toolbarSecondary && (
                <div className="relative mt-1 w-full bg-layout-light px-16 py-4  md:px-6" style={{ zIndex: 2 }}>
                    {toolbarSecondary}
                </div>
            )}

            {toolbarTertiary && (
                <div className="relative mt-1 w-full bg-layout-light px-16 py-4 md:px-6" style={{ zIndex: 1 }}>
                    {toolbarTertiary}
                </div>
            )}
        </>
    );
};

Header.propTypes = {
    pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    action: PropTypes.element,
    toolbar: PropTypes.element,
    toolbarSecondary: PropTypes.element,
    toolbarTertiary: PropTypes.element,
};

Header.defaultProps = {
    pageTitle: '',
    action: null,
    toolbar: null,
    toolbarSecondary: null,
    toolbarTertiary: null,
};
