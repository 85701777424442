import React, { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { TextField } from '@material-ui/core';

import ErrorButton from 'components/shared/error-button';
import SuccessButton from 'components/shared/success-button';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { errorHandling, formatDate } from 'utils';
import * as yup from 'yup';

import { RiemContext } from '../riem';

const RiemInfoForm = ({ riem, companyLogo }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { setIsOperatingOnRowAt, onEditInfo } = useContext(RiemContext);
    const [isMounted, setIsMounted] = useState(false);
    const [formState, setFormState] = useState(riem.info);
    const formRef = useRef(null);
    const handleChangeField = (e) => setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const schema = yup.object().shape({
        title: yup.string().typeError(t('The title is mandatory')).required(t('The title is mandatory')),
        project: yup
            .string()
            .typeError(t('The project label is mandatory'))
            .required(t('The project label is mandatory')),
        beneficiary: yup
            .string()
            .typeError(t('The beneficiary label is mandatory'))
            .required(t('The beneficiary label is mandatory')),
        dates: yup
            .string()
            .typeError(t('The realization date / last update label is mandatory'))
            .required(t('The realization date / last update label is mandatory')),
    });

    const handleSuccessAction = async () => {
        try {
            await schema.validate(formState);
            onEditInfo(formState, riem.id, 'info');
            setIsOperatingOnRowAt(null);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };

    useEffect(() => {
        const form = formRef.current;
        if (!form) return;
        const firstInput = form.querySelector('input');
        firstInput.focus({ preventScroll: true });
        firstInput.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        setIsMounted(true);
    }, []);

    return (
        <form
            ref={formRef}
            role="row"
            className="col-span-full grid h-60 border-l border-r border-t border-black bg-layout-transparent-dark"
            style={{
                gridTemplateColumns: 'subgrid',
            }}
        >
            <div role="columnheader" className="col-span-2 p-6">
                <img
                    src={companyLogo}
                    placeholder="Logo"
                    style={{
                        minWidth: '5rem',
                        maxWidth: '5rem',
                    }}
                />
            </div>
            <div role="columnheader" className="col-span-7 flex items-center justify-center">
                <LabelWrapper label={t('Title')}>
                    <TextField
                        name="title"
                        placeholder={t('Title')}
                        value={formState.title}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="col-span-4 flex items-center">
                <div className="flex w-full flex-col gap-1 px-6 py-2 text-right">
                    <div className="flex items-center gap-1">
                        <LabelWrapper label={t('Project label')}>
                            <TextField
                                name="project"
                                placeholder={t('Project label')}
                                value={formState.project}
                                onChange={handleChangeField}
                            />
                        </LabelWrapper>
                        <p className="mt-5 whitespace-nowrap text-xl font-bold">- {riem.project.name}</p>
                    </div>
                    <div className="flex items-center gap-1">
                        <LabelWrapper label={t('Beneficiary label')}>
                            <TextField
                                name="beneficiary"
                                placeholder={t('Beneficiary label')}
                                value={formState.beneficiary}
                                onChange={handleChangeField}
                            />
                        </LabelWrapper>
                        <p className="mt-5 whitespace-nowrap text-xl font-bold">
                            -{' '}
                            {typeof riem.project.contact.data === 'string'
                                ? JSON.parse(riem.project.contact.data).standard.name
                                : riem.project.contact.data.standard.name}
                        </p>
                    </div>
                    <div className="flex items-center gap-1">
                        <LabelWrapper label={t('Realization date / last update label')}>
                            <TextField
                                name="dates"
                                placeholder={t('Realization date / last update label')}
                                value={formState.dates}
                                onChange={handleChangeField}
                            />
                        </LabelWrapper>
                        <p className="mt-5 whitespace-nowrap text-xl font-bold">
                            - {formatDate(riem.createdAt, true, true)} / {formatDate(riem.updatedAt, true, true)}
                        </p>
                    </div>
                </div>
            </div>
            {isMounted &&
                createPortal(
                    <div className="flex flex-col items-center justify-center gap-2">
                        <SuccessButton startIcon={<CheckCircleIcon />} size="small" onClick={handleSuccessAction}>
                            {t('Complete')}
                        </SuccessButton>
                        <ErrorButton
                            startIcon={<CancelIcon />}
                            size="small"
                            onClick={() => setIsOperatingOnRowAt(null)}
                        >
                            {t('Cancel')}
                        </ErrorButton>
                    </div>,
                    document.getElementById('riem-info-container')
                )}
        </form>
    );
};
RiemInfoForm.propTypes = {
    riem: PropTypes.object,
    companyLogo: PropTypes.string,
};
RiemInfoForm.defaultProps = {
    riem: {},
    companyLogo: '',
};
export default RiemInfoForm;
