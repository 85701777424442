import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button } from '@material-ui/core';

import Employee from 'api/Employee';
import ViewEmployees from 'components/employees/view-employees';
import Gantt from 'components/planning-overview/gantt/gantt';
import GantContext from 'contexts/GantContext';
import UserContext from 'contexts/UserContext';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { internalActivity } from 'routes';
import API from 'utils/axios';

const PlanningOverview = () => {
    const { t } = useTranslation();
    const tabs = useMemo(() => ['Planning Overview', t('Internal resources'), t('External resources')], [t]);
    const history = useHistory();

    const params = new URL(document.location).searchParams;

    const [activeTab, setActiveTab] = useState(params.get('tab') ? Number(params.get('tab')) : 0);
    const [projects, setProjects] = useState([]);
    const [internalEmployees, setInternalEmployees] = useState([]);
    const [externalEmployees, setExternalEmployees] = useState([]);

    const { permissionMap } = useContext(UserContext);
    const canAll = permissionMap['Planning Overview'] > 1;
    const canView = permissionMap['Planning Overview'] > 0;

    const getProjects = useCallback(async () => {
        try {
            const res = await API.get('/projectsOverviewPlanning');
            setProjects([...res.data.value]);
        } catch (err) {
            console.error(err);
        }
    }, []);

    const fetchEmployees = () => {
        Employee.internalActivity.get().then((res) => {
            setInternalEmployees(res.data.filter((e) => e.employeeType === 'INTERNAL'));
            setExternalEmployees(res.data.filter((e) => e.employeeType === 'EXTERNAL'));
        });
    };

    useEffect(() => {
        if (!canView) {
            history.push('/');
            return;
        }

        const tab = params.get('tab');
        if (tab !== null) setActiveTab(Number(params.get('tab')));
        fetchEmployees();
    }, []);

    useEffect(() => {
        getProjects();
    }, [activeTab]);

    const addEmployeeOnTeam = useCallback(
        async (body) => {
            try {
                await API.post('/employeeTeams', body);
            } catch (err) {
                console.error(err);
            } finally {
                await getProjects();
            }
        },
        [getProjects]
    );

    const deleteEmployeeFromTeam = useCallback(
        async (body) => {
            try {
                await API.delete('/employeeTeams', { data: body });
            } catch (err) {
                console.error(err);
            } finally {
                await getProjects();
            }
        },
        [getProjects]
    );

    return (
        canView && (
            <React.Fragment>
                <Helmet>
                    <title>Planning overview</title>
                </Helmet>

                <Header
                    pageTitle="Planning overview"
                    toolbar={<Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />}
                    action={
                        canAll && (
                            <>
                                {activeTab === 0 ? (
                                    <Button
                                        color="secondary"
                                        startIcon={<AddIcon />}
                                        style={{ borderRadius: '999px' }}
                                        onClick={() =>
                                            history.push(
                                                internalActivity.base +
                                                    internalActivity.planning.base +
                                                    internalActivity.planning.create
                                            )
                                        }
                                    >
                                        {t('Create new project')}
                                    </Button>
                                ) : activeTab === 1 ? (
                                    <Button
                                        color="secondary"
                                        startIcon={<AddIcon />}
                                        style={{ borderRadius: '999px' }}
                                        onClick={() =>
                                            history.push({
                                                pathname: internalActivity.base + '/add-employee',
                                                state: { employeeType: 'internal' },
                                            })
                                        }
                                    >
                                        {t('Add new employee')}
                                    </Button>
                                ) : (
                                    <Button
                                        color="secondary"
                                        startIcon={<AddIcon />}
                                        style={{ borderRadius: '999px' }}
                                        onClick={() =>
                                            history.push({
                                                pathname: internalActivity.base + '/add-employee',
                                                state: { employeeType: 'external' },
                                            })
                                        }
                                    >
                                        {t('Add new external resource')}
                                    </Button>
                                )}
                            </>
                        )
                    }
                />

                <div className="page-container">
                    <GantContext.Provider
                        value={{
                            internalEmployees,
                            externalEmployees,
                            fetchEmployees,
                            addEmployeeOnTeam,
                            deleteEmployeeFromTeam,
                            type: 'internal',
                        }}
                    >
                        <React.Fragment>
                            {activeTab === 0 ? (
                                <>
                                    {projects.length > 0 && (
                                        <Gantt
                                            tasksRequest={'/employeeTask'}
                                            projects={projects}
                                            setProjects={setProjects}
                                            getProjects={getProjects}
                                        />
                                    )}
                                </>
                            ) : activeTab === 1 ? (
                                <React.Fragment>
                                    <Helmet>
                                        <title>
                                            {t('Internal activity')} | {t('Internal resources')}
                                        </title>
                                    </Helmet>
                                    <ViewEmployees
                                        moduleType="internalActivity"
                                        employees={internalEmployees}
                                        actionCallback={fetchEmployees}
                                        canEditEmploy={canAll ? true : false}
                                    />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Helmet>
                                        <title>
                                            {t('Internal activity')} | {t('External resources')}
                                        </title>
                                    </Helmet>
                                    <ViewEmployees
                                        moduleType="internalActivity"
                                        employees={externalEmployees}
                                        actionCallback={fetchEmployees}
                                        canEditEmploy={canAll ? true : false}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </GantContext.Provider>
                </div>
            </React.Fragment>
        )
    );
};

export default PlanningOverview;
