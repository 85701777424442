import React from 'react';

import PropTypes from 'prop-types';

const Tab = (props) => {
    const { children, index, activeTab, setActiveTab, small, disabled } = props;

    return (
        // eslint-disable-next-line
        <div
            onClick={() => setActiveTab(index)}
            className={`group overflow-hidden rounded-lg text-buttons-text transition-colors ${
                index === activeTab ? 'bg-primary-light' : 'bg-transparent'
            } ${disabled ? 'pointer-events-none bg-disabled' : ''}`}
        >
            <p
                className={`user-select-none whitespace-nowrap font-medium text-buttons-text transition-colors ${
                    small ? 'px-4 py-3' : 'px-6 py-4'
                } ${index !== activeTab && !disabled ? 'group-hover:text-primary-light' : ''} ${
                    !disabled && 'cursor-pointer '
                }`}
            >
                {children}
            </p>
        </div>
    );
};

Tab.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    index: PropTypes.number,
    activeTab: PropTypes.number,
    setActiveTab: PropTypes.func,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
};

Tab.defaultProps = {
    children: <></>,
    index: 0,
    activeTab: 0,
    setActiveTab: () => null,
    small: false,
    disabled: false,
};

export const Tabs = (props) => {
    const { activeTab, setActiveTab, tabs, small, disabled } = props;

    return (
        <div className="no-user-select-recursive relative inline-block">
            {/* {tabs.map((tab, index) => (
                    <div
                        // eslint-disable-next-line
                        key={`TAB-${index}`}
                        className={`last:mr-0 ${small ? 'mr-0.5' : 'mr-1'}`}
                    >
                        <Tab index={index} activeTab={activeTab} setActiveTab={setActiveTab} small={small}>
                            {tab}
                        </Tab>
                    </div>
                ))} */}
            <div className="text-main-text ">
                <div className="flex flex-wrap justify-center gap-2  rounded-xl bg-layout-transparent p-1 md:flex-row">
                    {tabs.map((tab, index) => (
                        <div
                            // eslint-disable-next-line
                            key={`TAB-${index}`}
                            className={`last:mr-0 ${small ? 'mr-0.5' : 'mr-1'}`}
                        >
                            <Tab
                                index={index}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                small={small}
                                disabled={disabled}
                            >
                                {tab}
                            </Tab>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
Tabs.propTypes = {
    activeTab: PropTypes.number,
    setActiveTab: PropTypes.func,
    tabs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element])),
    small: PropTypes.bool,
    disabled: PropTypes.bool,
};
Tabs.defaultProps = {
    activeTab: 0,
    setActiveTab: () => null,
    tabs: [],
    small: false,
    disabled: false,
};
