//eslint-disable-next-line

import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import currentDayHighlightPath from '../assets/frappe-gantt/svgs/current-day-highlight.svg';

import GanttLegend from 'components/frappe-gantt/gantt-legend';
import GanttTable from 'components/frappe-gantt/gantt-table';
import { toCanvas } from 'html-to-image';
import i18n from 'i18next';
import { defaultTheme, getRawFileFromPath, rgbToHex } from 'utils';
import { setMilestonesContent } from 'utils/notificationsUtils';

import Gantt from '../components/frappe-gantt/frappe-gantt';
import { handleElementExport, handleMultiPageExport } from './exportUtils';
import { getTasks } from './ganttUtils';

/**
 * The `generateNotificationData` function generates notification data for an email and PDF document based on the
 * provided parameters and returns a Promise that resolves to the generated PDF.
 * @param {object} tenant - The object used to extract all the data needed about the company.
 * @param {String} viewMode - The view mode of the gantt chart. It determines how the chart is displayed, such
 * as day, week, month, etc.
 * @param {Array} selectedMilestones - An array of selected milestones.
 * @param {Array} allMilestones - An array of all milestones available.
 * @param {String} purpose - The purpose parameter is a string that
 * represents what type of info will be used for the email data (PM or CRM)
 * @param {object} info - The `info` parameter is an object that contains
 * information about the the Company or Project used for the export (CRM or PM)
 * @param {Function} callback - The `callback` parameter is a function that will be called with the generated
 * content HTML as its argument. It is used to handle the generated content and perform any necessary
 * actions, such as displaying it on the page or component.
 * @param {String} splitImage - The `splitImage` parameter determines how the exported PDF will
 * handle multiple pages. It can have two possible values: multi-page for a PDF with multiple pages and single-page for a one page PDF
 * @returns The function `generateNotificationData` returns a Promise that resolves to a PDF file.
 */
const generateNotificationData = async (
    pageOrientation = 'landscape',
    returnsOnlyData = false,
    tenant,
    viewMode,
    selectedMilestones,
    allMilestones,
    purpose,
    info,
    callback,
    splitImage = 'multi-page'
) => {
    try {
        // ? We create a delay in order to wait for some UI elements to display (loaders or others)
        await new Promise((resolve) => setTimeout(resolve, 250));

        // ? At the end we return a promise that we will be used to create the web worker where the notification data and PDF file will be created
        return new Promise((resolve, reject) => {
            const worker = new Worker('../workers/generateNotificationData.worker', {
                name: 'generateNotificationData',
                type: 'module',
            });

            // ? We generate the html content needed for the notification
            const contentHtml = setMilestonesContent({
                selectedMilestones,
                allMilestones,
                purpose,
                info,
            });

            // ? We create the array of tasks needed for the gantt
            const { tasks, disabledCropHeightsIntervals } = (() => {
                const filteredMilestones = [];

                for (const selectedMilestone of selectedMilestones) {
                    filteredMilestones.push(allMilestones[selectedMilestone]);
                }

                const [tasks, disabledCropHeightsIntervals] = getTasks(filteredMilestones);

                return { tasks, disabledCropHeightsIntervals };
            })();

            // ? We create the images for the gantt and send them to the worker
            (async () => {
                // ? We get the row SVG file from the path in order to render it in the gantt highlight
                const currentDayHighlightEl = await getRawFileFromPath(currentDayHighlightPath);

                // ? We get the table container and we render the component to it
                const ganttTable = document.getElementById('notify-gantt-table-root');
                render(<GanttTable tasks={tasks} />, ganttTable);

                // ? We get the legend container and we render the component to it
                const ganttLegend = document.getElementById('notify-gantt-legend');
                render(<GanttLegend />, ganttLegend);

                // ? We get the gantt container
                const ganttContent = document.getElementById('notify-gantt-container-root');

                // ? We create and render the gantt
                new Gantt(ganttContent, tasks, {
                    language: i18n.resolvedLanguage,
                    start_date: null,
                    end_date: null,
                    view_mode: viewMode,
                    current_day_highlight_element: currentDayHighlightEl,
                });

                // ? We get the wrapper container witch includes the table and gantt elements
                const ganttWrapper = document.getElementById('notify-gantt-wrapper-root');

                // ? We add the gantt-wrapper class in order to add the border around the wrapper
                ganttWrapper.classList.add('gantt-wrapper');

                let dataImages = [];

                // ? We extract the images into a variables and we skip the auto scale in order to maintain the aspect ratio of the images
                const ganttWrapperContent = await toCanvas(ganttWrapper, {
                    skipAutoScale: true,
                });
                const ganttWrapperLegend = await toCanvas(ganttLegend, {
                    skipAutoScale: true,
                });

                // ? In the case of a multi page export we split the big image in smaller images in order to fit them in different PDF pages
                if (splitImage === 'multi-page') {
                    const extractedImages = handleMultiPageExport(
                        ganttWrapperContent,
                        disabledCropHeightsIntervals,
                        pageOrientation
                    );
                    const extractedImage = handleElementExport(ganttWrapperLegend, pageOrientation);
                    dataImages = [...extractedImages, extractedImage];
                }

                // ? In the case of a single page export we get the images using the same function for both
                if (splitImage === 'single-page') {
                    const extractedImage = handleElementExport(ganttWrapperContent, pageOrientation);
                    const extractedSecondaryImage = handleElementExport(ganttWrapperLegend, pageOrientation);
                    dataImages = [extractedImage, extractedSecondaryImage];
                }

                // ? We must delete the pageBreak property on the last item of the array in order to avoid creating an empty page in the PDF
                delete dataImages.at(-1).pageBreak;

                // ? We remove the gantt-wrapper class, unmount and remove the generated elements in order to reset the containers to the initial state
                ganttWrapper.classList.remove('gantt-wrapper');
                unmountComponentAtNode(ganttTable);
                unmountComponentAtNode(ganttLegend);
                ganttContent.removeChild(ganttContent.firstChild);

                // ? We check if we only have to return the data and not the PDF
                if (returnsOnlyData) {
                    worker.terminate();
                    resolve({
                        contentHtml,
                        dataImages,
                    });
                }

                // ? Header object with the company details
                const headerDetails = {
                    name: tenant.companyName,
                    registerNo: tenant.companyRegisterNo,
                    cui: tenant.cui,
                    address: tenant.addressString,
                    phone: tenant.phoneNo,
                    email: tenant.email,
                };

                const colorsDetails = {};

                // ? If the company has at least one theme we will use that theme in order to get the PDF colors
                if (tenant.ThemeConfigurator.length > 0) {
                    const tenantTheme = JSON.parse(
                        tenant.ThemeConfigurator.find((theme) => theme.id === tenant.activeThemeId).theme
                    );

                    colorsDetails.mainText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-TEXT'].color.split(' '));
                    colorsDetails.linkText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-LINK-TEXT'].color.split(' '));
                    colorsDetails.footerText = rgbToHex(
                        ...tenantTheme['PDF-COLORS']['PDF-LAYOUT-TEXT'].color.split(' ')
                    );
                    colorsDetails.footerBackground = rgbToHex(
                        ...tenantTheme['PDF-COLORS']['PDF-LAYOUT-BACKGROUND'].color.split(' ')
                    );
                }
                // ? If the company doesn't have a theme yet we use the general default M2M theme
                else {
                    const parsedDefaultTheme = JSON.parse(defaultTheme.theme);

                    colorsDetails.mainText = rgbToHex(...parsedDefaultTheme['PDF-COLORS']['PDF-TEXT'].color.split(' '));
                    colorsDetails.linkText = rgbToHex(
                        ...parsedDefaultTheme['PDF-COLORS']['PDF-LINK-TEXT'].color.split(' ')
                    );
                    colorsDetails.footerText = rgbToHex(
                        ...parsedDefaultTheme['PDF-COLORS']['PDF-LAYOUT-TEXT'].color.split(' ')
                    );
                    colorsDetails.footerBackground = rgbToHex(
                        ...parsedDefaultTheme['PDF-COLORS']['PDF-LAYOUT-BACKGROUND'].color.split(' ')
                    );
                }

                // ? At the end we send a message to the worker with all the data received above in order to generate the PDF
                worker.postMessage({
                    dataImages,
                    headerDetails,
                    colorsDetails,
                    pageOrientation,
                    companyLogo: tenant.logoUrl,
                });
            })();

            // ? We generate the pdf file for the email and finalize the process
            worker.onmessage = (e) => {
                // ? At the end we call the callback, close the worker and resolve the promise
                callback?.(contentHtml);
                worker.terminate();

                // const link = document.createElement('a');
                // link.href = e.data;
                // link.setAttribute('download', `${new Date().toISOString().slice(0, 10)}.pdf`);
                // document.body.appendChild(link);
                // link.click();
                // link.parentNode.removeChild(link);

                resolve(e.data);
            };

            // ? If the actions result in a error we call the callback, close the worker and reject the promise
            worker.onerror = (e) => {
                console.error(e);
                callback?.();
                worker.terminate();
                reject(e);
            };
        });
    } catch (error) {
        console.error(error);
    }
};

export default generateNotificationData;
