import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button } from '@material-ui/core';

import Gantt from 'components/planning-overview/gantt/gantt';
import ExternalResources from 'components/resources/external-resources/external-resources';
import InternalResources from 'components/resources/internal-resources/internal-resources';
import GantContext from 'contexts/GantContext';
import UserContext from 'contexts/UserContext';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { projectInManagement } from 'routes';
import API from 'utils/axios';
import { getResources } from 'utils/getterFunctions';

const ProjectPlanningOverview = () => {
    const { t } = useTranslation();
    const tabs = useMemo(() => ['Planning Overview', t('Internal resources'), t('External resources')], [t]);
    const history = useHistory();
    const params = new URL(document.location).searchParams;
    const { projectId } = useParams();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const [activeTab, setActiveTab] = useState(0);
    const [project, setProject] = useState(null);
    const [internalEmployees, setInternalEmployees] = useState([]);
    const [externalEmployees, setExternalEmployees] = useState([]);

    const getProject = useCallback(async () => {
        try {
            const res = await API.get('/pm_projectPlanningOverview', {
                params: {
                    id: projectId,
                },
            });
            setProject([res.data.pm]);
        } catch (err) {
            console.error(err);
        }
    }, [projectId]);

    useEffect(() => {
        (async () => {
            await getProject();
        })();
    }, [activeTab]);

    const fetchEmployees = useCallback(async () => {
        const resources = await getResources('pm');
        setInternalEmployees(resources.internalEmployees);
        setExternalEmployees(resources.externalEmployees);
    }, []);

    useEffect(() => {
        const tab = params.get('tab');
        if (tab !== null) setActiveTab(Number(params.get('tab')));
        fetchEmployees();
    }, []);

    const addEmployeeOnTeam = useCallback(
        async (body) => {
            try {
                await API.post('/pmEmployeeTeams', body);
            } catch (err) {
                console.error(err);
            } finally {
                await getProject();
            }
        },
        [getProject]
    );

    const deleteEmployeeFromTeam = useCallback(
        async (body) => {
            try {
                await API.delete('/pmEmployeeTeams', { data: body });
            } catch (err) {
                console.error(err);
            } finally {
                await getProject();
            }
        },
        [getProject]
    );

    return (
        <>
            <Helmet>
                <title>PM Planning overview</title>
            </Helmet>

            <Header
                pageTitle="PM Planning Overview"
                // toolbar={<Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />}
                action={
                    activeTab === 0 ? (
                        <></>
                    ) : activeTab === 1 ? (
                        <>
                            {canAll && (
                                <Button
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    style={{ borderRadius: '999px' }}
                                    onClick={() =>
                                        history.push(
                                            projectInManagement.base +
                                                '/' +
                                                projectId +
                                                projectInManagement.internalResources.base +
                                                projectInManagement.internalResources.create
                                        )
                                    }
                                >
                                    {t('Add new employee')}
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            {canAll && (
                                <Button
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    style={{ borderRadius: '999px' }}
                                    onClick={() =>
                                        history.push(
                                            projectInManagement.base +
                                                '/' +
                                                projectId +
                                                projectInManagement.externalResources.base +
                                                projectInManagement.externalResources.create
                                        )
                                    }
                                >
                                    {t('Add new external resource')}
                                </Button>
                            )}
                        </>
                    )
                }
            />

            <GantContext.Provider
                value={{
                    internalEmployees,
                    externalEmployees,
                    fetchEmployees,
                    addEmployeeOnTeam,
                    deleteEmployeeFromTeam,
                    type: 'pm',
                    projectId,
                }}
            >
                {!!project && (
                    <>
                        {activeTab === 0 ? (
                            <Gantt
                                tasksRequest={'/pmEmployeeTasks'}
                                projects={project}
                                setProjects={setProject}
                                getProjects={getProject}
                            />
                        ) : activeTab === 1 ? (
                            <InternalResources />
                        ) : (
                            <ExternalResources />
                        )}
                    </>
                )}
            </GantContext.Provider>
        </>
    );
};

export default ProjectPlanningOverview;
