import React, { useContext, useState } from 'react';

import PhotoIcon from '@material-ui/icons/Photo';

import NoImage from '../../assets/common/no-image.png';

import { Button, InputAdornment, TextField } from '@material-ui/core';

import FileUploadContainer from 'components/shared/file-upload-container';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';
import { formatVATnumber, IBAN_REGEX, PHONE_NO_REGEX, uploadSingleFile } from 'utils/index';
import * as yup from 'yup';

import InitializeButtons from './initialize-buttons';
import InitializeStepInfo from './initialize-step-info';

const InitializeCompanyDetails = ({ handleIncrementStep }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { currencyTypes, setCurrencyObj, setReferenceCurrencyObj, tenant, setTenant } = useContext(GlobalContext);

    const [isUpdating, setIsUpdating] = useState(false);

    const [companyDetails, setCompanyDetails] = useState(() => {
        let currencySelection = null,
            secondaryCurrencySelection = null;

        if (tenant.CustomerCurrency.length > 0) {
            const currencyId = tenant.CustomerCurrency.at(-1).currencyId;
            const currencyIndex = currencyTypes.findIndex((c) => c.id === currencyId);
            const secondCurrencyId = tenant.CustomerCurrency.at(-1).secondCurrencyId;
            const secondCurrencyIndex = currencyTypes.findIndex((c) => c.id === secondCurrencyId);

            currencySelection = currencyIndex;
            secondaryCurrencySelection = secondCurrencyIndex;
        }

        return {
            id: tenant.id,
            companyRegisterNo: tenant.companyRegisterNo,
            cui: tenant.cui,
            addressString: tenant.addressString,
            phoneNo: tenant.phoneNo,
            administratorName: tenant.administratorName,
            percentageVAT: tenant.percentageVAT ?? 0,
            customerBankInfo: tenant.customerBankInfo.map((cbi) => ({ bank: cbi.bank, iban: cbi.iban })),
            currencySelection,
            secondaryCurrencySelection,
            logoUrl: tenant.logoUrl,
            iconUrl: tenant.iconUrl,
            logo: null,
            icon: null,
        };
    });

    const schema = yup.object().shape({
        currencyId: yup
            .string()
            .typeError(t('The nation currency is mandatory!'))
            .required(t('The nation currency is mandatory!')),
        secondCurrencyId: yup
            .string()
            .typeError(t('The reference currency is mandatory!'))
            .required(t('The reference currency is mandatory!')),
        logo: yup.mixed().required(t('The logo is mandatory!')),
        icon: yup.mixed().required(t('The icon is mandatory!')),
        customerBankInfo: yup
            .array()
            .of(
                yup
                    .object()
                    .shape({
                        bank: yup
                            .string()
                            .trim()
                            .typeError(t('The bank name is mandatory'))
                            .required(t('The bank name is mandatory'))
                            .min(3, t('The bank name must be at least 3 characters long!')),
                        iban: yup
                            .string()
                            .trim()
                            .typeError(t('IBAN account number one is mandatory'))
                            .required(t('IBAN account number one is mandatory'))
                            .matches(IBAN_REGEX, t('The IBAN account is not valid')),
                    })
                    .required(),
            )
            .min(1)
            .required(),
        administratorName: yup
            .string()
            .trim()
            .typeError(t('The company administrator field is mandatory'))
            .required(t('The company administrator field is mandatory'))
            .min(5, t('The company administrator name must be at least 5 characters long!')),
        phoneNo: yup
            .string()
            .trim()
            .typeError(t('The phone number field is mandatory'))
            .required(t('The phone number field is mandatory'))
            .matches(PHONE_NO_REGEX, t('The phone number is not valid'))
            .min(10, t('The phone number needs to contain at least 10 digits')),
        addressString: yup
            .string()
            .trim()
            .typeError(t('The company address is mandatory!'))
            .required(t('The company address is mandatory!')),
        cui: yup
            .string()
            .trim()
            .typeError(t('The company CUI/CIF is mandatory'))
            .required(t('The company CUI/CIF is mandatory')),
        companyRegisterNo: yup
            .string()
            .trim()
            .typeError(t('The company registration number is mandatory'))
            .required(t('The company registration number is mandatory')),
        percentageVAT: yup
            .number()
            .typeError(t('The VAT percentage is mandatory!'))
            .required(t('The VAT percentage is mandatory!')),
    });

    const handleChangeCompanyDetails = (e) =>
        setCompanyDetails((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));

    const handleChangeCompanyBankDetails = (e, index) =>
        setCompanyDetails((prev) => {
            const copyOfCustomerBankInfo = structuredClone(prev.customerBankInfo);

            if (!copyOfCustomerBankInfo[index])
                copyOfCustomerBankInfo[index] = {
                    bank: '',
                    iban: '',
                };

            return {
                ...prev,
                customerBankInfo: copyOfCustomerBankInfo.map((v, i) =>
                    i === index ? { ...v, [e.target.name]: e.target.value } : v,
                ),
            };
        });

    const handleLogoUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }

                handleChangeCompanyDetails({
                    target: {
                        name: 'logo',
                        value: blob,
                    },
                });
            },
            'image',
        );
    };

    const handleIconUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }

                handleChangeCompanyDetails({
                    target: {
                        name: 'icon',
                        value: blob,
                    },
                });
            },
            'image',
        );
    };

    const handleCompanyInitialization = async () => {
        try {
            setIsUpdating(true);

            const reqData = structuredClone(companyDetails);
            delete reqData.logoUrl;
            delete reqData.iconUrl;
            delete reqData.id;
            reqData.customerBankInfo = reqData.customerBankInfo.filter(
                (cbi) => !!cbi && (cbi.bank.trim() || cbi.iban.trim()),
            );

            await schema.validate({
                ...reqData,
                currencyId: currencyTypes[reqData.currencySelection].id,
                secondCurrencyId: currencyTypes[reqData.secondaryCurrencySelection].id,
                logo: reqData.logo ?? companyDetails.logoUrl,
                icon: reqData.icon ?? companyDetails.iconUrl,
            });

            delete reqData.logo;
            delete reqData.icon;
            delete reqData.currencySelection;
            delete reqData.secondaryCurrencySelection;

            const reqBody = new FormData();
            reqBody.append(
                'data',
                JSON.stringify({
                    ...reqData,
                    id: companyDetails.id,
                    initializationStep: 'CONFIGURATOR_CONTACTS',
                    companyName: tenant.companyName,
                    email: tenant.email,
                    activeThemeId: tenant.activeThemeId,
                    customerCurrency: {
                        currencyId: currencyTypes[companyDetails.currencySelection].id,
                        secondCurrencyId: currencyTypes[companyDetails.secondaryCurrencySelection].id,
                        type: 'PRIMARY',
                    },
                }),
            );

            if (companyDetails.logo) reqBody.append('logo', companyDetails.logo);
            if (companyDetails.icon) reqBody.append('icon', companyDetails.icon);

            const res = await API.put('/tenant', reqBody, { 'Content-Type': 'multipart/form-data' });

            setCurrencyObj(currencyTypes[companyDetails.currencySelection]);
            setReferenceCurrencyObj(currencyTypes[companyDetails.secondaryCurrencySelection]);
            setTenant(res.data.tenant);
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <>
            <InitializeStepInfo
                mainInfo={[
                    {
                        id: 0,
                        content: t(
                            'The currencies and VAT are mandatory to be set in this step and will be used in next steps and are needed for a lot of functionalities in the application',
                        ),
                    },
                    {
                        id: 1,
                        content: t(
                            'Uncompleted company details, logo and icon are mandatory because they are needed to complete the details for an exported document',
                        ),
                    },
                ]}
                className="mb-10"
            />
            <div className="flex flex-row md:flex-col">
                <div className="grid w-1/2 grid-cols-2 gap-10 lg:w-full lg:grid-cols-1 ">
                    <div className="rounded-md p-6" style={{ border: '1px solid rgb(var(--base-layout-lighter))' }}>
                        <h4 className="mb-5">{t('Company details')}</h4>
                        <div className="mb-5">
                            <TextField
                                name="companyRegisterNo"
                                label={t('Trade Register Code')}
                                placeholder="ex: j22/3321/2017"
                                value={companyDetails.companyRegisterNo}
                                onChange={handleChangeCompanyDetails}
                                disabled={isUpdating}
                            />
                        </div>
                        <div className="mb-5">
                            <TextField
                                name="cui"
                                label="CUI/CIF"
                                placeholder="ex: RO31422664"
                                value={companyDetails.cui}
                                onChange={handleChangeCompanyDetails}
                                disabled={isUpdating}
                            />
                        </div>
                        <div className="mb-5">
                            <TextField
                                name="addressString"
                                label={t('Company address')}
                                placeholder={t('ex:county,city,street,nr, etc')}
                                value={companyDetails.addressString}
                                onChange={handleChangeCompanyDetails}
                                disabled={isUpdating}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="rounded-md p-6" style={{ border: '1px solid rgb(var(--base-layout-lighter))' }}>
                            <h4 className="mb-5">{'Administrator Details'}</h4>
                            <div className="mb-5">
                                <TextField
                                    name="administratorName"
                                    label={t('Administrator / Associate name')}
                                    placeholder={t('Administrator/Associate Name')}
                                    value={companyDetails.administratorName}
                                    onChange={handleChangeCompanyDetails}
                                    disabled={isUpdating}
                                />
                            </div>
                            <div className="mb-5">
                                <TextField
                                    name="phoneNo"
                                    label={t('Phone number')}
                                    placeholder="0753176913"
                                    value={companyDetails.phoneNo}
                                    onChange={handleChangeCompanyDetails}
                                    disabled={isUpdating}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rounded-md p-6" style={{ border: '1px solid rgb(var(--base-layout-lighter))' }}>
                        <h4 className="mb-5">{t('Bank/Banks Details')}</h4>
                        <div className="mb-5">
                            <TextField
                                name="bank"
                                label={`${t('Bank name')} I`}
                                placeholder="ex: Banca Transilvania"
                                value={companyDetails.customerBankInfo?.[0]?.bank}
                                onChange={(e) => handleChangeCompanyBankDetails(e, 0)}
                                disabled={isUpdating}
                            />
                        </div>
                        <div className="mb-5">
                            <TextField
                                name="iban"
                                label={`${t('IBAN account')} I`}
                                placeholder="ex: RO91BTRLRONCRT0421401234"
                                value={companyDetails.customerBankInfo?.[0]?.iban}
                                onChange={(e) => handleChangeCompanyBankDetails(e, 0)}
                                disabled={isUpdating}
                            />
                        </div>
                        <div className="mb-5">
                            <TextField
                                name="bank"
                                label={`${t('Bank name')} II`}
                                placeholder="ex: Banca Transilvania"
                                value={companyDetails.customerBankInfo?.[1]?.bank}
                                onChange={(e) => handleChangeCompanyBankDetails(e, 1)}
                                disabled={isUpdating}
                            />
                        </div>
                        <div className="mb-5">
                            <TextField
                                name="iban"
                                label={`${t('IBAN account')} II`}
                                placeholder="ex: RO91BTRLRONCRT0421401234"
                                value={companyDetails.customerBankInfo?.[1]?.iban}
                                onChange={(e) => handleChangeCompanyBankDetails(e, 1)}
                                disabled={isUpdating}
                            />
                        </div>
                        <div className="mb-5">
                            <TextField
                                name="bank"
                                label={`${t('Bank name')} III`}
                                placeholder="ex: Banca Transilvania"
                                value={companyDetails.customerBankInfo?.[2]?.bank}
                                onChange={(e) => handleChangeCompanyBankDetails(e, 2)}
                                disabled={isUpdating}
                            />
                        </div>
                        <div className="mb-5">
                            <TextField
                                name="iban"
                                label={`${t('IBAN account')} III`}
                                placeholder="ex: RO91BTRLRONCRT0421401234"
                                value={companyDetails.customerBankInfo?.[2]?.iban}
                                onChange={(e) => handleChangeCompanyBankDetails(e, 2)}
                                disabled={isUpdating}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col gap-10">
                        <div className="rounded-md p-6" style={{ border: '1px solid rgb(var(--base-layout-lighter))' }}>
                            <h4 className="mb-5">{t('Currency/VAT details')}</h4>
                            <div>
                                <div className="mb-5">
                                    <LabelWrapper label={t('Select national currency')}>
                                        <Dropdown
                                            disabled={isUpdating}
                                            options={currencyTypes.map((ct) => ct.currency)}
                                            selectedOption={companyDetails.currencySelection}
                                            setSelectedOption={(value) =>
                                                handleChangeCompanyDetails({
                                                    target: {
                                                        name: 'currencySelection',
                                                        value,
                                                    },
                                                })
                                            }
                                        />
                                    </LabelWrapper>
                                </div>

                                <div className="mb-5">
                                    <LabelWrapper label={t('Select reference currency')}>
                                        <Dropdown
                                            disabled={isUpdating}
                                            options={currencyTypes.map((ct) => ct.currency)}
                                            selectedOption={companyDetails.secondaryCurrencySelection}
                                            setSelectedOption={(value) =>
                                                handleChangeCompanyDetails({
                                                    target: {
                                                        name: 'secondaryCurrencySelection',
                                                        value,
                                                    },
                                                })
                                            }
                                        />
                                    </LabelWrapper>
                                </div>

                                <div className="mb-5">
                                    <LocaleTextField
                                        name="percentageVAT"
                                        label={t('VAT rate')}
                                        placeholder="19%"
                                        value={companyDetails.percentageVAT}
                                        onChange={(e) => {
                                            handleChangeCompanyDetails({
                                                target: {
                                                    name: e.target.name,
                                                    value: formatVATnumber(e.target.value),
                                                },
                                            });
                                        }}
                                        disabled={isUpdating}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ml-10 flex flex-col gap-10 md:ml-0">
                    <div className="rounded-md p-6" style={{ border: '1px solid rgb(var(--base-layout-lighter))' }}>
                        <h4 className="mb-5 mt-0 md:mt-10">{t("Company's logo and icon")}</h4>
                        <div>
                            <LabelWrapper label={t('Company logo')}>
                                <div className="mb-5 w-full max-w-xs">
                                    <img
                                        src={companyDetails.logoUrl || NoImage}
                                        className="w-full rounded-md p-2.5"
                                        style={{
                                            border: '1px solid var(--layout-transparent)',
                                        }}
                                    />
                                </div>

                                <div className="mb-5">
                                    <FileUploadContainer onUpload={handleLogoUpload} disabled={isUpdating}>
                                        <Button fullWidth startIcon={<PhotoIcon />} disabled={isUpdating}>
                                            {(() => {
                                                if (companyDetails.logoUrl !== null && companyDetails.logo === null) {
                                                    return t('Change company logo');
                                                } else if (companyDetails.logo) {
                                                    return companyDetails.logo.name.substr(0, 10) + '...';
                                                }
                                            })()}
                                        </Button>
                                    </FileUploadContainer>
                                </div>
                            </LabelWrapper>
                            <LabelWrapper label={t('Company icon')}>
                                <div className="mb-5 w-full max-w-xs">
                                    <img
                                        src={companyDetails.iconUrl || NoImage}
                                        className="w-full rounded-md p-2.5"
                                        style={{
                                            border: '1px solid var(--layout-transparent)',
                                        }}
                                    />
                                </div>

                                <div className="mb-5">
                                    <FileUploadContainer onUpload={handleIconUpload} disabled={isUpdating}>
                                        <Button fullWidth startIcon={<PhotoIcon />} disabled={isUpdating}>
                                            {(() => {
                                                if (companyDetails.iconUrl !== null && companyDetails.icon === null) {
                                                    return t('Change company icon');
                                                } else if (companyDetails.icon) {
                                                    return companyDetails.icon.name.substr(0, 10) + '...';
                                                }
                                            })()}
                                        </Button>
                                    </FileUploadContainer>
                                </div>
                            </LabelWrapper>
                        </div>
                    </div>
                </div>
            </div>

            <InitializeButtons
                className={'mt-20 flex justify-start'}
                nextDisabled={isUpdating}
                canSkip={false}
                handleIncrementStep={() => handleIncrementStep(false)}
                handleIncrementCallback={handleCompanyInitialization}
            />
        </>
    );
};

InitializeCompanyDetails.propTypes = {
    handleIncrementStep: PropTypes.func,
};

InitializeCompanyDetails.defaultProps = {
    handleIncrementStep: () => {},
};

export default InitializeCompanyDetails;
