import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import Activity from 'components/internal-activity/activity';
import ActivityModal from 'components/internal-activity/activity-modal';
import EmailCreator from 'components/internal-activity/email-creator';
import CustomModal from 'components/modals/custom_modal';
import FileDisplay from 'components/shared/file-display/file-display';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import ActivityContext from 'contexts/ActivityContext';
import UserContext from 'contexts/UserContext';
import useScreenSizes from 'hooks/useScreenSizes.js';
import { useSnackbar } from 'notistack';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm } from 'routes';
import { formatDate } from 'utils';
import API from 'utils/axios';
import { getContactById, getDepartments, getEntityFields } from 'utils/getterFunctions';

const ContactActivity = () => {
    const { t } = useTranslation();
    const { id: contactId } = useParams();
    const history = useHistory();
    const { user } = useContext(UserContext);
    const [departments, setDepartments] = useState([]);
    const [contact, setContact] = useState(null);
    const [contactFields, setContactFields] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const [activityForEdit, setActivityForEdit] = useState(null);
    const [width] = useScreenSizes();
    const [showClientInfo, setShowClientInfo] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '5',
            permissionType: 'ALL',
        },
    ]);

    const canViewDocuments = checkPerm([
        {
            permissionId: '26',
            permissionType: 'VIEW',
        },
    ]);

    const canEditDocuments = checkPerm([
        {
            permissionId: '26',
            permissionType: 'ALL',
        },
    ]);

    const canNotify = checkPerm([
        {
            permissionId: '25',
            permissionType: 'VIEW',
        },
    ]);

    //Send notification states
    const [emailUsers, setEmailUsers] = useState([]);
    const [emailModalStatus, setEmailModalStatus] = useState(false);
    const [initialMilestone, setInitialMilestone] = useState(null);
    const [milestones, setMilestones] = useState([]);

    const handleNotify = (milestone) => {
        setInitialMilestone(milestone);
        setEmailModalStatus(true);
    };

    const getEmailUsers = async () => {
        try {
            const [tenantUsers, clientAccounts] = await Promise.all([
                API.get('/tenant'),
                API.get('/contacts', {
                    params: {
                        currentPage: 0,
                        perPage: 99999,
                        pagesToLoad: 1,
                        type: 'ALL',
                    },
                }),
            ]);

            const formattedClients = clientAccounts.data.data.contacts.map((client) => {
                const clientFields = typeof client.data === 'string' ? JSON.parse(client.data) : client.data;
                const profile = Object.assign({}, ...Object.values(clientFields));
                client.email = profile.email;
                delete profile.email;
                delete client.data;

                return { ...client, profile };
            });

            setEmailUsers([...tenantUsers.data.data.tenantAccounts, ...formattedClients]);
        } catch (err) {
            console.error(err);
        }
    };

    /*
        Filters
        Status: todo, done, all
        Type: comment, task, email...
   */
    const [status, setStatus] = useState('all');
    const [activityType, setActivityType] = useState('ALL');

    const handleTypeChange = (event) => {
        setActivityType(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    /*
        Variables for manipulating activity modal
        On create: createActivity is true
        On update: updatedActivity is the activity itself
    */
    const [isCreating, setIsCreating] = useState(false);
    const [createActivity, setCreateActivity] = useState(false);

    const submitNewActivity = async (reqBody) => {
        if (isCreating) return;

        try {
            setIsCreating(true);
            await API.post('/contact_activities', reqBody);
            enqueueSnackbar(t('Activity added successfully!'), { variant: 'success' });
            setCreateActivity(false);
        } catch (err) {
            console.error(err);
        } finally {
            setIsCreating(false);
            fetchActivities();
        }
    };

    const editActivity = async (reqBody) => {
        if (isCreating) return;

        try {
            setIsCreating(true);
            await API.put('/contact_activities', reqBody);
            enqueueSnackbar(t('Activity was edited successfully!'), { variant: 'success' });
            setActivityForEdit(null);
            setCreateActivity(false);
        } catch (err) {
            console.error(err);
        } finally {
            setIsCreating(false);
            fetchActivities();
        }
    };

    /*
        Fetching data periodically, and separating all activities in 2 arrays
        Update occurs only if differences are spotted
    */
    const [loadingActivities, setLoadingActivities] = useState(false);
    const [todoActivities, setTodoActivities] = useState([]);
    const [doneActivities, setDoneActivities] = useState([]);

    const fetchActivities = async () => {
        if (loadingActivities) return;

        const params = {
            currentPage: 0,
            perPage: 9999,
            pagesToLoad: 1,
            id: contactId,
        };

        const res = await API.get('/contacts_activities', { params });

        let activities = res.data.activities;

        if (!canViewDocuments) {
            activities = activities.filter(
                (activity) => activity.actionType !== 'DOCUMENTS' && activity.actionType !== 'FILES',
            );
        }

        const milestoneActivities = activities.filter((activity) => activity.activitiesTasks.length > 0);
        setMilestones(milestoneActivities);

        const todoFiltered = [
            ...activities.filter(
                (a) =>
                    a.status === 'TODO' &&
                    (activityType === 'ALL' || activityType === a.actionType) &&
                    (selectedDepartment === 0 || a.departmentId === departments[selectedDepartment - 1].id),
            ),
        ];

        if (JSON.stringify(todoFiltered) !== JSON.stringify(todoActivities)) {
            setTodoActivities(todoFiltered);
        }

        const doneFiltered = [
            ...activities.filter(
                (a) =>
                    a.status === 'DONE' &&
                    (activityType === 'ALL' || activityType === a.actionType) &&
                    (selectedDepartment === 0 || a.departmentId === departments[selectedDepartment - 1].id),
            ),
        ];
        if (JSON.stringify(doneFiltered) !== JSON.stringify(doneActivities)) {
            setDoneActivities(doneFiltered);
        }
    };

    /*
        CRUD
        1. Delete
    */
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteActivity = async (activity) => {
        if (user.id !== activity.authorId) return;
        if (isDeleting) return;

        setIsDeleting(true);

        try {
            await API.delete('contact_activities', {
                data: {
                    id: activity.id,
                },
            });
        } catch (err) {
            console.error(err);
        } finally {
            setIsDeleting(false);
            fetchActivities();
        }
    };

    /*
        2. Update
    */
    const [isUpdating, setIsUpdating] = useState(false);

    const updateActivity = async (activity, newValue) => {
        if (user.id !== activity.authorId) return;
        if (isUpdating) return;

        setIsUpdating(true);

        try {
            await API.post('/mark_activity', {
                id: activity.id,
                status: newValue,
            });
        } catch (err) {
            console.error(err);
        } finally {
            setIsUpdating(false);
            fetchActivities();
        }
    };

    /**
     * Fetch departments
     */
    useEffect(() => {
        (async () => {
            const deps = await getDepartments();
            const contact = await getContactById(contactId);
            const contactFields = await getEntityFields('Contacts');
            setContactFields(contactFields.objectToRender);
            if (!contact) {
                history.push(crm.base + crm.contacts.base + crm.contacts.read);
            }

            setDepartments(deps);
            setContact(contact);
        })();
    }, []);

    useEffect(() => {
        const getData = async () => {
            setLoadingActivities(true);
            await Promise.allSettled([fetchActivities(), getEmailUsers()]);
            setLoadingActivities(false);
        };

        if (departments.length > 0) {
            getData();
        } else {
            setLoadingActivities(false);
        }
    }, [departments, activityType, selectedDepartment]);

    useEffect(() => {
        if (width <= 1500 && createActivity && showClientInfo) setShowClientInfo(false);
    }, [width]);

    return (
        <>
            <Helmet>
                <title>CRM | {t('contact-activity')}</title>
            </Helmet>

            <Header
                pageTitle={selectedDepartment !== 0 ? departments[selectedDepartment - 1].name : t('contact-activity')}
                action={
                    <Fragment>
                        <div className={`duration-350 transform opacity-100 transition-all`}>
                            <Button
                                startIcon={<VisibilityIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={() => {
                                    setShowClientInfo((prev) => !prev);
                                }}
                            >
                                {showClientInfo ? t('Hide client info') : t('Show client info')}
                            </Button>
                        </div>

                        {canNotify && (
                            <Button
                                startIcon={<EmailIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={() => {
                                    setEmailModalStatus((prev) => !prev);
                                }}
                            >
                                {t('Notify')}
                            </Button>
                        )}

                        <div
                            className={`duration-350 transform transition-all ${
                                createActivity ? 'translate-x-8 opacity-0' : 'translate-x-0 opacity-100'
                            }`}
                        >
                            {canAll && (
                                <Button
                                    startIcon={<AddIcon />}
                                    color="secondary"
                                    style={{ borderRadius: '999px' }}
                                    onClick={() => {
                                        setCreateActivity((prev) => !prev);
                                    }}
                                >
                                    {t('Add activity')}
                                </Button>
                            )}
                        </div>
                    </Fragment>
                }
                toolbar={
                    <Tabs
                        tabs={['Toate', ...(departments?.map((d) => d.name) ?? [])]}
                        activeTab={selectedDepartment}
                        setActiveTab={setSelectedDepartment}
                    />
                }
                toolbarSecondary={
                    <div className="flex flex-col">
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="status"
                                name="status-filter"
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <div className="no-user-select-recursive flex items-center">
                                    <FormControlLabel value="all" control={<Radio />} label={t('All')} />
                                    <FormControlLabel value="todo" control={<Radio />} label={t('To do')} />
                                    <FormControlLabel value="done" control={<Radio />} label={t('Done')} />
                                </div>
                            </RadioGroup>
                        </FormControl>

                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="activity-type"
                                name="activity-type-filter"
                                value={activityType}
                                onChange={handleTypeChange}
                            >
                                <div className="no-user-select-recursive flex items-center">
                                    <FormControlLabel value="ALL" control={<Radio />} label={t('All')} />
                                    <FormControlLabel value="COMMENT" control={<Radio />} label={t('Comments')} />
                                    <FormControlLabel value="TASK" control={<Radio />} label={'Tasks'} />
                                    <FormControlLabel value="FILES" control={<Radio />} label={t('Files')} />
                                    <FormControlLabel value="DOCUMENTS" control={<Radio />} label={t('Documents')} />

                                    {/* <FormControlLabel
                                        value="INVOICES_AND_BILLS"
                                        control={<Radio  />}
                                        label="Facturi/Proforme"
                                    /> */}
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                }
            />

            <div className="page-container">
                {/* 
                    Timeline
                 */}
                <ActivityContext.Provider
                    value={{
                        isCreating,
                        setIsCreating,
                        submitNewActivity,
                        fetchActivities,
                        isContact: true,
                        setActivityForEdit,
                        activityForEdit,
                        editActivity,
                    }}
                >
                    <div className="relative z-40 flex items-start justify-center gap-12">
                        {contact !== null && showClientInfo && (
                            <div className="sticky top-8 z-50 whitespace-nowrap rounded-md bg-layout-transparent 2xl:fixed 2xl:left-2/4 2xl:top-2/4 2xl:-translate-x-2/4 2xl:-translate-y-2/4 2xl:transform 2xl:bg-layout-light">
                                <div className="mb-5 flex items-center justify-between px-10 pt-10 sm:px-4 sm:pt-4">
                                    <h2 className="font-semibold">{t('Contact information')}</h2>
                                    <CloseIcon
                                        className="ml-10 cursor-pointer text-primary-main opacity-50 hover:opacity-100"
                                        style={{ fontSize: '2.5rem', transition: 'opacity .2s ease' }}
                                        onClick={() => {
                                            setShowClientInfo((prev) => !prev);
                                        }}
                                    />
                                </div>
                                {(() => {
                                    const parsedData = JSON.parse(contact.data);
                                    return (
                                        <div
                                            className="overflow-y-auto px-4 py-8"
                                            style={{
                                                minWidth: '300px',
                                                width: width > 600 ? 'min-content' : '90vw',
                                                maxHeight: width > 1500 ? '85vh' : '70vh',
                                            }}
                                        >
                                            {contactFields.map((column) => {
                                                const body = column.fields.map((field) => {
                                                    return (
                                                        <div key={field.name} className="mb-2 text-base text-main-text">
                                                            {!['Text', 'Phone', 'Email', 'Number'].indexOf(
                                                                field.type,
                                                            ) >= 0
                                                                ? (() => {
                                                                      const checkForValue =
                                                                          column.name in parsedData &&
                                                                          field.name in parsedData[column.name];

                                                                      if (checkForValue) {
                                                                          const value =
                                                                              parsedData[column.name][field.name];
                                                                          const isArray = Array.isArray(
                                                                              parsedData[column.name][field.name],
                                                                          );
                                                                          const fileType = field.type;

                                                                          if (isArray && fileType === 'File')
                                                                              return (
                                                                                  <>
                                                                                      <br />
                                                                                      <FileDisplay
                                                                                          utilityOptions={{
                                                                                              canDelete: false,
                                                                                              canCopyLink: true,
                                                                                              canDownload: true,
                                                                                          }}
                                                                                          files={value}
                                                                                          purpose={fileType}
                                                                                          view={true}
                                                                                      />
                                                                                  </>
                                                                              );

                                                                          if (isArray && fileType === 'Image')
                                                                              return (
                                                                                  <>
                                                                                      <br />
                                                                                      <FileDisplay
                                                                                          files={value}
                                                                                          purpose={fileType}
                                                                                          view={true}
                                                                                      />
                                                                                  </>
                                                                              );

                                                                          if (!isArray && fileType === 'Date')
                                                                              return (
                                                                                  <span className="font-semibold text-main-text">
                                                                                      {formatDate(value)}
                                                                                  </span>
                                                                              );

                                                                          if (!isArray)
                                                                              return (
                                                                                  <span className="font-semibold text-main-text">
                                                                                      {value}
                                                                                  </span>
                                                                              );
                                                                      } else {
                                                                          return <></>;
                                                                      }
                                                                  })()
                                                                : ''}
                                                        </div>
                                                    );
                                                });
                                                return (
                                                    <div key={column.name} className="mb-10 last:mb-0">
                                                        <h4 className="mb-5 border-b-2 border-layout-transparent pb-2 text-2xl">
                                                            {column.displayName}
                                                        </h4>
                                                        {body}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })()}
                            </div>
                        )}

                        <div
                            className="relative z-40 flex w-full flex-col items-center pl-24 md:pl-32 sm:pl-0"
                            style={{ maxWidth: '1000px' }}
                        >
                            {loadingActivities && <CircularProgress />}
                            {/* Activities Timeline */}
                            {(todoActivities.length > 0 || doneActivities.length > 0) && (
                                <>
                                    {(((status === 'all' || status === 'todo') && todoActivities.length > 0) ||
                                        isCreating) && (
                                        <div className="relative w-full border-l-2 border-layout-lighter py-8 pl-8 sm:pl-4">
                                            <h2 className="mb-12">{t('to-do')}</h2>

                                            {/*
                                                    Creating loading placeholder
                                                */}
                                            <div
                                                className={`pointer-events-none flex w-full items-center justify-center overflow-hidden rounded-md bg-layout-transparent transition-all duration-300 ${
                                                    isCreating ? 'opacity-1 mb-10 h-48' : 'mb-0 h-0 opacity-0'
                                                }`}
                                            >
                                                <CircularProgress />
                                            </div>

                                            {todoActivities.map((a) => (
                                                <Activity
                                                    key={a.id}
                                                    activity={a}
                                                    deleteActivity={deleteActivity}
                                                    updateActivity={updateActivity}
                                                    check={canAll ? true : false}
                                                    actionArrow={{
                                                        edit: canAll ? true : false,
                                                        notify:
                                                            canNotify && a.activitiesTasks.length > 0 ? true : false,
                                                    }}
                                                    onNotify={handleNotify}
                                                    deleteIcon={canAll ? true : false}
                                                    commentView={canAll ? true : false}
                                                    milestoneCheck={canAll ? true : false}
                                                    userLink={canAll ? true : false}
                                                    setEditActivity={() => {
                                                        setCreateActivity(true);
                                                        setActivityForEdit(a);
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    )}

                                    {(status === 'all' || status === 'done') && doneActivities.length && (
                                        <div className="relative w-full border-l-2 border-layout-light py-8 pl-8 sm:pl-4">
                                            <h2 className="mb-12">{t('Done')}</h2>
                                            {doneActivities.map((a) => (
                                                <Activity
                                                    key={a.id}
                                                    activity={a}
                                                    deleteActivity={deleteActivity}
                                                    updateActivity={updateActivity} // To actually edit activity in the backend
                                                />
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}

                            {!todoActivities.length && !doneActivities.length && !loadingActivities && (
                                <NoDataPlaceholder />
                            )}
                        </div>

                        {/* New Activity Modal */}
                        {canAll && createActivity && (
                            <div className="sticky top-8 z-50 ml-8 rounded-md bg-layout-transparent 2xl:fixed 2xl:left-2/4 2xl:top-2/4 2xl:ml-0 2xl:-translate-x-2/4 2xl:-translate-y-2/4 2xl:transform 2xl:bg-layout-lighter">
                                <ActivityModal
                                    close={() => setCreateActivity(false)}
                                    contactId={contactId}
                                    selectedDepartment={selectedDepartment}
                                    editDocuments={canEditDocuments ? true : false}
                                />
                            </div>
                        )}
                        <div
                            className={`fixed left-0 top-0 z-40 hidden h-svh w-screen ${
                                createActivity || showClientInfo ? '2xl:flex' : ''
                            }`}
                            style={{ backgroundColor: 'rgba(0,0,0,0.3)', backdropFilter: 'blur(5px)' }}
                            onClick={() => {
                                setCreateActivity(false);
                                setShowClientInfo(false);
                            }}
                        />

                        {emailModalStatus && (
                            <CustomModal
                                open={emailModalStatus}
                                setOpen={setEmailModalStatus}
                                onClose={() => {
                                    setInitialMilestone(null);
                                }}
                            >
                                <EmailCreator
                                    cancel={() => {
                                        setInitialMilestone(null);
                                        setEmailModalStatus(false);
                                    }}
                                    users={emailUsers}
                                    emailReplyToUsers={emailUsers.filter((value) => value.id === user.id)}
                                    milestones={milestones}
                                    setEmailModalStatus={setEmailModalStatus}
                                    purpose="CRM"
                                    initialMilestone={initialMilestone}
                                />
                            </CustomModal>
                        )}
                    </div>
                </ActivityContext.Provider>
            </div>
        </>
    );
};

export default ContactActivity;
