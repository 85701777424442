import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, TableCell } from '@material-ui/core';

import PrpTypes from 'prop-types';
import { ExpandableRow } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { projectInManagement, resourceModule } from 'routes';
import { formatDate, formatTime } from 'utils';

const AllocationRow = ({ allocation }) => {
    const optionClass = 'text-base text-dark-text mb-2';
    const optionSpanClass = 'text-main-text font-bold';

    const history = useHistory();
    const { t } = useTranslation();

    const handleEdit = () => history.push(resourceModule.allocationResources.edit + '/' + allocation.id);
    const handleViewProject = () =>
        history.push(
            projectInManagement.base + '/' + allocation.PmProject.id + projectInManagement.planingOverviewOnProject.base
        );
    const handleViewResponsible = () => history.push(resourceModule.humanResources.edit + '/' + allocation.id);

    return (
        <ExpandableRow
            colSpan={7}
            row={
                <Fragment>
                    <TableCell>{allocation.MaterialResource.name}</TableCell>
                    <TableCell>{allocation.quantity}</TableCell>
                    <TableCell>{allocation.status}</TableCell>
                    <TableCell>{allocation.Responsible.name}</TableCell>
                    <TableCell>{allocation?.PmProject?.name ?? '-'}</TableCell>
                </Fragment>
            }
            collapse={
                <div className="flex flex-col gap-10">
                    <div className="flex flex-wrap justify-between gap-4">
                        <Button color="primary" onClick={handleEdit}>
                            {t('Edit allocation')}
                        </Button>
                        <div className="flex flex-wrap gap-4">
                            {allocation.pmProjectOverviewPlanId && (
                                <Button color="secondary" onClick={handleViewProject}>
                                    {t('View project')}
                                </Button>
                            )}
                            <Button color="secondary" onClick={handleViewResponsible}>
                                {t('View responsible')}
                            </Button>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-10">
                        <div>
                            <h4 className="mb-5 whitespace-nowrap">{t('Allocation info')}</h4>
                            <p className={optionClass}>
                                {t('Quantity')}: <span className={optionSpanClass}>{allocation.quantity}</span>
                            </p>
                            <p className={optionClass}>
                                Status: <span className={optionSpanClass}>{allocation.status}</span>
                            </p>
                        </div>

                        <div>
                            <h4 className="mb-5 whitespace-nowrap">{t('Resource info')}</h4>
                            <p className={optionClass}>
                                {t('Name')}: <span className={optionSpanClass}>{allocation.MaterialResource.name}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Quantity')}:{' '}
                                <span className={optionSpanClass}>{allocation.MaterialResource.quantity ?? '-'}</span>
                            </p>
                            <p className={optionClass}>
                                {t('NIR')}: <span className={optionSpanClass}>{allocation.MaterialResource.NIR}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Description')}:{' '}
                                <span className={optionSpanClass}>{allocation.MaterialResource.description}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Type')}:{' '}
                                <span className={optionSpanClass}>
                                    {t(allocation.MaterialResource.type.split('_').join(' '))}
                                </span>
                            </p>
                            <p className={optionClass}>
                                {t('Status')}:{' '}
                                <span className={optionSpanClass}>{t(allocation.MaterialResource.status)}</span>
                            </p>
                        </div>

                        <div>
                            <h4 className="mb-5 whitespace-nowrap">{t('Responsible info')}</h4>
                            <p className={optionClass}>
                                {t('Name')}: <span className={optionSpanClass}>{allocation.Responsible.name}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Address')}:{' '}
                                <span className={optionSpanClass}>{allocation.Responsible.address}</span>
                            </p>
                            <p className={optionClass}>
                                CNP: <span className={optionSpanClass}>{allocation.Responsible.CNP}</span>
                            </p>
                            <p className={optionClass}>
                                Email: <span className={optionSpanClass}>{allocation.Responsible.email}</span>
                            </p>
                        </div>

                        {allocation.pmProjectOverviewPlanId && (
                            <div>
                                <h4 className="mb-5 whitespace-nowrap">{t('Project info')}</h4>

                                <p className={optionClass}>
                                    {t('Project name')}:{' '}
                                    <span className={optionSpanClass}>{allocation.PmProject.name}</span>
                                </p>
                                <p className={optionClass}>
                                    {t('Allocated teams')}:{' '}
                                    <span className={optionSpanClass}>
                                        {(() => {
                                            let string = allocation.PmProject.teams
                                                .slice(0, 3)
                                                .map((t) => t.name)
                                                .join(', ');
                                            if (allocation.PmProject.teams.length > 3)
                                                string += ` + ${t('others')} ${allocation.PmProject.teams.length - 3}`;
                                            return string;
                                        })()}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Created on')}:{' '}
                                    <span className={optionSpanClass}>{formatDate(allocation.PmProject.createAt)}</span>
                                </p>
                                <p className={optionClass}>
                                    {t('Last update')}:{' '}
                                    <span className={optionSpanClass}>
                                        {formatDate(allocation.PmProject.updateAt)},{' '}
                                        {formatTime(allocation.PmProject.updateAt)}
                                    </span>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            }
        />
    );
};

AllocationRow.propTypes = {
    allocation: PrpTypes.object,
};

export default AllocationRow;
