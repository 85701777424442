import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// import { getCurrentLanguage, openIndexedDB, updateCurrentLanguage } from './utils/indexedDButils';

// const languageDetector = {
//     type: 'languageDetector',
//     async: true,
//     init: function (services, detectorOptions, i18nextOptions) {},
//     detect: async function () {
//         const db = await openIndexedDB();
//         const dbLng = await getCurrentLanguage(db);

//         const detectedLng = dbLng?.lang || navigator.language.split('-')[0];
//         return detectedLng;
//     },
//     cacheUserLanguage: async function (lng) {
//         const db = await openIndexedDB();
//         await updateCurrentLanguage(db, lng);
//     },
// };

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        caches: ['localStorage'],
        fallbackLng: 'en',
        interpolation: { escapeValue: false },
    });

export default i18n;
