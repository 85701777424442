import React from 'react';

import RevenueForm from 'components/accountancy/revenue-form';
import ManageRevenue from 'pages/accountancy/revenue/manage-revenue';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InitializeButtons from './initialize-buttons';
import InitializeStepInfo from './initialize-step-info';

const InitializeRevenues = ({ handleIncrementStep, handleDecrementStep, initializeData, setInitializeData }) => {
    const { t } = useTranslation();

    return (
        <>
            <InitializeStepInfo
                mainInfo={[
                    {
                        id: 0,
                        content: t('You can add revenues which will be used in a next step to create an article'),
                    },
                    {
                        id: 1,
                        content: t(
                            'The revenues are used all across the application and we recommend you add at least one'
                        ),
                    },
                ]}
                secondaryInfo={[{ id: 0, content: t("If you don't want to add one, this step can be skipped") }]}
                className="mb-10"
            />
            {initializeData.tab === 0 && <ManageRevenue setInitializeData={setInitializeData} />}
            {initializeData.tab === 1 && <RevenueForm setInitializeData={setInitializeData} />}
            <InitializeButtons
                handleIncrementStep={handleIncrementStep}
                handleDecrementStep={handleDecrementStep}
                className={'mt-20 flex justify-start gap-4'}
                canSkip={true}
                nextDisabled={initializeData.nextDisabled || initializeData.loading}
                prevDisabled={initializeData.loading}
                skipDisabled={initializeData.loading}
            />
        </>
    );
};

InitializeRevenues.propTypes = {
    handleIncrementStep: PropTypes.func,
    handleDecrementStep: PropTypes.func,
    initializeData: PropTypes.object,
    setInitializeData: PropTypes.func,
};

InitializeRevenues.defaultProps = {
    handleIncrementStep: null,
    handleDecrementStep: null,
    initializeData: {},
    setInitializeData: () => {},
};

export default InitializeRevenues;
