import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { crm } from 'routes';

import CreateEstate from './create-estate';
import EditEstate from './edit-estate';
import ManageEstates from './manage-estates';

const Estates = () => {
    return (
        <>
            <Switch>
                <Route path={crm.base + crm.estate.base + crm.estate.create}>
                    <CreateEstate />
                </Route>

                <Route exact path={crm.base + crm.estate.base + crm.estate.read}>
                    <ManageEstates />
                </Route>

                <Route path={crm.base + crm.estate.base + crm.estate.updateFull}>
                    <EditEstate />
                </Route>
            </Switch>
        </>
    );
};

export default Estates;
