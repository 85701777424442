import React, { useEffect, useRef, useState } from 'react';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { Popover } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import BasicTooltip from 'components/shared/basic-tooltip';
import useInViewport from 'hooks/useInViewport';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generateUUID } from 'utils';

import { Search } from '../Search/Search';

const getOptionStatus = (withDescription, optIdx) => {
    if (!withDescription) return null;

    const isNotOk = 'notOk' in withDescription && withDescription.notOk.check(optIdx);
    if (isNotOk) return 'notOk';

    const isWarning = 'warning' in withDescription && withDescription.warning.check(optIdx);
    if (isWarning) return 'warning';

    return 'ok';
};

const mappedDescriptionIcons = {
    ok: <CheckCircleOutlineIcon className="text-success" />,
    warning: <ReportProblemOutlinedIcon className="text-warning" />,
    notOk: <ErrorOutlineIcon className="text-error" />,
};

const renderDescriptionIcon = (status) => {
    if (!status) return null;
    return mappedDescriptionIcons[status];
};

const getDescriptionMessage = (withDescription, status, optIdx) => {
    if (!status) return '';
    return withDescription[status].message(optIdx);
};

export const Dropdown = (props) => {
    const { t } = useTranslation();

    const {
        options,
        selectedOption,
        setSelectedOption,
        placeholder,
        variant,
        icon,
        onlyIcon,
        disabled,
        hiddenOptions,
        withDescription,
    } = props;

    const [searchValue, setSearchValue] = useState('');

    const isContent = 'props' in options;

    const optionsRef = useRef(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const isInView = useInViewport({ current: anchorEl });

    const open = isInView && Boolean(anchorEl);
    const id = open ? 'dropdown-id' : undefined;

    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useEffect(() => {
        let observer = null;

        if (open) {
            setTimeout(() => {
                observer = new ResizeObserver(() => {
                    if (optionsRef.current) {
                        const { width: anchorWidth } = getComputedStyle(anchorEl);
                        if (parseFloat(anchorWidth) >= 200) optionsRef.current.style.width = anchorWidth;
                    }
                });

                observer.observe(anchorEl);
            }, 0);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [open]);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div
                className={`relative inline-block w-full flex-shrink-0 ${
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer '
                }`}
            >
                {/* Selected option */}
                {/* eslint-disable-next-line */}
                <div
                    className={`dropdown-selected ${variant} ${open ? 'clicked' : ''} ${
                        selectedOption !== null ? 'hasValue' : ''
                    } ${icon !== null && 'is-icon'} ${disabled ? 'disabled' : ''}`}
                    onClick={!disabled ? (open ? handleClose : handleOpen) : undefined}
                >
                    {icon !== null && !onlyIcon ? (
                        <>
                            <div>{icon}</div>
                            <p className="pointer-events-none whitespace-nowrap font-medium text-buttons-text">
                                {selectedOption !== null ? options[selectedOption] : placeholder ?? t('Choose option')}
                            </p>
                        </>
                    ) : icon !== null && onlyIcon ? (
                        <>{icon}</>
                    ) : (
                        <>
                            <p className="pointer-events-none whitespace-nowrap font-medium text-buttons-text">
                                {selectedOption !== null ? options[selectedOption] : placeholder ?? t('Choose option')}
                            </p>
                            <KeyboardArrowDownIcon
                                className={`flex-shrink-0 transform text-buttons-text ${
                                    open ? 'rotate-180' : 'rotate-0'
                                }`}
                                style={{ transition: 'transform .2s ease' }}
                            />
                        </>
                    )}
                </div>

                {/* Options */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    marginThreshold={12}
                    className="-mt-1"
                >
                    <div ref={optionsRef} className={`dropdown-options ${variant}`}>
                        {isContent ? (
                            <>{options}</>
                        ) : (
                            <>
                                <div className="search-li w-full">
                                    <Search light value={searchValue} setValue={setSearchValue} />
                                </div>
                                <ul className="z-50">
                                    {options.map((option, index) => {
                                        if (String(option).toLowerCase().search(searchValue.toLocaleLowerCase()) >= 0) {
                                            const isHidden =
                                                hiddenOptions.findIndex((disabledOption) => disabledOption === index) >=
                                                0;

                                            if (isHidden) return null;

                                            const optStatus = getOptionStatus(withDescription, index);

                                            return (
                                                // eslint-disable-next-line
                                                <BasicTooltip
                                                    key={generateUUID()}
                                                    disabled={!optStatus}
                                                    tip={getDescriptionMessage(withDescription, optStatus, index)}
                                                >
                                                    <li
                                                        onClick={() => {
                                                            setSelectedOption(index);
                                                            handleClose();
                                                        }}
                                                        className={`${index === selectedOption ? 'active' : ''} ${
                                                            optStatus === 'notOk' ? 'pointer-events-none' : ''
                                                        }`}
                                                    >
                                                        {renderDescriptionIcon(optStatus)}

                                                        <p
                                                            className={`text-sm font-medium text-buttons-text ${
                                                                Boolean(option) && option.length > 32
                                                                    ? 'overflow-hidden overflow-ellipsis whitespace-nowrap'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {option}
                                                        </p>
                                                    </li>
                                                </BasicTooltip>
                                            );
                                        }
                                        return null;
                                    })}
                                </ul>
                            </>
                        )}
                    </div>
                </Popover>
            </div>
        </ClickAwayListener>
    );
};

Dropdown.propTypes = {
    // eslint-disable-next-line
    options: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    selectedOption: PropTypes.number,
    setSelectedOption: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.string,
    icon: PropTypes.element,
    disabled: PropTypes.bool,
    onlyIcon: PropTypes.bool,
    hiddenOptions: PropTypes.array,
    withDescription: PropTypes.shape({
        ok: PropTypes.shape({
            message: PropTypes.func,
        }),
        notOk: PropTypes.shape({
            message: PropTypes.func,
            check: PropTypes.func,
        }),
        warning: PropTypes.shape({
            message: PropTypes.func,
            check: PropTypes.func,
        }),
    }),
};

Dropdown.defaultProps = {
    options: null,
    selectedOption: null,
    setSelectedOption: () => null,
    variant: 'default',
    icon: null,
    disabled: false,
    onlyIcon: false,
    hiddenOptions: [],
    withDescriptions: null,
};
