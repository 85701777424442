import React from 'react';

import PropTypes from 'prop-types';

const labelStyle = {
    fontSize: '10px',
    textAlign: 'left',
    marginBottom: '2px',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export const LabelWrapper = (props) => {
    const { children, label, noPadding, variant, style } = props;
    return (
        <div className="relative w-full">
            <div
                style={{
                    ...labelStyle,
                    ...style,
                    paddingLeft: noPadding ? '0' : 'calc(1rem + 1px)',
                }}
                className={`${variant ?? 'text-dark-text'}`}
            >
                {label}
            </div>

            {children}
        </div>
    );
};
LabelWrapper.propTypes = {
    label: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.arrayOf(PropTypes.element)]),
    noPadding: PropTypes.bool,
    variant: PropTypes.string,
    style: PropTypes.object,
};
LabelWrapper.defaultProps = {
    label: '',
    children: <></>,
    noPadding: false,
    white: false,
    style: {},
};
