import React, { useContext, useState } from 'react';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { Button } from '@material-ui/core';

import { ActionArrow } from 'components/internal-activity/activity';
import BasicTooltip from 'components/shared/basic-tooltip';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { daysFrom, errorHandling, formatDate, formatTime } from 'utils';
import API from 'utils/axios';

import PMAddWikiComment from './pm-wiki-add-comment';
import PMWikiComment from './pm-wiki-comment';

const PMWikiDisplayFiles = ({ file, deleteFileFromWiki, canAll }) => {
    const { t } = useTranslation();

    return (
        <div className="group mb-1 flex items-center justify-between rounded-md bg-layout-transparent-dark px-4 py-2 transition-colors hover:bg-primary-main hover:bg-opacity-40">
            {file.type === 'FILE' ? (
                <div className="flex items-center">
                    <AttachFileIcon
                        className="mr-2 rotate-45 transform text-buttons-text"
                        style={{ fontSize: '1.75rem' }}
                    />
                    <p className="user-select-none text-buttons-text">{file.fileName}</p>
                </div>
            ) : (
                <div className="flex items-center">
                    <InsertDriveFileIcon className="mr-2 text-buttons-text" style={{ fontSize: '1.75rem' }} />
                    <p className="user-select-none text-buttons-text">{file.fileName}</p>
                </div>
            )}

            <div className="flex gap-2 pl-2">
                <div className="flex items-center">
                    <BasicTooltip tip={`${file.type == 'FILE' ? t('Download file') : t('Download document')}`}>
                        <div className="flex h-7 w-7 -translate-y-2 transform cursor-pointer items-center justify-center rounded-full bg-secondary-light opacity-0 transition-all hover:bg-secondary-main group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100">
                            <a href={file.urls} target="_blank" rel="noreferrer">
                                <GetAppIcon className="text-buttons-text" style={{ fontSize: '1.2rem' }} />
                            </a>
                        </div>
                    </BasicTooltip>
                </div>

                {canAll && (
                    <div className="flex items-center">
                        <BasicTooltip tip={`${file.type == 'FILE' ? t('Delete file') : t('Delete document')}`}>
                            <div
                                className="flex h-7 w-7 -translate-y-2 transform cursor-pointer items-center justify-center rounded-full bg-error-light opacity-0 transition-all hover:bg-error group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100"
                                onClick={() => {
                                    deleteFileFromWiki(file.fileId);
                                }}
                            >
                                <CloseIcon className="text-buttons-text" style={{ fontSize: '1.2rem' }} />
                            </div>
                        </BasicTooltip>
                    </div>
                )}
            </div>
        </div>
    );
};

PMWikiDisplayFiles.propTypes = {
    file: PropTypes.object,
    deleteFileFromWiki: PropTypes.func,
    canAll: PropTypes.bool,
};

PMWikiDisplayFiles.defaultProps = {
    file: {},
    deleteFileFromWiki: () => null,
    canAll: false,
};

const PMWikiDisplay = ({ wiki, tenants, departments, getWikis, openEdit, canAll }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const globalContext = useContext(GlobalContext);

    /**
     * Get info of the author of the wiki
     */
    const tenant = tenants.find(({ id }) => id === wiki.authorId);

    /**
     * Get info of the department of the wiki
     */
    const department = departments.find(({ id }) => id === wiki.pmDepartmentId);

    const [createNew, setCreateNew] = useState({ type: null, open: false });

    /**
     * Function delete files from a created wiki
     * @param {*} id - of the file which is need to be deleted
     */
    const deleteFileFromWiki = async (id) => {
        try {
            await API.delete('/wikiFileDeletion', {
                params: {
                    wikiId: wiki.id,
                    fileId: id,
                },
            });

            await getWikis();
        } catch (err) {
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
            console.error(err);
        }
    };

    const deleteWiki = async (id) => {
        try {
            await API.delete('/wikis', {
                params: {
                    id,
                },
            });

            await getWikis();
            enqueueSnackbar(t('The wiki was deleted successfully'), { variant: 'success' });
        } catch (err) {
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
            console.error(err);
        }
    };

    // const commentSchema = yup.object().shape({
    //     newComment: yup
    //         .string()
    //         .trim()
    //         .typeError(t('Comment is required and can not be empty!'))
    //         .required(t('Comment is required and can not be empty!')),
    // });

    const createComment = async (newComment, newCommentFiles) => {
        try {
            // await commentSchema.validate({
            //     newComment,
            // });

            try {
                const formData = new FormData();

                const reqBody = {
                    wikiId: wiki.id,
                    comment: newComment,
                };

                formData.append('data', JSON.stringify(reqBody));
                if (newCommentFiles.length > 0) {
                    newCommentFiles.forEach((file) => formData.append('files', file.blob));
                }

                await API.post('/wikiComment', formData);
                setCreateNew(false);
                await getWikis();
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
                console.error(err);
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    return (
        <div className="relative mb-12 last:mb-0">
            {/* Wiki icon and date */}
            <div className="absolute -left-8 top-5 sm:-left-6" style={{ transform: 'translateX(calc(-50% - 1px))' }}>
                <div className="h-6 w-6 rounded-full bg-buttons-text">
                    <i
                        className="fa-solid fa-circle-info"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '1.8rem',
                            color: 'var(--primary-light)',
                        }}
                    />
                </div>

                <div className="absolute top-1/2 -translate-x-full -translate-y-1/2 transform p-2.5 pr-5 text-right sm:flex sm:translate-x-12 sm:translate-y-2 sm:gap-6">
                    <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right text-primary-main">{`${
                        daysFrom(wiki.createdAt) === 0
                            ? t('today')
                            : daysFrom(wiki.createdAt) === 1
                            ? t('yesterday')
                            : daysFrom(wiki.createdAt)
                    } ${daysFrom(wiki.createdAt) !== 0 ? t('days ago') : ''}`}</p>
                    <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right font-semibold">
                        {formatDate(wiki.createdAt, false)}
                    </p>
                    <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right text-dark-text">
                        {formatTime(wiki.createdAt)}
                    </p>
                </div>
            </div>

            {/* Wiki department */}
            <div className="absolute left-6 top-0 -translate-y-1/2 transform rounded-full bg-primary-main px-5 py-3 transition-colors hover:bg-primary-light">
                <p className="user-select-none font-semibold text-buttons-text">{department?.name}</p>
            </div>

            {/* Action arrow */}
            <div className="absolute right-2 top-2">
                {canAll && (
                    <ActionArrow
                        actions={[
                            {
                                name: t('Edit'),
                                action: () => {
                                    openEdit();
                                },
                            },
                            {
                                name: t('Delete'),
                                action: () => {
                                    globalContext.setGlobalModalOpen(true);
                                    globalContext.setGlobalModalChildren(
                                        <div className="flex-col">
                                            <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                {t('Are you sure you want to')}
                                                <br />
                                                {t('delete this wiki?')}
                                            </p>
                                            <div className="flex items-center justify-center">
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        deleteWiki(wiki.id);
                                                        globalContext.setGlobalModalOpen(false);
                                                    }}
                                                >
                                                    {t('Yes')}
                                                </Button>
                                                <div className="w-4" />
                                                <Button onClick={() => globalContext.setGlobalModalOpen(false)}>
                                                    {t('No')}
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                )}
            </div>

            <div className="mb-1.5 rounded-md bg-layout-transparent px-6 py-4 pt-10 text-main-text transition-colors hover:bg-layout-transparent-dark sm:pt-24">
                {/* Wiki title */}
                <h3 className="mb-5 font-semibold">{wiki.subject}</h3>

                {/* Wiki content */}
                <div style={{ width: '100%' }}>
                    <pre className="mb-5" style={{ fontFamily: 'Sarabun', whiteSpace: 'pre-wrap' }}>
                        {wiki.details}
                    </pre>
                </div>

                {/* Wiki files and documents */}
                <div className={`${wiki.fileUrls.length && 'border-t border-layout-transparent-dark pt-4'}`}>
                    <div className="mb-4">
                        {wiki?.fileUrls
                            ?.filter((file) => file.type === 'FILE')
                            .map((file) => (
                                <PMWikiDisplayFiles
                                    key={file.fileId}
                                    file={file}
                                    deleteFileFromWiki={deleteFileFromWiki}
                                />
                            ))}
                    </div>

                    {wiki?.fileUrls
                        ?.filter((file) => file.type === 'DOCUMENT')
                        .map((file) => (
                            <PMWikiDisplayFiles
                                key={file.fileId}
                                file={file}
                                deleteFileFromWiki={deleteFileFromWiki}
                                canAll={canAll}
                            />
                        ))}
                </div>

                {/* Wiki author */}
                <div className="text-md mt-3 cursor-default font-semibold text-dark-text">
                    {t('Wiki author')}: <span className="text-main-text">{tenant?.profile.name}</span>
                </div>

                {canAll && (
                    <div className="mt-4">
                        <PMAddWikiComment
                            onSubmit={(newComment, newCommentFiles) => createComment(newComment, newCommentFiles)}
                            getWikis={getWikis}
                            createNew={createNew}
                            setCreateNew={setCreateNew}
                        />
                    </div>
                )}
            </div>
            {wiki?.comments?.map((comment) => (
                <PMWikiComment key={comment.id} comment={comment} tenants={tenants} getWikis={getWikis} />
            ))}
        </div>
    );
};

PMWikiDisplay.propTypes = {
    wiki: PropTypes.object,
    tenants: PropTypes.array,
    departments: PropTypes.array,
    getWikis: PropTypes.func,
    openEdit: PropTypes.func,
    canAll: PropTypes.bool,
};

PMWikiDisplay.defaultProps = {
    wiki: {},
    tenants: [],
    departments: [],
    getWikis: () => null,
    openEdit: () => null,
    canAll: false,
};

export default PMWikiDisplay;
