import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import { TableSeparator } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

import DocumentTemplateRow from './document-template-row';

const DocumentsTemplates = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);

    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);

    const canView = checkPerm([
        {
            permissionId: '34',
            permissionType: 'ALL',
        },
    ]);

    const getTemplates = async (currentPage, perPage) => {
        try {
            const templatesRes = await API.get('docTemplates', {
                params: {
                    currentPage,
                    perPage,
                    pagesToLoad: 1,
                },
            });
            setTemplates(templatesRes.data.data);

            return templatesRes.data.length;
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!canView) history.goBack();
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>
                    {t('ERP Configurator')} | {t('Documents templates')}
                </title>
            </Helmet>
            <Pagination loading={loading} setLoading={setLoading} getFunction={getTemplates}>
                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : templates.length === 0 ? (
                    <NoDataPlaceholder />
                ) : (
                    <div className="page-container">
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Name')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {templates.map((template) => (
                                        <DocumentTemplateRow
                                            key={template.id}
                                            template={template}
                                            callback={() => getTemplates(0, 10)}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </Pagination>
        </Fragment>
    );
};

export default DocumentsTemplates;
