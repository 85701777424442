import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AddEmployeePage from 'components/employees/add-employee-page';
import EditEmployeePage from 'components/employees/edit-employee-page';
import Footer from 'components/footer/footer';
import Resource from 'components/planning-overview/resource-page/resource';
// import UserContext from 'contexts/UserContext';
import { internalActivity } from 'routes';

import MilestonesPipeline from './milestones-pipeline/milestones-pipeline';
import Partners from './partners/partners';
import CreateProject from './planning-overview/create-project';
import EditProject from './planning-overview/edit-project';
import PlanningOverview from './planning-overview/planning-overview';
import CreateProjectStructure from './project-structures/create-project-structure';
import EditProjectStructure from './project-structures/edit-project-structure';
import GanttProjectStructure from './project-structures/gantt-project-structure';
import ProjectStructures from './project-structures/project-structures';
import TasksPipeline from './tasks-pipeline/tasks-pipeline';
import TimelineActivity from './timeline-activity/timeline-activity';

const InternalActivity = () => {
    return (
        <React.Fragment>
            <Switch>
                {/* Internal & External resources */}
                <Route path={internalActivity.base + '/add-employee'}>
                    <AddEmployeePage moduleType="internalActivity" />
                </Route>

                <Route path={internalActivity.base + '/edit-employee/:employeeId'}>
                    <EditEmployeePage moduleType="internalActivity" />
                </Route>

                {/* --- End --- */}
                {/* <Route exact path={internalActivity.base}>
                    {checkPerm([{ permissionId: 1, permissionType: 'VIEW' }]) ? (
                        <TimelineActivity />
                    ) : checkPerm([{ permissionId: 2, permissionType: 'VIEW' }]) ? (
                        <MilestonesPipeline />
                    ) : null}
                </Route> */}

                <Route path={internalActivity.base + internalActivity.timeline.base}>
                    <TimelineActivity />
                </Route>

                <Route path={internalActivity.base + internalActivity.tasksPipeline.base}>
                    <TasksPipeline purpose="IA" />
                </Route>

                <Route path={internalActivity.base + internalActivity.pipeline.base}>
                    <MilestonesPipeline />
                </Route>

                <Route path={internalActivity.base + internalActivity.partners.base}>
                    <Partners />
                </Route>

                <Route exact path={internalActivity.base + internalActivity.planning.base}>
                    <PlanningOverview />
                </Route>

                <Route
                    exact
                    path={
                        internalActivity.base + internalActivity.planning.base + internalActivity.planning.resourceFull
                    }
                >
                    <Resource type="internal" />
                </Route>

                <Route path={internalActivity.base + internalActivity.planning.base + internalActivity.planning.create}>
                    <CreateProject />
                </Route>

                <Route
                    path={internalActivity.base + internalActivity.planning.base + internalActivity.planning.updateFull}
                >
                    <EditProject />
                </Route>

                <Route exact path={internalActivity.base + internalActivity.projectStructure.base}>
                    <ProjectStructures />
                </Route>

                <Route
                    path={
                        internalActivity.base +
                        internalActivity.projectStructure.base +
                        internalActivity.projectStructure.create
                    }
                >
                    <CreateProjectStructure />
                </Route>

                <Route
                    path={
                        internalActivity.base +
                        internalActivity.projectStructure.base +
                        internalActivity.projectStructure.updateFull
                    }
                >
                    <EditProjectStructure />
                </Route>

                <Route
                    path={
                        internalActivity.base +
                        internalActivity.projectStructure.base +
                        internalActivity.projectStructure.ganttFull
                    }
                >
                    <GanttProjectStructure />
                </Route>
            </Switch>
            <Footer />
        </React.Fragment>
    );
};

export default InternalActivity;
