import API from './axios';

export const getTenantUsers = async () => {
    try {
        const res = await API.get('/tenants', {
            params: {
                perPage: 9999,
                currentPage: 0,
                pagesToLoad: 1,
            },
        });
        return res.data.users || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getProjectUsers = async (params) => {
    try {
        const res = await API.get('/ProjectUsers', {
            params: {
                perPage: 9999,
                currentPage: 0,
                pagesToLoad: 1,
                ...params,
            },
        });

        return res.data.Users.users.map((u) => u.User) || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getDepartments = async () => {
    try {
        const res = await API.get('/departments', {
            params: {
                perPage: 9999,
                currentPage: 0,
                pagesToLoad: 1,
            },
        });
        return res.data.departments.departments || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getPmDepartments = async () => {
    try {
        const res = await API.get('/pmDepartments', {
            params: {
                perPage: 9999,
                currentPage: 0,
                pagesToLoad: 1,
                type: 'PLANNING_OVERVIEW',
            },
        });

        return res.data.pmDepartments || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getPartners = async () => {
    try {
        const res = await API.get('/partners', {
            params: {
                perPage: 9999,
                currentPage: 0,
                pagesToLoad: 1,
            },
        });
        return res.data.formattedPartners || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getPermissions = async () => {
    try {
        const res = await API.get('permissions');
        return res.data.data || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getContactById = async (id) => {
    try {
        const res = await API.get('/contact', {
            params: {
                id,
            },
        });
        return res.data.data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getFields = async () => {
    try {
        const res = await API.get('fields');
        return res.data.data || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getEntityFields = async (entity) => {
    try {
        const res = await API.get('/field', {
            params: {
                type: entity,
            },
        });

        return res.data.data || null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

/**
 * Resources (internal/external functions)
 */
export const getResources = async (type = 'internal') => {
    const resources = {
        internalEmployees: [],
        externalEmployees: [],
    };

    const url = type === 'internal' ? 'employees' : 'pm_employees';

    try {
        const response = await API.get(url);
        resources.internalEmployees = response.data[type === 'internal' ? 'employees' : 'pmEmployees'].filter(
            (e) => e.employeeType === 'INTERNAL',
        );
        resources.externalEmployees = response.data[type === 'internal' ? 'employees' : 'pmEmployees'].filter(
            (e) => e.employeeType === 'EXTERNAL',
        );
    } catch (err) {
        console.error(err);
    }

    return resources;
};

/**
 * Expenses Nomenclature
 */
export const getExpensesNames = async () => {
    let expenses = [];

    try {
        const response = await API.get('/expensesName', {
            params: {
                perPage: 9999,
                pagesToLoad: 1,
                currentPage: 0,
            },
        });
        expenses = response.data.expensesName.expenseNames;
    } catch (err) {
        console.error(err);
    }

    return expenses;
};

export const getExpensesNameById = async (id) => {
    let expense = null;

    try {
        const response = await API.get('/expenseName', {
            params: {
                id,
            },
        });
        expense = response.data.expenseName;
    } catch (err) {
        console.error(err);
    }

    return expense;
};

export const getArticles = async () => {
    try {
        const response = await API.get('/articles', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
                many: 'ALL',
            },
        });
        return response.data.articles || null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getNomenclatureAndStockArticles = async () => {
    try {
        const response = await API.get('articlesAndStocks', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
            },
        });

        return response.data.data || null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getContacts = async (type = 'ALL') => {
    try {
        const response = await API.get('/contacts', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
                type,
            },
        });

        return response.data.data.contacts || null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getCompanyVAT = async () => {
    try {
        const response = await API.get('/tenant');
        return response.data.data.percentageVAT || null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

/**
 * [Get Exchange Rates from BNR]
 */
export let exchangeRates = [];

export const getExchangeRates = async () => {
    try {
        const response = await API.get('/currency');
        exchangeRates = response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const getSpecificRate = (currency) => {
    if (currency === 'RON' || currency === 'ALL')
        return {
            currency: 'RON',
            changeRate: 1,
        };

    return exchangeRates.filter((r) => r.currency === currency.toUpperCase())[0];
};

export const getCompanyDetails = async () => {
    try {
        const response = await API.get('/tenant');
        return response.data.data;
    } catch (err) {
        console.error(err);
    }
};
