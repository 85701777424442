import React from 'react';

import PropTypes from 'prop-types';

import FilterDesktop from './filter-desktop';
import FilterMobile from './filter-mobile';

export const findFilterOption = (element, filterOptions) => {
    let filterOption = null;
    element.relations.forEach((relation) => {
        const lastDashIndex = relation.lastIndexOf('-');
        const id = relation.substring(0, lastDashIndex);
        const index = +relation.substring(lastDashIndex + 1);

        if (!filterOption) filterOption = filterOptions.find((el, elIndex) => el.id === id && elIndex === index);
        else filterOption = filterOption.list.find((el, elIndex) => el.id === id && elIndex === index);
    });

    return filterOption;
};

const Filter = ({ filterOptions, filter, setFilter, onFilter, disabled, mobileBP }) => {
    const handleChangeFilter = (close, newFilter) => {
        setFilter((prev) => {
            const newFilterList = [...prev];

            const presentAtIndex = prev.findIndex((element) => element.key === newFilter.key);

            if (presentAtIndex >= 0) newFilterList[presentAtIndex] = newFilter;
            else newFilterList.push(newFilter);

            onFilter(newFilterList);
            return newFilterList;
        });
        close();
    };

    const handleRemoveFilter = (id, close) => {
        setFilter((prev) => {
            const newFilterList = prev.filter((element) => element.id !== id);
            onFilter(newFilterList);

            if (newFilterList.length === 0) close();

            return newFilterList;
        });
    };

    return (
        <>
            <FilterDesktop
                disabled={disabled}
                filter={filter}
                setFilter={setFilter}
                filterOptions={filterOptions}
                onFilter={onFilter}
                onChangeFilter={handleChangeFilter}
                onRemoveFilter={handleRemoveFilter}
                mobileBP={mobileBP}
            />
            <FilterMobile
                disabled={disabled}
                filter={filter}
                setFilter={setFilter}
                filterOptions={filterOptions}
                onFilter={onFilter}
                onChangeFilter={handleChangeFilter}
                onRemoveFilter={handleRemoveFilter}
                mobileBP={mobileBP}
            />
        </>
    );
};

Filter.propTypes = {
    disabled: PropTypes.bool,
    filterOptions: PropTypes.array,
    filter: PropTypes.array,
    setFilter: PropTypes.func,
    onFilter: PropTypes.func,
    mobileBP: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
};

Filter.defaultProps = {
    disabled: false,
    filterOptions: [],
    filter: [],
    setFilter: () => {},
    onFilter: () => {},
    mobileBP: 'sm',
};

export default Filter;
