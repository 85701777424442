import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ListAltIcon from '@material-ui/icons/ListAlt';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import ProjectDisplay from 'components/shared/configurator/project-display';
import DisplayQueryResults from 'components/shared/display-query-results';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ExpandableRow, Header, LabelWrapper, Search, TableSeparator, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import { errorHandling, toLocaleNumber } from 'utils';
import API from 'utils/axios';
import { calculateTotal, calculateVATValue } from 'utils/index';

import CreateStockModal from '../stocks/create-stock-modal';
import ManageStocks from '../stocks/manage-stocks';
import ArticleCategories from './article-categories';

const NomenclatureRow = (props) => {
    const { nomenclature: article, isInitializationCase, onOpensStockModal, setLoading, getArticlesCallback } = props;
    const history = useHistory();
    const { currencyObj, setGlobalModalOpen, setGlobalModalChildren } = useContext(GlobalContext);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const optionClass = 'text-md text-dark-text mb-4';
    const optionClassValues = 'text-md text-dark-text mb-4 flex flex-col';
    const optionSpanClass = 'text-main-text font-bold';

    /** Functions for calculation of VAT and finale price with VAT  */
    const totalPrice =
        Number(article.price.pricePerUnit) +
        article.options.map((a) => Number(a.price.pricePerUnit))?.reduce((a, b) => a + b, 0);
    const totalVatValue =
        Number(calculateVATValue(article.price.pricePerUnit, article.price.procentualVAT)) +
        article.options
            .map((a) => Number(calculateVATValue(a.price.pricePerUnit, a.price.procentualVAT)))
            ?.reduce((a, b) => a + b, 0);

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    const handleDuplicateRequest = async () => {
        try {
            setLoading(true);
            setGlobalModalOpen(false);

            await API.post('duplicateArticle', undefined, {
                params: {
                    id: article.id,
                },
            });
            await getArticlesCallback();

            enqueueSnackbar(t('The article was duplicated successfully!'), { variant: 'success' });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setGlobalModalChildren(null);
            setLoading(false);
        }
    };

    const handleDuplicateArticle = () => {
        setGlobalModalOpen(true);
        setGlobalModalChildren(
            <div className="flex-col">
                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                    {t('Are you sure you want to duplicate this article?')}
                </p>
                <div className="flex items-center justify-center">
                    <Button id="global-yes-button-root" color="primary" onClick={handleDuplicateRequest}>
                        {t('Yes')}
                    </Button>
                    <div className="w-4" />
                    <Button id="global-no-button-root" onClick={() => setGlobalModalOpen(false)}>
                        {t('No')}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <ExpandableRow
            colSpan={7}
            row={
                <>
                    <TableCell>{article.name}</TableCell>
                    <TableCell>
                        {article.categories.map(
                            (c, index) => c.categorie.name + (index < article.categories.length - 1 ? ', ' : '')
                        )}
                    </TableCell>
                    <TableCell>
                        {toLocaleNumber(totalPrice, 2)} {currencyObj.currency}
                    </TableCell>
                    <TableCell>{toLocaleNumber(article.price.procentualVAT, 2)} %</TableCell>
                    <TableCell>
                        {toLocaleNumber(totalVatValue, 2)} {currencyObj.currency}
                    </TableCell>
                    <TableCell>
                        {toLocaleNumber(totalPrice + totalVatValue, 2)} {currencyObj.currency}
                    </TableCell>
                    <TableCell>{article.options.length}</TableCell>
                    <TableCell>
                        <p
                            style={{
                                color: article.pm_planningOverviewProject?.name
                                    ? 'var(--main-text)'
                                    : 'var(--dark-text)',
                            }}
                        >
                            {article.pm_planningOverviewProject?.name ?? `${t('Not assigned to a project')}`}
                        </p>
                    </TableCell>
                </>
            }
            collapse={
                <>
                    {canAll && !isInitializationCase && (
                        <div className="flex justify-between gap-3 p-3">
                            <div className="flex gap-3">
                                <Button
                                    color="secondary"
                                    startIcon={<VisibilityOutlinedIcon style={{ fontSize: 25 }} />}
                                    onClick={() =>
                                        history.push(
                                            quotes.base +
                                                quotes.nomenclature.base +
                                                quotes.nomenclature.update +
                                                '/' +
                                                article.id
                                        )
                                    }
                                >
                                    {t('View article & edit')}
                                </Button>

                                <Button
                                    color="secondary"
                                    startIcon={<ListAltIcon style={{ fontSize: 25 }} />}
                                    onClick={() => onOpensStockModal(article.id)}
                                >
                                    {t('Create stock')}
                                </Button>

                                <Button
                                    color="secondary"
                                    startIcon={<FileCopyIcon style={{ fontSize: 25 }} />}
                                    onClick={handleDuplicateArticle}
                                >
                                    {t('Duplicate article')}
                                </Button>
                            </div>

                            <div className="flex gap-3">
                                <Button
                                    color="primary"
                                    startIcon={<EditAttributesIcon style={{ fontSize: 25 }} />}
                                    onClick={() =>
                                        history.push(quotes.base + quotes.attributes.base + '/' + article.id)
                                    }
                                >
                                    {article.ArticleAttribute.length
                                        ? t('Add or view attributes')
                                        : t('Add attributes')}
                                </Button>

                                <Button
                                    color="primary"
                                    startIcon={<CallMergeIcon style={{ fontSize: 25 }} />}
                                    onClick={() =>
                                        history.push(quotes.base + quotes.variations.base + '/' + article.id)
                                    }
                                >
                                    {article.ArticleVariations.length
                                        ? t('Add or view variations')
                                        : t('Add variations')}
                                </Button>
                            </div>
                        </div>
                    )}
                    <div className="-ml-3 grid w-4/5 grid-cols-2 gap-16 2xl:w-3/4 xl:w-full lg:-ml-0  lg:grid-cols-1 lg:p-4">
                        <div>
                            <div className="mt-10 bg-layout-transparent p-6">
                                <h4 className="mb-5 break-words text-2xl">{article.name}</h4>
                                <p className={optionClass}>
                                    {t('Article type')}:{' '}
                                    <span className={optionSpanClass}>
                                        {article.type === 'SERVICES'
                                            ? t('Service')
                                            : article.type === 'FIZIC'
                                            ? t('Physical product')
                                            : article.type === 'DIGITAL'
                                            ? t('Digital product')
                                            : ''}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Article category')}:{' '}
                                    <span className={optionSpanClass}>
                                        {article.categories.map(
                                            (c, index) =>
                                                c.categorie.name + (index < article.categories.length - 1 ? ', ' : '')
                                        )}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Article code(SKU)')}: <span className={optionSpanClass}>{article.sku}</span>
                                </p>
                                <p className={optionClass}>
                                    {t('Description')}: <span className={optionSpanClass}>{article.description}</span>
                                </p>

                                <div className="inline-flex gap-6">
                                    <p className={optionClassValues}>
                                        {t('Price without VAT')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(article.price.pricePerUnit, 2)} {currencyObj.currency}
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('VAT rate')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(article.price.procentualVAT, 2)} %
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('VAT value')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(
                                                calculateVATValue(
                                                    article.price.pricePerUnit,
                                                    article.price.procentualVAT
                                                ),
                                                2
                                            )}{' '}
                                            {currencyObj.currency}
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('Final price with VAT')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(
                                                calculateTotal(
                                                    calculateVATValue(
                                                        article.price.pricePerUnit,
                                                        article.price.procentualVAT
                                                    ),
                                                    article.price.pricePerUnit
                                                ),
                                                2
                                            )}{' '}
                                            {currencyObj.currency}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {article.options.map((o) => (
                                <div
                                    className="border-t-2 border-layout-transparent-dark p-6 hover:bg-layout-transparent"
                                    key={o.id}
                                >
                                    <h4 className="mb-5 text-2xl">{o.name}</h4>
                                    <p className={optionClass}>
                                        {t('Variable code(SKU)')}: <span className={optionSpanClass}>{o.sku}</span>
                                    </p>
                                    <p className={optionClass}>
                                        {t('Description')}: <span className={optionSpanClass}>{o.description}</span>
                                    </p>
                                    {o.price.type === 'BASE_PROCENT' ? (
                                        <p className={optionClass}>
                                            {t('Percentage of basic item')}:{' '}
                                            <span className={optionSpanClass}>{o.price.priceProcent} %</span>
                                        </p>
                                    ) : o.price.type === 'BASE_PROCENT_AND_VARIABLE' ? (
                                        <p className={optionClass}>
                                            {t('Percentage of the entire article(article + all variables)')}:{' '}
                                            <span className={optionSpanClass}>{o.price.priceProcent} %</span>
                                        </p>
                                    ) : null}

                                    <div className="inline-flex gap-6">
                                        <p className={optionClassValues}>
                                            {t('Price without VAT')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(o.price.pricePerUnit, 2)} {currencyObj.currency}
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('VAT rate')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(o.price.procentualVAT, 2)} %
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('VAT value')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(
                                                    calculateVATValue(o.price.pricePerUnit, o.price.procentualVAT),
                                                    2
                                                )}{' '}
                                                {currencyObj.currency}
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('Final price with VAT')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(
                                                    calculateTotal(
                                                        calculateVATValue(o.price.pricePerUnit, o.price.procentualVAT),
                                                        o.price.pricePerUnit
                                                    ),
                                                    2
                                                )}{' '}
                                                {currencyObj.currency}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-9/12 sm:w-full">
                            <div className="mt-10 bg-layout-transparent p-7">
                                <h4 className="mb-4 text-2xl">{t('Price with all variables')}</h4>
                                <div className="grid grid-cols-3 gap-2 xl:grid-cols-1">
                                    <div>
                                        <LabelWrapper label={t('Price without VAT')} noPadding>
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(totalPrice, 2)} {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>

                                    <div>
                                        <LabelWrapper label={t('VAT value')} noPadding>
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(totalVatValue, 2)} {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>

                                    <div>
                                        <LabelWrapper label={t('Final price with VAT')} noPadding>
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(totalPrice + totalVatValue, 2)} {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>
                                </div>
                            </div>
                            {article.pm_planningOverviewProject && (
                                <ProjectDisplay
                                    project={article.pm_planningOverviewProject}
                                    style={{
                                        containerStyle: {
                                            marginTop: '4rem',
                                            padding: '1.75rem',
                                            backgroundColor: 'var(--layout-transparent)',
                                        },
                                        headerStyle: { fontSize: '1.5rem', lineHeight: '1.5rem' },
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </>
            }
        />
    );
};

NomenclatureRow.propTypes = {
    nomenclature: PropTypes.object,
    isInitializationCase: PropTypes.bool,
    onOpensStockModal: PropTypes.func,
    getArticlesCallback: PropTypes.func,
    setLoading: PropTypes.func,
};

NomenclatureRow.defaultValues = {
    nomenclature: null,
    isInitializationCase: false,
    onOpensStockModal: () => {},
    getArticlesCallback: () => {},
    setLoading: () => {},
};

const ManageNomenclatures = ({ setInitializeData }) => {
    const history = useHistory();
    const params = new URL(document.location).searchParams;
    const { t } = useTranslation();

    const currentPageForArticles = useRef(null);

    const [query, setQuery] = useState('');
    const isSearch = query.trim().length > 0;

    const [activeTab, setActiveTab] = useState(0);
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);

    const [purposeSortType, setPurposeSortType] = useState('ALL');
    const [sortType, setSortType] = useState('ALL');
    const [categorySortCrit, setCategorySortCrit] = useState('ALL');
    const articleType = ['SERVICES', 'FIZIC', 'DIGITAL'];
    const type = [t('Service'), t('Physical product'), t('Digital product')];
    const tabs = [t('Article nomenclature'), t('Article categories'), t('Stocks')];

    const [loading, setLoading] = useState(true);
    const [isOpenStockModal, setIsOpenStockModal] = useState({ articleId: null, open: false });

    const handleOpenStockModal = (articleId) => setIsOpenStockModal({ articleId, open: true });
    const handleCloseStockModal = () => setIsOpenStockModal({ articleId: null, open: false });

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '18',
            permissionType: 'VIEW',
        },
    ]);

    // useEffect(() => {
    //     if (!canView) {
    //         history.push('/');
    //     }
    // }, [canView]);

    const getArticles = async (currentPage, perPage, controller) => {
        try {
            const response = await API.get('/articles', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                    type: sortType !== 'ALL' ? sortType : null,
                    categorieId: categorySortCrit !== 'ALL' ? categorySortCrit : null,
                    many: purposeSortType,
                },
                signal: controller?.signal,
            });

            currentPageForArticles.current = currentPage;
            setArticles(response.data.articles);
            setInitializeData?.((prev) => ({ ...prev, nextDisabled: !response.data.length }));
            return response.data.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    const getSearchedArticles = async (currentPage, perPage, controller) => {
        try {
            const response = await API.get('/searchArticles', {
                params: {
                    text: query,
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                    type: sortType !== 'ALL' ? sortType : null,
                    categorieId: categorySortCrit !== 'ALL' ? categorySortCrit : null,
                    many: purposeSortType,
                },
                signal: controller?.signal,
            });

            currentPageForArticles.current = currentPage;
            setArticles(response.data.articles);

            return response.data.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const response = await API.get('/categories');
                setCategories(response.data.getCategory);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        setActiveTab(Number(params.get('tab')));
    }, []);

    return (
        <>
            {canView && (
                <>
                    {!setInitializeData && (
                        <>
                            <Helmet>
                                <title>{t('Article nomenclature')}</title>
                            </Helmet>
                            <Header
                                pageTitle={
                                    <div className="flex w-full flex-wrap items-center gap-8 sm:gap-4">
                                        <span>{tabs[activeTab]}</span>

                                        {activeTab === 0 && (
                                            <Search
                                                searchIconBig={true}
                                                value={query}
                                                setValue={setQuery}
                                                withSearchIcon={false}
                                            />
                                        )}
                                    </div>
                                }
                                action={
                                    <>
                                        {activeTab === 0 && canAll && (
                                            <Button
                                                color="secondary"
                                                startIcon={<AddIcon />}
                                                style={{ borderRadius: '62.4375rem' }}
                                                onClick={() =>
                                                    history.push(
                                                        quotes.base +
                                                            quotes.nomenclature.base +
                                                            quotes.nomenclature.create
                                                    )
                                                }
                                            >
                                                {t('Add new article')}
                                            </Button>
                                        )}

                                        {activeTab === 1 && canAll && (
                                            <Button
                                                color="secondary"
                                                startIcon={<AddIcon />}
                                                style={{ borderRadius: '62.4375rem' }}
                                                onClick={() =>
                                                    history.push(
                                                        quotes.base +
                                                            quotes.nomenclature.base +
                                                            quotes.categories.base +
                                                            quotes.categories.create
                                                    )
                                                }
                                            >
                                                {t('Add new article category')}
                                            </Button>
                                        )}

                                        {activeTab === 2 && canAll && (
                                            <Button
                                                color="secondary"
                                                startIcon={<AddIcon />}
                                                style={{ borderRadius: '62.4375rem' }}
                                                onClick={() => handleOpenStockModal(null)}
                                            >
                                                {t('Create stock')}
                                            </Button>
                                        )}
                                    </>
                                }
                                toolbar={
                                    <Tabs
                                        tabs={tabs}
                                        activeTab={activeTab}
                                        setActiveTab={(i) => {
                                            setIsOpenStockModal({ articleId: null, open: false });
                                            setActiveTab(i);
                                        }}
                                    />
                                }
                                toolbarSecondary={
                                    activeTab === 0 ? (
                                        <div className="flex items-center gap-10 text-main-text">
                                            <RadioGroup
                                                aria-label="status"
                                                name="status-filter"
                                                value={purposeSortType}
                                                onChange={(e) => setPurposeSortType(e.target.value)}
                                            >
                                                <div className="no-user-select-recursive flex items-center text-main-text">
                                                    <div className="flex flex-wrap">
                                                        <FormControlLabel
                                                            key={'ALL'}
                                                            value={'ALL'}
                                                            control={<Radio />}
                                                            label={t('All')}
                                                        />
                                                        <FormControlLabel
                                                            key="CRM"
                                                            value="CRM"
                                                            control={<Radio />}
                                                            label={t('CRM Articles')}
                                                        />
                                                        <FormControlLabel
                                                            key="PM"
                                                            value="PM"
                                                            control={<Radio />}
                                                            label={t('PM Articles')}
                                                        />
                                                    </div>
                                                </div>
                                            </RadioGroup>

                                            <div className="h-10 w-1 bg-layout-transparent" />

                                            <RadioGroup
                                                aria-label="status"
                                                name="status-filter"
                                                value={categorySortCrit}
                                                onChange={(e) => setCategorySortCrit(e.target.value)}
                                            >
                                                <div className="no-user-select-recursive -mr-3 flex items-center text-main-text">
                                                    <div className="flex flex-wrap">
                                                        <FormControlLabel
                                                            key={'ALL'}
                                                            value={'ALL'}
                                                            control={<Radio />}
                                                            label={t('All')}
                                                        />
                                                        {categories.map((c) => (
                                                            <FormControlLabel
                                                                key={c.id}
                                                                value={c.id}
                                                                control={<Radio />}
                                                                label={c.name}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </RadioGroup>

                                            <div className="h-10 w-1 bg-layout-transparent" />

                                            <RadioGroup
                                                aria-label="status"
                                                name="status-filter"
                                                value={sortType}
                                                onChange={(e) => setSortType(e.target.value)}
                                            >
                                                <div className="no-user-select-recursive flex items-center text-main-text">
                                                    <div className="flex flex-wrap">
                                                        <FormControlLabel
                                                            key={'ALL'}
                                                            value={'ALL'}
                                                            control={<Radio />}
                                                            label={t('All')}
                                                        />
                                                        {type.map((t, index) => (
                                                            <FormControlLabel
                                                                key={t}
                                                                value={articleType[index]}
                                                                control={<Radio />}
                                                                label={t}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    ) : null
                                }
                            ></Header>
                        </>
                    )}

                    <div className={`${!setInitializeData ? 'page-container' : ''}`}>
                        <div className="flex gap-16">
                            <div className="z-40 flex-grow">
                                {activeTab === 0 && (
                                    <DisplayQueryResults
                                        query={query}
                                        loading={loading}
                                        setLoading={setLoading}
                                        getFunction={getArticles}
                                        getSearchFunction={getSearchedArticles}
                                        refreshList={[sortType, categorySortCrit, purposeSortType]}
                                    >
                                        {loading ? (
                                            <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                                                <CircularProgress />
                                            </div>
                                        ) : articles.length ? (
                                            <TableContainer component={Box}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>{t('Article name')}</TableCell>
                                                            <TableCell>{t('Article category')}</TableCell>
                                                            <TableCell>{t('Price without VAT')}</TableCell>
                                                            <TableCell>{t('VAT rate')}</TableCell>
                                                            <TableCell>{t('VAT value')}</TableCell>
                                                            <TableCell>{t('Final price with VAT')}</TableCell>
                                                            <TableCell>{t('Variables')}</TableCell>
                                                            <TableCell>{t('Project')}</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableSeparator />
                                                        {articles.map((nomenclature) => (
                                                            <NomenclatureRow
                                                                key={nomenclature.id}
                                                                nomenclature={nomenclature}
                                                                isInitializationCase={!!setInitializeData}
                                                                onOpensStockModal={handleOpenStockModal}
                                                                setLoading={setLoading}
                                                                getArticlesCallback={() =>
                                                                    !isSearch
                                                                        ? getArticles(
                                                                              currentPageForArticles.current,
                                                                              10
                                                                          )
                                                                        : getSearchedArticles(
                                                                              currentPageForArticles.current,
                                                                              10
                                                                          )
                                                                }
                                                            />
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <NoDataPlaceholder />
                                        )}
                                    </DisplayQueryResults>
                                )}
                                {activeTab === 1 && <ArticleCategories />}
                                {activeTab === 2 && <ManageStocks />}
                            </div>
                            {isOpenStockModal.open && (
                                <>
                                    <div className="sticky top-6 z-50 h-full rounded-lg bg-layout-transparent xl:fixed xl:left-2/4 xl:top-2/4 xl:h-auto xl:-translate-x-2/4 xl:-translate-y-2/4 xl:transform xl:bg-layout-light">
                                        <CreateStockModal
                                            onClose={handleCloseStockModal}
                                            articleId={isOpenStockModal.articleId}
                                        />
                                    </div>
                                    <div
                                        className={`fixed left-0 top-0 z-40 hidden h-svh w-screen ${
                                            isOpenStockModal.open ? 'xl:flex' : ''
                                        }`}
                                        style={{ backgroundColor: 'rgba(0,0,0,0.3)', backdropFilter: 'blur(5px)' }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

ManageNomenclatures.propTypes = {
    setInitializeData: PropTypes.func,
};

ManageNomenclatures.defaultValues = {
    setInitializeData: null,
};

export default ManageNomenclatures;
