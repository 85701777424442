import React, { Fragment, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { ReactComponent as AllocationResourcesIcon } from '../../../assets/resource-management/svgs/resources-allocation-icon.svg';
import { ReactComponent as HumanResourcesIcon } from '../../../assets/resource-management/svgs/resources-human-icon.svg';
import { ReactComponent as MaterialResourcesIcon } from '../../../assets/resource-management/svgs/resources-material-icon.svg';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { useResources } from 'contexts/ResourcesContext';
import { useActiveNavLink } from 'hooks/useActiveNavLink';
import { Header, NavLinkTabs, Search } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { resourceModule } from 'routes';

import AllocationResources from '../allocation-resources/allocation-resources';
import HumanResources from '../human-resources/human-resources';
import MaterialResources from '../material-resources/material-resources';

const ResourcesPage = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const isEditMaterial = useRouteMatch(resourceModule.materialResources.editFull);
    const isEditHuman = useRouteMatch(resourceModule.humanResources.editFull);
    const isEditAllocation = useRouteMatch(resourceModule.allocationResources.editFull);
    const isAlternating = isEditMaterial || isEditHuman || isEditAllocation;

    const { query, filterParameters, setQuery, onUpdateFilterParameters, title, setTitle } = useResources();

    const resourceTypeTabs = [
        {
            children: (
                <Fragment>
                    <span className="inline-block">
                        <HumanResourcesIcon />
                    </span>
                    {t('Human resources')}
                </Fragment>
            ),
            route: resourceModule.humanResources.base,
        },
        {
            children: (
                <Fragment>
                    <span className="inline-block">
                        <MaterialResourcesIcon />
                    </span>
                    {t('Material resources')}
                </Fragment>
            ),
            route: resourceModule.materialResources.base,
        },
        {
            children: (
                <Fragment>
                    <span className="inline-block">
                        <AllocationResourcesIcon />
                    </span>
                    {t('Resource allocation')}
                </Fragment>
            ),
            route: resourceModule.allocationResources.base,
        },
    ];

    const humanTabs = [
        {
            children: t('Resource categories'),
            route: resourceModule.humanResources.categories,
        },
        {
            children: isEditHuman ? t('Edit resource') : t('Add resource'),
            route: isEditHuman
                ? resourceModule.humanResources.edit + '/' + isEditHuman.params.id
                : resourceModule.humanResources.add,
        },
    ];

    const materialTabs = [
        {
            children: t('Resource categories'),
            route: resourceModule.materialResources.categories,
        },
        {
            children: isEditMaterial ? t('Edit resource') : t('Add resource'),
            route: isEditMaterial
                ? resourceModule.materialResources.edit + '/' + isEditMaterial.params.id
                : resourceModule.materialResources.add,
        },
    ];

    const allocationTabs = [
        {
            children: t('Allocated resources'),
            route: resourceModule.allocationResources.all,
        },
        {
            children: isEditAllocation ? t('Edit allocated resource') : t('Allocate resource'),
            route: isEditAllocation
                ? resourceModule.allocationResources.edit + '/' + isEditAllocation.params.id
                : resourceModule.allocationResources.add,
        },
    ];

    const resourcesTypeActiveTab = useActiveNavLink(resourceTypeTabs.map((tab) => tab.route));
    const humanActiveTab = useActiveNavLink(humanTabs.map((tab) => tab.route));
    const materialActiveTab = useActiveNavLink(materialTabs.map((tab) => tab.route));
    const allocationActiveTab = useActiveNavLink(allocationTabs.map((tab) => tab.route));

    useEffect(() => {
        if (humanActiveTab === 0 || materialActiveTab === 0) setTitle(t('Resource categories'));
        if (humanActiveTab === 1 || materialActiveTab === 1)
            setTitle(!isAlternating ? t('Add resource') : t('Edit resource'));
        if (allocationActiveTab === 0) setTitle(t('Allocated resources'));
        if (allocationActiveTab === 1) setTitle(!isAlternating ? t('Allocate resource') : t('Edit allocated resource'));
    }, [location.pathname]);

    return (
        <Fragment>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Header
                pageTitle={title}
                toolbar={
                    <div className="flex items-center justify-between gap-10 sm:flex-col sm:items-start sm:gap-4">
                        <NavLinkTabs small tabs={resourceTypeTabs} />

                        <Fragment>
                            {resourcesTypeActiveTab === 0 && <NavLinkTabs tabs={humanTabs} />}
                            {resourcesTypeActiveTab === 1 && <NavLinkTabs tabs={materialTabs} />}
                            {resourcesTypeActiveTab === 2 && <NavLinkTabs tabs={allocationTabs} />}
                        </Fragment>
                    </div>
                }
                toolbarSecondary={
                    (humanActiveTab === 0 || materialActiveTab === 0 || allocationActiveTab === 0) && (
                        <Fragment>
                            <div className="flex items-center justify-between gap-10 sm:flex-col-reverse sm:items-start sm:gap-4">
                                {humanActiveTab === 0 && <Fragment>&nbsp;</Fragment>}

                                {materialActiveTab === 0 && (
                                    <RadioGroup
                                        aria-label="status"
                                        name="type"
                                        value={filterParameters.type}
                                        onChange={onUpdateFilterParameters}
                                    >
                                        <div className="no-user-select-recursive -mr-3 flex items-center text-main-text">
                                            <div className="flex flex-wrap">
                                                <FormControlLabel
                                                    key={'All'}
                                                    value={'All'}
                                                    control={<Radio />}
                                                    label={t('All')}
                                                />
                                                <FormControlLabel
                                                    key={'Fixed means'}
                                                    value={'FIXED_ASSETS'}
                                                    control={<Radio />}
                                                    label={t('Fixed means')}
                                                />

                                                <FormControlLabel
                                                    key={'Inventory objects'}
                                                    value={'INVENTORY_OBJECTS'}
                                                    control={<Radio />}
                                                    label={t('Inventory objects')}
                                                />

                                                <FormControlLabel
                                                    key={'Consumables'}
                                                    value={'CONSUMABLES'}
                                                    control={<Radio />}
                                                    label={t('Consumables')}
                                                />

                                                <FormControlLabel
                                                    key={'Intangible assets'}
                                                    value={'INTANGIBLE_ASSETS'}
                                                    control={<Radio />}
                                                    label={t('Intangible assets')}
                                                />

                                                {/* <FormControlLabel
                                            key={'External resources'}
                                            value={'EXTERNAL'}
                                            control={<Radio  />}
                                            label={t('External resources')}
                                        /> */}
                                            </div>
                                        </div>
                                    </RadioGroup>
                                )}

                                {allocationActiveTab === 0 && <Fragment>&nbsp;</Fragment>}

                                <Search searchIconBig={true} value={query} setValue={setQuery} withSearchIcon={false} />
                            </div>
                        </Fragment>
                    )
                }
            />
            <Switch>
                <Route exact path={resourceModule.resources.base}>
                    <Redirect to={resourceModule.humanResources.base} />
                </Route>
                <Route path={resourceModule.humanResources.base}>
                    <HumanResources />
                </Route>
                <Route path={resourceModule.materialResources.base}>
                    <MaterialResources />
                </Route>
                <Route path={resourceModule.allocationResources.base}>
                    <AllocationResources />
                </Route>
            </Switch>
        </Fragment>
    );
};

export default ResourcesPage;
