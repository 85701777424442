import React, { useContext, useState } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Button, Checkbox, CircularProgress, FormControlLabel, TextField } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import useScreenSizes from 'hooks/useScreenSizes';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const ModalExportAddAlias = ({ loading, setOpen, dynamicName, handleExport, withAlias }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [width] = useScreenSizes();

    const { currencyObj, referenceCurrencyObj } = useContext(GlobalContext);

    const [formData, setFormData] = useState({
        alias: dynamicName,
        fileName: dynamicName,
        nationalCurrency: true,
        referenceCurrency: true,
    });

    const handleUpdateFormData = (e) => setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const schema = yup.object().shape({
        alias: yup
            .string()
            .min(3, t('The report alias must be at least three characters long'))
            .required('The report alias is mandatory'),
        fileName: yup
            .string()
            .min(3, t('The report fileName must be at least three characters long'))
            .required('The report fileName is mandatory'),
        nationalCurrency: yup.boolean().test({
            name: 'Has selected currency in national field',
            message: t('You must select a currency for the report'),
            test: function (nationalValue) {
                const { referenceCurrency } = this.parent;
                return nationalValue || referenceCurrency;
            },
        }),
        referenceCurrency: yup.boolean().test({
            name: 'Has selected currency in reference field',
            message: t('You must select a currency for the report'),
            test: function (referenceValue) {
                const { nationalCurrency } = this.parent;
                return referenceValue || nationalCurrency;
            },
        }),
    });

    const onexportImagesAsPDF = async () => {
        try {
            await schema.validate(formData);

            handleExport(formData.alias, formData.fileName, formData.nationalCurrency, formData.referenceCurrency);
        } catch (error) {
            enqueueSnackbar(error.errors[0], { variant: 'error' });
        }
    };

    return (
        <div className="flex flex-col items-center gap-8 p-10 sm:w-full" style={{ width: width > 600 && '45rem' }}>
            <h2>{t('Export details')}</h2>

            {withAlias && (
                <LabelWrapper label={t('Enter an alias for the generated report')}>
                    <TextField
                        placeholder={t('Alias')}
                        name="alias"
                        value={formData.alias}
                        onChange={handleUpdateFormData}
                    />
                </LabelWrapper>
            )}

            <LabelWrapper label={t('Enter file name')}>
                <TextField
                    placeholder={t('File name')}
                    name="fileName"
                    value={formData.fileName}
                    onChange={handleUpdateFormData}
                />
            </LabelWrapper>

            <div className="flex items-center justify-between gap-8 sm:flex-col">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.nationalCurrency}
                            name="nationalCurrency"
                            onChange={(e) =>
                                handleUpdateFormData({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                    },
                                })
                            }
                            style={{
                                color: 'var(--primary-main)',
                            }}
                            color="primary"
                        />
                    }
                    label={
                        <p className="user-select-none">{`${t('Generate report in the national currency')} (${
                            currencyObj.currency
                        })`}</p>
                    }
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.referenceCurrency}
                            name="referenceCurrency"
                            onChange={(e) =>
                                handleUpdateFormData({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                    },
                                })
                            }
                            style={{
                                color: 'var(--primary-main)',
                            }}
                            color="primary"
                        />
                    }
                    label={
                        <p className="user-select-none">{`${t('Generate report in the reference currency')} (${
                            referenceCurrencyObj.currency
                        })`}</p>
                    }
                />
            </div>

            <div className="flex w-full justify-end gap-4">
                <Button onClick={() => setOpen(false)} startIcon={<CancelIcon />} color="secondary" disabled={loading}>
                    {t('Cancel')}
                </Button>
                <Button
                    startIcon={loading ? <CircularProgress size={20} /> : <CheckCircleIcon />}
                    color="secondary"
                    className="focus:outline-none disabled:!bg-slate-600 disabled:!text-slate-400"
                    onClick={loading ? undefined : onexportImagesAsPDF}
                >
                    {loading ? t("It's downloading") : t('Download report')}
                </Button>
            </div>
        </div>
    );
};

ModalExportAddAlias.propTypes = {
    handleExport: PropTypes.func,
    setOpen: PropTypes.func,
    dynamicName: PropTypes.string,
    loading: PropTypes.bool,
    withAlias: PropTypes.bool,
};

ModalExportAddAlias.defaultProps = {
    handleExport: () => {},
    setOpen: () => {},
    dynamicName: '',
    loading: false,
    withAlias: true,
};

export default ModalExportAddAlias;
