import React from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import { Button, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import InitializeButtons from 'pages/initialize-company/initialize-buttons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import API from 'utils/axios';
import * as yup from 'yup';

const EmailTemplateForm = ({ template, initializeData, handleDecrementStep, handleIncrementStep }) => {
    const { t } = useTranslation();

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = React.useState(false);

    const defaultTemplatePallet = {
        templateName: '',
        headerBackground: '#2d7af7',
        headerFont: '#fcfcfc',
        footerBackground: '#2d7af7',
        footerFont: '#fcfcfc',
    };

    const [colors, setColors] = React.useState(defaultTemplatePallet);

    const handleColorsChange = (key, value) => {
        const colorsObjCopy = { ...colors };
        colorsObjCopy[key] = value;
        setColors(colorsObjCopy);
    };

    React.useEffect(() => {
        if (Object.keys(template).length > 0) {
            const templatePallet = {
                templateName: template.name,
                headerBackground: template.headerPalette.backgroundColor,
                headerFont: template.headerPalette.foregroundColor,
                footerBackground: template.footerPalette.backgroundColor,
                footerFont: template.footerPalette.foregroundColor,
            };

            setColors(templatePallet);
        }
    }, [template]);

    const palletValidationSchema = yup.object().shape({
        templateName: yup
            .string()
            .typeError(t('The name for the email template is required!'))
            .trim()
            .required(t('The name for the email template is required!')),
    });

    const handleEmailTemplatePalletEdit = async () => {
        try {
            setLoading(true);

            await palletValidationSchema.validate({
                templateName: colors.templateName,
            });

            const requestBodyForUpdate = {
                id: template.id,
                data: {
                    name: colors.templateName,
                    headerPalette: {
                        backgroundColor: colors.headerBackground,
                        foregroundColor: colors.headerFont,
                    },
                    footerPalette: {
                        backgroundColor: colors.footerBackground,
                        foregroundColor: colors.footerFont,
                    },
                },
            };

            await API.put('email_template', requestBodyForUpdate);
            if (!initializeData) history.push(configurator.base + '?tab=Email templates');
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <div className="flex md:flex-col">
                <div className="m-3 ">
                    <div className="mb-8 max-w-md">
                        <TextField
                            name={'email'}
                            label={t('Email template name')}
                            placeholder={t('Add name to the email template')}
                            value={colors.templateName}
                            onChange={(e) => handleColorsChange('templateName', e.target.value)}
                        />
                    </div>
                    <div className="mb-10">
                        <p className="mb-8 text-3xl font-bold uppercase">{t('Header')}</p>
                        <div className="flex flex-col gap-6">
                            <div className="flex items-center gap-4">
                                <input
                                    type="color"
                                    name="head"
                                    value={colors.headerBackground}
                                    onChange={(e) => handleColorsChange('headerBackground', e.target.value)}
                                ></input>
                                <p className="text-xl uppercase">{t('Background color')}</p>
                            </div>

                            <div className="flex items-center gap-4">
                                <input
                                    type="color"
                                    name="head"
                                    value={colors.headerFont}
                                    onChange={(e) => handleColorsChange('headerFont', e.target.value)}
                                ></input>
                                <p className="text-xl uppercase">{t('Font color')}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="mb-8 text-3xl font-bold uppercase">{t('Footer')}</p>
                        <div className="flex flex-col gap-6">
                            <div className="flex items-center gap-4">
                                <input
                                    type="color"
                                    id="head"
                                    name="head"
                                    value={colors.footerBackground}
                                    onChange={(e) => handleColorsChange('footerBackground', e.target.value)}
                                ></input>
                                <p className="text-xl uppercase">{t('Background color')}</p>
                            </div>

                            <div className="flex items-center gap-4">
                                <input
                                    type="color"
                                    id="head"
                                    name="head"
                                    value={colors.footerFont}
                                    onChange={(e) => handleColorsChange('footerFont', e.target.value)}
                                ></input>
                                <p className="text-xl uppercase">{t('Font color')}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="m-4 max-w-2xl lg:max-w-md">
                    <p className="mb-4 text-xl">{t('Preview')}</p>
                    <div className="flex h-full flex-col">
                        <div
                            className="flex-2 p-4"
                            style={{ backgroundColor: colors.headerBackground, borderRadius: '10px 10px 0 0' }}
                        >
                            <p className="text-xl font-bold" style={{ color: colors.headerFont }}>
                                M2M Solutions
                            </p>
                        </div>
                        <div className="flex-1 bg-white p-3">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </div>
                        <div
                            className="flex-2 p-4 text-center"
                            style={{ backgroundColor: colors.footerBackground, borderRadius: '0 0 10px 10px' }}
                        >
                            <p className="text-lg" style={{ color: colors.footerFont }}>
                                M2M Solutions 2023 © All rights reserved
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-14">
                {template ? (
                    initializeData ? (
                        <InitializeButtons
                            handleIncrementStep={handleIncrementStep}
                            handleDecrementStep={handleDecrementStep}
                            handleIncrementCallback={handleEmailTemplatePalletEdit}
                            className={'mt-20 flex justify-start gap-4'}
                            canSkip={true}
                            nextDisabled={loading}
                            prevDisabled={loading}
                            skipDisabled={loading}
                        />
                    ) : (
                        <Button
                            startIcon={<CheckIcon />}
                            color="primary"
                            onClick={handleEmailTemplatePalletEdit}
                            disabled={loading ? true : false}
                        >
                            {t('Edit email template')}
                        </Button>
                    )
                ) : (
                    <Button
                        startIcon={<AddIcon />}
                        color="primary"
                        onClick={() => null}
                        disabled={loading ? true : false}
                    >
                        {t('Create email template')}
                    </Button>
                )}
            </div>
        </React.Fragment>
    );
};

EmailTemplateForm.propTypes = {
    template: PropTypes.object,
    initializeData: PropTypes.object,
    handleDecrementStep: PropTypes.func,
    handleIncrementStep: PropTypes.func,
};

EmailTemplateForm.defaultProps = {
    template: {},
    initializeData: null,
    handleDecrementStep: null,
    handleIncrementStep: null,
};

export default EmailTemplateForm;
