import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import EditIcon from '@material-ui/icons/Edit';

import BasicTooltip from 'components/shared/basic-tooltip';
import SuccessButton from 'components/shared/success-button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

import { RiemContext } from '../riem';
import RiemInfoForm from './riem-info-form';

const RiemInfo = ({ riem, companyLogo, renderType, height }) => {
    const { t } = useTranslation();

    const [isMounted, setIsMounted] = useState(false);
    const { isSaving, isOperatingOnRowAt, setIsOperatingOnRowAt } = useContext(RiemContext);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (isOperatingOnRowAt === 'info') return <RiemInfoForm riem={riem} companyLogo={companyLogo} />;

    return (
        <div
            role="row"
            className="col-span-full grid border-l border-r border-t border-black bg-pdf-layout-background"
            style={{
                gridTemplateColumns: 'subgrid',
                height: height ?? '',
            }}
        >
            <div role="columnheader" colSpan="2" className="col-span-2 p-6">
                <img
                    src={companyLogo}
                    placeholder="Logo"
                    style={{
                        minWidth: '5rem',
                        maxWidth: '5rem',
                    }}
                />
            </div>
            <div role="columnheader" colSpan="7" className="col-span-7 flex items-center justify-center">
                <p className="p-6 text-4xl font-bold text-pdf-layout-text">{riem.info.title}</p>
            </div>
            <div role="columnheader" colSpan="4" className="col-span-4 flex items-center justify-end">
                <div className="flex h-full flex-col justify-between gap-12 p-6">
                    <p className="text-right text-xl font-bold text-pdf-layout-text">{`${riem.info.project}: ${riem.project.name}`}</p>
                    <p className="text-right text-xl font-bold text-pdf-layout-text">{`${riem.info.beneficiary}: ${
                        typeof riem.project.contact.data === 'string'
                            ? JSON.parse(riem.project.contact.data).standard.name
                            : riem.project.contact.data.standard.name
                    }`}</p>
                    <p className="whitespace-pre-wrap text-right text-xl font-bold text-pdf-layout-text">
                        {`${riem.info.dates}\n${formatDate(riem.createdAt, true, true)} / ${formatDate(
                            riem.updatedAt,
                            true,
                            true
                        )}`}
                    </p>
                </div>
            </div>

            {!renderType &&
                isMounted &&
                createPortal(
                    <div className="flex items-center justify-center">
                        <BasicTooltip
                            tip={t("You can't add new rows while you create or edit an existing one")}
                            disabled={isOperatingOnRowAt === null}
                        >
                            <SuccessButton
                                disabled={isSaving || isOperatingOnRowAt !== null}
                                startIcon={<EditIcon />}
                                size="small"
                                onClick={() => setIsOperatingOnRowAt('info')}
                            >
                                {t('Edit')}
                            </SuccessButton>
                        </BasicTooltip>
                    </div>,
                    document.getElementById('riem-info-container')
                )}
        </div>
    );
};

RiemInfo.propTypes = {
    riem: PropTypes.object,
    companyLogo: PropTypes.string,
    renderType: PropTypes.string,
    height: PropTypes.string,
};

RiemInfo.defaultProps = {
    riem: {},
    companyLogo: '',
    renderType: null,
    height: null,
};

export default RiemInfo;
