import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import FingerprintIcon from '@material-ui/icons/Fingerprint';

import { Button, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { Layout } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { login } from 'routes';
import API from 'utils/axios';

const iconStyle = {
    marginRight: '1rem',
    color: '#fff',
    fontSize: '4rem',
    flexShrink: 0,
};

const VerifyPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [passwordRe, setPasswordRe] = useState('');
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const onSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;
        if (!password.length) {
            enqueueSnackbar('Password is not valid!', { variant: 'error' });
            return;
        }
        if (password !== passwordRe) {
            enqueueSnackbar('Passwords must be the same!', { variant: 'error' });
            return;
        }

        setLoading(true);

        try {
            await API.post('/reset_password', {
                token,
                newPassword: password,
            });
            enqueueSnackbar('Password reset successfully!', { variant: 'success' });
            history.push(login);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>ERP | Recover Password</title>
            </Helmet>

            <Layout sidebar={false}>
                <form className="relative flex h-svh w-full items-center justify-center" onSubmit={onSubmit}>
                    <div className="w-full max-w-sm -translate-y-12 transform">
                        <div className="mb-12 flex items-center" style={{ userSelect: 'none' }}>
                            <FingerprintIcon style={iconStyle} />
                            <h2 className="whitespace-nowrap font-medium text-white">Reset Password</h2>
                        </div>

                        <div className="mb-10 w-full">
                            <div className="mb-5">
                                <TextField
                                    label="New password"
                                    placeholder="New password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <TextField
                                    label="New password again"
                                    placeholder="New password again"
                                    type="password"
                                    value={passwordRe}
                                    onChange={(e) => setPasswordRe(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="flex">
                            <Button
                                type="submit"
                                fullWidth
                                color="primary"
                                style={{
                                    transition: 'opacity .2s ease',
                                    pointerEvents: loading ? 'none' : 'all',
                                    opacity: loading ? '.5' : '1',
                                }}
                            >
                                Reset Password
                            </Button>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    );
};

export default VerifyPassword;
