import React from 'react';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import BallotIcon from '@material-ui/icons/Ballot';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EmailIcon from '@material-ui/icons/Email';
import Filter1Icon from '@material-ui/icons/Filter1';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ImageIcon from '@material-ui/icons/Image';
import LinkIcon from '@material-ui/icons/Link';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import PhoneIcon from '@material-ui/icons/Phone';
import StorageIcon from '@material-ui/icons/Storage';
import TitleIcon from '@material-ui/icons/Title';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';

import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents/index.js';

const fieldIconStyle = {
    fontSize: '1.25rem',
    flexShrink: 0,
};

const iconMapper = {
    Text: <TitleIcon className="text-buttons-text" style={fieldIconStyle} />,
    Number: <Filter1Icon className="text-buttons-text" style={fieldIconStyle} />,
    Phone: <PhoneIcon className="text-buttons-text" style={fieldIconStyle} />,
    Email: <EmailIcon className="text-buttons-text" style={fieldIconStyle} />,
    Link: <LinkIcon className="text-buttons-text" style={fieldIconStyle} />,

    Selector: <FormatListBulletedIcon className="text-buttons-text" style={fieldIconStyle} />,
    Structure: <BallotIcon className="text-buttons-text" style={fieldIconStyle} />,
    Toggle: <ToggleOffIcon className="text-buttons-text" style={fieldIconStyle} />,
    Date: <DateRangeIcon className="text-buttons-text" style={fieldIconStyle} />,
    Image: <ImageIcon className="text-buttons-text" style={fieldIconStyle} />,
    File: <AttachFileIcon className="text-buttons-text" style={fieldIconStyle} />,

    Criterion: <MenuOpenIcon className="text-buttons-text" style={fieldIconStyle} />,
    Reference: <StorageIcon className="text-buttons-text" style={fieldIconStyle} />,
};

const ConfigFieldWrapper = (props) => {
    const { children, type, name, setName, canBeDeleted, deleteFieldFromColumn, hasOptions } = props;

    return (
        <div
            className={`no-user-select-recursive relative mb-4 w-full rounded-md border-transparent ${
                canBeDeleted ? null : 'border-primary-light'
            }`}
            style={{ borderWidth: '1px' }}
        >
            <div
                className={`group relative flex h-14 items-center bg-layout-transparent-dark px-3 pr-12 text-main-text ${
                    hasOptions ? 'rounded-tl-md rounded-tr-md' : 'rounded-md'
                }`}
            >
                <div className="mr-2.5 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-layout-transparent">
                    {iconMapper[type]}
                </div>

                <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="outline-none w-full border-none bg-transparent text-lg font-semibold text-buttons-text"
                />

                {canBeDeleted ? (
                    <div
                        className="absolute right-3 -translate-x-2 transform opacity-0 transition-all duration-150 group-hover:translate-x-0 group-hover:opacity-100"
                        onClick={deleteFieldFromColumn}
                    >
                        <ActionButton icon={<CloseIcon className="text-buttons-text" />} />
                    </div>
                ) : null}
            </div>

            {hasOptions ? (
                <div className="rounded-bl-md rounded-br-md bg-layout-transparent p-3">{children}</div>
            ) : null}

            {/* 3 dots */}
            <div className="group absolute left-0 top-0 flex h-14 w-6 -translate-x-full transform cursor-move flex-col items-center justify-center">
                {[1, 2, 3].map((el) => (
                    <div
                        key={el}
                        className="mb-1 h-1 w-1 rounded-full bg-primary-transparent transition-colors last:mb-0 group-hover:bg-primary-main"
                    ></div>
                ))}
            </div>
        </div>
    );
};

ConfigFieldWrapper.propTypes = {
    children: PropTypes.element,
    type: PropTypes.string,
    name: PropTypes.string,
    setName: PropTypes.func,
    canBeDeleted: PropTypes.bool,
    deleteFieldFromColumn: PropTypes.func,
    hasOptions: PropTypes.bool,
};

ConfigFieldWrapper.defaultProps = {
    children: <></>,
    type: null,
    name: '',
    setName: () => null,
    canBeDeleted: true,
    deleteFieldFromColumn: () => null,
    hasOptions: true,
};

export default ConfigFieldWrapper;
