import React from 'react';

import ManagePDFTemplates from 'pages/configurator/pdf-templates';
import CreatePDFTemplate from 'pages/configurator/pdf-templates/create-pdf-template';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InitializeButtons from './initialize-buttons';
import InitializeStepInfo from './initialize-step-info';

const InitializePDFTemplates = ({ handleIncrementStep, handleDecrementStep, initializeData, setInitializeData }) => {
    const { t } = useTranslation();

    return (
        <>
            <InitializeStepInfo
                mainInfo={[
                    { id: 0, content: t('You must add PDF templates in order to generate the PDF of an offer') },
                ]}
                className="mb-10"
            />
            {initializeData.tab === 0 && <ManagePDFTemplates setInitializeData={setInitializeData} />}
            {initializeData.tab === 1 && <CreatePDFTemplate setInitializeData={setInitializeData} />}
            <InitializeButtons
                handleIncrementStep={handleIncrementStep}
                handleDecrementStep={handleDecrementStep}
                className={'mt-20 flex justify-start gap-4'}
                canSkip={false}
                nextDisabled={initializeData.nextDisabled || initializeData.loading}
                prevDisabled={initializeData.loading}
            />
        </>
    );
};

InitializePDFTemplates.propTypes = {
    handleIncrementStep: PropTypes.func,
    handleDecrementStep: PropTypes.func,
    initializeData: PropTypes.object,
    setInitializeData: PropTypes.func,
};

InitializePDFTemplates.defaultProps = {
    handleIncrementStep: null,
    handleDecrementStep: null,
    initializeData: {},
    setInitializeData: () => {},
};

export default InitializePDFTemplates;
