import React from 'react';

import OfferTagForm from 'components/crm/offer-tag-form';
import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateOfferTag = ({ setInitializeData }) => {
    const { t } = useTranslation();

    if (setInitializeData) return <OfferTagForm setInitializeData={setInitializeData} />;

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Add new offer tag')}</title>
            </Helmet>

            <Header pageTitle={t('Add new offer tag')} />
            <div className="page-container">
                <OfferTagForm />
            </div>
        </>
    );
};

CreateOfferTag.propTypes = {
    setInitializeData: PropTypes.func,
};

CreateOfferTag.defaultProps = {
    setInitializeData: null,
};

export default CreateOfferTag;
