import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { TextField } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import OfferArticleHistory from 'pages/quotes/newBidding/offer-article-history';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { getCompanyVAT } from 'utils/getterFunctions';
import { calculateTotal, calculateVATValue, formatPositiveNumber, toLocaleNumber } from 'utils/index';

import CustomVariable from './custom-variable';
import VariableRow from './variable-row';

const ArticleRow = ({ article, editArticle, deleteArticle, unitList, setUnitList, disabled }) => {
    const { t } = useTranslation();
    const { currencyObj } = useContext(GlobalContext);

    const [articleRow, setArticleRow] = useState(false);

    const [variableOption, setVariableOption] = useState(null);
    const [variableToDisplay, setVariableToDisplay] = useState([]);

    const [stopCustomVariable, setStopCustomVariable] = useState(null);
    const [companyVat, setCompanyVat] = useState(null);

    useEffect(() => {
        if (article.amount === undefined)
            editArticle(article.id, 'amount', article.quantity === undefined ? 1 : article.quantity);
    }, [article]);

    useEffect(() => {
        const baseVariableList = article.options
            .filter((vr) => article.selectedVariables.findIndex((vr2) => vr2.name == vr.name) < 0)
            .map((vr) => vr.name);
        if (
            !stopCustomVariable &&
            !article.selectedVariables
                .map((op) => {
                    if (op.custom && op.custom === true) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .some((el) => el === true)
        ) {
            baseVariableList.push(t('Add custom variable'));
        }

        setVariableToDisplay(baseVariableList);
    }, [article, article.selectedVariables, stopCustomVariable]);

    const finalPriceArticle =
        Number(article.price.pricePerUnit * article.amount) +
        Number(
            article.selectedVariables
                ?.map((v) => Number(v.price.pricePerUnit * (v.amount || v.quantity))) // variables on the article don't have amount every time and we choose between amount and quantity
                ?.reduce((a, b) => a + b, 0)
        );

    const totalVatValue =
        Number(calculateVATValue(article.price.pricePerUnit, article.price.procentualVAT) * article.amount) +
        article.selectedVariables
            ?.map((v) =>
                Number(calculateVATValue(v.price.pricePerUnit, v.price.procentualVAT) * (v.amount || v.quantity))
            ) // variables on the article don't have amount every time and we choose between amount and quantity
            ?.reduce((a, b) => a + b, 0);

    const finalPriceArticleVAT = finalPriceArticle + totalVatValue;

    const deleteVar = (id) => {
        if (id === stopCustomVariable) setStopCustomVariable(null);
        const newSelectedVariables = [...article.selectedVariables].filter((v) => v.id != id);
        editArticle(article.id, 'selectedVariables', newSelectedVariables);
    };

    useEffect(() => {
        (async () => {
            const vatValue = await getCompanyVAT();
            setCompanyVat(vatValue);
        })();
    }, []);

    const [newCustomVariable, setNewCustomVariable] = useState(null);
    const defaultVariable = useMemo(() => {
        return {
            id: Math.random(),
            custom: true,
            amount: 1,
            quantity: 1,
            order: 2,
            name: '',
            sku: null,
            description: '',
            articleId: article.id,
            priceId: null,
            price: {
                id: null,
                priceProcent: null,
                type: 'SIMPLE',
                unit: null,
                unitName: '',
                pricePerUnit: null,
                procentualVAT: companyVat,
            },
        };
    }, [companyVat]);

    // const [unitList, setUnitList] = useState([
    //     t('Hours'),
    //     t('Days'),
    //     t('Piece'),
    //     'Kg',
    //     t('Linear meter'),
    //     t('Square meter'),
    //     t('Add new UM'),
    // ]);

    const appendCustomVariable = useCallback(() => {
        if (!stopCustomVariable) {
            editArticle(article.id, 'selectedVariables', [...article.selectedVariables, newCustomVariable]);
            setNewCustomVariable(null);
            return newCustomVariable.id;
        }
        return null;
    }, [stopCustomVariable, article, article.selectedVariables, newCustomVariable]);

    const editCustomVariable = (key, value) => {
        const newVar = { ...newCustomVariable };
        newVar[key] = value;
        setNewCustomVariable(newVar);
    };

    const editVariable = (id, key, value) => {
        const variableIndex = article.selectedVariables.findIndex((v) => v.id === id);
        const newVariable = [...article.selectedVariables];
        newVariable[variableIndex][key] = value;
        editArticle(article.id, 'selectedVariables', newVariable);
    };

    return (
        <>
            {article && (
                <div
                    className="relative mb-10"
                    style={{
                        zIndex: 100,
                    }}
                >
                    {article.stockId && (
                        <OfferArticleHistory
                            article={article}
                            style={{
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                                backgroundColor: 'var(--layout-transparent)',
                            }}
                        />
                    )}

                    <div
                        className={`relative inline-flex w-full justify-between bg-layout-transparent-dark p-5 sm:flex-col sm:gap-6 ${
                            !article.stockId || article.OfferArticles.length === 0 ? 'rounded-tl-md rounded-tr-md' : ''
                        }`}
                    >
                        <div className="flex gap-4 sm:justify-center">
                            {!article.stockId && (
                                <div className="w-24">
                                    <TextField
                                        name="cantitate"
                                        label={t('Amount')}
                                        placeholder={1}
                                        value={article.amount}
                                        onChange={(e) => {
                                            editArticle(article.id, 'amount', formatPositiveNumber(e.target.value));
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            )}
                            <div className="flex flex-col sm:flex-grow">
                                <h4 className="break-all pt-3 text-xl">{`${article.name}${
                                    article.stockId ? ` - (${article.Stock.name})` : ''
                                }`}</h4>
                                <h3 className="break-all text-lg text-dark-text">{article.description}</h3>
                            </div>
                        </div>

                        <div className="mr-10 flex sm:mr-0 sm:justify-center">
                            <div className="flex gap-2 sm:flex-grow">
                                <LabelWrapper label={t('Unit price')} noPadding>
                                    <div className="pointer-events-none whitespace-nowrap rounded-md border border-layout-transparent-dark bg-layout-transparent p-2 text-main-text">
                                        {toLocaleNumber(article.price.pricePerUnit, 2)} {currencyObj.currency}
                                    </div>
                                </LabelWrapper>
                                <LabelWrapper label={t('Price with VAT')} noPadding>
                                    <div className="pointer-events-none whitespace-nowrap rounded-md border border-layout-transparent-dark p-2 text-main-text">
                                        {toLocaleNumber(
                                            article.amount *
                                                calculateTotal(
                                                    calculateVATValue(
                                                        article.price.pricePerUnit,
                                                        article.price.procentualVAT
                                                    ),
                                                    article.price.pricePerUnit
                                                ),
                                            2
                                        )}{' '}
                                        {currencyObj.currency}
                                    </div>
                                </LabelWrapper>
                            </div>
                        </div>

                        <div
                            className="absolute right-4 top-2/4 -translate-y-2/4 transform cursor-pointer"
                            onClick={() => setArticleRow(!articleRow)}
                        >
                            <KeyboardArrowDownIcon
                                fontSize="medium"
                                style={{ color: 'var(--main-text)', transition: 'transform .2s ease' }}
                                className={`transform ${articleRow ? 'rotate-180' : 'rotate-0'}`}
                            />
                        </div>
                    </div>

                    {articleRow && (
                        <div className="inline-flex w-full gap-4 bg-layout-transparent p-5 sm:flex-col">
                            <LabelWrapper label={'UM'} noPadding>
                                <div className="pointer-events-none rounded-md border border-layout-transparent-dark p-2 text-main-text">
                                    {article.price.unit}
                                </div>
                            </LabelWrapper>

                            <LabelWrapper label={t('VAT rate')} noPadding>
                                <div className="pointer-events-none rounded-md border border-layout-transparent-dark bg-layout-transparent p-2 text-main-text">
                                    {article.price.procentualVAT} %
                                </div>
                            </LabelWrapper>

                            <LabelWrapper label={t('VAT value')} noPadding>
                                <div className="pointer-events-none rounded-md border border-layout-transparent-dark p-2 text-main-text">
                                    {(
                                        article.amount *
                                        calculateVATValue(article.price.pricePerUnit, article.price.procentualVAT)
                                    ).toFixed(2)}{' '}
                                    {currencyObj.currency}
                                </div>
                            </LabelWrapper>
                        </div>
                    )}

                    {articleRow && !disabled && (
                        <div className="left-0 top-full flex w-full justify-end rounded-bl-md rounded-br-md border-t border-layout-transparent-dark bg-layout-transparent">
                            <div className="text-main-text">
                                <p
                                    className="ml-2 flex cursor-pointer items-center p-4 text-main-text transition-colors hover:text-secondary-main"
                                    style={{ userSelect: 'none' }}
                                    onClick={deleteArticle}
                                >
                                    <CloseIcon className="mr-2 text-base" />
                                    {t('Delete article')}
                                </p>
                            </div>
                        </div>
                    )}

                    {/* Variables */}
                    {article.selectedVariables.map((v) => (
                        <VariableRow
                            variable={v}
                            editVariable={editVariable}
                            key={v.id}
                            deleteVar={() => deleteVar(v.id)}
                            unitList={unitList}
                            disabled={disabled}
                        />
                    ))}

                    {/* <CustomVariable /> */}
                    {newCustomVariable && (
                        <CustomVariable
                            variable={newCustomVariable}
                            setVariable={setNewCustomVariable}
                            editCustomVariable={editCustomVariable}
                            appendCustomVariable={appendCustomVariable}
                            setStopCustomVariable={setStopCustomVariable}
                            article={article}
                            unitList={unitList}
                            setUnitList={setUnitList}
                        />
                    )}

                    {article.options.length + 1 !== article.selectedVariables.length && !disabled && (
                        <>
                            {/* {variableToDisplay?.length ? ( */}
                            <div className="border-t border-layout-transparent-dark bg-layout-transparent p-5 hover:bg-layout-transparent-dark">
                                <div className="inline-block">
                                    <Dropdown
                                        style={{
                                            zIndex: 9999,
                                        }}
                                        options={variableToDisplay}
                                        selectedOption={variableOption}
                                        setSelectedOption={(newOption) => {
                                            if (
                                                newOption !== variableToDisplay.length - 1 ||
                                                stopCustomVariable ||
                                                article.selectedVariables
                                                    .map((op) => {
                                                        if (op.custom && op.custom === true) {
                                                            return true;
                                                        } else {
                                                            return false;
                                                        }
                                                    })
                                                    .some((el) => el === true)
                                            ) {
                                                const pos = article.options.findIndex(
                                                    (a) => a.name == variableToDisplay[newOption]
                                                );
                                                const variableToAdd = article.options[pos];
                                                const newSelectedVariables = [
                                                    ...article.selectedVariables,
                                                    variableToAdd,
                                                ];
                                                editArticle(article.id, 'selectedVariables', newSelectedVariables);
                                            } else {
                                                if (
                                                    !stopCustomVariable &&
                                                    !article.selectedVariables
                                                        .map((op) => {
                                                            if (op.custom && op.custom === true) {
                                                                return true;
                                                            } else {
                                                                return false;
                                                            }
                                                        })
                                                        .some((el) => el === true)
                                                )
                                                    setNewCustomVariable(defaultVariable);
                                            }
                                        }}
                                        placeholder={t('Add the variable')}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div className="inline-flex w-full items-center gap-4 rounded-bl-md rounded-br-md border-t border-layout-transparent-dark bg-layout-transparent-dark p-5">
                        <div className="w-1/5">
                            <h4 className="pt-4 text-xl">{t('Final price')}</h4>
                        </div>

                        <div className="grid w-full grid-cols-3 gap-4 sm:grid-cols-1">
                            <div>
                                <LabelWrapper label={t('Price without VAT')} noPadding>
                                    <div className="pointer-events-none rounded-md border border-layout-transparent-dark bg-layout-transparent p-3 text-main-text">
                                        {toLocaleNumber(finalPriceArticle, 2)} {currencyObj.currency}
                                    </div>
                                </LabelWrapper>
                            </div>

                            <div>
                                <LabelWrapper label={t('VAT value')} noPadding>
                                    <div className="pointer-events-none rounded-md border border-layout-transparent-dark bg-layout-transparent p-3 text-main-text">
                                        {toLocaleNumber(totalVatValue, 2)} {currencyObj.currency}
                                    </div>
                                </LabelWrapper>
                            </div>

                            <div>
                                <LabelWrapper label={t('Final price with VAT')} noPadding>
                                    <div className="pointer-events-none rounded-md border border-layout-transparent-dark bg-layout-transparent p-3 text-main-text">
                                        {toLocaleNumber(finalPriceArticleVAT, 2)} {currencyObj.currency}
                                    </div>
                                </LabelWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

ArticleRow.propTypes = {
    article: PropTypes.object,
    editArticle: PropTypes.func,
    deleteArticle: PropTypes.func,
    unitList: PropTypes.array,
    setUnitList: PropTypes.func,
    disabled: PropTypes.bool,
};

ArticleRow.defaultProps = {
    article: null,
    editArticle: () => null,
    deleteArticle: () => null,
    unitList: [],
    setUnitList: () => null,
    disabled: false,
};

export default ArticleRow;
