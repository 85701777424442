import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import EditZoneDetails from './edit-zone-details';
import ProjectsTable from './project-table';

const EditZones = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const { t } = useTranslation();

    const history = useHistory();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '12',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>
                    {t('Areas & Projects')} | {t('Area details')}
                </title>
            </Helmet>

            <Header
                pageTitle={t('Area details')}
                toolbar={
                    <Tabs
                        tabs={[t('Projects'), t('Edit area details')]}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                }
            />

            {activeTab === 0 && <ProjectsTable />}
            {activeTab === 1 && <EditZoneDetails zoneId={id} />}
        </>
    );
};

export default EditZones;
