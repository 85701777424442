import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { resourceModule } from 'routes';

import MaterialAdd from './material-add';
import MaterialCategories from './material-categories';

const MaterialResources = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path={resourceModule.materialResources.base}>
                    <Redirect to={resourceModule.materialResources.categories} />
                </Route>
                <Route path={resourceModule.materialResources.add}>
                    <MaterialAdd />
                </Route>
                <Route path={resourceModule.materialResources.editFull}>
                    <MaterialAdd />
                </Route>
                <Route path={resourceModule.materialResources.categories}>
                    <MaterialCategories />
                </Route>
            </Switch>
        </Fragment>
    );
};

export default MaterialResources;
