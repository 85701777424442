import React, { useContext, useEffect, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';

import { Button, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import {
    calculateTotal,
    calculateVATValue,
    formatPositiveNumber,
    formatPositiveNumberWithDigits,
    formatVATnumber,
    toLocaleNumber,
} from 'utils/index';
import * as yup from 'yup';

const CustomVariable = (props) => {
    const {
        variable,
        setVariable,
        editCustomVariable,
        appendCustomVariable,
        setStopCustomVariable,
        article,
        unitList,
        setUnitList,
    } = props;

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [um, setUM] = useState(null);
    const [newUm, setNewUm] = useState(null);
    const [newUmField, setNewUmField] = useState(false);
    const [numberOfUnit, setNumberOfUnit] = useState(null);

    const { currencyObj } = useContext(GlobalContext);

    useEffect(() => {
        if (variable.price.unit == unitList.length - 1) {
            setUM(null);
            setNewUmField(!newUmField);
        }
    }, [variable.price.unit]);

    const schema = yup.object().shape({
        um: yup
            .string()
            .typeError(t('You must choose a unit of measure for the custom variable!'))
            .required(t('You must choose a unit of measure for the custom variable!')),
        type: yup
            .string()
            .typeError(t('You have to choose a price type for the custom variable!'))
            .required(t('You have to choose a price type for the custom variable!')),
        pricePerUnit: yup
            .number()
            .min(1, 'Custom variable price must be at least 1!')
            .typeError(t('Custom variable price is required!'))
            .required(t('Custom variable price is required!')),
        procentualVAT: yup
            .number()
            .typeError(t('The VAT rate of the custom variable is mandatory!'))
            .min(0, t('The VAT percentage of the custom variable must be greater than or equal to 0%'))
            .required(t('The VAT rate of the custom variable is mandatory!')),
        name: yup
            .string()
            .trim()
            .typeError(t('Custom variable name required!'))
            .required(t('Custom variable name required!')),
        description: yup
            .string()
            .trim()
            .typeError(t('Custom variable description is required!'))
            .required(t('Custom variable description is required!')),
    });

    useEffect(() => {
        if (variable.price.unit === 1) {
            editCustomVariable('price', {
                ...variable.price,
                pricePerUnit: Number(((variable.percent / 100) * article.price.pricePerUnit).toFixed(2)),
            });
        } else if (variable.price.unit === 2) {
            editCustomVariable('price', {
                ...variable.price,
                pricePerUnit: (
                    (article.selectedVariables?.map((v) => Number(v.price.pricePerUnit))?.reduce((a, b) => a + b, 0) +
                        Number(article.price.pricePerUnit)) *
                    (variable.percent / 100)
                ).toFixed(2),
            });
        }
    }, [variable.percent]);

    useEffect(() => {
        if (variable.amount === null) editCustomVariable('amount', 1);
    }, [variable.amount]);

    useEffect(() => {
        if (variable.price.type === 'BASE_PROCENT') {
            editCustomVariable('price', {
                ...variable.price,
                pricePerUnit: ((variable.price.priceProcent / 100) * article.price.pricePerUnit).toFixed(2),
            });
        }
    }, [variable.price.type, variable.price.priceProcent, article.price.pricePerUnit]);

    useEffect(() => {
        if (variable.price.type === 'BASE_PROCENT_AND_VARIABLE') {
            editCustomVariable('price', {
                ...variable.price,
                pricePerUnit: (
                    (article.selectedVariables.map((v) => Number(v.price.pricePerUnit))?.reduce((a, b) => a + b, 0) +
                        Number(article.price.pricePerUnit)) *
                    (variable.price.priceProcent / 100)
                ).toFixed(2),
            });
        }
    }, [variable.price.type, variable.price.priceProcent, article.price.pricePerUnit]);

    return (
        <div>
            <div className="inline-flex w-full gap-4 border-t border-layout-transparent-dark bg-layout-transparent-dark p-5 sm:flex-col">
                <div className="w-24">
                    {variable.price.type !== 'BASE_PROCENT_AND_VARIABLE' && (
                        <TextField
                            name="cantitate"
                            label={t('Amount')}
                            placeholder={1}
                            inputProps={{ min: 1 }}
                            value={variable.amount}
                            onChange={(e) => {
                                editCustomVariable('amount', formatPositiveNumber(e.target.value));
                            }}
                        />
                    )}
                </div>

                <div
                    className="flex flex-grow flex-col gap-3"
                    style={{
                        flexBasis: '50%',
                    }}
                >
                    <TextField
                        name="nume"
                        label={t('Custom variable name')}
                        placeholder={t('Custom variable name')}
                        value={variable.name}
                        onChange={(e) => editCustomVariable('name', e.target.value)}
                    />

                    <TextField
                        name="descriere"
                        label={t('Variable description')}
                        placeholder={t('Variable description')}
                        value={variable.description}
                        onChange={(e) => editCustomVariable('description', e.target.value)}
                        multiline
                        rows={3}
                    />
                </div>

                <div className="flex gap-2">
                    <LocaleTextField
                        name="price"
                        label={t('Unit price')}
                        InputProps={{
                            readOnly:
                                variable.price.type === 'BASE_PROCENT' ||
                                variable.price.type === 'BASE_PROCENT_AND_VARIABLE',
                            endAdornment: <InputAdornment position="start">{currencyObj.currency}</InputAdornment>,
                        }}
                        placeholder={toLocaleNumber(1234, 2)}
                        value={variable.price.pricePerUnit}
                        onChange={(e) => {
                            if (variable.price.type !== 'BASE_PROCENT')
                                editCustomVariable('price', {
                                    ...variable.price,
                                    pricePerUnit: formatPositiveNumberWithDigits(e.target.value),
                                });
                        }}
                    />

                    <LocaleTextField
                        name="tva"
                        label={t('VAT rate')}
                        placeholder={'19%'}
                        value={variable.price.procentualVAT}
                        onChange={(e) =>
                            editCustomVariable('price', {
                                ...variable.price,
                                procentualVAT: formatVATnumber(e.target.value),
                            })
                        }
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                    />
                </div>
            </div>

            {variable.price.type === 'BASE_PROCENT' ? (
                <div className="inline-flex w-full gap-4 bg-layout-transparent-dark p-5 pb-0">
                    <div className="w-1/3">
                        <TextField
                            name="percent"
                            label={t('Percent')}
                            placeholder="10%"
                            InputProps={{
                                readOnly: !article.price.pricePerUnit,
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            value={variable.price.priceProcent}
                            onChange={(e) => {
                                editCustomVariable('price', {
                                    ...variable.price,
                                    priceProcent: formatPositiveNumber(e.target.value),
                                });
                            }}
                        />
                    </div>
                </div>
            ) : variable.price.type === 'BASE_PROCENT_AND_VARIABLE' ? (
                <div className="inline-flex w-full gap-4 bg-layout-transparent-dark p-5 pb-0">
                    <div className="w-1/3">
                        <TextField
                            name="percent"
                            label={t('Percentage of the entire article(article + all variables)')}
                            placeholder="10%"
                            value={variable.price.priceProcent}
                            onChange={(e) => {
                                editCustomVariable('price', {
                                    ...variable.price,
                                    priceProcent: formatPositiveNumber(e.target.value),
                                });
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />
                    </div>
                </div>
            ) : null}

            {newUmField && (
                <div className="inline-flex w-full gap-4 bg-layout-transparent-dark p-5 pb-0">
                    <div className="mb-5 w-1/2">
                        <div className="mb-4">
                            <TextField
                                name="new um"
                                label={t('New UM')}
                                placeholder={t('New UM')}
                                value={newUm}
                                onChange={(e) => setNewUm(e.target.value)}
                            />
                        </div>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => {
                                const newUnitList = [...unitList];
                                const listLen = newUnitList.length;
                                if (newUm == null) {
                                    setNewUmField(!newUmField);
                                    return;
                                } else {
                                    newUnitList[listLen - 1] = newUm;
                                }
                                newUnitList.push(t('Add new UM'));
                                setUnitList(newUnitList);
                                setNewUm('');
                                setNewUmField(false);
                            }}
                        >
                            {t('Add new UM')}
                        </Button>
                    </div>
                </div>
            )}

            <div
                className="relative inline-flex w-full gap-4 bg-layout-transparent-dark p-5 sm:flex-col"
                style={{ zIndex: 999 }}
            >
                <LabelWrapper label={t('Amount/Percent')}>
                    <Dropdown
                        className="z-999 relative"
                        placeholder={t('Choose the option')}
                        options={[
                            t(' Amount'),
                            t('Percentage of basic item'),
                            t('Percentage of the entire article(article + all variables)'),
                        ]}
                        selectedOption={
                            variable.price.type === 'SIMPLE'
                                ? 0
                                : variable.price.type === 'BASE_PROCENT'
                                ? 1
                                : variable.price.type === 'BASE_PROCENT_AND_VARIABLE'
                                ? 2
                                : null
                        }
                        setSelectedOption={(type) =>
                            editCustomVariable('price', {
                                ...variable.price,
                                type:
                                    type === 0
                                        ? 'SIMPLE'
                                        : type === 1
                                        ? 'BASE_PROCENT'
                                        : type === 2
                                        ? 'BASE_PROCENT_AND_VARIABLE'
                                        : null,
                            })
                        }
                    />
                </LabelWrapper>

                <LabelWrapper label="UM">
                    <Dropdown
                        placeholder={t('Choose UM')}
                        options={unitList}
                        selectedOption={variable.price.unit}
                        setSelectedOption={(newUm) => {
                            editCustomVariable('price', { ...variable.price, unit: newUm });
                            setNumberOfUnit(newUm);
                        }}
                    />
                </LabelWrapper>

                <LabelWrapper label={t('VAT value')} noPadding>
                    <div className="pointer-events-none whitespace-nowrap rounded-md border border-layout-transparent-dark bg-layout-transparent p-2 text-main-text">
                        {toLocaleNumber(
                            (variable.amount ? variable.amount : 1) *
                                calculateVATValue(variable.price.pricePerUnit, variable.price.procentualVAT),
                            2
                        )}{' '}
                        {currencyObj.currency}
                    </div>
                </LabelWrapper>

                <LabelWrapper label={t('Final price with VAT')} noPadding>
                    <div className="pointer-events-none whitespace-nowrap rounded-md border border-layout-transparent-dark bg-layout-transparent p-2 text-main-text">
                        {toLocaleNumber(
                            (variable.amount ? variable.amount : 1) *
                                calculateTotal(
                                    calculateVATValue(variable.price.pricePerUnit, variable.price.procentualVAT),
                                    variable.price.pricePerUnit
                                ),
                            2
                        )}{' '}
                        {currencyObj.currency}
                    </div>
                </LabelWrapper>
            </div>

            <div className="inline-flex w-full bg-layout-transparent-dark pb-5 pl-5 pr-5">
                <h3 className="text-base text-dark-text">{t('Value added above the base price')}</h3>
            </div>

            <div className="left-0 top-full flex w-full justify-end rounded-bl-md rounded-br-md border-t border-layout-transparent-dark bg-layout-transparent">
                <div className="p-3">
                    <Button
                        size="small"
                        color="secondary"
                        startIcon={<SendIcon />}
                        onClick={async () => {
                            try {
                                await schema.validate({
                                    name: variable.name,
                                    pricePerUnit: variable.price.pricePerUnit,
                                    type: variable.price.type,
                                    um: variable.price.unit,
                                    procentualVAT: variable.price.procentualVAT,
                                    description: variable.description,
                                });
                                editCustomVariable('price', { ...variable.price, unit: unitList[numberOfUnit] });
                                setStopCustomVariable(appendCustomVariable());
                            } catch (err) {
                                enqueueSnackbar(err.errors[0], { variant: 'error' });
                            }
                        }}
                    >
                        {t('Add the variable')}
                    </Button>
                </div>
                <div className="text-main-text">
                    <p
                        className="ml-2 flex cursor-pointer items-center p-4 text-main-text transition-colors hover:text-secondary-main"
                        style={{ userSelect: 'none' }}
                        onClick={() => {
                            setVariable(null);
                            setStopCustomVariable(false);
                        }}
                    >
                        <CloseIcon className="mr-2 text-base" />
                        {t('Delete variable')}
                    </p>
                </div>
            </div>
        </div>
    );
};

CustomVariable.propTypes = {
    variable: PropTypes.object,
    setVariable: PropTypes.func,
    editCustomVariable: PropTypes.func,
    appendCustomVariable: PropTypes.func,
    setStopCustomVariable: PropTypes.func,
    article: PropTypes.object,
    unitList: PropTypes.array,
    setUnitList: PropTypes.func,
};

CustomVariable.defaultProps = {
    variable: null,
    setVariable: () => null,
    editCustomVariable: () => null,
    appendCustomVariable: () => null,
    setStopCustomVariable: () => null,
    article: null,
    unitList: [],
    setUnitList: () => null,
};

export default CustomVariable;
