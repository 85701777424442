import React from 'react';

import M2M from 'assets/common/m2m-logo.png';
import RaisisLogo from 'assets/common/raisis-logo.png';

import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="mx-5 mb-5 mt-auto flex justify-center gap-2 pt-20">
            <a href="https://m2msolutions.ro/" target="_blank" rel="noreferrer" className="group w-full max-w-sm">
                <div className="flex h-full items-center justify-center gap-4 rounded-lg bg-layout-transparent p-4 group-hover:bg-layout-transparent-dark sm:flex-col">
                    <img src={M2M} className="w-24" />
                    <div className="flex flex-col items-center">
                        <p className="text-center opacity-70">© {new Date().getFullYear()} - M2M Solutions</p>
                        <p className="text-center opacity-70">{t('All rights reserved')}</p>
                    </div>
                </div>
            </a>
            <a href="https://raisissoftware.com/" target="_blank" rel="noreferrer" className="group w-full max-w-sm">
                <div className="flex h-full items-center justify-center gap-4 rounded-lg bg-layout-transparent p-4 group-hover:bg-layout-transparent-dark sm:flex-col">
                    <div className="flex flex-col items-center">
                        <p className="text-center opacity-70">© powered by</p>
                    </div>
                    <img src={RaisisLogo} className="w-52" />
                </div>
            </a>
        </div>
    );
};

export default Footer;
