import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ContractTypesForm from 'components/accountancy/contract-types-form';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const EditContractType = () => {
    const { t } = useTranslation();
    const { permissionMap } = useContext(UserContext);
    const canAll = permissionMap['Nomenclator tipuri de contract'] > 1;
    const history = useHistory();

    useEffect(() => {
        if (!canAll) history.push('/');
    }, [canAll]);

    const { contractTypeId } = useParams();

    const [contractTypeNameInitial, setContractTypeNameInitial] = useState(null);

    /**
     * This is the array for contract type form, who will be sent to the reporting tools component
     * This array has the same form like expenseRevenue array from contract type form
     * and is used to set the reporting tools component with the selected expenses and revenues and related reporting tools
     */
    const [expenseRevenueInfo, setExpenseRevenueInfo] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const res = await API.get('contractType', {
                    params: {
                        id: contractTypeId,
                    },
                });

                setContractTypeNameInitial(res.data.contract.name);

                /**
                 * We format the response from the backend to have an array un objects with same form like on frontend
                 * to be used by the reporting tools component and contract type form
                 * You can see an example on reporting-tools.jsx
                 */
                const newFormat = res.data.contract.ContractFinancialTools.map((ct) => {
                    let obj = {
                        reportingToolsId: ct.ContractToReportingTools.map((tool) => tool.reportingToolsId),
                    };

                    if (ct.expenseNameId === null) {
                        obj.type = 'revenue';
                        obj.expenseRevenueId = ct.revenueNameId;
                    } else if (ct.revenueNameId === null) {
                        obj.type = 'expense';
                        obj.expenseRevenueId = ct.expenseNameId;
                    }

                    return obj;
                });

                setExpenseRevenueInfo(newFormat);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('Edit the contract type')}</title>
            </Helmet>

            <Header pageTitle={t('Edit the contract type')} />

            <div className="page-container">
                <ContractTypesForm
                    initialContractName={contractTypeNameInitial}
                    expenseRevenueInfo={expenseRevenueInfo}
                    hideElements={false}
                />
            </div>
        </>
    );
};

export default EditContractType;
