import React, { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Button, TextField } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import useScreenSizes from 'hooks/useScreenSizes.js';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, DatePicker, Dropdown, LabelWrapper, MultiDropdown, Toggle } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { toLocaleNumber, months as utilsMonths } from 'utils';

const QuarterlySelectionRow = ({ year, years, onPeriodsSelection, onPeriodsDeletion }) => {
    const { t } = useTranslation();

    const quarters = [t('Q1'), t('Q2'), t('Q3'), t('Q4')];
    const [selectedQuarters, setSelectedQuarters] = useState([]);

    // ? We add the selected quarter as a period and we have to properly set the start and end dates to represent the start and end of a quarter
    const handleAddPeriod = (i) => {
        setSelectedQuarters([...selectedQuarters, i]);

        const selectedYear = years[year];
        const selectedQuarter = quarters[i];

        const startDate = new Date(selectedYear, i * 3, 1);

        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);

        const endDate = new Date(selectedYear, i * 3 + 3, 0);

        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);

        onPeriodsSelection({
            startDate,
            endDate,
            name: `${selectedQuarter} - ${selectedYear}`,
            id: `q-${i}-${selectedYear}`,
        });
    };

    // ? We remove a quarter from the selection and remove the period linked to it
    const handleRemovePeriod = (i) => {
        setSelectedQuarters(selectedQuarters.filter((opt) => opt !== i));
        onPeriodsDeletion(`q-${i}-${years[year]}`);
    };

    return (
        <LabelWrapper label={`${t('Quarters')}  ${years[year]}`} noPadding>
            <MultiDropdown
                variant="default"
                options={quarters}
                placeholder={t('Choose quarters')}
                selectedOptions={selectedQuarters}
                setSelectedOptions={(i) => {
                    if (selectedQuarters.indexOf(i) > -1) {
                        handleRemovePeriod(i);
                    } else {
                        handleAddPeriod(i);
                    }
                }}
            />
        </LabelWrapper>
    );
};

QuarterlySelectionRow.propTypes = {
    year: PropTypes.number,
    years: PropTypes.array,
    months: PropTypes.array,
    onPeriodsSelection: PropTypes.func,
    onPeriodsDeletion: PropTypes.func,
};

QuarterlySelectionRow.defaultProps = {
    year: () => {},
    years: [],
    months: [],
    onPeriodsSelection: () => {},
    onPeriodsDeletion: () => {},
};

const QuarterlySelection = ({ onPeriodsSelection, onPeriodsDeletion, onMultiplePeriodsDeletion, years }) => {
    const { t } = useTranslation();
    const [selectedYears, setSelectedYears] = useState([]);

    // ? We add a parent (year) for the quarter selection
    const handleAddYear = (i) => {
        setSelectedYears([...selectedYears, i]);
    };

    // ? We remove a parent (year) for the quarter selection and we remove all periods linked to this year
    const handleDeleteYear = (i) => {
        setSelectedYears(selectedYears.filter((opt) => opt !== i));

        // ? We can find them by indicating the type of the period, q in this case and the parent (year)
        onMultiplePeriodsDeletion('q', years[i]);
    };

    return (
        <div className="flex flex-col gap-6">
            <LabelWrapper
                label={
                    selectedYears.length === 0 ? (
                        <div className="flex gap-1 text-warning">
                            <ErrorOutlineIcon />
                            <span>
                                {t(
                                    'To select the desired quarters you must first select the year or years they are in'
                                )}
                            </span>
                        </div>
                    ) : (
                        <p className="text-success-light">{t('Selected years')}</p>
                    )
                }
                noPadding
                style={{
                    whiteSpace: 'normal',
                    overflowX: 'auto',
                    textOverflow: 'unset',
                    lineHeight: 1.5,
                }}
            >
                <MultiDropdown
                    variant="default"
                    options={years}
                    placeholder={t('Choose years')}
                    selectedOptions={selectedYears}
                    setSelectedOptions={(i) => {
                        if (selectedYears.indexOf(i) > -1) {
                            handleDeleteYear(i);
                        } else {
                            handleAddYear(i);
                        }
                    }}
                />
            </LabelWrapper>
            {selectedYears.length > 0 && (
                <div className="flex flex-col gap-2 rounded-md bg-layout-transparent p-2">
                    {selectedYears.map((year) => (
                        <QuarterlySelectionRow
                            key={year}
                            year={year}
                            years={years}
                            onPeriodsSelection={onPeriodsSelection}
                            onPeriodsDeletion={onPeriodsDeletion}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

QuarterlySelection.propTypes = {
    onPeriodsSelection: PropTypes.func,
    onPeriodsDeletion: PropTypes.func,
    onMultiplePeriodsDeletion: PropTypes.func,
    years: PropTypes.array,
};

QuarterlySelection.defaultProps = {
    onPeriodsSelection: () => {},
    onPeriodsDeletion: () => {},
    onMultiplePeriodsDeletion: () => {},
    years: [],
};

const MonthsSelectionRow = ({ year, years, months, onPeriodsSelection, onPeriodsDeletion }) => {
    const { t } = useTranslation();

    const [selectedMonths, setSelectedMonths] = useState([]);

    // ? We add the selected month as a period and we have to properly set the start and end dates to represent the start and end of a month
    const handleAddPeriod = (i) => {
        setSelectedMonths([...selectedMonths, i]);

        const selectedYear = years[year];
        const selectedMonth = months[i];

        const startDate = new Date(selectedYear, i, 1);

        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);

        const endDate = new Date(selectedYear, i + 1, 0);

        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);

        onPeriodsSelection({
            startDate,
            endDate,
            name: `${selectedMonth} - ${selectedYear}`,
            id: `m-${i}-${selectedYear}`,
        });
    };

    // ? We remove a month from the selection and remove the period linked to it
    const handleRemovePeriod = (i) => {
        setSelectedMonths(selectedMonths.filter((opt) => opt !== i));
        onPeriodsDeletion(`m-${i}-${years[year]}`);
    };

    return (
        <LabelWrapper label={`${t('Months')}  ${years[year]}`} noPadding>
            <MultiDropdown
                variant="default"
                options={months}
                placeholder={t('Choose months')}
                selectedOptions={selectedMonths}
                setSelectedOptions={(i) => {
                    if (selectedMonths.indexOf(i) > -1) {
                        handleRemovePeriod(i);
                    } else {
                        handleAddPeriod(i);
                    }
                }}
            />
        </LabelWrapper>
    );
};

MonthsSelectionRow.propTypes = {
    year: PropTypes.number,
    years: PropTypes.array,
    months: PropTypes.array,
    onPeriodsSelection: PropTypes.func,
    onPeriodsDeletion: PropTypes.func,
};

MonthsSelectionRow.defaultProps = {
    year: () => {},
    years: [],
    months: [],
    onPeriodsSelection: () => {},
    onPeriodsDeletion: () => {},
};

const MonthsSelection = ({ onPeriodsSelection, onPeriodsDeletion, onMultiplePeriodsDeletion, years }) => {
    const { t } = useTranslation();
    const { language } = useContext(GlobalContext);
    const [selectedYears, setSelectedYears] = useState([]);
    const months = utilsMonths(language);

    // ? We add a parent (year) for the months selection
    const handleAddYear = (i) => {
        setSelectedYears([...selectedYears, i]);
    };

    // ? We remove a parent (year) for the months selection and we remove all periods linked to this year
    const handleDeleteYear = (i) => {
        setSelectedYears(selectedYears.filter((opt) => opt !== i));

        // ? We can find them by indicating the type of the period, m in this case and the parent (year)
        onMultiplePeriodsDeletion('m', years[i]);
    };

    return (
        <div className="flex flex-col gap-6">
            <LabelWrapper
                label={
                    selectedYears.length === 0 ? (
                        <div className="flex gap-1 text-warning">
                            <ErrorOutlineIcon />
                            <span>
                                {t('To select the desired months you must first select the year or years they are in')}
                            </span>
                        </div>
                    ) : (
                        <p className="text-success-light">{t('Selected years')}</p>
                    )
                }
                noPadding
                style={{
                    whiteSpace: 'normal',
                    overflowX: 'auto',
                    textOverflow: 'unset',
                    lineHeight: 1.5,
                }}
            >
                <MultiDropdown
                    variant="default"
                    options={years}
                    placeholder={t('Choose years')}
                    selectedOptions={selectedYears}
                    setSelectedOptions={(i) => {
                        if (selectedYears.indexOf(i) > -1) {
                            handleDeleteYear(i);
                        } else {
                            handleAddYear(i);
                        }
                    }}
                />
            </LabelWrapper>
            {selectedYears.length > 0 && (
                <div className="flex flex-col gap-2 rounded-md bg-layout-transparent p-2">
                    {selectedYears.map((year) => (
                        <MonthsSelectionRow
                            key={year}
                            year={year}
                            years={years}
                            months={months}
                            onPeriodsSelection={onPeriodsSelection}
                            onPeriodsDeletion={onPeriodsDeletion}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

MonthsSelection.propTypes = {
    onPeriodsSelection: PropTypes.func,
    onPeriodsDeletion: PropTypes.func,
    onMultiplePeriodsDeletion: PropTypes.func,
    years: PropTypes.array,
};

MonthsSelection.defaultProps = {
    onPeriodsSelection: () => {},
    onPeriodsDeletion: () => {},
    onMultiplePeriodsDeletion: () => {},
    years: [],
};

const YearsSelection = ({ onPeriodsSelection, onPeriodsDeletion, years }) => {
    const { t } = useTranslation();
    const [selectedYears, setSelectedYears] = useState([]);

    // ? We add the selected year as a period and we have to properly set the start and end dates to represent the start and end of a year
    const handleAddDate = (i) => {
        setSelectedYears([...selectedYears, i]);

        const selectedYear = years[i];

        const startDate = new Date(selectedYear, 0, 1);

        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);

        const endDate = new Date(selectedYear, 11, 31);

        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);

        onPeriodsSelection({
            startDate,
            endDate,
            name: `${selectedYear}`,
            id: `y-${selectedYear}`,
        });
    };

    // ? We remove a year from the selection and remove the period linked to it
    const handleDeleteDate = (i) => {
        setSelectedYears(selectedYears.filter((opt) => opt !== i));
        onPeriodsDeletion(`y-${years[i]}`);
    };

    return (
        <MultiDropdown
            variant="default"
            options={years}
            placeholder={t('Choose years')}
            selectedOptions={selectedYears}
            setSelectedOptions={(i) => {
                if (selectedYears.indexOf(i) > -1) {
                    handleDeleteDate(i);
                } else {
                    handleAddDate(i);
                }
            }}
        />
    );
};

YearsSelection.propTypes = {
    onPeriodsSelection: PropTypes.func,
    onPeriodsDeletion: PropTypes.func,
    years: PropTypes.array,
};

YearsSelection.defaultProps = {
    onPeriodsSelection: () => {},
    onPeriodsDeletion: () => {},
    years: [],
};

const PeriodsSelection = ({ onPeriodsSelection, onPeriodsDeletion, onMultiplePeriodsDeletion }) => {
    const { t } = useTranslation();
    const years = (() => {
        const maxYear = new Date().getFullYear() + 5;
        const generatedYears = [];
        let startYear = 2000;

        while (startYear <= maxYear) {
            generatedYears.unshift(startYear);
            startYear++;
        }

        return generatedYears;
    })();

    return (
        <div className="flex w-full flex-col gap-8 rounded-md bg-layout-transparent p-3">
            <h3 className="text-center text-2xl font-bold">{t('Select periods')}</h3>
            <div className="grid grid-cols-3 items-start gap-4 sm:grid-cols-1 sm:items-center">
                <div className="flex flex-col gap-2 rounded-md border-2 border-layout-transparent-dark p-2 text-xl">
                    <h4 className="font-normal">{t('Select months')}</h4>
                    <MonthsSelection
                        onPeriodsSelection={onPeriodsSelection}
                        onPeriodsDeletion={onPeriodsDeletion}
                        years={years}
                        onMultiplePeriodsDeletion={onMultiplePeriodsDeletion}
                    />
                </div>
                <div className="flex flex-col gap-2 rounded-md border-2 border-layout-transparent-dark p-2 text-xl">
                    <h4 className="font-normal">{t('Select quarters')}</h4>
                    <QuarterlySelection
                        onPeriodsSelection={onPeriodsSelection}
                        onPeriodsDeletion={onPeriodsDeletion}
                        years={years}
                        onMultiplePeriodsDeletion={onMultiplePeriodsDeletion}
                    />
                </div>
                <div className="flex flex-col gap-2 rounded-md border-2 border-layout-transparent-dark p-2 text-xl">
                    <h4 className="font-normal">{t('Select years')}</h4>
                    <YearsSelection
                        onPeriodsSelection={onPeriodsSelection}
                        onPeriodsDeletion={onPeriodsDeletion}
                        years={years}
                    />
                </div>
            </div>
        </div>
    );
};

PeriodsSelection.propTypes = {
    onPeriodsSelection: PropTypes.func,
    onPeriodsDeletion: PropTypes.func,
    onMultiplePeriodsDeletion: PropTypes.func,
};

PeriodsSelection.defaultProps = {
    onPeriodsSelection: () => {},
    onPeriodsDeletion: () => {},
    onMultiplePeriodsDeletion: () => {},
};

const Row = ({ row, setRows, index }) => {
    const { t } = useTranslation();

    const handleChange = (key, e) => {
        setRows((prev) =>
            prev.map((el, i) => {
                let finalData;

                // ? If the property that we modify for the row is a date we have to set the correct hours, minutes and seconds
                if (key === 'startDate' || key === 'endDate') {
                    let date = new Date(e);

                    // ? When we have a start date we set the hours, minutes and seconds to 0 in order to simulate the start of the day
                    if (key === 'startDate') {
                        date.setHours(0);
                        date.setMinutes(0);
                        date.setSeconds(0);
                    }

                    // ? When we have a start date we set the hours, minutes and seconds to 23, 59 and 59 respectively in order to simulate the end of the day
                    if (key === 'endDate') {
                        date.setHours(23);
                        date.setMinutes(59);
                        date.setSeconds(59);
                    }

                    finalData = date;
                }
                // ? Otherwise we assign the unaltered data from the input
                else {
                    finalData = e;
                }

                if (i === index) {
                    return { ...el, [key]: finalData };
                } else return el;
            })
        );
    };

    const deleteRow = () => {
        setRows((prev) => {
            const updatedArray = _.cloneDeep(prev);
            updatedArray.splice(index, 1);

            return updatedArray;
        });
    };

    return (
        <div className="flex gap-8 rounded-md border-2 border-layout-transparent-dark px-6 py-2">
            <div className="flex gap-5 sm:flex-col sm:gap-3">
                <LabelWrapper label={t('Start date')}>
                    <DatePicker date={row.startDate} setDate={(e) => handleChange('startDate', e)} />
                </LabelWrapper>
                <LabelWrapper label={t('End date')}>
                    <DatePicker date={row.endDate} setDate={(e) => handleChange('endDate', e)} />
                </LabelWrapper>
                <LabelWrapper label={t('Name')}>
                    <TextField
                        placeholder={t('Name')}
                        value={row.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />
                </LabelWrapper>
            </div>

            <div className="self-center">
                <ActionButton size={9} icon={<DeleteIcon />} color="var(--error)" onClick={() => deleteRow()} />
            </div>
        </div>
    );
};

Row.propTypes = {
    row: PropTypes.object,
    setRows: PropTypes.func,
    index: PropTypes.number,
};

Row.defaultProps = {
    row: { startDate: new Date(), endDate: new Date(), name: '' },
    setRows: () => {},
    index: null,
};

const ModalAddPeriodsData = ({
    setOpen,
    getExcelData,
    title,
    contracts,
    pmContracts,
    projects,
    projectStructures,
    selectedData,
    setSelectedData,
    useCase,
    types,
    selectedCrmType,
    setSelectedCrmType,
    exchangeRateDate,
    exchangeRate,
    getExchangeRate,
    handleUpdateExchangeRate,
    disabled,
    defaultDetailsTypes,
    newDetailsTypes,
    selectedDetailsType,
    setSelectedDetailsType,
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const contentRef = useRef(null);

    const [periodsDates, setPeriodsDates] = useState([]);
    const [rows, setRows] = useState([]);

    const [useInvoiceExchangeRate, setUseInvoiceExchangeRate] = useState(false);

    const [width, height] = useScreenSizes();

    // ? Used to keep the count of the created rows between renders
    const rowInstances = useRef(0);

    const { checkPerm } = useContext(UserContext);

    const canViewProjectStructure = checkPerm([
        {
            permissionId: '42',
            permissionType: 'VIEW',
        },
    ]);

    const canAllContracts = checkPerm([
        {
            permissionId: '8',
            permissionType: 'VIEW',
        },
    ]);

    const reportOnTypes = [
        t('General report'),
        ...(canAllContracts ? [t('Report on contract')] : []),
        ...(canViewProjectStructure ? [t('Report on project structure')] : []),
    ];

    const addNewRow = () => {
        setRows([
            ...rows,
            {
                startDate: new Date(),
                endDate: new Date(),
                name: `${t('Period')} ${++rowInstances.current}`,
            },
        ]);
    };

    const validateData = () => {
        if (rows.length === 0 && periodsDates.length === 0) {
            enqueueSnackbar(`${t('You must enter or select at least one period')}`, {
                variant: 'error',
            });
            return false;
        }

        for (let i = 0; i < rows.length; i++) {
            if (rows[i].name.trim() === '' || rows[i].name === null) {
                enqueueSnackbar(`${t('You must add the name of the column')}: ${Number(i) + 1}`, {
                    variant: 'error',
                });
                return false;
            }
        }
        if (selectedData === null) {
            if (useCase === 'projectCase' || (useCase === 'crmCase' && selectedCrmType === 1))
                enqueueSnackbar(`${t('You must select one contract')}`, {
                    variant: 'error',
                });

            if (useCase === 'crmCase' && selectedCrmType === 2)
                enqueueSnackbar(`${t('You must select one project structure')}`, {
                    variant: 'error',
                });

            if (useCase === 'pmCase')
                enqueueSnackbar(`${t('You must select one project')}`, {
                    variant: 'error',
                });

            if (useCase === 'crmCase' && selectedCrmType === 0)
                enqueueSnackbar(`${t('You must select one type')}`, {
                    variant: 'error',
                });

            return false;
        }

        if (selectedDetailsType === null) {
            enqueueSnackbar(`${t('You must select the type of the details')}`, {
                variant: 'error',
            });

            return false;
        }

        return true;
    };

    /**
     * The `handleComplete` function sorts and combines arrays of periods, removes the `id` property
     * from each period, and then calls the `getExcelData` function with the combined array.
     * The arrays are sorted by date and are displayed in the following order: months, quarters, years.
     */
    const handleComplete = () => {
        if (!validateData()) return;

        const sortByDate = (a, b) => {
            const startDateA = new Date(a.startDate).getTime();
            const startDateB = new Date(b.startDate).getTime();

            return startDateA - startDateB;
        };

        const periodsDatesClone = structuredClone(periodsDates);
        const monthsArray = periodsDatesClone.filter((period) => period.id.startsWith('m')).sort(sortByDate);
        const quartersArray = periodsDatesClone.filter((period) => period.id.startsWith('q')).sort(sortByDate);
        const yearsArray = periodsDatesClone.filter((period) => period.id.startsWith('y')).sort(sortByDate);

        const newPeriodsDatesClone = [...monthsArray, ...quartersArray, ...yearsArray].map((period) => {
            delete period.id;
            return period;
        });

        getExcelData([...rows, ...newPeriodsDatesClone], useInvoiceExchangeRate);
    };

    /**
     * The function `getOptions` returns an array of options based on different use cases and
     * conditions.
     * @returns {Array} The function `getOptions` returns an array of options based on the conditions specified
     * in the code. The specific options returned depend on the values of the variables `useCase`,
     * `selectedCrmType`, `contracts`, `pmContracts`, `projectStructures`, and `projects`.
     */
    const getOptions = () => {
        let options = [];

        if (useCase === 'projectCase' || (useCase === 'crmCase' && selectedCrmType === 1)) {
            const contractsArray = useCase === 'crmCase' ? contracts : useCase === 'projectCase' ? pmContracts : [];

            options = contractsArray.map((c) => {
                const data = typeof c.data === 'string' ? JSON.parse(c.data) : c.data;
                return 'standard' in data && 'number' in data['standard']
                    ? data['standard'].number
                    : 'Numar contract inexistent';
            });
        }

        if (useCase === 'crmCase' && selectedCrmType === 2)
            options = projectStructures.map((projectStructure) => projectStructure.name);

        if (useCase === 'pmCase') options = projects.map((project) => project.name);

        return options;
    };

    // ? Adds a new period
    const handlePeriodsSelection = (date) => setPeriodsDates((prev) => [...prev, date]);

    // ? Removes a period
    const handlePeriodsDeletion = (id) => setPeriodsDates((prev) => prev.filter((item) => item.id !== id));

    // ? Removes one ore more periods when you removing their parent (year)
    const handleMultiplePeriodsDeletion = (startString, endString) =>
        setPeriodsDates((prev) =>
            prev.filter((item) => !(item.id.startsWith(startString) && item.id.endsWith(endString)))
        );

    useEffect(() => {
        let observer = null;

        observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target.getBoundingClientRect().height >= height * 0.6) {
                    contentRef.current.style.overflowY = 'auto';
                    contentRef.current.style.overflowX = 'hidden';
                } else {
                    contentRef.current.style.overflow = 'visible';
                }
            }
        });

        observer.observe(contentRef.current);

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [height]);

    return (
        <div
            className="flex flex-col items-center gap-16 p-10 sm:px-2"
            style={{ width: width > 750 ? '50rem' : '90vw', maxHeight: width > 600 ? '90vh' : '70vh' }}
            ref={contentRef}
        >
            <h2>{t(`Generate ${title}`)}</h2>

            <div className="flex w-full flex-col items-center gap-16">
                <div className="flex w-full flex-col items-center gap-8 rounded-md bg-layout-transparent p-3">
                    <h3 className="text-center text-2xl font-bold">{t('Select what the report will be based on')}</h3>
                    {(useCase === 'projectCase' || useCase === 'pmCase') && (
                        <div className="w-1/2 sm:w-full">
                            <LabelWrapper
                                label={
                                    useCase === 'projectCase'
                                        ? t('Contracts')
                                        : useCase === 'pmCase'
                                        ? t('Projects')
                                        : ''
                                }
                            >
                                <Dropdown
                                    variant="default"
                                    options={getOptions()}
                                    placeholder={
                                        useCase === 'projectCase'
                                            ? t('Choose the contract for report')
                                            : useCase === 'pmCase'
                                            ? t('Choose the project for report')
                                            : ''
                                    }
                                    selectedOption={selectedData}
                                    setSelectedOption={(e) => setSelectedData(e)}
                                    disabled={disabled}
                                />
                            </LabelWrapper>
                        </div>
                    )}

                    {useCase === 'crmCase' && (
                        <div className="flex w-full justify-between gap-4 sm:flex-col">
                            <LabelWrapper label={t('Select the report type')}>
                                <Dropdown
                                    variant="default"
                                    options={reportOnTypes}
                                    placeholder={t('Report type')}
                                    selectedOption={selectedCrmType}
                                    setSelectedOption={(e) => {
                                        setSelectedData(null);
                                        setSelectedDetailsType(null);
                                        setSelectedCrmType(e);
                                    }}
                                />
                            </LabelWrapper>

                            <LabelWrapper
                                label={
                                    selectedCrmType === 0
                                        ? t('Type')
                                        : selectedCrmType === 1
                                        ? t('Contracts')
                                        : selectedCrmType === 2
                                        ? t('Project structures')
                                        : ''
                                }
                            >
                                <Dropdown
                                    variant="default"
                                    options={
                                        selectedCrmType === 0
                                            ? types.map((type) => t(type))
                                            : selectedCrmType === 1 || selectedCrmType === 2
                                            ? getOptions()
                                            : []
                                    }
                                    placeholder={
                                        selectedCrmType === 0
                                            ? t('Choose what the report will be on')
                                            : selectedCrmType === 1
                                            ? t('Choose the contract for report')
                                            : selectedCrmType === 2
                                            ? t('Choose the project structure for the report')
                                            : ''
                                    }
                                    selectedOption={selectedData}
                                    setSelectedOption={(e) => setSelectedData(e)}
                                    disabled={(selectedCrmType === 1 || selectedCrmType === 2) && disabled}
                                />
                            </LabelWrapper>
                        </div>
                    )}

                    <div className="flex w-full items-center rounded-lg bg-layout-transparent px-1.5 py-3">
                        <Toggle
                            checked={useInvoiceExchangeRate}
                            setChecked={setUseInvoiceExchangeRate}
                            disabled={disabled}
                        />

                        <p className="ml-4 font-bold">{t('The report will be based on the invoices exchange rate')}</p>
                    </div>

                    {!useInvoiceExchangeRate && (
                        <div className="flex w-full justify-between gap-4 sm:flex-col">
                            <LabelWrapper label={t('Select the date for the exchange rate')}>
                                <DatePicker disabled={disabled} date={exchangeRateDate} setDate={getExchangeRate} />
                            </LabelWrapper>

                            <LabelWrapper label={t('Exchange rate')}>
                                <LocaleTextField
                                    disabled={disabled}
                                    placeholder={`${toLocaleNumber(0, 4, 4)}`}
                                    value={exchangeRate}
                                    onChange={handleUpdateExchangeRate}
                                    minDecimals={4}
                                    maxDecimals={4}
                                    className="w-full"
                                />
                            </LabelWrapper>
                        </div>
                    )}

                    <div className="w-1/2 sm:w-full">
                        <LabelWrapper label={t('Select what type of details the report will have')}>
                            <Dropdown
                                variant="default"
                                options={
                                    useCase === 'projectCase' || (useCase === 'crmCase' && selectedCrmType === 1)
                                        ? defaultDetailsTypes.map((item) =>
                                              t(`${item.at(0).toUpperCase()}${item.substring(1, item.length)}`)
                                          )
                                        : newDetailsTypes.map((item) => {
                                              // ? If the item contains a number we have to separate them in order to translate only the text witch contains letters
                                              if (/\d/.test(item)) {
                                                  // ? We split the text by numbers and eliminate the empty space at the end
                                                  const splitItem = item.split(/(\d)/).filter(Boolean);

                                                  // ? We return the new string and we only translate the non numeric parts
                                                  return splitItem
                                                      .map(
                                                          (item) =>
                                                              `${
                                                                  /\d/.test(item)
                                                                      ? item
                                                                      : t(
                                                                            `${item
                                                                                .at(0)
                                                                                .toUpperCase()}${item.substring(
                                                                                1,
                                                                                item.length
                                                                            )}`
                                                                        )
                                                              }`
                                                      )
                                                      .join(' ');
                                              }

                                              return t(`${item.at(0).toUpperCase()}${item.substring(1, item.length)}`);
                                          })
                                }
                                selectedOption={selectedDetailsType}
                                setSelectedOption={(e) => {
                                    setSelectedDetailsType(e);
                                }}
                            />
                        </LabelWrapper>
                    </div>
                </div>

                <PeriodsSelection
                    onPeriodsSelection={handlePeriodsSelection}
                    onPeriodsDeletion={handlePeriodsDeletion}
                    onMultiplePeriodsDeletion={handleMultiplePeriodsDeletion}
                />

                <div className="flex w-full flex-col gap-8 rounded-md bg-layout-transparent p-3">
                    <h3 className="text-center text-2xl font-bold">{t('Enter periods')}</h3>
                    {rows.length > 0 && (
                        <div className="flex flex-col items-center gap-2">
                            {rows.map((row, index) => (
                                <Row key={index} row={row} setRows={setRows} index={index} />
                            ))}
                        </div>
                    )}
                    <span className="self-center">
                        <Button startIcon={<AddIcon />} color="primary" onClick={addNewRow}>
                            {t('Add new column')}
                        </Button>
                    </span>
                </div>
            </div>

            <div className="flex w-full justify-end gap-4">
                <Button onClick={() => setOpen(false)} startIcon={<CancelIcon />} color="secondary">
                    {t('Cancel')}
                </Button>
                <Button
                    startIcon={<CheckCircleIcon />}
                    color="secondary"
                    className="focus:outline-none disabled:!bg-slate-600 disabled:!text-slate-400"
                    onClick={() => handleComplete()}
                    disabled={disabled}
                >
                    {t('Complete')}
                </Button>
            </div>
        </div>
    );
};

ModalAddPeriodsData.propTypes = {
    title: PropTypes.string,
    setOpen: PropTypes.func,
    getExcelData: PropTypes.func,
    contracts: PropTypes.func,
    pmContracts: PropTypes.func,
    projects: PropTypes.func,
    projectStructures: PropTypes.func,
    selectedData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setSelectedData: PropTypes.func,
    useCase: PropTypes.string,
    types: PropTypes.array,
    selectedCrmType: PropTypes.number,
    setSelectedCrmType: PropTypes.func,
    exchangeRateDate: PropTypes.date,
    exchangeRate: PropTypes.number,
    getExchangeRate: PropTypes.func,
    handleUpdateExchangeRate: PropTypes.func,
    disabled: PropTypes.bool,
    defaultDetailsTypes: PropTypes.array,
    newDetailsTypes: PropTypes.array,
    selectedDetailsType: PropTypes.number,
    setSelectedDetailsType: PropTypes.func,
};

ModalAddPeriodsData.defaultProps = {
    title: '',
    setOpen: () => {},
    getExcelData: () => {},
    contracts: [],
    pmContracts: [],
    projects: [],
    projectStructures: [],
    selectedData: null,
    setSelectedData: () => {},
    useCase: null,
    types: [],
    selectedCrmType: 0,
    setSelectedCrmType: () => {},
    exchangeRateDate: new Date(),
    exchangeRate: 1,
    getExchangeRate: () => {},
    handleUpdateExchangeRate: () => {},
    disabled: false,
    defaultDetailsTypes: [],
    newDetailsTypes: [],
    selectedDetailsType: 0,
    setSelectedDetailsType: () => {},
};

export default ModalAddPeriodsData;
