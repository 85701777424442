import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button, CircularProgress, TextField } from '@material-ui/core';

import ReportingTools from 'components/reporting-tools/reporting-tools';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { accountancy } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const ContractTypesForm = (props) => {
    const { initialContractName, expenseRevenueInfo, hideElements } = props;

    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const { contractTypeId = null } = useParams();

    const [contractTypeName, setContractTypeName] = useState('');

    const [reportingTools, setReportingTools] = useState([]);

    const [expenses, setExpenses] = useState([]);
    const [revenues, setRevenues] = useState([]);
    const [selectedExpenses, setSelectedExpenses] = useState([]);
    const [selectedRevenues, setSelectedRevenues] = useState([]);

    // This is the array that goes in the request body
    const [expenseRevenue, setExpenseRevenue] = useState([]);

    //This array is used in component to mix the expenses and revenues and to display in table
    const [expensesAndRevenues, setExpensesAndRevenues] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                let res;

                res = await API.get('expensesName', {
                    params: {
                        perPage: 9999,
                        currentPage: 0,
                        pagesToLoad: 1,
                    },
                });
                setExpenses(res.data.expensesName.expenseNames);

                res = await API.get('revenues_names', {
                    params: {
                        perPage: 9999,
                        currentPage: 0,
                        pagesToLoad: 1,
                    },
                });
                setRevenues(res.data.data.revenuName);

                res = await API.get('ReportingTools');
                setReportingTools(res.data.reportingTools);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    useEffect(() => {
        setContractTypeName(initialContractName);

        setExpenseRevenue(expenseRevenueInfo);

        if (expenses.length && revenues.length) {
            const expArray = [];
            const revArray = [];

            /**
             * We set the expArray and revArray with the indexes of the selected expenses and selected revenues
             */
            expenseRevenueInfo.forEach((e) => {
                if (e.type === 'expense') {
                    expArray.push(expenses.findIndex((el) => el.id === e.expenseRevenueId));
                } else if (e.type === 'revenue') {
                    revArray.push(revenues.findIndex((re) => re.id === e.expenseRevenueId));
                }
            });

            // After we have the indexes of selected expenses/revenues we make arrays with expenses and revenues info/ to be displayed in component
            const newExpensesToDisplay = expArray.map((e) => ({
                id: expenses[e].id,
                name: expenses[e].name,
                type: 'expense',
            }));

            const newRevenueToDisplay = revArray.map((r) => ({
                id: revenues[r].id,
                name: revenues[r].name,
                type: 'revenue',
            }));

            setSelectedExpenses(expArray);
            setSelectedRevenues(revArray);

            setExpensesAndRevenues([...newExpensesToDisplay, ...newRevenueToDisplay]);
        }
    }, [contractTypeId, expenseRevenueInfo, expenses, revenues]);

    /**
     * This function format our expenseRevenue, be in form expected in requests
     * @returns - an array of formatted objects to be sent in request
     */
    const formatExpensesAndRevenuesForRequest = () => {
        return expenseRevenue.map((e) => {
            let obj = {
                reportingToolsIds: e.reportingToolsId,
            };

            if (e.type === 'expense') {
                obj.expenseId = e.expenseRevenueId;
            } else if (e.type === 'revenue') {
                obj.revenueId = e.expenseRevenueId;
            }

            return obj;
        });
    };

    const contractTypeSchema = yup.object().shape({
        contractTypeName: yup
            .string(t('The name of the contract type is required'))
            .trim()
            .typeError(t('The name of the contract type is required'))
            .required(t('The name of the contract type is required')),
    });

    const createContractType = async () => {
        try {
            await contractTypeSchema.validate({
                contractTypeName,
            });

            if (expensesAndRevenues.length === 0) {
                enqueueSnackbar(t('The contract type must be associated with at least one reporting tools!'), {
                    variant: 'error',
                });
                return;
            }

            if (expenseRevenue.length !== expensesAndRevenues.length) {
                enqueueSnackbar(t('Every expense or revenue selected must have at least one reporting tool!'), {
                    variant: 'error',
                });
                return;
            }

            setLoading(true);

            try {
                if (contractTypeId) {
                    await API.put('/contractType', {
                        id: contractTypeId,
                        data: {
                            name: contractTypeName,
                            financialTools: formatExpensesAndRevenuesForRequest(),
                        },
                    });

                    enqueueSnackbar(t('The contract type was updated successfully!'), {
                        variant: 'success',
                    });
                } else {
                    await API.post('/contractType', {
                        name: contractTypeName,
                        financialTools: formatExpensesAndRevenuesForRequest(),
                    });

                    enqueueSnackbar(t('The contract type was created successfully!'), {
                        variant: 'success',
                    });
                }

                history.push(accountancy.base + accountancy.contractTypeNomenclature.base);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {!hideElements && (
                <div className="mb-10 max-w-2xl">
                    <LabelWrapper label={t('Name of the type of contract')}>
                        <TextField
                            placeholder={t('Add a name for the type of the contract')}
                            value={contractTypeName}
                            onChange={(e) => setContractTypeName(e.target.value)}
                        />
                    </LabelWrapper>
                </div>
            )}

            <ReportingTools
                expenses={expenses}
                revenues={revenues}
                reportingTools={reportingTools}
                expenseRevenue={expenseRevenue}
                setExpenseRevenue={setExpenseRevenue}
                expensesAndRevenues={expensesAndRevenues}
                setExpensesAndRevenues={setExpensesAndRevenues}
                selectedExpenses={selectedExpenses}
                setSelectedExpenses={setSelectedExpenses}
                selectedRevenues={selectedRevenues}
                setSelectedRevenues={setSelectedRevenues}
                hideElements={hideElements}
            />

            {!hideElements && (
                <div>
                    <Button color="primary" onClick={createContractType} startIcon={<AddIcon />}>
                        {contractTypeId ? t('Edit the contract type') : t('Add the contract type')}
                    </Button>
                </div>
            )}
        </>
    );
};

ContractTypesForm.propTypes = {
    expenseRevenue: PropTypes.arrayOf(PropTypes.object),
    initialContractName: PropTypes.string,
    expenseRevenueInfo: PropTypes.arrayOf(PropTypes.object),
    hideElements: PropTypes.bool,
};

ContractTypesForm.defaultProps = {
    expenseRevenue: [],
    initialContractName: null,
    expenseRevenueInfo: [],
    hideElements: false,
};

export default ContractTypesForm;
