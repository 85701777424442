import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { quotes } from 'routes';

import CreateAttributes from './create-attributes';
import EditAttributes from './edit-attributes';
import ManageAttributes from './manage-attributes';

const Attributes = () => {
    return (
        <>
            <Switch>
                <Route exact path={quotes.base + quotes.attributes.base + '/' + ':articleId'}>
                    <ManageAttributes />
                </Route>

                <Route path={quotes.base + quotes.attributes.base + quotes.attributes.create + '/' + ':articleId'}>
                    <CreateAttributes />
                </Route>

                <Route path={quotes.base + quotes.attributes.base + quotes.attributes.updateFull + '/' + ':articleId'}>
                    <EditAttributes />
                </Route>
            </Switch>
        </>
    );
};

export default Attributes;
