import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { SelectList } from 'RaisisComponents/index.js';

import ConfigFieldWrapper from './config-field-wrapper';

const NumberField = (props) => {
    const { field, changeFieldValue, deleteFieldFromColumn } = props;
    const [selectedType, setSelectedType] = useState([0]);

    useEffect(() => {
        changeFieldValue('selectedTypeOptions', field.fieldOptions[selectedType[0]]);
    }, [selectedType]);

    return (
        <>
            {field ? (
                <ConfigFieldWrapper
                    canBeDeleted={field.canBeDeleted}
                    type={field.type}
                    name={field.displayName}
                    setName={(newValue) => changeFieldValue('displayName', newValue)}
                    deleteFieldFromColumn={deleteFieldFromColumn}
                >
                    <div className="-mb-1">
                        <SelectList
                            options={field.fieldOptions}
                            selectedOptions={selectedType}
                            setSelectedOptions={setSelectedType}
                        />
                    </div>
                </ConfigFieldWrapper>
            ) : null}
        </>
    );
};

NumberField.propTypes = {
    field: PropTypes.object,
    changeFieldValue: PropTypes.func,
    deleteFieldFromColumn: PropTypes.func,
};

NumberField.defaultProps = {
    field: null,
    changeFieldValue: () => null,
    deleteFieldFromColumn: () => null,
};

export default NumberField;
