import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, CircularProgress, makeStyles, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { resourceModule } from 'routes';
import { errorHandling, formatPositiveNumber } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
    customButton: {
        backgroundColor: 'var(--error)',
        color: 'var(--buttons-text)',
        '&:hover': {
            backgroundColor: 'var(--error-light)',
        },
    },
}));

const MaterialAdd = () => {
    const styles = useStyles();

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);

    const status = ['AVAILABLE', 'CONSUMED', 'USING'];
    const types = ['FIXED_ASSETS', 'INVENTORY_OBJECTS', 'CONSUMABLES', 'INTANGIBLE_ASSETS'];
    const [formData, setFormData] = useState({
        name: '',
        NIR: '',
        description: '',
        type: undefined,
        status: undefined,
        // pmProjectOverviewPlanId: undefined,
        warehouseId: undefined,
        quantity: '',
    });
    // const [projects, setProjects] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const handleFormDataUpdate = (e) =>
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.name === 'quantity' ? formatPositiveNumber(e.target.value) : e.target.value,
        }));

    const schema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .min(2, t('Name must be at least 2 characters long!'))
            .required(t('Name is mandatory!')),
        NIR: yup.string().trim().required(t('NIR is mandatory!')),
        quantity: yup
            .number()
            .typeError(t('Quantity is mandatory!'))
            .integer()
            .min(1, t('Quantity must be bigger then 0!'))
            .required(t('Quantity is mandatory!')),
        description: yup.string().trim().required(t('Description is mandatory!')),
        type: yup.string().required(t('The type of the resource is mandatory!')),
        status: yup.string().required(t('The status of the resource is mandatory!')),
        // pmProjectOverviewPlanId: yup.string().trim(),
        warehouseId: yup.string().trim().required(t('The warehouse is mandatory!')),
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsUploading(true);

            const newFormData = {
                ...formData,
                type: types[formData.type],
                status: status[formData.status],
                // pmProjectOverviewPlanId: projects[formData.pmProjectOverviewPlanId]?.id ?? undefined,
                warehouseId: warehouses[formData.warehouseId].id,
            };

            await schema.validate(newFormData);

            if (!id) await API.post('materialResource', newFormData);
            else await API.put('materialResource', { ...newFormData, id });

            enqueueSnackbar(!id ? t('Resource created successfully!') : t('Resource updated successfully!'), {
                variant: 'success',
            });

            history.push(resourceModule.materialResources.categories);
        } catch (error) {
            if (error?.errors) enqueueSnackbar(error.errors[0], { variant: 'error' });
            else
                enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                    variant: 'error',
                });

            console.error(error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsUploading(true);

            await API.delete('materialResource', { params: { id } });

            enqueueSnackbar(t('Resource deleted successfully!'), {
                variant: 'success',
            });

            history.push(resourceModule.materialResources.categories);
        } catch (error) {
            enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                variant: 'error',
            });
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                // const projectsRes = await API.get('PmProjects', {
                //     params: {
                //         perPage: 99999,
                //         currentPage: 0,
                //         pagesToLoad: 1,
                //     },
                // });

                const warehousesRes = await API.get('warehouses', {
                    params: {
                        perPage: 99999,
                        currentPage: 0,
                        pagesToLoad: 1,
                    },
                });

                // const projectData = projectsRes.data.PmProjects.content;
                const warehouseData = warehousesRes.data.allWarehouses.warehouse;

                if (id) {
                    const res = await API.get('materialResource', { params: { id } });
                    const resourceData = res.data.getMaterialResourceById;

                    const typeIndex = types.findIndex((type) => type === resourceData.type);
                    const statusIndex = status.findIndex((status) => status === resourceData.status);
                    // const projectIndex = projectData.findIndex(
                    //     (project) => project.id === resourceData.pmProjectOverviewPlanId
                    // );
                    const warehouseIndex = warehouseData.findIndex(
                        (warehouse) => warehouse.id === resourceData.warehouseId
                    );

                    setFormData({
                        name: resourceData.name,
                        NIR: resourceData.NIR,
                        quantity: resourceData.quantity,
                        description: resourceData.description,
                        type: typeIndex,
                        status: statusIndex,
                        // pmProjectOverviewPlanId: projectIndex >= 0 ? projectIndex : undefined,
                        warehouseId: warehouseIndex,
                    });
                }

                // setProjects(projectData);
                setWarehouses(warehouseData);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t('Something went wrong!'), {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <div className="page-container">
            {isLoading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <form className="flex max-w-xl flex-col gap-5" onSubmit={handleSubmit}>
                    <TextField
                        name="name"
                        placeholder={t('Name')}
                        label={t('Name')}
                        type="text"
                        value={formData.name}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <TextField
                        name="NIR"
                        placeholder={t('NIR')}
                        label={t('NIR')}
                        type="text"
                        value={formData.NIR}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <TextField
                        name="quantity"
                        placeholder={t('Quantity')}
                        label={t('Quantity')}
                        type="text"
                        value={formData.quantity}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <TextField
                        multiline
                        name="description"
                        placeholder={t('Description')}
                        label={t('Description')}
                        type="text"
                        value={formData.description}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <LabelWrapper label={t('Select resource type')}>
                        <Dropdown
                            variant="black"
                            options={types.map((type) => t(type.split('_').join(' ')))}
                            placeholder={t('Choose the resource type')}
                            selectedOption={formData.type}
                            setSelectedOption={(i) =>
                                handleFormDataUpdate({
                                    target: {
                                        name: 'type',
                                        value: i,
                                    },
                                })
                            }
                            disabled={isUploading}
                        />
                    </LabelWrapper>
                    <LabelWrapper label={t('Select resource status')}>
                        <Dropdown
                            variant="black"
                            options={status.map((status) => t(status))}
                            placeholder={t('Choose the resource status')}
                            selectedOption={formData.status}
                            setSelectedOption={(i) =>
                                handleFormDataUpdate({
                                    target: {
                                        name: 'status',
                                        value: i,
                                    },
                                })
                            }
                            disabled={isUploading}
                        />
                    </LabelWrapper>
                    {/* <div className="flex gap-3 items-center">
                        <LabelWrapper label={t('Select project')}>
                            <Dropdown
                                variant="black"
                                options={projects.map((project) => project.name)}
                                placeholder={t('Choose the project')}
                                selectedOption={formData.pmProjectOverviewPlanId}
                                setSelectedOption={(i) =>
                                    handleFormDataUpdate({
                                        target: {
                                            name: 'pmProjectOverviewPlanId',
                                            value: i,
                                        },
                                    })
                                }
                                disabled={isUploading}
                            />
                        </LabelWrapper>
                        <div className="mt-6">
                            <ActionButton
                                icon={<DeleteIcon />}
                                color={formData.pmProjectOverviewPlanId ? '#D43545' : '#aaa'}
                                disabled={formData.pmProjectOverviewPlanId ? false : true}
                                onClick={() =>
                                    handleFormDataUpdate({
                                        target: {
                                            name: 'pmProjectOverviewPlanId',
                                            value: undefined,
                                        },
                                    })
                                }
                            />
                        </div>
                    </div> */}
                    <LabelWrapper label={t('Select warehouse')}>
                        <Dropdown
                            variant="black"
                            options={warehouses.map((warehouse) => warehouse.name)}
                            placeholder={t('Choose the warehouse')}
                            selectedOption={formData.warehouseId}
                            setSelectedOption={(i) =>
                                handleFormDataUpdate({
                                    target: {
                                        name: 'warehouseId',
                                        value: i,
                                    },
                                })
                            }
                            disabled={isUploading}
                        />
                    </LabelWrapper>
                    <div className="mt-5 flex flex-wrap justify-between gap-5">
                        <div className="flex flex-wrap gap-5">
                            <Button
                                startIcon={!id ? <AddIcon /> : <CheckIcon />}
                                color="primary"
                                type="submit"
                                disabled={isUploading}
                            >
                                {!id ? t('Add resource') : t('Edit resource')}
                            </Button>
                            {id && (
                                <Button
                                    className={styles.customButton}
                                    startIcon={<DeleteIcon />}
                                    color="primary"
                                    type="submit"
                                    disabled={isUploading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete();
                                    }}
                                >
                                    {t('Delete resource')}
                                </Button>
                            )}
                        </div>
                        <Button
                            startIcon={<BackspaceIcon />}
                            color="secondary"
                            disabled={isUploading}
                            onClick={(e) => {
                                e.preventDefault();
                                history.goBack();
                            }}
                        >
                            {t('Back')}
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default MaterialAdd;
