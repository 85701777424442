import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { crm } from 'routes';

import CreateContract from './create-contracts';
import EditContract from './edit-contract';
import ManageContracts from './manage-contracts';
import ContractsPipeline from './pipeline/contracts-pipeline';

const Contracts = () => {
    return (
        <>
            <Switch>
                <Route path={crm.base + crm.contracts.base + crm.contracts.create}>
                    <CreateContract />
                </Route>

                <Route exact path={crm.base + crm.contracts.base + crm.contracts.read}>
                    <ManageContracts />
                </Route>

                <Route path={crm.base + crm.contracts.base + crm.contracts.updateFull}>
                    <EditContract />
                </Route>

                <Route path={crm.base + crm.contracts.base + crm.contracts.pipeline}>
                    <ContractsPipeline />
                </Route>
            </Switch>
        </>
    );
};

export default Contracts;
