import React, { useState } from 'react';

import Gallery from 'components/crm/estate/gallery';
import GalleryEditable from 'components/crm/estate/gallery-editable';
import PropTypes from 'prop-types';

import DocsLayout from './docs-layout';

const FileDisplay = (props) => {
    const { type, files, setFiles, utilityOptions, entity, purpose, view } = props;
    const [initialIndex, setInitialIndex] = useState(0);
    const [galleryOpen, setGalleryOpen] = useState(false);

    return purpose === 'Image' ? (
        <>
            <GalleryEditable
                type={type}
                gallery={files}
                setGallery={setFiles}
                setOpen={setGalleryOpen}
                entity={entity}
                photoClickCallback={(photoIndex) => {
                    setInitialIndex(photoIndex);
                }}
                view={view}
            />
            <Gallery
                open={galleryOpen}
                setOpen={setGalleryOpen}
                initialIndex={initialIndex}
                urlList={files ? files.map((img) => img.url) : []}
            />
        </>
    ) : (
        <DocsLayout
            files={files}
            setFiles={setFiles}
            type={type}
            utilityOptions={utilityOptions}
            view={view}
            entity={entity}
        />
    );
};

FileDisplay.propTypes = {
    purpose: PropTypes.string,
    entity: PropTypes.string,
    type: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.object),
    setFiles: PropTypes.func,
    utilityOptions: PropTypes.object,
    view: PropTypes.bool,
};

FileDisplay.defaultProps = {
    view: false,
    purpose: 'File',
    entity: null,
    type: 'Uni-select',
    files: [],
    setFiles: () => null,
    utilityOptions: { canDelete: false, canCopyLink: false, canDownload: false },
};

export default FileDisplay;
