import React, { useEffect, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import { Button, TextField } from '@material-ui/core';

import PropTypes from 'prop-types';
import { ActionButton, SelectList } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';

import ConfigFieldWrapper from './config-field-wrapper';

const SelectorField = (props) => {
    const { t } = useTranslation();
    const { field, changeFieldValue, deleteFieldFromColumn } = props;
    const [selectedType, setSelectedType] = useState([field.fieldOptions.indexOf(field.selectedTypeOptions)]);
    const [options, setOptions] = useState(field.options);

    useEffect(() => {
        changeFieldValue('selectedTypeOptions', field.fieldOptions[selectedType[0]]);
    }, [selectedType]);

    useEffect(() => {
        changeFieldValue('options', options);
    }, [options]);

    return (
        <>
            {field ? (
                <ConfigFieldWrapper
                    canBeDeleted={field.canBeDeleted}
                    type={field.type}
                    name={field.displayName}
                    setName={(newValue) => changeFieldValue('displayName', newValue)}
                    deleteFieldFromColumn={deleteFieldFromColumn}
                >
                    <div className="mb-2">
                        <SelectList
                            options={field.fieldOptions}
                            selectedOptions={selectedType}
                            setSelectedOptions={setSelectedType}
                        />
                    </div>

                    {options.map((option, index) => (
                        <div key={index} className="group relative mb-1 flex items-center">
                            <TextField
                                value={option}
                                placeholder={t('Option name')}
                                onChange={(e) => {
                                    const newOptions = [...options];
                                    newOptions[index] = e.target.value;
                                    setOptions(newOptions);
                                }}
                            />

                            <div
                                className="absolute right-2 -translate-x-2 transform opacity-0 transition-all duration-150 group-hover:translate-x-0 group-hover:opacity-100"
                                onClick={() => setOptions(options.filter((_, i) => i !== index))}
                            >
                                <ActionButton icon={<CloseIcon className="text-main-text" />} />
                            </div>
                        </div>
                    ))}

                    <div className="h-2" />

                    <Button onClick={() => setOptions([...options, ''])}>{t('Add option')}</Button>
                </ConfigFieldWrapper>
            ) : null}
        </>
    );
};

SelectorField.propTypes = {
    field: PropTypes.object,
    changeFieldValue: PropTypes.func,
    deleteFieldFromColumn: PropTypes.func,
};

SelectorField.defaultProps = {
    field: null,
    changeFieldValue: () => null,
    deleteFieldFromColumn: () => null,
};

export default SelectorField;
