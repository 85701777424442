import React from 'react';

import ArticleCategories from 'pages/quotes/nomenclature/article-categories';
import CreateArticleCategory from 'pages/quotes/nomenclature/create-article-category';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InitializeButtons from './initialize-buttons';
import InitializeStepInfo from './initialize-step-info';

const InitializeArticleCategories = ({
    handleIncrementStep,
    handleDecrementStep,
    initializeData,
    setInitializeData,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <InitializeStepInfo
                mainInfo={[
                    {
                        id: 0,
                        content: t(
                            'You can add categories for articles which will be used in the next step to create an article'
                        ),
                    },
                ]}
                secondaryInfo={[{ id: 0, content: t("If you don't want to add one, this step can be skipped") }]}
                className="mb-10"
            />
            {initializeData.tab === 0 && <ArticleCategories setInitializeData={setInitializeData} />}
            {initializeData.tab === 1 && <CreateArticleCategory setInitializeData={setInitializeData} />}
            <InitializeButtons
                handleIncrementStep={handleIncrementStep}
                handleDecrementStep={handleDecrementStep}
                className={'mt-20 flex justify-start gap-4'}
                canSkip={true}
                nextDisabled={initializeData.nextDisabled || initializeData.loading}
                prevDisabled={initializeData.loading}
                skipDisabled={initializeData.loading}
            />
        </>
    );
};

InitializeArticleCategories.propTypes = {
    handleIncrementStep: PropTypes.func,
    handleDecrementStep: PropTypes.func,
    initializeData: PropTypes.object,
    setInitializeData: PropTypes.func,
};

InitializeArticleCategories.defaultProps = {
    handleIncrementStep: null,
    handleDecrementStep: null,
    initializeData: {},
    setInitializeData: () => {},
};

export default InitializeArticleCategories;
