import React, { useState } from 'react';

import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import { PropTypes } from 'prop-types';
import { Header, TableSeparator } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

import ProjectClientRow from './project-client-row';

const ProjectClients = ({ projectId }) => {
    const { t } = useTranslation();

    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);

    const getClientsOnProject = async (currentPage, perPage) => {
        try {
            const res = await API.get('contactsPm', {
                params: {
                    currentPage,
                    perPage,
                    pagesToLoad: 1,
                    pm_planningOverviewProjectId: projectId,
                },
            });

            const mappedClients = res.data.formattedContacts.map((client) => {
                const parsedData = typeof client.data === 'string' ? JSON.parse(client.data) : client.data;
                const contactInfo = { ...parsedData.standard, ...parsedData.extra_info };

                return { id: client.id, ...contactInfo };
            });

            setClients(mappedClients);

            return res.data.length;
        } catch (error) {
            throw new Error(error);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('Clients on project')}</title>
            </Helmet>
            <Header pageTitle={t('Clients on project')} />

            <Pagination loading={loading} setLoading={setLoading} getFunction={getClientsOnProject}>
                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : clients.length === 0 ? (
                    <NoDataPlaceholder />
                ) : (
                    <div className="page-container">
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Name')}</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>{t('Phone')}</TableCell>
                                        <TableCell>CNP</TableCell>
                                        <TableCell>{t('Address')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {clients.map((client) => (
                                        <ProjectClientRow key={client.id} client={client} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </Pagination>
        </React.Fragment>
    );
};

ProjectClients.propTypes = {
    projectId: PropTypes.string,
};

ProjectClients.defaultProps = {
    projectId: null,
};

export default ProjectClients;
