import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import API from 'utils/axios';
import { errorHandling } from 'utils/index';

import PDFTemplateForm from './pdf-template-form';

const EditPDFTemplate = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { templateId } = useParams();

    const [templateInfo, setTemplateInfo] = useState({});

    useEffect(() => {
        (async () => {
            try {
                const res = await API.get(`offer_template_pages/${templateId}`);

                setTemplateInfo(res.data.data);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        })();
    }, []);

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Edit PDF template')}</title>
            </Helmet>

            <Header pageTitle={t('Edit PDF template')} />
            <div className="page-container">
                <PDFTemplateForm templateInfo={templateInfo} />
            </div>
        </>
    );
};

export default EditPDFTemplate;
