import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { internalActivity } from 'routes';

import CreatePartner from './create-partner';
import EditPartner from './edit-partner';
import ManagePartners from './manage-partners';

const Partners = () => {
    return (
        <Switch>
            <Route path={internalActivity.base + internalActivity.partners.base + internalActivity.partners.create}>
                <CreatePartner />
            </Route>

            <Route exact path={internalActivity.base + internalActivity.partners.base + internalActivity.partners.read}>
                <ManagePartners />
            </Route>

            <Route path={internalActivity.base + internalActivity.partners.base + internalActivity.partners.updateFull}>
                <EditPartner />
            </Route>
        </Switch>
    );
};

export default Partners;
