import React from 'react';
import { useParams } from 'react-router-dom';

import Gantt from 'components/planning-overview/gantt/gantt';
import GantContext from 'contexts/GantContext';
import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const AffiliateProjectOverview = ({ project }) => {
    const { t } = useTranslation();
    const { projectId } = useParams();

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('PM Planning overview')}</title>
            </Helmet>
            <Header pageTitle="PM Planning Overview"></Header>

            <GantContext.Provider
                value={{
                    type: 'pm',
                    projectId,
                }}
            >
                {project && <Gantt projects={[project]} />}
            </GantContext.Provider>
        </React.Fragment>
    );
};

AffiliateProjectOverview.propTypes = { project: PropTypes.object };

export default AffiliateProjectOverview;
