import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button } from '@material-ui/core';

import Footer from 'components/footer/footer';
import Config from 'components/shared/configurator/configurator';
import UserContext from 'contexts/UserContext';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';

import CreateProjects from '../../components/crm/zones-projects/create-projects';
import CreateZone from '../../components/crm/zones-projects/create-zone';
import EditZones from '../../components/crm/zones-projects/edit-zones';
import CompanyDetails from './company-details';
import Departments from './departments';
import CreateDepartments from './departments/create-departments';
import EditDepartments from './departments/edit-departments';
import AddDocumentTemplate from './documents_configurator/add-document-template';
import DocumentsTemplates from './documents_configurator/documents-templates';
import ManageEmailTemplates from './email_templates';
import CreateEmailTemplate from './email_templates/create_email_template';
import EditEmailTemplate from './email_templates/edit_email_template';
import Milestones from './milestones';
import CreateMilestone from './milestones/create-milestones';
import EditMilestone from './milestones/edit-milestones';
import OfferTags from './offer-tags';
import CreateOfferTag from './offer-tags/create-offer-tag';
import EditOfferTag from './offer-tags/edit-offer-tag';
import ManagePDFTemplates from './pdf-templates';
import CreatePDFTemplate from './pdf-templates/create-pdf-template';
import EditPDFTemplate from './pdf-templates/edit-pdf-template';
import Users from './users';
import CreateUser from './users/create-user';
import EditUser from './users/edit-user';

const ConfiguratorRouter = () => {
    const { user } = useContext(UserContext);

    return (
        <>
            <Switch>
                <Route exact path={configurator.base}>
                    <Configurator />
                </Route>

                <Route path={configurator.base + configurator.zones.base + configurator.zones.create}>
                    <CreateZone />
                </Route>

                <Route path={configurator.base + configurator.zones.base + configurator.zones.updateFull}>
                    <EditZones />
                </Route>

                <Route exact path={configurator.base + configurator.projects.base + configurator.projects.create}>
                    <CreateProjects />
                </Route>

                <Route path={configurator.base + configurator.projects.base + configurator.projects.createWithZone}>
                    <CreateProjects />
                </Route>

                {user && !user.isAffiliate && (
                    <Route path={configurator.base + configurator.users.base + configurator.users.create}>
                        <CreateUser />
                    </Route>
                )}

                <Route path={configurator.base + configurator.users.base + configurator.users.updateFull}>
                    <EditUser />
                </Route>

                <Route path={configurator.base + configurator.departments.base + configurator.departments.create}>
                    <CreateDepartments />
                </Route>

                <Route path={configurator.base + configurator.departments.base + configurator.departments.updateFull}>
                    <EditDepartments />
                </Route>

                <Route path={configurator.base + configurator.milestones.base + configurator.milestones.create}>
                    <CreateMilestone />
                </Route>

                <Route path={configurator.base + configurator.milestones.base + configurator.milestones.updateFull}>
                    <EditMilestone />
                </Route>

                <Route path={configurator.base + configurator.offerTags.base + configurator.offerTags.create}>
                    <CreateOfferTag />
                </Route>

                <Route path={configurator.base + configurator.offerTags.base + configurator.offerTags.updateFull}>
                    <EditOfferTag />
                </Route>

                <Route path={configurator.base + configurator.pdfTemplates.base + configurator.pdfTemplates.create}>
                    <CreatePDFTemplate />
                </Route>

                <Route path={configurator.base + configurator.pdfTemplates.base + configurator.pdfTemplates.updateFull}>
                    <EditPDFTemplate />
                </Route>

                <Route path={configurator.base + configurator.emailTemplates.base + configurator.emailTemplates.create}>
                    <CreateEmailTemplate />
                </Route>

                <Route path={configurator.base + configurator.emailTemplates.base + configurator.emailTemplates.update}>
                    <EditEmailTemplate />
                </Route>

                <Route
                    path={
                        configurator.base +
                        configurator.documentsTemplates.base +
                        configurator.documentsTemplates.create
                    }
                >
                    <AddDocumentTemplate />
                </Route>
                <Route
                    path={
                        configurator.base +
                        configurator.documentsTemplates.base +
                        configurator.documentsTemplates.updateFull
                    }
                >
                    <AddDocumentTemplate />
                </Route>
            </Switch>
            <Footer />
        </>
    );
};

const Configurator = () => {
    const history = useHistory();
    const params = new URL(document.location).searchParams;
    const { checkPerm, user } = useContext(UserContext);

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(null);
    const [activeConfigTab, setActiveConfigTab] = useState(0);

    const allTabs =
        user && !user.isAffiliate
            ? [
                  {
                      id: '17',
                      tab: t('Company details'),
                  },
                  {
                      id: '11',
                      tab: t('users'),
                  },
                  //   {
                  //       id: '12',
                  //       tab: t('Areas & Projects'),
                  //   },
                  // {
                  //     id: '1',
                  //     tab: t('working-points'),
                  // },
                  {
                      id: '13',
                      tab: t('CRM Configurator'),
                  },
                  {
                      id: '14',
                      tab: t('CRM Departments'),
                  },
                  {
                      id: '15',
                      tab: t('Milestone'),
                  },
                  {
                      id: '33',
                      tab: t('Offer tags'),
                  },
                  {
                      id: '34',
                      tab: t('PDF Templates'),
                  },
                  {
                      id: '35',
                      tab: t('Email templates'),
                  },
                  {
                      id: '34',
                      tab: t('Documents templates'),
                  },
              ]
            : [
                  //   {
                  //       id: '12',
                  //       tab: t('Areas & Projects'),
                  //   },
                  {
                      id: '15',
                      tab: t('Milestone'),
                  },
              ];

    const tabs = [];
    allTabs.forEach((t) => {
        if (
            checkPerm([
                {
                    permissionId: t.id,
                    permissionType: 'VIEW',
                },
            ])
        )
            tabs.push(t.tab);
    });

    const configTabs = [t('Contacts'), t('Estate'), t('Contracts'), 'Partners'];

    useEffect(() => {
        if (params.get('tab') === null) {
            setActiveTab(0);
        } else {
            setActiveTab(tabs.indexOf(`${t(`${params.get('tab')}`)}`));
        }
    }, []);

    if (tabs.length <= 0) {
        history.push('/');
    }

    return (
        <>
            <Helmet>
                <title>{t('ERP Configurator')}</title>
            </Helmet>

            <Header
                pageTitle={tabs[activeTab]}
                action={
                    activeTab === tabs.indexOf(t('Areas & Projects')) ? (
                        <div className="flex items-center">
                            {checkPerm([
                                {
                                    permissionId: '12',
                                    permissionType: 'ALL',
                                },
                            ]) && (
                                <Button
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    style={{ borderRadius: '999px' }}
                                    onClick={() =>
                                        history.push(
                                            configurator.base + configurator.zones.base + configurator.zones.create
                                        )
                                    }
                                >
                                    {t('Add new area')}
                                </Button>
                            )}

                            <div className="w-4" />

                            {checkPerm([
                                {
                                    permissionId: '16',
                                    permissionType: 'ALL',
                                },
                            ]) && (
                                <Button
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    style={{ borderRadius: '999px' }}
                                    onClick={() =>
                                        history.push(
                                            configurator.base +
                                                configurator.projects.base +
                                                configurator.projects.create
                                        )
                                    }
                                >
                                    {t('Add new project')}
                                </Button>
                            )}
                        </div>
                    ) : activeTab === tabs.indexOf(t('users')) ? (
                        <>
                            {user.isAdmin && (
                                <Button
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    style={{ borderRadius: '999px' }}
                                    onClick={() =>
                                        history.push(
                                            configurator.base + configurator.users.base + configurator.users.create
                                        )
                                    }
                                >
                                    {t('Add new user')}
                                </Button>
                            )}
                        </>
                    ) : activeTab === tabs.indexOf(t('CRM Departments')) ? (
                        <>
                            {checkPerm([
                                {
                                    permissionId: '14',
                                    permissionType: 'ALL',
                                },
                            ]) && (
                                <Button
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    style={{ borderRadius: '999px' }}
                                    onClick={() =>
                                        history.push(
                                            configurator.base +
                                                configurator.departments.base +
                                                configurator.departments.create
                                        )
                                    }
                                >
                                    {t('Add new department')}
                                </Button>
                            )}
                        </>
                    ) : activeTab === tabs.indexOf(t('Milestone')) ? (
                        <>
                            {checkPerm([
                                {
                                    permissionId: '15',
                                    permissionType: 'ALL',
                                },
                            ]) && (
                                <Button
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    style={{ borderRadius: '999px' }}
                                    onClick={() =>
                                        history.push(
                                            configurator.base +
                                                configurator.milestones.base +
                                                configurator.milestones.create
                                        )
                                    }
                                >
                                    {t('Add new Milestone')}
                                </Button>
                            )}
                        </>
                    ) : activeTab === tabs.indexOf(t('Offer tags')) ? (
                        <>
                            {checkPerm([
                                {
                                    permissionId: '33',
                                    permissionType: 'ALL',
                                },
                            ]) && (
                                <Button
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    style={{ borderRadius: '999px' }}
                                    onClick={() =>
                                        history.push(
                                            configurator.base +
                                                configurator.offerTags.base +
                                                configurator.offerTags.create
                                        )
                                    }
                                >
                                    {t('Add new offer tag')}
                                </Button>
                            )}
                        </>
                    ) : activeTab === tabs.indexOf(t('PDF Templates')) ? (
                        <>
                            {checkPerm([
                                {
                                    permissionId: '34',
                                    permissionType: 'ALL',
                                },
                            ]) && (
                                <Button
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    style={{ borderRadius: '999px' }}
                                    onClick={() =>
                                        history.push(
                                            configurator.base +
                                                configurator.pdfTemplates.base +
                                                configurator.pdfTemplates.create
                                        )
                                    }
                                >
                                    {t('Add new PDF template')}
                                </Button>
                            )}
                        </>
                    ) : // : activeTab === tabs.indexOf(t('Email templates')) ? (
                    //     <>
                    //         {checkPerm([
                    //             {
                    //                 permissionId: '35',
                    //                 permissionType: 'ALL',
                    //             },
                    //         ]) && (
                    //             <Button
                    //                 color="secondary"
                    //                 startIcon={<AddIcon />}
                    //                 style={{ borderRadius: '999px' }}
                    //                 onClick={() =>
                    //                     history.push(
                    //                         configurator.base +
                    //                             configurator.emailTemplates.base +
                    //                             configurator.emailTemplates.create
                    //                     )
                    //                 }
                    //             >
                    //                 {t('Add new email template')}
                    //             </Button>
                    //         )}
                    //     </>
                    // )

                    activeTab === tabs.indexOf(t('Documents templates')) ? (
                        <>
                            {checkPerm([
                                {
                                    permissionId: '34',
                                    permissionType: 'ALL',
                                },
                            ]) && (
                                <Button
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    style={{ borderRadius: '999px' }}
                                    onClick={() =>
                                        history.push(
                                            configurator.base +
                                                configurator.documentsTemplates.base +
                                                configurator.documentsTemplates.create
                                        )
                                    }
                                >
                                    {t('Add new template')}
                                </Button>
                            )}
                        </>
                    ) : null
                }
                toolbar={
                    <div className="flex items-center">
                        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </div>
                }
                toolbarSecondary={
                    activeTab === tabs.indexOf(t('CRM Configurator')) ? (
                        <div className="flex items-center">
                            <Tabs tabs={configTabs} activeTab={activeConfigTab} setActiveTab={setActiveConfigTab} />
                        </div>
                    ) : null
                }
            />

            <div className="page-container relative">
                {activeTab === tabs.indexOf(t('Company details')) && <CompanyDetails />}

                {/* Users */}
                {activeTab === tabs.indexOf(t('users')) && <Users />}

                {/*
                        Zones and Projects
                    */}
                {/* {activeTab === tabs.indexOf(t('Areas & Projects')) && <ZonesAndProjects />} */}

                {/* {activeTab === 3 && <div className="text-white">Pagină în dezvoltare!</div>} */}

                {/*Departaments
                 */}
                {activeTab === tabs.indexOf(t('CRM Departments')) && <Departments />}

                {/* 
                    Milestones
                */}
                {activeTab === tabs.indexOf(t('Milestone')) && <Milestones />}

                {/* 
                   Offer tags
                */}
                {activeTab === tabs.indexOf(t('Offer tags')) && <OfferTags />}

                {/* 
               PDF Templates
               */}
                {activeTab === tabs.indexOf(t('PDF Templates')) && <ManagePDFTemplates />}

                {/* 
               Email Templates
               */}
                {activeTab === tabs.indexOf(t('Email templates')) && <ManageEmailTemplates />}

                {/* 
                Documents Templates
               */}
                {activeTab === tabs.indexOf(t('Documents templates')) && <DocumentsTemplates />}

                {/* 
                    Configurators
                */}
                {activeTab === tabs.indexOf(t('CRM Configurator')) && (
                    <>
                        {activeConfigTab === 0 && <Config entity="contact" />}
                        {activeConfigTab === 1 && <Config entity="estate" />}
                        {activeConfigTab === 2 && <Config entity="contract" />}
                        {activeConfigTab === 3 && <Config entity="partner" />}
                    </>
                )}
            </div>
        </>
    );
};

export default ConfiguratorRouter;
