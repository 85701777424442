import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import OfferTagForm from 'components/crm/offer-tag-form';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import API from 'utils/axios';

const EditOfferTag = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { offerTagId } = useParams();

    const [tagInfo, setTagInfo] = useState();

    useEffect(() => {
        (async () => {
            try {
                const res = await API.get(`offer_tag/${offerTagId}`);
                setTagInfo(res.data.offerTag);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        })();
    }, []);

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Edit offer tag')}</title>
            </Helmet>

            <Header pageTitle={t('Edit offer tag')} />
            <div className="page-container">
                <OfferTagForm tagInfo={tagInfo} />
            </div>
        </>
    );
};

export default EditOfferTag;
