import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { affiliate } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';

import AffiliateProjectOverview from './affiliate-project-overview';
import AffiliateProjectTasks from './affiliate-project-tasks';
import AffiliateProjectWikis from './affiliate-project-wikis';

const AffiliateProjectRoutes = () => {
    const { t } = useTranslation();
    const enqueueSnackbar = useSnackbar();
    const { changeNavName } = useContext(GlobalContext);

    const { projectId } = useParams();

    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/pm_projectPlanningOverview', {
                    params: {
                        id: projectId,
                    },
                });

                setProject(response.data.pm);
                changeNavName(response.data.pm.name);
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('Project')}</title>
            </Helmet>
            {loading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <Switch>
                    <Route
                        path={affiliate.base + affiliate.projectManagement.projectFull + affiliate.projectWikis.base}
                    >
                        <AffiliateProjectWikis />
                    </Route>

                    <Route
                        path={affiliate.base + affiliate.projectManagement.projectFull + affiliate.projectTasks.base}
                    >
                        <AffiliateProjectTasks />
                    </Route>

                    <Route
                        path={
                            affiliate.base +
                            affiliate.projectManagement.projectFull +
                            affiliate.planingOverviewOnProject.base
                        }
                    >
                        <AffiliateProjectOverview project={project} />
                    </Route>
                </Switch>
            )}
        </>
    );
};

export default AffiliateProjectRoutes;
