import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ArticleForm from 'components/crm/quotes/article-form';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';

const CreateNomenclature = ({ setInitializeData }) => {
    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);
    const history = useHistory();

    const canAll = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push(quotes.base + quotes.nomenclature.base + quotes.nomenclature.read);
        }
    }, [canAll]);

    if (setInitializeData) return <ArticleForm setInitializeData={setInitializeData} />;

    return (
        <>
            <Helmet>
                <title>
                    {t('nomenclature')} | {t('Add new article')}
                </title>
            </Helmet>
            {canAll && (
                <>
                    <Header pageTitle={t('Add new article')} />
                    <ArticleForm />
                </>
            )}
        </>
    );
};

CreateNomenclature.propTypes = {
    setInitializeData: PropTypes.func,
};

CreateNomenclature.defaultProps = {
    setInitializeData: null,
};

export default CreateNomenclature;
