import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';

import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import FrappeGantt from 'components/frappe-gantt/frappe-gantt-layout';
import TaskManagementDisplay from 'components/modules/pm-task-management/pm-task-management-display';
import PMTaskManagementModal from 'components/modules/pm-task-management/pm-task-management-modal';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import TaskManagementContext from 'contexts/TaskManagementContext';
import { useSnackbar } from 'notistack';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import { getTasks } from 'utils/ganttUtils';
import { getTenantUsers } from 'utils/getterFunctions';

const AffiliateProjectTasks = () => {
    const { t } = useTranslation();
    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [modalOpen, setModalOpen] = useState(false);

    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const [departments, setDepartments] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [seeGantt, setSeeGantt] = useState(false);

    /**
     * Status of the activity ( to do or done)
     * This state is use for sorting the activity by status
     */
    const [status, setStatus] = useState('all');
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    /**
     * Type of the activity ( comment, tasks, files, documents, invoices)
     * This state is use for sorting the activity by type
     */
    const [activityType, setActivityType] = useState('ALL');
    const handleTypeChange = (event) => {
        setActivityType(event.target.value);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('pmDepartments', {
                    params: {
                        perPage: 9999,
                        currentPage: 0,
                        pagesToLoad: 1,
                        type: 'PLANNING_OVERVIEW',
                    },
                });

                setDepartments(response.data.pmDepartments);
            } catch (err) {
                console.error(err);
            }

            const tenantsFetch = await getTenantUsers();
            setTenants(tenantsFetch);
        })();
    }, []);

    /**
     * State for open and close the modal
     * On creation of an activity: isCreating is true
     */
    const [isCreating, setIsCreating] = useState(false);

    const submitTaskManagementActivity = async (formData) => {
        if (isCreating) return;

        try {
            setIsCreating(true);

            await API.post('/pmProjectActivity', formData);

            enqueueSnackbar(t('Task Management activity added successfully!'), { variant: 'success' });

            setModalOpen(false);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        } finally {
            setIsCreating(false);
            fetchActivities();
        }
    };

    /*
        Fetching data periodically, and separating all activities in 2 arrays
        Update occurs only if differences are spotted
    */
    const [loadingActivities, setLoadingActivities] = useState(true);
    const [todoActivities, setTodoActivities] = useState([]);
    const memoizedToDoActivities = useMemo(() => todoActivities.map((a) => a.activityInfo), [todoActivities]);
    const [doneActivities, setDoneActivities] = useState([]);

    const fetchActivities = async () => {
        if (!departments.length) return;

        const params = {
            currentPage: 0,
            perPage: 9999,
            pagesToLoad: 1,
            pmProjectPlanId: projectId,
        };

        setLoadingActivities(true);

        const res = await API.get('/pmProjectActivityForAffiliates', { params });

        const todoFiltered = [
            ...res.data.activity.filter(
                (a) =>
                    a.activityInfo.status === 'TODO' &&
                    (activityType === 'ALL' || activityType === a.activityInfo.actionType) &&
                    (selectedDepartment === 0 ||
                        a.activityInfo.pmDepartmentId === departments[selectedDepartment - 1].id)
            ),
        ];

        if (JSON.stringify(todoFiltered) !== JSON.stringify(todoActivities)) {
            setTodoActivities(todoFiltered);
        }

        const doneFiltered = [
            ...res.data.activity.filter(
                (a) =>
                    a.activityInfo.status === 'DONE' &&
                    (activityType === 'ALL' || activityType === a.activityInfo.actionType) &&
                    (selectedDepartment === 0 ||
                        a.activityInfo.pmDepartmentId === departments[selectedDepartment - 1].id)
            ),
        ];

        if (JSON.stringify(doneFiltered) !== JSON.stringify(doneActivities)) {
            setDoneActivities(doneFiltered);
        }

        setLoadingActivities(false);
    };

    const [isDeleting, setIsDeleting] = useState(false);
    const deleteActivity = async (activity) => {
        // if (user.id !== activity.authorId) return;
        if (isDeleting) return;

        setIsDeleting(true);

        try {
            await API.delete('/pmProjectActivity', {
                data: {
                    id: activity.id,
                },
            });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        } finally {
            setIsDeleting(false);
            fetchActivities();
        }
    };

    const [isUpdating, setIsUpdating] = useState(false);
    const updateActivity = async (activity, newValue) => {
        if (isUpdating) return;
        setIsUpdating(true);

        try {
            await API.post('/mark_activity', {
                id: activity.id,
                status: newValue,
            });

            fetchActivities();
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        } finally {
            setIsUpdating(false);
        }
    };

    /**
     * This useEffect is for sorting. When the dependency is changed, is calling fetchActivities with correct params for sorting
     */
    useEffect(() => {
        if (departments.length > 0) {
            fetchActivities();
        }
    }, [departments, activityType, selectedDepartment]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('Tasks on project')}</title>
            </Helmet>
            <Header
                pageTitle={t('Tasks on project')}
                action={
                    <div
                        className={`duration-350 transform transition-all ${
                            modalOpen ? 'translate-x-8 opacity-0' : 'translate-x-0 opacity-100'
                        }`}
                    >
                        <Button
                            startIcon={<AddIcon />}
                            color="secondary"
                            style={{ borderRadius: '999px' }}
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        >
                            {t('Add activity')}
                        </Button>
                    </div>
                }
                toolbar={
                    <div className="flex items-center gap-8">
                        <Tabs
                            tabs={[t('All'), ...(departments?.map((d) => d.name) ?? [])]}
                            activeTab={selectedDepartment}
                            setActiveTab={setSelectedDepartment}
                        />
                        {seeGantt ? (
                            <Button
                                style={{ borderRadius: '999px' }}
                                onClick={() => {
                                    setSeeGantt(false);
                                }}
                            >
                                {t('See Timeline')}
                            </Button>
                        ) : (
                            <Button
                                style={{ borderRadius: '999px' }}
                                onClick={() => {
                                    setSeeGantt(true);
                                }}
                            >
                                {t('See Gantt')}
                            </Button>
                        )}
                    </div>
                }
                toolbarSecondary={
                    <div className="flex flex-col">
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="status"
                                name="status-filter"
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <div className="no-user-select-recursive flex flex-wrap items-center">
                                    <FormControlLabel value="all" control={<Radio />} label={t('All')} />
                                    <FormControlLabel value="todo" control={<Radio />} label={t('To do')} />
                                    <FormControlLabel value="done" control={<Radio />} label={t('Done')} />
                                </div>
                            </RadioGroup>
                        </FormControl>

                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="activity-type"
                                name="activity-type-filter"
                                value={activityType}
                                onChange={handleTypeChange}
                            >
                                <div className="no-user-select-recursive flex flex-wrap items-center">
                                    <FormControlLabel value="ALL" control={<Radio />} label={t('All')} />
                                    <FormControlLabel value="COMMENT" control={<Radio />} label={t('Comments')} />
                                    <FormControlLabel value="TASK" control={<Radio />} label={'Tasks'} />
                                    <FormControlLabel value="FILES" control={<Radio />} label={t('Files')} />
                                    <FormControlLabel value="DOCUMENTS" control={<Radio />} label={t('Documents')} />
                                    <FormControlLabel
                                        value="INVOICES_AND_BILLS"
                                        control={<Radio />}
                                        label={t('Invoices/Bills')}
                                    />
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                }
            />
            <div className="page-container">
                {seeGantt ? (
                    <FrappeGantt milestones={memoizedToDoActivities} getTasksFunction={getTasks} />
                ) : (
                    <TaskManagementContext.Provider
                        value={{
                            isCreating,
                            setIsCreating,
                            submitTaskManagementActivity,
                            fetchActivities,
                        }}
                    >
                        <div className=" flex items-start justify-center lg:items-center">
                            <div
                                className="relative z-40 flex w-full flex-col items-center pl-24 md:pl-32 sm:pl-0"
                                style={{ maxWidth: '1000px' }}
                            >
                                {(todoActivities.length > 0 || doneActivities.length > 0) && (
                                    <Fragment>
                                        {(((status === 'all' || status === 'todo') && todoActivities.length > 0) ||
                                            isCreating) && (
                                            <div className="relative w-full border-l-2 border-layout-lighter py-8 pl-8 sm:pl-4">
                                                <h2 className="mb-12">{t('To do')}</h2>

                                                <div
                                                    className={`pointer-events-none flex w-full items-center justify-center overflow-hidden rounded-md bg-layout-transparent transition-all duration-300 ${
                                                        isCreating ? 'opacity-1 mb-10 h-48' : 'mb-0 h-0 opacity-0'
                                                    }`}
                                                >
                                                    <CircularProgress />
                                                </div>

                                                {todoActivities.map((a) => (
                                                    <TaskManagementDisplay
                                                        key={a.activityInfo.id}
                                                        activity={a}
                                                        deleteActivity={deleteActivity}
                                                        fetchActivities={fetchActivities}
                                                        updateActivity={updateActivity}
                                                        milestoneCheck={true}
                                                        // check={canAll ? true : false}
                                                        // actionArrow={canAll ? true : false}
                                                        // deleteIcon={canAll ? true : false}
                                                        // commentView={canAll ? true : false}
                                                        // userLink={canAll ? true : false}
                                                    />
                                                ))}
                                            </div>
                                        )}

                                        {(status === 'all' || status === 'done') && doneActivities.length > 0 && (
                                            <div className="relative w-full border-l-2 border-layout-light py-8 pl-8 sm:pl-4">
                                                <h2 className="mb-12">{t('Done')}</h2>
                                                {doneActivities.map((a) => (
                                                    <TaskManagementDisplay
                                                        key={a.activityInfo.id}
                                                        activity={a}
                                                        deleteActivity={deleteActivity}
                                                        fetchActivities={fetchActivities}
                                                        updateActivity={updateActivity}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </Fragment>
                                )}

                                {!todoActivities.length && !doneActivities.length && !loadingActivities && (
                                    <NoDataPlaceholder />
                                )}
                            </div>

                            {modalOpen && (
                                <div className="sticky top-8 z-50 ml-8 rounded-md bg-layout-transparent xl:fixed xl:left-2/4 xl:top-2/4 xl:ml-0 xl:-translate-x-2/4 xl:-translate-y-2/4 xl:transform xl:bg-layout-lighter">
                                    <PMTaskManagementModal
                                        closeModal={() => setModalOpen(false)}
                                        departments={departments}
                                        tenants={tenants}
                                    />
                                </div>
                            )}

                            <div
                                className={`fixed left-0 top-0  z-40 hidden h-svh w-screen ${
                                    modalOpen ? 'xl:flex' : ''
                                }`}
                                style={{ backgroundColor: 'rgba(0,0,0,0.3)', backdropFilter: 'blur(5px)' }}
                                onClick={() => setModalOpen((prev) => !prev)}
                            />
                        </div>
                    </TaskManagementContext.Provider>
                )}
            </div>
        </React.Fragment>
    );
};

export default AffiliateProjectTasks;
