import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import { Revenues } from 'api/Expenses_Revenues';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { ExpandableRow, Header, TableSeparator } from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { accountancy } from 'routes';

import RevenueForm from '../../../components/accountancy/revenue-form';

const RevenueRow = ({ revenue, canAll, isInitializeCase }) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <ExpandableRow
            colSpan={2}
            padding={false}
            row={
                <>
                    <TableCell>{revenue.name}</TableCell>
                </>
            }
            collapse={
                <>
                    {canAll && !isInitializeCase && (
                        <div className="p-5">
                            <Button
                                color="primary"
                                startIcon={<MonetizationOnIcon />}
                                onClick={() =>
                                    history.push(
                                        accountancy.base +
                                            accountancy.revenueNomenclature.base +
                                            accountancy.revenueNomenclature.update +
                                            '/' +
                                            revenue.id
                                    )
                                }
                            >
                                {t('Edit revenue')}
                            </Button>
                        </div>
                    )}
                    <div className="p-5">
                        <RevenueForm revenueId={revenue.id} canEdit={canAll && !isInitializeCase} />
                    </div>
                </>
            }
        />
    );
};

RevenueRow.propTypes = {
    revenue: PropTypes.object,
    canAll: PropTypes.bool,
    isInitializeCase: PropTypes.bool,
};

RevenueRow.defaultProps = {
    revenue: null,
    canAll: null,
    isInitializeCase: false,
};

const ManageRevenue = ({ setInitializeData }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { permissionMap } = useContext(UserContext);
    const canView = permissionMap['Nomenclator venituri'] > 0;
    const canAll = permissionMap['Nomenclator venituri'] > 1;

    useEffect(() => {
        if (!canView) history.push('/');
    }, [canView]);

    const [loadingRevenue, setLoadingRevenue] = useState(true);
    const [revenue, setRevenue] = useState([]);

    const getRevenues = async (currentPage, perPage) => {
        try {
            const res = await Revenues.get(perPage, currentPage);

            if (res.ok) {
                setRevenue(res.data.revenuName);
                setInitializeData?.((prev) => ({ ...prev, nextDisabled: !res.data.length }));
                return res.data.length;
            } else {
                throw new Error(res.error);
            }
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <>
            {!setInitializeData && (
                <>
                    <Helmet>
                        <title>{t('Revenues management')}</title>
                    </Helmet>

                    <Header
                        pageTitle={t('Revenues management')}
                        action={
                            canAll && (
                                <Button
                                    color="primary"
                                    style={{ borderRadius: '999px' }}
                                    startIcon={<AddIcon />}
                                    onClick={() =>
                                        history.push(
                                            accountancy.base +
                                                accountancy.revenueNomenclature.base +
                                                accountancy.revenueNomenclature.create
                                        )
                                    }
                                >
                                    {t('Add revenue')}
                                </Button>
                            )
                        }
                    />
                </>
            )}

            <div className={`${!setInitializeData ? 'page-container' : ''}`}>
                <Pagination loading={loadingRevenue} setLoading={setLoadingRevenue} getFunction={getRevenues}>
                    {loadingRevenue ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : revenue.length ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Revenue type')}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {revenue.map((revenue) => (
                                        <RevenueRow
                                            key={revenue.id}
                                            revenue={revenue}
                                            canAll={canAll}
                                            isInitializeCase={!!setInitializeData}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </Pagination>
            </div>
        </>
    );
};

ManageRevenue.propTypes = {
    setInitializeData: PropTypes.func,
};

ManageRevenue.defaultProps = {
    setInitializeData: null,
};

export default ManageRevenue;
