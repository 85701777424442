import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Footer from 'components/footer/footer';
import { Helmet } from 'react-helmet';

import Dashboard from './dashboard';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>ERP</title>
            </Helmet>
            <Switch>
                <Route exact path="/">
                    <Dashboard />
                </Route>
            </Switch>
            <Footer />
        </>
    );
};

export default Home;
