import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import EditIcon from '@material-ui/icons/Edit';

import BasicTooltip from 'components/shared/basic-tooltip';
import SuccessButton from 'components/shared/success-button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RiemContext } from '../riem';
import RiemHeaderForm from './riem-header-form';

const RiemHeader = ({ riem, renderType, height }) => {
    const { t } = useTranslation();

    const [isMounted, setIsMounted] = useState(false);
    const { isSaving, isOperatingOnRowAt, setIsOperatingOnRowAt } = useContext(RiemContext);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (isOperatingOnRowAt === 'header') return <RiemHeaderForm riem={riem} />;

    return (
        <div
            role="row"
            className="col-span-full grid bg-disabled"
            style={{
                gridTemplateColumns: 'subgrid',
                height: height ?? '',
            }}
        >
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.number}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.stage}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.identifiedDescription}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.identifiedScore}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.identifiedProbability}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.identifiedTotal}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.measures}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`px-0.5 py-1 text-xl font-bold text-pdf-text  w-full${renderType ? 'break-words' : ''}`}>
                    {riem.header.residualDescription}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.residualScore}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.residualProbability}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.residualTotal}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.identificationDate}
                </p>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black">
                <p className={`w-full px-0.5 py-1 text-xl font-bold text-pdf-text ${renderType ? 'break-words' : ''}`}>
                    {riem.header.lastUpdate}
                </p>
            </div>

            {!renderType &&
                isMounted &&
                createPortal(
                    <div className="flex items-center justify-center">
                        <BasicTooltip
                            tip={t("You can't add new rows while you create or edit an existing one")}
                            disabled={isOperatingOnRowAt === null}
                        >
                            <SuccessButton
                                disabled={isSaving || isOperatingOnRowAt !== null}
                                startIcon={<EditIcon />}
                                size="small"
                                onClick={() => setIsOperatingOnRowAt('header')}
                            >
                                {t('Edit')}
                            </SuccessButton>
                        </BasicTooltip>
                    </div>,
                    document.getElementById('riem-header-container')
                )}
        </div>
    );
};

RiemHeader.propTypes = {
    riem: PropTypes.object,
    renderType: PropTypes.string,
    height: PropTypes.string,
};
RiemHeader.defaultProps = {
    riem: {},
    renderType: null,
    height: null,
};

export default RiemHeader;
