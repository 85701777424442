import React from 'react';

import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import EmailTemplateForm from './email_templates_form';

const CreateEmailTemplate = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Add new email template')}</title>
            </Helmet>

            <Header pageTitle={t('Add new email template')} />
            <div className="page-container">
                <EmailTemplateForm />
            </div>
        </>
    );
};

export default CreateEmailTemplate;
