import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import PlanningOverviewProject from './project-form';

const CreateProject = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const { permissionMap } = useContext(UserContext);
    const canAll = permissionMap['Planning Overview'] > 1;

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    return (
        canAll && (
            <>
                <Helmet>
                    <title>{t('Create new project')}</title>
                </Helmet>

                <Header pageTitle={t('Create new project')} />

                <div className="page-container">
                    <PlanningOverviewProject />
                </div>
            </>
        )
    );
};

export default CreateProject;
