import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

export const ActionButton = (props) => {
    const { onClick, icon, color, disabled, size } = props;
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const newSize = `${size / 6}rem`;

        for (const child of container.children) {
            child.style.width = newSize;
            child.style.height = newSize;
        }
    }, [icon, color, disabled, size]);

    return (
        <div
            ref={containerRef}
            className={`action-button no-user-select-recursive relative inline-flex flex-shrink-0 cursor-pointer items-center justify-center rounded-full font-thin
            text-buttons-text ${disabled ? 'pointer-events-none' : ''}`}
            onClick={onClick}
            style={{
                width: `${size / 4}rem`,
                height: `${size / 4}rem`,
                backgroundColor: disabled ? 'var(--disabled)' : color ?? `var(--primary-main)`,
            }}
        >
            {icon}
        </div>
    );
};
ActionButton.propTypes = {
    icon: PropTypes.element,
    onClick: PropTypes.func,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.number,
};
ActionButton.defaultProps = {
    icon: null,
    onClick: () => null,
    color: null,
    disabled: false,
    size: 7,
};
