import React from 'react';

import PropTypes from 'prop-types';

const Selectable = (props) => {
    const { children, selected, onClick } = props;

    return (
        // eslint-disable-next-line
        <div
            onClick={onClick}
            className={`flex items-center ${
                selected ? 'bg-primary-main' : 'bg-layout-transparent-dark hover:bg-primary-transparent'
            } mb-1 mr-1 h-10 cursor-pointer rounded-md px-5 transition-colors`}
        >
            <p className="user-select-none whitespace-nowrap text-buttons-text">{children}</p>
        </div>
    );
};

Selectable.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    selected: PropTypes.bool,
    onClick: PropTypes.func,
};

Selectable.defaultProps = {
    children: '',
    selected: false,
    onClick: () => null,
};

export const SelectList = (props) => {
    const { options, selectedOptions, setSelectedOptions, multiple } = props;

    return (
        <>
            {options && (
                <div className="no-user-select-recursive inline-flex flex-wrap">
                    {options.map((option, index) => {
                        const selected = selectedOptions ? selectedOptions.indexOf(index) >= 0 : false;

                        return (
                            <Selectable
                                selected={selected}
                                onClick={() => {
                                    if (!selected) {
                                        if (multiple) setSelectedOptions([...selectedOptions, index]);
                                        else setSelectedOptions([index]);
                                    } else if (multiple) setSelectedOptions(selectedOptions.filter((i) => i !== index));
                                    else setSelectedOptions([]);
                                }}
                                key={`OPTION-${option}`}
                            >
                                {option}
                            </Selectable>
                        );
                    })}
                </div>
            )}
        </>
    );
};
SelectList.propTypes = {
    options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    selectedOptions: PropTypes.arrayOf(PropTypes.number),
    setSelectedOptions: PropTypes.func,
    multiple: PropTypes.bool,
};
SelectList.defaultProps = {
    options: [],
    selectedOptions: [],
    setSelectedOptions: () => null,
    multiple: false,
};
