import { toPng } from 'html-to-image';

/**
 * The `exportImage` function allows you to export an element as a PNG image and optionally download
 * it.
 * @param {Element} element - The `element` parameter is the HTML element that you want to export as an image. It
 * could be any valid HTML element such as a `<div>`, `<canvas>`, or `<img>`.
 * @param {Boolean} download - The `download` parameter is a boolean value that determines whether the
 * exported image should be downloaded or not. If `download` is set to `true`, the image will be
 * downloaded. If `download` is set to `false`, the image will not be downloaded and the function will
 * only return the
 * @param {String} fileName - The `fileName` parameter is a string that specifies the name of the file to be
 * downloaded. By default, it is set to the current date in the format "YYYY-MM-DD.png". However, you
 * can provide a custom name by passing a string value to the `fileName` parameter when calling the
 * @returns The function `exportImage` returns the `dataPng` variable, which is the PNG data of the
 * element.
 */
const exportImage = async (element, download = true, fileName = `${new Date().toISOString().slice(0, 10)}.png`) => {
    // ? We create a delay in order to wait for some UI elements to display (loaders or others)
    await new Promise((resolve) => setTimeout(resolve, 250));

    // ? We extract the image into a variable and we skip the auto scale in order to maintain the aspect ratio of the image
    const dataPng = await toPng(element, { skipAutoScale: true });

    // ? We download the image only if we specify that
    if (download) {
        const link = document.createElement('a');
        link.href = dataPng;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return dataPng;
};

export default exportImage;
