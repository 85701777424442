import React from 'react';

import VariationsForm from 'components/crm/quotes/variations/variations-form';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateVariations = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('Add variation')} </title>
            </Helmet>

            <Header pageTitle={t('Add variation')} />

            <div className="page-container">
                <VariationsForm />
            </div>
        </>
    );
};

export default CreateVariations;
