import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import { Button, CircularProgress, TextField } from '@material-ui/core';

import Milestone from 'components/milestones/milestone';
import DOMPurify from 'dompurify';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useScreenSizes from 'hooks/useScreenSizes.js';
import htmlToDraft from 'html-to-draftjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { CheckItem, Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const MilestonesForm = (props) => {
    const { view, editable, id, milestoneInfo, setInitializeData } = props;

    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [width] = useScreenSizes();

    const [title, setTitle] = useState('');
    const [milestones, setMilestones] = useState([]);
    const [responsible, setResponsible] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [watcher, setWatcher] = useState([]);
    const [pipeline, setPipeline] = useState(null);
    const [active, setActive] = useState(false);
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    // Description logic

    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const [description, setDescription] = useState(
        htmlToDraftBlocks(`<h2 style="color: rgb(0,0,0);>${t('Add description for the Milestone')}</h2>`)
    );

    let schema = yup.object().shape({
        pipeline: yup
            .number()
            .typeError(t('Selecting the MIlestone Pipeline is mandatory!'))
            .required(t('Selecting the MIlestone Pipeline is mandatory!')),
        responsible: yup
            .array()
            .typeError(t('Choosing at least one Milestone responsible is mandatory!'))
            .min(1, t('Choosing at least one Milestone responsible is mandatory!'))
            .required(t('Choosing at least one Milestone responsible is mandatory!')),
        supervisor: yup
            .array()
            .typeError(t('Choosing at least one Milestone supervisor is mandatory!'))
            .min(1, t('Choosing at least one Milestone supervisor is mandatory!'))
            .required(t('Choosing at least one Milestone supervisor is mandatory!')),
        watcher: yup
            .array()
            .typeError(t('Choosing at least one Milestone watcher/beneficiary is mandatory!'))
            .min(1, t('Choosing at least one Milestone watcher/beneficiary is mandatory!'))
            .required(t('Choosing at least one Milestone watcher/beneficiary is mandatory!')),
        title: yup
            .string()
            .trim()
            .typeError(t('The name of the Milestone is mandatory!'))
            .required(t('The name of the Milestone is mandatory!')),
        milestone: yup
            .array()
            .of(
                yup.object().shape({
                    // estimatedDays: yup
                    //     .number()
                    //     .typeError(
                    //         t('The Sub-Milestone must have allocated the required number of days for completion!')
                    //     )
                    //     .required(
                    //         t('The Sub-Milestone must have allocated the required number of days for completion!')
                    //     ),
                    name: yup
                        .string()
                        .trim()
                        .typeError(t('Sub-Milestone name is mandatory!'))
                        .required(t('Sub-Milestone name is mandatory!')),
                    mileStoneResponsible: yup
                        .array()
                        .of(yup.number().typeError(t('Choosing at least one Sub-Milestone responsible is mandatory!')))
                        .min(1, t('Choosing at least one Sub-Milestone responsible is mandatory!'))
                        .required(t('Choosing at least one Sub-Milestone responsible is mandatory!')),
                    children: yup
                        .array()
                        .of(
                            yup.object().shape({
                                estimatedDays: yup
                                    .number()
                                    .typeError(
                                        t('The Task must have allocated the required number of days for completion!')
                                    )
                                    .required(
                                        t('The Task must have allocated the required number of days for completion!')
                                    ),
                                name: yup
                                    .string()
                                    .trim()
                                    .typeError(t('Task name is mandatory!'))
                                    .required(t('Task name is mandatory!')),
                                mileStoneResponsible: yup
                                    .array()
                                    .of(
                                        yup
                                            .number()
                                            .typeError(t('Choosing at least one Task responsible is mandatory!'))
                                    )
                                    .min(1, t('Choosing at least one Task responsible is mandatory!'))
                                    .required(t('Choosing at least one Task responsible is mandatory!')),
                            })
                        )
                        .min(1, t('Sub-Milestone must contain at least one task')),
                })
            )
            .min(1, t('The Milestone must have at least one Sub - Milestone!')),
    });

    const defaultMilestone = useCallback(() => {
        return {
            id: Date.now(),
            name: '',
            estimatedDays: null,
            mileStoneResponsible: [],
            children: [],
        };
    }, []);

    const addMilestone = useCallback(() => {
        const newMilestones = [...milestones, defaultMilestone()];
        setMilestones(newMilestones);
    }, [milestones]);

    const deleteMilestone = useCallback(
        (id) => {
            setMilestones(milestones.filter((m) => m.id !== id));
        },
        [milestones]
    );

    const updateMilestone = useCallback(
        (id, key, value) => {
            const milestoneIndex = milestones.findIndex((m) => m.id == id);
            const newMilestones = [...milestones];
            newMilestones[milestoneIndex][key] = value;
            setMilestones(newMilestones);
        },
        [milestones]
    );

    /**
     * @param {*} usersList - Is the state of the every dropdown with users - Array of numbers, returned by dropdown
     * @returns An array of users ids
     */
    const getUsersIds = (usersList) => {
        return usersList.map((user) => allUsers[user].id);
    };

    /**
     * Function take the info about user from an dropdown and transform data into an array on indexes for the dropdown to be set on edit
     * @param {*} usersId is an array of objects from the milestone data
     * @returns  An array of index for the dropdowns
     */
    const setDropdowns = useCallback(
        (usersId) => {
            if (allUsers.length) {
                return usersId?.map((user) => user.userId).map((id) => allUsers.findIndex((user) => user.id === id));
            }
            return [];
        },
        [allUsers]
    );

    const createMilestone = async () => {
        try {
            setLoading(true);
            setInitializeData?.((prev) => ({ ...prev, loading: true }));

            await schema.validate({
                title,
                pipeline,
                responsible,
                supervisor,
                watcher,
                milestone: milestones.map((m) => m),
            });

            try {
                const htmlContent = DOMPurify.sanitize(
                    draftToHtml(convertToRaw(description.getCurrentContent())).replaceAll('color: currentcolor;', '')
                );

                await API.post('/mileStones', {
                    name: title,
                    description: htmlContent,
                    pipelineType:
                        pipeline === 0
                            ? 'NOT_ASSUMED'
                            : pipeline === 1
                            ? 'LEADS_TO_POTENTIAL'
                            : pipeline === 2
                            ? 'OFFER'
                            : pipeline === 3
                            ? 'CLIENT_ACTIVITY'
                            : '',
                    isActive: active,
                    mileStoneTask: milestones.map((m) => ({
                        estimatedDays: m.estimatedDays,
                        name: m.name,
                        mileStoneResponsible: getUsersIds(m.mileStoneResponsible),
                        children: m.children.map((task) => ({
                            name: task.name,
                            estimatedDays: task.estimatedDays,
                            mileStoneResponsible: getUsersIds(task.mileStoneResponsible),
                        })),
                    })),
                    responsible: getUsersIds(responsible),
                    watcher: getUsersIds(watcher),
                    accepter: getUsersIds(supervisor),
                });
                if (setInitializeData) setInitializeData((prev) => ({ ...prev, tab: 0 }));
                else history.push(configurator.base + '?tab=Milestone');
                enqueueSnackbar(t('The Milestone was created successfully!'), {
                    variant: 'success',
                });
            } catch (err) {
                console.error(err);
                throw err;
            }
        } catch (err) {
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        } finally {
            setLoading(false);
            setInitializeData?.((prev) => ({ ...prev, loading: false }));
        }
    };

    const updateMilestoneForm = async () => {
        try {
            setLoading(true);

            await schema.validate({
                title,
                pipeline,
                responsible,
                supervisor,
                watcher,
                milestone: milestones.map((m) => m),
            });

            try {
                const htmlContent = DOMPurify.sanitize(
                    draftToHtml(convertToRaw(description.getCurrentContent())).replaceAll('color: currentcolor;', '')
                );

                await API.put('/mileStones', {
                    data: {
                        name: title,
                        description: htmlContent,
                        pipelineType:
                            pipeline === 0
                                ? 'NOT_ASSUMED'
                                : pipeline === 1
                                ? 'LEADS_TO_POTENTIAL'
                                : pipeline === 2
                                ? 'OFFER'
                                : pipeline === 3
                                ? 'CLIENT_ACTIVITY'
                                : '',
                        isActive: active,
                        mileStoneTask: milestones.map((m) => ({
                            estimatedDays: m.estimatedDays,
                            name: m.name,
                            mileStoneResponsible: getUsersIds(m.mileStoneResponsible),
                            children: m.children.map((task) => ({
                                name: task.name,
                                estimatedDays: task.estimatedDays,
                                mileStoneResponsible: getUsersIds(task.mileStoneResponsible),
                            })),
                        })),
                        responsible: getUsersIds(responsible),
                        watcher: getUsersIds(watcher),
                        accepter: getUsersIds(supervisor),
                    },
                    id: milestoneInfo.id,
                });
                history.push(configurator.base + '?tab=Milestone');
                enqueueSnackbar(t('The Milestone was updated successfully!'), {
                    variant: 'success',
                });
            } catch (err) {
                console.error(err);
                throw err;
            }
        } catch (err) {
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const getPipelineType = useCallback(() => {
        switch (milestoneInfo?.pipelineType) {
            case 'NOT_ASSUMED':
                return 0;
            case 'LEADS_TO_POTENTIAL':
                return 1;
            case 'OFFER':
                return 2;
            default:
                return 3;
        }
    }, [milestoneInfo]);

    useEffect(() => {
        if (allUsers.length && milestoneInfo) {
            setTitle(milestoneInfo?.name);
            setDescription(htmlToDraftBlocks(milestoneInfo?.description));
            setMilestones(milestoneInfo?.mileStoneTask);
            setResponsible(setDropdowns(milestoneInfo?.responsible));
            setWatcher(setDropdowns(milestoneInfo?.watcher));
            setSupervisor(setDropdowns(milestoneInfo?.accepter));
            setPipeline(getPipelineType());
            setActive(milestoneInfo?.isActive);
        }
    }, [milestoneInfo, allUsers]);

    /**
     * Fetching Users
     */
    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/tenants', {
                    params: {
                        perPage: 9999,
                        currentPage: 0,
                    },
                });
                setUsers(response.data.users.map((u) => u?.profile?.name));
                setAllUsers(response.data.users);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    return loading ? (
        <div className="flex h-svh w-full items-center justify-center bg-layout-main">
            <CircularProgress />
        </div>
    ) : (
        <div className={`flex w-1/2 flex-col gap-8 overflow-visible xl:w-2/3 lg:w-3/4 md:w-full`}>
            <div className="flex w-full gap-5 ">
                <div style={{ zIndex: '9999' }}>
                    <LabelWrapper label={t('Pipeline')}>
                        <Dropdown
                            placeholder={t('Choose option')}
                            options={[
                                t('Do not take in the pipeline'),
                                t('Leads to potential'),
                                t('Offer'),
                                t('Customer activity (contracts)'),
                            ]}
                            selectedOption={pipeline}
                            setSelectedOption={!editable ? setPipeline : () => null}
                            disabled={editable}
                        />
                    </LabelWrapper>
                </div>

                <LabelWrapper label={'Status'}>
                    <div className="inline-block">
                        <CheckItem
                            small
                            name={active ? t('active') : t('inactive')}
                            checked={active}
                            setChecked={setActive}
                            disabled={editable}
                        />
                    </div>
                </LabelWrapper>
            </div>

            <div className="flex w-full gap-10 sm:gap-5">
                <LabelWrapper label={t('Responsible')}>
                    <MultiDropdown
                        options={users}
                        placeholder={t('Choose user')}
                        selectedOptions={responsible}
                        setSelectedOptions={(i) => {
                            if (!editable) {
                                if (responsible?.indexOf(i) > -1) {
                                    setResponsible(responsible.filter((opt) => opt !== i));
                                } else {
                                    setResponsible([...responsible, i]);
                                }
                            }
                        }}
                        disabled={editable}
                    />
                </LabelWrapper>

                <LabelWrapper label={'Supervisor'}>
                    <MultiDropdown
                        options={users}
                        placeholder={t('Choose user')}
                        selectedOptions={supervisor}
                        setSelectedOptions={(i) => {
                            if (!editable) {
                                if (supervisor?.indexOf(i) > -1) {
                                    setSupervisor(supervisor.filter((opt) => opt !== i));
                                } else {
                                    setSupervisor([...supervisor, i]);
                                }
                            }
                        }}
                        disabled={editable}
                    />
                </LabelWrapper>

                <LabelWrapper label={t('Watcher / Beneficiary')}>
                    <MultiDropdown
                        options={users}
                        placeholder={t('Choose user')}
                        selectedOptions={watcher}
                        setSelectedOptions={(i) => {
                            if (!editable) {
                                if (watcher?.indexOf(i) > -1) {
                                    setWatcher(watcher.filter((opt) => opt !== i));
                                } else {
                                    setWatcher([...watcher, i]);
                                }
                            }
                        }}
                        disabled={editable}
                    />
                </LabelWrapper>
            </div>

            <TextField
                placeholder={t('Milestone name')}
                disabled={editable}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />

            {editable && (
                <LabelWrapper label={t('Description')}>
                    <iframe
                        className="h-96 w-full rounded-md border border-layout-lighter bg-white p-2"
                        srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(
                            draftToHtml(convertToRaw(description.getCurrentContent())).replaceAll(
                                'color: currentcolor;',
                                ''
                            )
                        )}`}
                    />
                </LabelWrapper>
            )}

            {!editable && (
                <LabelWrapper label={t('Add description for the Milestone')}>
                    <div style={{ backgroundColor: 'var(--layout-light)' }} className={`rounded-md p-4`}>
                        <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            editorState={description}
                            onEditorStateChange={setDescription}
                            toolbarStyle={{
                                margin: 2,
                                padding: 2,
                                borderRadius: 0,
                                color: 'black',
                                backgroundColor: 'inherit',
                                border: 'none',
                            }}
                            wrapperStyle={{
                                width: '100%',
                            }}
                            editorStyle={{
                                borderRadius: 0,
                                backgroundColor: 'white',
                                padding: '0px 8px',
                                color: 'black',
                                minHeight: '20rem',
                                maxHeight: width <= 750 ? '' : '40rem',
                                lineHeight: 1.2,
                            }}
                            toolbar={{
                                options: [
                                    'inline',
                                    'blockType',
                                    'fontSize',
                                    'list',
                                    'textAlign',
                                    'colorPicker',
                                    'link',
                                    'remove',
                                    'history',
                                ],
                            }}
                        />
                    </div>
                </LabelWrapper>
            )}

            <div className="relative">
                {milestones.length > 0 && (
                    <div>
                        {milestones.map((subMile, index) => (
                            <div
                                key={subMile.id}
                                className="relative"
                                style={{
                                    zIndex: milestones.length - index + 1,
                                }}
                            >
                                <Milestone
                                    onDelete={deleteMilestone}
                                    onUpdate={updateMilestone}
                                    milestone={subMile}
                                    allUsers={users}
                                    letter={String.fromCharCode(index + 65)}
                                    setDropdowns={setDropdowns}
                                    editable={editable}
                                />
                            </div>
                        ))}
                    </div>
                )}

                {!editable && (
                    <Button startIcon={<AddIcon />} fullWidth onClick={!editable ? addMilestone : null}>
                        {t('Add Sub-Milestone')}
                    </Button>
                )}
            </div>

            {view && (
                <div className="mt-20">
                    {id ? (
                        <Button
                            type="submit"
                            startIcon={<CheckIcon />}
                            color="primary"
                            onClick={() => updateMilestoneForm()}
                        >
                            {t('Update Milestone')}
                        </Button>
                    ) : (
                        <Button startIcon={<AddIcon />} color="primary" onClick={() => createMilestone()}>
                            {t('Create Milestone')}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

MilestonesForm.propTypes = {
    view: PropTypes.bool,
    editable: PropTypes.bool,
    id: PropTypes.string,
    milestoneInfo: PropTypes.object,
    setInitializeData: PropTypes.func,
};

MilestonesForm.defaultProps = {
    view: true,
    editable: true,
    id: null,
    milestoneInfo: null,
    setInitializeData: null,
};

export default MilestonesForm;
