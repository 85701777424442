import API from 'utils/axios';

export const Expenses = {
    /**
     * Function to get all expenses paginated
     * @param {Number} perPage - number of the expense per page
     * @param {Number} currentPage - number of the current expenses page
     * @returns
     */
    async get(currentPage, perPage) {
        try {
            const res = await API.get('/expensesName', {
                params: {
                    currentPage,
                    perPage,
                    pagesToLoad: 1,
                },
            });

            return { ok: true, data: res.data.expensesName };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Function for getting the expense by id
     * @param {string} expenseId
     * @returns
     */
    async getById(expenseId) {
        try {
            const response = await API.get('/expenseName', {
                params: {
                    id: expenseId,
                },
            });

            return { ok: true, data: response.data.expenseName };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * The function for creating the expense
     * @param {Object}  expensesBodyRequest  Body request for creating the expense
     *
     */
    async edit(expensesBodyRequest) {
        try {
            await API.put('/expensesName', expensesBodyRequest);

            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * The function for editing the expense
     * @param {Object} expensesBodyRequest - This is the body of the request
     *
     */
    async create(expensesBodyRequest) {
        try {
            await API.post('/expensesName', expensesBodyRequest);

            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Function for deleting a expense
     * @param {*} expenseId - expense id
     * @returns
     */
    async delete(expenseId) {
        try {
            await API.delete(`/expensesName`, {
                params: {
                    id: expenseId,
                },
            });

            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },
};

export const Revenues = {
    /**
     * Function to get all revenues paginated
     * @param {Number} perPage - number of the revenue per page
     * @param {Number} currentPage - number of the current revenue page
     * @returns
     */
    async get(perPage, currentPage) {
        try {
            const res = await API.get('/revenues_names', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            return { ok: true, data: res.data.data };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * Function for getting the revenue by id
     * @param {string} revenueId
     * @returns
     */
    async getById(revenueId) {
        try {
            const response = await API.get(`/revenue_name/${revenueId}`);

            return { ok: true, data: response.data.data[0] };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * The function for creating the revenue
     * @param {Object}  revenueBodyRequest  Body request for creating the revenue
     *
     */
    async edit(revenueBodyRequest) {
        try {
            await API.put('/revenues_names', revenueBodyRequest);

            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    /**
     * The function for editing the revenue
     * @param {Object} revenueBodyRequest - This is the body of the revenue
     *
     */
    async create(revenueBodyRequest) {
        try {
            await API.post('/revenues_names', revenueBodyRequest);

            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },

    async delete(revenueId) {
        try {
            await API.delete(`/revenues_names`, {
                data: {
                    id: revenueId,
                },
            });

            return { ok: true, data: {} };
        } catch (error) {
            return {
                ok: false,
                data: null,
                error,
            };
        }
    },
};
