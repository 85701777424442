import React from 'react';

import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import API from 'utils/axios';

import AffiliateAccountForm from './affiliate_account_form';

export default function EditAffiliate({ pmId }) {
    const { t } = useTranslation();
    const { affiliateId } = useParams();
    const [affiliateInfo, setAffiliateInfo] = React.useState({});

    const getAffiliateAccountInfo = async () => {
        try {
            const response = await API.get(`/PmAffiliate?id=${affiliateId}`);

            setAffiliateInfo(response.data.affiliate.user);
        } catch (err) {
            console.error(err);
        }
    };

    React.useEffect(() => {
        getAffiliateAccountInfo();
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('PM Configurator')} | {t('Edit affiliate account')}
                </title>
            </Helmet>

            <Header pageTitle={t('Edit affiliate account')} />

            <div className="page-container">
                <AffiliateAccountForm pmId={pmId} affiliateInfoForEdit={affiliateInfo} />
            </div>
        </React.Fragment>
    );
}

EditAffiliate.propTypes = {
    pmId: PropTypes.string,
};

EditAffiliate.defaultProps = {
    pmId: '',
};
