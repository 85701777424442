import React from 'react';

import PropTypes from 'prop-types';

export const Toggle = (props) => {
    const { checked = false, setChecked = () => null, disabled } = props;

    return (
        // eslint-disable-next-line
        <div
            className={`toggle-component flex-shrink-0 ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
            onClick={disabled ? undefined : () => setChecked(!checked)}
        >
            <div />
        </div>
    );
};
Toggle.propTypes = {
    checked: PropTypes.bool,
    setChecked: PropTypes.func,
    disabled: PropTypes.bool,
};
Toggle.defaultProps = {
    checked: false,
    setChecked: () => null,
    disabled: false,
};
