import React from 'react';

import PropTypes from 'prop-types';

const NotResponsiveYet = ({ children }) => {
    return (
        <div className="hidden items-center justify-center bg-layout-main p-5  text-center text-lg  lg:flex">
            {children}
        </div>
    );
};

NotResponsiveYet.propTypes = {
    children: PropTypes.element,
};

export default NotResponsiveYet;
