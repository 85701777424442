import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

const FileUploadContainer = (props) => {
    const { children, onUpload, multiple, accept, disabled } = props;
    const inputRef = useRef(null);
    const [inputName, setInputName] = useState(null);

    const acceptValues = {
        all: '.jpg, .jpeg, .png, .pdf',
        file: '.pdf',
        image: '.jpg, .jpeg, .png',
    };

    useEffect(() => {
        const name = Math.random();
        setInputName(name);
    }, []);

    return (
        <div>
            {inputName != null && (
                <>
                    <span
                        onClick={() => {
                            inputRef?.current ? inputRef.current.click() : undefined;
                        }}
                    >
                        {children}
                    </span>

                    <input
                        id={`${inputName}-file-input`}
                        name={`${inputName}-file-input`}
                        type="file"
                        onChange={onUpload}
                        ref={inputRef}
                        multiple={multiple}
                        accept={acceptValues[accept]}
                        disabled={disabled}
                        style={{
                            display: 'none',
                            visibility: 'hidden',
                            opacity: 0,
                            pointerEvents: 'none',
                        }}
                    />
                </>
            )}
        </div>
    );
};

FileUploadContainer.propTypes = {
    children: PropTypes.element,
    onUpload: PropTypes.func,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    disabled: PropTypes.bool,
};

FileUploadContainer.defaultProps = {
    children: <></>,
    onUpload: () => null,
    multiple: true,
    accept: 'all',
    disabled: false,
};

export default FileUploadContainer;
