import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InfoIcon from '@material-ui/icons/Info';
import PersonIcon from '@material-ui/icons/Person';

import { TextField } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';

const SubMilestoneActivity = (props) => {
    const { milestone, onDelete, onUpdate, letter, allUsers } = props;
    const { t } = useTranslation();

    const defaultTask = () => {
        return {
            id: Math.random(),
            name: '',
            estimatedDays: 1,
            estimatedStartDate: new Date(),
            estimatedEndDate: new Date(),
            priority: 1,
            mileStoneResponsible: [],
        };
    };

    const addTask = () => {
        onUpdate(milestone.id, 'children', [...milestone.children, defaultTask()]);
    };

    const deleteTask = (id) => {
        onUpdate(
            milestone.id,
            'children',
            milestone.children.filter((t) => t.id != id)
        );
    };

    const updateTask = (id, key, value) => {
        const taskIndex = milestone.children.findIndex((t) => t.id == id);
        const newTasks = [...milestone.children];
        newTasks[taskIndex][key] = value;

        onUpdate(milestone.id, 'children', newTasks);
    };

    return (
        <div className="mb-1 flex flex-col border-l border-layout-lighter pl-1">
            <div className="relative z-20 mb-1 flex w-full items-center justify-center">
                <TextField
                    className="flex-1"
                    placeholder={`Sub - Milestone ${letter}`}
                    value={milestone.name}
                    onChange={(e) => onUpdate(milestone.id, 'name', e.target.value)}
                />

                <div className="w-1 flex-shrink-0" />

                {/* <div className="flex-shrink-0">
                    {!('children' in milestone) && (
                        <BasicTooltip tip="Timeline">
                            <Dropdown
                                variant="black"
                                icon={<DateRangeIcon />}
                                options={
                                    <div className="flex flex-col gap-2 w-64 p-4">
                                        <DatePicker date={milestone.estimatedStartDate || new Date()} variant="black" />
                                        <DatePicker date={milestone.estimatedEndDate} variant="black" />

                                        <TextField
                                            placeholder="Estimated duration (days)"
                                            inputProps={{ min: 1 }}
                                            value={milestone?.estimatedDays}
                                        />

                                        <div className="flex justify-end mt-2">
                                            <BasicTooltip
                                                tip={t('Set automatically when a task is added')}
                                                placement="left-start"
                                            >
                                                <InfoIcon className="text-white" />
                                            </BasicTooltip>
                                        </div>
                                    </div>
                                }
                            />
                        </BasicTooltip>
                    )}
                </div>

                <div className="w-1 flex-shrink-0" /> */}

                {/* Responsible DROPDOWN for milestone */}
                <div className="flex-shrink-0">
                    <BasicTooltip tip={t('Responsible')}>
                        <MultiDropdown
                            variant="black"
                            icon={<PersonIcon className="text-buttons-text" />}
                            options={allUsers}
                            selectedOptions={milestone?.mileStoneResponsible}
                            setSelectedOptions={(newId) => {
                                if (milestone?.mileStoneResponsible?.indexOf(newId) > -1) {
                                    onUpdate(
                                        milestone.id,
                                        'mileStoneResponsible',
                                        milestone?.mileStoneResponsible?.filter((opt) => opt !== newId)
                                    );
                                } else {
                                    let newVal = [];

                                    if (milestone?.mileStoneResponsible)
                                        newVal = [...milestone.mileStoneResponsible, newId];

                                    onUpdate(milestone.id, 'mileStoneResponsible', newVal);
                                }
                            }}
                        />
                    </BasicTooltip>
                </div>

                <div
                    className="transition:opacity flex h-11 w-11 flex-shrink-0 cursor-pointer items-center justify-center text-center opacity-50 hover:opacity-100"
                    onClick={() => onDelete(milestone.id)}
                >
                    <CloseIcon className="text-buttons-text" style={{ fontSize: '1rem' }} />
                </div>
            </div>

            {/* Submilestone's Tasks */}

            <div className="relative z-10 flex flex-col items-start">
                {milestone.children.length > 0 && (
                    <div className="relative mb-1 w-full border-l border-layout-lighter pl-1">
                        {milestone.children.map((task, index) => {
                            return (
                                <div
                                    key={task.id}
                                    className="relative mb-1 flex w-full items-center last:mb-0"
                                    style={{
                                        position: 'relative',
                                        zIndex: milestone.children.length - index + 1,
                                    }}
                                >
                                    <TextField
                                        placeholder={t('Task name')}
                                        value={task.name}
                                        onChange={(e) => updateTask(task.id, 'name', e.target.value)}
                                    />

                                    <div className="w-1 flex-shrink-0" />

                                    <div className="flex-shrink-0">
                                        <Dropdown
                                            variant="black"
                                            icon={<ClearAllIcon className="text-buttons-text" />}
                                            placeholder={t('Priority')}
                                            options={[t('Low priority'), t('Medium priority'), t('Top priority')]}
                                            selectedOption={task.priority}
                                            setSelectedOption={(priority) => updateTask(task.id, 'priority', priority)}
                                        />
                                    </div>

                                    <div className="w-1 flex-shrink-0" />

                                    <div className="flex-shrink-0">
                                        <Dropdown
                                            variant="black"
                                            icon={<DateRangeIcon className="text-buttons-text" />}
                                            options={
                                                <div className="flex w-64 flex-col gap-2 p-4">
                                                    <div className="flex flex-col gap-2">
                                                        {/* Start of task */}
                                                        <DatePicker
                                                            date={task.estimatedStartDate}
                                                            variant="black"
                                                            // setDate={(date) =>
                                                            //     updateTask(task.id, 'estimatedStartDate', date)
                                                            // }
                                                        />

                                                        {/* End of task */}
                                                        <DatePicker
                                                            date={task.estimatedEndDate}
                                                            variant="black"
                                                            // setDate={(date) =>
                                                            //     updateTask(task.id, 'estimatedEndDate', date)
                                                            // }
                                                        />
                                                    </div>

                                                    {/* Estimated days */}
                                                    <div className="relative">
                                                        {/* Text field with estimated days is readonly because of validating reasons */}
                                                        <div style={{ pointerEvents: 'none' }}>
                                                            <TextField
                                                                placeholder="N zile"
                                                                value={task.estimatedDays}
                                                            />
                                                        </div>

                                                        <div className="absolute right-2 top-1/2 flex -translate-y-1/2 transform gap-2">
                                                            {/* Decrease number of days button */}
                                                            {task.estimatedDays > 1 && (
                                                                <button
                                                                    type="button"
                                                                    className="flex h-8 w-8 select-none items-center justify-center rounded-full bg-layout-transparent text-buttons-text hover:bg-layout-transparent-dark"
                                                                    onClick={() =>
                                                                        updateTask(
                                                                            task.id,
                                                                            'estimatedDays',
                                                                            task.estimatedDays - 1
                                                                        )
                                                                    }
                                                                >
                                                                    -
                                                                </button>
                                                            )}

                                                            {/* Increase number of days button */}
                                                            <button
                                                                type="button"
                                                                className="flex h-8 w-8 select-none items-center justify-center rounded-full bg-layout-transparent text-buttons-text hover:bg-layout-transparent-dark"
                                                                onClick={() =>
                                                                    updateTask(
                                                                        task.id,
                                                                        'estimatedDays',
                                                                        task.estimatedDays + 1
                                                                    )
                                                                }
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </div>

                                    <div className="w-1 flex-shrink-0" />

                                    <div className="flex-shrink-0">
                                        <MultiDropdown
                                            variant="black"
                                            icon={<PersonIcon className="text-buttons-text" />}
                                            options={allUsers}
                                            selectedOptions={
                                                task?.mileStoneResponsible ? task?.mileStoneResponsible : []
                                            }
                                            setSelectedOptions={(newId) => {
                                                if (task?.mileStoneResponsible?.indexOf(newId) > -1) {
                                                    updateTask(
                                                        task.id,
                                                        'mileStoneResponsible',
                                                        task?.mileStoneResponsible?.filter((opt) => opt !== newId)
                                                    );
                                                } else {
                                                    updateTask(task.id, 'mileStoneResponsible', [
                                                        ...(task?.mileStoneResponsible ?? undefined),
                                                        newId,
                                                    ]);
                                                }
                                            }}
                                        />
                                    </div>

                                    <div
                                        className="transition:opacity flex h-11 w-11 flex-shrink-0 cursor-pointer items-center justify-center text-center opacity-50 hover:opacity-100"
                                        onClick={() => deleteTask(task.id)}
                                    >
                                        <CloseIcon className="text-buttons-text" style={{ fontSize: '1rem' }} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                {/* Add New Task Button */}
                <div
                    className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-layout-transparent text-center transition-colors hover:bg-layout-transparent-dark"
                    onClick={addTask}
                >
                    <AddIcon className="text-buttons-text" />
                </div>
            </div>
        </div>
    );
};

SubMilestoneActivity.propTypes = {
    milestone: PropTypes.object.isRequired,
    allUsers: PropTypes.array.isRequired,

    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    letter: PropTypes.string,
};

SubMilestoneActivity.defaultProps = {
    onDelete: () => null,
    onUpdate: () => null,
    letter: '',
};

export default SubMilestoneActivity;
