import React from 'react';

import PropTypes from 'prop-types';

import { Toggle } from '../Toggle/Toggle';

export const ModuleWrapper = (props) => {
    const { name, checked, setChecked, children, enabled } = props;

    return (
        <div
            className={`no-user-select-recursive overflow-hidden rounded-md border-2 border-layout-transparent hover:border-layout-transparent-dark ${
                checked ? 'opacity-100' : 'opacity-75'
            }`}
        >
            {/* eslint-disable-next-line */}
            <div
                className="flex cursor-pointer items-center bg-layout-transparent px-1.5 py-3 hover:bg-layout-transparent-dark"
                onClick={() => {
                    if (!enabled) return;
                    setChecked(!checked);
                }}
            >
                <Toggle checked={checked} />
                <p className="ml-1.5 whitespace-nowrap text-lg font-bold">{name}</p>
            </div>

            <div className={`px-1.5 py-3 ${checked ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                <div className="text-white">{children}</div>
            </div>
        </div>
    );
};

ModuleWrapper.propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    setChecked: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    enabled: PropTypes.bool,
};

ModuleWrapper.defaultProps = {
    name: '',
    checked: false,
    setChecked: () => null,
    children: <></>,
    enabled: true,
};
