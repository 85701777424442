import React from 'react';

import AttributesForm from 'components/crm/quotes/attributes/attributes-form';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateAttributes = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('Add attribute')} </title>
            </Helmet>

            <Header pageTitle={t('Add attribute')} />

            <div className="page-container">
                <AttributesForm />
            </div>
        </>
    );
};

export default CreateAttributes;
