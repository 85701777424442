import React from 'react';

import EditIcon from '@material-ui/icons/Edit';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import { Button, ClickAwayListener } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

const ProjectModal = ({ modal, setModal, teamInfo }) => {
    const { t } = useTranslation();

    return (
        <>
            {modal && !!teamInfo ? (
                <ClickAwayListener onClickAway={() => modal && setModal(false)}>
                    <div
                        className="absolute top-20 rounded-md border border-disabled p-4 "
                        style={{ zIndex: 9999, backgroundColor: 'var(--layour-lighter)', width: '130%' }}
                    >
                        <div className="border-b border-disabled">
                            <p className="mb-4 text-main-text">
                                <span className=" text-main-text text-opacity-50">{t('Team author')}: </span>
                                {teamInfo.UserName}, {formatDate(teamInfo.createdAt)}
                            </p>

                            <p className="mb-2 text-main-text">
                                <span className=" text-main-text text-opacity-50">{t('Total allocated hours')}: </span>
                                {teamInfo.hours}
                            </p>

                            {teamInfo.startDate ? (
                                <>
                                    <p className="mb-5 text-main-text">
                                        <span className=" text-main-text text-opacity-50">
                                            {t('Timeline activity')}:{' '}
                                        </span>
                                        {formatDate(teamInfo.startDate)} - {formatDate(teamInfo.endDate)}
                                    </p>
                                </>
                            ) : (
                                <div className="text-main-text text-opacity-50">
                                    {t('There is no timeline activity!')}
                                </div>
                            )}
                        </div>
                        <div className="mt-3 flex justify-between gap-2">
                            <Button startIcon={<EditIcon />} color="primary">
                                Nume Contract
                            </Button>
                            <Button startIcon={<LocalOfferIcon />} color="primary">
                                Nume oferta - 500$
                            </Button>
                        </div>
                    </div>
                </ClickAwayListener>
            ) : (
                <></>
            )}
        </>
    );
};

ProjectModal.propTypes = {
    modal: PropTypes.bool,
    setModal: PropTypes.func,
    teamInfo: PropTypes.array,
};

ProjectModal.defaultProps = {
    modal: false,
    setModal: () => null,
    teamInfo: [],
};

export default ProjectModal;
