import React, { useCallback, useEffect } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InfoIcon from '@material-ui/icons/Info';
import PersonIcon from '@material-ui/icons/Person';

import { TextField } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { formatPositiveNumber } from 'utils';

const Milestone = (props) => {
    const { milestone, onDelete, onUpdate, letter, allUsers, setDropdowns, editable } = props;

    const { t } = useTranslation();

    const defaultTask = useCallback(() => {
        return {
            id: Date.now(),
            name: '',
            estimatedDays: null,
            mileStoneResponsible: [],
        };
    }, []);

    const addTask = () => {
        const newTasks = [...milestone.children, defaultTask()];
        onUpdate(milestone.id, 'children', newTasks);
    };

    const deleteTask = (id) => {
        const newTasks = milestone.children.filter((t) => t.id != id);
        onUpdate(milestone.id, 'children', newTasks);
    };

    const updateTask = (id, key, value) => {
        const taskIndex = milestone.children.findIndex((t) => t.id == id);
        const newTasks = [...milestone.children];
        newTasks[taskIndex][key] = value;

        onUpdate(milestone.id, 'children', newTasks);
    };

    useEffect(() => {
        onUpdate(milestone.id, 'mileStoneResponsible', setDropdowns(milestone?.mileStoneResponsible));
    }, [milestone]);

    useEffect(() => {
        milestone?.children?.map((task) =>
            updateTask(task.id, 'mileStoneResponsible', setDropdowns(task?.mileStoneResponsible))
        );
    }, [milestone]);

    const setEstimatedDaysSubMilestone = () => {
        const tasksDays = milestone?.children?.map((c) => c.estimatedDays).reduce((a, b) => a + b, 0);
        onUpdate(milestone.id, 'estimatedDays', tasksDays);
    };

    return (
        <div>
            {milestone && (
                <div
                    className="mb-2 flex flex-col pl-2"
                    style={{ borderLeft: '1px solid  rgba(var(--layout-transparent), .05)' }}
                >
                    <div className="relative z-20 mb-2 flex w-full items-center justify-center">
                        <TextField
                            className="flex-1"
                            style={{ backgroundColor: 'rgba(var(--layout-transparent), .05)', borderRadius: '5px' }}
                            placeholder={`Sub - Milestone ${letter}`}
                            value={milestone.name}
                            disabled={editable}
                            onChange={(e) => onUpdate(milestone.id, 'name', e.target.value)}
                        />

                        <div className="w-2 flex-shrink-0" />

                        <div className="flex-shrink-0">
                            <BasicTooltip tip="Timeline">
                                <Dropdown
                                    variant="black"
                                    icon={<DateRangeIcon className="text-buttons-text" />}
                                    // options={ //Todo de vazut componenta din libraria noua
                                    //     <div className="w-56 p-4">
                                    //         <LabelWrapper label={t('Nr. of working days(M - F)')} white>
                                    //             <DropdownTextfield
                                    //                 placeholder="10"
                                    //                 disabled={true}
                                    //                 value={milestone?.estimatedDays}
                                    //             />
                                    //         </LabelWrapper>
                                    //         <div className="flex justify-end mt-2">
                                    //             <BasicTooltip
                                    //                 tip={t('Set automatically when a task is added')}
                                    //                 placement="left-start"
                                    //             >
                                    //                 <InfoIcon className="text-white" />
                                    //             </BasicTooltip>
                                    //         </div>
                                    //     </div>
                                    // }
                                    options={
                                        <div className="w-56 p-4">
                                            <LabelWrapper label={t('Nr. of working days(M - F)')} white>
                                                <input
                                                    className="outline-none h-10 rounded-md border bg-transparent px-2 text-main-text placeholder-main-text placeholder-opacity-25"
                                                    placeholder="10"
                                                    disabled={true}
                                                    value={milestone?.estimatedDays}
                                                />
                                            </LabelWrapper>
                                            <div className="mt-2 flex justify-end">
                                                <BasicTooltip
                                                    tip={t('Set automatically when a task is added')}
                                                    placement="left-start"
                                                >
                                                    <InfoIcon className="text-main-text" />
                                                </BasicTooltip>
                                            </div>
                                        </div>
                                    }
                                    selectedOption={milestone.dependencyId}
                                    setSelectedOption={(newId) => onUpdate(milestone.id, 'dependencyId', newId)}
                                    disabled={editable}
                                />
                            </BasicTooltip>
                        </div>

                        <div className="w-2 flex-shrink-0" />

                        <div className="flex-shrink-0">
                            <BasicTooltip tip={t('Responsible')}>
                                <MultiDropdown
                                    variant="black"
                                    disabled={editable}
                                    icon={<PersonIcon className="text-buttons-text" />}
                                    options={allUsers}
                                    selectedOptions={milestone.mileStoneResponsible}
                                    setSelectedOptions={(newId) => {
                                        if (!editable) {
                                            if (milestone?.mileStoneResponsible?.indexOf(newId) > -1) {
                                                onUpdate(
                                                    milestone.id,
                                                    'mileStoneResponsible',
                                                    milestone?.mileStoneResponsible.filter((opt) => opt !== newId)
                                                );
                                            } else {
                                                onUpdate(milestone.id, 'mileStoneResponsible', [
                                                    ...milestone.mileStoneResponsible,
                                                    newId,
                                                ]);
                                            }
                                        }
                                    }}
                                />
                            </BasicTooltip>
                        </div>
                        {!editable && (
                            <div
                                className="transition:opacity flex h-11 w-11 flex-shrink-0 cursor-pointer items-center justify-center text-center text-main-text opacity-50 hover:opacity-100"
                                onClick={() => !editable && onDelete(milestone.id)}
                            >
                                <CloseIcon className="text-error" style={{ fontSize: '1rem' }} />
                            </div>
                        )}
                    </div>

                    <div className="relative z-10 flex flex-col items-start">
                        {milestone?.children?.length > 0 && (
                            /*
                                Milestone Tasks
                            */
                            <div
                                className="relative mb-2 w-full pl-2"
                                style={{
                                    borderLeft: '1px solid rgba(var(--layout-transparent), .05)',
                                }}
                            >
                                {milestone.children.map((task, index) => (
                                    <div
                                        key={task.id}
                                        className="relative mb-2 flex w-full items-center last:mb-0"
                                        style={{
                                            zIndex: milestone.children.length - index + 1,
                                        }}
                                    >
                                        <TextField
                                            placeholder={t('Task name')}
                                            value={task.name}
                                            disabled={editable}
                                            onChange={(e) => updateTask(task.id, 'name', e.target.value)}
                                        />

                                        <div className="w-2 flex-shrink-0" />

                                        <div className="flex-shrink-0">
                                            <Dropdown
                                                variant="black"
                                                disabled={editable}
                                                icon={<DateRangeIcon className="text-buttons-text" />}
                                                // options={ // Todo de vazut componenta din librarie
                                                //     <div className="w-56 p-4">
                                                //         <LabelWrapper label={t('Nr. of working days(M - F)')} white>
                                                //             <DropdownTextfield
                                                //                 placeholder="10 zile"
                                                //                 type="number"
                                                //                 value={task.estimatedDays}
                                                //                 onChange={(newValue) => {
                                                //                     if (!editable) {
                                                //                         updateTask(
                                                //                             task.id,
                                                //                             'estimatedDays',
                                                //                             formatPositiveNumber(newValue)
                                                //                         );
                                                //                         setEstimatedDaysSubMilestone();
                                                //                     }
                                                //                 }}
                                                //             />
                                                //         </LabelWrapper>
                                                //     </div>
                                                // }
                                                options={
                                                    <div className="w-56 p-4">
                                                        <LabelWrapper label={t('Nr. of working days(M - F)')} white>
                                                            <input
                                                                className="outline-none h-10 rounded-md border bg-transparent px-2 text-main-text placeholder-main-text placeholder-opacity-25"
                                                                value={task.estimatedDays}
                                                                placeholder="10 zile"
                                                                onChange={(e) => {
                                                                    if (!editable) {
                                                                        if (e.target.value < 0) return;
                                                                        if (e.target.value == 0) {
                                                                            updateTask(
                                                                                task.id,
                                                                                'estimatedDays',
                                                                                formatPositiveNumber(1)
                                                                            );
                                                                        } else {
                                                                            updateTask(
                                                                                task.id,
                                                                                'estimatedDays',
                                                                                formatPositiveNumber(e.target.value)
                                                                            );
                                                                            setEstimatedDaysSubMilestone();
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </LabelWrapper>
                                                    </div>
                                                }
                                            />
                                        </div>

                                        <div className="w-2 flex-shrink-0" />

                                        <div className="flex-shrink-0">
                                            <MultiDropdown
                                                variant="black"
                                                disabled={editable}
                                                icon={<PersonIcon className="text-buttons-text" />}
                                                options={allUsers}
                                                selectedOptions={task.mileStoneResponsible}
                                                setSelectedOptions={(newId) => {
                                                    if (!editable) {
                                                        if (task.mileStoneResponsible.indexOf(newId) > -1) {
                                                            updateTask(
                                                                task.id,
                                                                'mileStoneResponsible',
                                                                task?.mileStoneResponsible.filter(
                                                                    (opt) => opt !== newId
                                                                )
                                                            );
                                                        } else {
                                                            updateTask(task.id, 'mileStoneResponsible', [
                                                                ...task.mileStoneResponsible,
                                                                newId,
                                                            ]);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>

                                        {!editable && (
                                            <div
                                                className="transition:opacity flex h-11 w-11 flex-shrink-0 cursor-pointer items-center justify-center text-center text-main-text opacity-50 hover:opacity-100"
                                                onClick={() => {
                                                    !editable && deleteTask(task.id);
                                                    setEstimatedDaysSubMilestone();
                                                }}
                                            >
                                                <CloseIcon className="text-error" style={{ fontSize: '1rem' }} />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* 
                            Add New Task Button
                        */}
                        {!editable && (
                            <BasicTooltip tip={t('Add task')}>
                                <div
                                    className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-layout-transparent text-center text-main-text transition-colors hover:bg-layout-transparent-dark"
                                    onClick={!editable ? addTask : null}
                                >
                                    <AddIcon className="text-buttons-text" />
                                </div>
                            </BasicTooltip>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

Milestone.propTypes = {
    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    milestone: PropTypes.object,
    letter: PropTypes.string,
    allUsers: PropTypes.array,
    setDropdowns: PropTypes.func,
    editable: PropTypes.bool,
};

Milestone.defaultProps = {
    onDelete: () => null,
    onUpdate: () => null,
    milestone: null,
    letter: '',
    allUsers: [],
    setDropdowns: () => null,
    editable: true,
};

export default Milestone;
