import React from 'react';

import { Button, makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

import PipelineItemRow from './pipeline-item-row';

const useStyles = makeStyles({
    buttonClass: {
        fontSize: '1.25rem',
        backgroundColor: (props) => props.backgroundColor,
        color: (props) => props.color,
        '&:hover': {
            backgroundColor: (props) => props.backgroundColor,
        },
    },
});

const PipelineItemBody = ({ itemProps, item, open, color, backgroundColor }) => {
    const { buttonClass } = useStyles({ color, backgroundColor });

    const visibleRows = itemProps.body.renderVisibleRows(item);
    const extendedRows = itemProps.body.renderExpandedRows(item);

    const canExtend = extendedRows.length + Number(Boolean(itemProps.button) && itemProps.button.canRender) > 0;

    return (
        <div
            className="grid items-center gap-x-2"
            style={{
                gridTemplateColumns: '1fr 2fr',
            }}
        >
            {visibleRows.map((row, index) => {
                if (!row.canRender) return null;

                return <PipelineItemRow key={index} row={row} className={index !== 0 ? 'mt-4' : ''} />;
            })}

            {canExtend && (
                <div
                    className={`col-span-full grid transform transition-all duration-300 ${
                        open ? 'translate-y-4 pb-4' : 'translate-y-0'
                    }`}
                    style={{
                        gridTemplateColumns: 'subgrid',
                        gridTemplateRows: open ? '1fr' : `0fr`,
                    }}
                >
                    <div
                        className="col-span-full grid items-center gap-x-2 gap-y-4 overflow-hidden"
                        style={{
                            gridTemplateColumns: 'subgrid',
                        }}
                    >
                        {extendedRows.map((row, index) => {
                            if (!row.canRender) return null;

                            return <PipelineItemRow key={index} row={row} />;
                        })}
                        {itemProps.button && itemProps.button.canRender && (
                            <Button
                                className={`col-span-full ${buttonClass}`}
                                onClick={() => itemProps.button.onClick(item)}
                                disabled={itemProps.button.disabled}
                            >
                                {itemProps.button.render()}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

PipelineItemBody.propTypes = {
    itemProps: PropTypes.object,
    item: PropTypes.object,
    open: PropTypes.bool,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
};

PipelineItemBody.defaultProps = {
    itemProps: {},
    item: {},
    open: false,
    color: 'var(--main-text)',
    backgroundColor: 'var(--primary-main)',
};

export default PipelineItemBody;
