import React, { useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ConfigFieldWrapper from './config-field-wrapper';

const ToggleField = (props) => {
    const { t } = useTranslation();
    const { field, changeFieldValue, deleteFieldFromColumn } = props;
    const [active, setActive] = useState(field.options[0]);
    const [inactive, setInactive] = useState(field.options[1]);

    useEffect(() => {
        changeFieldValue('options', [active, inactive]);
    }, [active, inactive]);

    return (
        <>
            {field ? (
                <ConfigFieldWrapper
                    canBeDeleted={field.canBeDeleted}
                    type={field.type}
                    name={field.displayName}
                    setName={(newValue) => changeFieldValue('displayName', newValue)}
                    deleteFieldFromColumn={deleteFieldFromColumn}
                >
                    <TextField
                        label={t('Active')}
                        placeholder={t('Active')}
                        value={active}
                        onChange={(e) => setActive(e.target.value)}
                    />

                    <div className="h-4" />

                    <TextField
                        label={t('Inactive')}
                        placeholder={t('Inactive')}
                        value={inactive}
                        onChange={(e) => setInactive(e.target.value)}
                    />
                </ConfigFieldWrapper>
            ) : null}
        </>
    );
};

ToggleField.propTypes = {
    field: PropTypes.object,
    changeFieldValue: PropTypes.func,
    deleteFieldFromColumn: PropTypes.func,
};

ToggleField.defaultProps = {
    field: null,
    changeFieldValue: () => null,
    deleteFieldFromColumn: () => null,
};

export default ToggleField;
