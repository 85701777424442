import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, TableCell } from '@material-ui/core';

import PrpTypes from 'prop-types';
import { ExpandableRow } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { resourceModule } from 'routes';

const MaterialRow = ({ material }) => {
    const optionClass = 'text-base text-dark-text mb-2';
    const optionSpanClass = 'text-main-text font-bold';

    const history = useHistory();
    const { t } = useTranslation();

    const handleEdit = () => history.push(resourceModule.materialResources.edit + '/' + material.id);
    // const handleViewProject = () =>
    //     history.push(
    //         projectInManagement.base + '/' + material.PmProject.id + projectInManagement.planingOverviewOnProject.base
    //     );
    const handleViewWarehouse = () => history.push(resourceModule.warehouse.edit + '/' + material.id);

    return (
        <ExpandableRow
            colSpan={7}
            row={
                <Fragment>
                    <TableCell>{material.name}</TableCell>
                    <TableCell>{material.quantity ?? '-'}</TableCell>
                    <TableCell>{material.NIR}</TableCell>
                    <TableCell>{t(material.type.split('_').join(' '))}</TableCell>
                    <TableCell>{t(material.status)}</TableCell>
                    {/* <TableCell>{material?.PmProject?.name ?? '-'}</TableCell> */}
                    <TableCell>{material.Warehouse.name}</TableCell>
                </Fragment>
            }
            collapse={
                <div className="flex flex-col gap-10">
                    <div className="flex flex-wrap justify-between gap-4">
                        <Button color="primary" onClick={handleEdit}>
                            {t('Edit resource')}
                        </Button>
                        {/* <div className="flex gap-4 flex-wrap">
                            {material.pmProjectOverviewPlanId && (
                                <Button color="secondary" onClick={handleViewProject}>
                                    {t('View project')}
                                </Button>
                            )}
                            <Button color="secondary" onClick={handleViewWarehouse}>
                                {t('View warehouse')}
                            </Button>
                        </div> */}
                        <Button color="secondary" onClick={handleViewWarehouse}>
                            {t('View warehouse')}
                        </Button>
                    </div>
                    <div className="grid grid-cols-4 gap-10">
                        <div>
                            <h4 className="mb-5 whitespace-nowrap">{t('Resource info')}</h4>
                            <p className={optionClass}>
                                {t('Name')}: <span className={optionSpanClass}>{material.name}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Quantity')}: <span className={optionSpanClass}>{material.quantity ?? '-'}</span>
                            </p>
                            <p className={optionClass}>
                                {t('NIR')}: <span className={optionSpanClass}>{material.NIR}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Description')}: <span className={optionSpanClass}>{material.description}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Type')}:{' '}
                                <span className={optionSpanClass}>{t(material.type.split('_').join(' '))}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Status')}: <span className={optionSpanClass}>{t(material.status)}</span>
                            </p>
                        </div>
                        {/* {material.pmProjectOverviewPlanId && (
                            <div>
                                <h4 className="mb-5 whitespace-nowrap">{t('Project info')}</h4>

                                <p className={optionClass}>
                                    {t('Project name')}:{' '}
                                    <span className={optionSpanClass}>{material.PmProject.name}</span>
                                </p>
                                <p className={optionClass}>
                                    {t('Allocated teams')}:{' '}
                                    <span className={optionSpanClass}>
                                        {(() => {
                                            let string = material.PmProject.teams
                                                .slice(0, 3)
                                                .map((t) => t.name)
                                                .join(', ');
                                            if (material.PmProject.teams.length > 3)
                                                string += ` + ${t('others')} ${material.PmProject.teams.length - 3}`;
                                            return string;
                                        })()}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Created on')}:{' '}
                                    <span className={optionSpanClass}>{formatDate(material.PmProject.createAt)}</span>
                                </p>
                                <p className={optionClass}>
                                    {t('Last update')}:{' '}
                                    <span className={optionSpanClass}>
                                        {formatDate(material.PmProject.updateAt)},{' '}
                                        {formatTime(material.PmProject.updateAt)}
                                    </span>
                                </p>
                            </div>
                        )} */}
                        <div>
                            <h4 className="mb-5 whitespace-nowrap">{t('Warehouse info')}</h4>
                            <p className={optionClass}>
                                {t('Name')}: <span className={optionSpanClass}>{material.Warehouse.name}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Address')}: <span className={optionSpanClass}>{material.Warehouse.address}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Space')}: <span className={optionSpanClass}>{material.Warehouse.space}</span>
                            </p>
                            <p className={optionClass}>
                                {t('Type')}: <span className={optionSpanClass}>{t(material.Warehouse.type)}</span>
                            </p>
                        </div>
                    </div>
                </div>
            }
        />
    );
};

MaterialRow.propTypes = {
    material: PrpTypes.object,
};

export default MaterialRow;
