import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { Popover } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { findFilterOption } from './filter';
import FilterList from './filter-list';

const breakpointClasses = {
    sm: {
        wrapper: 'sm:hidden',
    },
    md: {
        wrapper: 'md:hidden',
    },
    lg: {
        wrapper: 'lg:hidden',
    },
    xl: {
        wrapper: 'xl:hidden',
    },
    '2xl': {
        wrapper: '2xl:hidden',
    },
};

const FilterDesktop = ({ filterOptions, filter, disabled, onChangeFilter, onRemoveFilter, mobileBP }) => {
    const { t } = useTranslation();

    const [hidden, setHidden] = useState(false);

    const handleHide = () => setHidden(true);
    const handleCanShow = () => setHidden(false);

    const wrapperRef = useRef(null);
    const contentRef = useRef(null);

    const actionRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'filter-id-desktop' : undefined;

    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useEffect(() => {
        let observer = null;

        if (open) {
            setTimeout(() => {
                observer = new ResizeObserver(() => {
                    if (actionRef.current) actionRef.current.updatePosition();

                    const popoverEl = document.getElementById(id);
                    if (popoverEl) {
                        const { height: bodyHeight } = getComputedStyle(document.body);
                        const { height: contentHeight } = getComputedStyle(contentRef.current);

                        if (parseFloat(contentHeight) >= parseFloat(bodyHeight) * 0.9)
                            popoverEl.style.overflowY = 'auto';
                        else popoverEl.style.overflowY = 'visible';
                    }
                });

                observer.observe(contentRef.current);
            }, 0);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [open]);

    useEffect(() => {
        let observer = null;

        observer = new ResizeObserver(() => {
            if (wrapperRef.current) {
                const { display } = getComputedStyle(wrapperRef.current);

                if (display === 'none') {
                    handleHide();
                    handleClose();
                } else handleCanShow();
            }
        });

        observer.observe(wrapperRef.current);

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [open]);

    return (
        <div className={`flex flex-wrap items-center gap-2 ${breakpointClasses[mobileBP].wrapper}`} ref={wrapperRef}>
            {filter.map((element) => {
                const filterOption = findFilterOption(element, filterOptions);

                return (
                    <div
                        key={element.id}
                        onClick={() => onRemoveFilter(element.id, handleClose)}
                        className={`flex cursor-pointer items-center gap-1 rounded-md bg-layout-lighter px-2 py-1.5 hover:bg-error-light ${
                            disabled ? 'pointer-events-none' : ''
                        }`}
                    >
                        <p className={`text-xl font-semibold leading-none ${disabled ? 'text-disabled' : ''}`}>{`${
                            filterOption.data.filterData.label
                        }: ${filterOption.data.filterData.render(element.value)}`}</p>
                        <CloseIcon
                            className={`${disabled ? 'text-disabled' : 'text-main-text'}`}
                            style={{
                                fontSize: '1.75rem',
                            }}
                        />
                    </div>
                );
            })}

            <div
                aria-describedby={id}
                className={`group flex cursor-pointer items-center gap-1 px-2 py-1.5 ${
                    disabled ? 'pointer-events-none' : ''
                }`}
                onClick={handleOpen}
            >
                <p
                    className={`text-xl font-semibold leading-none group-hover:text-secondary-light ${
                        disabled ? 'text-disabled' : ''
                    }`}
                >
                    {t('Add filter')}
                </p>

                <AddIcon
                    className={`text-main-text group-hover:text-secondary-light ${disabled ? 'text-disabled' : ''}`}
                    style={{
                        fontSize: '1.75rem',
                    }}
                />
            </div>
            <Popover
                id={id}
                open={open}
                action={actionRef}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                marginThreshold={12}
                hidden={hidden}
            >
                <div className="flex gap-3" ref={contentRef}>
                    <FilterList
                        mobileBP={mobileBP}
                        disabled={disabled}
                        list={filterOptions}
                        filter={filter}
                        relations={[]}
                        onChangeFilter={onChangeFilter.bind(null, handleClose)}
                    />
                </div>
            </Popover>
        </div>
    );
};

FilterDesktop.propTypes = {
    disabled: PropTypes.bool,
    filterOptions: PropTypes.array,
    filter: PropTypes.array,
    onRemoveFilter: PropTypes.func,
    onChangeFilter: PropTypes.func,
    mobileBP: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
};

FilterDesktop.defaultProps = {
    disabled: false,
    filterOptions: [],
    filter: [],
    onRemoveFilter: () => {},
    onChangeFilter: () => {},
    mobileBP: 'sm',
};

export default FilterDesktop;
