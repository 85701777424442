import React, { useContext, useState } from 'react';

import BorderColorIcon from '@material-ui/icons/BorderColor';
import CheckIcon from '@material-ui/icons/Check';
import PhotoIcon from '@material-ui/icons/Photo';

import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';

import FileUploadContainer from 'components/shared/file-upload-container';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';
import { errorHandling, formatVATnumber, IBAN_REGEX, PHONE_NO_REGEX, uploadSingleFile } from 'utils/index';
import * as yup from 'yup';

const CompanyDetails = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { checkPerm } = useContext(UserContext);

    const [editable, setEditable] = useState(false);
    const [update, setUpdate] = useState(false);
    const {
        currencyTypes,
        setCurrencyObj,
        setReferenceCurrencyObj,
        setGlobalModalOpen,
        setGlobalModalChildren,
        onUpdateTheme,
        themes,
        tenant,
        setTenant,
    } = useContext(GlobalContext);

    const [companyName, setCompanyName] = useState(tenant.companyName);
    const [companyRegisterNo, setCompanyRegisterNo] = useState(tenant.companyRegisterNo);
    const [cui, setCui] = useState(tenant.cui);
    const [addressString, setAddressString] = useState(tenant.addressString);
    const [email, setEmail] = useState(tenant.email);
    const [phoneNo, setPhoneNo] = useState(tenant.phoneNo);
    const [administratorName, setAdministratorName] = useState(tenant.administratorName);
    const [bank, setBank] = useState(tenant.customerBankInfo[0]?.bank);
    const [iban, setIban] = useState(tenant.customerBankInfo[0]?.iban);
    const [bank2, setBank2] = useState(tenant.customerBankInfo[1]?.bank);
    const [iban2, setIban2] = useState(tenant.customerBankInfo[1]?.iban);
    const [bank3, setBank3] = useState(tenant.customerBankInfo[2]?.bank);
    const [iban3, setIban3] = useState(tenant.customerBankInfo[2]?.iban);
    const [vatPercent, setVatPercent] = useState(tenant.percentageVAT ?? 0);
    const [selectedNationalCurrency, setSelectedNationalCurrency] = useState(() => {
        if (tenant.CustomerCurrency.length > 0) {
            const currencyId = tenant.CustomerCurrency.at(-1).currencyId;
            const currencyIndex = currencyTypes.findIndex((c) => c.id === currencyId);

            return currencyIndex;
        } else {
            return 0;
        }
    });
    const [selectedReferenceCurrency, setSelectedReferenceCurrency] = useState(() => {
        if (tenant.CustomerCurrency.length > 0) {
            const secondCurrencyId = tenant.CustomerCurrency.at(-1).secondCurrencyId;
            const secondCurrencyIndex = currencyTypes.findIndex((c) => c.id === secondCurrencyId);

            return secondCurrencyIndex;
        } else {
            return 0;
        }
    });

    const [logoBlob, setLogoBlob] = useState(null);
    const [iconBlob, setIconBlob] = useState(null);
    const [loading, setLoading] = useState(false);

    const canView = checkPerm([
        {
            permissionId: '17',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '17',
            permissionType: 'ALL',
        },
    ]);

    const [selectedTheme, setSelectedTheme] = useState(() => {
        if (tenant.ThemeConfigurator.length > 0) {
            return tenant.ThemeConfigurator.findIndex((theme) => theme.id === tenant.activeThemeId);
        }

        return null;
    });
    //TODO: Add the real permission check
    // const canAllTheme = checkPerm([
    //     {
    //         permissionId: '42',
    //         permissionType: 'ALL',
    //     },
    // ]);
    const canAllTheme = true;

    /**
     * Logo Upload Code
     */

    const handleLogoUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }
                setLogoBlob(blob);
            },
            'image',
        );
    };

    const handleIconUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }
                setIconBlob(blob);
            },
            'image',
        );
    };

    let sc = yup.object().shape({
        currencyId: yup
            .string()
            .typeError(t('The nation currency is mandatory!'))
            .required(t('The nation currency is mandatory!')),
        secondCurrencyId: yup
            .string()
            .typeError(t('The reference currency is mandatory!'))
            .required(t('The reference currency is mandatory!')),
        activeThemeId: yup
            .string()
            .typeError(t('Active theme is mandatory!'))
            .required(t('Active theme is mandatory!')),
        logo: yup.mixed().required(t('The logo is mandatory!')),
        icon: yup.mixed().required(t('The icon is mandatory!')),
        newCustomerBankInfo: yup
            .array()
            .of(
                yup
                    .object()
                    .shape({
                        bank: yup
                            .string()
                            .trim()
                            .typeError(t('The bank name is mandatory'))
                            .required(t('The bank name is mandatory'))
                            .min(3, t('The bank name must be at least 3 characters long!')),
                        iban: yup
                            .string()
                            .trim()
                            .typeError(t('IBAN account number one is mandatory'))
                            .required(t('IBAN account number one is mandatory'))
                            .matches(IBAN_REGEX, t('The IBAN account is not valid')),
                    })
                    .required(),
            )
            .min(1)
            .required(),
        administratorName: yup
            .string()
            .trim()
            .typeError(t('The company administrator field is mandatory'))
            .required(t('The company administrator field is mandatory'))
            .min(5, t('The company administrator name must be at least 5 characters long!')),
        phoneNo: yup
            .string()
            .trim()
            .typeError(t('The phone number field is mandatory'))
            .required(t('The phone number field is mandatory'))
            .matches(PHONE_NO_REGEX, t('The phone number is not valid'))
            .min(10, t('The phone number needs to contain at least 10 digits')),
        email: yup
            .string()
            .typeError(t('The email address is mandatory'))
            .required(t('The email address is mandatory'))
            .email(t('The email address is not valid')),
        addressString: yup
            .string()
            .trim()
            .typeError(t('The company address is mandatory!'))
            .required(t('The company address is mandatory!')),
        cui: yup
            .string()
            .trim()
            .typeError(t('The company CUI/CIF is mandatory'))
            .required(t('The company CUI/CIF is mandatory')),
        companyRegisterNo: yup
            .string()
            .trim()
            .typeError(t('The company registration number is mandatory'))
            .required(t('The company registration number is mandatory')),
        companyName: yup
            .string()
            .trim()
            .typeError(t('The company name is mandatory'))
            .required(t('The company name is mandatory')),
        percentageVAT: yup
            .number()
            .typeError(t('The VAT percentage is mandatory!'))
            .required(t('The VAT percentage is mandatory!')),
    });

    const updateCompanyDetails = async () => {
        try {
            const newCustomerBankInfo = [
                {
                    bank,
                    iban,
                },
            ];

            if (bank2 || iban2)
                newCustomerBankInfo.push({
                    bank: bank2,
                    iban: iban2,
                });

            if (bank3 || iban3)
                newCustomerBankInfo.push({
                    bank: bank3,
                    iban: iban3,
                });

            setLoading(true);

            await sc.validate({
                newCustomerBankInfo,
                administratorName,
                phoneNo,
                email,
                addressString,
                cui,
                companyRegisterNo,
                companyName,
                currencyId: currencyTypes[selectedNationalCurrency].id,
                secondCurrencyId: currencyTypes[selectedReferenceCurrency].id,
                activeThemeId: themes[selectedTheme].id,
                logo: logoBlob ?? tenant?.logoUrl,
                icon: iconBlob ?? tenant?.iconUrl,
                percentageVAT: vatPercent,
            });

            const reqBody = new FormData();
            reqBody.append(
                'data',
                JSON.stringify({
                    name: tenant.name,
                    companyName: companyName,
                    companyRegisterNo: companyRegisterNo,
                    cui: cui,
                    addressString: addressString,
                    email: email,
                    phoneNo: phoneNo,
                    administratorName: administratorName,
                    percentageVAT: vatPercent,
                    customerBankInfo: newCustomerBankInfo,
                    occupationDescription: tenant.occupationDescription,
                    id: tenant.id,
                    customerCurrency: {
                        currencyId: currencyTypes[selectedNationalCurrency].id,
                        secondCurrencyId: currencyTypes[selectedReferenceCurrency].id,
                        type: 'PRIMARY',
                    },
                    activeThemeId: themes[selectedTheme].id,
                    initializationStep: tenant.initializationStep,
                }),
            );

            if (logoBlob) reqBody.append('logo', logoBlob);
            if (iconBlob) reqBody.append('icon', iconBlob);

            try {
                const res = await API.put('/tenant', reqBody, { 'Content-Type': 'multipart/form-data' });

                setEditable(!editable);
                setUpdate(!update);
                enqueueSnackbar(t('Company details was successfully updated!'), {
                    variant: 'success',
                });

                // Update the global currency object
                setCurrencyObj(currencyTypes[selectedNationalCurrency]);
                setReferenceCurrencyObj(currencyTypes[selectedReferenceCurrency]);
                setTenant(res.data.tenant);
                onUpdateTheme(JSON.parse(themes[selectedTheme].theme));
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleNationalCurrencySelection = (e) => {
        setGlobalModalOpen(true);
        setGlobalModalChildren(
            <div className="flex-col">
                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                    {t(
                        'By changing the currency in the platform all the values for invoices, forecasted payments, etc will still be the same.',
                    )}
                    <br />
                    {t('Are you sure you want to proceed?')}
                </p>
                <div className="flex items-center justify-center">
                    <Button
                        color="primary"
                        onClick={() => {
                            setSelectedNationalCurrency(e);
                            setGlobalModalOpen(false);
                        }}
                    >
                        {t('Yes')}
                    </Button>
                    <div className="w-4" />
                    <Button onClick={() => setGlobalModalOpen(false)}>{t('No')}</Button>
                </div>
            </div>,
        );
    };

    const handleReferenceCurrencySelection = (e) => {
        setGlobalModalOpen(true);
        setGlobalModalChildren(
            <div className="flex-col">
                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                    {t(
                        'By changing the currency in the platform all the values for invoices, forecasted payments, etc will still be the same.',
                    )}
                    <br />
                    {t('Are you sure you want to proceed?')}
                </p>
                <div className="flex items-center justify-center">
                    <Button
                        color="primary"
                        onClick={() => {
                            setSelectedReferenceCurrency(e);
                            setGlobalModalOpen(false);
                        }}
                    >
                        {t('Yes')}
                    </Button>
                    <div className="w-4" />
                    <Button onClick={() => setGlobalModalOpen(false)}>{t('No')}</Button>
                </div>
            </div>,
        );
    };

    return (
        <>
            {canView && (
                <>
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="flex flex-row md:flex-col ">
                            <div className="grid w-1/2 grid-cols-2 gap-10 lg:w-full lg:grid-cols-1 ">
                                <div
                                    className="rounded-md p-6"
                                    style={{ border: '1px solid rgb(var(--base-layout-lighter))' }}
                                >
                                    <h4 className="mb-5">{t('Company details')}</h4>
                                    <div className="mb-5 ">
                                        <TextField
                                            name="companyName"
                                            label={t('Company name')}
                                            placeholder="ex: M2M Property Solutions SRL"
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <TextField
                                            name="companyRegisterNo"
                                            label={t('Trade Register Code')}
                                            placeholder="ex: j22/3321/2017"
                                            value={companyRegisterNo}
                                            onChange={(e) => setCompanyRegisterNo(e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <TextField
                                            name="cui"
                                            label="CUI/CIF"
                                            placeholder="ex: RO31422664"
                                            value={cui}
                                            onChange={(e) => setCui(e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <TextField
                                            name="addressString"
                                            label={t('Company address')}
                                            placeholder={t('ex:county,city,street,nr, etc')}
                                            value={addressString}
                                            onChange={(e) => setAddressString(e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className="rounded-md p-6"
                                        style={{ border: '1px solid rgb(var(--base-layout-lighter))' }}
                                    >
                                        <h4 className="mb-5">{'Administrator Details'}</h4>
                                        <div className="mb-5">
                                            <TextField
                                                name="administratorName"
                                                label={t('Administrator / Associate name')}
                                                placeholder={t('Administrator/Associate Name')}
                                                value={administratorName}
                                                onChange={(e) => setAdministratorName(e.target.value)}
                                                disabled={!editable}
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <TextField
                                                name="email"
                                                label="Email"
                                                placeholder={t('client@domain.com')}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                disabled={!editable}
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <TextField
                                                name="phoneNo"
                                                label={t('Phone number')}
                                                placeholder="0753176913"
                                                value={phoneNo}
                                                onChange={(e) => setPhoneNo(e.target.value)}
                                                disabled={!editable}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="rounded-md p-6"
                                    style={{ border: '1px solid rgb(var(--base-layout-lighter))' }}
                                >
                                    <h4 className="mb-5">{t('Bank/Banks Details')}</h4>
                                    <div className="mb-5">
                                        <TextField
                                            name="bank"
                                            label={`${t('Bank name')} I`}
                                            placeholder="ex: Banca Transilvania"
                                            value={bank}
                                            onChange={(e) => setBank(e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <TextField
                                            name="iban"
                                            label={`${t('IBAN account')} I`}
                                            placeholder="ex: RO91BTRLRONCRT0421401234"
                                            value={iban}
                                            onChange={(e) => setIban(e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <TextField
                                            name="bank 2"
                                            label={`${t('Bank name')} II`}
                                            placeholder="ex: Banca Transilvania"
                                            value={bank2}
                                            onChange={(e) => setBank2(e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <TextField
                                            name="iban 2"
                                            label={`${t('IBAN account')} II`}
                                            placeholder="ex: RO91BTRLRONCRT0421401234"
                                            value={iban2}
                                            onChange={(e) => setIban2(e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <TextField
                                            name="bank 3"
                                            label={`${t('Bank name')} III`}
                                            placeholder="ex: Banca Transilvania"
                                            value={bank3}
                                            onChange={(e) => setBank3(e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <TextField
                                            name="iban 3"
                                            label={`${t('IBAN account')} III`}
                                            placeholder="ex: RO91BTRLRONCRT0421401234"
                                            value={iban3}
                                            onChange={(e) => setIban3(e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col gap-10">
                                    <div
                                        className="rounded-md p-6"
                                        style={{ border: '1px solid rgb(var(--base-layout-lighter))' }}
                                    >
                                        <h4 className="mb-5">{t('Currency/VAT details')}</h4>
                                        <div>
                                            <div className="mb-5">
                                                <LabelWrapper label={t('Select national currency')}>
                                                    <Dropdown
                                                        disabled={!editable}
                                                        options={currencyTypes.map((ct) => ct.currency)}
                                                        selectedOption={selectedNationalCurrency}
                                                        setSelectedOption={
                                                            editable ? handleNationalCurrencySelection : () => null
                                                        }
                                                    />
                                                </LabelWrapper>
                                            </div>

                                            <div className="mb-5">
                                                <LabelWrapper label={t('Select reference currency')}>
                                                    <Dropdown
                                                        disabled={!editable}
                                                        options={currencyTypes.map((ct) => ct.currency)}
                                                        selectedOption={selectedReferenceCurrency}
                                                        setSelectedOption={
                                                            editable ? handleReferenceCurrencySelection : () => null
                                                        }
                                                    />
                                                </LabelWrapper>
                                            </div>

                                            <div className="mb-5">
                                                <LocaleTextField
                                                    name="vatPercent"
                                                    label={t('VAT rate')}
                                                    placeholder="19%"
                                                    value={vatPercent}
                                                    onChange={(e) => {
                                                        setVatPercent(formatVATnumber(e.target.value));
                                                    }}
                                                    disabled={!editable}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">%</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ml-10 flex flex-col gap-10 md:ml-0">
                                <div
                                    className="rounded-md p-6"
                                    style={{ border: '1px solid rgb(var(--base-layout-lighter))' }}
                                >
                                    <h4 className="mb-5 mt-0 md:mt-10">
                                        {canAllTheme
                                            ? t("Company's theme, logo and icon")
                                            : t("Company's logo and icon")}
                                    </h4>
                                    <div>
                                        {canAllTheme && (
                                            <div className="mb-5">
                                                <LabelWrapper label={t('Select company theme')}>
                                                    <Dropdown
                                                        disabled={!editable}
                                                        options={themes.map((t) => t.name)}
                                                        selectedOption={selectedTheme}
                                                        setSelectedOption={
                                                            editable ? (i) => setSelectedTheme(i) : undefined
                                                        }
                                                    />
                                                </LabelWrapper>
                                            </div>
                                        )}
                                        <LabelWrapper label={t('Company logo')}>
                                            <div className="mb-5 w-full max-w-xs">
                                                <img
                                                    src={tenant?.logoUrl}
                                                    className="w-full rounded-md p-2.5"
                                                    style={{
                                                        border: '1px solid var(--layout-transparent)',
                                                    }}
                                                />
                                            </div>
                                            {editable && (
                                                <div className="mb-5">
                                                    <FileUploadContainer onUpload={handleLogoUpload}>
                                                        <Button fullWidth startIcon={<PhotoIcon />}>
                                                            {(() => {
                                                                if (tenant?.logoUrl !== null && logoBlob === null) {
                                                                    return t('Change company logo');
                                                                } else if (logoBlob) {
                                                                    return logoBlob.name.substr(0, 10) + '...';
                                                                }
                                                            })()}
                                                        </Button>
                                                    </FileUploadContainer>
                                                </div>
                                            )}
                                        </LabelWrapper>
                                        <LabelWrapper label={t('Company icon')}>
                                            <div className="mb-5 w-full max-w-xs">
                                                <img
                                                    src={tenant?.iconUrl}
                                                    className="w-full rounded-md p-2.5"
                                                    style={{
                                                        border: '1px solid var(--layout-transparent)',
                                                    }}
                                                />
                                            </div>
                                            {editable && (
                                                <div className="mb-5">
                                                    <FileUploadContainer onUpload={handleIconUpload}>
                                                        <Button fullWidth startIcon={<PhotoIcon />}>
                                                            {(() => {
                                                                if (tenant?.iconUrl !== null && iconBlob === null) {
                                                                    return t('Change company icon');
                                                                } else if (iconBlob) {
                                                                    return iconBlob.name.substr(0, 10) + '...';
                                                                }
                                                            })()}
                                                        </Button>
                                                    </FileUploadContainer>
                                                </div>
                                            )}
                                        </LabelWrapper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="mt-10 flex justify-start">
                        {canAll && (
                            <>
                                {!update && (
                                    <Button
                                        size="large"
                                        color="primary"
                                        startIcon={<BorderColorIcon style={{ fontSize: 18 }} />}
                                        onClick={() => {
                                            setEditable(!editable);
                                            setUpdate(!update);
                                        }}
                                    >
                                        {t('Edit company details')}
                                    </Button>
                                )}

                                {update && (
                                    <Button
                                        size="large"
                                        color="primary"
                                        startIcon={<CheckIcon style={{ fontSize: 18 }} />}
                                        onClick={() => {
                                            updateCompanyDetails();
                                        }}
                                    >
                                        {t('Update company details')}
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default CompanyDetails;
