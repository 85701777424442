import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, CircularProgress, makeStyles, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { resourceModule } from 'routes';
import { EMAIL_REGEX, errorHandling } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
    customButton: {
        backgroundColor: 'var(--error)',
        color: 'var(--buttons-text)',
        '&:hover': {
            backgroundColor: 'var(--error-light)',
        },
    },
}));

const HumanAdd = () => {
    const styles = useStyles();

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(id ? true : false);
    const [isUploading, setIsUploading] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        address: '',
        CNP: '',
        email: '',
    });

    const handleFormDataUpdate = (e) =>
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));

    const schema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .min(2, t('Name must be at least 2 characters long!'))
            .required(t('Name is mandatory!')),
        address: yup.string().trim().required(t('Address is mandatory!')),
        CNP: yup.string().trim().required(t('CNP is mandatory!')),
        email: yup
            .string()
            .trim()
            .required(t('The email address is mandatory!'))
            .matches(EMAIL_REGEX, t('The email address is not valid!')),
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsUploading(true);

            await schema.validate(formData);

            if (!id) await API.post('humanResource', formData);
            else await API.put('humanResource', { ...formData, id });

            enqueueSnackbar(!id ? t('Resource created successfully!') : t('Resource updated successfully!'), {
                variant: 'success',
            });

            history.push(resourceModule.humanResources.categories);
        } catch (error) {
            if (error?.errors) enqueueSnackbar(error.errors[0], { variant: 'error' });
            else
                enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                    variant: 'error',
                });

            console.error(error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsUploading(true);

            await API.delete('humanResource', { params: { id } });

            enqueueSnackbar(t('Resources deleted successfully!'), {
                variant: 'success',
            });

            history.push(resourceModule.humanResources.categories);
        } catch (error) {
            enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                variant: 'error',
            });
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        if (!id) return;

        (async () => {
            try {
                const res = await API.get(`humanResource/${id}`);
                const humanData = res.data.getHumanResourceById;

                setFormData({
                    name: humanData.name,
                    address: humanData.address,
                    CNP: humanData.CNP,
                    email: humanData.email,
                });
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t('Something went wrong!'), {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <div className="page-container">
            {isLoading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <form className="flex max-w-xl flex-col gap-5" onSubmit={handleSubmit}>
                    <TextField
                        name="name"
                        placeholder={t('Name')}
                        label={t('Name')}
                        type="text"
                        value={formData.name}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <TextField
                        name="address"
                        placeholder={t('Address')}
                        label={t('Address')}
                        type="text"
                        value={formData.address}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <TextField
                        name="CNP"
                        placeholder="CNP"
                        label="CNP"
                        type="text"
                        value={formData.CNP}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <TextField
                        name="email"
                        placeholder="Email"
                        label="Email"
                        type="text"
                        value={formData.email}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <div className="mt-5 flex flex-wrap justify-between gap-5">
                        <div className="flex flex-wrap gap-5">
                            <Button
                                startIcon={!id ? <AddIcon /> : <CheckIcon />}
                                color="primary"
                                type="submit"
                                disabled={isUploading}
                            >
                                {!id ? t('Add resource') : t('Edit resource')}
                            </Button>
                            {id && (
                                <Button
                                    className={styles.customButton}
                                    startIcon={<DeleteIcon />}
                                    type="submit"
                                    disabled={isUploading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete();
                                    }}
                                >
                                    {t('Delete resource')}
                                </Button>
                            )}
                        </div>
                        <Button
                            startIcon={<BackspaceIcon />}
                            color="secondary"
                            disabled={isUploading}
                            onClick={(e) => {
                                e.preventDefault();
                                history.goBack();
                            }}
                        >
                            {t('Back')}
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default HumanAdd;
