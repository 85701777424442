import React from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Button } from '@material-ui/core';

import useScreenSizes from 'hooks/useScreenSizes';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';

const FilterMilestonesModal = ({ setOpen, ganttFilterData, setGanttFilterData, handleFilterGantt, milestones }) => {
    const [width] = useScreenSizes();
    const { t } = useTranslation();

    const isDisabled =
        ganttFilterData.milestones.length === 0 && (ganttFilterData.type === 1 || ganttFilterData.type === 3);

    return (
        <div className="flex flex-col items-center gap-16 p-10 sm:w-full" style={{ width: width > 600 && '45rem' }}>
            <h2>{t('Filter gantt')}</h2>

            <div className="flex w-full flex-col gap-8 rounded-md bg-layout-transparent p-4">
                <h3 className="text-center text-2xl font-bold">{t('Select the filter method')}</h3>

                <Dropdown
                    options={[
                        t('All milestones'),
                        t('By selected milestones'),
                        t('Between dates'),
                        t('Between dates and selected milestones'),
                        t('Included between dates'),
                    ]}
                    placeholder={t('Choose filter method')}
                    selectedOption={ganttFilterData.type}
                    setSelectedOption={(i) => setGanttFilterData((prev) => ({ ...prev, type: i }))}
                />
            </div>

            {ganttFilterData.type !== 0 && (
                <div className="flex w-full flex-col gap-4 rounded-md bg-layout-transparent p-4">
                    <h3 className="text-center text-2xl font-bold">{t('Select the filter data')}</h3>

                    {(ganttFilterData.type === 1 || ganttFilterData.type === 3) && (
                        <LabelWrapper label={t('Select milestones')}>
                            <MultiDropdown
                                options={milestones.map((milestone) => milestone.title)}
                                placeholder={t('Choose milestones')}
                                selectedOptions={ganttFilterData.milestones}
                                setSelectedOptions={(i) => {
                                    if (ganttFilterData.milestones.indexOf(i) > -1) {
                                        setGanttFilterData((prev) => ({
                                            ...prev,
                                            milestones: prev.milestones.filter((opt) => opt !== i),
                                        }));
                                    } else {
                                        setGanttFilterData((prev) => ({
                                            ...prev,
                                            milestones: [...prev.milestones, i],
                                        }));
                                    }
                                }}
                            />
                        </LabelWrapper>
                    )}
                    {(ganttFilterData.type === 2 || ganttFilterData.type === 3 || ganttFilterData.type === 4) && (
                        <div className="flex gap-4">
                            <LabelWrapper label={t('Start date')}>
                                <DatePicker
                                    date={ganttFilterData.startDate}
                                    setDate={(i) => setGanttFilterData((prev) => ({ ...prev, startDate: i }))}
                                />
                            </LabelWrapper>
                            <LabelWrapper label={t('End date')}>
                                <DatePicker
                                    date={ganttFilterData.endDate}
                                    setDate={(i) => setGanttFilterData((prev) => ({ ...prev, endDate: i }))}
                                />
                            </LabelWrapper>
                        </div>
                    )}
                </div>
            )}

            <div className="flex w-full justify-end gap-4">
                <Button onClick={() => setOpen(false)} startIcon={<CancelIcon />} color="secondary">
                    {t('Cancel')}
                </Button>
                <Button
                    startIcon={<CheckCircleIcon />}
                    color="secondary"
                    className="focus:outline-none disabled:!bg-slate-600 disabled:!text-slate-400"
                    onClick={handleFilterGantt}
                    disabled={isDisabled}
                >
                    {t('Filter milestones')}
                </Button>
            </div>
        </div>
    );
};

FilterMilestonesModal.propTypes = {
    ganttFilterData: PropTypes.object,
    setGanttFilterData: PropTypes.func,
    setOpen: PropTypes.func,
    handleFilterGantt: PropTypes.func,
    milestones: PropTypes.array,
};

export default FilterMilestonesModal;
